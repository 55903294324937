import { defineMessages } from 'react-intl'

const messages = defineMessages({
  errorRequired: {
    id: 'form.required.fileds',
    defaultMessage: 'Os campos com * são obrigatórios'
  },
  errorMismatch: {
    id: 'form.password.mismatch',
    defaultMessage: 'Os valores de senha estão diferentes'
  },
  errorLength: {
    id: 'form.password.invalid',
    defaultMessage: 'A senha precisa ter no mínimo 6 caracteres'
  },
  title: {
    id: 'confirmPasswordBuyer.title',
    defaultMessage: 'Falta pouco!',
  },
  subTitle: {
    id: 'confirmPasswordBuyer.text.subTitle',
    defaultMessage: 'Agora só precisamos que você crie seus dados de acesso.',
  },
  password: {
    id: 'confirmPasswordBuyer.input.password',
    defaultMessage: 'Criar senha *',
  },
  confirmPassword: {
    id: 'confirmPasswordBuyer.input.confirmPassword',
    defaultMessage: 'Confirmar senha *',
  },
  step: {
    id: 'confirmPasswordBuyer.text.step',
    defaultMessage: 'etapa 7 de 9',
  },
  back: {
    id: 'confirmPasswordBuyer.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'confirmPasswordBuyer.button.continue',
    defaultMessage: 'Continuar',
  },
  invalid: {
    id: 'confirmPasswordBuyer.text.invalid',
    defaultMessage: 'Senha inválida!',
  },
  equal: {
    id: 'confirmPasswordBuyer.text.equal',
    defaultMessage: 'As senhas devem ser iguais!',
  },
  size: {
    id: 'confirmPasswordBuyer.text.size',
    defaultMessage: 'A senha deve ter 6 dígitos!',
  },
})

export default messages

import React, { useState, useEffect } from 'react'
import { Container, Logo, FormContainer, Content, Footer } from './styles'
import Button from 'components/Button'
import Text from 'components/Text'
import Input from 'components/Input'
import logo from 'assets/logo.svg'
import { Link } from 'react-router-dom'
// import { useUser } from '../../modules/data/hooks/useUser'
import { useAuth } from '../../modules/data/hooks/useAuth'
import { useHistory } from 'react-router-dom'
import { routes } from 'configuration'
import { useIntl } from 'react-intl'
import messages from './messages'
import { ErrorMessage } from 'components/ErrorMessage'

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [password, setPasword] = useState('')
  // const { login, roles, isAnonymous, error } = useUser()
  const { login, isAnonymous, loggedIn, error } = useAuth()
  const history = useHistory()

  const { formatMessage } = useIntl()

  const signIn = (event) => {
    event.preventDefault()
    setLoading(true)
    login(email, password)
  }

  useEffect( () => {
    if(error?.login) setLoading(false)
  }, [ error ])

  if(loggedIn && !isAnonymous) {
    history.push(routes.admin.home)
  }

  return (
    <Container>
      <ErrorMessage 
        hide={!error?.login}
        mode='banner' 
        title='Erro no login' 
        message='O usuário e/ou a senha informados estão incorretos'
      />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form onSubmit={signIn}>
          <Content>
            <Input
              type='email'
              placeholder={formatMessage(messages.login)}
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
            <Input
              placeholder={formatMessage(messages.password)}
              type='password'
              value={password}
              onChange={({ target }) => setPasword(target.value)}
              showPasswordToggle
            />
            <Footer>
              <Link to='/recover-password'>
                <Text className='forgot'>
                  {formatMessage(messages.forgotPassword)}
                </Text>
              </Link>
              <Button loading={loading} className='button'>
                {formatMessage(messages.signIn)}
              </Button>
              <Link to='/welcome-registration'>
                <Text className='signup'>
                  {formatMessage(messages.noAccount)}
                  <span className='signup-text' color='primary'>
                    {' '}
                    &nbsp; {formatMessage(messages.signUp)}
                  </span>
                </Text>
              </Link>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

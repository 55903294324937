import React, { useState } from 'react'

import Title from 'components/Title'
import Text from 'components/Text'
import Button from 'components/Button'

import { parseToMoneyBRL } from 'utils'

import { BankData } from '../Shared'

import ChargingTab from './ChargingTab'

import * as s from './styles'
import { useFarmer } from 'modules/data/hooks/useFarmer'
import SVG from 'components/SVG'
import StepProgressBar from 'components/StepProgressBar'
import { colors } from 'styles'
import { useNegotiation } from 'modules/data/hooks/useNegotiation'
import PasswordValidation from 'components/PasswordValidation'

export function Help() {
  return (
    <Text className='batch-help'>
      Em caso de dúvida acesse nossa <a href='/'>central de ajuda</a>
    </Text>
  )
}

function Content({ batch, tab, close, negotiation }) {
  const [opened, setOpened] = useState(false)
  const [action, setAction] = useState()
  const { farmer } = useFarmer()
  const { acceptNegotiation, rejectNegotiation } = useNegotiation()
  console.log(batch, negotiation)

  const steps = [
    { title: 'Criação do lote' },
    { title: 'Interesse de negociação' },
    { title: 'Aceitou a negociação' },
    { title: 'Venda confirmada' },
  ]

  const handleCloseAuthModal = () => {
    setOpened(false)
  }

  const handleActionButtonClick = (action) => {
    setOpened(true)
    setAction(action)
  }

  const handleAuthSuccess = () => {
    switch (action) {
      case 'ACCEPT':
        acceptNegotiation(negotiation)
        break
      case 'REJECT':
        rejectNegotiation(negotiation)
        break
      default:
        break
    }

    setOpened(false)
  }

  switch (tab.slug) {
    case 'NEGOTIATION':
      if (!negotiation)
        return (
          <React.Fragment>
            <s.Card>
              <s.CardHeader>
                <Title>
                  Tudo certo por aqui, estamos encontrando a melhor oferta
                </Title>
                <Text color={'gray'} fontSize={'14px'}>
                  O seu lote está <b>aguardando negociação</b> quando uma
                  negociação for encontrada você será avisado e verá os detalhes
                  aqui
                </Text>
              </s.CardHeader>
              <SVG.Clock className='clock' />
            </s.Card>
            <Text className='batch-help'>
              Em caso de dúvida acesse nossa <a href='/'>central de ajuda</a>
            </Text>
          </React.Fragment>
        )
      else {
        if (!negotiation.acceptance?.farmer) {
          return (
            <React.Fragment>
              <s.Card>
                <s.CardHeader>
                  <Title>Você tem um interesse de negociação!</Title>
                  <Text color={'gray'} fontSize={'14px'}>
                    Um comprador se interessou por este lote
                  </Text>
                </s.CardHeader>
                <s.NegotiationDetail>
                  <s.Values>
                    <s.ValueCard>
                      <Text>
                        Valor da <b>tonelada do produto</b> hoje
                      </Text>
                      <Text>{parseToMoneyBRL(negotiation.value)}</Text>
                    </s.ValueCard>
                    <s.ValueCard>
                      <Text>Valor total do lote</Text>
                      <Text>{parseToMoneyBRL(negotiation.totalValue)}</Text>
                    </s.ValueCard>
                  </s.Values>
                  <Text color={'gray'} className='text-warning'>
                    Ao aceitar a negociação não garante que seu lote será
                    vendido, ele entrará em processo de confirmação da venda e
                    você será avisado sobre o processo. Se a venda for
                    confirmada você tem a obrigação de entregar a mercadoria
                    conforme{' '}
                    <a href='/'>
                      <b>termo de uso</b>
                    </a>
                  </Text>
                  <s.NegotiationButtons>
                    <Button
                      className='negotiation-buttons reject'
                      // onClick={() => rejectNegotiation(negotiation)}>
                      onClick={() => handleActionButtonClick('REJECT')}>
                      Recusar negociação
                    </Button>
                    <Button
                      className='negotiation-buttons accept'
                      // onClick={() => acceptNegotiation(negotiation)}>
                      onClick={() => handleActionButtonClick('ACCEPT')}>
                      Aceitar negociação
                    </Button>
                  </s.NegotiationButtons>
                </s.NegotiationDetail>
              </s.Card>
              <Help />
              <PasswordValidation
                opened={opened}
                handleClose={handleCloseAuthModal}
                successCallback={handleAuthSuccess}
                errorCallback={() => {
                  console.log('fail')
                }}
              />
            </React.Fragment>
          )
        } else {
          if (!negotiation.acceptance?.buyer) {
            return (
              <React.Fragment>
                <s.Card>
                  <s.CardHeader>
                    <Title>
                      Tudo certo por aqui, você já aceitou a negociação!
                    </Title>
                    <Text color={'gray'} fontSize={'14px'}>
                      Nessa etapa iremos validar com o comprador os detalhes
                      para confirmar a venda do seu lote. Não se preocupe, você
                      será informado quando tivermos a resposta!
                    </Text>
                  </s.CardHeader>
                  <s.NegotiationDetail>
                    <s.Values>
                      <s.ValueCard>
                        <Text>
                          Valor da <b>saca de soja</b> hoje
                        </Text>
                        <Text>{parseToMoneyBRL(negotiation.value)}</Text>
                      </s.ValueCard>
                      <s.ValueCard>
                        <Text>Valor total do lote</Text>
                        <Text>{parseToMoneyBRL(negotiation.totalValue)}</Text>
                      </s.ValueCard>
                    </s.Values>
                    <StepProgressBar steps={steps} currentStep={2} />
                  </s.NegotiationDetail>
                </s.Card>
                <Help />
              </React.Fragment>
            )
          } else {
            return (
              <React.Fragment>
                <s.Card>
                  <s.CardHeader>
                    <Title>Boas notícias, sua venda foi confirmada!</Title>
                    <Text color={'gray'} fontSize={'14px'}>
                      A venda do seu lote foi confirmada com sucesso! Nós
                      entraremos em contato pelo telefone{' '}
                      <b>+55 (55) 99957-6430</b> para combinarmos o carregamento
                      em sua propriedade
                    </Text>
                    <Text marginTop={'22px'} color={'defaultText'}>
                      Valor total do lote:{' '}
                      <b color={colors.green}>
                        {parseToMoneyBRL(negotiation.totalValue)}
                      </b>
                    </Text>
                  </s.CardHeader>
                  <s.NegotiationDetail>
                    <StepProgressBar steps={steps} currentStep={3} />
                    {negotiation.sellerCommercialInvoice && (
                      <s.Link
                        href={negotiation.sellerCommercialInvoice}
                        target='_blank'>
                        Baixar <b>Contrato de compra e venda</b>
                      </s.Link>
                    )}
                  </s.NegotiationDetail>
                </s.Card>
                <Help />
              </React.Fragment>
            )
          }
        }
      }

    case 'CHARGING':
      return <ChargingTab negotiation={negotiation} />

    case 'PAYMENT':
      return (
        <React.Fragment>
          <s.Card>
            <s.CardHeader>
              <Title>Situação do pagamento</Title>
              <Button className='btn-paid'>Pago</Button>
            </s.CardHeader>

            {negotiation.paymentReceipt && (
              <s.Link
                href={negotiation.paymentReceipt}
                target='_blank'>
                Baixar <b>Comprovante de pagamento</b>
              </s.Link>
            )}
          </s.Card>
          <Help />
        </React.Fragment>
      )

    default:
      return (
        <s.BatchDescription>
          <s.Row>
            <s.Column className='batch-description'>
              <Text>
                Fazenda <b>{batch.farm.name}</b>
              </Text>
              <Text>
                Quantidade total de <b>{batch.sacks} sacas</b> de{' '}
                <b>{batch.sacksKG} quilos</b>
              </Text>
              <Text>
                Tempo de oferta de <b>{batch.offerTime} dias úteis</b>
              </Text>
              <Text>
                Disponibilidade de entrega{' '}
                <b>{batch.daysToProvide} dias úteis</b>
              </Text>
              <Text>
                Valor mínimo de <b>{parseToMoneyBRL(batch.cost?.min)}</b>
              </Text>
              <Text>
                Valor máximo de <b>{parseToMoneyBRL(batch.cost?.max)}</b>
              </Text>
            </s.Column>
            <s.Column>
              <BankData
                bordered
                title='Conta para pagamento:'
                accounts={[batch.paymentAccount]}
                farmer={farmer}
                readOnly
              />
            </s.Column>
          </s.Row>
          <s.FooterButtons>
            <Button className='exclude' disabled>
              Excluir lote
            </Button>
            <Button className='close' onClick={close}>
              Fechar
            </Button>
          </s.FooterButtons>
        </s.BatchDescription>
      )
  }
}

export default Content

import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from './styles'
import Title from 'components/Title'
import Input from 'components/Input'
import ErrorAgent from 'components/ErrorAgent'
import Text from 'components/Text'
import Button from 'components/Button'
import logo from 'assets/logo.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import * as Yup from 'yup'
import { useFarmer } from '../../../modules/data/hooks/useFarmer'
import { useAuth } from '../../../modules/data/hooks/useAuth'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import routes from 'configuration/routes'

import messages from './messages'
import { useSelector } from 'react-redux'

export default function ConfirmPassword() {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const { farmer } = useFarmer()
  const user = useAuth()
  const { formatMessage } = useIntl()
  const userStore = useSelector(state => state.user)
  const firstRender = useRef(true)

  const nextStep = routes.farmer.verifyPhone
  const prevStep = routes.farmer.confirmEmail

  const handleNextStep = async () => {
    const schema = Yup.object().shape({
      password: Yup.string().required().min(6),
    })

    if (!(await schema.isValid({ password: password }))) {
      toast.warn(formatMessage(messages.size))
      return
    }

    if (!password) {
      toast.error(formatMessage(messages.invalid))
      return
    }

    if (password !== confirmPassword) {
      toast.warn(formatMessage(messages.equal))
      return
    }

    user.createAccount(farmer.email, password)
  }

  // if(user && user.isAnonymous) history.push(prevStep)

  useEffect(() => {
    console.warn(userStore)
    if(!firstRender.current) {
      if(userStore.success) {
        history.push(nextStep)
      } else {
        toast.error(formatMessage(messages.invalid))
      }
    } else {
      firstRender.current = false;
    }
  }, [userStore])

  return (
    <Container>
      <ErrorAgent
        group='userCreate'
        onError={e => setLoading(false)}
        onSuccess={e => history.push(nextStep)}
      />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Title className='title'>{formatMessage(messages.title)}</Title>
            <Text>{formatMessage(messages.subTitle)}</Text>
            <Input
              type='password'
              placeholder={formatMessage(messages.password)}
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              showPasswordToggle
            />
            <Input
              type='password'
              placeholder={formatMessage(messages.confirmPassword)}
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
              showPasswordToggle
            />

            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to={prevStep}>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to={prevStep}>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  loading={loading}
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

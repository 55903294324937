import styled from 'styled-components'
import SignInBackgroundImage from 'assets/SigninBackground.jpg'
import { colors } from 'styles'

export const Container = styled.div`
  height: 100%;
  flex: 1;
  background: url(${SignInBackgroundImage}) no-repeat top;
  background-size: 100% 50%;
  display: flex;
  justify-content: center;
`
export const Logo = styled.div`
  display: flex;
  justify-content: center;
`

export const FormContainer = styled.div`
  height: 100%;
  padding-top: 30px;

  form {
    img {
      margin-bottom: 10px;
    }

    width: 540px;

    background: ${colors.white};
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.31);
    border-radius: 5px;
    margin-top: 21px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
  }

  @media only screen and (max-width: 590px) {
    form {
      width: 400px;
    }
  }

  @media only screen and (max-width: 450px) {
    form {
      width: 350px;
    }
  }

  @media only screen and (max-width: 390px) {
    form {
      width: 320px;
    }
  }
`

export const Content = styled.div`
  width: 450px;

  div:not(:second-child) {
    margin-top: 40px;
  }
  margin-bottom: 30px;

  .title {
    font-weight: 500;
    text-align: center;
  }

  .info {
    line-height: 1.4;
    text-justify: auto;
    font-style: italic;
    color: ${colors.silver3};
  }

  @media only screen and (max-width: 590px) {
    width: 340px;
  }

  @media only screen and (max-width: 450px) {
    width: 330px;
  }

  @media only screen and (max-width: 390px) {
    width: 315px;
  }
`

export const Footer = styled.div`
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .resend {
    margin-bottom: 50px;
    font-style: italic;
    text-decoration: underline;
    color: ${colors.camarone2};
  }

  a {
    text-decoration: none;
  }
`

export const VerifyCode = styled.div`
  display: flex;
  justify-content: center;
`

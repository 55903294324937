import { SVGWithNotification } from 'components/SVG'
import SVGObject from 'components/SVG/Object'
import React from 'react'
import { useIntl } from 'react-intl'
import { NavLink, useHistory } from 'react-router-dom'

export default function SidebarButton({
  link,
  icon,
  titleLineOne,
  titleLineTwo,
  notifications,
  handleCloseMenu,
}) {
  const { formatMessage } = useIntl()
  const { location } = useHistory()
  const onClick = () => {
    if (location.pathname === link) {
      if (handleCloseMenu) handleCloseMenu()
    }
  }

  return (
    <NavLink
      exact
      activeClassName='navbar__link--active'
      to={link}
      onClick={onClick}>
      {!notifications ? (
        <SVGObject svg={icon} />
      ) : (
        <SVGWithNotification svg={icon} count={notifications} />
      )}
      <div className='option'>
        <span>
          {typeof titleLineOne === 'string'
            ? titleLineOne
            : formatMessage(titleLineOne)}
        </span>
        <strong>
          {typeof titleLineTwo === 'string'
            ? titleLineTwo
            : formatMessage(titleLineTwo)}
        </strong>
      </div>
    </NavLink>
  )
}

import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { getBatchStatusDescription, parseToMoneyBRL } from 'utils'

import Negotiation from '../Negotiation'

import { Tabs } from 'components/Tabs'

import * as s from './styles'

function BatchesList({ batches }) {
  const [selectedBatch, setSelectedBatch] = useState(null)
  const [negotiationOpen, setNegotiationOpen] = useState(false)
  const [filters, setFilters] = useState([
    { name: 'Todos', selected: true },
    { name: 'Aguardando negociação' },
    { name: 'Aguardando confirmação de venda' },
    { name: 'Vendido' },
    { name: 'Cancelado' },
  ])

  const { formatMessage } = useIntl()

  const handleClose = () => {
    setNegotiationOpen(false)
  }

  const openNegotiation = (batch) => {
    setSelectedBatch(batch)
    setNegotiationOpen(true)
  }

  const getBatchesByFilters = () => {
    if (filters[0].selected) {
      return batches
    } else if (filters[1].selected) {
      return batches.map((batch) => {
        if (batch.status === 'originatingOffer') return batch
        return false
      })
    } else if (filters[2].selected) {
      return batches.map((batch) => {
        if (batch.status === 'awaitingSaleConfirmation') return batch
        return false
      })
    } else if (filters[3].selected) {
      return batches.map((batch) => {
        if (
          batch.status !== 'originatingOffer' &&
          batch.status !== 'awaitingSaleConfirmation' &&
          batch.status !== 'canceled'
        )
          return batch
        return false
      })
    } else if (filters[4].selected) {
      return batches.map((batch) => {
        if (batch.status === 'canceled') return batch
        return false
      })
    }
  }

  return (
    <React.Fragment>
      <Negotiation
        open={negotiationOpen}
        close={handleClose}
        batch={selectedBatch}
      />
      <Tabs tabs={filters} setTabs={setFilters} mobile />
      <s.List>
        {getBatchesByFilters().map(
          (batch, index) =>
            batch && (
              <s.Item
                status={batch.status}
                onClick={() => openNegotiation(batch)}
                key={index}>
                <s.Img src={batch.product.url_negotiation} />
                <s.Content>
                  <s.Header>
                    <s.Title>{batch.product.name}</s.Title>
                    <s.Text fontSize='10px' italic>
                      de {batch.farm.name}
                    </s.Text>
                    <s.StatusHeader>
                      {batch.status !== 'canceled' ? 'Vendido' : 'Cancelado'}
                    </s.StatusHeader>
                  </s.Header>
                  <s.Infos>
                    <s.Text defaultColor fontSize='16px'>
                      <b>{batch.sacks}</b> sacas
                    </s.Text>
                    <s.Time>
                      <s.Text italic>Tempo restante: </s.Text>
                      <s.Text defaultColor fontSize='14px'>
                        <b>{batch.expiration} dias</b>
                      </s.Text>
                    </s.Time>
                  </s.Infos>
                  <s.Text italic>
                    de <b>{parseToMoneyBRL(batch.cost?.min)}</b> à{' '}
                    <b>{parseToMoneyBRL(batch.cost?.max)}</b>{' '}
                  </s.Text>
                  <s.StatusFooter>
                    <s.Text color='white' italic>
                      {formatMessage(getBatchStatusDescription(batch.status))}
                    </s.Text>
                  </s.StatusFooter>
                </s.Content>
                <s.StatusIcon />
              </s.Item>
            )
        )}
      </s.List>
    </React.Fragment>
  )
}

export default BatchesList

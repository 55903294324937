import styled from 'styled-components'
import { device } from 'styles'

export const Message = styled.div`
  background-color: #c4acab;
  border: 1px solid #c64e4e;
  box-sizing: border-box;
  border-radius: 10px;
  color: #c64e4e;
  position: fixed;
  padding: 20px;
  z-index: 999999;

  @media ${device.untilTablet} {
	padding: 0 10px;
  }

  ${(props) =>
    props.mode === 'banner' &&
    `
		width: 98%;
		height: 80px;
		top: 1%;
		left: 1%;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;

		-ms-flex-align: center;
		-webkit-align-items: center;
		-webkit-box-align: center;

		align-items: center;

		.error-img {
			background-color: white;
			border-radius: 50%;
			height: 50px;
			width: 50px;
			display: flex;
			margin-right: 20px;

			img {
				width: 70%;
				height: 70%;
				display: block;
				margin: auto;
			}
		}
	`}

  ${(props) =>
    props.mode === 'popup' &&
    `
		width: 400px;
		height: 200px;
	`}

	${(props) =>
    props.mode === 'dialog' &&
    `
		width: 30vw;
		height: 60vh;
		left: calc(50% - 15vw);
		top: calc(50% - 40vh);
		min-width: 300px;

		@media only screen and (max-width: 590px) {
			left: calc(50% - 150px);
		}

		justify-content: center;
		align-items: center;
		text-align: center;



		.error-img {
			background-color: white;
			border-radius: 50%;
			width: 100px;
			height: 100px;
			display: flex;
			margin-right: 20px;
			margin: auto;

			img {
				width: 70%;
				height: 70%;
				display: block;
				margin: auto;
			}
		}

		p {
			margin-top: 20px;
			margin-bottom: 20px;
			color: #565656;
			font-weight: normal;
		}

		h1 {
			margin-top: 30px;
		}
	`}

	.close {
    position: absolute;
    top: 10px;
    right: 10px;
    content: '✕';
    font-size: 18pt;
    cursor: pointer;
  }
  .light {
    color: white;
  }
  .dark {
    color: #565656;
  }
`

import React from 'react'
import { Container, Logo, FormContainer, Content, Footer } from './styles'
import Title from 'components/Title'
import Text from 'components/Text'
// import Input from "components/Input";
import logo from 'assets/logo.svg'
import { Link } from 'react-router-dom'
import messages from './messages'
import { useIntl } from 'react-intl'

export default function VerifyEmail() {
  const { formatMessage } = useIntl()
  return (
    <Container>
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Title className='title' color='primary'>
              {formatMessage(messages.title)}
            </Title>
            <Text className='info'>{formatMessage(messages.info)}</Text>
            <Footer>
              <Link to='/'>
                <Text color='primary'>{formatMessage(messages.back)}</Text>
              </Link>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

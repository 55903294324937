import './styles.css'
import * as s from './style'
import moment from 'moment'

const logoUrl = 'https://firebasestorage.googleapis.com/v0/b/agricon-dev.appspot.com/o/mail%2Flogo_branco.png?alt=media&token=8698c664-6b54-499b-8b97-5267eaefef7a'

const farmUrl = 'https://firebasestorage.googleapis.com/v0/b/agricon-dev.appspot.com/o/mail%2Ffarm.png?alt=media&token=87667c9a-f382-48b8-9ec9-8280b21bcd85'

const envelope = message => {
	return `
<!doctype html><html><head><title>Agricon</title></head><body>
	<div style='${s.topbar}'><img style='${s.logo}' src='${logoUrl}'></div>
	<div style='${s.divbar}'/>
	<div style='${s.body}'>
		${message}
		<p><strong>Um grande abraço, Equipe Agricon!</strong></p>
	</div>
	<div style='${s.divbar}'/>
	<div style='${s.footer}'>
		<a style='${s.url}' href='www.agriconbusiness.com'>www.agriconbusiness.com</a>
	</div>
</body>
</html>
	`
}


export const welcomeFarmer = user => {
	return envelope(`
	<h1 style='${s.green}'>Olá, ${user.name}</h1>
	<h1 style='${s.subtitle}'>Bem-Vindo(a)</h1>
	<p>Ficamos muito felizes com seu interesse em fazer parte da Agricon!</p>
	<div style='${s.account}'>
		<p>
			Estes são seus dados de aceso,<br/>
			<span style='${s.green}'>Login: ${user.email}<br/>
			Sua senha está protegida e nem nós mesmos temos acesso
		</p>
	</div>
	<p>Mas antes de começar a cadastrar e a negociar seus lotes, precisamos que você nos conte um pouco mais sobre você e sua propriedade rural.</p>

	<a style='${s.button}' href='${window.location.hostname}'>
		<img src='${farmUrl}' />
		Detalhar Propriedade
	</a>
 `)
}

export const welcomeBuyer = user => {
	return envelope(`
	<h1 style='${s.green}'>Olá, ${user.name}</h1>
	<h1 style='${s.subtitle}'>Bem-Vindo(a)</h1>
	<p>Ficamos muito felizes com seu interesse em fazer parte da Agricon!</p>
	<div style='${s.account}'>
		<p>
			Estes são seus dados de aceso,<br/>
			<span style='${s.green}'>Login: ${user.email}<br/>
			Sua senha está protegida e nem nós mesmos temos acesso
		</p>
	</div>
	<p><strong>Seu cadastro está sendo analisado. Quando for aprovado, você receberá um e-mail e poderá acessar a plataforma.</strong></p>

	<p>Qualquer dúvida é só entrar em contato pelo e-mail: contato@agriconbusiness.com</p>
	`)
}

export const inspectionRequested = user => {
	return envelope(`
		<h1 style='${s.green}'>Olá, ${user.name}</h1>
		<div style='${s.account}'>
			<p>
				Sua visita técnica foi agendada!<br/>
				Logo entraremos em contato pelo telefone para confirmar o agendamento.
			</p>
		</div>
	`)
}

export const inspectionScheduled = (user, inspection) => {
	const dateLabel = moment(inspection.week).format("DD/MM/YYYY")
	const turn = { 1: 'manhã', 2: 'tarde'}
	return envelope(`
		<h1 style='${s.green}'>Olá, ${user.name}</h1>
		<p>Sua visita técnica está confirmada!</p>
		<div style='${s.account}'>
			<p>
				<strong>Data: ${dateLabel}<br/>
				Turno: ${turn[inspection.turn]}</strong>
			</p>
		</div>
	`)
}


export const newRegistration = (user, type) => {
	
	return envelope(`
		<h1 style='${s.green}'>Novo cadastro de ${type} realizado</h1>
		<div style='${s.account}'>
			<p>
				Os dados de contato do cliente estão abaixo:<br/>
				<span style='${s.green}'>Nome: ${user.name}<br/>
				<span style='${s.green}'>Email: ${user.email}<br/>
				<span style='${s.green}'>Telefone: ${user.phoneNumber}<br/>
			</p>
		</div>
	`)
}
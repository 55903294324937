import React from 'react'
import { Route, Switch } from 'react-router-dom'
import WelcomeFarmer from 'views/Farmer/WelcomeFarmer'
import RegisterName from 'views/Farmer/RegisterName'
import RegisterData from 'views/Farmer/RegisterData'
import VerifyPhoneNumber from 'views/Farmer/VerifyPhoneNumber'
import ConfirmEmail from 'views/Farmer/ConfirmEmail'
import ConfirmPassword from 'views/Farmer/ConfirmPassword'
import Terms from 'views/Farmer/Terms'

import { routes } from 'configuration'

export default function FarmerRoutes() {
  return (
    <Switch>
      <Route path={routes.farmer.welcome}>
        <WelcomeFarmer />
      </Route>
      <Route path={routes.farmer.registerName}>
        <RegisterName />
      </Route>
      <Route path={routes.farmer.registerData}>
        <RegisterData />
      </Route>
      <Route path={routes.farmer.confirmEmail}>
        <ConfirmEmail />
      </Route>
      <Route path={routes.farmer.confirmPassword}>
        <ConfirmPassword />
      </Route>
      <Route path={routes.farmer.verifyPhone}>
        <VerifyPhoneNumber />
      </Route>
      <Route path={routes.farmer.terms}>
        <Terms />
      </Route>
    </Switch>
  )
}

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'buyerDone.title',
    defaultMessage: 'Tudo pronto!',
  },
  subTitle: {
    id: 'buyerDone.text.subTitle',
    defaultMessage:
      'Bem-vindo(a) ao novo ecossistema tecnológico e sustentável, conectando produtors rurais a compradores globais!',
  },
  back: {
    id: 'buyerDone.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'buyerDone.button.continue',
    defaultMessage: 'Entrar na plataforma',
  },
})

export default messages

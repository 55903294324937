export const FARMER_COLLECTION = 'farmers'
export const BUYER_COLLECTION = 'buyers'
export const USER_COLLECTION = 'users'
export const PRODUCT_COLLECTION = 'products'
export const PARAMS_COLLECTION = 'params'
export const LOI_COLLECTION = 'lois'
export const BATCH_COLLECTION = 'batches'
export const FARM_COLLECTION = 'farms'
export const INSPECTION_COLLECTION = 'inspections'
export const MAIL_COLLECTION = 'mail'
export const PORT_COLLECTION = 'ports'
export const TERMS_COLLECTION = 'terms'
export const NEGOTIATIONS_COLLECTION = 'negotiations'
export const SHIPMENT_COLLECTION = 'shipments'
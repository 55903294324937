import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from './styles'
import Title from 'components/Title'
import Input from 'components/Input'

import Text from 'components/Text'
import Button from 'components/Button'
import logo from 'assets/logo-green.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useIntl } from 'react-intl'
import messages from './messages'
import { toast } from 'react-toastify'

export default function ConfirmEmail() {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const { addFeature, buyer } = useBuyer()
  const { formatMessage } = useIntl()

  useEffect( () => {
    if(buyer && buyer.email) setEmail(buyer.email)
  }, [ buyer ])

  const handleNextStep = () => {
    if(!email || !confirmEmail) {
      toast.error(formatMessage(messages.errorRequired))
      return
    }
    if(email !== confirmEmail) {
      toast.error(formatMessage(messages.errorMismatch))
      return
    }
    addFeature({ email })
    history.push('/buyer-step-password')
  }

  return (
    <Container>
      <div className='bg-image' />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Title className='title'>{formatMessage(messages.title)}</Title>
            <Text>{formatMessage(messages.subTitle)}</Text>
            <Input
              type='email'
              placeholder={formatMessage(messages.email)}
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
            <Input
              type='email'
              placeholder={formatMessage(messages.confirmEmail)}
              value={confirmEmail}
              onChange={({ target }) => setConfirmEmail(target.value)}
            />

            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to='/buyer-step-details-3'>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to='/buyer-step-details-3'>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'accounts.title',
    defaultMessage: 'Contas para pagamento',
  },
  addAccount: {
    id: 'accounts.button.addAccount',
    defaultMessage: 'Adicionar conta',
  },
})

export default messages

import styled, { css } from 'styled-components'
import { colors, fonts } from 'styles'

export const Button = styled.button`
  font-family: ${fonts.primary};
  font-size: ${({ fontSize }) => fontSize || '18px'};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '50px'};
  background: ${({ background }) => colors[background] || colors.primary};
  color: ${({ color }) => colors[color] || colors.white};
  border-radius: 5px;
  border: none;
  max-width: ${({ maxWidth }) => maxWidth || '350px'};
  display: ${({ display }) => display || 'flex'};
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`

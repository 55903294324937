import React, { useState, useEffect } from 'react'
import {
  Container,
} from './styles'
import Text from 'components/Text'
import ErrorAgent from 'components/ErrorAgent'
import { VerifyCode } from 'components/VerifyCode'

import { usePhoneVerifier } from 'modules/data/hooks/usePhoneVerifier';
import { useIntl } from 'react-intl'
import messages from './messages'

export default function PhoneModal(props) {
	const [codeRequested, setCodeRequested] = useState(false)
	const verification = usePhoneVerifier(props.submitId)
	const { formatMessage } = useIntl()

	useEffect( () => {
		verification.init()
		setCodeRequested(true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ ])

	useEffect( () => {
		if(props.phone && codeRequested) {
			verification.request(props.phone);
			setCodeRequested(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeRequested, props.phone])

	const handleChange = val => {
		if(val.length === 6 && props.phone) {
			props.onLoading(true)
			verification.confirm(val);
			if(props.onCode) props.onCode(val)
		}
		else {
			props.onLoading(false)
		}
	}

	const resend = () => {
		if(!verification.recaptcha) {
			verification.init()
		}
		verification.request(props.phone);
	}

	if(verification.success) props.onSuccess()

	const notifyError = error => {
		if(error.code === 'vierification-id-missing') {
			verification.init()
		}
		props.onLoading(false)
		props.onError(error)
	}

	return (
		<Container>
			<ErrorAgent
				group='phoneValidation'
				onError={e => notifyError(e)}
				onClear={e => props.onLoading(false)}
			/>
			<ErrorAgent
				group='userCreate'
				onError={e => notifyError(e)}
				onClear={e => props.onLoading(false)}
			/>
			<VerifyCode onChange={handleChange}/>
			<a href="" className="resend" onClick={resend}>
				<Text color="secondary">{formatMessage(messages.resend)}</Text>
			</a>
		</Container>
	)
}
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'loi.form.title',
    defaultMessage: 'Cadastro de LOI',
  },
  subTitle: {
    id: 'loi.form.subTitle',
    defaultMessage:
      'Cadastre suas LOIs para que nossa plataforma encontre a melhor oferta para você',
  },
  productSelection: {
    id: 'loi.form.productSelection',
    defaultMessage: 'Escolha o produto que deseja comprar:',
  },
  productDetails: {
    id: 'loi.form.productDetails',
    defaultMessage: 'Detalhes do produto',
  },
  quantity: {
    id: 'loi.form.quantity',
    defaultMessage: 'Quantidade:',
  },
  tons: {
    id: 'loi.form.tons',
    defaultMessage: 'Toneladas',
  },
  packageType: {
    id: 'loi.form.packageType',
    defaultMessage: 'Tipo da embalagem:',
  },
  amountPerPackage: {
    id: 'loi.form.amountPerPackage',
    defaultMessage: 'Quantidade de quilos por embalagem:',
  },
  targetPrice: {
    id: 'loi.form.targetPrice',
    defaultMessage: 'Preço alvo por tonelada:',
  },
  offerTime: {
    id: 'loi.form.offerTime',
    defaultMessage: 'Tempo de oferta:',
  },
  daysBy: {
    id: 'loi.form.daysBy',
    defaultMessage: 'dias corridos',
  },
  country: {
    id: 'loi.form.country',
    defaultMessage: 'País de entrega:',
  },
  port: {
    id: 'loi.form.port',
    defaultMessage: 'Porto de destino:',
  },
  selectAddress: {
    id: 'loi.form.selectAddress',
    defaultMessage: 'Selecione um endereço:',
  },
  anotherAddress: {
    id: 'loi.form.anotherAddress',
    defaultMessage: 'Enviar para outro endereço',
  },
  noAddress: {
    id: 'loi.form.noAddress',
    defaultMessage: 'Nenhum endereço cadastrado',
  },
  selectContractType: {
    id: 'loi.form.selectContractType',
    defaultMessage: 'Selecione o tipo de contrato:',
  },
  contractType: {
    id: 'loi.form.contractType',
    defaultMessage: 'Contrato',
  },
  cif: {
    id: 'loi.form.cif',
    defaultMessage: 'CIF',
  },
  cifDescription: {
    id: 'loi.form.cifDescription',
    defaultMessage:
      'O exportador (Agricon Business), deve entregar a mercadoria a bordo do navio, no porto de embarque, com frete e seguro pagos. A responsabilidade do exportador cessa no momento em que o produto cruza a amurada do navio no porto de destino.',
  },
  crf: {
    id: 'loi.form.crf',
    defaultMessage: 'CFR',
  },
  crfDescription: {
    id: 'loi.form.crfDescription',
    defaultMessage:
      'O exportador (Agricon Business), deve entregar a mercadoria a bordo do navio, no porto de embarque, com frete e sem seguro. A responsabilidade do exportador  cessa no momento em que o produto cruza a amurada do navio no porto de destino.',
  },
  fob: {
    id: 'loi.form.fob',
    defaultMessage: 'FOB',
  },
  fobDescription: {
    id: 'loi.form.fobDescription',
    defaultMessage:
      'O exportador (Agricon Business), deve entregar a mercadoria, desembaraçada, a bordo do navio indicado pelo importador, no porto de embarque. Todas as despesas, até o momento em que o produto é colocado a bordo do veículo transportador, são da responsabilidade do exportador.',
  },
  transportation: {
    id: 'loi.form.transportation',
    defaultMessage: 'Selecione o método de transporte:',
  },
  truck: {
    id: 'loi.form.truck',
    defaultMessage: 'Carreta',
  },
  transferThirtySeventy: {
    id: 'loi.form.transferThirtySeventy',
    defaultMessage: 'Transferência bancária 30/70',
  },
  transferTotal: {
    id: 'loi.form.transferTotal',
    defaultMessage: 'Transferência bancária total (antecipado)',
  },
  lcThirtySeventy: {
    id: 'loi.form.lcThirtySeventy',
    defaultMessage: 'Carta de Crédito (LC) 30/70',
  },
  lcTotal: {
    id: 'loi.form.lcTotal',
    defaultMessage: 'Carta de Crédito (LC) (antecipado) ',
  },
  container: {
    id: 'loi.form.container',
    defaultMessage: 'Contêiner 40`',
  },
  paymentSelection: {
    id: 'loi.form.paymentSelection',
    defaultMessage: 'Selecione a forma de pagamento',
  },
  addLoi: {
    id: 'loi.form.addLoi',
    defaultMessage: 'Cadastrar loi:',
  },
  ok: {
    id: 'loi.form.ok',
    defaultMessage: 'Concluir',
  },
  cancel: {
    id: 'loi.form.cancel',
    defaultMessage: 'Cancelar',
  },
  granel: {
    id: 'loi.form.granel',
    defaultMessage: 'Granel (bulkliner)',
  },
  bigBag: {
    id: 'loi.form.bigBag',
    defaultMessage: 'Big Bag',
  },
  sacks: {
    id: 'loi.form.sacks',
    defaultMessage: 'Sacas',
  },
})

export default messages

import React, { useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print';

import Title from 'components/Title'
import Text from 'components/Text'
import Button from 'components/Button'
import SVG from 'components/SVG'

import messages from './messages'

import { parseMoneyToCurrency } from 'utils'

import * as s from './styles'
import { useIntl } from 'react-intl'
import { colors } from 'styles'
import { useNegotiation } from 'modules/data/hooks/useNegotiation'
// import Upload from 'components/Upload'
import { Backdrop, Fade, makeStyles, Modal } from '@material-ui/core'
import PasswordValidation from 'components/PasswordValidation'

export function Help() {
  return (
    <Text className='batch-help'>
      In case of doubt check our <a href='/'>help center</a>
    </Text>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

function Content({ loi, tab, close, negotiation }) {
  const { formatMessage } = useIntl()
  const { acceptNegotiation, rejectNegotiation } = useNegotiation()
  const [paymentInstructions, setPaymentInstructions] = useState(false)
  const [opened, setOpened] = useState(false)
  const [action, setAction] = useState()
  const classes = useStyles()

  const handlePaymentInstructionsClick = () => {
    setPaymentInstructions(true)
  }

  const handleClose = () => {
    setPaymentInstructions(false)
  }

  const handleCloseAuthModal = () => {
    setOpened(false)
  }

  const handleActionButtonClick = (action) => {
    setOpened(true)
    setAction(action)
  }

  const handleAuthSuccess = () => {
    switch (action) {
      case 'ACCEPT':
        acceptNegotiation(negotiation)
        break
      case 'REJECT':
        rejectNegotiation(negotiation)
        break
      default:
        break
    }

    setOpened(false)
  }

  const ModalPaymentInstructions = () => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={paymentInstructions}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        ref={componentRef}>
        <Fade in={paymentInstructions}>
          <s.ModalContainer>
            <s.ModalHeader>
              <Text fontWeight='bold'>Payment information</Text>
            </s.ModalHeader>

            <s.ModalSection>
              <Text textAlign='center' fontWeight='bold' color='apple'>
                Swift Message Type: 103
              </Text>
            </s.ModalSection>

            <s.ModalSection>
              <Text fontWeight='bold'>
                Correspondent Bank / Intermediary Bank
              </Text>

              <Text fontSize='14px'>
                Citibank, N. A.
                <br />
                New York - NY - USA
                <br />
                ABA: 021000089
                <br />
                SWIFT Code: CITIUS33
              </Text>
            </s.ModalSection>

            <s.ModalSection>
              <Text fontWeight='bold'>Beneficiary`s Bank</Text>

              <Text fontSize='14px'>
                Banco Cooperativo SICREDI
                <br />
                Porto Alegre - RS
                <br />
                Account: 36369424
                <br />
                SWIFT Code: BCSIBRRS
              </Text>
            </s.ModalSection>

            <s.ModalSection>
              <Text fontWeight='bold'>Beneficiary (For final credit to)</Text>

              <Text fontSize='14px'>
                ACC (account n): BRS401181521001160000344127C2
                <br />
                Beneficiary name: Agricon Business LTDA
                <br />
                Beneficiary address: Street Felipe Becker, n 485 Porto Alegre,
                Brasil
              </Text>
            </s.ModalSection>
            <s.ButtonContainer>
              <Button onClick={handlePrint}>Print</Button>
            </s.ButtonContainer>
          </s.ModalContainer>
        </Fade>
      </Modal>
    )
  }

  const PaymentTab = ({ smartContract }) => {
    const handleDraftButtonClick = () => {
      openLink(negotiation.buyerCommercialInvoice)
    }

    const handleContractButtonClick = () => {
      openLink(negotiation.smartContract)
    }

    const openLink = (url) => {
      window.open(url, '_blank', 'noreferrer')
    }

    return (
      <React.Fragment>
        <s.Card padding='25px 0'>
          <s.CardHeader>
            <Text fontWeight='700 !important' fontSize='20px !important'>
              {smartContract
                ? 'Smart contract'
                : negotiation?.acceptance &&
                  negotiation.acceptance.buyer &&
                  negotiation.acceptance.farmer
                ? 'Contract draft!  '
                : 'You have a trading interest!'}
            </Text>
          </s.CardHeader>
          <s.CardBody direction='row'>
            <s.CardBodyLeft backgroundColor={'dorado'}>
              <s.Section>
                <Text lineHeight='24px' color={'silverChalice6'}>
                  {formatMessage(messages.country)}
                </Text>
                <Text fontWeight='700' color={'white'}>
                  {loi.delivery.port.country}
                </Text>
              </s.Section>
              <s.Section>
                <Text lineHeight='24px' color={'silverChalice6'}>
                  {formatMessage(messages.port)}
                </Text>
                <Text fontWeight='700' color={'white'}>
                  {loi.delivery.port.name}
                </Text>
              </s.Section>

              <s.Section>
                <Text lineHeight='24px' color={'silverChalice6'}>
                  {formatMessage(messages.targetPrice)}
                </Text>
                <Text fontWeight='700' color={'white'}>
                  {parseMoneyToCurrency(loi.targetPricePerTon, 'USD')}
                </Text>
              </s.Section>

              <s.Section>
                <Text lineHeight='24px' color={'silverChalice6'}>
                  Total value
                </Text>
                <Text fontWeight='700' color={'white'}>
                  {parseMoneyToCurrency(
                    loi.targetPricePerTon * loi.tons,
                    'USD'
                  )}
                </Text>
              </s.Section>
              <s.NegotiationButtons margin='15px 0 10px 0'>
                {smartContract || negotiation.acceptance?.buyer ? (
                  <>
                    <Button
                      background='bostonBlue'
                      display='block'
                      width='115px'
                      fontSize='14px'
                      onClick={handleDraftButtonClick}
                      className='negotiation-buttons accept'
                      disabled={!negotiation.buyerCommercialInvoice}>
                      <b>Draft Contract</b> <br />
                      Download
                    </Button>
                    <Button
                      display='block'
                      background='bostonBlue'
                      width='115px'
                      className='negotiation-buttons'
                      fontSize='14px'
                      onClick={handleContractButtonClick}
                      disabled={!smartContract || !negotiation.smartContract}>
                      <b>Smart Contract</b> <br />
                      Download
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      display='block'
                      width='115px'
                      className='negotiation-buttons accept'
                      fontSize='14px'
                      // onClick={() => acceptNegotiation(negotiation)}
                      onClick={() => handleActionButtonClick('ACCEPT')}>
                      <b>Accept</b> <br />
                      negotiation
                    </Button>
                    <Button
                      display='block'
                      width='115px'
                      fontSize='14px'
                      className='negotiation-buttons reject'
                      // onClick={() => rejectNegotiation(negotiation)}
                      onClick={() => handleActionButtonClick('REJECT')}>
                      <b>Reject</b> <br />
                      negotiation
                    </Button>
                  </>
                )}
              </s.NegotiationButtons>
            </s.CardBodyLeft>
            <s.CardBodyRight>
              {smartContract ? (
                <>
                  <s.Section>
                    <Text textAlign='center' color={'apple'} fontWeight='700'>
                      Congratulations!
                    </Text>
                  </s.Section>

                  <s.Section margin='7px 0'>
                    <Text
                      textAlign='center'
                      fontSize='14px'
                      color={'silverChalice6'}>
                      Your smart Contract has just been registered on our
                      Blockchain network.
                    </Text>
                  </s.Section>

                  <s.Section margin='7px 0'>
                    <Text
                      textAlign='center'
                      fontSize='14px'
                      color={'silverChalice6'}>
                      Access and obtain all the{' '}
                      <b style={{ color: colors.silverChalice6 }}>
                        origin and traceability information of your product.
                      </b>
                    </Text>
                  </s.Section>

                  <s.Section margin='7px 0'>
                    <Text
                      textAlign='center'
                      fontSize='14px'
                      color={'silverChalice6'}>
                      From now on you will be informed of the tracking of the
                      operation in real time.
                    </Text>
                  </s.Section>
                </>
              ) : (
                <>
                  <s.Section>
                    <Text color={'silverChalice6'}>
                      Volume: <b>{loi.transportationType.description}</b>
                    </Text>
                  </s.Section>

                  <s.Section>
                    <Text color={'silverChalice6'}>
                      {formatMessage(messages.packageType)}{' '}
                      <b>{loi.packing.type.description}</b>
                    </Text>
                  </s.Section>

                  <s.Section>
                    <Text color={'silverChalice6'}>
                      Inspection: <b>{negotiation.inspection}</b>
                    </Text>
                  </s.Section>

                  {loi.contractType.type !== 'CIF' && (
                    <s.Section>
                      <Text color={'silverChalice6'}>
                        Free time: <b>{negotiation.freetime}</b>
                      </Text>
                    </s.Section>
                  )}

                  <s.Section>
                    <Text color={'silverChalice6'}>
                      {formatMessage(messages.port)}{' '}
                      <b>{loi.delivery.port.name}</b>
                    </Text>
                  </s.Section>

                  <s.Section>
                    <Text color={'silverChalice6'}>
                      Shipping port: <b>{negotiation.shippingPort}</b>
                    </Text>
                  </s.Section>

                  <s.Section>
                    <Text color={'silverChalice6'}>
                      Loading forecast: <b>{negotiation.loadDate}</b>
                    </Text>
                  </s.Section>

                  <s.Section>
                    <Text color={'silverChalice6'}>
                      {formatMessage(messages.contractType)}{' '}
                      <b>{loi.contractType.type}</b>
                    </Text>
                  </s.Section>

                  <s.Section>
                    <Text color={'silverChalice6'}>
                      Expected delivery: <b>{negotiation.deliveryDate}</b>
                    </Text>
                  </s.Section>
                </>
              )}
            </s.CardBodyRight>
          </s.CardBody>
        </s.Card>
        <Help />
        <PasswordValidation
          opened={opened}
          handleClose={() => handleCloseAuthModal()}
          successCallback={handleAuthSuccess}
          errorCallback={() => {
            console.log('fail')
          }}
        />
      </React.Fragment>
    )
  }

  switch (tab.slug) {
    case 'NEGOTIATION':
      if (!negotiation)
        return (
          <React.Fragment>
            <s.Card>
              <s.CardHeader>
                <Title>{formatMessage(messages.originatingOfferTitle)}</Title>
                <Text>{formatMessage(messages.originatingLoiText)}</Text>
              </s.CardHeader>
              <SVG.Clock className='clock' />
            </s.Card>
            <Text className='loi-help'>
              {formatMessage(messages.inCaseOfDoubt)}{' '}
              <a
                style={{ color: colors.apple }}
                href='https://api.whatsapp.com/send?phone=+5551991634737'
                target='_blank'
                rel='noopener noreferrer'>
                {formatMessage(messages.helpCenter)}
              </a>
            </Text>
          </React.Fragment>
        )
      else {
        if (!negotiation.acceptance?.buyer) {
          return (
            // <React.Fragment>
            //   <s.Card>
            //     <s.CardHeader>
            //       <Title>Your LOI has been originated</Title>
            //     </s.CardHeader>
            //     <s.NegotiationDetail>
            //       <s.Values>
            //         <s.ValueCard>
            //           <Text>
            //             Product <b>ton value</b>
            //           </Text>
            //           <Text className='value'>
            //             {parseToMoneyBRL(negotiation.value)}
            //           </Text>
            //         </s.ValueCard>
            //         <s.ValueCard>
            //           <Text>Total value of LOI</Text>{' '}
            //           <Text className='value'>
            //             {parseToMoneyBRL(negotiation.totalValue)}
            //           </Text>
            //         </s.ValueCard>
            //       </s.Values>
            //       <Text textAlign={'center'}>
            //         Estimated delivery: <b>40 days</b>
            //       </Text>
            //       <Text textAlign={'center'}>
            //         {formatMessage(messages.paymentType)}{' '}
            //         <b>{loi.paymentType.type}</b>
            //       </Text>
            //       <s.NegotiationButtons>
            //         <Button
            //           className='negotiation-buttons reject'
            //           onClick={() => rejectNegotiation(negotiation)}>
            //           Reject negotiation
            //         </Button>
            //         <Button
            //           className='negotiation-buttons accept'
            //           onClick={() => acceptNegotiation(negotiation)}>
            //           Accept negotiation
            //         </Button>
            //       </s.NegotiationButtons>
            //     </s.NegotiationDetail>
            //   </s.Card>
            //   <Help />
            // </React.Fragment>

            <PaymentTab />
          )
        } else {
          return (
            // <React.Fragment>
            //   <s.Card>
            //     <s.CardHeader>
            //       <Title>Product originated successfully!</Title>
            //     </s.CardHeader>
            //     <s.NegotiationDetail justifyContent={'space-between'}>
            //       <Text textAlign={'center'}>
            //         We sent the Commercial Invoice to your email, but you can
            //         also access it via button below
            //       </Text>
            //       {negotiation.buyerCommercialInvoice && (
            //         <s.Link
            //           href={negotiation.buyerCommercialInvoice}
            //           target='_blank'>
            //           Download <b>Commercial Invoice</b>
            //         </s.Link>
            //       )}
            //     </s.NegotiationDetail>
            //   </s.Card>
            //   <Help />
            // </React.Fragment>
            <PaymentTab />
          )
        }
      }

    case 'PAYMENT':
      return (
        <React.Fragment>
          <s.Card>
            <s.CardHeader>
              <Title>Payment instructions</Title>
            </s.CardHeader>
            <s.CardBody alignItems='center' justifyContent='space-between'>
              <Text color='gray' fontWeight='700'>
                Access financial institutions{' '}
                <s.LinkButton onClick={handlePaymentInstructionsClick}>
                  HERE
                </s.LinkButton>
                .
              </Text>
              <Text>
                You must contact your financial institution and initiate payment
                protocols.
              </Text>

              <Text color='apple'>The payment method chosen was SBLC.</Text>

              <SVG.Clock width={64} height={64} />
            </s.CardBody>
          </s.Card>
          <Help />
          <ModalPaymentInstructions />
        </React.Fragment>
      )
    case 'SMARTCONTRACT':
      return <PaymentTab smartContract />

    default:
      return (
        <s.LoiDescription>
          <s.Row>
            <s.Column className='loi-description'>
              <Text>
                {formatMessage(messages.quantity)}{' '}
                <b>
                  {loi.tons} {formatMessage(messages.tons)}{' '}
                  {loi.packing.type.description}
                </b>
              </Text>
              <Text>
                {formatMessage(messages.packageType)}{' '}
                <b>
                  {loi.packing.kgPerUnit ? loi.packing.kgPerUnit : null}{' '}
                  {loi.packing.type.description}
                </b>
              </Text>
              <Text>
                {formatMessage(messages.offerTime)}{' '}
                <b>
                  {loi.offerTime} {formatMessage(messages.daysBy)}
                </b>
              </Text>
              <Text>
                {formatMessage(messages.targetPrice)}{' '}
                <b>{parseMoneyToCurrency(loi.targetPricePerTon, 'USD')}</b>
              </Text>
              <Text>
                {formatMessage(messages.contractType)}{' '}
                <b>{loi.contractType.type}</b>
              </Text>
              <Text>
                {formatMessage(messages.paymentType)}{' '}
                <b>{loi.paymentType.type}</b>
              </Text>
            </s.Column>
            <s.Column>
              <s.Destination>
                {loi.delivery.port ? (
                  <React.Fragment>
                    <Text color={'silverChalice6'}>
                      {formatMessage(messages.country)}
                    </Text>
                    <s.TitlePorts>{loi.delivery.port.country}</s.TitlePorts>
                    <Text color={'silverChalice6'}>
                      {formatMessage(messages.port)}
                    </Text>
                    <s.TitlePorts>{loi.delivery.port.name}</s.TitlePorts>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Text color={'silverChalice6'}>
                      {formatMessage(messages.address)}
                    </Text>
                    <s.TitleAddress>{loi.address.description}</s.TitleAddress>
                    <Text color={'silverChalice6'}>
                      {loi.address.formatted_address}
                    </Text>
                  </React.Fragment>
                )}
              </s.Destination>
            </s.Column>
          </s.Row>
          <s.FooterButtons>
            <Button className='exclude' disabled>
              {formatMessage(messages.deleteLoi)}
            </Button>
            <Button className='close' onClick={close}>
              {formatMessage(messages.close)}
            </Button>
          </s.FooterButtons>
        </s.LoiDescription>
      )
  }
}

export default Content

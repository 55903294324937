import styled from 'styled-components'
import { colors, device } from 'styles'


export const ModalContainer = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  width: 650px;
  height: 550px;
  max-height: 95%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  

  @media ${device.untilTablet} {
    width: 90%;
    overflow: auto;
  }
`
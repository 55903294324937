import { Backdrop, Fade, makeStyles, Modal } from '@material-ui/core'
import Button from 'components/Button'
import Input from 'components/Input'
import SVG from 'components/SVG'
import Text from 'components/Text'
import { useAuth } from 'modules/data/hooks/useAuth'
import React, { useState } from 'react'
import * as s from './styles'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

function PasswordValidation({
  opened,
  handleClose,
  successCallback,
  errorCallback,
}) {
  console.log(opened, 'opened')
  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(false)
  const { validatePassword } = useAuth()

  const classes = useStyles()

  const handleModalClose = () => {
    setPassword()
    if (handleClose) handleClose()
  }

  const handleButtonClick = () => {
    setLoading(true)
    validatePassword(password)
      .then((login) => {
        console.log('success', login)
        setLoading(false)
        if (successCallback) successCallback()
      })
      .catch((err) => {
        console.log('error', err)
        setLoading(false)
        if (errorCallback) errorCallback()
      })
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={opened}
      onClose={handleModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={opened}>
        <s.ModalContainer>
          <SVG.Lock color={'apple'} />
          <Text color='dorado' fontWeight='700'>
            Validação de segurança
            {/* {formatMessage(messages.signIn)} */}
          </Text>
          <Text color='dorado'>
            Por questões de segurança precisamos que você valide seu usuário
            {/* {formatMessage(messages.signIn)} */}
          </Text>
          <Input
            // placeholder={formatMessage(messages.password)}
            placeholder={'Password'}
            type='password'
            value={password}
            onChange={({ target }) => setPassword(target.value)}
          />

          <Button
            loading={loading}
            className='button'
            onClick={handleButtonClick}
            disabled={!password}>
            {/* {formatMessage(messages.signIn)} */}
            Validate
          </Button>
        </s.ModalContainer>
      </Fade>
    </Modal>
  )
}

export default PasswordValidation

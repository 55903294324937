import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: block;
  background-color: white;
  border-radius: 15px;
  margin 20px 0;

  .address-list {
    display: block;
    position: absolute;
    background-color: white;
    margin-top: 10px;
    z-index: 1000;
  }
  .address-option {
    list-style: none;
  }
  .address-option:hover {
    background-color: #ccc;
    cursor: pointer;
  }
`


import styled, { css } from 'styled-components'
import { colors } from 'styles'

const containerSizes = {
  default: css`
    width: 64px;
    height: 64px;
  `,
  insideButton: css`
    width: 32px;
    height: 32px;
  `,
}

const sizes = {
  default: css`
    width: 64px;
    height: 64px;
    border: 8px solid ${({ color }) => colors[color] || colors.primary};
  `,
  insideButton: css`
    width: 32px;
    height: 32px;
    border: 3px solid ${({ color }) => colors[color] || colors.primary};
  `,
}

export const Container = styled.div`
  & {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    ${({ size }) => containerSizes[size] || containerSizes.default}
  }
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    ${({ size }) => sizes[size] || sizes.default}
    /* margin: 8px; */
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) => colors[color] || colors.primary} transparent
      transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

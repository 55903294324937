import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'registration.title',
    defaultMessage: 'Bem-vindo!',
  },
  subTitle: {
    id: 'registration.subTitle',
    defaultMessage: 'Selecione o que você deseja fazer',
  },
  farmer: {
    id: 'registration.text.farmer',
    defaultMessage: 'Produtor',
  },
  sell: {
    id: 'registration.text.sell',
    defaultMessage: 'Quero vender lotes',
  },
  buyer: {
    id: 'registration.text.buyer',
    defaultMessage: 'Comprador',
  },
  buy: {
    id: 'registration.text.buy',
    defaultMessage: 'Quero comprar lotes',
  },
  haveAccount: {
    id: 'registration.button.haveAccount',
    defaultMessage: 'Já possui uma conta?',
  },
  signIn: {
    id: 'registration.button.signIn',
    defaultMessage: 'Entrar',
  },
})

export default messages

import React from 'react'
import Title from 'components/Title'

export default function Negotiations() {
  return (
    <div>
      <Title>Negociações</Title>
    </div>
  )
}

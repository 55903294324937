import React from 'react'
import FarmerHome from './Farmer'
import BuyerHome from './Buyer'
import { useUser } from 'modules/data/hooks/useUser'

export default function Home() {
  const { roles } = useUser()

  if(roles.farmer) {
    return(<FarmerHome />)
  }

  if(roles.buyer) {
    return(<BuyerHome />)
  }
  
  return('')
}

import { createGlobalStyle } from 'styled-components'
import { colors, fonts } from './index'
import 'react-toastify/dist/ReactToastify.css'
import './global.css'

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }
    body {
        font-family: ${fonts.primary};
        font-size: 16px;
        background: ${colors.white};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        color: ${colors.defaultText};
        background: ${colors.mercury};
    }
    html, body, #root {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      height: 100%;
    }
`

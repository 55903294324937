import { useFarm } from 'modules/data/hooks/useFarm'
import { useFarmer } from 'modules/data/hooks/useFarmer'

export function useHookMap(farmId) {
	const farmHook = useFarm(farmId);
	const farmerHook = useFarmer();

	const farmerObj = {
		load: field => {
			return farmerHook.farmer[field]
		},
		save: data => {
			farmerHook.addFeature(data, farmHook.farm.users[0].user.uid)
		}
	}

	const farmObj = {
		save: data => {
			farmHook.update(data)
		}
	}

	const hookMap = {
		manager: {
			to: 'name',
			collection: 'farmer'
		},
		cpf: {
			to: 'cpf',
			collection: 'farmer'
		},
		name: {
			to: 'name',
			collection: 'farm'
		},
		cnpj: {
			to: 'cnpj',
			collection: 'farm'
		},
		farm_registration: {
			to: 'ie',
			collection: 'farm'
		},
		city: {
			to: 'city',
			collection: 'farm'
		},
		state: {
			to: 'state',
			collection: 'farm'
		},
		country: {
			to: 'country',
			collection: 'farm'
		},
		geolocation: {
			to: 'geo',
			collection: 'farm'
		},
		
	}

	return {
		loaded: farmHook.farm.loaded,
		load: fields => {
			let obj = {}
			for(let field in hookMap) {
				switch(hookMap[field].collection) {
					case 'farmer':
						// obj[field] = farmHook.farm[hookMap[field].to]
						obj[field] = farmHook.farm.users[0].profile[hookMap[field].to]
						break
					case 'farm':
						obj[field] = farmHook.farm[hookMap[field].to]
						break
					default:
						break
				}
			}
			return obj;
		},
		save: fields => {
			for(let field in hookMap) {
				const value = fields[field]
				console.log(field)
				if(field in hookMap) {
					switch(hookMap[field].collection) {
						case 'farmer':
							farmerObj.save({[hookMap[field].to]: value})
							break
						case 'farm':
							farmObj.save({[hookMap[field].to]: value})
							break
						default:
							break
					}
				}
			}
		}
	}
}
import styled from 'styled-components'
import BannerComprador from 'assets/banner-comprador.jpg'
import { colors, device } from 'styles'

export const Container = styled.div`
  height: 100%;
  flex: 1;
  .bg-image{
    z-index: -1;
    position: absolute;
    top: 0;
    height: 50vh;
    width: 100%;
    background: url(${BannerComprador}) no-repeat top;
    background-size: cover;
  }
  display: flex;
  justify-content: center;
`
export const Logo = styled.div`
  display: flex;
  justify-content: center;
`

export const FormContainer = styled.div`
  height: 100%;
  padding-top: 30px;

  form {
    width: 60vw;
    min-height: 60vh;

    background: ${colors.white};
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.31);
    border-radius: 5px;
    margin-top: 21px;

    padding: 30px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .info {
      font-weight: 300;
      align-self: flex-start;
      color: ${colors.dorado};
      span {
        color: ${colors.apple};
      }
    }

    .fields {
      flex-grow: 1;
      padding-top: 20px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      
      div {
        margin-bottom: 30px;
        flex-grow: 1;
      }
    }
  }

  @media only screen and (max-width: 590px) {
    form {
      width: 400px;
    }
  }

  @media only screen and (max-width: 450px) {
    form {
      width: 350px;
    }
  }

  @media only screen and (max-width: 390px) {
    form {
      width: 300px;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .location {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;

    *:not(:last-child) {
      margin-right: 40px;
    }
  }

  div:not(:second-child) {
    margin-top: 40px;
  }
  margin-bottom: 20px;

  p {
    line-height: 1.4;
    text-justify: auto;
    color: ${colors.silver3};
    margin: 0;
    margin-top: 30px;

    strong {
      color: ${colors.dorado};
      font-weight: 500;
    }
  }

  .city-list {
    display: block;
    position: absolute;
    background-color: white;
    margin-top: 70px;
    z-index: 1000;
  }
  .city-option {
    list-style: none;
  }
  .city-option:hover {
    background-color: #ccc;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    color: ${colors.apple};
    margin: 20px 0;
  }

  .option {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .container-check {
      display: flex;
      span {
        margin-left: 10px;
        margin-right: 40px;
        display: flex;
        color: ${colors.dorado};
        font-style: italic;
      }
    }

    .item {
      width: 20px;
      height: 20px;
      margin-bottom: 0px;
      background: ${colors.silver};
      border-radius: 2px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 0;
    }
    .check {
      width: 20px;
      height: 20px;
      background: ${colors.apple};
      border-radius: 2px;
      margin-bottom: 0px !important;
    }
  }

  @media only screen and (max-width: 590px) {
    width: 340px;

    .location {
      display: flex;
      flex-direction: column;

      div {
        margin-top: 20px;
      }
    }
  }

  @media only screen and (max-width: 450px) {
    width: 310px;

    .option {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .container-check {
        display: flex;
        span {
          margin-left: 10px;
          margin-right: 20px;
          display: flex;
          color: ${colors.dorado};
          font-style: italic;
          font-size: 12px;
        }
      }
    }
  }

  @media only screen and (max-width: 390px) {
    width: 270px;
  }
`

export const Cards = styled.div`
  padding: 0 20px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: left;
  width: 100%;

  @media ${device.untilTablet} {
    justify-content: center;
  }

  .card {
    width: 130px;
    height: 130px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
    margin-right: 25px;
    margin-top: 20px;

    @media ${device.untilMobileM} {
      width: 115px;
      height: 115px;
      margin: 10px;
    }

    img {
      display: block;
      height: 45px;
    }

    p {
      display: block;
      width: 100%;
      text-align: center;
      margin: 20px 0 0 0;

      @media ${device.untilMobileM} {
        font-size: 14px;
      }
    }
  }

  .active {
    border: 3px solid #00bd00;
  }

  .active > object {
    fill: #00bd00;
  }
`

export const Footer = styled.div`
  margin-bottom: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: red; */

  .resend {
    margin-bottom: 50px;
    font-style: italic;
    text-decoration: underline;
    color: ${colors.camarone2};
  }

  .step {
    color: ${colors.silver5};
    font-style: italic;
  }

  @media only screen and (max-width: 590px) {
    .step {
      display: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    margin-right: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
      color: ${colors.apple};
    }
  }
  .continue {
    width: 150px;
  }

  .voltar-mobile {
    width: 70px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    display: flex;
    justify-content: center;
    align-items: center;

    display: none;
  }

  @media only screen and (max-width: 590px) {
    .voltar-desktop {
      display: none;
    }

    .voltar-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: center;
    }

    .desktop-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

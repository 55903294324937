import React, { useState, useEffect } from 'react'
import CustomInput from 'components/CustomInput'
import Select from 'components/Select'

import { useFarm } from 'modules/data/hooks/useFarm'
import { useInspection } from 'modules/data/hooks/useInspection'
import { status as farmStatus } from 'modules/data/consts/farm'
import { Container, Header, Footer, Content, Description } from './styles'

function ScheduleModal(props) {
  const [date, setDate] = useState(null)
  const [turn, setTurn] = useState(props.turn)
  const { setStatus } = useFarm(props.id)
  const { create } = useInspection()

  const bookInspection = (date, turn) => {
    if (date && turn) {
      setStatus(farmStatus.STATUS_WAITING_FOR_VISIT, date, turn)
      create(props.id, {
        date: date,
        turn: turn,
      })
    }
    props.close()
  }

  useEffect( () => {
    setTurn(props.turn)
  }, [ props.turn ])

  return (
    <Container>
      <Header>
        <h1>Agendar Visita</h1>
      </Header>
      <Description>
        <div>
          <p>
            Antes de definir a data, faça contato com o produtor ({props.name})
            para definir o dia e o turno em que a visita será realizada.
          </p>
        </div>
      </Description>
      <Content>
        <CustomInput
          type='date'
          placeholder='Escolha o dia'
          min={props.week.startOf('week').format('YYYY-MM-DD')}
          max={props.week.endOf('week').format('YYYY-MM-DD')}
          onChange={(event) => setDate(event.target.value)}
          required
        />
        <div id='space' />
        <Select
          required
          placeholder='Escolha um turno'
          placeholderAlign='center'
          onChange={(event) => setTurn(event.target.value)}
          value={turn}
          options={[
            { label: 'Manhã', value: 1 },
            { label: 'Tarde', value: 2 },
          ]}
        />
      </Content>
      <Footer>
        <div id='buttons'>
          <button id='cancel' onClick={props.close}>
            Cancelar
          </button>
          <button
            id='finishing'
            onClick={(event) => bookInspection(date, turn)}>
            Concluir
          </button>
        </div>
      </Footer>
    </Container>
  )
}

export default ScheduleModal

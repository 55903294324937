import { defineMessages } from 'react-intl'

const messages = defineMessages({
  loi: {
    id: 'loi.negotiation.loi',
    defaultMessage: 'Loi',
  },
  inNegotiation: {
    id: 'loi.negotiation.inNegotiation',
    defaultMessage: 'Negociação',
  },
  payment: {
    id: 'loi.negotiation.payment',
    defaultMessage: 'Pagamento',
  },
  originatingOffer: {
    id: 'loi.negotiation.originatingOffer',
    defaultMessage: 'Originando oferta',
  },
  productOriginated: {
    id: 'loi.negotiation.productOriginated',
    defaultMessage: 'Produto originado',
  },
  canceled: {
    id: 'loi.negotiation.canceled',
    defaultMessage: 'Cancelado',
  },
  quantity: {
    id: 'loi.negotiation.quantity',
    defaultMessage: 'Quantidade total de',
  },
  packageType: {
    id: 'loi.negotiation.packageType',
    defaultMessage: 'Tipo da embalagem:',
  },
  contractType: {
    id: 'loi.negotiation.contractType',
    defaultMessage: 'Tipo de contrato:',
  },
  paymentType: {
    id: 'loi.negotiation.paymentType',
    defaultMessage: 'Forma de pagamento:',
  },
  offerTime: {
    id: 'loi.negotiation.offerTime',
    defaultMessage: 'Tempo de oferta de',
  },
  remainingOfferTime: {
    id: 'loi.negotiation.remainingOfferTime',
    defaultMessage: 'Tempo restante da oferta:',
  },
  daysBy: {
    id: 'loi.negotiation.daysBy',
    defaultMessage: 'dias úteis',
  },
  country: {
    id: 'loi.negotiation.country',
    defaultMessage: 'País destino:',
  },
  port: {
    id: 'loi.negotiation.port',
    defaultMessage: 'Porto de destino:',
  },
  address: {
    id: 'loi.negotiation.address',
    defaultMessage: 'Endereço destino:',
  },
  targetPrice: {
    id: 'loi.negotiation.targetPrice',
    defaultMessage: 'Preço alvo',
  },
  close: {
    id: 'loi.negotiation.close',
    defaultMessage: 'Fechar',
  },
  deleteLoi: {
    id: 'loi.negotiation.deleteLoi',
    defaultMessage: 'Excluir loi',
  },
  originatingOfferTitle: {
    id: 'loi.negotiation.originatingOfferTitle',
    defaultMessage: 'Tudo certo por aqui, estamos originando sua oferta',
  },
  originatingLoiText: {
    id: 'loi.negotiation.originatingLoiText',
    defaultMessage:
      'A sua loi está sendo originada, quando uma negociação for encontrada você será avisado e verá os detalhes aqui',
  },
  inCaseOfDoubt: {
    id: 'loi.negotiation.inCaseOfDoubt',
    defaultMessage: 'Em caso de dúvida acesse nossa',
  },
  helpCenter: {
    id: 'loi.negotiation.helpCenter',
    defaultMessage: 'central de ajuda',
  },
  tons: {
    id: 'loi.negotiation.tons',
    defaultMessage: 'toneladas',
  },
})

export default messages

import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import Title from 'components/Title'
import Text from 'components/Text'

import { Tabs } from 'components/Tabs'

import Content from './Content'

import * as s from './styles'
import { getBatchStatusDescription } from 'utils'
import { useIntl } from 'react-intl'
import { useNegotiation } from 'modules/data/hooks/useNegotiation'
import { idType } from 'modules/data/consts/negotiation'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

function Negotiation({ batch, open, close }) {
  const { negotiation } = useNegotiation(batch?.id, idType.lot)
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const tabs = [
    { name: 'Lote', slug: 'BATCH' },
    { name: 'Negociação', slug: 'NEGOTIATION' },
    { name: 'Carregamento', slug: 'CHARGING', disabled: !negotiation || !negotiation?.shipment },
    { name: 'Pagamento', slug: 'PAYMENT', disabled: !negotiation },
  ]

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const changeTab = (tab) => {
    setSelectedTab(tab)
  }

  if (!batch) {
    return null
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <s.Container>
          <s.Header>
            <s.HeaderImg src={batch.product.url_negotiation} />
            <s.HeaderContent>
              <Title>{batch.product.name}</Title>
              <Text italic fontWeight='bold' color='bostonBlue'>
                {formatMessage(getBatchStatusDescription(batch.status))}
              </Text>
              <Text italic color='nobel'>
                Tempo restante da oferta: {batch.expiration} dias
              </Text>
            </s.HeaderContent>
          </s.Header>
          <Tabs tabs={tabs} onSetTabs={changeTab} selectedTab={selectedTab} />
          <s.Body>
            <Content close={close} batch={batch} tab={selectedTab} negotiation={negotiation}/>
          </s.Body>
        </s.Container>
      </Fade>
    </Modal>
  )
}

export default Negotiation

import React from 'react'

import Text from 'components/Text'

import * as s from './styles'

function Farm({ farm, ...props }) {
  const { active, name, available, products, img } = farm
  return (
    <s.Farm active={active} available={available} {...props}>
      <s.Img src={img} />
      <s.Content>
        <Text fontWeight='bold'>{name}</Text>
        <Text color={available ? 'green' : 'danger'} fontSize='12px'>
          {available ? 'Disponível' : 'Indisponível'}
        </Text>
        <s.Divider />
        <Text className='txt-hide' fontSize='12px'>
          {products.map((p) => p.name).join(',')}
        </Text>
      </s.Content>
    </s.Farm>
  )
}

export default Farm

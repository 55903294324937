import React from 'react'

import * as s from './styles'

export default function Address({
  description,
  route,
  neighborhood,
  number,
  city,
  state,
  postalCode,
  onClick,
}) {
  return (
    <s.Container>
      {onClick && (
        <span className='delete' onClick={(e) => onClick(description)}>
          ✕
        </span>
      )}
      <s.Title>{description} </s.Title>
      <s.Text>
        {route}, {number} - {neighborhood}
      </s.Text>
      <s.Text>
        {city}, {state}
      </s.Text>
      <s.Text>{postalCode}</s.Text>
    </s.Container>
  )
}

// Oficial firebase list of auth errors: https://firebase.google.com/docs/auth/admin/errors
export const errorModule = {
  emailValidation: {
    title: {
      id: 'error.emailValidation.title',
      defaultMessage: 'Erro de validação'
    },
    selector: {
      agent: 'user',
      field: 'verify'
    },
    codes: [
      {
        code: 'auth/expired-action-code',
        id: 'error.emailValidation.expired',
        defaultMessage: 'Não é possível verificar a conta. O código enviado expirou.'
      },
      {
        code: 'auth/invalid-action-code',
        id: 'error.emailValidation.invalid',
        defaultMessage: 'Não é possível verificar a conta. O código enviado é inválido.'
      },
      {
        code: 'auth/user-disabled',
        id: 'error.emailValidation.userDisabled',
        defaultMessage: 'Não é possível verificar a conta. O usuário está desabilitado no sistema.'
      },
      {
        code: 'auth/user-not-found',
        id: 'error.emailValidation.userNotFound',
        defaultMessage: 'Não é possível verificar a conta. O usuário não foi encontrado.'
      }
    ]
  },
  phoneValidation: {
    title: {
      id: 'error.phoneValidation.title',
      defaultMessage: 'Erro de validação'
    },
    selector: {
      agent: 'phone',
      field: 'verify'
    },
    codes: [
      {
        code: 'auth/provider-already-linked',
        id: 'error.phoneValidation.alreadyLinked',
        defaultMessage: 'Erro de validação. Esse número de telefone já foi validado anteriormente.'
      },
      {
        code: 'auth/credential-already-in-use',
        id: 'error.phoneValidation.alreadyInUse',
        defaultMessage: 'Erro de validação. Esse número de telefone já está associado a uma conta cadastrada.'
      },
      { 
        code: 'auth/invalid-verification-code',
        id: 'error.phoneValidation.invalidCode',
        defaultMessage: 'O código inserido está incorreto. Verifique em seu telefone e confirme novamente.'
      },
      {
        code: 'auth/too-many-requests',
        id: 'error.phoneValidation.tooManyRequests',
        defaultMessage: 'Você solicitou o código muitas vezes. Se você não recebeu o código, verifique se o número está correto e se seu telefone está conectado à operadora. Aguarde e tente novamente em alguns instantes.'
      }
    ]
  },
  passwordReset: {
    title: {
      id: 'error.passwordReset.title',
      defaultMessage: 'Erro ao alterar senha'
    },
    selector: {
      agent: 'user',
      field: 'reset'
    },
    codes: [
      {
        code: 'auth/id-token-expired',
        id: 'error.passwordReset.expired',
        defaultMessage: 'O link utilizado já expirou. Solicite novamente.'
      }
    ]
  },
  userCreate: {
    title: {
      id: 'error.userCreate.title',
      defaultMessage: 'Erro ao criar usuário'
    },
    selector: {
      agent: 'user',
      field: 'create'
    },
    codes: [
      {
        code: 'auth/email-already-in-use',
        id: 'error.userCreate.alreadyInUse',
        defaultMessage: 'Essa conta de e-mail já está em uso na plataforma.'
      }
    ],
    default: {
      id: 'error.userCreate.default',
      defaultMessage: 'Houve um erro ao criar o usuário'
    }
  }
}
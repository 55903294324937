import React, { useState } from 'react'

import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import Title from 'components/Title'
import Text from 'components/Text'

import { Tabs } from 'components/Tabs'
import { getDays, getLoiStatusDescription } from 'utils'

import Content from './Content'

import * as s from './styles'
import messages from './messages'
import { idType } from 'modules/data/consts/negotiation'
import { useNegotiation } from 'modules/data/hooks/useNegotiation'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

function Negotiation({ loi, open, close }) {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { negotiation } = useNegotiation(loi?.id, idType.loi)

  const tabs = [
    { name: formatMessage(messages.loi), selected: true, slug: 'loi' },
    { name: formatMessage(messages.inNegotiation), slug: 'NEGOTIATION' },
    {
      name: formatMessage(messages.payment),
      slug: 'PAYMENT',
      disabled: !negotiation,
    },
    { name: 'Smart Contract', slug: 'SMARTCONTRACT', disabled: !negotiation || !negotiation.smartContract },
  ]

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const changeTab = (tab) => {
    setSelectedTab(tab)
  }

  if (!loi) {
    return null
  }

  const getTitle = () => {
    if (
      selectedTab.slug === 'NEGOTIATION' &&
      negotiation?.acceptance &&
      negotiation.acceptance.buyer &&
      negotiation.acceptance.farmer
    ) {
      return 'Contract draft!'
    } else {
      return formatMessage(getLoiStatusDescription(loi.status))
    }
  }

  const getSubtitle = () => {
    if (
      selectedTab.slug === 'NEGOTIATION' &&
      negotiation?.acceptance &&
      negotiation.acceptance.buyer &&
      negotiation.acceptance.farmer
    ) {
      return 'Validate payment'
    } else {
      if (selectedTab.slug === 'PAYMENT') return 'Awaiting payment validation'
      else
        return (
          <>
            {formatMessage(messages.remainingOfferTime)}{' '}
            {getDays(loi.date, Number(loi.offerTime))}
          </>
        )
    }
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <s.Container>
          <s.Header>
            <s.HeaderImg src={loi.product.url_negotiation} />
            <s.HeaderContent>
              <Title>{loi.product.name_en}</Title>
              <Text italic fontWeight='bold' color='bostonBlue'>
                {getTitle()}
              </Text>
              <Text italic color='nobel'>
                {getSubtitle()}
              </Text>
            </s.HeaderContent>
          </s.Header>
          <Tabs
            tabs={tabs}
            onSetTabs={changeTab}
            selectedTab={selectedTab}
            hasLast
          />
          <s.Body>
            <Content
              close={close}
              loi={loi}
              tab={selectedTab}
              negotiation={negotiation}
            />
          </s.Body>
        </s.Container>
      </Fade>
    </Modal>
  )
}

export default Negotiation

import routes from './routes'
import messages from './messages'


const profileMenus = {
  farmer: [
    {
      link: routes.farmer.home,
      icon: 'Clipboard',
      titleLineOne: 'Minha',
      titleLineTwo: 'Tela Inicial',
    },
    {
      link: routes.admin.farms,
      icon: 'Farm',
      titleLineOne: 'Minhas',
      titleLineTwo: 'Propriedades',
    },
    {
      link: routes.admin.batches,
      icon: 'WheatBag',
      titleLineOne: 'Meus',
      titleLineTwo: 'Lotes',
    },
    // {
    //   link: routes.admin.negotiations,
    //   icon: 'MoneyBag',
    //   titleLineOne: messages.minhas,
    //   titleLineTwo: messages.negociacoes,
    //   notifications: 1,
    // },
    {
      link: routes.admin.profile,
      icon: 'Farmer',
      titleLineOne: 'Meu',
      titleLineTwo: 'Perfil',
    },
  ],

  buyer: [
    {
      link: routes.buyer.home,
      icon: 'Clipboard',
      titleLineOne: messages.minha,
      titleLineTwo: messages.telaInicial,
    },
    {
      link: routes.admin.lois,
      icon: 'WheatBag',
      titleLineOne: messages.minhas,
      titleLineTwo: messages.lois,
    },
    // {
    //   link: routes.admin.negotiations,
    //   icon: 'MoneyBag',
    //   titleLineOne: messages.minhas,
    //   titleLineTwo: messages.negociacoes,
    // },
    {
      link: routes.admin.profile,
      icon: 'Buyer',
      titleLineOne: messages.meu,
      titleLineTwo: messages.perfil,
    },
  ],

  inspector: [
    {
      link: routes.inspector.inspections,
      icon: 'Farm',
      titleLineOne: 'Agendar',
      titleLineTwo: 'Visitas',
    },
    {
      link: routes.inspector.schedule,
      icon: 'Farm',
      titleLineOne: 'Minhas',
      titleLineTwo: 'Visitas',
    },
    {
      link: routes.inspector.review,
      icon: 'Farm',
      titleLineOne: 'Visitas',
      titleLineTwo: 'Concluídas',
    },
  ],

  admin: [
    {
      link: routes.admin.management,
      icon: 'Clipboard',
      titleLineOne: 'Minha',
      titleLineTwo: 'Tela inicial',
    },
    {
      link: routes.admin.farmers,
      icon: 'Farmer',
      titleLineOne: 'Produtores',
      titleLineTwo: 'Rurais',
    },
    {
      link: routes.admin.buyers,
      icon: 'Buyer',
      titleLineOne: 'Compradores',
      titleLineTwo: 'Globais',
    },
    {
      link: routes.admin.negotiation,
      icon: 'MoneyBag',
      titleLineOne: '',
      titleLineTwo: 'Negociações',
    },
    {
      link: routes.admin.match,
      icon: 'MoneyBag',
      titleLineOne: 'The',
      titleLineTwo: 'Match',
    },
  ],
}

export default profileMenus

import React, { useState } from 'react'
import CustomInput from 'components/CustomInput'

import { Container, Header, Footer, Content, Description } from './styles'
import Select from 'components/Select'

function Appointment(props) {
  const [week, setWeek] = useState(null)
  const [turn, setTurn] = useState(null)

  const getWeek = (base) => {
    let onejan = new Date(base.getFullYear(), 0, 1)
    return Math.ceil(
      ((base.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7
    )
  }

  let now = { date: new Date() }
  let then = { date: new Date() }
  then.date.setDate(now.date.getDate() + 60)
  now.week = getWeek(now.date)
  then.week = getWeek(then.date)

  return (
    <Container>
      <Header>
        <h1>{props.schedule ? 'Caro Produtor(a) Rural,' : 'Remarcar visita'}</h1>
      </Header>
      <Description>
        {props.schedule ? (
          <div>
            <p>
              A Agricon é uma plataforma digital, mas não há nada que substitua
              um <strong>cumprimentar firme olhando nos olhos.</strong>
            </p>
            <p>
              Para isso precisamos agendar uma visita à sua propriedade rural,
              para conversar, entender suas necessidades, conhecer a sua
              estrutura e validar informações.
            </p>
            <p>
              Para isso, agende sua visita técnica, na semana e turno que
              preferir. Entraremos em contato pelo telefone para confirmar o
              agendamento.
            </p>
          </div>
        ) : (
          ''
        )}
      </Description>
      <Content>
        <CustomInput
          type='week'
          placeholder='Escolha uma semana'
          min={
            now.date.getFullYear() +
            '-W' +
            (now.week < 10 ? '0' : '') +
            now.week
          }
          max={
            then.date.getFullYear() +
            '-W' +
            (then.week < 10 ? '0' : '') +
            then.week
          }
          onChange={(event) => setWeek(event.target.value)}
          required
        />
        <div id='space' />
        <Select
          required
          placeholder='Escolha um turno'
          placeholderAlign='center'
          onChange={(event) => setTurn(event.target.value)}
          options={[
            { label: 'Manhã', value: 1 },
            { label: 'Tarde', value: 2 },
          ]}
        />
      </Content>
      <Footer>
        <div id='buttons'>
          <button id='cancel' onClick={props.close}>
            Cancelar
          </button>
          <button id='finishing' onClick={(event) => props.book(week, turn)}>
            Concluir
          </button>
        </div>
      </Footer>
    </Container>
  )
}

export default Appointment

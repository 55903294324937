import React from 'react'

import SVG from '../'

export default function SVGObject({ svg }) {
  const SVGComponent = SVG[svg]

  if (!SVGComponent) {
    return null
  }

  return (
    <React.Fragment>
      <SVGComponent />
    </React.Fragment>
  )
}

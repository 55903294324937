import { useSelector, useDispatch } from 'react-redux'
import { 
	createAccount, check, login, 
	logout, resetPassword, 
	requestPasswordReset, verifyEmail, validatePassword,
} from '../logic/userLogic'


export function useAuth() {
	const dispatch = useDispatch()
	const propsAuth = useSelector((state) => state.firebase.auth)
	const propsState = useSelector(state => state.user)

	const phoneAuth = propsAuth && propsAuth.phoneNumber !== null;

	return {
		createAccount: (email, password) => dispatch(createAccount, { email, password }),
		createAccountWithPhone: (verification, code) => dispatch(createAccount, { verification, code }), 
		login: (email, password) => dispatch(login, { email, password }),
		logout: () => dispatch(logout),
		validatePassword: (password) => validatePassword(propsAuth, password),
		checkEmail: email => check(email, dispatch),
		requestPasswordReset: email => dispatch(requestPasswordReset, email),
		resetPassword: (code, password) => dispatch(resetPassword, code, password),
		verifyEmail: code => dispatch(verifyEmail, code),
		phoneAuth,
		loggedIn: propsAuth.isLoaded && !propsAuth.isEmpty,
		...propsAuth,
		...propsState,
	}
}


import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'verifyPhone.title',
    defaultMessage: 'Validar telefone',
  },
  info: {
    id: 'verifyPhone.text.info',
    defaultMessage:
      'Em breve você receberá uma mensagem contendo um código de 6 dígitos. Digite-o abaixo para validr seu telefone',
  },
  done: {
    id: 'verifyPhoneNumberBuyer.text.done',
    defaultMessage: 'Seu número de telefone já foi devidamente validado',
  },
  next: {
    id: 'verifyPhone.button.next',
    defaultMessage: 'Próximo'
  },
  resend: {
    id: 'verifyPhone.button.resend',
    defaultMessage: 'Reenviar código',
  },
  back: {
    id: 'verifyPhone.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'verifyPhone.button.continue',
    defaultMessage: 'Validar Telefone',
  },
  error: {
    id: 'verifyPhone.message.missing',
    defaultMessage: 'Preencha o código'
  }
})

export default messages

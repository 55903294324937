import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'form.required.fileds',
    defaultMessage: 'Os campos com * são obrigatórios'
  },
  name: {
    id: 'companyData.input.name',
    defaultMessage: 'Razão Social *',
  },
  fantasyName: {
    id: 'companyData.input.fantasyName',
    defaultMessage: 'Nome fantasia *',
  },
  step: {
    id: 'companyNameData.text.step',
    defaultMessage: 'etapa 2 de 9',
  },
  back: {
    id: 'companyData.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'companyData.button.continue',
    defaultMessage: 'Continuar',
  },
})

export default messages

import React from 'react'

import Text from 'components/Text'

import * as s from './styles'
import SelectableDiv from 'components/SelectableDiv'

export function BankData({
  accounts,
  bordered,
  farmer,
  onSelect,
  selected,
  title,
  readOnly = false,
}) {
  if (readOnly) {
    const { bank, unit, account } = accounts[0]
    return (
      <s.BankData bordered={bordered}>
        {title && <Text className='bank-data-title'>{title}</Text>}
        <Text fontWeight='bold'>{farmer.name}</Text>
        {farmer && farmer.cpf ? (
          <Text className='cpf'>{farmer.cpf}</Text>
        ) : null}
        <Text>
          <b>Banco:</b> {bank}
        </Text>
        <Text>
          <b>Agência:</b> {unit}
        </Text>
        <Text>
          <b>Conta</b> <span>(com dígito)</span>: {account}
        </Text>
      </s.BankData>
    )
  } else {
    return (
      <s.Container>
        {accounts.map((c) => {
          const { account, bank, unit } = c

          return (
            <SelectableDiv
              key={c.type}
              selected={selected && selected.description === c.description}
              disabled={c.disabled}
              selectionObject={c}
              setSelected={onSelect}
              width={'270px'}>
              <s.BankData bordered={bordered}>
                <Text fontWeight='bold'>{farmer.name}</Text>
                {farmer && farmer.cpf ? (
                  <Text className='cpf'>{farmer.cpf}</Text>
                ) : null}
                <Text>
                  <b>Banco:</b> {bank}
                </Text>
                <Text>
                  <b>Agência:</b> {unit}
                </Text>
                <Text>
                  <b>Conta</b> <span>(com dígito)</span>: {account}
                </Text>
              </s.BankData>
            </SelectableDiv>
          )
        })}
      </s.Container>
    )
  }
}

export function Tabs({ tabs = [], setTabs, onSetTabs }) {
  const selectTab = (i) => {
    const _tabs = tabs.map((tab, j) =>
      i === j ? { ...tab, selected: true } : { ...tab, selected: false }
    )

    setTabs(_tabs)

    if (onSetTabs) {
      onSetTabs(tabs[i], i)
    }
  }

  return (
    <s.Tabs>
      {tabs
        .filter((t) => !t.disabled)
        .map((tab, i) => (
          <s.TabItem
            key={`tab-${tab.name}-${i}`}
            onClick={() => selectTab(i)}
            selected={tab.selected}>
            {tab.name}
          </s.TabItem>
        ))}
    </s.Tabs>
  )
}

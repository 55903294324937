import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'farmerTerms.title',
    defaultMessage: 'Termos de uso',
  },
  subTitle: {
    id: 'farmerTerms.text.subTitle',
    defaultMessage:
      'Não se preocupe. Você pode acessar os termos de uso a hora que quiser.',
  },
  accept: {
    id: 'farmerTerms.input.accept',
    defaultMessage: 'Eu aceito os termos de uso',
  },
  step: {
    id: 'farmerTerms.text.step',
    defaultMessage: 'etapa 5 de 5',
  },
  back: {
    id: 'farmerTerms.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'farmerTerms.button.continue',
    defaultMessage: 'Finalizar',
  },
  checkTerms: {
    id: 'farmerTerms.text.checkTerms',
    defaultMessage: 'Você precisa aceitar os termos!',
  },
})

export default messages

import React from 'react'
import { Container, Logo, FormContainer, Content, Footer } from './styles'
import Title from 'components/Title'
import Text from 'components/Text'
import Button from 'components/Button'
import logo from 'assets/logo-green.svg'
import { useHistory } from 'react-router-dom'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useUser } from 'modules/data/hooks/useUser'
import { useIntl } from 'react-intl'
import messages from './messages'

export default function WelcomeBuyer() {
  const history = useHistory()
  const { create } = useBuyer()
  const { isAnonymous } = useUser()
  const { formatMessage } = useIntl()

  const handleNextStep = () => {
    create()
    history.push('/buyer-step-data')
  }

  return (
    <Container>
      <div className='bg-image'/>
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Title className='title' color='primary'>
              {formatMessage(messages.title)}
            </Title>
            <Text innerHtml={formatMessage(messages.happy)} />
            <Text innerHtml={formatMessage(messages.oportunity)} />
            <Text>
             {formatMessage(messages.informations)}
            </Text>
            <Footer>
              <Button onClick={handleNextStep}>{formatMessage(isAnonymous ? messages.continue :  messages.begin)} {formatMessage(messages.registration)}</Button>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import {
  USER_COLLECTION,
  FARMER_COLLECTION,
  BUYER_COLLECTION,
} from '../consts/collections'

export function useAdmin() {
  // const propsAuth = useSelector((state) => state.firebase.auth)
  useFirestoreConnect([
    { collection: USER_COLLECTION },
    { collection: FARMER_COLLECTION },
    { collection: BUYER_COLLECTION },
  ])
  // useFirestoreConnect([{ collection: NEGOTIATIONS_COLLECTION }])

  const allUsers = useSelector(
    (state) => state.firestore.ordered[USER_COLLECTION]
  )

  const allFarmers = useSelector(
    (state) => state.firestore.ordered[FARMER_COLLECTION]
  )

  const allBuyers = useSelector(
    (state) => state.firestore.ordered[BUYER_COLLECTION]
  )

  const farmers = useSelector((state) => {
    if (isLoaded(allUsers)) {
      if (
        allUsers &&
        allUsers.length > 0 &&
        allFarmers &&
        allFarmers.length > 0
      ) {
        let farmerUsers = []
        allFarmers.forEach((farmerProfile) => {
          const user = allUsers.find((user) => farmerProfile.id === user.id)
          if (user) farmerUsers.push({ ...farmerProfile, profile: user })
        })
        // console.log(farmerUsers)
        return farmerUsers
      }
      return []
    }

    return []
  })

  const buyers = useSelector((state) => {
    if (isLoaded(allUsers)) {
      if (
        allUsers &&
        allUsers.length > 0 &&
        allBuyers &&
        allBuyers.length > 0
      ) {
        let buyerUsers = []
        allBuyers.forEach((buyerProfile) => {
          const user = allUsers.find((user) => buyerProfile.id === user.id)
          if (user) buyerUsers.push({ ...buyerProfile, profile: user })
        })

        return buyerUsers
      }
      return []
    }

    return []
  })

  return {
    users: isLoaded(allUsers) ? allUsers : [],
    farmers: isLoaded(farmers) ? farmers : [],
    buyers: isLoaded(buyers) ? buyers : [],
  }
}

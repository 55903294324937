import model from 'inspection-model-1'

const data = {
  id: 1,
  storage_id: 2,
  owner: 'Luciane',
  date: '2020-10-30',
  shift: 'manhã',
  start_time: '14:00',
  end_time: '15:00',
  manager: '',
  cpf: '00585725888',
  cnpj: '80000000000110',
  age: 30,
  farmer_experience_time: 12,
  family_succession: true,
  literacy: '',
  parents_profession: 'Professor',
  products: '',
  organic_product_certification: true,
}

async function stall(stallTime = 500) {
  await new Promise((resolve) => setTimeout(resolve, stallTime))
}

async function getInspectionSteps() {
  await stall(1000)
  return new Promise((resolve) => resolve(model))
}

async function getData() {
  await stall(1000)
  const fields = localStorage.getItem('fields')
  const localData = fields ? JSON.parse(fields) : {}
  return new Promise((resolve) => resolve({ ...data, ...localData }))
}

async function saveFields(fields) {
  localStorage.setItem('fields', JSON.stringify(fields))
  await stall()
  return new Promise((resolve) => resolve())
}

export { getInspectionSteps, getData, saveFields }

import React, { useEffect, useState } from 'react'

import { useFileUpload } from 'modules/data/hooks/useFileUpload'

import SVG from '../SVG'

import * as s from './styles'
import { Backdrop, Fade, Modal, makeStyles } from '@material-ui/core'
import Cropper from 'components/Cropper'

function Status({ fileUpload }) {
  const { progress, error, url } = fileUpload

  if (url) {
    return <SVG.FileStatus type='success' />
  }

  if (error) {
    return <SVG.FileStatus type='error' />
  }

  if (progress > 0 && progress < 100) {
    return <SVG.FileStatus type='loading' />
  }

  return null
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function AvatarUpload(props) {
  const [fileUrl, setFileUrl] = useState(null)
  const fileUpload = useFileUpload()
  const classes = useStyles()
  const {
    className,
    onChange,
    onUpload,
    theme,
    folder,
    opened,
    setOpened,
  } = props

  const saveCallback = (blob) => {
    opened && setOpened && setOpened(false)
    fileUpload.upload(blob, folder)
  }

  const handleClose = () => {
    setOpened && setOpened(false)
  }

  useEffect(() => {
    onChange && onChange(fileUpload)
    if (fileUpload.url !== fileUrl) {
      setFileUrl(fileUpload.url)
      onUpload(fileUpload.url)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUpload.url])

  return (
    <s.Wrapper className={className} theme={theme}>
      <Status fileUpload={fileUpload} />
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={opened}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={opened}>
          <s.Container>
            <Cropper saveCallback={saveCallback} />
          </s.Container>
        </Fade>
      </Modal>
    </s.Wrapper>
  )
}

export default AvatarUpload

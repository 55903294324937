import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'form.required.fileds',
    defaultMessage: 'Os campos com * são obrigatórios'
  },
  info: {
    id: 'companyData.text.info',
    defaultMessage: 'Os campos com <span>*</span> são obrigatórios',
  },
  city: {
    id: 'companyData.input.city',
    defaultMessage: 'Cidade *',
  },
  identification: {
    id: 'companyData.input.identification',
    defaultMessage: 'CNPJ *',
  },
  step: {
    id: 'companyFirstData.text.step',
    defaultMessage: 'etapa 1 de 9',
  },
  informations: {
    id: 'companyData.text.informations',
    defaultMessage:
      'Cadastre suas informações para poder ter acesso a plataforma. Fique tranquilo(a), seus dados são confidenciais e de uso interno.',
  },
  back: {
    id: 'companyData.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'companyData.button.continue',
    defaultMessage: 'Continuar',
  },
})

export default messages

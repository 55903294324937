import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
  Term,
  AcceptTerm,
} from './styles'
import Title from 'components/Title'
import Text from 'components/Text'
import Button from 'components/Button'
import logo from 'assets/logo.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { toast } from 'react-toastify'
import { useTerms } from '../../../modules/data/hooks/useTerms'
import { useFarmer } from '../../../modules/data/hooks/useFarmer'
import { useIntl } from 'react-intl'
import messages from './messages'
import routes from 'configuration/routes'

export default function Terms() {
  const history = useHistory()
  const [value, setValue] = useState(false)
  const { current } = useTerms()
  const { acceptTerms, farmer } = useFarmer()
  const { formatMessage } = useIntl()

  const nextStep = routes.farmer.home
  const prevStep = routes.farmer.verifyPhone

  useEffect( () => {
    if(farmer?.terms) setValue(true)
  }, [ farmer ])

  const handleNextStep = () => {
    if (!value) {
      toast.warn(formatMessage(messages.checkTerms))
      return
    }

    acceptTerms(current.version)
    history.push(nextStep)
  }

  const handlCheck = () => {
    setValue(!value)
  }

  return (
    <Container>
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Title className='title'>{formatMessage(messages.title)}</Title>
            <Text>{formatMessage(messages.subTitle)}</Text>
            <Term>
              <Text>{current.content.pt_br}</Text>
            </Term>
            <AcceptTerm>
              <div onClick={handlCheck} className='container'>
                {value && <div className='check' />}
              </div>
              <span>{formatMessage(messages.accept)}</span>
            </AcceptTerm>
            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to={prevStep}>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to={prevStep}>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

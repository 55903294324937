import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { SHIPMENT_COLLECTION } from '../consts/collections'
import { update, updateTruck } from '../logic/shipmentLogic'

export function useShipment(id) {
    useFirestoreConnect([{ collection: SHIPMENT_COLLECTION, doc: id }])
    const dispatch = useDispatch()
  
    const shipment = useSelector(
      ({ firestore: { data } }) =>
        data[SHIPMENT_COLLECTION] && data[SHIPMENT_COLLECTION][id]
    )

    const error = useSelector( state => state.loi.error )
    const all = useSelector((state) => state.firestore.ordered[SHIPMENT_COLLECTION])

    return {
      all: all,
      update: (data) => dispatch(update, id, data),
      updateTruck: (truckData) => dispatch(updateTruck, id, truckData, shipment),
      shipment: isLoaded(shipment) ? shipment : { loading: true },
      error
    }
}

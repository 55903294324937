import React from 'react'
import { colors } from 'styles'

export default function ({ color, width, height, ...props }) {
  const fill = colors[color] || colors.defaultSVG
  return (
    <svg
      {...props}
      width={width ? width : '64'}
      height={height ? height : '71'}
      viewBox="0 0 64 71" 
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.3313 2.46094C23.2204 1.16719 28.4094 0 31.5172 0C34.6204 0 39.8141 1.16719 44.7032 2.46094C49.0639 3.63176 53.3964 4.90555 57.6969 6.28125C60.1719 7.06875 62.0376 9.20156 62.3985 11.8312C65.0844 31.5047 58.8547 46.0828 51.3032 55.7297C48.0851 59.8697 44.2645 63.5039 39.9688 66.5109C38.4751 67.5572 36.8961 68.4761 35.2485 69.2578C33.9829 69.8391 32.6329 70.3125 31.5172 70.3125C30.4016 70.3125 29.0516 69.8391 27.786 69.2578C26.138 68.4767 24.559 67.5579 23.0657 66.5109C18.7717 63.5035 14.9527 59.8693 11.736 55.7297C4.17506 46.0828 -2.04994 31.5047 0.635996 11.8312C0.815578 10.5566 1.35247 9.35883 2.18452 8.37663C3.01657 7.39443 4.10976 6.66794 5.33756 6.28125C9.63809 4.90558 13.9706 3.63179 18.3313 2.46094ZM33.9313 34.7297C35.4769 34.1642 36.7751 33.0744 37.5998 31.6502C38.4246 30.226 38.7236 28.5576 38.4448 26.9356C38.1661 25.3137 37.3272 23.8409 36.0743 22.7737C34.8214 21.7066 33.2338 21.1128 31.5882 21.0957C29.9425 21.0786 28.3429 21.6394 27.0682 22.6803C25.7934 23.7212 24.9241 25.1763 24.6117 26.7921C24.2994 28.408 24.5637 30.0822 25.3588 31.5232C26.1538 32.9642 27.4291 34.0806 28.9626 34.6781C28.8636 34.8797 28.7942 35.0943 28.7563 35.3156L27.1954 44.6906C27.1396 45.0262 27.1575 45.37 27.2479 45.698C27.3384 46.0259 27.4991 46.3303 27.719 46.5899C27.9389 46.8495 28.2127 47.0581 28.5213 47.2012C28.83 47.3444 29.1661 47.4186 29.5063 47.4188H33.4204C33.762 47.4189 34.0995 47.3444 34.4093 47.2005C34.7191 47.0565 34.9937 46.8466 35.2139 46.5854C35.434 46.3241 35.5944 46.0179 35.6838 45.6882C35.7732 45.3585 35.7894 45.0132 35.7313 44.6766L34.1047 35.3016C34.0716 35.1043 34.0133 34.9121 33.9313 34.7297Z'
        fill={fill}
      />
    </svg>
  )
}

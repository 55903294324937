import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from '../styles'
import Input from 'components/Input'
import Select from 'components/Select'
import Button from 'components/Button'
import logo from 'assets/logo-green.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useParams } from 'modules/data/hooks/useParams'
import { useIntl } from 'react-intl'
import messages from './messages'
import { toast } from 'react-toastify'

export default function CompanyDetails() {
  const history = useHistory()
  const [businessType, setBusinessType] = useState(null)
  const [yearFounded, setYearFounded] = useState('')
  const [companySegment, setCompanySegment] = useState(null)
  const { addFeature, buyer } = useBuyer()
  const { formatMessage, locale } = useIntl()

  const params = useParams('farmer')

  let selector = 'en'
    if(params && locale) {
      selector = locale === 'pt-br' ? 'ptBR' : 'en'
    }

  let optionMap = []
  if(params) optionMap = params.type[selector].map( (val, idx) => {
    return {value: val, label: val}
  })

  let segmentMap = []
  if(params) segmentMap = params.segment[selector].map( (val, idx) => {
    return {value: val, label: val}
  })

  useEffect( () => {
    if(buyer && buyer.businessType) setBusinessType(buyer.businessType)
    if(buyer && buyer.yearFounded) setYearFounded(buyer.yearFounded)
    if(buyer && buyer.companySegment) setCompanySegment(buyer.companySegment)
  }, [ buyer ])

  const handleNextStep = () => {
    if(!businessType || !yearFounded || !companySegment) {
      toast.error(formatMessage(messages.errorRequired))
      return
    }
    const currentYear = new Date().getFullYear()
    if(yearFounded < 1900 || yearFounded > currentYear) {
      toast.error(formatMessage(messages.errorInvalid))
      return
    }
    addFeature({ businessType, yearFounded: yearFounded, companySegment })
    history.push('/buyer-step-details-2')
  }

  return (
    <Container>
      <div className='bg-image' />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <div className='fields'>
              <div className='location'>
                <Select
                  theme='default'
                  tooltip={formatMessage(messages.businessTypeDetail)}
                  options={params ? optionMap : []}
                  placeholder={formatMessage(messages.businessType)}
                  value={
                    buyer && optionMap.length
                      ? businessType 
                      : null
                  }
                  onChange={({ target }) => setBusinessType(target.value)}
                />
                <Input
                  type='number'
                  placeholder={formatMessage(messages.foundationYear)}
                  value={yearFounded}
                  onChange={({ target }) =>
                    setYearFounded(parseInt(target.value))
                  }
                />
              </div>
              <Select
                theme='default'
                tooltip={formatMessage(messages.segmentDetail)}
                options={params ? segmentMap : []}
                placeholder={formatMessage(messages.segment)}
                value={
                  buyer && segmentMap.length
                    ? companySegment
                    : null
                }
                onChange={({ target }) => setCompanySegment(target.value)}
              />
            </div>
            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to='/buyer-step-name'>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to='/buyer-step-name'>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

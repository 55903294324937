import { getFirebase } from 'react-redux-firebase'
import { negotiationStatus } from 'utils'
import { NEGOTIATIONS_COLLECTION } from '../consts/collections'
import { setStatus as setStatusBatch } from './batchLogic'
import { userHasRole } from './helper'
import { setStatus as setStatusLoi } from './loiLogic'

export const acceptNegotiation = async (
  dispatch,
  getState,
  negotiation,
  user
) => {
  const firebase = getFirebase()

  const { id } = negotiation
  const isFarmer = Object.keys(user.roles).includes('farmer')

  const data = {
    acceptance: {
      [isFarmer ? 'farmer' : 'buyer']: {
        date: firebase.firestore.Timestamp.fromDate(new Date()),
        id: firebase.auth().currentUser.uid,
      },
      ...(negotiation.acceptance || {})
    },
  }

  if (!userHasRole(['buyer', 'farmer', 'admin'])) {
    dispatch({
      type: 'negotiations/accept/error',
      error: {
        code: 'no-permission',
        message: 'User has no permission to change status',
      },
    })
    return
  }

  return firebase
    .firestore()
    .collection(NEGOTIATIONS_COLLECTION)
    .doc(id)
    .set({...negotiation, ...data})
    .then((result) => {
      if(data.acceptance.farmer && data.acceptance.buyer) {
        setStatusLoi(dispatch, null, negotiation.loiId.id, negotiationStatus.LOADING_CARGO)
        setStatusBatch(dispatch, null, negotiation.batchId.id, negotiationStatus.AWATING_LOADING)
      }

      dispatch({ type: 'negotiations/accept/accepted', result })
    })
    .catch((error) => {
      dispatch({ type: 'negotiations/accept/error', error })
    })
}

export const rejectNegotiation = async (
  dispatch,
  getState,
  negotiation,
  user
) => {
  const firebase = getFirebase()

  const isFarmer = Object.keys(user.roles).includes('farmer')
  const { id } = negotiation

  const data = {
    refusal: {
      [isFarmer ? 'farmer' : 'buyer']: {
        date: firebase.firestore.Timestamp.fromDate(new Date()),
        id: firebase.auth().currentUser.uid,
      },
      ...(negotiation.refusal || {}),
    },
  }

  if (!userHasRole(['buyer', 'farmer', 'admin'])) {
    dispatch({
      type: 'negotiations/reject/error',
      error: {
        code: 'no-permission',
        message: 'User has no permission to reject negotiation',
      },
    })
    return
  }

  //   const negotiationDoc = await firebase
  //     .firestore()
  //     .collection(NEGOTIATIONS_COLLECTION)
  //     .doc(id)
  //     .get()
  //   const batch = negotiationDoc.data()

  //   if (!userHasAccessToFarm(batch.farm.id)) {
  //     dispatch({
  //       type: 'negotiations/reject/error',
  //       error: {
  //         code: 'no-access',
  //         message: 'User has no access to the batch farm',
  //       },
  //     })
  //     return
  //   }

  return firebase
    .firestore()
    .collection(NEGOTIATIONS_COLLECTION)
    .doc(id)
    .set({...negotiation, ...data})
    .then((data) => {
      dispatch({ type: 'negotiations/reject/rejected', data })
    })
    .catch((error) => {
      dispatch({ type: 'negotiations/reject/error', error })
    })
}

import React, { useState } from 'react'

import Text from 'components/Text'

import * as s from './styles'

function CheckBox({ placeholder, options, hint, name, onChange }) {
  const [selectedBox, setSelectedBox] = useState(null)

  function setBox(value) {
    setSelectedBox(value)
    if (onChange) {
      onChange({ target: { name, value } })
    }
  }

  return (
    <s.Container>
      <s.Placeholder>{placeholder}</s.Placeholder>
      <s.List>
        {options.map((o, index) => (
          <s.Box
            key={index}
            selected={selectedBox === o.value}
            onClick={() => setBox(o.value)}>
            {o.label}
          </s.Box>
        ))}
        {hint && (
          <Text color='silverChalice3' fontSize='14px'>
            {hint}
          </Text>
        )}
      </s.List>
    </s.Container>
  )
}

export default CheckBox

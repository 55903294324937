import { getFirebase } from 'react-redux-firebase'
import { FARMER_COLLECTION as COLLECTION_NAME, USER_COLLECTION, TERMS_COLLECTION } from '../consts/collections'
import { updateDoc } from './helper'

export const create = (dispatch, getState) => {
  const firebase = getFirebase()

  dispatch({type: 'farmer/create', loginType: 'anonymous' })
  firebase.auth().signInAnonymously().then(async (login) => {
      dispatch({type: '/user/login/anonymous', user: login })

      const when = firebase.firestore.Timestamp.fromDate(new Date())
      // await firebase.firestore().collection(USER_COLLECTION).doc(login.user.uid).set({
      //   created: when,
      // })

      const payload = {
        uid: login.user.uid,
        roles: {
          farmer: {
            date: when,
          },
        }
      }
      // updateDoc(dispatch, USER_COLLECTION, login.user.uid, payload, 'user/roles/update', 'user/roles/updated', 'user/roles/error');
      dispatch({
        type: 'farmer/created',
        data: payload,
      })
      await firebase
        .firestore()
        .collection(USER_COLLECTION)
        .doc(login.user.uid)
        .set({
          uid: login.user.uid,
          roles: {
            farmer: {
              date: when,
            },
          },
        })

      // return {
      //   type: 'farmer/created',
      //   data: {
      //     user: login.user,
      //   },
      // }
    })
    .catch(function (error) {
      // console.error(
      //   'Unable to setup session: (Error code ' +
      //     error.code +
      //     ') - ' +
      //     error.message
      // )
      console.error(error)
      dispatch({ type: 'farmer/create/error', error: error })
    })
}

export const addFeature = (dispatch, getState, data, uid) => {
  const firebase = getFirebase()
  const user = uid || firebase.auth().currentUser.uid
  updateDoc(dispatch, COLLECTION_NAME, user, data, 'farmer/add/feature', 'farmer/add/feature/added', 'farmer/add/feature/error')

  // return firebase
  //   .firestore()
  //   .collection(COLLECTION_NAME)
  //   .doc(user)
  //   .set(data, { merge: true })
  //   .then((result) => {
  //     return { type: 'farmer/add/feature/added', data }
  //   })
  //   .catch((error) => {
  //     return { type: 'farmer/add/feature/error', error }
  //   })
}

export const acceptTerms = (dispatch, getState, version) => {
  const firebase = getFirebase()

  const terms = {
    date: firebase.firestore.Timestamp.fromDate(new Date()),
    version: firebase.firestore().collection(TERMS_COLLECTION).doc(version),
  }

  updateDoc(dispatch, COLLECTION_NAME, firebase.auth().currentUser.uid, { terms }, 'farmer/terms/accept', 'farmer/terms/accepted', 'farmer/terms/error')

  // return firebase
  //   .firestore()
  //   .collection(COLLECTION_NAME)
  //   .doc(firebase.auth().currentUser.uid)
  //   .set({ terms }, { merge: true })
  //   .then((result) => {
  //     return { type: 'farmer/terms/accepted', terms }
  //   })
  //   .catch((error) => {
  //     return { type: 'farmer/terms/error', error }
  //   })
}

import styled, { css } from 'styled-components'
import { colors, device } from 'styles'

export const TabsMobile = styled.div`
  display: flex;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${device.untilTablet} {
    overflow-x: auto;
  }
`

export const TabMobileItem = styled.div`
  display: flex;
  font-size: 16px;
  border-bottom: 1px solid ${colors.alto3};
  padding-bottom: 10px;
  color: ${colors.silverChalice5};
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  margin: 50px 0;

  @media ${device.untilTablet} {
    color: ${colors.apple};
    background-color: transparent;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.apple};
    margin: 0 0 20px 5px;
    white-space: nowrap;
  }

  ${({ selected }) =>
    selected &&
    css`
      color: ${colors.apple};
      font-weight: bold;
      border-bottom: 2px solid ${colors.apple};
      @media ${device.untilTablet} {
        color: ${colors.white};
        background-color: ${colors.apple};
        border-radius: 30px;
        font-size: 15px;
        font-weight: 500;
        padding: 10px 18px;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    `}
`

export const Tabs = styled.div`
  display: -webkit-box;
  display: -moz-box;
  width: 100%;
  margin-top: 20px;

  @media ${device.untilTablet} {
    overflow-x: auto;
    min-height: 50px;
  }

  ::-webkit-scrollbar {
    @media ${device.untilTablet} {
      display: none;
    }
  }
`

export const TabItem = styled.div`
  display: flex;
  font-size: 16px;
  border-bottom: 1px solid ${colors.alto3};
  padding-bottom: 10px;
  color: ${colors.silverChalice5};
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px 0;
  min-width: ${({ minWidth }) => minWidth || 'unset'};

  ${({ selected }) =>
    selected &&
    css`
      color: ${colors.apple};
      font-weight: bold;
      border-bottom: 4px solid ${colors.apple};
    `}
`

export const LastTab = styled(TabItem)`
  width: 65%;
  pointer-events: none;
  border-bottom: unset;
`

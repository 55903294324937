import styled, { css } from 'styled-components'
import { colors } from 'styles'

const placeholderSize = '20px'

export const Container = styled.div`
  display: flex;
  width: 100%;

  border-bottom: 2px solid ${colors.defaultInputBorder};

  position: relative;


  .container { 
    height: 60px;
    display: flex;
    flex-direction: column;

  }

  .dropdown {

  }

  .input {
    padding-left: 50px !important;
    font-size: 14pt;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    
    font-style: italic;
    padding-left: 4px;
    color: ${colors.dorado};
    padding-top: ${placeholderSize};

    ${({ isFocused, hasValue }) =>
      (isFocused || hasValue) &&
      css`
        padding-top: ${placeholderSize};
      `}
  }
`



export const Placeholder = styled.span`
  font-weight: bold;
  font-size: ${placeholderSize};
  line-height: ${placeholderSize};
  width: 100%;

  z-index: 99999;
  font-weight: 500;
  position: absolute;
  top: 25%;
  width: auto;
  transition: transform 150ms ease-out, font-size 150ms ease-out;

  ${({ isFocused, hasValue }) =>
    (isFocused || hasValue) &&
    css`
      top: 50%;
      transform: translateY(-125%);
    `}

  color: ${colors.primary};
`

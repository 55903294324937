import { defineMessages } from 'react-intl'

const messages = defineMessages({
  errorRequired: {
    id: 'form.required.fileds',
    defaultMessage: 'Os campos com * são obrigatórios'
  },
  errorInvalid: {
    id: 'companyData.year.invalid',
    defaultMessage: 'O ano é inválido'
  },
  businessType: {
    id: 'companyData.input.businessType',
    defaultMessage: 'Tipo de negócio *',
  },
  businessTypeDetail: {
    id: 'companyData.tooltip.businessTypeDetail',
    defaultMessage:
      'Aqui precisamos saber qual é o tipo de negócio que sua empresa atua.',
  },
  foundationYear: {
    id: 'companyData.input.foundationYear',
    defaultMessage: 'Ano de fundação *',
  },
  segment: {
    id: 'companyData.input.segment',
    defaultMessage: 'Segmento da empresa *',
  },
  segmentDetail: {
    id: 'companyData.tooltip.segmentDetail',
    defaultMessage: 'Qual é o segmento ou segmentos que a sua empresa atua.',
  },
  step: {
    id: 'companyDetailsData.text.step',
    defaultMessage: 'etapa 3 de 9',
  },
  back: {
    id: 'companyData.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'companyData.button.continue',
    defaultMessage: 'Continuar',
  },
})

export default messages

import styled from 'styled-components'
import { colors } from 'styles'

export const Container = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  margin-top: ${({ marginTop }) => marginTop || '24px'};

  & > :not(:last-child) {
    margin-bottom: 15px;
  }
`

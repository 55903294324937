import styled from 'styled-components'
import { device } from 'styles'

export const Container = styled.div`
  width: 200px;
  height: inherit;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;

  border-width: 1px;
  border-right-style: solid;
  border-right-color: #e3e3e3;

  @media ${device.untilTablet} {
    width: 33.3%;
    align-items: center;
    padding: 0;
  }

  span {
    color: #9d9d9d;
    margin-bottom: 5px;
    text-transform: capitalize;
    @media ${device.untilTablet} {
      display: none;
    }
  }

  strong {
    text-transform: capitalize;
    color: rgba(0, 93, 22, 0.38);
    font-size: 22px;
    font-weight: bold;

    @media ${device.untilTablet} {
      font-size: 16px;
      color: #47ac34;
    }

    @media ${device.untilMobileL} {
      font-size: 13px;
    }
  }
`

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  originatingOffer: {
    id: 'loi.list.originatingOffer',
    defaultMessage: 'Originando oferta',
  },
  productOriginated: {
    id: 'loi.list.productOriginated',
    defaultMessage: 'Produto originado',
  },
  awaitingSaleConfirmation: {
    id: 'loi.list.awaitingSaleConfirmation',
    defaultMessage: 'Aguardando confirmação de venda',
  },
  canceled: {
    id: 'loi.list.canceled',
    defaultMessage: 'Cancelado',
  },
  sold: {
    id: 'loi.list.sold',
    defaultMessage: 'Vendido',
  },
  day: {
    id: 'loi.list.day',
    defaultMessage: 'dia',
  },
  days: {
    id: 'loi.list.days',
    defaultMessage: 'dias',
  },
  expired: {
    id: 'loi.list.expired',
    defaultMessage: 'Expirada',
  },
  loadingCargo: {
    id: 'loi.list.loadingCargo',
    defaultMessage: 'Carregamento na propriedade rural agendado',
  },
  inTransit: {
    id: 'loi.list.inTransit',
    defaultMessage: 'Em trânsito',
  },
  done: {
    id: 'loi.list.done',
    defaultMessage: 'Comercialização concluída',
  },
  crossDocking: {
    id: 'loi.list.crossDocking',
    defaultMessage: 'Cross Docking',
  },
  inCargoShip: {
    id: 'loi.list.inCargoShip',
    defaultMessage: 'On Board',
  },
  inPort: {
    id: 'loi.list.inPort',
    defaultMessage: 'Presença de carga - Porto',
  },
  inCertification: {
    id: 'loi.list.inCertification',
    defaultMessage: 'Certificação e fiscalização',
  },
  awaitingNegotiation: {
    id: 'loi.list.awaitingNegotiation',
    defaultMessage: 'Aguardando negociação',
  },
  awaitingLoading: {
    id: 'loi.list.awaitingLoading',
    defaultMessage: 'Aguardando confirmação de carregamento em sua propriedade',
  },
  loadingConfirmed: {
    id: 'loi.list.loadingConfirmed',
    defaultMessage: 'Carregamento confirmado',
  },
  goodMorning: {
    id: 'menu.goodMorning',
    defaultMessage: 'Bom dia, ',
  },
  goodAfternoon: {
    id: 'menu.goodAfternoon',
    defaultMessage: 'Boa tarde, ',
  },
  goodNight: {
    id: 'menu.goodNight',
    defaultMessage: 'Boa noite, ',
  },
})

export default messages

const initialState = {
  error: {},
  loginMethods: {
    account: '',
    checked: null,
    values: []
  },
  success: false
}

export default (state = initialState, action) => {
  switch (action?.type) {
    case 'user/login/methods':
      return {
        ...state,
        loginMethods: {
          checked: null,
          values: [],
          account: ''
        }
      }
    case 'user/login/methods/data':
      return {
        ...state,
        loginMethods: {
          checked: Date.now(),
          values: action.methods,
          account: action.account
        }
      }
    // case 'user/phone/info/data':
    //   return {
    //     ...state,
    //     phoneAccount: action.result,
    //     error: {}
    //   }
    // case 'user/phone/info/nodata':
    //   return {
    //     ...state,
        
    //   }
    case 'user/login':
    case 'user/logout':
      return {...state,
        error: { ...state.error, login: null }
      }
    case 'user/login/error':
      return {
        ...state,
        error: { ...state.error, login: action.error}
      }
    case 'user/created':
      return {
        ...state, ...action.user, success: true, error: {}
      }
    case 'user/create/error':
      return {
        ...state,
        error: { ...state.error, create: action.error}
      }
    case 'user/avatar/error':
      return {
        ...state,
        error: { ...state.error, avatar: action.error}
      }
    case 'phone/confirm/code':
      return {
        ...state,
        error: {}
      }
    case 'user/reset':
      return {
        ...state,
        reset: {
          email: action.email
        },
        error: {}
      }
    case 'user/reset/request/error':
    case 'user/reset/error':
      return {
        ...state,
        error: { ...state.error, reset: action.error}
      }
    case 'user/reseted':
      return {
        ...state,
        reset: {
          ...state.reset,
          success: true
        },
        error: {}
      }
    case 'user/verify':
      return {
        ...state,
        verify: action.detail,
        error: {}
      }
    case 'user/verify/sucess':
      return {
        ...state,
        verify: {
          ...state.verify,
          success: true
        },
        error: {}
      }
    case 'user/verify/error':
      return {
        ...state,
        error: { ...state.error, verify: action.error}
      }
    case 'user/error/dismiss':
      return {
        ...state, error: {}
      }
    default:
      return state
  }
}


import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'addresses.title',
    defaultMessage: 'Meus endereços',
  },
  addAddress: {
    id: 'addresses.button.addAddress',
    defaultMessage: 'Adicionar endereço',
  },
})

export default messages

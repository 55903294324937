import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { parseMoneyToCurrency, getDays, getLoiStatusDescription } from 'utils'
import { Tabs } from 'components/Tabs'
import * as s from './styles'
import messages from './messages'
import useWindowSize from 'modules/data/hooks/useWindowSize'
import { device } from 'styles'

function LoiList({ lois, openNegotiation }) {
  const { formatMessage } = useIntl()
  const [width] = useWindowSize()
  const [filters, setFilters] = useState([
    { name: formatMessage(messages.all), selected: true },
    { name: formatMessage(messages.originatingOffer) },
    { name: formatMessage(messages.productOriginated) },
    { name: formatMessage(messages.sold) },
    { name: formatMessage(messages.canceled) },
  ])

  const getLoisByFilters = () => {
    if (filters[0].selected) {
      return lois
    } else if (filters[1].selected) {
      return lois.map((loi) => {
        if (loi.status === 'originatingOffer') return loi
        return false
      })
    } else if (filters[2].selected) {
      return lois.map((loi) => {
        if (
          loi.status !== 'originatingOffer' &&
          loi.status !== 'sold' &&
          loi.status !== 'canceled'
        )
          return loi
        return false
      })
    } else if (filters[3].selected) {
      return lois.map((loi) => {
        if (loi.status === 'sold') return loi
        return false
      })
    } else if (filters[4].selected) {
      return lois.map((loi) => {
        if (loi.status === 'canceled') return loi
        return false
      })
    }
  }

  return (
    <React.Fragment>
      <Tabs tabs={filters} setTabs={setFilters} hasLast mobile />
      <s.List>
        {getLoisByFilters().map(
          (loi, index) =>
            loi && (
              <s.Item
                key={index}
                status={loi.status}
                onClick={() => openNegotiation(loi)}>
                <s.Img src={loi.product.url_negotiation} />
                <s.Content>
                  <s.Header>
                    <s.Title>{loi.product.name_en}</s.Title>
                    <s.StatusHeader>
                      {formatMessage(
                        loi.status !== 'canceled'
                          ? messages.productOriginated
                          : messages.canceled
                      )}
                    </s.StatusHeader>
                  </s.Header>
                  <s.Infos>
                    <s.Text defaultColor fontSize='16px'>
                      <b>{loi.tons}</b> {formatMessage(messages.tons)}
                    </s.Text>
                    <s.Time>
                      <s.Text italic>
                        {formatMessage(messages.remainingTime)}{' '}
                      </s.Text>
                      <s.Text defaultColor fontSize='14px'>
                        <b>{getDays(loi.date, Number(loi.offerTime))}</b>
                      </s.Text>
                    </s.Time>
                  </s.Infos>
                  <s.Text italic>
                    {parseMoneyToCurrency(loi.targetPricePerTon, 'USD')}
                  </s.Text>
                  <s.StatusFooter>
                    <s.Text color='white' italic>
                      {formatMessage(getLoiStatusDescription(loi.status))}
                    </s.Text>
                  </s.StatusFooter>
                </s.Content>
                {width > device.screen.tablet && <s.StatusIcon />}
              </s.Item>
            )
        )}
      </s.List>
    </React.Fragment>
  )
}

export default LoiList

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'buyerHome.modal.title',
    defaultMessage: 'Bem-vindo(a) Comprador(a) Global'
  },
  message : {
    id: 'buyerHome.modal.message',
    defaultMessage: 'Seu cadastro está sendo analisado. Quando for apravado você recebrá um e-mail e poderá acessar a plataforma.'
  }
})

export default messages

import React from 'react'

import { Container, Header, Footer, Content } from './styles'

function Appointment(props) {
  return (
    <Container>
      <Header>
        <h1>Cancelar visita</h1>
      </Header>
      <Content>
        <span>
          Você tem certeza que deseja cancelar uma visita à sua propriedade?
        </span>
        <span>Sem a visita técnica você não poderá cadastrar seus LOTES.</span>
        <span>Este é um momento importante para validação dos seus dados.</span>
      </Content>
      <Footer>
        <div id='buttons'>
          <button id='cancel' onClick={props.closeCancel}>
            Fechar
          </button>
          <button id='finishing' onClick={props.cancelSchedule}>
            Cancelar Visita
          </button>
        </div>
      </Footer>
    </Container>
  )
}

export default Appointment

import Farm from './Farm'
import WheatBag from './WheatBag'
import MoneyBag from './MoneyBag'
import Farmer from './Farmer'
import Clipboard from './Clipboard'
import ArrowDown from './ArrowDown'
import Clock from './Clock'
import Truck from './Truck'
import Check from './Check'
import Close from './Close'
import QuestionMark from './QuestionMark'
import Attachment from './Attachment'
import FileStatus from './FileStatus'
import Buyer from './Buyer'
import Match from './Match'
import Waiting from './Waiting'
import Lock from './Lock'

export default {
  Farm,
  WheatBag,
  MoneyBag,
  Farmer,
  Clipboard,
  ArrowDown,
  Clock,
  Truck,
  Check,
  Close,
  QuestionMark,
  Attachment,
  FileStatus,
  Buyer,
  Match,
  Waiting,
  Lock,
}

import React, { useState, useEffect } from 'react'
import { ErrorMessage } from 'components/ErrorMessage'
import { useUser } from 'modules/data/hooks/useUser'
import { useFarmer } from 'modules/data/hooks/useFarmer'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useFarm } from 'modules/data/hooks/useFarm'
import { usePhoneVerifier } from 'modules/data/hooks/usePhoneVerifier'
import { errorModule } from 'modules/errors'
import { useIntl } from 'react-intl'

export default function ErrorAgent(props) {
	const [ errorMap, setErrorMap ] = useState({})
	const user = useUser()
	const farmer = useFarmer()
	const buyer = useBuyer()
	const farm = useFarm()
	const phone = usePhoneVerifier()
	const { formatMessage } = useIntl()
	const HIDE = true
	const SHOW = false

	const errorList = errorModule[props.group]
	const agentMap = { 
		user: user, 
		buyer: buyer, 
		farmer: farmer, 
		farm: farm,
		phone: phone
	}
	const agent = agentMap[ errorList.selector.agent ]
	const selector = errorList.selector.field

	useEffect( () => {
		let obj = {}
		for(let i in errorList.codes) obj[errorList.codes[i].code] = HIDE
		if(errorList.default) obj.default = HIDE
		setErrorMap(obj)
	// eslint-disable-next-line
	}, [ ])


	useEffect( () => {
		if(agent.error && agent.error[selector]) {
			if(agent.error[selector].code === undefined) {
				if(agent.error[selector].default) {
					setErrorMap( { ...errorMap, default: SHOW })
					if(props.onError) props.onError(agent.error[selector].default)
				} else {
					if(props.onError) props.onError({ code: 'unmapped-error', message: 'Unmapped erroor.'})
				}
			} else {
				// console.log(selector, agent.error[selector])
				setErrorMap( { ...errorMap, [agent.error[selector].code]: SHOW })
				if(props.onError) props.onError(agent.error[selector].code)
			}
		}
		// eslint-disable-next-line
	}, [ agent.error ])

	const clear = code => {
		agent.dismiss()
		setErrorMap({ ...errorMap, [code]: HIDE })
		if(props.onClear) props.onClear(code)
	}

	const errorMessages = errorList.codes.map((error, pos) => {
		return (
			<ErrorMessage
				hide={agent.error && errorMap[error.code]}
				title={formatMessage(errorList.title)}
				message={formatMessage(error)}
				mode='dialog'
				onClose={e => clear(error.code)}
				key={pos}
			/>
		)
	})
	if(errorList.default) errorMessages.push(
		<ErrorMessage
			hide={agent.error && errorMap.default}
			title={formatMessage(errorList.title)}
			message={formatMessage(errorList.default)}
			mode='dialog'
			onClose={e => clear('default')}
			key='default'
		/>
	)

	return(<div>{errorMessages}</div>)
}
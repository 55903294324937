import React, { useState, useEffect, useCallback } from 'react'
import {
  Container,
  Header,
  Form,
  Cards,
  OqueProduz,
  MapDescription,
} from './styles'
import Title from 'components/Title'
import Text from 'components/Text'
import Select from 'components/Select'
import CustomInput from 'components/CustomInput'
import SearchAddress from 'components/SearchAddress'
import InputCpf from 'components/InputCpf'
import { useFarm } from 'modules/data/hooks/useFarm'
import { useProduct } from 'modules/data/hooks/useProduct'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import { toast } from 'react-toastify'
import { maps } from 'modules/data/consts/apiTokens'
import useWindowSize from 'modules/data/hooks/useWindowSize'
import { device } from 'styles'

function Registerfarm(props) {
  const [yes, setYes] = useState(false)
  const [no, setNo] = useState(true)

  const [name, setName] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [storage, setStorage] = useState(1)
  const [ie, setIe] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')
  const [zone, setZone] = useState('')
  const [coords, setCoords] = useState('')
  const [geo, setGeo] = useState('')
  const [cpfList, setCpfList] = useState([])
  const [productList, setProductList] = useState([])
  const [mapService, setMapService] = useState({})
  const [status, setStatus] = useState({})
  const { create, update, farm, loaded } = useFarm(props.farmId)
  const { all } = useProduct()
  const [width] = useWindowSize()

  useEffect(() => {
    if (!farm || !loaded) {
      return
    }
    setName(farm.name)
    setCnpj(farm.cnpj)
    setStorage(farm.storage)
    setIe(farm.ie)
    setCity(farm.city)
    setState(farm.state)
    setCountry(farm.country)
    setZone(farm.zone)
    setGeo(farm.geo)
    setStatus(farm.status)
    setCpfList(farm.cpfList)
    setProductList(farm.productList.map((product) => product.id))
    // eslint-disable-next-line
  }, [loaded])

  const { edit } = props

  const initService = (mapProps, map) =>
    setMapService({
      map,
      service: new props.google.maps.places.PlacesService(map),
    })

  const updateMap = (value) => {
    if (coords) return
    const { google } = props
    if (mapService.service) {
      const request = {
        query: `${city},${state},${country}`,
        fields: ['name', 'geometry'],
      }

      mapService.service.findPlaceFromQuery(
        request,
        function (results, status) {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            mapService.map.setCenter(results[0].geometry.location)
          }
        }
      )
    }
  }
  const addMarker = (map, location, event) => {
    if (location.zoom > 16) {
      setCoords(event.latLng)
      setGeo(event.latLng.toString().replace(/[{()}]/g, ''))
    }
  }

  useEffect(() => {
    if (geo && mapService.map) focusMarker()
    // eslint-disable-next-line
  }, [geo, mapService.map])

  const focusMarker = useCallback(
    (event) => {
      const splitCoord = geo.split(',')
      const newCoords = new props.google.maps.LatLng(
        parseFloat(splitCoord[0]),
        parseFloat(splitCoord[1])
      )
      setCoords(newCoords)
      mapService.map.setCenter(newCoords)
      mapService.map.setZoom(16)

      const geocoder = new props.google.maps.Geocoder()
      geocoder.geocode({ location: newCoords }, (results, status) => {
        if (status === 'OK') {
          results[0].address_components.forEach((result) => {
            if (result.types.includes('administrative_area_level_2'))
              setCity(result.long_name)
            if (result.types.includes('administrative_area_level_1'))
              setState(result.long_name)
            if (result.types.includes('country')) setCountry(result.long_name)
          })
        }
      })
    },
    [geo, mapService.map, props.google.maps.Geocoder, props.google.maps.LatLng]
  )

  useEffect(() => {
    if (geo && mapService.map) focusMarker()
  }, [geo, mapService.map, focusMarker])

  let ProductGallery

  if (all && all.length) {
    ProductGallery = all.map((product) => {
      return (
        <div
          className={productList.includes(product.id) ? 'card active' : 'card'}
          onClick={(e) => toggleProduct(product.id)}
          key={product.id}>
          <img src={product.url} alt='product' title={product.name} />
          <p>{product.name}</p>
        </div>
      )
    })
  }

  let CPFInputList
  if (cpfList && cpfList.length) {
    CPFInputList = cpfList.map((cpf, i) => {
      return (
        <div>
          <InputCpf
            placeholder='CPF extra'
            value={cpf}
            onChange={(e) => updateCpf(i, e.target.value)}
          />
          <a href='#1' onClick={(e) => removeCPF(i)}>
            Remover
          </a>
        </div>
      )
    })
  }
  const updateCpf = (idx, value) => {
    let newArr = [...cpfList]
    newArr[idx] = value
    setCpfList(newArr)
  }
  const addCPF = () => {
    let newArr = [...cpfList]
    newArr.push('')
    setCpfList(newArr)
  }
  const removeCPF = (idx) => {
    let newArr = [...cpfList]
    newArr.splice(idx, 1)
    setCpfList(newArr)
    if (newArr.length === 0) toggleAdditionalCPF()
  }

  const toggleProduct = (id) => {
    if (productList.includes(id)) {
      setProductList(productList.filter((i) => i !== id))
    } else {
      setProductList((productList) => [...productList, id])
    }
  }

  const toggleAdditionalCPF = () => {
    setYes(!yes)
    setNo(!no)
    if (!yes) addCPF()
  }

  const toggleRemoveCPF = () => {
    setYes(!no)
    setNo(!yes)
    if (!no) removeCPF()
  }

  const addFarm = (event) => {
    if (
      !name ||
      !storage ||
      !ie ||
      !city ||
      !state ||
      !country ||
      !geo ||
      !productList ||
      !cpfList ||
      !status
    ) {
      toast.error('Todos os campos com * devem ser preenchidos')
      return
    }

    if (props.farmId) {
      update({
        name,
        cnpj,
        storage,
        ie,
        city,
        state,
        country,
        zone,
        geo,
        productList,
        cpfList,
        status,
      })
    } else {
      create({
        name,
        cnpj,
        storage,
        ie,
        city,
        state,
        country,
        zone,
        geo,
        productList,
        cpfList,
      })
    }
    props.hideModal(event)
  }

  const mapStyles = {
    width: width > device.screen.tablet ? '90%' : '100%',
    // height: '30vh',
  }
  const containerStyle = {
    position: 'relative',
    width: '100%',
  }

  const handleAddress = (data, place) => {
    setCity(data.city)
    setState(data.state)
    setCountry(data.country)
    mapService.map.setCenter(place.geometry.location)
  }

  return (
    <Container>
      <Header>
        <Title>Cadastro de Propriedade</Title>
        <Text>
          Você precisa ter uma propriedade para pode cadastrar seus lotes.
        </Text>
      </Header>
      <hr />
      <Form>
        <div id='farm-data'>
          <CustomInput
            placeholder='Nome da fazenda *'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <CustomInput
            placeholder='CNPJ, se houver'
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
          />
          <Select
            placeholder='Tipo de armazenagem *'
            options={[
              { label: 'Silo', value: 1 },
              { label: 'Armazém', value: 2 },
              { label: 'Big Bag', value: 3 },
            ]}
            value={storage}
            onChange={(e) => setStorage(e.target.value)}
          />
        </div>
        <div id='farm-location'>
          <SearchAddress
            placeholder='Município *'
            value={city}
            onChange={(e) => setCity(e.target.value)}
            onResult={(r, l) => handleAddress(r, l)}
            onBlur={updateMap}
          />

          <MapDescription>
            Encontre a localização de sua propriedade rural no mapa e dê dois
            cliques para fixar o ponto exato. *
          </MapDescription>

          <CustomInput
            placeholder='Inscrição estadual *'
            value={ie}
            onChange={(e) => setIe(e.target.value)}
          />
          <CustomInput
            disabled
            placeholder='Estado *'
            value={state}
            onChange={(e) => setState(e.target.value)}
            onBlur={updateMap}
          />
          <CustomInput
            disabled
            placeholder='País *'
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            onBlur={updateMap}
          />
          <CustomInput
            placeholder='Zona/Localidade'
            value={zone}
            onChange={(e) => setZone(e.target.value)}
          />
          <CustomInput
            placeholder='Coordenadas Geográficas *'
            value={geo}
            onChange={(e) => setGeo(e.target.value)}
            onBlur={focusMarker}
          />
        </div>

        <div id='image' style={{marginTop: '25px'}}>
          <Map
            google={props.google}
            zoom={8}
            style={mapStyles}
            containerStyle={containerStyle}
            centerAroundCurrentLocation={true}
            onReady={initService}
            onDblclick={addMarker}
            mapType={props.google.maps.MapTypeId.HYBRID}>
            {coords ? <Marker title={name} position={coords} /> : ''}
          </Map>
        </div>

        <OqueProduz>
          O que você produz? (pode selecionar mais de um) *
        </OqueProduz>
        <Cards>{ProductGallery}</Cards>

        <strong>
          Há mais um CPF que possa comercializar nesta propriedade? *
        </strong>
        <div className='option'>
          <div onClick={toggleAdditionalCPF} className='container-check'>
            <div className='item'>{yes && <div className='check' />}</div>
            <span>Sim</span>
          </div>
          <div onClick={toggleRemoveCPF} className='container-check'>
            <div className='item'>{no && <div className='check' />}</div>
            <span>Não</span>
          </div>
        </div>
        {cpfList.length ? (
          <div id='cpf'>
            {CPFInputList}
            <a href='#1' onClick={addCPF}>
              Adicionar mais CPFs
            </a>
          </div>
        ) : (
          ''
        )}

        {width < device.screen.tablet && (
          <div id='buttonContainer'>
            <button id='finishing' onClick={addFarm} type='button'>
              Concluir
            </button>
            <button
              id='cancel'
              type='button'
              onClick={(event) => props.hideModal(event)}>
              Cancelar
            </button>
          </div>
        )}

        {width > device.screen.tablet && (
          <footer>
            <span>Cadastrar propriedade:</span>

            <div>
              <button
                id='finishing'
                onClick={addFarm}
                type='button'
                style={{
                  opacity: edit ? 0.4 : 1,
                }}
                disabled={edit}>
                Concluir
              </button>
              <button
                id='cancel'
                type='button'
                onClick={(event) => props.hideModal(event)}>
                Cancelar
              </button>
            </div>
          </footer>
        )}
      </Form>
    </Container>
  )
}

export default GoogleApiWrapper({
  apiKey: maps.key,
})(Registerfarm)

import React from 'react'
import SVG from 'components/SVG'

import * as s from './styles'

export default function StepProgressBar({ steps, currentStep, style }) {
  return (
    <s.ProgressContainer>
      {steps.map((step, i) => (
        <s.Step>
          <s.StepContainer>
            <s.StepShapeContainer>
              <s.StepShape disabled={i > currentStep}>
                {i <= currentStep ? (
                  <SVG.Check color={'white'} height={15} width={15} />
                ) : (
                  <SVG.Waiting color={'white'} height={15} width={15} />
                )}
              </s.StepShape>
              <s.StepTitle disabled={i > currentStep}>{step.title}</s.StepTitle>
            </s.StepShapeContainer>
            {i < steps.length - 1 && <s.StepLine />}
          </s.StepContainer>
        </s.Step>
      ))}
    </s.ProgressContainer>
  )
}

import styled from 'styled-components'
import { colors, device } from 'styles'

export const Container = styled.div`
  display: inline-block;
  flex: 1;
  width: 90%;
  height: 100%;
  overflow: scroll;
  justify-content: center;
  align-items: center;
  padding-bottom: 200px;
`

export const Content = styled.div`
  width: 100%;

  background: ${colors.white};
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.31);
  border-radius: 5px;

  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;
  min-height: 400px;
`

export const Form = styled.form``

export const FormContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;

  width: 100%;

  padding-top: 60px;

  & > * {
    width: 100%;
    margin-bottom: 15px;
  }

  @media ${device.fromTablet} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 40px;

    & > * {
      max-width: 45%;
      width: 100%;
    }
  }
`

export const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;

`

export const PlusButton = styled.button`
  background: ${colors.primary};
  color: ${colors.white};
  border: none;
  border-radius: 100%;
  width: 25px;
  height: 25px;
`

export const StepStatus = styled.p`
  color: ${colors.silver5};
  font-style: italic;
  font-size: 16px;
  margin-top: 15px;
`

export const ReportContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ReportLine = styled.div`
  display: flex;
  margin-bottom: 10px;
`

export const Alert = styled.b`
  color: ${colors.danger};
  margin-right: 10px;
`

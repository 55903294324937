import { getFirebase } from 'react-redux-firebase'
import { MAIL_COLLECTION, FARM_COLLECTION, FARMER_COLLECTION } from '../consts/collections'


export const send = mail => {
	const firebase = getFirebase()

	firebase.firestore().collection(MAIL_COLLECTION).add({...mail, date: firebase.firestore.Timestamp.fromDate(new Date())})
}

export const forEachFarmer = (farmId, callback) => {
	const firebase = getFirebase()

	firebase.firestore().collection(FARM_COLLECTION).doc(farmId).get()
		.then(doc => {
			const data = doc.data()
			data.accessList.forEach( farmerRef => {
				firebase.firestore().collection(FARMER_COLLECTION).doc(farmerRef.id).get()
				.then(farmerDoc => {
					const farmerData = farmerDoc.data()

					callback(farmerData)
				})
			})
		})
}
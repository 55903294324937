import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'verifyPhoneNumber.title',
    defaultMessage: 'Validar telefone',
  },
  info: {
    id: 'verifyPhoneNumber.text.info',
    defaultMessage:
      'Em breve você receberá uma mensagem contendo um código de 6 dígitos. Digite-o abaixo para validr seu telefone',
  },
  done: {
    id: 'verifyPhoneNumber.text.done',
    defaultMessage: 'Seu número de telefone já foi devidamente validado',
  },
  send: {
    id: 'verifyPhoneNumber.button.send',
    defaultMessage: 'Enviar código',
  },
  resend: {
    id: 'verifyPhoneNumber.button.resend',
    defaultMessage: 'Reenviar código',
  },
  back: {
    id: 'verifyPhoneNumber.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'verifyPhoneNumber.button.continue',
    defaultMessage: 'Validar Telefone',
  },
  finish: {
    id: 'verifyPhoneNumber.button.finish',
    defaultMessage: 'Finalizar Cadastro'
  }
})

export default messages

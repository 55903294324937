import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
  Cards
} from '../styles'
import Button from 'components/Button'
import logo from 'assets/logo-green.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useProduct } from 'modules/data/hooks/useProduct'
import { useIntl } from 'react-intl'
import messages from './messages'
import { toast } from 'react-toastify'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { Title } from './styles'

export default function CompanyFinishingDetails() {
  const history = useHistory()
  const [products, setProducts] = useState([])
  const [productMap, setProductMap] = useState([])
  const [onDemand, setOnDemand] = useState(false)
  const { addFeature, buyer } = useBuyer()
  const { all } = useProduct()
  const { formatMessage, locale } = useIntl()

  const CustomTooltip = withStyles((props) => ({
    tooltip: {
      backgroundColor: 'rgba(0, 93, 22);',
      color: 'white',
      maxWidth: 260,
      fontSize: 15,
      borderRadius: '5px',
      padding: 10,
    },
    arrow: {
      color: 'rgba(0, 93, 22);',
    },
  }))(Tooltip)

  useEffect(() => {
    if (buyer && buyer.products) {
      setProducts(buyer.products || [])
      setOnDemand(buyer.buyOnDemand)
    }
  }, [buyer])

  const handleNextStep = () => {
    if (!products.length) {
      toast.error(formatMessage(messages.error))
      return
    }
    addFeature({
      products,
      buyOnDemand: onDemand,
    })
    history.push('/buyer-step-email')
  }

  useEffect(() => {
    let selector = 'name'
    if (all && locale) {
      selector = locale === 'pt-br' ? 'name' : 'name_en'
      let productMap = []
      productMap = all.map((product) => {
        return { id: product.id, name: product[selector] }
      })
      setProductMap(productMap)
    }
  }, [all, locale])

  const handleProductClick = (product) => {
    const productIn = products.find(({ id }) => product.id === id)
    if (productIn) {
      setProducts(products.filter((item) => item.id !== product.id))
    } else {
      setProducts([...products, product])
    }
  }

  console.log(productMap)

  const renderProducts = () => {
    if (productMap && productMap.length > 0) {
      return productMap.map((product) => (
        <div
          style={{ cursor: 'pointer' }}
          className={
            products.find(({ id }) => product.id === id) ? 'card active' : 'card'
          }
          onClick={() => {
            handleProductClick(product)
          }}
          key={product.id}>
          {product.name}
        </div>
      ))
    }
  }


  return (
    <Container>
      <div className='bg-image' />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <div className='fields'>
              <Title className='title'>{formatMessage(messages.whichProducts)}</Title>
              <Cards>{renderProducts()}</Cards>
              {/* <InputTag
                title={formatMessage(messages.whichProducts)}
                tags={products}
                suggestions={productMap}
                className='mobile-text'
                placeholderText={formatMessage(
                  messages.whichProductsPlaceholder
                )}
                value={products}
                onChange={({ target }) => setProducts(target.value)}
                onAddition={addProduct}
                onDelete={deleteProduct}
              /> */}
              <CustomTooltip title={formatMessage(messages.tooltipOnDemand)} arrow>
                <Title className='title'>
                  {formatMessage(messages.onDemand)}
                </Title>
              </CustomTooltip>
              <div className='option'>
                <div
                  onClick={(e) => setOnDemand(true)}
                  className='container-check'>
                  <div className='item'>
                    {onDemand && <div className='check' />}
                  </div>
                  <span>{formatMessage(messages.yes)}</span>
                </div>
                <div
                  onClick={(e) => setOnDemand(false)}
                  className='container-check'>
                  <div className='item'>
                    {!onDemand && <div className='check' />}
                  </div>
                  <span>{formatMessage(messages.no)}</span>
                </div>
              </div>
            </div>
            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to='/buyer-step-details-2'>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to='/buyer-step-details-2'>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

import styled from 'styled-components'
import { colors, device } from 'styles'
import MenuIcon from 'assets/menu.png'

import { Menu as UiMenu, MenuItem as UiMenuItem } from '@material-ui/core'

export const Container = styled.div``
export const Menu = styled(UiMenu)`
  .MuiMenu-paper {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: #005d16;
    color: ${colors.white};
    top: 95px !important;
    left: 0 !important;
    border-radius: 0;

    a {
      display: inline-flex;
      width: 100%;
      padding: 20px 28px;
      color: ${colors.white};
      text-decoration: none;

      &:hover {
        background-color: #005113;
      }
      & svg {
        margin-right: 30px;
      }
      & path {
        fill: ${colors.white};
      }

      span {
        display: block;
        font-size: 16px;
        font-weight: 300;
      }
      strong {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.6px;
      }
    }
    ul {
      height: 100%;
    }
    li {
      padding: 13px 30px;
      font-weight: 300;
      &:nth-child(1) {
        border-bottom: 1px solid #000;
      }
    }
  }

  .MuiList-padding {
    padding-top: 2px;
  }
`

export const MenuItem = styled(UiMenuItem)`
  background: #005d16;
`

export const Header = styled.header`
  background: #f8f8f8;
  height: 80px;
  display: flex;

  @media ${device.untilTablet} {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    z-index: 999;
  }
`

export const HeaderMobile = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #005d16;
  height: 100px;
`
export const LogoMobile = styled.div`
  width: calc(100% / 3);
  margin-right: 15px;
  display: flex;
  justify-content: center;

  img {
    width: 80px;
    margin-top: 15px;
  }
`

export const AvatarMobile = styled.div`
  width: calc(100% / 3);
  margin-right: 15px;
  display: flex;
  justify-content: center;
  img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
  }
`

export const MenuButtonOpen = styled.div`
  margin: 0 0 0 30px;
  background-image: url(${MenuIcon});
  background-repeat: no-repeat;
  width: calc(100% / 3 - 30px);
  height: 21px;
`

export const HeaderContent = styled.div`
  width: calc(100% - 250px);

  height: inherit;
  display: flex;
  justify-content: space-evenly;

  :last-child {
    flex-grow: 1;
  }

  @media ${device.untilTablet} {
    width: 100%;
  }
`

export const Logo = styled.div`
  width: 250px;
  background: #005d16;
  height: inherit;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const Profile = styled.div`
  @media ${device.untilTablet} {
    width: calc(100% / 3);
    justify-content: flex-end;
  }

  display: flex;
  align-items: center;
  padding: 20px;
  span {
    padding: 20px;
  }

  strong {
    font-weight: bold;
    color: ${colors.camarone};
  }

  .avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
`

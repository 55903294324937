import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
  Cards,
} from '../styles'
import Input from 'components/Input'
import Button from 'components/Button'
import Text from 'components/Text'
import Select from 'components/Select'
import Title from 'components/Title'
import logo from 'assets/logo-green.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useParams } from 'modules/data/hooks/useParams'
import { useIntl } from 'react-intl'
import messages from './messages'
import { toast } from 'react-toastify'

export default function CompanyMoreDetails() {
  const history = useHistory()
  const [numberEmployes, setNumberEmployes] = useState('')
  const [annualSales, setAnnualSales] = useState('')
  const [markets, setMarkets] = useState([])
  const [internal, setInternal] = useState(false)
  const [external, setExternal] = useState(false)
  const [hybrid, setHybrid] = useState(false)
  const { addFeature, buyer } = useBuyer()
  const params = useParams('farmer')
  const { formatMessage, locale } = useIntl()

  useEffect(() => {
    if (buyer && buyer.numberEmployes) {
      setNumberEmployes(buyer.numberEmployes)
      setAnnualSales(buyer.annualSales)
      setMarkets(buyer.markets || [])
      setInternal(buyer.segments.internal)
      setExternal(buyer.segments.external)
      setHybrid(buyer.segments.hybrid)
    }
  }, [buyer])

  const handleNextStep = () => {
    if (!numberEmployes) {
      toast.error(formatMessage(messages.error))
      return
    }
    addFeature({
      numberEmployes,
      annualSales,
      markets,
      segments: {
        internal,
        external,
        hybrid,
      },
    })
    history.push('/buyer-step-details-3')
  }

  const handleCheckInternal = (type) => {
    setInternal(!internal)
    setHybrid(external && !internal)
  }

  const handleCheckHybrid = (type) => {
    setHybrid(!hybrid)
    setInternal(!hybrid)
    setExternal(!hybrid)
  }

  const handleCheckExternal = (type) => {
    setHybrid(internal && !external)
    setExternal(!external)
  }

  let selector = 'en'
  if (params && locale) {
    selector = locale === 'pt-br' ? 'ptBR' : 'en'
  }

  let salesMap = []
  if (params)
    salesMap = params.sales[selector].map((val, idx) => {
      return { value: val, label: val }
    })

  let regionMap = []
  if (params)
    regionMap = params.regions[selector].map((val, idx) => {
      return { id: idx, name: val }
    })

  const handleRegionClick = (region) => {
    const marketIn = markets.find(({ id }) => region.id === id)
    if (marketIn) {
      setMarkets(markets.filter((item) => item.id !== region.id))
    } else {
      setMarkets([...markets, region])
    }
  }

  const renderRegions = () => {
    if (regionMap && regionMap.length > 0) {
      return regionMap.map((region) => (
        <div
          style={{ cursor: 'pointer' }}
          className={
            markets.find(({ id }) => region.id === id) ? 'card active' : 'card'
          }
          onClick={() => {
            handleRegionClick(region)
          }}
          key={region.id}>
          {region.name}
        </div>
      ))
    }
  }

  return (
    <Container>
      <div className='bg-image' />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <div className='fields'>
              <div className='location' style={{ 'margin-bottom': '0px' }}>
                <Input
                  type='number'
                  style={{ width: '100%' }}
                  placeholder={formatMessage(messages.employeesNumber)}
                  value={numberEmployes}
                  onChange={({ target }) => setNumberEmployes(target.value)}
                />
                <Select
                  theme='default'
                  options={params ? salesMap : []}
                  placeholder={formatMessage(messages.salesAnnually)}
                  value={buyer && salesMap.length ? annualSales : null}
                  onChange={({ target }) => setAnnualSales(target.value)}
                />
              </div>
              <Text style={{ 'margin-top': '0px' }}>
                {formatMessage(messages.dontWorry)}
              </Text>
              <Title className='title'>
                {formatMessage(messages.buysInMarkets)}
              </Title>
              <div className='option' style={{ 'margin-bottom': '0px' }}>
                <div onClick={handleCheckInternal} className='container-check'>
                  <div onClick={handleCheckInternal} className='item'>
                    {internal && <div className='check' />}
                  </div>
                  <span>{formatMessage(messages.intern)}</span>
                </div>
                <div onClick={handleCheckExternal} className='container-check'>
                  <div onClick={handleCheckExternal} className='item'>
                    {external && <div className='check' />}
                  </div>
                  <span>{formatMessage(messages.extern)}</span>
                </div>
                <div onClick={handleCheckHybrid} className='container-check'>
                  <div onClick={handleCheckHybrid} className='item'>
                    {hybrid && <div className='check' />}
                  </div>
                  <span>{formatMessage(messages.hybrid)}</span>
                </div>
              </div>

              <Title className='title'>{formatMessage(messages.mainMarkets)}</Title>
              <Cards>{renderRegions()}</Cards>
              {/* <InputTag
                title={formatMessage(messages.mainMarkets)}
                placeholderText={formatMessage(messages.mainMarketsPlaceholder)}
                tags={markets}
                suggestions={regionMap}
                onDelete={deleteMarket}
                onAddition={addMarket}
              /> */}
            </div>
            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to='/buyer-step-details'>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to='/buyer-step-details'>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

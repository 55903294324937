import styled, { css } from 'styled-components'
import { colors } from 'styles'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;

  border-bottom: 2px solid ${colors.defaultInputBorder};

  position: relative;

  ${({ isFocused, hasValue }) =>
    (isFocused || hasValue) &&
    css`
      height: 60px;
    `}

  input {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    font-size: 16px;
    font-style: italic;
    padding-left: 4px;
    color: ${colors.dorado};

    ${({ isFocused, hasValue }) =>
      (isFocused || hasValue) &&
      css`
        padding-top: ${placeholderSize};
      `}
  }
`

const placeholderSize = '20px'

export const Placeholder = styled.span`
  font-weight: bold;
  font-size: ${placeholderSize};
  line-height: ${placeholderSize};
  width: 100%;

  font-weight: 500;
  position: absolute;
  top: 25%;
  width: auto;
  transition: transform 150ms ease-out, font-size 150ms ease-out;

  ${({ isFocused, hasValue }) =>
    (isFocused || hasValue) &&
    css`
      top: 50%;
      transform: translateY(-125%);
    `}

  color: ${colors.primary};
`

import React from 'react'
import { useHistory } from 'react-router-dom'
import { routes } from 'configuration'

import Title from 'components/Title'
import Text from 'components/Text'
import Button from 'components/Button'

import { useFarm } from 'modules/data/hooks/useFarm'
import { useInspection } from 'modules/data/hooks/useInspection'
import { status as farmStatus } from 'modules/data/consts/farm'


import * as s from './styles'

function Wrapper({ children }) {
  return (
    <s.Container>
      <s.Content>{children}</s.Content>
    </s.Container>
  )
}

function Report({ steps, formData, prevStep, farm: farmId, inspection: inspectionId }) {
  const { setStatus } = useFarm(farmId)
  const { addInfo } = useInspection(farmId, inspectionId)
  const history = useHistory()

  const approve = () => {
    if(window.confirm('Você confirma a aprovação da propriedade? Após confirmado, você não poderá mais alterar esse status.')) {
      setStatus(farmStatus.STATUS_APPROVED)
      addInfo({ finalStatus: 'approved'})
      history.push(routes.inspector.inspections)
    }
  }

  const reprove = () => {
    if(window.confirm('Você tem certeza que deseja reprovar a propriedade? Após confirmar essa mensagem, você não poderá mais alterar esse status')) {
      setStatus(farmStatus.STATUS_REPROVED)
      addInfo({ finalStatus: 'reproved'})
      history.push(routes.inspector.inspections)
    }
  }

  const valueMap = value => {
    if (Array.isArray(value)) {
      return value.join(', ')
    }

    switch(value) {
      case 'true': return 'Sim'
      case 'false': return 'Não'
      case null: return <s.Alert>Não preenchido</s.Alert>
      default: return value
    }
  }

  return (
    <Wrapper>
      <Title style={{ marginBottom: 20 }}>Revisão do Relatório de Visita Técnica</Title>
      <s.ReportContent>
        {steps.map((step) => (
          <React.Fragment>
            <Title fontSize='18px' style={{ marginBottom: 15, marginTop: 15 }}>
              {step.name}
            </Title>
            {step.fields.map((f) => (
              <s.ReportLine>
                <Text style={{ marginRight: 10 }}>{f.placeholder}</Text>
                <Text>
                  <b>{valueMap(formData[f.name] || null)}</b>
                </Text>
              </s.ReportLine>
            ))}
          </React.Fragment>
        ))}
      </s.ReportContent>
      <Text style={{ padding: '10px 30px' }}>
        <s.Alert>Atenção:</s.Alert>
        <b>
          Faça a revisão criteriosa dos dados antes de encerrar o relatório, pois após a conclusão, você não poderá mais editar esse relatório.
        </b>
      </Text>
      <s.FooterButtons>
        <Button type='button' onClick={prevStep} background='boulder'>
          Voltar
        </Button>
        <Button type='submit' background='danger' onClick={reprove}>Reprovar Propriedade</Button>
        <Button type='submit' onClick={approve}>Aprovar Propriedade</Button>
      </s.FooterButtons>
    </Wrapper>
  )
}

export { Wrapper, Report }

import styled from 'styled-components'

import { shade } from 'polished'
import { colors } from 'styles'

export const Container = styled.div`
  #content {
    width: 100%;
  }

  overflow-y: scroll;
  width: 100%;
  margin-bottom: 100px;

  /* Handle */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
    margin: 10px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${colors.silver3};
  }
`

export const Card = styled.div`
  margin: 20px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #c4c4c4;
  min-height: 200px;
  width: 80vw;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  #info-container {
    display: flex;
    align-items: center;

    #icon {
      background: #fff;
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      svg {
        fill: #30acc7;
      }
    }
  }

  #info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    strong {
      color: #2d97ae;
    }
    span {
      color: #2d97ae;
      margin-top: 5px;
    }
  }

  #buttons {
    button:nth-child(2) {
      opacity: 0.7;
    }
    button {
      padding: 10px;
      border: 0;
      background: #2d97ae;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      margin: 10px;
      width: 107px;

      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.2, '#2D97AE')};
      }
    }
  }
`

export const Header = styled.div`
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;

  button {
    padding: 10px;
    width: 190px;
    border: 0;
    background: #565656;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;

    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#565656')};
    }
  }

  #title {
    display: flex;
    flex-direction: column;

    span {
      color: #a2a2a2;
    }
    strong {
      color: #005d16;
    }
  }
`

export const Content = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;

  button {
    display: block;
    padding: 10px;
    margin: 10px;
    width: 150px;
    border: 0;
    background: #47ac34;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;

    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#47AC34')};
    }
  }

  span {
    margin-top: 50px;
  }
`

export const Infos = styled.div`
  width: 100%;
  position: relative;
  #header-card {
    margin-top: 15px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 107px;

    #farm-info {
      span {
        display: block;
        margin: 0;
        color: #a3a3a3;
        margin-top: 8px;
      }
    }
  }

  #schedule {
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 370px;
    padding: 10px;
    height: 110px;
    background-color: rgba(0, 189, 0, 0.2);

    .button-list {
      display: flex;
    }

    a:link,
    a:visited,
    a:active {
      text-decoration: none;
    }

    button {
      height: 40px;
    }

    .cancel {
      background: #ff9797;
      border-radius: 5px;
      width: 99px;
      height: 41px;
    }
  }

  #description {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .item {
      color: #adadad;
      margin-right: 50px;
      display: inline-block;
    }

    #wheatbag {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
      margin-right: 100px;
    }

    #moneybag {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }
  }
`

export const Cards = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  justify-content: left;
  align-items: left;
  width: 100%;

  .card {
    width: 130px;
    height: 130px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-right: 25px;
    margin-top: 10px;
    padding-bottom: 15px;

    img {
      width: 50px;
    }
  }
`

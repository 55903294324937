import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'welcomeFarmer.title',
    defaultMessage: 'Olá Produtor(a) Rural!',
  },
  oportunity: {
    id: 'welcomeFarmer.text.oportunity',
    defaultMessage:
      'Aqui você tem a oportunidade de comercializar sua produção para diversos compradores  <strong> globais.</strong>',
  },
  informations: {
    id: 'welcomeFarmer.text.informations',
    defaultMessage:
      'Cadastre suas informações básicas. Fique tranquilo(a), o cadastro é bem rápido.',
  },
  begin: {
    id: 'welcomeFarmer.button.begin',
    defaultMessage: 'Iniciar cadastro',
  },
})

export default messages

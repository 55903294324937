import React, { useState, useEffect } from 'react';

const InactivityLogger = ({ inactiveTimeThreshold, onInactivity }) => {
  const [lastInteraction, setLastInteraction] = useState(Date.now());
  let checkInactiveInterval = null; // Referência para o intervalo de verificação de inatividade

  const checkInactiveTime = () => {
    const currentTime = Date.now();
    const inactiveTime = currentTime - lastInteraction;

    if (inactiveTime > inactiveTimeThreshold) {
      onInactivity();
    }
  };

  const handleUserActivity = () => {
    setLastInteraction(Date.now());
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(checkInactiveInterval);
      } else {
        checkInactiveTime(); // Executa imediatamente ao retomar o foco
        checkInactiveInterval = setInterval(checkInactiveTime, 60 * 1000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(checkInactiveInterval);
    };
  }, [lastInteraction]);

  return <></>;
};

export default InactivityLogger;
import React, { useState, useEffect } from 'react'

import errorIcon from 'assets/error.png'
import { Message } from './styles'

export function ErrorMessage(props) {
	const modeMap = {
		banner: {
			class: 'banner',
			close: 'light'
		},
		popup: {
			class: 'popup',
			close: 'light'
		},
		dialog: {
			class: 'dialog',
			close: 'dark'
		}
	}
	const mode = modeMap[props.mode] || modeMap.banner;
	
	const [ hide, setHide ] = useState(props.hide);

	useEffect( () => {
		if(!props.hide && props.onError) props.onError()
		if(!hide && props.hide && props.onClose) props.onClose()
		setHide(props.hide)
	// eslint-disable-next-line
	}, [props.hide])

	const close = () => {
		setHide(true)
		if(props.onClose) props.onClose() 
	}

	return (
		hide === true ? '' :
		<Message mode={mode.class}>
			<div className='error-img'>
				<img src={errorIcon} alt='error icon' />
			</div>
			<div>
				<h1>{props.title}</h1>
				<p>{props.message}</p>
			</div>
			<span onClick={close} className={'close ' + mode.close}>✕</span>
		</Message>
	)
}
import styled from 'styled-components'
import SignInBackgroundImage from 'assets/SigninBackground.jpg'
import { colors } from 'styles'

export const Container = styled.div`
  height: 100%;
  flex: 1;
  background: url(${SignInBackgroundImage}) no-repeat top;
  background-size: 100% 50%;
  display: flex;
  justify-content: center;
`
export const Logo = styled.div`
  display: flex;
  justify-content: center;
`

export const FormContainer = styled.div`
  height: 100%;
  padding-top: 30px;

  form {
    width: 540px;

    background: ${colors.white};
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.31);
    border-radius: 5px;
    margin-top: 21px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
  }

  @media only screen and (max-width: 590px) {
    form {
      width: 400px;
    }
  }

  @media only screen and (max-width: 450px) {
    form {
      width: 350px;
    }
  }

  @media only screen and (max-width: 390px) {
    form {
      width: 300px;
    }
  }
`

export const Content = styled.div`
  width: 350px;

  & > div:not(:first-child) {
    margin-top: 20px;
  }

  @media only screen and (max-width: 450px) {
    width: 310px;
  }

  @media only screen and (max-width: 390px) {
    width: 270px;
  }
`

export const Footer = styled.div`
  margin-top: 40px;
  .forgot {
    text-align: right;
    color: ${colors.dorado};
  }
  .button {
    margin-top: 30px;
    margin-bottom: 70px;
  }

  .signup {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    .signup-text {
      font-weight: 600;
    }
  }

  a {
    text-decoration: none;
  }
`

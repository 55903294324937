import styled from 'styled-components';
import { colors } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.p`
  color: ${colors.boulder};
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin-bottom: 5px;
`;

const getColor = (props) => {
  if (props.isDragAccept) {
    return colors.success;
  }
  if (props.isDragReject) {
    return colors.fuzzyWuzzyBrown;
  }
  if (props.isDragActive) {
    return colors.curiousBlue;
  }
  return colors.gallery;
}

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: ${colors.concrete};
  outline: none;
  transition: border .24s ease-in-out;
  width: 100%;
`;

export const Text = styled.p`
  color: ${({ error }) => error ? colors.fuzzyWuzzyBrown : colors.silver7};
`;

export const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const File = styled.div`
  position: relative;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > span {
    margin-top: 5px;
    font-size: 15px;
  }

  & > div {
    background-image: url(${({ src }) => src});
    background-repeat: no-repeat;
    background-size: cover;
    width: 100px;
    height: 100px;

    & > svg {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
`;
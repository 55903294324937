import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { PRODUCT_COLLECTION } from '../consts/collections'

export function useProduct(id) {
  useFirestoreConnect([{ collection: PRODUCT_COLLECTION, doc: id }])

  const product = useSelector(
    ({ firestore: { data } }) =>
      data[PRODUCT_COLLECTION] && data[PRODUCT_COLLECTION][id]
  )

  return {
    all: useSelector((state) => state.firestore.ordered[PRODUCT_COLLECTION]),
    product: isLoaded(product) ? product : { loading: true },
  }
}

import { useState, useEffect } from 'react'

export function useMarketInfo() {
  const [dollar, setDollar] = useState(5.33)

  useEffect(() => {
    fetch('https://economia.awesomeapi.com.br/all/USD-BRL').then(
      async (res) => {
        setDollar((await res.json()).USD.high)
      }
    )
  }, [])

  return {
    dollar: parseFloat(dollar).toFixed(2),
  }
}

import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect, getFirebase, isLoaded } from 'react-redux-firebase'
import { USER_COLLECTION } from '../consts/collections'
import { roles } from '../consts/user'
import { useFarmer } from './useFarmer'
import { useBuyer } from './useBuyer'
import { 
	createAccount, check, login, 
	logout, setAvatar, resetPassword, 
	requestPasswordReset, verifyEmail,
} from '../logic/userLogic'

const COLLECTION_NAME = USER_COLLECTION

export function useUser() {
	const dispatch = useDispatch()
	const propsAuth = useSelector((state) => state.firebase.auth)
	const propsState = useSelector(state => state.user)
	const loginMethods = useSelector(state => state.user.loginMethods)
	useFirestoreConnect([{ collection: COLLECTION_NAME, doc: propsAuth.uid }])
	const user = useSelector(state => 
			state.firestore.data[COLLECTION_NAME] && state.firestore.data[COLLECTION_NAME][propsAuth.uid])

	const all = useSelector((state) => state.firestore.ordered[COLLECTION_NAME])
	const { farmer } = useFarmer(propsAuth.uid);
	const { buyer }  = useBuyer(propsAuth.uid);

	let profile = {}
	if(user && user.roles) {
		if(farmer && !farmer.loading && user.roles[roles.FARMER] !== null) profile = {...farmer}
		if(buyer && !buyer.loading && user.roles[roles.BUYER] !== null) profile = {...buyer}
		if(profile.name) profile.first_name = profile.name.split(' ')[0];
	}

	const phoneAuth = propsAuth && propsAuth.phoneNumber !== null
	const checkPhone = getFirebase().functions().httpsCallable('checkPhone')

	return {
		loaded: isLoaded(propsAuth),
		current: propsAuth.uid,
		all: all,	
		user: { ...user, profile },
		roles: user ? user.roles : { loading : true },
		createAccount: (email, password) => dispatch(createAccount, { email, password }),
		createAccountWithPhone: (verification, code) => dispatch(createAccount, { verification, code }), 
		login: (email, password) => dispatch(login, { email, password }),
		logout: () => dispatch(logout, user),
		setAvatar: url => dispatch(setAvatar, url),
		checkEmail: email => check(email, dispatch),
		requestPasswordReset: email => dispatch(requestPasswordReset, email),
		resetPassword: (code, password) => dispatch(resetPassword, code, password),
		checkPhone: async phone => {
			return await checkPhone( { phone: phone.replace(/[{()}]/g, "").replace(/\s/g, "") } )
		},
		verifyEmail: code => dispatch(verifyEmail, code),
		dismiss: () => dispatch({ type: 'user/error/dismiss' }),
		reload: () => propsAuth.currentUser ? propsAuth.currentUser.reload() : null,
		loginMethods,
		phoneAuth,
		...propsAuth,
		...propsState
	}
}


import { SHIPMENT_COLLECTION } from '../consts/collections'
import { updateDoc } from './helper'

export const update = (dispatch, getState, shipmentId, data) => {
  dispatch({ type: 'shipment/update', data })
  updateDoc(
    dispatch,
    SHIPMENT_COLLECTION,
    shipmentId,
    data,
    'shipment/update',
    'shipment/updated',
    'shipment/update/error'
  )
}

export const updateTruck = (
  dispatch,
  getState,
  shipmentId,
  truckData,
  shipment
) => {
  dispatch({ type: 'shipment/update', truckData })

  let shipmentUpdated = { ...shipment, freights: [] }
  shipment.freights.forEach((freight) => {
    // const freight = shipment.freights[freight]
    shipmentUpdated.freights.push({ ...freight, trucks: [] })
    freight.trucks.forEach((truck) => {
      let freightToUpdate = shipmentUpdated.freights.find(element => element.date === freight.date)
      freightToUpdate.trucks.push(
        truck.tag === truckData.tag ? truckData : truck
      )
    })
  })

  updateDoc(
    dispatch,
    SHIPMENT_COLLECTION,
    shipmentId,
    shipmentUpdated,
    'shipment/update',
    'shipment/updated',
    'shipment/update/error'
  )
}

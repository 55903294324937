import styled from 'styled-components'
import homeLogo from 'assets/home_logo.svg'
import { shade } from 'polished'
import { colors, device, fonts } from 'styles'

export const Container = styled.div`
  background: red;
  width: calc(100% - 250px);
  background: url(${homeLogo}) no-repeat center;
  background-size: 100% 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media ${device.untilTablet} {
    width: 100%;
    display: initial;
    padding: 20px;
    margin-top: 70px;
  }
`

export const Banner = styled.div`
  margin: 20px;
  width: 100%;
  border-radius: 10px;
  background: rgba(0, 155, 189, 0.2);
  border: 1px solid #009bbd;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  @media ${device.untilTablet} {
    flex-direction: column;
    height: unset;
    margin: 0;
  }

  #info-container {
    display: flex;
    align-items: center;
    width: 100%;

    #icon {
      background: #fff;
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      @media ${device.untilTablet} {
        display: none;
      }

      svg {
        fill: #30acc7;
      }
    }
  }

  #info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    @media ${device.untilTablet} {
      margin-left: unset;
    }

    strong {
      color: #2d97ae;
    }

    span {
      color: #2d97ae;
      margin-top: 5px;

      @media ${device.untilTablet} {
        margin-top: 10px;
      }
    }
  }

  button {
    padding: 10px;
    border: 0;
    background: #2d97ae;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
    width: 220px;

    &:hover {
      background: ${shade(0.2, '#2D97AE')};
    }

    @media ${device.untilTablet} {
      width: 100%;
      margin: 25px 0 10px;
      height: 40px;
    }
  }
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  padding-top: 55px;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background: ${colors.wildSand};
  border: 1px solid ${colors.mercury3};
  border-radius: 5px;
  flex: 1;
  width: 100%;
  margin: 0 16px;
  min-height: 200px;
`

export const CardTitle = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #494949;
  margin-top: 22px;
`

export const FarmStatus = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: ${colors.apple};
  padding-top: 4px;
`

export const FarmExpiration = styled.p`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: ${colors.silverChalice3};
  padding-top: 30px;
`

export const Counters = styled.div`
  display: flex;
  margin-top: 20px;
`

export const Value = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: ${colors.apple};
  padding-top: 33px;
`

export const Header = styled.div`
  display: none;

  @media ${device.untilTablet} {
    position: fixed;
    display: flex;
    top: 101px;
    right: 0;
    left: 0;
    margin: 0;
    width: 100%;
    padding: 12px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.17);
  }

}
`

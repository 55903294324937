import { getFirebase } from 'react-redux-firebase'
import { updateDoc } from './helper'
import { USER_COLLECTION } from '../consts/collections'

export const createAccount = (dispatch, getState, user) => {
  const firebase = getFirebase()

  var loginType = user.email ? 'email' : 'phone'
  var credential = null
  switch(loginType) {
    case 'phone':
      if(!user.verification || !user.verification.verificationId) {
        dispatch({ type: 'user/create/error', error: {
          code: 'verification-id-missing',
          message: 'Verification code not provided'
        }})
        return
      }
      credential = firebase.auth.PhoneAuthProvider.credential(user.verification.verificationId, user.code)
      break;
    case 'email':
    default:
      credential = firebase.auth.EmailAuthProvider.credential(user.email, user.password)
      break;

  }

  dispatch({ type: 'user/create', user})

  return firebase
    .auth()
    .currentUser.linkWithCredential(credential)
    .then(function (usercred) {
      var user = usercred.user
      if(loginType === 'phone') {
        const when = firebase.firestore.Timestamp.fromDate(new Date())
        firebase.firestore().collection(USER_COLLECTION).doc(user.uid).set({
          phone: {
            number: usercred.user.phoneNumber,
            verified: true,
            when
          }
        }, { merge: true})
      }

      firebase.firestore().collection(USER_COLLECTION).doc(user.uid).get().then(userDoc => {
        user.roles = userDoc.data().roles
        dispatch({ type: 'user/created', user, loginType })
        if(loginType === 'email') {
          dispatch({ type: 'user/email/verification', user })
          getFirebase().auth().currentUser.sendEmailVerification()
            .then( () => dispatch({ type: 'user/email/verification/requested', user }))
            .catch( () => dispatch({ type: 'user/email/verification/error', user }))
          }
        })

    })
    .catch(function (error) {
      console.error(error)
      dispatch({ type: 'user/create/error', error, user })
    })
}

// export const check = (dispatch, getState, email) => {
export const check = (email, dispatch) => 
  new Promise((resolve, reject) => {
    const firebase = getFirebase()

    dispatch({ type: 'user/login/methods', email})
    firebase.auth().fetchSignInMethodsForEmail(email)
      .then(methods => {
        resolve(methods)
        dispatch({ type: 'user/login/methods/data', methods, account: email})
      })
      .catch(error => {
        reject(error)
      })
  })

// export const check = (dispatch, getState, phone) =>  {
export const checkPhone = (phone, dispatch) => 
  new Promise((resolve, reject) => {
  dispatch({ type: 'user/phone/info', phone})
  getFirebase().auth().getUserByPhoneNumber(phone)
    .then(result => {
      dispatch({ type: 'user/phone/info/data', result })
      resolve(result)
    })
    .catch(error => {
      console.error(error)
      dispatch({ type: 'user/phone/info/nodata', error })
      reject(error)
    })
  })

export const requestPasswordReset = (dispatch, getState, email) => {
  dispatch({ type: 'user/reset', email })
  getFirebase().auth().sendPasswordResetEmail(email).then(function() {
    dispatch({ type: 'user/reset/requested', email })
  }).catch(function(error) {
    dispatch({ type: 'user/reset/request/error', email })
  });
}

export const resetPassword = (dispatch, getState, code, password) => {
  getFirebase().auth().verifyPasswordResetCode(code)
    .then( email => {
      dispatch({ type: 'user/reset', email})
      getFirebase().auth().confirmPasswordReset(code, password)
        .then(response => {
          dispatch({ type: 'user/reseted', email })
          getFirebase().auth().signInWithEmailAndPassword(email, password)
            .then(login => dispatch({ type: 'user/logged/in', login }))
        }).catch( error => dispatch({ type: 'user/reset/error', error }))
    }).catch( error => dispatch({ type: 'user/reset/error', error }))
}

export const verifyEmail = (dispatch, getState, code) => {
  getFirebase().auth().checkActionCode(code).then( detail => {
    dispatch({ type: 'user/verify', detail })
  })
  getFirebase().auth().applyActionCode(code).then(function(result) {
    dispatch({ type: 'user/verify/success', result})
  }).catch(error => dispatch({ type: 'user/verify/error', error }))
}

export const login = (dispatch, getState, user) => {
  const firebase = getFirebase()

  dispatch({ type: 'user/login', user})
  return firebase
    .auth()
    .signInWithEmailAndPassword(user.email, user.password)
    .then((login) => {
      dispatch({ type: 'user/logged/in', login: login })
    })
    .catch((e) => {
      dispatch({ type: 'user/login/error', email: user.email, error: e })
    })
}

export const validatePassword = (user, password) => {
  const firebase = getFirebase()
  return firebase
  .auth()
  .signInWithEmailAndPassword(user.email, password)
}

export const logout = (dispatch, getState, user) => {
  return getFirebase()
    .auth()
    .signOut()
    .then(() => {
      dispatch({ type: 'user/logged/out', user: user.uid })
    })
}

export const setAvatar = (dispatch, getState, url) => {
  const firebase = getFirebase()
  if(!url) return;

  return updateDoc(
    dispatch,
    USER_COLLECTION,
    firebase.auth().currentUser.uid,
    {avatar: url},
    'user/avatar/add',
    'user/avatar/added',
    'user/avatar/add/error'
  )

}

import React from 'react'
import './App.css'
import { ToastContainer } from 'react-toastify'
import Routes from './Routes'
import GlobalStyle from 'styles/global'

import DataDispatcher from './modules/data/DataDispatcher'

function App() {
  return (
    <DataDispatcher>
      <GlobalStyle />
      <ToastContainer autoClose={3000} />
      <Routes />
    </DataDispatcher>
  )
}

export default App

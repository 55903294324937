import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'numberValidation.title',
    defaultMessage: 'Validar telefone',
  },
  info: {
    id: 'numberValidation.text.info',
    defaultMessage:
      'Em breve você receberá uma mensagem contendo um código de 5 dígitos . Digite-o abaixo para validr seu telefone',
  },
  resend: {
    id: 'numberValidation.link.resend',
    defaultMessage: 'Reenviar código',
  },
  back: {
    id: 'numberValidation.button.back',
    defaultMessage: 'Voltar',
  },
})

export default messages

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  description: {
    id: 'form.address.description',
    defaultMessage: 'Descrição',
  },
  postalCode: {
    id: 'form.address.postalCode',
    defaultMessage: 'Cep',
  },
  address: {
    id: 'form.address.address',
    defaultMessage: 'Endereço',
  },
  number: {
    id: 'form.address.number',
    defaultMessage: 'Número',
  },
  complement: {
    id: 'form.address.complement',
    defaultMessage: 'Complemento',
  },
  neighborhood: {
    id: 'form.address.neighborhood',
    defaultMessage: 'Bairro',
  },
  state: {
    id: 'form.address.state',
    defaultMessage: 'Estado',
  },
  city: {
    id: 'form.address.city',
    defaultMessage: 'Cidade',
  },
  banks: {
    id: 'form.account.banks',
    defaultMessage: 'Banco',
  },
  agency: {
    id: 'form.account.agency',
    defaultMessage: 'Agência',
  },
  account: {
    id: 'form.account.account',
    defaultMessage: 'Conta',
  },
  addressTitle: {
    id: 'form.address.addTitle',
    defaultMessage: 'Cadastre o nome do local e faça a busca do endereço',
  },
  localeName: {
    id: 'form.address.localeName',
    defaultMessage: 'Nome do local',
  },
  localeSearch: {
    id: 'form.address.localeSearch',
    defaultMessage: 'Buscar endereço',
  },
  submit: {
    id: 'form.address.submit',
    defaultMessage: 'Cadastrar',
  },
  error: {
    id: 'form.address.addError',
    defaultMessage: 'Preencha os campos para salvar',
  },
})

export default messages

import styled from 'styled-components'
import SignInBackgroundImage from 'assets/SigninBackground.jpg'
import { colors } from 'styles'

export const Container = styled.div`
  height: 100%;
  flex: 1;
  background: url(${SignInBackgroundImage}) no-repeat top;
  background-size: 100% 50%;
  display: flex;
  justify-content: center;
`
export const Logo = styled.div`
  display: flex;
  justify-content: center;
`

export const FormContainer = styled.div`
  height: 100%;
  padding-top: 30px;

  form {
    width: 540px;

    background: ${colors.white};
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.31);
    border-radius: 5px;
    margin-top: 21px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
  }

  @media only screen and (max-width: 590px) {
    form {
      width: 400px;
    }
  }

  @media only screen and (max-width: 450px) {
    form {
      width: 350px;
    }
  }

  @media only screen and (max-width: 390px) {
    form {
      width: 300px;
    }
  }
`

export const Content = styled.div`
  width: 450px;

  div:not(:second-child) {
    margin-top: 40px;
  }
  margin-bottom: 30px;

  .info-text {
    margin-top: 10px;
    color: ${colors.silver4};
  }

  @media only screen and (max-width: 590px) {
    width: 340px;
  }

  @media only screen and (max-width: 450px) {
    width: 310px;
  }

  @media only screen and (max-width: 390px) {
    width: 270px;
  }
`

export const Footer = styled.div`
  margin-top: 60px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

  .signup {
    display: flex;
    justify-content: center;
    .signup-text {
      font-weight: 600;
    }
  }

  a {
    text-decoration: none;
  }
`

export const CardsContainer = styled.div`
  margin-top: 60px;
  a {
    text-decoration: none;
  }
`

export const Card = styled.div`
  height: 80px;
  border: 1px solid ${colors.silver};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 14px 40px;

  display: flex;

  align-items: center;
  margin-top: 20px;
  strong {
    margin-top: 10px;
    color: ${colors.dorado};
  }

  .buyer-icon {
    width: 60px;
    padding: 0;
    margin: 0;
    margin-right: -20px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;

  .title {
    font-weight: bold;
    margin-bottom: 5px;
  }
`

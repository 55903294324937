import React from 'react'

import * as s from './styles'
import messages from './messages'
import { useIntl } from 'react-intl'

export default function Accounts({ bank, unit, account, onClick }) {
  const { formatMessage } = useIntl()

  return (
    <s.Container>
      <span className='delete' onClick={e => onClick(account)}>✕</span>
      <s.Title>{bank}</s.Title>
      <s.Text>
        {formatMessage(messages.unit)}: {unit}
      </s.Text>
      <s.Text>
        {formatMessage(messages.account)}: {account}
      </s.Text>
    </s.Container>
  )
}

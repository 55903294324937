import { defineMessages } from 'react-intl'

const messages = defineMessages({
  minha: {
    id: 'menus.minha',
    defaultMessage: 'Minha',
  },
  minhas: {
    id: 'menus.minhas',
    defaultMessage: 'Minhas',
  },
  meu: {
    id: 'menus.meu',
    defaultMessage: 'Meu',
  },
  telaInicial: {
    id: 'menus.telaInicial',
    defaultMessage: 'Tela Inicial',
  },
  propriedades: {
    id: 'menus.propriedades',
    defaultMessage: 'Propriedades',
  },
  perfil: {
    id: 'menus.perfil',
    defaultMessage: 'Perfil',
  },
  lois: {
    id: 'menus.lois',
    defaultMessage: 'LOIs',
  },
  negociacoes: {
    id: 'menus.negociacoes',
    defaultMessage: 'Negociações',
  },
  agendar: {
    id: 'menus.agendar',
    defaultMessage: 'Agendar',
  },
  visitas: {
    id: 'menus.visitas',
    defaultMessage: 'Visitas',
  },
  concluidas: {
    id: 'menus.concluidas',
    defaultMessage: 'Concluídas',
  },
})

export default messages

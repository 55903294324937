import styled from 'styled-components'
import homeLogo from 'assets/home_logo.svg'
import { colors, device, fonts } from 'styles'

export const Container = styled.div`
  background: red;
  width: calc(100% - 250px);
  background: url(${homeLogo}) no-repeat center;
  background-size: 100% 50%;
  display: flex;
  justify-content: center;

  @media ${device.untilTablet} {
    width: 100%;
    height: 100vh;
  }
`
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  padding-top: 55px;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background: ${colors.wildSand};
  border: 1px solid ${colors.mercury3};
  border-radius: 5px;
  flex: 1;
  width: 100%;
  margin: 0 16px;
  max-height: 200px;
  max-width: 390px;
`

export const CardTitle = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  color: #494949;
  margin-top: 22px;
`

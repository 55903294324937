import styled, { css } from 'styled-components'
import { colors, device } from 'styles'

const containerSizes = {
  default: css`
    width: 750px;
    height: 750px;
    max-height: 95%;
  `,
  medium: css`
    width: 565px;
    height: 575px;
  `,
}

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;

  & .close-modal {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    padding: 5px;
    z-index: 1;

    & svg {
      z-index: 0;
    }
  }

  @media ${device.untilTablet} {
    width: 90%;
    height: auto;
    overflow: auto;
  }

  ${({ size = 'default' }) => containerSizes[size]}
`

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`

export const HeaderImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin-right: 25px;
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h1 {
    margin-bottom: 8px;
  }

  & > p:not(:last-of-type) {
    margin-bottom: 17px;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & .batch-help {
    margin: 40px auto 20px auto;
    & a {
      color: ${colors.apple};
    }
  }
`

export const BatchDescription = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-top: 30px;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.untilTablet} {
    flex-direction: column;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.untilTablet} {
    margin-top: 40px;
  }

  &.batch-description {
    & p {
      color: ${colors.silverChalice6};

      & b {
        color: ${colors.dorado};
      }
    }

    & > :not(:last-child) {
      margin-bottom: 25px;
    }
  }
`

export const FooterButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media ${device.untilTablet} {
    margin-top: 40px;
    justify-content: center;
  }

  & button {
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    width: 200px;
    height: 40px;

    @media ${device.untilTablet} {
      font-size: 16px;
    }

    &.exclude {
      background: ${colors.fuzzyWuzzyBrown};
      color: ${colors.white};
    }

    &.close {
      border: 1px solid ${colors.dorado};
      background: ${colors.white};
      color: ${colors.dorado};
    }
  }

  & > :not(:last-child) {
    margin-right: 20px;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background: ${colors.wildSand};
  border: 1px solid ${colors.mercury3};
  border-radius: 20px;
  flex: 1;

  & .clock {
    margin: auto;
  }

  & .text-warning {
    font-size: 14px;
    color: gray;
    text-align: center;
    max-width: 95%;
  }
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    color: ${colors.dorado};
    margin-bottom: 17px;
  }

  & > p {
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }

  & .btn-paid {
    border-radius: 10px;
    width: 100px;
    height: 35px;
  }
`

export const DateList = styled.div`
  display: flex;
  margin-bottom: 50px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  & > button {
    border: 1px solid ${colors.dorado};
    background: transparent;
    height: 40px;
    width: 115px;
    color: ${colors.dorado};
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;

    &.selected-date {
      background: ${colors.dorado};
      color: ${colors.white};
    }
  }
`

export const TruckList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  width: 100%;

  & > .arrow-nav {
    position: absolute;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    top: 25%;

    &.arrow-left {
      left: 5px;
    }
    &.arrow-right {
      right: 5px;
    }
  }
`

export const Truck = styled.div`
  border-radius: 5px;
  background: ${colors.white};
  width: 225px;
  height: 50px;
  margin-bottom: 15px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:nth-child(even) {
    margin-right: 10px;
  }

  &:nth-child(odd) {
    margin-left: 10px;
  }

  ${({ active }) =>
    active &&
    css`
      background: rgba(71, 172, 52, 0.1);
      border: 1px solid ${colors.apple};
    `}

  & > div {
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    & .truck-name {
      color: ${colors.dorado};
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }

    & .board {
      color: ${colors.dorado};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  & > .svg-check {
    margin-left: auto;
  }
`

export const NegotiationDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 45px;

  & > :not(:last-child) {
    margin-bottom: 30px;
  }
`

export const Link = styled.a`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: ${colors.bostonBlue};
  padding-top: 60px;
`

export const NegotiationButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    width: 210px;
    font-weight: bold;
  }

  & .accept {
    margin-left: 7px;
  }
  & .reject {
    margin-right: 7px;
    background: ${colors.danger};
  }
`

export const Values = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
  & > :last-child {
    margin-left: 5px;
  }
`

export const ValueCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  padding: 15px 25px;

  & > p:first-of-type {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 12px;
  }

  & > p:last-of-type {
    color: ${colors.apple};
    font-size: 20px;
    font-weight: bold;
  }
`

export const TruckHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`

export const TruckHeaderBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 90px;
  height: 90px;
  margin-right: 15px;

  background: ${colors.apple};

  & > :first-child {
    margin-bottom: 5px;
    width: 40px;
    height: 30px;
  }

  & > :last-child {
    margin-top: 5px;
    font-size: 30px;
    font-weight: 700;
    line-height: 20px;
    color: ${colors.white};
  }
`

export const TruckHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > p {
    font-size: 14px;
  }

  & > :first-child {
    font-size: 18px;
  }

  & > p:not(:last-of-type) {
    margin-bottom: 5px;
  }
`

export const TruckBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button {
    margin-top: 75px;
    width: 250px;
    height: 40px;
  }
`

export const TruckRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & > p:first-of-type {
    margin-right: 30px;
  }

  & > p:last-of-type {
    margin-left: 15px;
  }

  & .truck-input {
    min-width: auto;
    width: 100%;
    max-width: 75px;
    margin: 0;
  }

  & .truck-input-file {
    min-width: auto;
    width: 100%;
    max-width: 295px;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`

export const Download = styled.a`
  font-size: 16px;
  color: ${colors.bostonBlue};
  text-decoration: underline;
  text-align: center;
  margin: auto auto 0;
  cursor: pointer;
`

import {
  isValidCPF,
  isValidCNPJ,
  isValidMobilePhone,
} from '@brazilian-utils/brazilian-utils'

export function getProperties(type) {
  switch (type) {
    case 'cpf':
      return {
        mask: '###.###.###-##',
        fnValidation: isValidCPF,
        errorMessage: 'CPF inválido',
      }
    case 'cnpj':
      return {
        mask: '##.###.###/####-##',
        fnValidation: isValidCNPJ,
        errorMessage: 'CNPJ inválido',
      }
    case 'phone':
      return {
        mask: '+55 (##) #####-####',
        fnValidation: isValidMobilePhone,
        errorMessage: 'Telefone inválido',
      }
    case 'time':
      return { mask: '##:##', fnValidation: (e) => true }
    case 'percent':
      return { mask: '##,##%', fnValidation: (e) => true }
    default:
      return {}
  }
}

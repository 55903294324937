import styled from 'styled-components'
import { shade } from 'polished'
import { colors, device } from 'styles'

export const Container = styled.aside`
  width: 80px;
  background-color: #fdfdfd;
  height: 100%;
  transition: width 0.3s;
  /* position: fixed; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: scroll;

  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.24);

  strong {
    display: none;
  }

  /* not small devices*/
  @media ${device.fromTablet} {
    width: 250px;
    strong {
      display: flex;
    }
  }

  @media ${device.untilTablet} {
    display: none;
  }
`

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    padding: 20px;
    width: 100%;
    transition: 0.3s;
    display: flex;
    align-items: center;

    .option {
      margin-left: 25px;
      span {
        color: #a1a1a1;
      }

      strong {
        color: #494949;
      }
    }

    &:hover {
      background: ${shade(0.1, '#FDFDFD')};
      cursor: pointer;
    }

    svg {
      width: 30px;
    }
  }

  .navbar__link--active {
    border-width: 6px;
    border-right-style: solid;
    border-right-color: ${colors.green};
    transition: background-color 0.5s ease-in-out;
  }
`

export const Help = styled.ul`
  padding: 20px;
  list-style: none;
  position: fixed;
  bottom: 0;

  li {
    margin-bottom: 15px;
    color: #565656;
    cursor: pointer;
    &:hover {
      color: ${shade(0.2, '#565656')};
    }
    a:link,
    a:hover,
    a:visited {
      text-decoration: none;
      color: inherit;
    }
  }
`

import { getFirebase } from 'react-redux-firebase'
import { createAccount } from './userLogic'

export const init = (dispatch, getState, btnId) => {
	const firebase = getFirebase()
	window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(btnId, {
	  'size': 'invisible',
	  'callback': function(response) {
	    dispatch({ type: 'phone/recaptcha', response})
	  },
	  'expired-callback': function() {
	    window.recaptchaVerifier.reset()
	  }
	});
	// window.recaptchaVerifier.render()
	// window.recaptchaVerifier.verify()
}


export const request = (dispatch, getState, phoneNumber) => {
	const firebase = getFirebase()

	dispatch({ type: 'phone/request', phoneNumber })
	
	var appVerifier = window.recaptchaVerifier;
	firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
    .then(confirmation => {
    	dispatch({ type: 'phone/request/confirmation', confirmation})
    })
    .catch(function (error) {
    	console.error(error)
    	dispatch({ type: 'phone/request/error', error})
    });
}

export const confirm = (dispatch, getState, code) => {
	dispatch({ type: 'phone/confirm/code', code})
	dispatch(createAccount, {
		verification: getState().phone.confirmation, 
		code: code
	});
	window.confirmationResult = getState().phone.confirmation;
}
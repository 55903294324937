import styled, { css } from 'styled-components'
import { colors, device } from 'styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Placeholder = styled.p`
  font-size: 18px;
  margin-bottom: 25px;
`

export const List = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 20px;
  }
`

export const Box = styled.div`
  font-size: 18px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid ${colors.silver7};
  cursor: pointer;

  @media ${device.untilTablet} {
    padding: 10px 9px;
  }

  ${({ selected }) =>
    selected
      ? css`
          background: ${colors.apple};
          color: ${colors.white};
        `
      : css`
          background: ${colors.concrete};
        `}
`

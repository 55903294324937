import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Title from 'components/Title'
import Text from 'components/Text'
import CheckBox from 'components/CheckBox'
import SVG from 'components/SVG'
import Button from 'components/Button'
import CustomInput from 'components/CustomInput'
import { parseMoneyToCurrency } from 'utils'

import messages from './messages'

import { useSeaPort } from 'modules/data/hooks/useSeaPort'
import { useLOI } from 'modules/data/hooks/useLOI'
import { useProduct } from 'modules/data/hooks/useProduct'
import { useBuyer } from 'modules/data/hooks/useBuyer'

import * as s from './styles'
import ContractTypeList from './ContractTypeList'
import TransportTypeList from './TransportTypeList'
import PaymentTypeList from './PaymentTypeList'
import AddressTypeList from './AddressTypeList'
import { useConstants } from './constants'
import { Link, makeStyles, Modal } from '@material-ui/core'
import { useUser } from 'modules/data/hooks/useUser'
import Input from 'components/Input'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function Form({ hideModal }) {
  const [form, setForm] = useState({
    cost: {},
    product: null,
    date: new Date(),
    delivery: {},
    targetPricePerTon: 0,
    tons: 0,
    user: {},
    contractType: null,
    transportationType: null,
    paymentType: null,
    packing: {},
    address: null,
  })

  const [modalForm, setModalForm] = useState(null)

  const classes = useStyles()

  const [productDetailModal, setProductDetailModal] = useState(false)
  const [tonsError, setTonsError] = useState(false)

  const { create } = useLOI()
  const { all: ports = [] } = useSeaPort()
  const { all: products = [] } = useProduct()
  const { formatMessage } = useIntl()
  const { buyer } = useBuyer()
  const user = useUser()
  const {
    contractTypes,
    transportationMethods,
    paymentTypes,
    packageTypes,
    bigBagValues,
    sacksValues,
    countries,
  } = useConstants()

  const setField = ({ target: { name, value } }) => {
    const val = Array.isArray(value) ? value[0] : value
    if (name.includes('.')) {
      const names = name.split('.')
      const objectToSet = { ...form[names[0]], [names[1]]: val }
      setForm({ ...form, [names[0]]: objectToSet })
    } else setForm({ ...form, [name]: val })
  }

  const onChangeWithObject = (event, collection) => {
    const {
      target: { value, name },
    } = event

    const val = Array.isArray(value) ? value[0] : value

    const objCol = collection.filter((p) => p.id === val)

    setField({ target: { ...event.target, name: name, value: objCol[0] } })
  }

  const setSelected = (name, object) => {
    setField({ target: { name: name, value: object } })
  }

  const {
    targetPricePerTon,
    product,
    delivery,
    contractType,
    transportationType,
    paymentType,
    address,
    packing,
    tons,
  } = form

  const getTransportationTypes = (country) => {
    let tTypes = []

    for (let index = 0; index < transportationMethods.length; index++) {
      const transportMethod = transportationMethods[index]
      if (country) {
        if (country === 'BR') {
          transportMethod.disabled = index === 1
        } else {
          transportMethod.disabled = index === 0
        }
      }
      tTypes.push(transportMethod)
    }
    return tTypes
  }

  const getPaymentTypes = (country) => {
    let pTypes = []
    for (let index = 0; index < paymentTypes.length; index++) {
      const payType = paymentTypes[index]
      if (country) {
        if (country === 'BR') {
          payType.disabled = index > 0
        } else {
          if (index < 1) payType.disabled = true
        }
      }
      pTypes.push(payType)
    }

    return pTypes
  }

  const handleProductDetailClose = () => {
    setModalForm(null)
    setProductDetailModal(false)
  }

  const handleProductDetailContinue = () => {
    setForm({ ...form, customProductFields: { ...modalForm } })
    setModalForm(null)
    setProductDetailModal(false)
  }

  const handleTonsChange = (value) => {
    if(value < 25) {
      setTonsError(true);
    } else {
      setTonsError(false);
    }

    setField({
      target: { name: 'tons', value: value },
    })
  }

  async function createLoi(e) {
    await create(form)
    hideModal(e)
  }

  const setCountry = (country) => {
    const countryObj = Array.isArray(country) ? country[0] : country

    setForm({
      ...form,
      paymentType: null,
      transportationType: null,
      delivery: {
        country: countryObj,
        port: null,
      },
      address: null,
    })
  }

  const setProduct = (event, collection) => {
    const {
      target: { value },
    } = event

    const val = Array.isArray(value) ? value[0] : value

    const objCol = collection.filter((p) => p.id === val)
    setForm({
      ...form,
      product: objCol[0],
      customProductFields: null,
    })
  }

  const setPackingType = (event, collection) => {
    const {
      target: { value },
    } = event

    const val = Array.isArray(value) ? value[0] : value

    const objCol = collection.filter((p) => p.id === val)

    setForm({
      ...form,
      packing: {
        ...form.packing,
        type: objCol[0],
        kgPerUnit: null,
      },
    })
  }

  return (
    <React.Fragment>
      <s.Container>
        {productDetailModal && ''}
        <s.Header>
          <Title>{formatMessage(messages.title)}</Title>
          <s.SubTitle>{formatMessage(messages.subTitle)}</s.SubTitle>
        </s.Header>
        <s.FormContent>
          {product && product.customFields && (
            <Modal
              className={classes.modal}
              open={productDetailModal}
              onClose={handleProductDetailClose}
              aria-labelledby='simple-modal-title'
              aria-describedby='simple-modal-description'>
              <s.ModalBody>
                <Title color={'dorado'} style={{ marginBottom: '10px' }}>
                  Detalhes do Produto
                </Title>
                <Text color={'apple'} style={{ marginBottom: '50px' }}>
                  {product.name}
                </Text>
                {Object.keys(product.customFields).map((key, index) => (
                  <s.ModalRow key={index}>
                    <CustomInput
                      value={modalForm ? modalForm[key] || '' : ''}
                      onChange={(e) =>
                        setModalForm({ ...modalForm, [key]: e.target.value })
                      }
                    />
                    <Text>{product.customFields[key]}</Text>
                  </s.ModalRow>
                ))}
                <s.ModalRow
                  style={{ marginTop: '45px', justifyContent: 'center' }}>
                  <Button
                    disabled={
                      Object.keys(product.customFields).length !==
                      Object.keys(modalForm || {}).length
                    }
                    maxWidth='200px'
                    onClick={handleProductDetailContinue}>
                    Confirmar
                  </Button>
                </s.ModalRow>
              </s.ModalBody>
            </Modal>
          )}
          <s.ProductContainer>
            <s.Products>
              <s.CustomSelect
                placeholder={formatMessage(messages.productSelection)}
                options={products.map((p) => ({
                  label: p.name_en,
                  value: p.id,
                }))}
                name='product'
                onChange={(event) => setProduct(event, products)}
                minWidth='175px'
                maxWidth='335px'
                disabled={!user.user.enabled}
              />

              <s.Link
                onClick={() => setProductDetailModal(true)}
                disabled={!product || !product.customFields}
                alignSelf={'center'}
                component={Link}>
                {formatMessage(messages.productDetails)}
              </s.Link>
            </s.Products>
          </s.ProductContainer>
          <s.Row>
            <>
              <Input
                minWidth={'30%'}
                theme='inlineGrey'
                placeholder={formatMessage(messages.quantity)}
                className='inputMax'
                disabled={!user.user.enabled}
                value={tons}
                onChange={({ target: { value } }) => handleTonsChange(value)} />
              <Text alignSelf={'center'}>
                {formatMessage(messages.tons)}
              </Text>
            </>
            <>
                {tonsError && 
                  <Text color={'vividRed'} alignSelf={'center'} fontWeight={'bold'} fontSize={'18px'}>
                    Minimum value for tons is 25
                  </Text>
                }
            </>
          </s.Row>
          <s.Row>
            <s.CustomSelect
              placeholder={formatMessage(messages.packageType)}
              options={packageTypes.map((p) => ({
                label: p.description,
                value: p.id,
              }))}
              name='packing.type'
              onChange={(event) => setPackingType(event, packageTypes)}
              minWidth='200px'
              maxWidth='335px'
              width='100%'
              disabled={!user.user.enabled}
            />
            {(!packing.type || packing.type.id !== 'granel') && (
              <s.CustomSelect
                key='customPackingSelect'
                placeholder={formatMessage(messages.amountPerPackage)}
                value={packing.type ? packing.type.kgPerUnit : null}
                options={
                  !packing.type
                    ? []
                    : packing.type.id === 'sacks'
                    ? sacksValues
                    : bigBagValues
                }
                name='packing.kgPerUnit'
                onChange={setField}
                minWidth='125px'
                maxWidth='335px'
                disabled={!user.user.enabled}
              />
            )}
          </s.Row>

          <s.Row>
            <s.Column>
              <Text>{formatMessage(messages.targetPrice)}</Text>
              <s.CustomSlider
                value={targetPricePerTon}
                min={0}
                max={2000}
                name={'targetPricePerTon'}
                onChange={(event, value) => {
                  setField({
                    target: { name: 'targetPricePerTon', value: value },
                  })
                }}
                aria-labelledby='continuous-slider'
                disabled={!user.user.enabled}
              />
              <Text>{parseMoneyToCurrency(targetPricePerTon, 'USD')}</Text>
            </s.Column>
          </s.Row>

          <s.Row>
            <CheckBox
              placeholder={formatMessage(messages.offerTime)}
              options={[
                { label: '07', value: '07' },
                { label: '15', value: '15' },
                { label: '30', value: '30' },
              ]}
              name='offerTime'
              onChange={setField}
              hint={formatMessage(messages.daysBy)}
            />
          </s.Row>

          <s.Row>
            <s.CustomSelect
              placeholder={formatMessage(messages.country)}
              options={countries
                .sort((a, b) => {
                  var x = a.name_en.toLowerCase()
                  var y = b.name_en.toLowerCase()
                  if (x < y) {
                    return -1
                  }
                  if (x > y) {
                    return 1
                  }
                  return 0
                })
                .map((country) => ({
                  key: country.name_en + country.code,
                  label: country.name_en,
                  value: country.code,
                }))}
              name='delivery.country'
              onChange={(event) => setCountry(event.target.value)}
              minWidth='175px'
              maxWidth='335px'
              width='100%'
              disabled={!user.user.enabled}
            />

            {delivery.country && delivery.country !== 'BR' && (
              <s.CustomSelect
                placeholder={formatMessage(messages.port)}
                options={ports
                  .filter((p) => p.id.startsWith(delivery.country))
                  .map((p) => ({ label: p.name, value: p.id }))}
                name='delivery.port'
                onChange={(event) => onChangeWithObject(event, ports)}
                minWidth='175px'
                maxWidth='335px'
                width='100%'
                disabled={!user.user.enabled}
              />
            )}
          </s.Row>
          {delivery.country === 'BR' && (
            <s.Row>
              {buyer.addressList ? (
                <AddressTypeList
                  items={buyer.addressList}
                  onSelect={(address) => setSelected('address', address)}
                  selected={address}
                />
              ) : (
                <Text>{formatMessage(messages.noAddress)}</Text>
              )}
            </s.Row>
          )}
          <s.Row>
            <s.Column>
              <Text>{formatMessage(messages.selectContractType)}</Text>
              <ContractTypeList
                items={contractTypes}
                onSelect={(contract) => setSelected('contractType', contract)}
                selected={contractType}
              />
            </s.Column>
            {contractType && (
              <s.Column>
                <s.ContractTitle>
                  {formatMessage(messages.contractType)} {contractType.type}
                </s.ContractTitle>
                <s.ContractDescription
                  dangerouslySetInnerHTML={{ __html: contractType.description }}
                />
              </s.Column>
            )}
          </s.Row>
          <s.Row>
            <s.Column>
              <Text>{formatMessage(messages.transportation)}</Text>
              <TransportTypeList
                items={getTransportationTypes(delivery.country)}
                onSelect={(transportationType) =>
                  setSelected('transportationType', transportationType)
                }
                selected={transportationType}
              />
            </s.Column>
          </s.Row>

          <s.Row>
            <s.Column>
              <s.TitleContainer>
                <Text marginRight={'30px'}>
                  {formatMessage(messages.paymentSelection)}
                </Text>{' '}
                <SVG.QuestionMark />
              </s.TitleContainer>
              <PaymentTypeList
                items={getPaymentTypes(delivery.country)}
                onSelect={(paymentType) =>
                  setSelected('paymentType', paymentType)
                }
                selected={paymentType}
              />
            </s.Column>
          </s.Row>
        </s.FormContent>
      </s.Container>
      <s.Footer>
        <Text>{formatMessage(messages.addLoi)}</Text>
        <Button
          disabled={
            !form.targetPricePerTon ||
            !form.product ||
            !form.delivery ||
            (form.delivery.country === 'BR' && !form.address) ||
            (form.delivery.country !== 'BR' && !form.delivery.port) ||
            !form.contractType ||
            !form.transportationType ||
            !form.packing.type ||
            (form.packing.type.id !== 'granel' && !form.packing.kgPerUnit) ||
            !form.paymentType || 
            (!form.tons || tonsError)
          }
          onClick={createLoi}>
          {formatMessage(messages.ok)}
        </Button>
        <Button
          color='defaultText'
          background='transparent'
          onClick={hideModal}>
          {formatMessage(messages.cancel)}
        </Button>
      </s.Footer>
    </React.Fragment>
  )
}

export default Form

import styled from 'styled-components'
import { colors, device } from 'styles'

export const Container = styled.div`
  position: relative;
  background: ${colors.white};
  display: flex;
  height: 100%;
  width: ${({ width }) => width || '100%'};
  min-height: ${({ minHeight }) => minHeight || 'unset'};
  min-width: ${({ minWidth }) => minWidth || 'unset'};
  height: ${({ height }) => height || '100%'};
  overflow-y: auto;
  border: 1px solid ${colors.mercury2};
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  cursor: pointer;

  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }
`

export const LineGreen = styled.div`
  width: 5px;
  height: 100%;
  background: ${colors.apple};
  border-radius: 5px 0px 0px 5px;
  @media ${device.untilTablet} {
    position: absolute;
  }
`

export const LineWhite = styled.div`
  background: ${colors.white};
  width: 5px;
  height: 100%;
  border-radius: 5px 0px 0px 5px;
`

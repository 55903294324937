import React from 'react'
import { colors } from 'styles'

export default function ({ color, height, width, ...props }) {
  const fill = colors[color] || colors.defaultSVG
  return (
    <svg
      {...props}
      width={width || '26'}
      height={height || '20'}
      viewBox={`0 0 26 20`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M21.1933 0.669074C21.6241 0.242024 22.2057 0.00168241 22.8123 8.79919e-06C23.4189 -0.00166482 24.0018 0.235464 24.435 0.66013C24.8681 1.0848 25.1167 1.66288 25.1271 2.26939C25.1374 2.87591 24.9086 3.46213 24.4902 3.90131L12.2016 19.2621C11.9904 19.4896 11.7354 19.6721 11.4521 19.7989C11.1687 19.9256 10.8627 19.9939 10.5523 19.9996C10.242 20.0054 9.93367 19.9485 9.6458 19.8323C9.35794 19.7162 9.09645 19.5432 8.87697 19.3237L0.73482 11.1784C0.507989 10.9671 0.326054 10.7122 0.199867 10.429C0.0736811 10.1458 0.00582881 9.84008 0.000359301 9.53008C-0.00511021 9.22009 0.0519153 8.91216 0.168033 8.62469C0.28415 8.33721 0.456981 8.07606 0.676215 7.85683C0.895449 7.63759 1.15659 7.46476 1.44407 7.34865C1.73155 7.23253 2.03947 7.1755 2.34947 7.18097C2.65946 7.18644 2.96518 7.25429 3.24838 7.38048C3.53159 7.50667 3.78647 7.6886 3.99784 7.91543L10.4438 14.3584L21.1348 0.736798C21.1539 0.712952 21.1745 0.690332 21.1964 0.669074H21.1933Z'
        fill={fill}
      />
    </svg>
  )
}

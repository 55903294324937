import React, { useState } from 'react'

import Drawer from '@material-ui/core/Drawer'
import { useLOI } from 'modules/data/hooks/useLOI'

import * as s from './styles'
import messages from './messages'

import Text from 'components/Text'
import Title from 'components/Title'
import Button from 'components/Button'
import SVG from 'components/SVG'
import LoiList from 'components/LoiList'
import Form from './Form'
import { useIntl } from 'react-intl'
import Negotiation from 'components/Negotiation'
import { device } from 'styles'
import useWindowSize from 'modules/data/hooks/useWindowSize'

export default function Loi() {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const { formatMessage } = useIntl()
  const { mine: lois = [] } = useLOI()
  const [negotiation, setNegotiation] = useState(undefined)
  const [width] = useWindowSize()

  const openNegotiation = (negotiation) => {
    setNegotiation(negotiation)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  return (
    <s.Container>
      <s.Header>
        <s.PageDescription>
          <s.TitleHint>{formatMessage(messages.my)}</s.TitleHint>
          <Title>{formatMessage(messages.loi)}</Title>
        </s.PageDescription>
        {width > device.screen.tablet && (
          <Button
            onClick={toggleDrawer('right', true)}
            height='40px'
            fontSize='16px'
            maxWidth='230px'
            background='dorado'>
            {formatMessage(messages.addNewLoi)}
          </Button>
        )}
      </s.Header>
      <s.Content>
        {lois.length ? (
          <React.Fragment>
            <Negotiation
              open={negotiation}
              loi={negotiation}
              close={() => setNegotiation(undefined)}
            />
            <LoiList lois={lois} openNegotiation={openNegotiation} />
            {width < device.screen.tablet && (
              <Button
                onClick={toggleDrawer('right', true)}
                maxWidth='230px'
                fontSize='20px'>
                {formatMessage(messages.addLoi)}
              </Button>
            )}
          </React.Fragment>
        ) : (
          <s.NoLois>
            <SVG.WheatBag width='86px' height='86px' />
            <Text>{formatMessage(messages.noLoiAdded)}</Text>
            <Button
              onClick={toggleDrawer('right', true)}
              maxWidth='230px'
              fontSize='20px'>
              {formatMessage(messages.addLoi)}
            </Button>
          </s.NoLois>
        )}

        <Drawer
          anchor='right'
          open={state['right']}
          onClose={toggleDrawer('right', false)}>
          <Form hideModal={toggleDrawer('right', false)} />
        </Drawer>
      </s.Content>
    </s.Container>
  )
}

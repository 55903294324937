import styled from 'styled-components'
import BannerFarmer from 'assets/banner-produtor.jpg'
import { colors } from 'styles'

export const Container = styled.div`
  flex: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${BannerFarmer}) no-repeat top;
  background-size: cover;
  display: flex;
  justify-content: center;
  max-height: 415px;
  
  @media only screen and (max-width: 590px) {
    background-position: 0 -23vh;
  }

  @media only screen and (max-width: 390px) {
    margin-bottom: 50px;
  }
`
export const Logo = styled.div`
  display: flex;
  justify-content: center;
`

export const FormContainer = styled.div`
  height: 100%;
  padding-top: 20px;

  form {
    width: 540px;

    background: ${colors.white};
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.31);
    border-radius: 5px;
    margin-top: 21px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }

  @media only screen and (max-width: 590px) {
    form {
      width: 400px;
    }
  }

  @media only screen and (max-width: 450px) {
    form {
      width: 350px;
    }
  }

  @media only screen and (max-width: 390px) {
    form {
      width: 300px;
    }
    margin-bottom: 30px;
  }
`

export const Content = styled.div`
  margin: 20px;

  div:not(:second-child) {
    margin-top: 40px;
  }
  margin-bottom: 20px;

  .title {
    font-weight: 300;
    text-align: center;
    color: ${colors.greenDark};
  }

  p {
    line-height: 1.4;
    text-justify: auto;
    color: ${colors.silver3};
    margin: 0;
    margin-top: 30px;
    text-align: justify;

    strong {
      color: ${colors.dorado};
      font-weight: 500;
    }
  }

  .message {
    text-align: center;
  }
  @media only screen and (max-width: 590px) {
    width: 340px;
  }

  @media only screen and (max-width: 450px) {
    width: 310px;
  }

  @media only screen and (max-width: 390px) {
    width: 270px;
  }
`

export const Footer = styled.div`
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .resend {
    margin-bottom: 50px;
    font-style: italic;
    text-decoration: underline;
    color: ${colors.camarone2};
  }

  a {
    text-decoration: none;
  }
`

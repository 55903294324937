import styled from 'styled-components'
import { colors, fonts } from 'styles'

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const PageDescription = styled.div`
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 5px;
  }
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 30px;
  overflow: scroll;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > p {
    margin: 80px 0 50px !important;
  }
`

export const TitleHint = styled.span`
  color: ${colors.silverChalice2};
  font-weight: 300;
  font-size: 16px;
`

export const Card = styled.div`
  max-width: 828px;
  width: 100%;
  margin: 2% auto;

  background: #fafafa;
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 40px;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const LeftHeader = styled.div`
  display: flex;
  padding: 10px;
`

export const RightHeader = styled.div`
  display: flex;
  padding: 10px;
`

export const Name = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.apple};
`
export const Email = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: ${colors.dorado};
`
export const CompanyName = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.dorado};
`
export const Telephone = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: ${colors.bostonBlue};
`
export const BlockLine = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0 20px;

  & :first-child {
      margin-bottom: 10px;
  }
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    padding: 10px;
    font-family: ${fonts.primary};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    color: ${colors.dorado};
  }

  strong {
    font-weight: bold;
    color: ${colors.camarone};
  }

  .avatar {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    cursor: pointer;
  }
`

import styled from 'styled-components'
import { colors } from 'styles'

export const FieldNotEditable = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`

export const FieldGroup = styled.div`
  position: relative;
`

export const ActionButton = styled.button`
  background: ${({ background }) => colors[background] || colors.primary};
  color: ${colors.white};
  border: none;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  font-weight: bold;
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

import React from 'react'
import { Container, Menu, Help } from './styles'
import { useUser } from '../../modules/data/hooks/useUser'

import SidebarButton from 'components/SidebarButton'
import profileMenus from 'configuration/menus'

import { useFarmer } from 'modules/data/hooks/useFarmer'
import { useBuyer } from 'modules/data/hooks/useBuyer'

import { useIntl } from 'react-intl'
import messages from './messages'

const Sidebar = () => {
  const menus = profileMenus
  const { logout, user } = useUser()
  const { buyer } = useBuyer()
  const { farmer } = useFarmer()
  const doLogout = () => logout()
  const { formatMessage } = useIntl()

  let message = ''

  const buildMenu = () => {
    let menu = []

    if (user.roles) {
      if (user.roles.admin) {
        menu = menu.concat(menus.admin)
        message = 'Olá, eu sou técnico de campo Agricon e gostaria de suporte'
      } else if (user.roles.inspector) {
        menu = menu.concat(menus.inspector)
        message = 'Olá, eu sou técnico de campo Agricon e gostaria de suporte'
      } else if (user.roles.farmer) {
        menu = menu.concat(menus.farmer)
        message = 'Olá, eu me chamo ' + farmer?.name + ', sou produtor(a) e gostaria de suporte no uso da plataforma Agricon.'
      } else if (user.roles.buyer) {
        menu = menu.concat(menus.buyer)
        message = 'Olá, aqui é da empresa ' + buyer?.fantasy + ', somos compradores na plataforma Agricon e gostaríamos de suporte no uso da plataforma.'
      }
    }

    return menu.map((menuItem) => {
      return <SidebarButton {...menuItem} key={menuItem.link} />
    })
  }

  return (
    <Container>
      <Menu>{buildMenu()}</Menu>
      <Help>
        {/*<li>Por onde começar ?</li>*/}
        <li>
          <a
            target='_blank'
            href={
              'https://api.whatsapp.com/send?phone=+5551991634737&text=' +
              message
            }
            rel='noopener noreferrer'>
            {formatMessage(messages.needHelp)}
          </a>
        </li>
        <li onClick={doLogout}>{formatMessage(messages.logout)}</li>
      </Help>
    </Container>
  )
}
export default Sidebar

import React from 'react'
import { Container, Logo, FormContainer, Content, Footer } from './styles'
import Title from 'components/Title'
import Text from 'components/Text'
import Button from 'components/Button'
import logo from 'assets/logo.svg'
import { useHistory } from 'react-router-dom'

import { useFarmer } from 'modules/data/hooks/useFarmer'
import { useIntl } from 'react-intl'

import messages from './messages'
import routes from 'configuration/routes'

export default function WelcomeFarmer() {
  const history = useHistory()
  const { create } = useFarmer()
  const { formatMessage } = useIntl()

  const nextStep = routes.farmer.registerName

  const handleNextStep = () => {
    create()
    history.push(nextStep)
  }

  return (
    <Container>
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Title className='title' color='primary'>
              {formatMessage(messages.title)}
            </Title>

            <Text className='message'>-----</Text>
            <Text className='message' innerHtml={formatMessage(messages.oportunity)}>
              {' '}
            </Text>
            <Text className='message'>
              {formatMessage(messages.informations)}
            </Text>
            <Footer>
              <Button onClick={handleNextStep}>
                {formatMessage(messages.begin)}
              </Button>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

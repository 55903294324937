import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Content, ContentEmpty } from './styles'
import { Header } from './styles'
import Drawer from '@material-ui/core/Drawer'
import RegisterFarm from './RegisterFarm'
import FarmCard from './FarmCard'
import { useFarm } from 'modules/data/hooks/useFarm'
import farmLogo from 'assets/farmIconGrey.svg'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function Farms() {
  const query = useQuery()
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: query.get('add') === 'true',
  })
  const [farmId, setFarmId] = useState(null)
  const [edit, setEdit] = useState(false)

  const { mine } = useFarm()

  const toggleDrawer = (farmId, edit = false) => {
    if (typeof farmId === 'string' || typeof farmId === 'number') {
      setEdit(edit)
      setFarmId(farmId)
    }
    setState({ ...state, right: !state.right })
  }

  const closeDrawer = () => {
    setEdit(false)
    setFarmId(null)
    setState({ ...state, right: !state.right })
  }

  return (
    <Container>
      <div id='content'>
        <Header>
          <div id='title'>
            <span>Minhas</span>
            <strong>Propriedades</strong>
          </div>
          <button onClick={toggleDrawer} type='button'>
            Nova propriedade
          </button>
        </Header>
        {mine && mine.length > 0 ? (
          <Content>
            {mine.map((farm) => (
              <FarmCard
                farm={farm}
                edit={(farmId) => toggleDrawer(farmId, true)}
              />
            ))}
            <button id='floatButton' onClick={toggleDrawer} type='button'>
              Cadastrar propriedade
            </button>
          </Content>
        ) : (
          <ContentEmpty>
            <div id='icon'>
              <img src={farmLogo} alt='Farm' />
            </div>
            <p>Você ainda não cadastrou uma propriedade. Deseja cadastrá-la?</p>
            <button onClick={toggleDrawer} type='button'>
              Cadastrar propriedade
            </button>
          </ContentEmpty>
        )}
      </div>
      <React.Fragment key='right'>
        <Drawer
          anchor='right'
          open={state['right']}
          onClose={() => closeDrawer()}>
          <RegisterFarm
            hideModal={() => closeDrawer()}
            farmId={farmId}
            edit={edit}
          />
        </Drawer>
      </React.Fragment>
    </Container>
  )
}

import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import * as s from './styles'
import messages from './messages'
import Input from 'components/Input'
import SearchAddress from 'components/SearchAddress'
import Button from 'components/Button'
import { useFarmer } from 'modules/data/hooks/useFarmer'
import { toast } from 'react-toastify'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useUser } from 'modules/data/hooks/useUser'

export default function Form({ formType, toggleDrawer }) {
  const [form, setForm] = useState({ address: {}, account: {} })
  const { formatMessage } = useIntl()

  const [description, setDescription] = useState('')
  const [address, setAddress] = useState()
  const [addressList, setAddressList] = useState([])

  const [bank, setBank] = useState()
  const [unit, setUnit] = useState()
  const [account, setAccount] = useState()
  const [accountList, setAccountList] = useState([])

  const farmerHook = useFarmer()
  const buyerHook = useBuyer()
  const { user } = useUser()

  // eslint-disable-next-line
  const setField = ({ target: { name, value } }) => {
    if (name.includes('.')) {
      const names = name.split('.')
      const objectToSet = { ...form[names[0]], [names[1]]: value }
      setForm({ ...form, [names[0]]: objectToSet })
    } else setForm({ ...form, [name]: value })
  }

  useEffect(() => {
    if (user.roles.farmer) {
      if (farmerHook.farmer && farmerHook.farmer.addressList) {
        setAddressList(farmerHook.farmer.addressList)
      }
      if (farmerHook.farmer && farmerHook.farmer.accountList) {
        setAccountList(farmerHook.farmer.accountList)
      }
    } else if (user.roles.buyer) {
      if (buyerHook.buyer && buyerHook.buyer.addressList) {
        setAddressList(buyerHook.buyer.addressList)
      }
      if (buyerHook.buyer && buyerHook.buyer.accountList) {
        setAccountList(buyerHook.buyer.accountList)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmerHook, buyerHook])

  const addAddress = (address) => {
    if (!address.city) {
      toast.error('Selecione um endereço ou cidade')
      return
    }
    setAddress(address)
  }

  const save = (e) => {
    let addFeature = user.roles.farmer
      ? farmerHook.addFeature
      : buyerHook.addFeature
    switch (formType) {
      case 'address':
        if (!description) {
          toast.error(formatMessage(messages.error))
          return
        }
        addFeature({
          addressList: [
            ...addressList,
            { ...address, description: description },
          ],
        })
        break
      case 'account':
        if (!bank || !unit || !account) {
          toast.error(formatMessage(messages.error))
          return
        }
        addFeature({
          accountList: [...accountList, { bank, unit, account }],
        })
        break
      default:
        break
    }

    toggleDrawer(e)
  }

  const renderUI = () => {
    switch (formType) {
      case 'address':
        return (
          <React.Fragment>
            <s.FormContent>
              <p>{formatMessage(messages.addressTitle)}</p>
              <Input
                placeholder={formatMessage(messages.localeName)}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <SearchAddress 
                label={formatMessage(messages.localeSearch)}
                visible={true} 
                onResult={addAddress} 
              />
            </s.FormContent>
            <s.Footer>
              <Button type='button' onClick={save}>
                {formatMessage(messages.submit)}
              </Button>
            </s.Footer>
          </React.Fragment>
        )
      case 'account':
        return (
          <React.Fragment>
            <s.FormContent>
              <p>Cadastre as contas em que deseja receber seus pagamentos</p>
              <Input
                placeholder={formatMessage(messages.banks)}
                value={bank}
                name='account.bank'
                onChange={(e) => setBank(e.target.value)}
              />
              <Input
                name='account.agency'
                placeholder={formatMessage(messages.agency)}
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              />
              <Input
                name='account.account'
                placeholder={formatMessage(messages.account)}
                value={account}
                onChange={(e) => setAccount(e.target.value)}
              />
            </s.FormContent>
            <s.Footer>
              <Button type='button' onClick={save}>
                Cadastrar
              </Button>
            </s.Footer>
          </React.Fragment>
        )
      default:
        return null
    }
  }

  return (
    <React.Fragment>
      <s.Container>{renderUI()}</s.Container>
    </React.Fragment>
  )
}

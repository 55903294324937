import React from 'react'
import { colors } from 'styles'

export default function ({ color, ...props }) {
  const fill = colors[color] || colors.defaultSVG
  return (
    <svg
      width='39'
      height='50'
      viewBox='0 0 39 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M559.4,442.34l-13.52-20.93a5.91,5.91,0,0,0-.56-10.78l4.1-14.14a1.75,1.75,0,0,0-.45-1.71,7.07,7.07,0,0,0-9.89,0,3.49,3.49,0,0,1-4.88,0h0a7.07,7.07,0,0,0-9.87,0l-.05.05a3.5,3.5,0,0,1-4.88,0h0a7.06,7.06,0,0,0-9.88,0,1.7,1.7,0,0,0-.45,1.71l4.1,14.14a5.91,5.91,0,0,0-.57,10.78l-13.52,20.93a25.3,25.3,0,0,0-.89,26.38,26,26,0,0,0,23,13.45h16.1a26,26,0,0,0,23-13.45A25.26,25.26,0,0,0,559.4,442.34Zm-42.52-45.09h0a7.07,7.07,0,0,0,9.87,0l0,0a3.49,3.49,0,0,1,4.88,0h0a7.06,7.06,0,0,0,9.88,0,3.48,3.48,0,0,1,4.1-.58l-3.92,13.54h-25l-3.93-13.54A3.48,3.48,0,0,1,516.88,397.25ZM557.19,467a22.45,22.45,0,0,1-19.91,11.64h-16.1A22.43,22.43,0,0,1,501.28,467a21.87,21.87,0,0,1,.76-22.82l14.3-22.13h4.93a1.75,1.75,0,1,0,0-3.5h-5.9a2.45,2.45,0,1,1,0-4.89h27.72a2.45,2.45,0,1,1,0,4.89h-5.9a1.75,1.75,0,1,0,0,3.5h4.93l14.3,22.13A21.87,21.87,0,0,1,557.19,467Z,M532.35,449.79,531,449.3v-7.91a7,7,0,0,1,2,.94,1.79,1.79,0,0,0,2.47-.35,1.72,1.72,0,0,0-.34-2.44A10.7,10.7,0,0,0,531,437.8v-1.74a1.77,1.77,0,0,0-3.54,0v1.72c-.3.06-.6.13-.91.22a7,7,0,0,0-4.86,5.49,6.11,6.11,0,0,0,2,6.08,17,17,0,0,0,3.72,2.09v10.13a7.18,7.18,0,0,1-3.88-1.4,1.78,1.78,0,0,0-2.45.5,1.75,1.75,0,0,0,.51,2.42,10.5,10.5,0,0,0,5.82,2v1.81a1.77,1.77,0,0,0,3.54,0v-2a8.46,8.46,0,0,0,6.5-6.86A7.38,7.38,0,0,0,532.35,449.79Zm-6.43-3a2.68,2.68,0,0,1-.75-2.68,3.55,3.55,0,0,1,2.29-2.75v6.39A9.69,9.69,0,0,1,525.92,446.82ZM534,457.66a5,5,0,0,1-3,3.79V453l.17.06A3.94,3.94,0,0,1,534,457.66Z,M529.23,422.09a1.75,1.75,0,0,0,1.25-.52,1.73,1.73,0,0,0,.52-1.23,1.77,1.77,0,0,0-.52-1.24,1.79,1.79,0,0,0-1.25-.51,1.82,1.82,0,0,0-1.25.51,1.77,1.77,0,0,0-.52,1.24,1.73,1.73,0,0,0,.52,1.23A1.79,1.79,0,0,0,529.23,422.09Z'
        fill={fill}
      />
    </svg>
  )
}

import React, { useState, useEffect } from 'react'
import {
  Container, FormContainer, Logo, Footer, Buttons,  Content
} from './styles'
import Text from 'components/Text'
import Title from 'components/Title'
import logo from 'assets/logo.svg'
import phoneIcon from 'assets/phone.svg'
import Button from 'components/Button'
import { Link, useHistory } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useUser } from 'modules/data/hooks/useUser';
import { useFarmer } from 'modules/data/hooks/useFarmer';
import PhoneModal from 'components/PhoneModal'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import messages from './messages'
import routes from 'configuration/routes'

const equalNumbers = (x, y) => {
  if(!x || !y) return false
  if(typeof x !== 'string' || typeof y !== 'string') return false
  return x.replace(/\D/g, "") === y.replace(/\D/g, "")
}

export default function VerifyPhoneNumber() {
	const [code, setCode] = useState(0)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const user = useUser()
  const { farmer } = useFarmer()
  const { formatMessage } = useIntl()

  const nextStep = routes.farmer.terms
  const prevStep = routes.farmer.confirmPassword

  const handleNextStep = () => {
    if(equalNumbers(farmer.phone, user.phoneNumber)) {
      history.push(nextStep)
      return
    }
  	if(!code) toast.warn('Preencha o código')
  }

  useEffect( () => {
    if(equalNumbers(farmer.phone, user.phoneNumber)) {
      setLoading(false)
      return
    }
  }, [ farmer, user])

  const handleError = error => {
    setLoading(false)
  }

  return(
    <Container>
    <FormContainer>
      <Logo>
        <img src={logo} alt='Agricon' />
      </Logo>
      <form action=''>
        <img src={phoneIcon} alt='Phone' />
        <Content>
        <Title className='title' color='primary'>
          {formatMessage(messages.title)}
        </Title>
        {
          equalNumbers(farmer.phone, user.phoneNumber) ? 
          <div>
            <Text className='infoDone'>{formatMessage(messages.done)}</Text>
          </div> :
          <div>
            <Text className='info'>{formatMessage(messages.info)}</Text>
            <PhoneModal
              submitId='sms'
              onSuccess={e => history.push(nextStep)}
              onError={e => handleError(e)}
              onLoading={e => setLoading(e)}
              onCode={code => setCode(code)}
              phone={farmer.phone}
            />
          </div>          
        } 
        <Footer>
          <Buttons>
            <Link className='voltar-desktop' to={prevStep}>
              <Text color='primary'>Voltar</Text>
            </Link>
            <Link className='voltar-mobile' to={prevStep}>
              <img src={voltar} alt='voltar' />
            </Link>
            <Button 
              loading={loading}
              type="button" 
              id="sms" 
              className="continue" 
              onClick={handleNextStep}>
              {
                equalNumbers(farmer.phone, user.phoneNumber) ?
                  formatMessage(messages.finish) :
                  formatMessage(messages.continue)
              }
            </Button>
          </Buttons>
        </Footer>
        </Content>
      </form>
    </FormContainer>
    </Container>
  )

}

// const proprieties = {
//   name: String,
//   type: String,
//   component: String,
//   value: any,
//   options: [{ label: String, value: any }],
//   required: Boolean,
//   active: Boolean,
// };

const fieldProprieties = {
  required: true,
  active: true,
  editable: true,
}

const stepProprieties = {
  active: true,
  editable: true,
}

const infos = {
  name: 'Informações de Cadastro',
  key: 'infos',
  ...stepProprieties,
  fields: [
    {
      placeholder: 'Número da Operação/Identificação:',
      name: 'id',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
      active: true,
      editable: false,
    },
    {
      placeholder: 'Arquivo',
      name: 'file',
      component: 'UPLOAD',
      ...fieldProprieties,
    },
    {
      placeholder: 'Responsável pela Operação:',
      name: 'owner',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
      active: true,
      required: false,
    },
    {
      placeholder: 'Data:',
      name: 'date',
      type: 'date',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Turno:',
      name: 'shift',
      component: 'SELECT',
      options: [
        { label: 'manhã', value: 'manhã' },
        { label: 'tarde', value: 'tarde' },
        { label: 'noite', value: 'noite' },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Hora de Início:',
      name: 'start_time',
      type: 'text',
      component: 'INPUT',
      format: 'time',
      ...fieldProprieties,
    },
    {
      placeholder: 'Hora de Término:',
      name: 'end_time',
      type: 'text',
      component: 'INPUT',
      format: 'time',
      ...fieldProprieties,
    },
    {
      placeholder: 'Nome do Produtor Responsável:',
      name: 'manager',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'CPF Produtor:',
      name: 'cpf',
      type: 'text',
      component: 'INPUT',
      format: 'cpf',
      ...fieldProprieties,
    },
    {
      placeholder: 'Propriedade (nome da fazenda)? ',
      name: 'name',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder:
        'CNPJ (se possui, existem alguns que possui sua fazenda como empresa):',
      name: 'cnpj',
      type: 'text',
      component: 'INPUT',
      format: 'cnpj',
      ...fieldProprieties,
    },
    {
      placeholder: 'Inscrição Estadual da Fazenda:',
      name: 'farm_registration',
      type: 'text',
      component: 'INPUT',
      format: 'cnpj',
      ...fieldProprieties,
    },
    {
      placeholder: 'CPFs Autorizados:',
      name: 'authorized_cpf',
      add_more_fields: true,
      type: 'text',
      component: 'INPUT',
      format: 'cpf',
      ...fieldProprieties,
    },
    {
      placeholder: 'Município:',
      name: 'city',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Estado:',
      name: 'state',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'País:',
      name: 'country',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Localização/coordenada Geográfica:',
      name: 'geolocation',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Área total (hectares):',
      name: 'number',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Área própria (hectares):',
      name: 'number',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Área arrendada (hectares):',
      name: 'number',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
  ],
}

const quest = {
  name: 'Questionário',
  key: 'quiz',
  ...stepProprieties,
  editable: true,
  fields: [
    {
      placeholder: 'O produtor responsável é proprietário?',
      name: 'is_manager_farmer',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Quantos anos você tem?',
      name: 'age',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Há quantos anos você é agricultor?',
      name: 'farmer_experience_time',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Sucessão familiar?',
      name: 'family_succession',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Grau de instrução?',
      name: 'literacy',
      component: 'SELECT',
      options: [{ label: 'graduação', value: 'graduação' }],
      ...fieldProprieties,
    },
    {
      placeholder: 'Profissão do seu pai?',
      name: 'father_profession',
      component: 'SELECT',
      options: [{ label: 'Economista', value: 'Economista' }],
      ...fieldProprieties,
    },
    {
      placeholder: 'Profissão da sua mãe?',
      name: 'mother_profession',
      component: 'SELECT',
      options: [{ label: 'Agrônomo', value: 'Agrônomo' }],
      ...fieldProprieties,
    },
    {
      placeholder: 'Você possui filhos?',
      name: 'have_children',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Quantos?',
      name: 'children_count',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Se sim, eles trabalham na propriedade rural?',
      name: 'children_work_in_farm',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Os filhos possuem formação?',
      name: 'are_children_trained',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se sim, qual?',
      name: 'children_training',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Quantos funcionários trabalham na propriedade?',
      name: 'employees_count',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Os funcionários são registrados?',
      name: 'registered_employees',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'FUNRURAL - (aqui vamos ter que add uma declaração, doc):',
      name: 'funrural',
      component: 'SELECT',
      options: [
        {
          label: 'descontado na comercialização ',
          value: 'descontado na comercialização ',
        },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Anexo Funrural?',
      name: 'funrural_attachment',
      component: 'SELECT',
      options: [
        {
          label: 'descontado na comercialização ',
          value: 'descontado na comercialização ',
        },
      ],
      ...fieldProprieties,
    },
    {
      placeholder:
        'Todas as crianças, residentes na propriedade, em idade escolar estão devidamente matriculadas?',
      name: 'registered_residents',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
  ],
}

const produtos = {
  name: 'Produtos da Propriedade',
  key: 'products',
  ...stepProprieties,
  fields: [
    {
      placeholder: 'Produtos',
      name: 'products',
      component: 'MULTISELECT',
      options: [
        { label: 'Soja GMO', value: 'Soja GMO' },
        { label: 'Soja non GMO', value: 'Soja non GMO' },
        { label: 'Soja Orgânica', value: 'Soja Orgânica' },
        { label: 'Milho GMO', value: 'Milho GMO' },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Certificação: Se produto organico',
      name: 'organic_product_certification',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Qual/Quais cultivar(es):',
      name: 'crops',
      add_more_fields: true,
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Tem outras certificações da propriedade?',
      name: 'have_other_property_certifications',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Sim, Qual?',
      name: 'other_property_certifications',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Se não, tem interesse?',
      name: 'other_property_certifications_interest',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder:
        'A sua fazenda estaria aberta a produzir produtos específicos sob demanda do cliente comprador?',
      name: 'open_to_producing_specific_products',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Possui irrigação na propriedade?',
      name: 'has_irrigation',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se sim, quantos hectares são irrigados?',
      name: 'irrigation_count',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Os implementos agrícolas/maquinários possuem GPS?',
      name: 'machinery_have_gps',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Quais?',
      name: 'machinery_with_gps',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder:
        'Os implementos agrícolas/maquinários possuem piloto automático?',
      name: 'machinery_have_autopilot',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Quais?',
      name: 'machinery_with_autopilot',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder:
        'A gestão da propriedade se dá de maneira manual ou utiliza-se software de gestão?',
      name: 'property_management_used',
      component: 'SELECT',
      options: [
        { label: 'Manual (excel/papéis)', value: 'Manual (excel/papéis)' },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se software de gestão. Qual?',
      name: 'management_software',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder:
        'Você assina algum canal de informações de preços e sobre a economia?',
      name: 'subscribe_information_channel',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se sim, qual?',
      name: 'information_channel',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Utiliza fontes alternativas de energia na propriedade?',
      name: 'alternative_energy',
      component: 'SELECT',
      options: [{ label: 'energia solar', value: 'energia solar' }],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se outros. Qual?',
      name: 'other_alternative_energy',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Adota práticas conservacionistas do solo?',
      name: 'adopt_soil_conservation_practices',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se sim, quais?',
      name: 'soil_conservation_practices',
      component: 'MULTISELECT',
      options: [
        {
          label: 'Semeadura direta',
          value: 0,
        },
        {
          label: 'Uso de plantas de cobertura',
          value: 1,
        },
        {
          label: 'Semeadura em nível',
          value: 2,
        },
        {
          label: 'Curvas/ terraceamento',
          value: 3,
        },
        {
          label: 'Outros',
          value: 4,
        },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se outros. Qual?',
      name: 'other_soil_conservation_practices',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'De modo geral a sua área possui erosão?',
      name: 'area_have_erosion',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Você compra sementes?',
      name: 'buy_seed',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Você produz a semente?',
      name: 'produces_seed',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder:
        'As áreas de vegetação natural (APP)  ao redor de nascentes e ao longo cursos naturais de água são mantidas ou restabelecidas?',
      name: 'natural_vegetation_maintained_or_restored',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder:
        'A propriedade utiliza algum manejo biológico nas suas áreas?',
      name: 'use_any_biological_management',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se sim, quais?',
      name: 'biological_management',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder:
        'A propriedade conta com assessoria de Eng. Agrônomos que emitem receituários agronômicos, respeitando as indicações dos fabricantes e melhores condições de aplicação?',
      name: 'property_have advice_from_agronomist_engineers',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Faz uso de rotação de culturas?',
      name: 'use_crop_rotation',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Toda aplicação de agroquímicos é documentada?',
      name: 'application_of_agrochemicals_documented',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se sim, como?',
      name: 'application_of_agrochemicals_description',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Há local apropriado para armazenamento dos agroquímicos?',
      name: 'have_appropriate_place_for_storage_of_agrochemicals',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder:
        'A propriedade devolve/retorna as embalagens vazias de agroquímicos às centrais de recebimento?',
      name: 'return_the_empty_agrochemical_packages_to_the_receiving_centers',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Possui coleta de lixo da prefeitura?',
      name: 'have_garbage_collection',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se não, como?',
      name: 'garbage_collection_alternative',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder:
        'Possui equipamentos para o controle de incêndios na propriedade?',
      name: 'have_equipment_for_fire_control',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'A propriedade realizou o georreferenciamento (INCRA)?',
      name: 'perform_incra',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se sim, quantos hectares?',
      name: 'incra_hectares',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder:
        'A propriedade rural possui Licença Ambiental para todas as atividades?',
      name: 'have_an_environmental_license_for_all_activities',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Quais? ex: silo, irrigação…',
      name: 'environmental_license',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Você tem cadastro no CAR?',
      name: 'car_registration',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se sim,  número do seu CAR?',
      name: 'car_number',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'A propriedade faz a segregação de produtos?',
      name: 'segregate_products',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
  ],
}

const info_tech = {
  name: 'Informações Técnicas da Armazenagem',
  key: 'technical_storage_information',
  ...stepProprieties,
  fields: [
    {
      placeholder: 'ID Armazenagem:',
      name: 'storage_id',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
      editable: false,
    },
    {
      placeholder: 'Tipo de armazenagem:',
      name: 'storage_type',
      component: 'SELECT',
      options: [{ label: 'Big Bag', value: 'Big Bag' }],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se outro, qual?',
      name: 'other_storage',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Produto: ',
      name: 'product',
      component: 'SELECT',
      options: [{ label: 'Soja non GMO', value: 'Soja non GMO' }],
      ...fieldProprieties,
    },
    {
      placeholder: 'Safra',
      name: 'crop',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Quantificação Estoque Anterior Tons:',
      name: 'previous_stock_tones',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Quantificação Estoque do Dia Tons: ',
      name: 'day_stock_tones',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Quantificação Saída do Dia Tons:',
      name: 'day_exit_tones',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Capacidade Total de Armazenagem Tons:',
      name: 'total_tone_storage_capacity',
      type: 'number',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder:
        'Forma de acondicionamento (bags, sacos, granel, etc) peso KG: Exemplo: Saco 60 kg',
      name: 'form_of_packaging',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Foi realizada alguma amostragem na inspeção?',
      name: 'some_sampling_was_performed_at_the_inspection',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Controle de quantidade:',
      name: 'quantity_control',
      component: 'SELECT',
      options: [{ label: 'Contagem', value: 'Contagem' }],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se outro, Qual?',
      name: 'other_quantity_control',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Há outro monitoramento no local?',
      name: 'has_other_monitoring',
      component: 'SELECT',
      options: [
        { label: 'sim', value: true },
        { label: 'não', value: false },
      ],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se sim, Qual?',
      name: 'other_monitoring',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Condições de armazenagem:',
      name: 'storage_conditions',
      component: 'SELECT',
      options: [{ label: 'Boas', value: 'Boas' }],
      ...fieldProprieties,
    },
    {
      placeholder: 'Por quê?',
      name: 'storage_conditions_why',
      add_more_fields: true,
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
    {
      placeholder: 'Classificação realizada por:',
      name: 'classification_performed_by',
      component: 'SELECT',
      options: [{ label: 'Agricon', value: 'Agricon' }],
      ...fieldProprieties,
    },
    {
      placeholder: 'Se outro, qual?',
      name: 'classification_performed_other',
      type: 'text',
      component: 'INPUT',
      ...fieldProprieties,
    },
  ],
}

const info_storage = {
  name: 'Informações Técnicas de Condições da Armazenagem',
  key: 'technical_storage_information',
  ...stepProprieties,
  fields: [
    {
      placeholder: 'Umidade:',
      name: 'moisture',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Impurezas:',
      name: 'impurities',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Avariados (Totais):',
      name: 'damaged_total',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Avariados (Queimados):',
      name: 'damaged_burned',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Avariados (Ardidos):',
      name: 'damaged_burning',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Avariados (Mofados):',
      name: 'damaged_moldy',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Avariados (Fermentados):',
      name: 'damaged_fermented',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Avariados (Danificados por insetos/carunchados):',
      name: 'damaged_by_insects_snails',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Avariados (Chochos):',
      name: 'damaged_chochos',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Esverdeados:',
      name: 'damaged_greenish',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Partidos e quebrados:',
      name: 'broken',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Peso Hectolitro (g):',
      name: 'hectoliter_weight',
      type: 'text',
      component: 'INPUT',
      format: 'percent',
      ...fieldProprieties,
    },
    {
      placeholder: 'Fotos:',
      name: 'photos',
      component: 'DROPZONE',
      ...fieldProprieties,
    },
  ],
}

export default [infos, quest, produtos, info_tech, info_storage]

const initialState = {
  error: {}
}

export default (state = initialState, action) => {
  switch (action?.type) {
    case 'inspection/create/error':
      return {
        ...state,
        error: { ...state.error, create: action.error}
      }
    case 'inspection/add/error':
      return {
        ...state,
        error: { ...state.error, update: action.error}
      }
    default:
      return state
  }
}

import React, { useEffect } from 'react'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { useUser } from '../modules/data/hooks/useUser'

import InitialRoutes from 'Routes/initial.routes.js'
import FarmerRoutes from 'Routes/farmer.routes.js'
import BuyerRoutes from 'Routes/buyer.routes.js'
import AdminRoutes from 'Routes/admin.routes.js'
import PlatformRoutes from 'Routes/platform.routes.js'
import withSplashScreen from 'components/withSplashScreen'

import ptBR from 'i18n/locales/pt-br.json'
import en from 'i18n/locales/en.json'
import firebase from 'firebase'

function Routes() {
  const { user } = useUser()
  const locale = user && user.roles && user.roles.buyer ? 'en-us' : 'pt-br'
  let lang = locale === 'pt-br' ? ptBR : en
  // const locale = 'pt-br'
  // let lang = ptBR

  const RoutesListener = () => {
    const location = useLocation()
    useEffect(() => {
      firebase.analytics().setCurrentScreen(location.pathname)
    }, [location])

    return <></>
  }

  return (
    <IntlProvider locale={locale} key={locale} messages={lang}>
      <Router>
        <RoutesListener />
        <InitialRoutes />
        <FarmerRoutes />
        <BuyerRoutes />
        <AdminRoutes />
        <PlatformRoutes />
      </Router>
    </IntlProvider>
  )
}

export default withSplashScreen(Routes)

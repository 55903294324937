export default [
  {
    name: 'Ajman',
    city: 'Ajman',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    coordinates: [55.5136433, 25.4052165],
    province: 'Ajman',
    timezone: 'Asia/Dubai',
    unlocs: ['AEAJM'],
    code: '52000',
    id: 'AEAJM',
  },
  {
    name: 'Abu Dhabi',
    coordinates: [54.37, 24.47],
    city: 'Abu Dhabi',
    province: 'Abu Z¸aby [Abu Dhabi]',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    timezone: 'Asia/Dubai',
    unlocs: ['AEAUH'],
    code: '52001',
    id: 'AEAUH',
  },
  {
    name: 'Dubai',
    coordinates: [55.27, 25.25],
    city: 'Dubai',
    province: 'Dubayy [Dubai]',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    timezone: 'Asia/Dubai',
    unlocs: ['AEDXB'],
    code: '52005',
    id: 'AEDXB',
  },
  {
    name: 'Al Fujayrah',
    coordinates: [56.33, 25.12],
    city: 'Al Fujayrah',
    province: 'Al Fujayrah',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    timezone: 'Asia/Dubai',
    unlocs: ['AEFJR'],
    id: 'AEFJR',
  },
  {
    name: 'Jebel Ali',
    city: 'Jebel Ali',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    coordinates: [55.0272904, 24.9857145],
    province: 'Dubai',
    timezone: 'Asia/Dubai',
    unlocs: ['AEJEA'],
    code: '52051',
    id: 'AEJEA',
  },
  {
    name: 'Jebel Dhanna',
    city: 'Jebel Dhanna',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    coordinates: [52.6126027, 24.1915137],
    province: 'Abu Dhabi',
    timezone: 'Asia/Dubai',
    unlocs: ['AEJED'],
    code: '52050',
    id: 'AEJED',
  },
  {
    name: 'Khor al Fakkan',
    coordinates: [56.35, 25.33],
    city: 'Khor al Fakkan',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    timezone: 'Asia/Dubai',
    unlocs: ['AEKLF'],
    id: 'AEKLF',
  },
  {
    name: 'Port Rashid',
    city: 'Port Rashid',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    coordinates: [55.2756505, 25.284755],
    province: 'Dubai',
    timezone: 'Asia/Dubai',
    unlocs: ['AEPRA'],
    code: '52005',
    id: 'AEPRA',
  },
  {
    name: 'Umm al Qaiwain',
    coordinates: [55.55, 25.57],
    city: 'Umm al Qaiwain',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    province: 'Umm Al Quwain',
    timezone: 'Asia/Dubai',
    unlocs: ['AEQIW'],
    id: 'AEQIW',
  },
  {
    name: 'Ras al Khaimah',
    coordinates: [55.95, 25.78],
    city: 'Ras al Khaimah',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    province: 'Ras al Khaimah',
    timezone: 'Asia/Dubai',
    unlocs: ['AERKT'],
    code: '52000',
    id: 'AERKT',
  },
  {
    name: 'Ar Ruways',
    coordinates: [52.73, 24.12],
    city: 'Ar Ruways',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    province: 'Abu Dhabi',
    timezone: 'Asia/Dubai',
    unlocs: ['AERUW'],
    id: 'AERUW',
  },
  {
    name: 'Sharjah',
    coordinates: [55.38, 25.35],
    city: 'Sharjah',
    province: 'Ash Shariqah [Sharjah]',
    country: 'United Arab Emirates',
    alias: [],
    regions: [],
    timezone: 'Asia/Dubai',
    unlocs: ['AESHJ'],
    code: '52070',
    id: 'AESHJ',
  },
  {
    name: 'Antigua',
    city: 'Antigua',
    country: 'Antigua and Barbuda',
    alias: [],
    regions: [],
    coordinates: [-61.8175207, 17.0746557],
    timezone: 'America/Antigua',
    unlocs: ['AGANU'],
    code: '24831',
    id: 'AGANU',
  },
  {
    name: "Saint John's",
    coordinates: [-61.85, 17.12],
    city: "Saint John's",
    country: 'Antigua and Barbuda',
    alias: [],
    regions: [],
    province: 'Saint John',
    timezone: 'America/Antigua',
    unlocs: ['AGSJO'],
    code: '24831',
    id: 'AGSJO',
  },
  {
    name: 'Blowing Point',
    city: 'Blowing Point',
    country: 'Anguilla',
    alias: [],
    regions: [],
    coordinates: [-63.093518, 18.177343],
    timezone: 'America/Anguilla',
    unlocs: ['AIBLP'],
    code: '24821',
    id: 'AIBLP',
  },
  {
    name: 'Road Bay',
    city: 'Road Bay',
    country: 'Anguilla',
    alias: [],
    regions: [],
    coordinates: [-63.0653265, 18.2207493],
    timezone: 'America/Anguilla',
    unlocs: ['AIRBY'],
    code: '24821',
    id: 'AIRBY',
  },
  {
    name: 'Durres',
    city: 'Durrës',
    country: 'Albania',
    alias: [],
    regions: [],
    coordinates: [19.4616074, 41.3328072],
    timezone: 'Europe/Tirane',
    unlocs: ['ALDRZ'],
    code: '48109',
    id: 'ALDRZ',
  },
  {
    name: 'Bonaire',
    city: 'Bonaire',
    country: 'Netherlands Antilles',
    alias: [],
    regions: [],
    coordinates: [-68.26238219999999, 12.2018902],
    timezone: 'America/Curacao',
    unlocs: ['ANBON'],
    id: 'ANBON',
  },
  {
    name: 'Curacao',
    city: 'Curacao',
    country: 'Netherlands Antilles',
    alias: [],
    regions: [],
    coordinates: [-68.8824233, 12.1224221],
    province: 'Curaçao',
    timezone: 'America/Curacao',
    unlocs: ['ANCUR'],
    id: 'ANCUR',
  },
  {
    name: 'Sint Eustatius (Antilles)',
    city: 'Sint Eustatius (Antilles)',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [-63.08416659999999, 18.0348259],
    province: 'Sint Maarten',
    timezone: 'America/Curacao',
    unlocs: ['ANEUX'],
    id: 'ANEUX',
  },
  {
    name: 'Galisby',
    city: 'Galisby',
    country: 'Netherlands Antilles',
    alias: [],
    regions: [],
    coordinates: [12.1686756, -68.4486145],
    unlocs: ['ANGSB'],
    id: 'ANGSB',
  },
  {
    name: 'Philipsburg',
    city: 'Philipsburg',
    country: 'Netherlands Antilles',
    alias: [],
    regions: [],
    coordinates: [-63.04713709999999, 18.0295839],
    province: 'Sint Maarten',
    timezone: 'America/Curacao',
    unlocs: ['ANPHI'],
    id: 'ANPHI',
  },
  {
    name: 'St Maarten',
    city: 'St Maarten',
    country: 'Netherlands Antilles',
    alias: [],
    regions: [],
    coordinates: [-68.81088489999999, 12.2248767],
    timezone: 'America/Curacao',
    unlocs: ['ANSXM'],
    id: 'ANSXM',
  },
  {
    name: 'Willemstad',
    city: 'Willemstad',
    country: 'Netherlands Antilles',
    alias: [],
    regions: [],
    coordinates: [-68.8824233, 12.1224221],
    province: 'Curaçao',
    timezone: 'America/Curacao',
    unlocs: ['ANWIL'],
    id: 'ANWIL',
  },
  {
    name: 'Cabinda',
    city: 'Cabinda',
    country: 'Angola',
    alias: [],
    regions: [],
    coordinates: [12.197583, -5.570573899999999],
    province: 'Cabinda',
    timezone: 'Africa/Luanda',
    unlocs: ['AOCAB'],
    code: '76231',
    id: 'AOCAB',
  },
  {
    name: 'Luanda',
    city: 'Luanda',
    country: 'Angola',
    alias: [],
    regions: [],
    coordinates: [13.2894368, -8.839987599999999],
    province: 'Luanda',
    timezone: 'Africa/Luanda',
    unlocs: ['AOLAD'],
    code: '76278',
    id: 'AOLAD',
  },
  {
    name: 'Lobito',
    city: 'Lobito',
    country: 'Angola',
    alias: [],
    regions: [],
    coordinates: [13.5610451, -12.3757287],
    province: 'Benguela',
    timezone: 'Africa/Luanda',
    unlocs: ['AOLOB'],
    code: '76274',
    id: 'AOLOB',
  },
  {
    name: 'Namibe',
    city: 'Namibe',
    country: 'Angola',
    alias: [],
    regions: [],
    coordinates: [12.1575544, -15.1978317],
    province: 'Namibe',
    timezone: 'Africa/Luanda',
    unlocs: ['AOMSZ'],
    code: '76279',
    id: 'AOMSZ',
  },
  {
    name: 'Soyo',
    city: 'Soyo',
    country: 'Angola',
    alias: [],
    regions: [],
    coordinates: [12.3521148, -6.1410265],
    province: 'Zaire',
    timezone: 'Africa/Luanda',
    unlocs: ['AOSZA'],
    code: '76281',
    id: 'AOSZA',
  },
  {
    name: 'Bahia Blanca',
    coordinates: [-62.28, -38.72],
    city: 'Bahía Blanca',
    province: 'Buenos Aires',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARBHI'],
    code: '35701',
    id: 'ARBHI',
  },
  {
    name: 'Buenos Aires',
    coordinates: [-58.67, -34.58],
    city: 'Buenos Aires',
    province: 'Ciudad Autónoma de Buenos Aires',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARBUE'],
    code: '35705',
    id: 'ARBUE',
  },
  {
    name: 'Campana',
    coordinates: [-58.95, -34.15],
    city: 'Campana',
    province: 'Buenos Aires',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARCMP'],
    code: '35711',
    id: 'ARCMP',
  },
  {
    name: 'Corrientes',
    city: 'Corrientes',
    province: 'Corrientes',
    country: 'Argentina',
    alias: [],
    regions: [],
    coordinates: [-58.8306349, -27.4692131],
    timezone: 'America/Cordoba',
    unlocs: ['ARCNQ'],
    code: '35700',
    id: 'ARCNQ',
  },
  {
    name: 'Comodoro Rivadavia',
    city: 'Comodoro Rivadavia',
    province: 'Chubut',
    country: 'Argentina',
    alias: [],
    regions: [],
    coordinates: [-67.4822429, -45.8656149],
    timezone: 'America/Cordoba',
    unlocs: ['ARCRD'],
    code: '35702',
    id: 'ARCRD',
  },
  {
    name: 'Caleta Olivia',
    city: 'Caleta Olivia',
    province: 'Santa Cruz',
    country: 'Argentina',
    alias: [],
    regions: [],
    coordinates: [-67.5171533, -46.4425647],
    timezone: 'America/Cordoba',
    unlocs: ['ARCVI'],
    code: '35703',
    id: 'ARCVI',
  },
  {
    name: 'Delta Dock',
    city: 'Delta Dock',
    province: 'Rio Parana',
    country: 'Argentina',
    alias: [],
    regions: [],
    coordinates: [-34.0447572, -59.199761],
    unlocs: ['ARDDC'],
    code: '35700',
    id: 'ARDDC',
  },
  {
    name: 'La Plata',
    coordinates: [-57.88, -34.85],
    city: 'La Plata',
    province: 'Buenos Aires',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARLPG'],
    code: '35737',
    id: 'ARLPG',
  },
  {
    name: 'Mar del Plata',
    coordinates: [-57.53, -38.05],
    city: 'Mar del Plata',
    province: 'Buenos Aires',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARMDQ'],
    code: '35739',
    id: 'ARMDQ',
  },
  {
    name: 'Necochea',
    city: 'Necochea',
    province: 'Buenos Aires',
    country: 'Argentina',
    alias: [],
    regions: [],
    coordinates: [-58.73960879999999, -38.5544968],
    timezone: 'America/Cordoba',
    unlocs: ['ARNEC'],
    code: '35741',
    id: 'ARNEC',
  },
  {
    name: 'Goya',
    city: 'Goya',
    province: 'Corrientes',
    country: 'Argentina',
    alias: [],
    regions: [],
    coordinates: [-59.2643242, -29.1442242],
    timezone: 'America/Cordoba',
    unlocs: ['AROYA'],
    code: '35700',
    id: 'AROYA',
  },
  {
    name: 'Puerto Madryn',
    coordinates: [-65.03, -42.75],
    city: 'Puerto Madryn',
    province: 'Chubut',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARPMY'],
    code: '35740',
    id: 'ARPMY',
  },
  {
    name: 'Puerto Deseado',
    coordinates: [-65.9, -47.75],
    city: 'Puerto Deseado',
    province: 'Santa Cruz',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARPUD'],
    code: '35745',
    id: 'ARPUD',
  },
  {
    name: 'Rio Grande',
    coordinates: [-67.7, -53.78],
    city: 'Río Grande',
    province: 'Tierra del Fuego',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARRGA'],
    code: '35700',
    id: 'ARRGA',
  },
  {
    name: 'Rio Gallegos',
    coordinates: [-69.22, -51.63],
    city: 'Río Gallegos',
    province: 'Santa Cruz',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARRGL'],
    code: '35700',
    id: 'ARRGL',
  },
  {
    name: 'Rio Cullen',
    city: 'Rio Cullen',
    province: 'Zaire',
    country: 'Argentina',
    unlocs: ['ARRIC'],
    timezone: 'America/Argentina',
    coordinates: [-68.3523021, -52.8955609],
    alias: [],
    regions: [],
    code: '35700',
    id: 'ARRIC',
  },
  {
    name: 'Rosario',
    coordinates: [-60.65, -32.95],
    city: 'Rosario',
    province: 'Santa Fe',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARROS'],
    code: '35771',
    id: 'ARROS',
  },
  {
    name: 'San Antonio Este',
    coordinates: [-64.73, -40.8],
    city: 'San Antonio Este',
    province: 'Río Negro',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARSAE'],
    code: '35700',
    id: 'ARSAE',
  },
  {
    name: 'Santa Fe',
    coordinates: [-60.68, -31.6],
    city: 'Santa Fe',
    province: 'Santa Fe',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARSFN'],
    code: '35791',
    id: 'ARSFN',
  },
  {
    name: 'San Lorenzo',
    city: 'San Lorenzo',
    province: 'La Pampa',
    country: 'Argentina',
    alias: [],
    regions: [],
    coordinates: [-60.7238722, -32.8072889],
    timezone: 'America/Cordoba',
    unlocs: ['ARSLO'],
    code: '35779',
    id: 'ARSLO',
  },
  {
    name: 'San Nicolas de los Arroyos',
    coordinates: [-60.23, -33.33],
    city: 'San Nicolás de los Arroyos',
    province: 'Buenos Aires',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARSNS'],
    code: '35781',
    id: 'ARSNS',
  },
  {
    name: 'San Pedro',
    coordinates: [-59.17, -33.68],
    city: 'San Pedro',
    province: 'Entre Ríos',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARSPD'],
    code: '35785',
    id: 'ARSPD',
  },
  {
    name: 'Ushuaia',
    coordinates: [-68.3, -54.8],
    city: 'Ushuaia',
    province: 'Tierra del Fuego',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARUSH'],
    code: '35700',
    id: 'ARUSH',
  },
  {
    name: 'Zarate',
    coordinates: [-59.03, -34.1],
    city: 'Zárate',
    province: 'Buenos Aires',
    country: 'Argentina',
    alias: [],
    regions: [],
    timezone: 'America/Cordoba',
    unlocs: ['ARZAE'],
    code: '35796',
    id: 'ARZAE',
  },
  {
    name: 'Pago Pago',
    city: 'Pago Pago',
    country: 'American Samoa',
    alias: [],
    regions: [],
    coordinates: [-170.7020359, -14.2756319],
    province: 'Eastern',
    timezone: 'Pacific/Pago_Pago',
    unlocs: ['ASPPG'],
    code: '95101',
    id: 'ASPPG',
  },
  {
    timezone: 'Europe/Vienna',
    coordinates: [15.7733196, 48.0337274],
    name: 'Hainfeld',
    city: 'Hainfeld',
    province: 'Niederösterreich',
    country: 'Austria',
    alias: [],
    regions: [],
    unlocs: ['ATLTX'],
    code: '60200',
    id: 'ATLTX',
  },
  {
    name: 'Abbot Point',
    city: 'Abbot Point',
    province: 'Queensland',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [148.0846726, -19.9078861],
    timezone: 'Australia/Brisbane',
    unlocs: ['AUABP'],
    code: '60200',
    id: 'AUABP',
  },
  {
    name: 'Adelaide',
    coordinates: [138.58, -34.92],
    city: 'Adelaide',
    province: 'South Australia',
    country: 'Australia',
    alias: [],
    regions: [],
    timezone: 'Australia/Adelaide',
    unlocs: ['AUADL'],
    code: '60201',
    id: 'AUADL',
  },
  {
    name: 'Ardrossan',
    city: 'Ardrossan',
    province: 'South Australia',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [137.9174283, -34.4230702],
    timezone: 'Australia/Adelaide',
    unlocs: ['AUARD'],
    code: '60276',
    id: 'AUARD',
  },
  {
    timezone: 'Australia/Perth',
    coordinates: [119.379376, -34.3926208],
    name: 'Bremer Bay',
    city: 'Bremer Bay',
    province: 'Western Australia',
    country: 'Australia',
    alias: [],
    regions: [],
    unlocs: ['AUBBY'],
    code: '60200',
    id: 'AUBBY',
  },
  {
    name: 'Bell Bay',
    city: 'Bell Bay',
    province: 'Tasmania',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [146.8635558, -41.1154147],
    timezone: 'Australia/Melbourne',
    unlocs: ['AUBEL'],
    code: '60290',
    id: 'AUBEL',
  },
  {
    name: 'Brisbane',
    coordinates: [153.02, -27.47],
    city: 'Brisbane',
    province: 'Queensland',
    country: 'Australia',
    alias: [],
    regions: [],
    timezone: 'Australia/Brisbane',
    unlocs: ['AUBNE'],
    code: '60210',
    id: 'AUBNE',
  },
  {
    name: 'Booby Island',
    coordinates: [141.92, -10.6],
    city: 'Booby Island',
    province: 'Queensland',
    country: 'Australia',
    alias: [],
    regions: [],
    timezone: 'Australia/Brisbane',
    unlocs: ['AUBOO'],
    code: '60200',
    id: 'AUBOO',
  },
  {
    name: 'Burnie',
    city: 'Burnie',
    province: 'Tasmania',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [145.9068513, -41.0524649],
    timezone: 'Australia/Melbourne',
    unlocs: ['AUBWT'],
    code: '60291',
    id: 'AUBWT',
  },
  {
    timezone: 'Australia/Perth',
    coordinates: [116.1010108, -31.4700549],
    name: 'Chittering',
    city: 'Chittering',
    province: 'Western Australia',
    country: 'Australia',
    alias: [],
    regions: [],
    unlocs: ['AUCHT'],
    code: '60200',
    id: 'AUCHT',
  },
  {
    name: 'Cairns',
    city: 'Cairns',
    province: 'Queensland',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [145.7708595, -16.9203338],
    timezone: 'Australia/Brisbane',
    unlocs: ['AUCNS'],
    code: '60200',
    id: 'AUCNS',
  },
  {
    name: 'Devonport',
    city: 'Devonport',
    province: 'Tasmania',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [146.3609534, -41.1783532],
    timezone: 'Australia/Melbourne',
    unlocs: ['AUDPO'],
    code: '60299',
    id: 'AUDPO',
  },
  {
    name: 'Darwin',
    coordinates: [130.83, -12.45],
    city: 'Darwin',
    province: 'Northern Territory',
    country: 'Australia',
    alias: [],
    regions: [],
    timezone: 'Australia/Darwin',
    unlocs: ['AUDRW'],
    code: '60220',
    id: 'AUDRW',
  },
  {
    name: 'Fremantle',
    city: 'Fremantle',
    province: 'Western Australia',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [115.7471797, -32.0560399],
    timezone: 'Australia/Perth',
    unlocs: ['AUFRE'],
    code: '60222',
    id: 'AUFRE',
  },
  {
    name: 'Gladstone',
    city: 'Gladstone',
    province: 'Queensland',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [151.2597998, -23.8487083],
    timezone: 'Australia/Brisbane',
    unlocs: ['AUGLT'],
    code: '60231',
    id: 'AUGLT',
  },
  {
    name: 'Gove',
    city: 'Gove',
    province: 'Northern Territory',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [130.8717878, -12.3780716],
    timezone: 'Australia/Darwin',
    unlocs: ['AUGOV'],
    code: '60227',
    id: 'AUGOV',
  },
  {
    name: 'Hobart',
    coordinates: [147.3, -42.87],
    city: 'Hobart',
    province: 'Tasmania',
    country: 'Australia',
    alias: [],
    regions: [],
    timezone: 'Australia/Melbourne',
    unlocs: ['AUHBA'],
    code: '60295',
    id: 'AUHBA',
  },
  {
    name: 'Hay Point',
    city: 'Hay Point',
    province: 'Queensland',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [149.2921629, -21.3055785],
    timezone: 'Australia/Brisbane',
    unlocs: ['AUHPT'],
    code: '60212',
    id: 'AUHPT',
  },
  {
    name: 'Launceston',
    city: 'Launceston',
    province: 'Tasmania',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [147.1124679, -41.4261807],
    timezone: 'Australia/Melbourne',
    unlocs: ['AULST'],
    code: '60297',
    id: 'AULST',
  },
  {
    name: 'Melbourne',
    coordinates: [144.97, -37.82],
    city: 'Melbourne',
    province: 'Victoria',
    country: 'Australia',
    alias: [],
    regions: [],
    timezone: 'Australia/Hobart',
    unlocs: ['AUMEL'],
    code: '60237',
    id: 'AUMEL',
  },
  {
    timezone: 'Australia/Melbourne',
    coordinates: [147.0636496, -42.7806827],
    name: 'New Norfolk',
    city: 'New Norfolk',
    province: 'Tasmania',
    country: 'Australia',
    alias: [],
    regions: [],
    unlocs: ['AUNLK'],
    code: '60200',
    id: 'AUNLK',
  },
  {
    name: 'Newcastle',
    city: 'Newcastle',
    province: 'New South Wales',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [151.7789205, -32.926689],
    timezone: 'Australia/Sydney',
    unlocs: ['AUNTL'],
    code: '60240',
    id: 'AUNTL',
  },
  {
    name: 'Port Adelaide',
    city: 'Port Adelaide',
    province: 'South Australia',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [138.5073616, -34.8477448],
    timezone: 'Australia/Adelaide',
    unlocs: ['AUPAE'],
    code: '60201',
    id: 'AUPAE',
  },
  {
    name: 'Port Hedland',
    city: 'Port Hedland',
    province: 'Western Australia',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [118.5752577, -20.3116266],
    timezone: 'Australia/Perth',
    unlocs: ['AUPHE'],
    code: '60219',
    id: 'AUPHE',
  },
  {
    name: 'Port Kembla',
    city: 'Port Kembla',
    province: 'New South Wales',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [150.900495, -34.4800735],
    timezone: 'Australia/Sydney',
    unlocs: ['AUPKL'],
    code: '60252',
    id: 'AUPKL',
  },
  {
    name: 'Sydney',
    coordinates: [151.2, -33.85],
    city: 'Sydney',
    province: 'New South Wales',
    country: 'Australia',
    alias: [],
    regions: [],
    timezone: 'Australia/Sydney',
    unlocs: ['AUSYD'],
    code: '60267',
    id: 'AUSYD',
  },
  {
    name: 'Townsville',
    city: 'Townsville',
    province: 'Queensland',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [146.8178707, -19.2576268],
    timezone: 'Australia/Brisbane',
    unlocs: ['AUTSV'],
    code: '60273',
    id: 'AUTSV',
  },
  {
    name: 'Weipa',
    city: 'Weipa',
    province: 'Queensland',
    country: 'Australia',
    alias: [],
    regions: [],
    coordinates: [141.8384064, -12.6591475],
    timezone: 'Australia/Brisbane',
    unlocs: ['AUWEI'],
    code: '60275',
    id: 'AUWEI',
  },
  {
    name: 'Aruba',
    coordinates: [-69.97, 12.5],
    city: 'Aruba',
    country: 'Aruba',
    alias: [],
    regions: [],
    timezone: 'America/Aruba',
    unlocs: ['AWAUA'],
    code: '27703',
    id: 'AWAUA',
  },
  {
    name: 'Oranjestad',
    city: 'Oranjestad',
    country: 'Aruba',
    alias: [],
    regions: [],
    coordinates: [-70.0086306, 12.5092044],
    timezone: 'America/Aruba',
    unlocs: ['AWORJ'],
    code: '27701',
    id: 'AWORJ',
  },
  {
    name: 'Baku',
    city: 'Baku',
    country: 'Azerbaijan',
    alias: [],
    regions: [],
    coordinates: [49.8670924, 40.40926169999999],
    timezone: 'Asia/Baku',
    unlocs: ['AZBAK'],
    id: 'AZBAK',
  },
  {
    name: 'Bridgetown',
    city: 'Bridgetown',
    country: 'Barbados',
    alias: [],
    regions: [],
    coordinates: [-59.59880889999999, 13.1132219],
    province: 'Saint Michael',
    timezone: 'America/Barbados',
    unlocs: ['BBBGI'],
    code: '27201',
    id: 'BBBGI',
  },
  {
    name: 'Chittagong',
    city: 'Chittagong',
    country: 'Bangladesh',
    alias: [],
    regions: [],
    coordinates: [91.8123324, 22.3475365],
    province: 'Chittagong Division',
    timezone: 'Asia/Dhaka',
    unlocs: ['BDCGP'],
    code: '53827',
    id: 'BDCGP',
  },
  {
    name: 'Chalna',
    city: 'Chalna',
    country: 'Bangladesh',
    alias: [],
    regions: [],
    coordinates: [89.5139693, 22.6036965],
    province: 'Khulna Division',
    timezone: 'Asia/Dhaka',
    unlocs: ['BDCHL'],
    code: '53820',
    id: 'BDCHL',
  },
  {
    name: 'Dhaka',
    city: 'Dhaka',
    country: 'Bangladesh',
    alias: [],
    regions: [],
    coordinates: [90.4125181, 23.810332],
    province: 'Dhaka Division',
    timezone: 'Asia/Dhaka',
    unlocs: ['BDDAC'],
    code: '53800',
    id: 'BDDAC',
  },
  {
    name: 'Mongla',
    city: 'Mongla',
    country: 'Bangladesh',
    alias: [],
    regions: [],
    coordinates: [89.6016171, 22.4942196],
    province: 'Khulna Division',
    timezone: 'Asia/Dhaka',
    unlocs: ['BDMGL'],
    code: '53800',
    id: 'BDMGL',
  },
  {
    name: 'Antwerpen',
    coordinates: [4.42, 51.22],
    city: 'Antwerpen',
    province: 'Antwerpen',
    country: 'Belgium',
    alias: [],
    regions: [],
    timezone: 'Europe/Brussels',
    unlocs: ['BEANR'],
    code: '42305',
    id: 'BEANR',
  },
  {
    timezone: 'Europe/Brussels',
    coordinates: [3.4072881, 50.7481174],
    name: 'Bossuit',
    city: 'Bossuit',
    province: 'West-Vlaanderen',
    country: 'Belgium',
    alias: [],
    regions: [],
    unlocs: ['BEBSU'],
    code: '42300',
    id: 'BEBSU',
  },
  {
    timezone: 'Europe/Brussels',
    coordinates: [4.264974899999999, 51.31059759999999],
    name: 'Doel',
    city: 'Doel',
    province: 'Oost-Vlaanderen',
    country: 'Belgium',
    alias: [],
    regions: [],
    unlocs: ['BEDEL'],
    code: '42300',
    id: 'BEDEL',
  },
  {
    name: 'Gent (Ghent)',
    coordinates: [3.72, 51.05],
    city: 'Gent (Ghent)',
    province: 'Oost-Vlaanderen',
    country: 'Belgium',
    alias: [],
    regions: [],
    timezone: 'Europe/Brussels',
    unlocs: ['BEGNE'],
    code: '42331',
    id: 'BEGNE',
  },
  {
    name: 'Oostende (Ostend)',
    coordinates: [2.92, 51.2],
    city: 'Oostende (Ostend)',
    province: 'West-Vlaanderen',
    country: 'Belgium',
    alias: [],
    regions: [],
    timezone: 'Europe/Brussels',
    unlocs: ['BEOST'],
    code: '42351',
    id: 'BEOST',
  },
  {
    name: 'Zeebrugge',
    coordinates: [3.2, 51.33],
    city: 'Zeebrugge',
    province: 'West-Vlaanderen',
    country: 'Belgium',
    alias: [],
    regions: [],
    timezone: 'Europe/Brussels',
    unlocs: ['BEZEE'],
    code: '42381',
    id: 'BEZEE',
  },
  {
    name: 'Ouagadougou',
    city: 'Ouagadougou',
    country: 'Burkina Faso',
    alias: [],
    regions: [],
    coordinates: [-1.5196603, 12.3714277],
    province: 'Centre',
    timezone: 'Africa/Ouagadougou',
    unlocs: ['BFOUA'],
    id: 'BFOUA',
  },
  {
    name: 'Burgas',
    city: 'Burgas',
    country: 'Bulgaria',
    alias: [],
    regions: [],
    coordinates: [27.4626361, 42.50479259999999],
    province: 'Burgas',
    timezone: 'Europe/Sofia',
    unlocs: ['BGBOJ'],
    code: '48702',
    id: 'BGBOJ',
  },
  {
    name: 'Varna',
    city: 'Varna',
    country: 'Bulgaria',
    alias: [],
    regions: [],
    coordinates: [27.9147333, 43.2140504],
    province: 'Varna',
    timezone: 'Europe/Sofia',
    unlocs: ['BGVAR'],
    code: '48707',
    id: 'BGVAR',
  },
  {
    country: 'Bahrain',
    province: 'Bahrain',
    city: 'Al HIdd',
    code: '52500',
    name: 'Al Hidd',
    alias: [],
    regions: [],
    unlocs: ['BHAHD'],
    id: 'BHAHD',
  },
  {
    name: 'Bahrain',
    coordinates: [50.63, 26.27],
    city: 'Bahrain',
    province: 'Al Muharraq',
    country: 'Bahrain',
    alias: [],
    regions: [],
    timezone: 'Asia/Bahrain',
    unlocs: ['BHBAH'],
    code: '52500',
    id: 'BHBAH',
  },
  {
    name: 'Khalifa Bin Salman Port',
    coordinates: [50.62, 26.2],
    city: 'Khalifa Bin Salman',
    province: 'Al Muharraq',
    country: 'Bahrain',
    alias: [],
    regions: [],
    timezone: 'Asia/Bahrain',
    unlocs: ['BHKBS'],
    code: '52500',
    id: 'BHKBS',
  },
  {
    name: 'Manama',
    city: 'Manama',
    country: 'Bahrain',
    alias: [],
    regions: [],
    coordinates: [50.5860497, 26.2285161],
    timezone: 'Asia/Bahrain',
    unlocs: ['BHMAN'],
    code: '52502',
    id: 'BHMAN',
  },
  {
    name: 'Sitrah',
    coordinates: [50.62, 26.13],
    city: 'Sitrah',
    province: 'Al Wustá',
    country: 'Bahrain',
    alias: [],
    regions: [],
    timezone: 'Asia/Bahrain',
    unlocs: ['BHSIT'],
    code: '52500',
    id: 'BHSIT',
  },
  {
    name: 'Cotonou',
    city: 'Cotonou',
    country: 'Benin',
    alias: [],
    regions: [],
    coordinates: [2.3912362, 6.3702928],
    province: 'Littoral',
    timezone: 'Africa/Porto-Novo',
    unlocs: ['BJCOO'],
    code: '76101',
    id: 'BJCOO',
  },
  {
    name: 'Hamilton',
    city: 'Hamilton',
    country: 'Bermuda',
    alias: [],
    regions: [],
    coordinates: [-64.781375, 32.294816],
    province: 'Hamilton',
    timezone: 'Atlantic/Bermuda',
    unlocs: ['BMBDA'],
    code: '23201',
    id: 'BMBDA',
  },
  {
    timezone: 'Europe/London',
    coordinates: [-1.4043509, 50.90970040000001],
    name: 'Southampton',
    city: 'Southampton',
    province: 'Zou',
    country: 'Bermuda',
    alias: [],
    regions: [],
    unlocs: ['BMSOU'],
    code: '23299',
    id: 'BMSOU',
  },
  {
    name: 'Bandar Seri Begawan',
    coordinates: [114.93, 4.88],
    city: 'Bandar Seri Begawan',
    country: 'Brunei Darussalam',
    alias: [],
    regions: [],
    province: 'Brunei-Muara',
    timezone: 'Asia/Brunei',
    unlocs: ['BNBWN'],
    code: '56100',
    id: 'BNBWN',
  },
  {
    name: 'Kuala Belait',
    city: 'Kuala Belait',
    country: 'Brunei Darussalam',
    alias: [],
    regions: [],
    coordinates: [114.2212174, 4.5831986],
    province: 'Belait',
    timezone: 'Asia/Brunei',
    unlocs: ['BNKUB'],
    code: '56100',
    id: 'BNKUB',
  },
  {
    name: 'Muara',
    city: 'Muara',
    country: 'Brunei Darussalam',
    alias: [],
    regions: [],
    coordinates: [115.0732883, 5.0333206],
    province: 'Brunei-Muara',
    timezone: 'Asia/Brunei',
    unlocs: ['BNMUA'],
    code: '56151',
    id: 'BNMUA',
  },
  {
    timezone: 'America/Campo_Grande',
    coordinates: [-57.96666699999999, -20.033333],
    name: 'Puerto Busch',
    city: 'Puerto Busch',
    province: 'Santa Cruz',
    country: 'Bolivia, Plurinational State of',
    alias: [],
    regions: [],
    unlocs: ['BOPBU'],
    code: '23299',
    id: 'BOPBU',
  },
  {
    name: 'Santa Cruz',
    coordinates: [-63.18, -17.78],
    city: 'Santa Cruz',
    province: 'La Paz',
    country: 'Bolivia, Plurinational State of',
    alias: [],
    regions: [],
    timezone: 'America/La_Paz',
    unlocs: ['BOSRZ'],
    code: '23299',
    id: 'BOSRZ',
  },
  {
    name: 'Aratu',
    city: 'Aratu',
    province: 'Bahia',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-38.4560755, -12.7855854],
    timezone: 'America/Fortaleza',
    unlocs: ['BRARB'],
    code: '35115',
    id: 'BRARB',
  },
  {
    name: 'Areia Branca',
    coordinates: [-37.17, -4.98],
    city: 'Areia Branca',
    province: 'Rio Grande do Norte',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Fortaleza',
    unlocs: ['BRARE'],
    code: '35198',
    id: 'BRARE',
  },
  {
    name: 'Belem',
    coordinates: [-48.5, -1.45],
    city: 'Belem',
    province: 'Pará',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Boa_Vista',
    unlocs: ['BRBEL'],
    code: '35121',
    id: 'BRBEL',
  },
  {
    name: 'Cabedelo',
    city: 'Cabedelo',
    province: 'Paraíba',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-34.8287221, -6.987721499999999],
    timezone: 'America/Fortaleza',
    unlocs: ['BRCDO'],
    code: '35127',
    id: 'BRCDO',
  },
  {
    name: 'Forno',
    city: 'Forno',
    province: 'Rio de Janeiro',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-41.8807698, -22.7583647],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRFNO'],
    code: '35199',
    id: 'BRFNO',
  },
  {
    name: 'Fortaleza',
    city: 'Fortaleza',
    province: 'Ceará',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-38.5266704, -3.7318616],
    timezone: 'America/Fortaleza',
    unlocs: ['BRFOR'],
    code: '35133',
    id: 'BRFOR',
  },
  {
    name: 'Imbituba',
    city: 'Imbituba',
    province: 'Santa Catarina',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-48.6691286, -28.227627],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRIBB'],
    code: '35178',
    id: 'BRIBB',
  },
  {
    name: 'Itaguai',
    coordinates: [-43.77, -22.87],
    city: 'Itaguaí',
    province: 'Rio de Janeiro',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRIGI'],
    code: '35185',
    id: 'BRIGI',
  },
  {
    name: 'Itapoa',
    coordinates: [-48.6, -26.12],
    city: 'Itapoá',
    province: 'Santa Catarina',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRIOA'],
    code: '35199',
    id: 'BRIOA',
  },
  {
    name: 'Ilheus',
    city: 'Ilheus',
    province: 'Bahia',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-39.0463797, -14.7935051],
    timezone: 'America/Fortaleza',
    unlocs: ['BRIOS'],
    code: '35137',
    id: 'BRIOS',
  },
  {
    timezone: 'America/Sao_Paulo',
    coordinates: [-43.8030567, -20.2530617],
    name: 'Itabirito',
    city: 'Itabirito',
    province: 'Minas Gerais',
    country: 'Brazil',
    alias: [],
    regions: [],
    unlocs: ['BRIRO'],
    code: '35199',
    id: 'BRIRO',
  },
  {
    name: 'Itajai',
    city: 'Itajai',
    province: 'Santa Catarina',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-48.6709514, -26.9105964],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRITJ'],
    code: '35138',
    id: 'BRITJ',
  },
  {
    name: 'Itaqui',
    city: 'Itaqui',
    province: 'Rio Grande do Sul',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-56.5481122, -29.1294007],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRITQ'],
    code: '35135',
    id: 'BRITQ',
  },
  {
    timezone: 'America/Sao_Paulo',
    coordinates: [-40.8310577, -21.0100062],
    name: 'Itapemirim',
    city: 'Itapemirim',
    province: 'Espírito Santo',
    country: 'Brazil',
    alias: [],
    regions: [],
    unlocs: ['BRITZ'],
    code: '35199',
    id: 'BRITZ',
  },
  {
    name: 'Manaus',
    city: 'Manaus',
    province: 'Amazonas',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-60.0217314, -3.1190275],
    timezone: 'Europe/Stockholm',
    unlocs: ['BRMAO'],
    code: '35144',
    id: 'BRMAO',
  },
  {
    name: 'Maceio',
    coordinates: [-35.72, -9.67],
    city: 'Maceió',
    province: 'Alagoas',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Fortaleza',
    unlocs: ['BRMCZ'],
    code: '35141',
    id: 'BRMCZ',
  },
  {
    name: 'Natal',
    city: 'Natal',
    province: 'Rio Grande do Norte',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-35.200916, -5.7792569],
    timezone: 'America/Fortaleza',
    unlocs: ['BRNAT'],
    code: '35157',
    id: 'BRNAT',
  },
  {
    name: 'Navegantes',
    coordinates: [-48.65, -26.9],
    city: 'Navegantes',
    province: 'Santa Catarina',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRNVT'],
    code: '35136',
    id: 'BRNVT',
  },
  {
    name: 'Portocel',
    city: 'Portocel',
    province: 'Espírito Santo',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-40.3088626, -19.1834229],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRPCL'],
    code: '35199',
    id: 'BRPCL',
  },
  {
    name: 'Pecem',
    coordinates: [-38.87, -3.52],
    city: 'Pecém',
    province: 'Ceará',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Fortaleza',
    unlocs: ['BRPEC'],
    code: '35120',
    id: 'BRPEC',
  },
  {
    name: 'Paranagua',
    coordinates: [-48.5, -25.52],
    city: 'Paranaguá',
    province: 'Paraná',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRPNG'],
    code: '35159',
    id: 'BRPNG',
  },
  {
    timezone: 'America/Fortaleza',
    coordinates: [-47.8759329, -12.6162809],
    name: 'Paranã',
    city: 'Paranã',
    province: 'Tocantins',
    country: 'Brazil',
    alias: [],
    regions: [],
    unlocs: ['BRPNN'],
    code: '35199',
    id: 'BRPNN',
  },
  {
    name: 'Porto Alegre',
    city: 'Pôrto Alegre',
    province: 'Rio Grande do Sul',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-51.2176584, -30.0346471],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRPOA'],
    code: '35167',
    id: 'BRPOA',
  },
  {
    name: 'Recife',
    city: 'Recife',
    province: 'Pernambuco',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-34.8828969, -8.0578381],
    timezone: 'America/Fortaleza',
    unlocs: ['BRREC'],
    code: '35169',
    id: 'BRREC',
  },
  {
    name: 'Rio Grande',
    city: 'Rio Grande',
    province: 'Rio Grande do Sul',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-52.1075802, -32.0353776],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRRIG'],
    code: '35173',
    id: 'BRRIG',
  },
  {
    name: 'Rio de Janeiro',
    city: 'Rio de Janeiro',
    province: 'Rio de Janeiro',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-43.1728965, -22.9068467],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRRIO'],
    code: '35171',
    id: 'BRRIO',
  },
  {
    timezone: 'America/Sao_Paulo',
    coordinates: [-49.9836057, -22.8898683],
    name: 'Salto Grande',
    city: 'Salto Grande',
    province: 'São Paulo',
    country: 'Brazil',
    alias: [],
    regions: [],
    unlocs: ['BRSAL'],
    code: '35199',
    id: 'BRSAL',
  },
  {
    name: 'Sao Francisco do Sul',
    city: 'São Francisco do Sul',
    province: 'Santa Catarina',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-48.6348454, -26.2584141],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRSFS'],
    code: '35179',
    id: 'BRSFS',
  },
  {
    name: 'Porto de Itaguai',
    coordinates: [-43.82, -22.92],
    city: 'Porto de Itaguaí',
    province: 'Rio de Janeiro',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRSPB'],
    code: '35185',
    id: 'BRSPB',
  },
  {
    name: 'Salvador',
    city: 'Salvador',
    province: 'Bahia',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-38.502304, -12.9730401],
    timezone: 'America/Fortaleza',
    unlocs: ['BRSSA'],
    code: '35181',
    id: 'BRSSA',
  },
  {
    name: 'Sao Sebastiao',
    city: 'São Sebastião',
    province: 'São Paulo',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-45.4016534, -23.8063468],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRSSO'],
    code: '35175',
    id: 'BRSSO',
  },
  {
    name: 'Santos',
    coordinates: [-46.33, -23.95],
    city: 'Santos',
    province: 'São Paulo',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRSSZ'],
    code: '35177',
    id: 'BRSSZ',
  },
  {
    name: 'Santarem',
    city: 'Santarem',
    province: 'Pará',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-54.7009228, -2.4506291],
    timezone: 'America/Boa_Vista',
    unlocs: ['BRSTM'],
    code: '35198',
    id: 'BRSTM',
  },
  {
    name: 'Suape',
    city: 'Suape',
    province: 'Pernambuco',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-34.9575528, -8.3536618],
    timezone: 'America/Fortaleza',
    unlocs: ['BRSUA'],
    code: '35170',
    id: 'BRSUA',
  },
  {
    name: 'Vitoria da Conquista',
    coordinates: [-40.85, -14.85],
    city: 'Vitória da Conquista',
    province: 'Bahia',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Fortaleza',
    unlocs: ['BRVDC'],
    code: '35195',
    id: 'BRVDC',
  },
  {
    name: 'Vila do Conde',
    coordinates: [-34.9, -7.27],
    city: 'Vila do Conde',
    province: 'Paraíba',
    country: 'Brazil',
    alias: [],
    regions: [],
    timezone: 'America/Fortaleza',
    unlocs: ['BRVIC'],
    code: '35121',
    id: 'BRVIC',
  },
  {
    name: 'Vitoria',
    city: 'Vitoria',
    province: 'Espírito Santo',
    country: 'Brazil',
    alias: [],
    regions: [],
    coordinates: [-40.2957768, -20.2976178],
    timezone: 'America/Sao_Paulo',
    unlocs: ['BRVIX'],
    code: '35195',
    id: 'BRVIX',
  },
  {
    name: 'Vila do Conde',
    coordinates: [-48.75, -1.53],
    city: 'Vila do Conde',
    province: 'Pará',
    country: 'Brazil',
    alias: [],
    regions: [],
    unlocs: ['BRVLC'],
    code: '35121',
    id: 'BRVLC',
  },
  {
    name: 'Freeport, Grand Bahama',
    city: 'Freeport, Grand Bahama',
    country: 'Bahamas',
    alias: [],
    regions: [],
    coordinates: [-78.64554749999999, 26.5416093],
    province: 'Freeport',
    timezone: 'America/Nassau',
    unlocs: ['BSFPO'],
    code: '23645',
    id: 'BSFPO',
  },
  {
    name: 'Nassau',
    coordinates: [-77.35, 25.08],
    city: 'Nassau',
    country: 'Bahamas',
    alias: [],
    regions: [],
    province: 'New Providence',
    timezone: 'America/Nassau',
    unlocs: ['BSNAS'],
    code: '23661',
    id: 'BSNAS',
  },
  {
    name: 'Minsk',
    city: 'Minsk',
    country: 'Belarus',
    alias: [],
    regions: [],
    coordinates: [27.5615244, 53.90453979999999],
    timezone: 'Europe/Minsk',
    unlocs: ['BYMSQ'],
    id: 'BYMSQ',
  },
  {
    name: 'Belize City',
    city: 'Belize City',
    country: 'Belize',
    alias: [],
    regions: [],
    coordinates: [-88.1962133, 17.5045661],
    province: 'Belize',
    timezone: 'America/Belize',
    unlocs: ['BZBZE'],
    code: '20801',
    id: 'BZBZE',
  },
  {
    name: 'Bathurst',
    city: 'Bathurst',
    province: 'New Brunswick',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-65.6513358, 47.6183507],
    timezone: 'America/Halifax',
    unlocs: ['CABAT'],
    code: '13458',
    id: 'CABAT',
  },
  {
    name: 'Brockville',
    city: 'Brockville',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-75.68428580000001, 44.5895244],
    timezone: 'America/Toronto',
    unlocs: ['CABCK'],
    code: '1694',
    id: 'CABCK',
  },
  {
    name: "Baie d'Urfe",
    coordinates: [-73.92, 45.4],
    city: "Baie d'Urfé",
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/Toronto',
    unlocs: ['CABDU'],
    code: '13400',
    id: 'CABDU',
  },
  {
    name: 'Becancour',
    coordinates: [-72.43, 46.33],
    city: 'Bécancour',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/Toronto',
    unlocs: ['CABEC'],
    code: '13441',
    id: 'CABEC',
  },
  {
    name: 'Baie-Johan-Beetz',
    coordinates: [-62.83, 50.3],
    city: 'Baie-Johan-Beetz',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/Toronto',
    unlocs: ['CABJO'],
    code: '13400',
    id: 'CABJO',
  },
  {
    name: 'Burlington',
    city: 'Burlington',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-79.7990319, 43.3255196],
    timezone: 'America/Toronto',
    unlocs: ['CABRL'],
    code: '9000',
    id: 'CABRL',
  },
  {
    name: 'Calgary',
    coordinates: [-114.07, 51.03],
    city: 'Calgary',
    province: 'Alberta',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/Edmonton',
    unlocs: ['CACAL'],
    code: '12200',
    id: 'CACAL',
  },
  {
    name: 'Corner Brook',
    coordinates: [-57.93, 48.95],
    city: 'Corner Brook',
    province: 'Newfoundland and Labrador',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/St_Johns',
    unlocs: ['CACBK'],
    code: '15231',
    id: 'CACBK',
  },
  {
    name: 'Cowichan Bay',
    city: 'Cowichan Bay',
    province: 'British Columbia',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-123.6215503, 48.7373094],
    timezone: 'America/Vancouver',
    unlocs: ['CACCB'],
    code: '12231',
    id: 'CACCB',
  },
  {
    name: 'Collingwood',
    city: 'Collingwood',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-80.2169047, 44.50076869999999],
    timezone: 'America/Toronto',
    unlocs: ['CACOL'],
    code: '9000',
    id: 'CACOL',
  },
  {
    name: 'Crofton',
    city: 'Crofton',
    province: 'British Columbia',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-123.6457977, 48.86384400000001],
    timezone: 'America/Vancouver',
    unlocs: ['CACRO'],
    code: '12234',
    id: 'CACRO',
  },
  {
    name: 'Cornwall',
    city: 'Cornwall',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-74.730345, 45.0212762],
    timezone: 'America/Toronto',
    unlocs: ['CACWL'],
    code: '1784',
    id: 'CACWL',
  },
  {
    name: 'Dorval',
    city: 'Dorval',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-73.75004849999999, 45.4503213],
    timezone: 'America/Toronto',
    unlocs: ['CADOR'],
    code: '13400',
    id: 'CADOR',
  },
  {
    name: 'Drummondville',
    city: 'Drummondville',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-72.4842824, 45.8802909],
    timezone: 'America/Toronto',
    unlocs: ['CADRU'],
    code: '13400',
    id: 'CADRU',
  },
  {
    name: 'Edmonton',
    city: 'Edmonton',
    province: 'Alberta',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-113.4909267, 53.544389],
    timezone: 'America/Edmonton',
    unlocs: ['CAEDM'],
    code: '12200',
    id: 'CAEDM',
  },
  {
    name: 'Halifax',
    city: 'Halifax',
    province: 'Nova Scotia',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-63.5753196, 44.6488625],
    timezone: 'America/Halifax',
    unlocs: ['CAHAL'],
    code: '13841',
    id: 'CAHAL',
  },
  {
    name: 'Hamilton',
    city: 'Hamilton',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-79.8660914, 43.25002080000001],
    timezone: 'America/Toronto',
    unlocs: ['CAHAM'],
    code: '23201',
    id: 'CAHAM',
  },
  {
    name: 'Harmac',
    coordinates: [-123.85, 49.13],
    city: 'Harmac',
    province: 'British Columbia',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/Vancouver',
    unlocs: ['CAHMC'],
    code: '12407',
    id: 'CAHMC',
  },
  {
    name: 'Kingsville',
    city: 'Kingsville',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-82.7389809, 42.0362841],
    timezone: 'America/Toronto',
    unlocs: ['CAKVL'],
    code: '3506',
    id: 'CAKVL',
  },
  {
    name: 'Magog',
    city: 'Magog',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-72.1479889, 45.2664863],
    timezone: 'America/Toronto',
    unlocs: ['CAMAG'],
    code: '13400',
    id: 'CAMAG',
  },
  {
    name: 'Moncton',
    city: 'Moncton',
    province: 'New Brunswick',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-64.7782313, 46.0878165],
    timezone: 'America/Halifax',
    unlocs: ['CAMNT'],
    code: '13400',
    id: 'CAMNT',
  },
  {
    name: 'Montreal',
    city: 'Montreal',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-73.567256, 45.5016889],
    timezone: 'America/Toronto',
    unlocs: ['CAMTR'],
    code: '1822',
    id: 'CAMTR',
  },
  {
    name: 'Niagara Falls',
    city: 'Niagara Falls',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-79.0849436, 43.0895577],
    timezone: 'America/Toronto',
    unlocs: ['CANIA'],
    code: '9000',
    id: 'CANIA',
  },
  {
    name: 'Nanaimo',
    city: 'Nanaimo',
    province: 'British Columbia',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-123.9400647, 49.1658836],
    timezone: 'America/Vancouver',
    unlocs: ['CANNO'],
    code: '12406',
    id: 'CANNO',
  },
  {
    name: 'North Bay',
    coordinates: [-79.45, 46.3],
    city: 'North Bay',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/Toronto',
    unlocs: ['CANOR'],
    code: '9000',
    id: 'CANOR',
  },
  {
    name: 'New Westminster',
    city: 'New Westminster',
    province: 'British Columbia',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-122.910956, 49.2057179],
    timezone: 'America/Vancouver',
    unlocs: ['CANWE'],
    code: '12200',
    id: 'CANWE',
  },
  {
    name: 'Oakville',
    city: 'Oakville',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-79.6876659, 43.467517],
    timezone: 'America/Toronto',
    unlocs: ['CAOAK'],
    code: '6770',
    id: 'CAOAK',
  },
  {
    timezone: 'America/Toronto',
    coordinates: [-76.71829249999999, 44.2770379],
    name: 'Odessa',
    city: 'Odessa',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    unlocs: ['CAODE'],
    code: '9000',
    id: 'CAODE',
  },
  {
    name: 'Oshawa',
    city: 'Oshawa',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-78.86579119999999, 43.8970929],
    timezone: 'America/Toronto',
    unlocs: ['CAOSH'],
    code: '9670',
    id: 'CAOSH',
  },
  {
    name: 'Port Credit',
    city: 'Port Credit',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-79.585628, 43.5516151],
    timezone: 'America/Toronto',
    unlocs: ['CAPCR'],
    code: '1530',
    id: 'CAPCR',
  },
  {
    name: 'Port Dover',
    city: 'Port Dover',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-80.20327790000002, 42.7865225],
    timezone: 'America/Toronto',
    unlocs: ['CAPDV'],
    code: '9000',
    id: 'CAPDV',
  },
  {
    name: 'Port Mellon',
    city: 'Port Mellon',
    province: 'British Columbia',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-123.4880588, 49.5217479],
    timezone: 'America/Vancouver',
    unlocs: ['CAPML'],
    code: '12428',
    id: 'CAPML',
  },
  {
    name: 'Pointe-Claire',
    coordinates: [-73.73, 45.43],
    city: 'Pointe-Claire',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/Toronto',
    unlocs: ['CAPOI'],
    code: '13400',
    id: 'CAPOI',
  },
  {
    name: 'Prince Rupert',
    city: 'Prince Rupert',
    province: 'British Columbia',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-130.3208187, 54.3150367],
    timezone: 'America/Vancouver',
    unlocs: ['CAPRR'],
    code: '12442',
    id: 'CAPRR',
  },
  {
    name: 'Quebec',
    city: 'Quebec',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-71.242796, 46.8032826],
    timezone: 'America/Toronto',
    unlocs: ['CAQUE'],
    code: '80109',
    id: 'CAQUE',
  },
  {
    name: 'Saskatoon',
    city: 'Saskatoon',
    province: 'Saskatchewan',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-106.6700458, 52.1332144],
    timezone: 'America/Regina',
    unlocs: ['CASAK'],
    code: '9000',
    id: 'CASAK',
  },
  {
    name: 'Saint-Catharines',
    coordinates: [-79.23, 43.17],
    city: 'Saint-Catharines',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/Toronto',
    unlocs: ['CASCA'],
    code: '1690',
    id: 'CASCA',
  },
  {
    name: 'Saint-John',
    coordinates: [-66.07, 45.27],
    city: 'Saint-John',
    province: 'New Brunswick',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/Halifax',
    unlocs: ['CASJB'],
    code: '14428',
    id: 'CASJB',
  },
  {
    name: "Saint-John's",
    coordinates: [-52.73, 47.57],
    city: "Saint-John's",
    province: 'Newfoundland and Labrador',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/St_Johns',
    unlocs: ['CASJF'],
    code: '15282',
    id: 'CASJF',
  },
  {
    name: 'Sarnia',
    city: 'Sarnia',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-82.4065901, 42.974536],
    timezone: 'America/Toronto',
    unlocs: ['CASNI'],
    code: '5520',
    id: 'CASNI',
  },
  {
    name: 'Sorel',
    city: 'Sorel',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-73.112302, 46.042825],
    timezone: 'America/Toronto',
    unlocs: ['CASOR'],
    code: '14466',
    id: 'CASOR',
  },
  {
    name: 'Sault-Sainte-Marie',
    city: 'Sault-Sainte-Marie',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-84.3460896, 46.52185799999999],
    timezone: 'America/Toronto',
    unlocs: ['CASSM'],
    code: '8525',
    id: 'CASSM',
  },
  {
    name: 'Stoney Creek',
    city: 'Stoney Creek',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-79.7195586, 43.2272182],
    timezone: 'America/Toronto',
    unlocs: ['CASTC'],
    code: '9000',
    id: 'CASTC',
  },
  {
    name: 'Summerside',
    city: 'Summerside',
    province: 'Prince Edward Island',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-63.79023309999999, 46.39337769999999],
    timezone: 'America/Halifax',
    unlocs: ['CASUM'],
    code: '14470',
    id: 'CASUM',
  },
  {
    name: 'Texada Island',
    city: 'Texada Island',
    province: 'British Columbia',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-124.4121947, 49.6596634],
    timezone: 'America/Vancouver',
    unlocs: ['CATEX'],
    code: '12206',
    id: 'CATEX',
  },
  {
    name: 'Thunder Bay',
    city: 'Thunder Bay',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-89.2476823, 48.3808951],
    timezone: 'America/Toronto',
    unlocs: ['CATHU'],
    code: '9662',
    id: 'CATHU',
  },
  {
    name: 'Toronto',
    city: 'Toronto',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-79.3831843, 43.653226],
    timezone: 'America/Toronto',
    unlocs: ['CATOR'],
    code: '1535',
    id: 'CATOR',
  },
  {
    name: 'Trois-Rivieres (Three Rivers)',
    city: 'Trois-Rivieres (Three Rivers)',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-72.5475259, 46.35924199999999],
    timezone: 'America/Toronto',
    unlocs: ['CATRR'],
    code: '14474',
    id: 'CATRR',
  },
  {
    name: 'Trenton',
    city: 'Trenton',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-77.576313, 44.1011263],
    timezone: 'America/Toronto',
    unlocs: ['CATTC'],
    code: '9000',
    id: 'CATTC',
  },
  {
    name: 'Vancouver',
    city: 'Vancouver',
    province: 'British Columbia',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-123.1207375, 49.2827291],
    timezone: 'America/Vancouver',
    unlocs: ['CAVAN'],
    code: '12493',
    id: 'CAVAN',
  },
  {
    name: 'Valleyfield',
    coordinates: [-74.13, 45.25],
    city: 'Valleyfield',
    province: 'Quebec',
    country: 'Canada',
    alias: [],
    regions: [],
    timezone: 'America/Toronto',
    unlocs: ['CAVLF'],
    code: '14067',
    id: 'CAVLF',
  },
  {
    name: 'Winona',
    city: 'Winona',
    province: 'Ontario',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-79.6509165, 43.2079596],
    timezone: 'America/Toronto',
    unlocs: ['CAWIN'],
    code: '9000',
    id: 'CAWIN',
  },
  {
    name: 'Winnipeg',
    city: 'Winnipeg',
    province: 'Manitoba',
    country: 'Canada',
    alias: [],
    regions: [],
    coordinates: [-97.1374937, 49.8997541],
    timezone: 'America/Winnipeg',
    unlocs: ['CAWNP'],
    code: '9000',
    id: 'CAWNP',
  },
  {
    name: 'Boma',
    city: 'Boma',
    country: 'Congo, The Democratic Republic of the',
    alias: [],
    regions: [],
    coordinates: [-5.8347218, 13.022875],
    timezone: 'Africa/Bamako',
    unlocs: ['CDBOA'],
    code: '76638',
    id: 'CDBOA',
  },
  {
    name: 'Matadi',
    city: 'Matadi',
    country: 'Congo, The Democratic Republic of the',
    alias: [],
    regions: [],
    coordinates: [-5.8237941, 13.4509856],
    timezone: 'Africa/Bamako',
    unlocs: ['CDMAT'],
    code: '76637',
    id: 'CDMAT',
  },
  {
    name: 'Pointe Noire',
    city: 'Pointe Noire',
    country: 'Congo',
    alias: [],
    regions: [],
    coordinates: [11.866362, -4.7691623],
    province: 'Kouilou',
    timezone: 'Africa/Brazzaville',
    unlocs: ['CGPNR'],
    code: '76600',
    id: 'CGPNR',
  },
  {
    name: 'Abidjan',
    city: 'Abidjan',
    country: "Côte d'Ivoire",
    alias: [],
    regions: [],
    coordinates: [-4.0082563, 5.3599517],
    province: 'Lagunes',
    timezone: 'Africa/Abidjan',
    unlocs: ['CIABJ'],
    code: '74825',
    id: 'CIABJ',
  },
  {
    name: 'San-Pedro',
    coordinates: [-6.62, 4.73],
    city: 'San-Pédro',
    province: 'Bas-Sassandra (Région du)',
    country: "Côte d'Ivoire",
    alias: [],
    regions: [],
    timezone: 'Africa/Abidjan',
    unlocs: ['CISPY'],
    id: 'CISPY',
  },
  {
    name: 'Antofagasta',
    coordinates: [-70.38, -23.63],
    city: 'Antofagasta',
    province: 'Antofagasta',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLANF'],
    code: '33703',
    id: 'CLANF',
  },
  {
    name: 'Arica',
    coordinates: [-70.32, -18.48],
    city: 'Arica',
    province: 'Arica y Parinacota',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLARI'],
    code: '33706',
    id: 'CLARI',
  },
  {
    name: 'Bahia San Gregorio',
    coordinates: [-70.13, -52.62],
    city: 'Bahía San Gregorio',
    province: 'Magallanes',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLBAG'],
    code: '33700',
    id: 'CLBAG',
  },
  {
    name: 'Barquito',
    coordinates: [-70.63, -26.35],
    city: 'Barquito',
    province: 'Atacama',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLBAR'],
    code: '33700',
    id: 'CLBAR',
  },
  {
    name: 'San Vicente',
    city: 'San Vicente',
    province: 'CAP',
    country: 'Chile',
    alias: [],
    regions: [],
    coordinates: [-4.397057999999999, 43.3813069],
    timezone: 'Europe/Madrid',
    unlocs: ['CLCAP'],
    code: '33780',
    id: 'CLCAP',
  },
  {
    name: 'Calbuco',
    coordinates: [-73.13, -41.75],
    city: 'Calbuco',
    province: 'Los Lagos',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLCBC'],
    code: '33700',
    id: 'CLCBC',
  },
  {
    name: 'Caleta Coloso',
    city: 'Caleta Coloso',
    country: 'Chile',
    alias: [],
    regions: [],
    coordinates: [-70.29371789999999, -18.4350352],
    province: 'Región de Arica y Parinacota',
    timezone: 'America/Santiago',
    unlocs: ['CLCCL'],
    code: '33700',
    id: 'CLCCL',
  },
  {
    name: 'Chacabuco',
    coordinates: [-72.82, -45.48],
    city: 'Chacabuco',
    province: 'Aysén',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLCHB'],
    code: '33700',
    id: 'CLCHB',
  },
  {
    name: 'Caldera',
    coordinates: [-70.8, -27.03],
    city: 'Caldera',
    province: 'Atacama',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLCLD'],
    code: '33708',
    id: 'CLCLD',
  },
  {
    name: 'Coronel',
    coordinates: [-73.15, -37.02],
    city: 'Coronel',
    province: 'Biobío',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLCNL'],
    code: '33700',
    id: 'CLCNL',
  },
  {
    name: 'Chanaral',
    coordinates: [-70.62, -26.35],
    city: 'Chañaral',
    province: 'Atacama',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLCNR'],
    code: '33716',
    id: 'CLCNR',
  },
  {
    name: 'Cabo Negro',
    coordinates: [-70.85, -52.97],
    city: 'Cabo Negro',
    province: 'Magallanes',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLCNX'],
    code: '33700',
    id: 'CLCNX',
  },
  {
    name: 'Coquimbo',
    coordinates: [-71.33, -29.97],
    city: 'Coquimbo',
    province: 'Coquimbo',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLCQQ'],
    code: '33721',
    id: 'CLCQQ',
  },
  {
    name: 'Corral',
    coordinates: [-73.43, -39.87],
    city: 'Corral',
    province: 'Los Lagos',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLCRR'],
    code: '33700',
    id: 'CLCRR',
  },
  {
    name: 'Dalcahue',
    coordinates: [-73.67, -42.38],
    city: 'Dalcahue',
    province: 'Los Lagos',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLDCH'],
    code: '33700',
    id: 'CLDCH',
  },
  {
    name: 'Guayacan',
    coordinates: [-71.37, -29.98],
    city: 'Guayacán',
    province: 'Coquimbo',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLGYC'],
    code: '33735',
    id: 'CLGYC',
  },
  {
    name: 'Huasco',
    coordinates: [-71.22, -28.45],
    city: 'Huasco',
    province: 'Atacama',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLHSO'],
    code: '33739',
    id: 'CLHSO',
  },
  {
    name: 'Isla de Pascua',
    coordinates: [-109.42, -27.15],
    city: 'Isla de Pascua',
    province: 'Valparaíso',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'Pacific/Easter',
    unlocs: ['CLIPC'],
    code: '33700',
    id: 'CLIPC',
  },
  {
    name: 'Iquique',
    coordinates: [-70.13, -20.22],
    city: 'Iquique',
    province: 'Tarapacá',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLIQQ'],
    code: '33741',
    id: 'CLIQQ',
  },
  {
    name: 'Jureles',
    city: 'Jureles',
    province: 'Coronel Bay',
    country: 'Chile',
    alias: [],
    regions: [],
    coordinates: [-36.9721439, -73.2265222],
    unlocs: ['CLJRL'],
    code: '33700',
    id: 'CLJRL',
  },
  {
    name: 'Los Vilos',
    coordinates: [-71.5, -31.9],
    city: 'Los Vilos',
    province: 'Coquimbo',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLLOS'],
    code: '33700',
    id: 'CLLOS',
  },
  {
    name: 'Lirquen',
    coordinates: [-72.98, -36.7],
    city: 'Lirquén',
    province: 'Biobío',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLLQN'],
    code: '33751',
    id: 'CLLQN',
  },
  {
    name: 'Las Ventanas',
    coordinates: [-71.65, -33.27],
    city: 'Las Ventanas',
    province: 'Valparaíso',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLLVS'],
    code: '33700',
    id: 'CLLVS',
  },
  {
    name: 'Michilla',
    city: 'Michilla',
    country: 'Chile',
    alias: [],
    regions: [],
    coordinates: [-70.27768979999999, -22.7183138],
    province: 'Región de Antofagasta',
    timezone: 'America/Santiago',
    unlocs: ['CLMCH'],
    code: '33700',
    id: 'CLMCH',
  },
  {
    name: 'Mejillones',
    coordinates: [-70.45, -23.1],
    city: 'Mejillones',
    province: 'Antofagasta',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLMJS'],
    code: '33700',
    id: 'CLMJS',
  },
  {
    name: 'Puerto Angamos',
    coordinates: [-70.45, -23.08],
    city: 'Puerto Angamos',
    province: 'Antofagasta',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLPAG'],
    code: '33700',
    id: 'CLPAG',
  },
  {
    name: 'Puerto Chacabuco',
    coordinates: [-72.83, -45.48],
    city: 'Puerto Chacabuco',
    province: 'Aysén',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLPCH'],
    code: '33700',
    id: 'CLPCH',
  },
  {
    name: 'Penco',
    coordinates: [-72.98, -36.72],
    city: 'Penco',
    province: 'Biobío',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLPEO'],
    code: '33782',
    id: 'CLPEO',
  },
  {
    name: 'Punta Chungo',
    city: 'Punta Chungo',
    country: 'Chile',
    alias: [],
    regions: [],
    coordinates: [-31.885551, -71.5038607],
    unlocs: ['CLPGO'],
    code: '33700',
    id: 'CLPGO',
  },
  {
    name: 'Puerto Montt',
    coordinates: [-72.95, -41.47],
    city: 'Puerto Montt',
    province: 'Los Lagos',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLPMC'],
    code: '33700',
    id: 'CLPMC',
  },
  {
    name: 'Puerto Natales',
    coordinates: [-72.5, -51.72],
    city: 'Puerto Natales',
    province: 'Magallanes',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLPNT'],
    code: '33700',
    id: 'CLPNT',
  },
  {
    name: 'Punta Patache',
    coordinates: [-70.22, -20.82],
    city: 'Punta Patache',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLPPC'],
    code: '33700',
    id: 'CLPPC',
  },
  {
    name: 'Punta Padrones',
    city: 'Punta Padrones',
    country: 'Chile',
    alias: [],
    regions: [],
    coordinates: [-27.0522946, -70.841904],
    unlocs: ['CLPPD'],
    code: '33700',
    id: 'CLPPD',
  },
  {
    name: 'Quellon (Puerto Quellon)',
    coordinates: [-73.62, -43.1],
    city: 'Quellón (Puerto Quellón)',
    province: 'Los Lagos',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLPTE'],
    code: '33700',
    id: 'CLPTE',
  },
  {
    name: 'Patillos',
    coordinates: [-70.18, -20.75],
    city: 'Patillos',
    province: 'Tarapacá',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLPTI'],
    code: '33765',
    id: 'CLPTI',
  },
  {
    name: 'Punta Arenas',
    coordinates: [-70.93, -53.13],
    city: 'Punta Arenas',
    province: 'Magallanes',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLPUQ'],
    code: '33770',
    id: 'CLPUQ',
  },
  {
    name: 'Quemchi',
    coordinates: [-73.48, -42.13],
    city: 'Quemchi',
    province: 'Los Lagos',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLQMC'],
    code: '33700',
    id: 'CLQMC',
  },
  {
    name: 'Quintero',
    coordinates: [-71.53, -32.77],
    city: 'Quintero',
    province: 'Valparaíso',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLQTV'],
    code: '33775',
    id: 'CLQTV',
  },
  {
    name: 'San Antonio',
    coordinates: [-71.6, -33.6],
    city: 'San Antonio',
    province: 'Valparaíso',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLSAI'],
    code: '33779',
    id: 'CLSAI',
  },
  {
    name: 'San Vicente',
    coordinates: [-73.13, -36.7],
    city: 'San Vicente',
    province: 'Biobío',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLSVE'],
    code: '33780',
    id: 'CLSVE',
  },
  {
    name: 'Talcahuano',
    coordinates: [-73.12, -36.73],
    city: 'Talcahuano',
    province: 'Biobío',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLTAL'],
    code: '33781',
    id: 'CLTAL',
  },
  {
    name: 'Talcahuano',
    city: 'Talcahuano',
    country: 'Chile',
    alias: [],
    regions: [],
    coordinates: [-73.1169808, -36.7247834],
    province: 'Bío Bío Region',
    timezone: 'America/Santiago',
    unlocs: ['CLTHO'],
    code: '33781',
    id: 'CLTHO',
  },
  {
    name: 'Tome',
    coordinates: [-72.95, -36.6],
    city: 'Tomé',
    province: 'Biobío',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLTOM'],
    code: '33700',
    id: 'CLTOM',
  },
  {
    name: 'Tocopilla',
    coordinates: [-70.18, -22.08],
    city: 'Tocopilla',
    province: 'Antofagasta',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLTOQ'],
    code: '33788',
    id: 'CLTOQ',
  },
  {
    name: 'Taltal',
    coordinates: [-70.47, -25.4],
    city: 'Taltal',
    province: 'Antofagasta',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLTTC'],
    code: '33700',
    id: 'CLTTC',
  },
  {
    name: 'Valparaiso',
    coordinates: [-71.63, -33.03],
    city: 'Valparaiso',
    province: 'Valparaíso',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLVAP'],
    code: '33797',
    id: 'CLVAP',
  },
  {
    name: 'Castro',
    coordinates: [-73.77, -42.47],
    city: 'Castro',
    province: 'Los Lagos',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLWCA'],
    code: '33700',
    id: 'CLWCA',
  },
  {
    name: 'Chaiten',
    coordinates: [-72.7, -42.92],
    city: 'Chaitén',
    province: 'Los Lagos',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLWCH'],
    code: '33700',
    id: 'CLWCH',
  },
  {
    name: 'Puerto Aisen',
    coordinates: [-72.7, -45.38],
    city: 'Puerto Aisén',
    province: 'Aysén',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLWPA'],
    code: '33700',
    id: 'CLWPA',
  },
  {
    name: 'Puerto Williams',
    coordinates: [-67.62, -54.92],
    city: 'Puerto Williams',
    province: 'Magallanes',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLWPU'],
    code: '33700',
    id: 'CLWPU',
  },
  {
    name: 'Ancud',
    coordinates: [-73.83, -41.85],
    city: 'Ancud',
    province: 'Los Lagos',
    country: 'Chile',
    alias: [],
    regions: [],
    timezone: 'America/Santiago',
    unlocs: ['CLZUD'],
    code: '33700',
    id: 'CLZUD',
  },
  {
    name: 'Douala',
    city: 'Douala',
    country: 'Cameroon',
    alias: [],
    regions: [],
    coordinates: [9.7678687, 4.0510564],
    province: 'Littoral',
    timezone: 'Africa/Douala',
    unlocs: ['CMDLA'],
    code: '74201',
    id: 'CMDLA',
  },
  {
    name: 'Bayuquan',
    city: 'Bayuquan',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [122.107814, 40.230636],
    province: 'Liaoning',
    timezone: 'Asia/Shanghai',
    unlocs: ['CNBAY'],
    code: '57000',
    id: 'CNBAY',
  },
  {
    name: 'Beihai Fucheng Apt',
    city: 'Beihai Fucheng Apt',
    province: 'Guangxi',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [108.327546, 22.815478],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNBHY'],
    code: '57076',
    id: 'CNBHY',
  },
  {
    name: 'Beijiao',
    coordinates: [119.92, 26.35],
    city: 'Beijiao',
    province: 'Fujian',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNBJO'],
    code: '57016',
    id: 'CNBJO',
  },
  {
    name: 'Guangzhou',
    city: 'Guangzhou',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.264385, 23.12911],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNCAN'],
    code: '57071',
    id: 'CNCAN',
  },
  {
    name: 'Changshu',
    city: 'Changshu',
    province: 'Jiangsu',
    country: 'China',
    alias: ['Zhangjiagang', 'Suzhou', 'Taicang'],
    regions: [],
    coordinates: [120.752503, 31.653686],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNCGU'],
    code: '57076',
    id: 'CNCGU',
  },
  {
    name: 'Chongqing',
    city: 'Chongqing',
    province: 'Chongqing',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [106.551557, 29.56301],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNCKG'],
    code: '57000',
    id: 'CNCKG',
  },
  {
    name: 'Chaozhou Port',
    coordinates: [116.63, 23.67],
    city: 'Chaozhou',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNCOZ'],
    code: '57000',
    id: 'CNCOZ',
  },
  {
    name: 'Changsha',
    city: 'Changsha',
    province: 'Hunan',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [112.938814, 28.228209],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNCSX'],
    code: '57076',
    id: 'CNCSX',
  },
  {
    name: 'Chengdu',
    city: 'Chengdu',
    province: 'Sichuan',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [104.066541, 30.572269],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNCTU'],
    code: '57000',
    id: 'CNCTU',
  },
  {
    name: 'Chiwan Port',
    coordinates: [113.88, 22.48],
    city: 'Chiwan',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNCWN'],
    code: '57067',
    id: 'CNCWN',
  },
  {
    name: 'Dagang',
    city: 'Dagang',
    province: 'Jiangsu',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [120.103473, 33.189192],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNDAA'],
    code: '57000',
    id: 'CNDAA',
  },
  {
    name: 'Dalian',
    city: 'Dalian',
    province: 'Liaoning',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.614682, 38.91400300000001],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNDLC', 'CNDAL'],
    code: '57051',
    id: 'CNDAL',
  },
  {
    city: 'Da Chan Bay',
    country: 'China',
    province: 'Shenzhen',
    name: 'Da Chan Bay',
    alias: [],
    regions: [],
    unlocs: ['CNDCB'],
    code: '57045',
    id: 'CNDCB',
  },
  {
    name: 'Dongguan Port',
    city: 'Dongguan',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.751765, 23.020536],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNDGG'],
    code: '57056',
    id: 'CNDGG',
  },
  {
    name: 'Dalian',
    city: 'Dalian',
    province: 'Liaoning',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.614682, 38.91400300000001],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNDLC', 'CNDAL'],
    code: '57051',
    id: 'CNDLC',
  },
  {
    name: 'Doumen Port',
    coordinates: [113.28, 22.2],
    city: 'Doumen',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNDOU'],
    code: '57000',
    id: 'CNDOU',
  },
  {
    name: 'Fangcheng Port',
    coordinates: [108.35, 21.77],
    city: 'Fangcheng',
    province: 'Guangxi',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNFAN'],
    code: '57044',
    id: 'CNFAN',
  },
  {
    name: 'Fuyong',
    coordinates: [113.78, 22.65],
    city: 'Fuyong',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNFNG'],
    code: '57000',
    id: 'CNFNG',
  },
  {
    code: '57015',
    country: 'China',
    province: 'Fujian',
    city: 'Fuzhou',
    name: 'Fuzhou',
    alias: ['Fuchou', 'Foochow', 'Foochou'],
    regions: [],
    unlocs: ['CNFOC'],
    id: 'CNFOC',
  },
  {
    name: 'Foshan',
    coordinates: [113.1, 23.03],
    city: 'Foshanzhan',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNFOS'],
    code: '57000',
    id: 'CNFOS',
  },
  {
    name: 'Fuqing',
    city: 'Fuqing',
    province: 'Fujian',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [119.383811, 25.720381],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNFUG'],
    code: '57011',
    id: 'CNFUG',
  },
  {
    name: 'Gongyi',
    city: 'Gongyi',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.218364, 23.4033077],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNGGY'],
    code: '57000',
    id: 'CNGGY',
  },
  {
    name: 'Gongming',
    coordinates: [113.87, 22.78],
    city: 'Gongming',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNGOG'],
    code: '57000',
    id: 'CNGOG',
  },
  {
    name: 'Gaoming Port',
    coordinates: [112.83, 22.88],
    city: 'Gaoming',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNGOM'],
    code: '57000',
    id: 'CNGOM',
  },
  {
    name: 'Guigang',
    coordinates: [109.6, 23.1],
    city: 'Guigang',
    province: 'Guangxi',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNGUG'],
    code: '57000',
    id: 'CNGUG',
  },
  {
    name: 'Haikou',
    city: 'Haikou',
    province: 'Hainan',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [110.198293, 20.044002],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNHAK'],
    code: '57012',
    id: 'CNHAK',
  },
  {
    name: 'Huadu',
    coordinates: [113.22, 23.4],
    city: 'Huadu',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNHDU'],
    code: '57000',
    id: 'CNHDU',
  },
  {
    name: 'Hebei',
    coordinates: [115.27, 39.88],
    city: 'Hebei',
    province: 'Hebei',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNHEB'],
    code: '57000',
    id: 'CNHEB',
  },
  {
    name: 'Hangzhou',
    city: 'Hangzhou',
    province: 'Zhejiang',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [120.15507, 30.274085],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNHGH'],
    code: '57000',
    id: 'CNHGH',
  },
  {
    name: 'Haimen',
    city: 'Haimen',
    province: 'Zhejiang',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.4530792, 32.1041568],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNHME'],
    code: '57000',
    id: 'CNHME',
  },
  {
    name: 'Humen Port',
    coordinates: [113.67, 22.83],
    city: 'Humen',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNHMN'],
    code: '57000',
    id: 'CNHMN',
  },
  {
    name: 'Heshan Port',
    coordinates: [112.95, 22.77],
    city: 'Heshan',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNHSN'],
    code: '57000',
    id: 'CNHSN',
  },
  {
    name: 'Huangpu Port',
    city: 'Huangpu',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.459714, 23.106899],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNHUA'],
    code: '57072',
    id: 'CNHUA',
  },
  {
    name: 'Huludao Port',
    coordinates: [120.85, 40.75],
    city: 'Huludao',
    province: 'Liaoning',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNHUD'],
    code: '57000',
    id: 'CNHUD',
  },
  {
    name: 'Huizhou Port',
    coordinates: [114.37, 23.08],
    city: 'Huizhou',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNHUI'],
    code: '57022',
    id: 'CNHUI',
  },
  {
    name: 'Jingzhou',
    coordinates: [112.18, 30.35],
    city: 'Jingzhou',
    province: 'Hubei',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNJGZ'],
    code: '57000',
    id: 'CNJGZ',
  },
  {
    name: 'Jiangyin Port',
    coordinates: [120.28, 31.9],
    city: 'Jiangyin',
    province: 'Jiangsu',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNJIA'],
    code: '57080',
    id: 'CNJIA',
  },
  {
    name: 'Jian',
    coordinates: [114.97, 27.12],
    city: 'Jian',
    province: 'Jiangxi',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNJIN'],
    code: '57000',
    id: 'CNJIN',
  },
  {
    name: 'Jiujiang Port',
    coordinates: [115.97, 29.72],
    city: 'Jiujiang',
    province: 'Jiangxi',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNJIU'],
    code: '57000',
    id: 'CNJIU',
  },
  {
    name: 'Jiangmen Port',
    coordinates: [113.07, 22.58],
    city: 'Jiangmen',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNJMN'],
    code: '57000',
    id: 'CNJMN',
  },
  {
    name: 'Jinzhou Port',
    coordinates: [121.15, 41.12],
    city: 'Jinzhou',
    province: 'Liaoning',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNJNZ'],
    code: '57000',
    id: 'CNJNZ',
  },
  {
    name: 'Jiao Xin',
    city: 'Jiao Xin',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.3136949, 22.7024799],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNJXN'],
    code: '57000',
    id: 'CNJXN',
  },
  {
    name: 'Jiuzhou Port',
    city: 'Jiuzhou',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [116.122239, 24.288615],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNJZU'],
    code: '57077',
    id: 'CNJZU',
  },
  {
    name: 'Nanchang',
    city: 'Nanchang',
    province: 'Jiangxi',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [115.858198, 28.682892],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNKHN'],
    code: '57000',
    id: 'CNKHN',
  },
  {
    name: 'Kunming',
    city: 'Kunming',
    province: 'Yunnan',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [102.832892, 24.880095],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNKMG'],
    code: '57000',
    id: 'CNKMG',
  },
  {
    name: 'Kaiping',
    coordinates: [118.3, 39.7],
    city: 'Kaiping',
    province: 'Hebei',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNKPN'],
    code: '57000',
    id: 'CNKPN',
  },
  {
    name: 'Lanshi Port',
    city: 'Lanshi',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.981592, 22.9701602],
    province: 'Guangdong Sheng',
    timezone: 'Asia/Shanghai',
    unlocs: ['CNLAN'],
    code: '57000',
    id: 'CNLAN',
  },
  {
    name: 'Longhua',
    city: 'Longhua',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [114.020921, 22.63964],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNLGH'],
    code: '57000',
    id: 'CNLGH',
  },
  {
    name: 'Lianhuashan Port',
    coordinates: [115.27, 23.02],
    city: 'Lianhuashan',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNLIH'],
    code: '57000',
    id: 'CNLIH',
  },
  {
    name: 'Longkou Port',
    coordinates: [120.35, 37.65],
    city: 'Longkou',
    province: 'Shandong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNLKU'],
    code: '57000',
    id: 'CNLKU',
  },
  {
    name: 'Lanshi',
    coordinates: [113.6, 26.15],
    city: 'Lanshi',
    province: 'Hunan',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNLSI'],
    code: '57000',
    id: 'CNLSI',
  },
  {
    name: 'Lianyungang',
    coordinates: [119.43, 34.72],
    city: 'Lianyungang',
    province: 'Jiangsu',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNLYG'],
    code: '57025',
    id: 'CNLYG',
  },
  {
    name: 'Liuzhou Port',
    coordinates: [109.4, 23.32],
    city: 'Liuzhou',
    province: 'Guangxi',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNLZH'],
    code: '57000',
    id: 'CNLZH',
  },
  {
    name: 'Mawei Port',
    coordinates: [119.45, 25.98],
    city: 'Mawei',
    province: 'Fujian',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNMAW'],
    code: '57000',
    id: 'CNMAW',
  },
  {
    name: 'Maoming',
    city: 'Maoming',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [110.925456, 21.662999],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNMMI'],
    code: '57000',
    id: 'CNMMI',
  },
  {
    name: 'Mawan Port',
    coordinates: [113.88, 22.45],
    city: 'Mawan',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNMWN'],
    code: '57000',
    id: 'CNMWN',
  },
  {
    name: 'Mianyang',
    coordinates: [104.7, 31.5],
    city: 'Mianyang',
    province: 'Sichuan',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNMYG'],
    code: '57000',
    id: 'CNMYG',
  },
  {
    name: 'Nanhai Port',
    coordinates: [113.15, 23.02],
    city: 'Nanhai',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNNAH'],
    code: '57000',
    id: 'CNNAH',
  },
  {
    name: 'Ningbo',
    city: 'Ningbo',
    province: 'Zhejiang',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.54399, 29.868336],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNNGB', 'CNNBO'],
    code: '57020',
    id: 'CNNBO',
  },
  {
    name: 'Ningbo',
    city: 'Ningbo',
    province: 'Zhejiang',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.54399, 29.868336],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNNGB', 'CNNBO'],
    code: '57020',
    id: 'CNNGB',
  },
  {
    name: 'Nangang',
    city: 'Nangang',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.2004523, 23.0372705],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNNGG'],
    code: '57000',
    id: 'CNNGG',
  },
  {
    name: 'Nanjing',
    city: 'Nanjing',
    province: 'Jiangsu',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [118.796877, 32.060255],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNNKG'],
    code: '57014',
    id: 'CNNKG',
  },
  {
    name: 'Nanning',
    city: 'Nanning',
    province: 'Guangxi',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [108.366543, 22.817002],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNNNG'],
    code: '57000',
    id: 'CNNNG',
  },
  {
    name: 'Nansha Port',
    coordinates: [113.58, 22.75],
    city: 'Nansha',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNNSA'],
    code: '57073',
    id: 'CNNSA',
  },
  {
    name: 'Nantong Port',
    coordinates: [120.85, 32.02],
    city: 'Nantong',
    province: 'Jiangsu',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNNTG'],
    code: '57074',
    id: 'CNNTG',
  },
  {
    name: 'Shiqiao',
    coordinates: [113.37, 22.95],
    city: 'Shiqiao',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNPNY'],
    code: '57000',
    id: 'CNPNY',
  },
  {
    name: 'Putian',
    coordinates: [119.02, 24.43],
    city: 'Putian',
    province: 'Fujian',
    country: 'China',
    alias: [],
    regions: [],
    unlocs: ['CNPUT'],
    code: '57000',
    id: 'CNPUT',
  },
  {
    name: 'Qianwan',
    coordinates: [120.22, 36.05],
    city: 'Qianwan',
    province: 'Shandong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNQAW'],
    code: '57000',
    id: 'CNQAW',
  },
  {
    name: 'Qingdao',
    city: 'Qingdao',
    province: 'Shandong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [120.382612, 36.067117],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNTAO', 'CNQIN'],
    code: '57047',
    id: 'CNQIN',
  },
  {
    name: 'Qingyuan',
    city: 'Qingyuan',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.056031, 23.681764],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNQYN'],
    code: '57000',
    id: 'CNQYN',
  },
  {
    name: 'Quanzhou Port',
    coordinates: [118.6, 24.93],
    city: 'Quanzhou',
    province: 'Fujian',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNQZJ'],
    code: '57071',
    id: 'CNQZJ',
  },
  {
    name: 'Rongqi Port',
    coordinates: [113.27, 22.78],
    city: 'Rongqi',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNROQ'],
    code: '57000',
    id: 'CNROQ',
  },
  {
    name: 'Rizhao Port',
    coordinates: [119.53, 35.38],
    city: 'Rizhao',
    province: 'Shandong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNRZH'],
    code: '57024',
    id: 'CNRZH',
  },
  {
    name: 'Sanbu Port',
    city: 'Sanbu',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [112.716189, 22.36821],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSBU'],
    code: '57000',
    id: 'CNSBU',
  },
  {
    name: 'Shuidong Port',
    coordinates: [111, 21.52],
    city: 'Shuidong',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSDG'],
    code: '57000',
    id: 'CNSDG',
  },
  {
    name: 'Shanghai',
    city: 'Shanghai',
    province: 'Shanghai',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.473701, 31.230416],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSHA', 'CNSGH'],
    code: '57035',
    id: 'CNSGH',
  },
  {
    name: 'Shanghai',
    city: 'Shanghai',
    province: 'Shanghai',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.473701, 31.230416],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSHA', 'CNSGH'],
    code: '57035',
    id: 'CNSHA',
  },
  {
    name: 'Shekou Port',
    coordinates: [113.92, 22.48],
    city: 'Shekou',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSHK'],
    code: '57018',
    id: 'CNSHK',
  },
  {
    name: 'Qinhuangdao Port',
    coordinates: [119.58, 39.92],
    city: 'Qinhuangdao',
    province: 'Hebei',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSHP'],
    code: '57009',
    id: 'CNSHP',
  },
  {
    name: 'Shatian',
    city: 'Shatian',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.617788, 22.919003],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSIN'],
    code: '57000',
    id: 'CNSIN',
  },
  {
    name: 'Sanshui',
    coordinates: [112.87, 23.17],
    city: 'Sanshui',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSJQ'],
    code: '57000',
    id: 'CNSJQ',
  },
  {
    name: 'Shenwan',
    city: 'Shenwan',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.3640848, 22.2815335],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSNW'],
    code: '57000',
    id: 'CNSNW',
  },
  {
    name: 'Sanshan (Nanhai)',
    city: 'Sanshan (Nanhai)',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.2413272, 23.0359965],
    province: 'Guangdong Sheng',
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSSH'],
    code: '57000',
    id: 'CNSSH',
  },
  {
    code: '57070',
    province: 'Guangdong',
    country: 'China',
    city: 'Shantou',
    name: 'Port of Shantou',
    alias: [],
    regions: [],
    unlocs: ['CNSTG'],
    id: 'CNSTG',
  },
  {
    name: 'Shunde Port',
    coordinates: [113.25, 22.83],
    city: 'Shunde',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSUD'],
    code: '57000',
    id: 'CNSUD',
  },
  {
    name: 'Jieyang Chaoshan Apt',
    city: 'Jieyang Chaoshan Apt',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.266531, 23.132191],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSWA'],
    code: '57000',
    id: 'CNSWA',
  },
  {
    name: 'Shanwei Port',
    coordinates: [115.35, 22.78],
    city: 'Shanwei',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSWE'],
    code: '57000',
    id: 'CNSWE',
  },
  {
    name: 'Shenzhen Baoan',
    city: 'Shenzhen Baoan',
    province: 'Guangdong',
    country: 'China',
    alias: ['Da Chan Bay'],
    regions: [],
    coordinates: [113.88402, 22.555259],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNSZX'],
    code: '57000',
    id: 'CNSZX',
  },
  {
    name: 'Taicang',
    city: 'Taicang',
    province: 'Jiangsu',
    country: 'China',
    alias: ['Suzhou', 'Changshu', 'Zhangjiagang'],
    regions: [],
    coordinates: [121.13055, 31.457735],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNTAG'],
    code: '57038',
    id: 'CNTAG',
  },
  {
    name: 'Qingdao',
    city: 'Qingdao',
    province: 'Shandong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [120.382612, 36.067117],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNTAO', 'CNQIN'],
    code: '57047',
    id: 'CNTAO',
  },
  {
    name: 'Taiping',
    coordinates: [110.17, 21.03],
    city: 'Taiping',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNTAP'],
    code: '57000',
    id: 'CNTAP',
  },
  {
    name: 'Taicheng (Taishan)',
    coordinates: [112.78, 22.25],
    city: 'Taicheng (Taishan)',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNTHG'],
    code: '57038',
    id: 'CNTHG',
  },
  {
    name: 'Tianjin',
    city: 'Tianjin',
    province: 'Tianjin',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [117.200983, 39.084158],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNTSN'],
    code: '57043',
    id: 'CNTSN',
  },
  {
    name: 'Tianjin New Port',
    coordinates: [117.7, 38.98],
    city: 'Tianjin New',
    province: 'Tianjin',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNTXG'],
    code: '57043',
    id: 'CNTXG',
  },
  {
    name: 'Taizhou',
    coordinates: [119.9, 32.48],
    city: 'Taizhou',
    province: 'Jiangsu',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNTZO'],
    code: '57000',
    id: 'CNTZO',
  },
  {
    name: 'Wanzai',
    coordinates: [113.52, 22.18],
    city: 'Wanzai',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNWAZ'],
    code: '57000',
    id: 'CNWAZ',
  },
  {
    name: 'Weihai',
    coordinates: [122.12, 37.52],
    city: 'Weihai',
    province: 'Shandong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNWEI'],
    code: '57000',
    id: 'CNWEI',
  },
  {
    name: 'Wuhu Port',
    coordinates: [118.37, 31.32],
    city: 'Wuhu',
    province: 'Anhui',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNWHI'],
    code: '57000',
    id: 'CNWHI',
  },
  {
    name: 'Wenzhou',
    city: 'Wenzhou',
    province: 'Zhejiang',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [120.699367, 27.994267],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNWNZ'],
    code: '57000',
    id: 'CNWNZ',
  },
  {
    name: 'Wuhan',
    city: 'Wuhan',
    province: 'Hubei',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [114.305393, 30.593099],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNWUH'],
    code: '57013',
    id: 'CNWUH',
  },
  {
    name: 'Xinfeng',
    city: 'Xinfeng',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [114.206867, 24.05976],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNXAB'],
    code: '57000',
    id: 'CNXAB',
  },
  {
    name: 'Xiamen',
    city: 'Xiamen',
    province: 'Fujian',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [118.089425, 24.479834],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNXMN', 'CNXAM'],
    code: '57069',
    id: 'CNXAM',
  },
  {
    name: 'Xingang',
    city: 'Xingang',
    province: 'Tianjin',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [117.6849329, 39.0065118],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNXGG', 'CNXGA'],
    code: '57017',
    id: 'CNXGA',
  },
  {
    name: 'Xingang',
    city: 'Xingang',
    province: 'Tianjin',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [117.6849329, 39.0065118],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNXGG', 'CNXGA'],
    code: '57017',
    id: 'CNXGG',
  },
  {
    name: 'Xiaolan',
    city: 'Xiaolan',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.2514, 22.671394],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNXIL'],
    code: '57000',
    id: 'CNXIL',
  },
  {
    name: 'Xinhui Port',
    coordinates: [113.02, 22.53],
    city: 'Xinhui',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNXIN'],
    code: '57000',
    id: 'CNXIN',
  },
  {
    name: 'Xintang Port',
    coordinates: [113.62, 23.12],
    city: 'Xintang',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNXIT'],
    code: '57000',
    id: 'CNXIT',
  },
  {
    name: 'Xiamen',
    city: 'Xiamen',
    province: 'Fujian',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [118.089425, 24.479834],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNXMN', 'CNXAM'],
    code: '57069',
    id: 'CNXMN',
  },
  {
    name: 'Yangshan',
    city: 'Yangshan',
    province: 'Zhejiang',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.869339, 29.476705],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYAN'],
    code: '57037',
    id: 'CNYAN',
  },
  {
    name: 'Yichang',
    coordinates: [111.28, 30.7],
    city: 'Yichang',
    province: 'Hubei',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYIC'],
    code: '57000',
    id: 'CNYIC',
  },
  {
    name: 'Yingkou Port',
    coordinates: [122.22, 40.65],
    city: 'Yingkou',
    province: 'Liaoning',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYIK'],
    code: '57053',
    id: 'CNYIK',
  },
  {
    name: 'Yangjiang Port',
    coordinates: [111.97, 21.83],
    city: 'Yangjiang',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYJI'],
    code: '57000',
    id: 'CNYJI',
  },
  {
    name: 'Yunfu',
    city: 'Yunfu',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [112.044491, 22.915094],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYNF'],
    code: '57000',
    id: 'CNYNF',
  },
  {
    name: 'Yantai',
    city: 'Yantai',
    province: 'Shandong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.447935, 37.463822],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYNT', 'CNYTG'],
    code: '57055',
    id: 'CNYNT',
  },
  {
    name: 'Yangpu Port',
    coordinates: [109.2, 19.7],
    city: 'Yangpu',
    province: 'Hainan',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYPG'],
    code: '57000',
    id: 'CNYPG',
  },
  {
    name: 'Beijiao',
    city: 'Beijiao',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.207187, 22.928305],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYQS'],
    code: '57016',
    id: 'CNYQS',
  },
  {
    name: 'Yantai',
    city: 'Yantai',
    province: 'Shandong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.447935, 37.463822],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYNT', 'CNYTG'],
    code: '57055',
    id: 'CNYTG',
  },
  {
    name: 'Yantian Port',
    coordinates: [119.87, 26.85],
    city: 'Yantian',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYTN'],
    code: '57078',
    id: 'CNYTN',
  },
  {
    name: 'Yangzhou Port',
    coordinates: [119.43, 32.38],
    city: 'Yangzhou',
    province: 'Jiangsu',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNYZH'],
    code: '57000',
    id: 'CNYZH',
  },
  {
    name: 'Zhapu (Zhejiang)',
    city: 'Zhapu (Zhejiang)',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [121.095594, 30.603546],
    province: 'Zhejiang',
    timezone: 'Asia/Shanghai',
    unlocs: ['CNZAP'],
    code: '57000',
    id: 'CNZAP',
  },
  {
    name: 'Zhanjiang Potou Apt',
    city: 'Zhanjiang Potou Apt',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [113.266531, 23.132191],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNZHA'],
    code: '57075',
    id: 'CNZHA',
  },
  {
    name: 'Zhenjiang Port',
    coordinates: [119.45, 32.18],
    city: 'Zhenjiang',
    province: 'Jiangsu',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNZHE'],
    code: '57019',
    id: 'CNZHE',
  },
  {
    name: 'Zhangjiagang',
    coordinates: [120.53, 31.87],
    city: 'Zhangjiagang',
    province: 'Jiangsu',
    country: 'China',
    alias: ['Taicang', 'Suzhou', 'Changshu'],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNZJG'],
    code: '57075',
    id: 'CNZJG',
  },
  {
    name: 'Zhoushan Port',
    city: 'Zhoushan',
    province: 'Zhejiang',
    country: 'China',
    alias: [],
    regions: [],
    coordinates: [122.207216, 29.98529499999999],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNZOS'],
    code: '57000',
    id: 'CNZOS',
  },
  {
    name: 'Zhaoqing Port',
    coordinates: [112.45, 23.05],
    city: 'Zhaoqing',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNZQG'],
    code: '57000',
    id: 'CNZQG',
  },
  {
    name: 'Zhongshan Port',
    coordinates: [113.37, 22.52],
    city: 'Zhongshan',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNZSN'],
    code: '57079',
    id: 'CNZSN',
  },
  {
    name: 'Zhuhai Port',
    coordinates: [113.57, 22.28],
    city: 'Zhuhai',
    province: 'Guangdong',
    country: 'China',
    alias: [],
    regions: [],
    timezone: 'Asia/Shanghai',
    unlocs: ['CNZUH'],
    code: '57077',
    id: 'CNZUH',
  },
  {
    name: 'Barranquilla',
    city: 'Barranquilla',
    country: 'Colombia',
    alias: [],
    regions: [],
    coordinates: [-74.80698129999999, 11.0041072],
    province: 'Atlantico',
    timezone: 'America/Bogota',
    unlocs: ['COBAQ'],
    code: '30104',
    id: 'COBAQ',
  },
  {
    name: 'Buenaventura',
    city: 'Buenaventura',
    country: 'Colombia',
    alias: [],
    regions: [],
    coordinates: [-77.01972119999999, 3.8830471],
    province: 'Valle del Cauca',
    timezone: 'America/Bogota',
    unlocs: ['COBUN'],
    code: '30151',
    id: 'COBUN',
  },
  {
    name: 'Cartagena',
    city: 'Cartagena',
    country: 'Colombia',
    alias: [],
    regions: [],
    coordinates: [-75.4794257, 10.3910485],
    province: 'Bolívar',
    timezone: 'America/Bogota',
    unlocs: ['COCTG'],
    code: '30107',
    id: 'COCTG',
  },
  {
    name: 'Puerto Bolivar',
    coordinates: [-74.73, 10.68],
    city: 'Puerto Bolívar',
    province: 'La Guajira',
    country: 'Colombia',
    alias: [],
    regions: [],
    timezone: 'America/Bogota',
    unlocs: ['COPBO'],
    code: '30121',
    id: 'COPBO',
  },
  {
    name: 'Santa Marta',
    city: 'Santa Marta',
    country: 'Colombia',
    alias: [],
    regions: [],
    coordinates: [-74.2110227, 11.2403547],
    province: 'Magdalena',
    timezone: 'America/Bogota',
    unlocs: ['COSMR'],
    code: '30140',
    id: 'COSMR',
  },
  {
    name: 'Tumaco',
    city: 'Tumaco',
    country: 'Colombia',
    alias: [],
    regions: [],
    coordinates: [-78.802094, 1.80009],
    province: 'Narino',
    timezone: 'America/Bogota',
    unlocs: ['COTCO'],
    code: '30152',
    id: 'COTCO',
  },
  {
    name: 'Caldera',
    coordinates: [-84.72, 9.95],
    city: 'Caldera',
    province: 'Puntarenas',
    country: 'Costa Rica',
    alias: [],
    regions: [],
    timezone: 'America/Costa_Rica',
    unlocs: ['CRCAL'],
    code: '22361',
    id: 'CRCAL',
  },
  {
    name: 'Golfito',
    coordinates: [-83.15, 8.65],
    city: 'Golfito',
    province: 'Puntarenas',
    country: 'Costa Rica',
    alias: [],
    regions: [],
    timezone: 'America/Costa_Rica',
    unlocs: ['CRGLF'],
    code: '22363',
    id: 'CRGLF',
  },
  {
    name: 'Puerto Limon',
    coordinates: [-83.03, 10],
    city: 'Puerto Limón',
    province: 'Limón',
    country: 'Costa Rica',
    alias: [],
    regions: [],
    timezone: 'America/Costa_Rica',
    unlocs: ['CRLIO'],
    code: '22313',
    id: 'CRLIO',
  },
  {
    name: 'Moin',
    coordinates: [-83.08, 10],
    city: 'Moín',
    province: 'Limón',
    country: 'Costa Rica',
    alias: [],
    regions: [],
    timezone: 'America/Costa_Rica',
    unlocs: ['CRMOB'],
    code: '22315',
    id: 'CRMOB',
  },
  {
    name: 'Puntarenas',
    coordinates: [-84.83, 9.97],
    city: 'Puntarenas',
    province: 'Puntarenas',
    country: 'Costa Rica',
    alias: [],
    regions: [],
    timezone: 'America/Costa_Rica',
    unlocs: ['CRPAS'],
    code: '22387',
    id: 'CRPAS',
  },
  {
    name: 'Puerto Caldera',
    city: 'Puerto Caldera',
    province: 'San Jose',
    country: 'Costa Rica',
    alias: [],
    regions: [],
    coordinates: [-84.0869419, 9.9328525],
    timezone: 'America/Costa_Rica',
    unlocs: ['CRPCA'],
    code: '22361',
    id: 'CRPCA',
  },
  {
    name: 'San Jose',
    coordinates: [-84.08, 9.93],
    city: 'San José',
    province: 'San José',
    country: 'Costa Rica',
    alias: [],
    regions: [],
    timezone: 'America/Costa_Rica',
    unlocs: ['CRSJO'],
    code: '20577',
    id: 'CRSJO',
  },
  {
    name: 'La Habana',
    coordinates: [-82.35, 23.12],
    city: 'La Habana',
    province: 'Ciudad de La Habana',
    country: 'Cuba',
    alias: [],
    regions: [],
    timezone: 'America/Havana',
    unlocs: ['CUHAV'],
    code: '23944',
    id: 'CUHAV',
  },
  {
    name: 'Mariel',
    coordinates: [-82.75, 23],
    city: 'Mariel',
    country: 'Cuba',
    alias: [],
    regions: [],
    province: 'Artemisa Province',
    timezone: 'America/Havana',
    unlocs: ['CUMAR'],
    code: '23900',
    id: 'CUMAR',
  },
  {
    name: 'Matanzas',
    city: 'Matanzas',
    country: 'Cuba',
    alias: [],
    regions: [],
    coordinates: [-81.3399414, 22.5767123],
    province: 'Matanzas',
    timezone: 'America/Havana',
    unlocs: ['CUQMA'],
    code: '23900',
    id: 'CUQMA',
  },
  {
    name: 'Santiago de Cuba',
    city: 'Santiago de Cuba',
    country: 'Cuba',
    alias: [],
    regions: [],
    coordinates: [-75.8301537, 20.01693],
    province: 'Santiago de Cuba',
    timezone: 'America/Havana',
    unlocs: ['CUSCU'],
    code: '23900',
    id: 'CUSCU',
  },
  {
    name: 'Mindelo',
    coordinates: [-25, 16.88],
    city: 'Mindelo',
    province: 'São Vicente',
    country: 'Cape Verde',
    alias: [],
    regions: [],
    timezone: 'Atlantic/Cape_Verde',
    unlocs: ['CVMIN'],
    code: '76465',
    id: 'CVMIN',
  },
  {
    name: 'Palmeira',
    city: 'Palmeira',
    country: 'Cape Verde',
    alias: [],
    regions: [],
    coordinates: [-22.9788596, 16.7582698],
    province: 'Sal',
    timezone: 'Atlantic/Cape_Verde',
    unlocs: ['CVPAL'],
    code: '76499',
    id: 'CVPAL',
  },
  {
    name: 'Praia',
    city: 'Praia',
    country: 'Cape Verde',
    alias: [],
    regions: [],
    coordinates: [-23.5133267, 14.93305],
    province: 'Praia',
    timezone: 'Atlantic/Cape_Verde',
    unlocs: ['CVRAI'],
    code: '76475',
    id: 'CVRAI',
  },
  {
    name: 'Limassol',
    coordinates: [33.05, 34.68],
    city: 'Limassol',
    province: 'Lemesos',
    country: 'Cyprus',
    alias: [],
    regions: [],
    timezone: 'Asia/Nicosia',
    unlocs: ['CYLMS'],
    code: '49135',
    id: 'CYLMS',
  },
  {
    name: 'Nicosia',
    coordinates: [33.37, 35.17],
    city: 'Nicosia',
    province: 'Lefkosia',
    country: 'Cyprus',
    alias: [],
    regions: [],
    timezone: 'Asia/Nicosia',
    unlocs: ['CYNIC'],
    code: '49199',
    id: 'CYNIC',
  },
  {
    name: 'Paphos',
    coordinates: [32.42, 34.77],
    city: 'Paphos',
    province: 'Pafos',
    country: 'Cyprus',
    alias: [],
    regions: [],
    timezone: 'Asia/Nicosia',
    unlocs: ['CYPFO'],
    code: '49199',
    id: 'CYPFO',
  },
  {
    timezone: 'Europe/Berlin',
    coordinates: [7.742533799999999, 48.1214009],
    name: 'Bahlingen',
    city: 'Bahlingen',
    province: 'Baden-Württemberg',
    country: 'Germany',
    alias: [],
    regions: [],
    unlocs: ['DEBEZ'],
    code: '42800',
    id: 'DEBEZ',
  },
  {
    name: 'Bingen am Rhein',
    coordinates: [7.88, 49.97],
    city: 'Bingen am Rhein',
    province: 'Rheinland-Pfalz',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DEBIN'],
    code: '42800',
    id: 'DEBIN',
  },
  {
    name: 'Brake',
    coordinates: [8.48, 53.33],
    city: 'Brake',
    province: 'Niedersachsen',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DEBKE'],
    code: '42867',
    id: 'DEBKE',
  },
  {
    name: 'Bad Oeynhausen',
    city: 'Bad Oeynhausen',
    province: 'Nordrhein-Westfalen',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [8.8009706, 52.2084623],
    timezone: 'Europe/Berlin',
    unlocs: ['DEBOY'],
    code: '42899',
    id: 'DEBOY',
  },
  {
    name: 'Bramel',
    coordinates: [8.7, 53.55],
    city: 'Bramel',
    province: 'Niedersachsen',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DEBRA'],
    code: '42899',
    id: 'DEBRA',
  },
  {
    name: 'Brunsbuttel',
    city: 'Brunsbüttel',
    province: 'Schleswig-Holstein',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [9.1338853, 53.8988902],
    timezone: 'Europe/Berlin',
    unlocs: ['DEBRB'],
    code: '42871',
    id: 'DEBRB',
  },
  {
    name: 'Bremen',
    city: 'Bremen',
    province: 'Bremen',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [8.8016937, 53.07929619999999],
    timezone: 'Europe/Berlin',
    unlocs: ['DEBRE'],
    code: '42869',
    id: 'DEBRE',
  },
  {
    name: 'Bremerhaven',
    city: 'Bremerhaven',
    province: 'Bremen',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [8.5809424, 53.5395845],
    timezone: 'Europe/Berlin',
    unlocs: ['DEBRV'],
    code: '42870',
    id: 'DEBRV',
  },
  {
    name: 'Burgstaaken/Fehmarn',
    city: 'Burgstaaken/Fehmarn',
    province: 'Schleswig-Holstein',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [11.1925499, 54.4191983],
    timezone: 'Europe/Berlin',
    unlocs: ['DEBSK'],
    code: '42800',
    id: 'DEBSK',
  },
  {
    name: 'Koln',
    coordinates: [6.93, 50.95],
    city: 'Köln',
    province: 'Nordrhein-Westfalen',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DECGN'],
    code: '42899',
    id: 'DECGN',
  },
  {
    name: 'Cuxhaven',
    coordinates: [8.7, 53.88],
    city: 'Cuxhaven',
    province: 'Niedersachsen',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DECUX'],
    code: '42873',
    id: 'DECUX',
  },
  {
    name: 'Dusseldorf',
    coordinates: [6.78, 51.23],
    city: 'Düsseldorf',
    province: 'Nordrhein-Westfalen',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DEDUS'],
    code: '42899',
    id: 'DEDUS',
  },
  {
    name: 'Elsfleth',
    coordinates: [8.47, 53.23],
    city: 'Elsfleth',
    province: 'Niedersachsen',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DEELS'],
    code: '42868',
    id: 'DEELS',
  },
  {
    name: 'Emden',
    coordinates: [7.22, 53.37],
    city: 'Emden',
    province: 'Niedersachsen',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DEEME'],
    code: '42875',
    id: 'DEEME',
  },
  {
    name: 'Flensburg',
    city: 'Flensburg',
    province: 'Schleswig-Holstein',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [9.4469964, 54.7937431],
    timezone: 'Europe/Berlin',
    unlocs: ['DEFLF'],
    code: '42800',
    id: 'DEFLF',
  },
  {
    name: 'Hamburg',
    city: 'Hamburg',
    province: 'Hamburg',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [9.9936818, 53.5510846],
    timezone: 'Europe/Berlin',
    unlocs: ['DEHAM'],
    code: '42879',
    id: 'DEHAM',
  },
  {
    name: 'Harburg',
    city: 'Harburg',
    province: 'Hamburg',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [9.9834184, 53.4607785],
    timezone: 'Europe/Berlin',
    unlocs: ['DEHBU'],
    code: '42881',
    id: 'DEHBU',
  },
  {
    name: 'Hamm',
    coordinates: [7.8, 51.68],
    city: 'Hamm',
    province: 'Nordrhein-Westfalen',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DEHMM'],
    code: '42899',
    id: 'DEHMM',
  },
  {
    name: 'Kiel',
    coordinates: [10.13, 54.33],
    city: 'Kiel',
    province: 'Schleswig-Holstein',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DEKEL'],
    code: '42825',
    id: 'DEKEL',
  },
  {
    timezone: 'Europe/Berlin',
    coordinates: [10.9556919, 49.5563955],
    name: 'Kriegenbrunn',
    city: 'Kriegenbrunn',
    province: 'Bayern',
    country: 'Germany',
    alias: [],
    regions: [],
    unlocs: ['DEKGU'],
    code: '42899',
    id: 'DEKGU',
  },
  {
    timezone: 'Europe/Berlin',
    coordinates: [9.211614899999999, 49.2178183],
    name: 'Kochendorf',
    city: 'Kochendorf',
    province: 'Baden-Württemberg',
    country: 'Germany',
    alias: [],
    regions: [],
    unlocs: ['DEKHO'],
    code: '42899',
    id: 'DEKHO',
  },
  {
    name: 'Lubeck',
    city: 'Lübeck',
    province: 'Schleswig-Holstein',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [10.6865593, 53.8654673],
    timezone: 'Europe/Berlin',
    unlocs: ['DELBC'],
    code: '42800',
    id: 'DELBC',
  },
  {
    name: 'Leer',
    city: 'Leer',
    province: 'Niedersachsen',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [7.4679018, 53.235657],
    timezone: 'Europe/Berlin',
    unlocs: ['DELEE'],
    code: '42899',
    id: 'DELEE',
  },
  {
    name: 'Mannheim',
    coordinates: [8.45, 49.48],
    city: 'Mannheim',
    province: 'Baden-Württemberg',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DEMHG'],
    code: '92003',
    id: 'DEMHG',
  },
  {
    name: 'Nordenham',
    coordinates: [8.48, 53.5],
    city: 'Nordenham',
    province: 'Niedersachsen',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DENHA'],
    code: '42883',
    id: 'DENHA',
  },
  {
    timezone: 'Europe/Berlin',
    coordinates: [8.9246523, 54.5108878],
    name: 'Nordstrand',
    city: 'Nordstrand',
    province: 'Schleswig-Holstein',
    country: 'Germany',
    alias: [],
    regions: [],
    unlocs: ['DENOR'],
    code: '42899',
    id: 'DENOR',
  },
  {
    timezone: 'Europe/Berlin',
    coordinates: [10.5264351, 47.5766418],
    name: 'Pfronten',
    city: 'Pfronten',
    province: 'Bayern',
    country: 'Germany',
    alias: [],
    regions: [],
    unlocs: ['DEPFR'],
    code: '42899',
    id: 'DEPFR',
  },
  {
    name: 'Rostock',
    coordinates: [12.13, 54.08],
    city: 'Rostock',
    province: 'Mecklenburg-Vorpommern',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DERSK'],
    code: '42811',
    id: 'DERSK',
  },
  {
    name: 'Wedel',
    city: 'Wedel',
    province: 'Schleswig-Holstein',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [9.7137465, 53.58238129999999],
    timezone: 'Europe/Berlin',
    unlocs: ['DEWED'],
    code: '42899',
    id: 'DEWED',
  },
  {
    name: 'Worms',
    city: 'Worms',
    province: 'Rheinland-Pfalz',
    country: 'Germany',
    alias: [],
    regions: [],
    coordinates: [8.3507182, 49.6341372],
    timezone: 'Europe/Berlin',
    unlocs: ['DEWOR'],
    code: '42899',
    id: 'DEWOR',
  },
  {
    name: 'Wilhelmshaven',
    coordinates: [8.13, 53.52],
    city: 'Wilhelmshaven',
    province: 'Niedersachsen',
    country: 'Germany',
    alias: [],
    regions: [],
    timezone: 'Europe/Berlin',
    unlocs: ['DEWVN'],
    code: '42891',
    id: 'DEWVN',
  },
  {
    name: 'Djibouti',
    city: 'Djibouti',
    country: 'Djibouti',
    alias: [],
    regions: [],
    coordinates: [43.1456475, 11.5720765],
    province: 'Djibouti',
    timezone: 'Africa/Djibouti',
    unlocs: ['DJJIB', 'DJPOD'],
    code: '77701',
    id: 'DJJIB',
  },
  {
    name: 'Djibouti',
    city: 'Djibouti',
    country: 'Djibouti',
    alias: [],
    regions: [],
    coordinates: [43.1456475, 11.5720765],
    province: 'Djibouti',
    timezone: 'Africa/Djibouti',
    unlocs: ['DJJIB', 'DJPOD'],
    code: '77701',
    id: 'DJPOD',
  },
  {
    name: 'Aalborg',
    city: 'Aalborg',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [9.921747, 57.0488195],
    province: 'North Denmark Region',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKAAL'],
    code: '40903',
    id: 'DKAAL',
  },
  {
    name: 'Aarhus',
    coordinates: [10.22, 56.15],
    city: 'Aarhus',
    province: 'Midtjylland',
    country: 'Denmark',
    alias: [],
    regions: [],
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKAAR'],
    code: '40906',
    id: 'DKAAR',
  },
  {
    name: 'Assens',
    city: 'Assens',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [9.9008188, 55.2702263],
    province: 'Region Syddanmark',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKASN'],
    code: '40900',
    id: 'DKASN',
  },
  {
    name: 'Kobenhavn',
    coordinates: [12.58, 55.67],
    city: 'København',
    province: 'Hovedstaden',
    country: 'Denmark',
    alias: [],
    regions: [],
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKCPH'],
    code: '40948',
    id: 'DKCPH',
  },
  {
    name: 'Esbjerg',
    city: 'Esbjerg',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [8.459405, 55.476466],
    province: 'Region Syddanmark',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKEBJ'],
    code: '40919',
    id: 'DKEBJ',
  },
  {
    name: 'Fredericia',
    city: 'Fredericia',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [9.749516999999999, 55.5689298],
    province: 'Region Syddanmark',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKFRC'],
    code: '40921',
    id: 'DKFRC',
  },
  {
    name: 'Hirtshals',
    city: 'Hirtshals',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [9.9668379, 57.58695299999999],
    province: 'North Denmark Region',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKHIR'],
    code: '40900',
    id: 'DKHIR',
  },
  {
    name: 'Helsingor',
    city: 'Helsingør',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [12.592127, 56.030787],
    province: 'Capital Region of Denmark',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKHLS'],
    code: '40900',
    id: 'DKHLS',
  },
  {
    name: 'Holstebro',
    city: 'Holstebro',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [8.621727, 56.36153400000001],
    province: 'Central Denmark Region',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKHSB'],
    code: '40900',
    id: 'DKHSB',
  },
  {
    name: 'Kalundborg',
    city: 'Kalundborg',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [11.089188, 55.686429],
    province: 'Region Zealand',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKKAL'],
    code: '40940',
    id: 'DKKAL',
  },
  {
    name: 'Kerteminde',
    city: 'Kerteminde',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [10.654979, 55.455882],
    province: 'Region Syddanmark',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKKTD'],
    code: '40900',
    id: 'DKKTD',
  },
  {
    name: 'Nyborg',
    city: 'Nyborg',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [10.799561, 55.324169],
    province: 'Region Syddanmark',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKNBG'],
    code: '40923',
    id: 'DKNBG',
  },
  {
    name: 'Odense',
    city: 'Odense',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [10.40237, 55.40375599999999],
    province: 'Region Syddanmark',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKODE'],
    code: '40961',
    id: 'DKODE',
  },
  {
    name: 'Rungsted',
    coordinates: [12.52, 55.88],
    city: 'Rungsted',
    province: 'Hovedstaden',
    country: 'Denmark',
    alias: [],
    regions: [],
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKRUG'],
    code: '40900',
    id: 'DKRUG',
  },
  {
    name: 'Skagen',
    city: 'Skagen',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [10.579186, 57.725004],
    province: 'North Denmark Region',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKSKA'],
    code: '40900',
    id: 'DKSKA',
  },
  {
    name: 'Svendborg',
    city: 'Svendborg',
    country: 'Denmark',
    alias: [],
    regions: [],
    coordinates: [10.607282, 55.067434],
    province: 'Region Syddanmark',
    timezone: 'Europe/Copenhagen',
    unlocs: ['DKSVE'],
    code: '40928',
    id: 'DKSVE',
  },
  {
    name: 'Roseau',
    city: 'Roseau',
    country: 'Dominica',
    alias: [],
    regions: [],
    coordinates: [-61.37935539999999, 15.3091676],
    province: 'Saint George Parish',
    timezone: 'America/Dominica',
    unlocs: ['DMRSU'],
    code: '24851',
    id: 'DMRSU',
  },
  {
    name: 'Caucedo',
    coordinates: [-69.63, 18.42],
    city: 'Caucedo',
    province: 'Distrito Nacional (Santo Domingo)',
    country: 'Dominican Republic',
    alias: [],
    regions: [],
    timezone: 'America/Santo_Domingo',
    unlocs: ['DOCAU'],
    code: '24722',
    id: 'DOCAU',
  },
  {
    name: 'Rio Haina',
    city: 'Rio Haina',
    country: 'Dominican Republic',
    alias: [],
    regions: [],
    coordinates: [-70.07310749999999, 18.5132686],
    province: 'Santo Domingo',
    timezone: 'America/Santo_Domingo',
    unlocs: ['DOHAI'],
    code: '24741',
    id: 'DOHAI',
  },
  {
    name: 'Manzanillo',
    coordinates: [-71.75, 19.7],
    city: 'Manzanillo',
    country: 'Dominican Republic',
    alias: [],
    regions: [],
    province: 'Monte Cristi',
    timezone: 'America/Santo_Domingo',
    unlocs: ['DOMAN'],
    code: '24745',
    id: 'DOMAN',
  },
  {
    name: 'Puerto Plata',
    city: 'Puerto Plata',
    country: 'Dominican Republic',
    alias: [],
    regions: [],
    coordinates: [-70.6871091, 19.7807686],
    province: 'Puerto Plata',
    timezone: 'America/Santo_Domingo',
    unlocs: ['DOPOP'],
    code: '24773',
    id: 'DOPOP',
  },
  {
    name: 'Santo Domingo',
    coordinates: [-69.9, 18.47],
    city: 'Santo Domingo',
    province: 'Distrito Nacional (Santo Domingo)',
    country: 'Dominican Republic',
    alias: [],
    regions: [],
    timezone: 'America/Santo_Domingo',
    unlocs: ['DOSDQ'],
    code: '24737',
    id: 'DOSDQ',
  },
  {
    name: 'San Pedro de Macoris',
    coordinates: [-69.3, 18.45],
    city: 'San Pedro de Macorís',
    country: 'Dominican Republic',
    alias: [],
    regions: [],
    province: 'San Pedro de Macoris',
    timezone: 'America/Santo_Domingo',
    unlocs: ['DOSPM'],
    code: '24791',
    id: 'DOSPM',
  },
  {
    name: 'Annaba (ex Bone)',
    city: 'Annaba (ex Bone)',
    country: 'Algeria',
    alias: [],
    regions: [],
    coordinates: [36.9178674, 7.6740654],
    timezone: 'Africa/Addis_Ababa',
    unlocs: ['DZAAE'],
    code: '72123',
    id: 'DZAAE',
  },
  {
    name: 'Alger (Algiers)',
    coordinates: [3.05, 36.75],
    city: 'Alger (Algiers)',
    province: 'Alger',
    country: 'Algeria',
    alias: [],
    regions: [],
    timezone: 'Africa/Algiers',
    unlocs: ['DZALG'],
    code: '72101',
    id: 'DZALG',
  },
  {
    name: 'Bejaia (ex Bougie)',
    city: 'Bejaia (ex Bougie)',
    country: 'Algeria',
    alias: [],
    regions: [],
    coordinates: [5.056733299999999, 36.7508896],
    province: 'Béjaïa',
    timezone: 'Africa/Algiers',
    unlocs: ['DZBJA'],
    code: '72107',
    id: 'DZBJA',
  },
  {
    name: 'Ghardaia',
    coordinates: [3.67, 32.48],
    city: 'Ghardaïa',
    country: 'Algeria',
    alias: [],
    regions: [],
    province: 'Ghardaïa Province',
    timezone: 'Africa/Tunis',
    unlocs: ['DZGHA'],
    code: '72100',
    id: 'DZGHA',
  },
  {
    name: 'Mostaganem',
    city: 'Mostaganem',
    country: 'Algeria',
    alias: [],
    regions: [],
    coordinates: [0.1401381, 36.01312350000001],
    province: 'Mostaganem',
    timezone: 'Africa/Algiers',
    unlocs: ['DZMOS'],
    code: '72100',
    id: 'DZMOS',
  },
  {
    name: 'Oran',
    city: 'Oran',
    country: 'Algeria',
    alias: [],
    regions: [],
    coordinates: [-0.6337376, 35.6976541],
    province: 'Oran',
    timezone: 'Africa/Algiers',
    unlocs: ['DZORN'],
    code: '72179',
    id: 'DZORN',
  },
  {
    name: 'Skikda (ex Philippeville)',
    city: 'Skikda (ex Philippeville)',
    country: 'Algeria',
    alias: [],
    regions: [],
    coordinates: [6.910181, 36.87152],
    timezone: 'Africa/Algiers',
    unlocs: ['DZSKI'],
    code: '72189',
    id: 'DZSKI',
  },
  {
    name: 'Bahia de Caraquez',
    coordinates: [-80.5, -0.55],
    city: 'Bahía de Caráquez',
    province: 'Manabí',
    country: 'Ecuador',
    alias: [],
    regions: [],
    timezone: 'America/Guayaquil',
    unlocs: ['ECBHA'],
    code: '33101',
    id: 'ECBHA',
  },
  {
    name: 'Chanduy',
    coordinates: [-80.67, -2.42],
    city: 'Chanduy',
    province: 'Guayas',
    country: 'Ecuador',
    alias: [],
    regions: [],
    timezone: 'America/Guayaquil',
    unlocs: ['ECCHA'],
    code: '33100',
    id: 'ECCHA',
  },
  {
    name: 'Esmeraldas',
    coordinates: [-79.7, 0.98],
    city: 'Esmeraldas',
    province: 'Esmeraldas',
    country: 'Ecuador',
    alias: [],
    regions: [],
    timezone: 'America/Guayaquil',
    unlocs: ['ECESM'],
    code: '33121',
    id: 'ECESM',
  },
  {
    name: 'Guayaquil',
    coordinates: [-79.9, -2.17],
    city: 'Guayaquil',
    province: 'Guayas',
    country: 'Ecuador',
    alias: [],
    regions: [],
    timezone: 'America/Guayaquil',
    unlocs: ['ECGYE'],
    code: '33131',
    id: 'ECGYE',
  },
  {
    name: 'La Libertad',
    coordinates: [-80.9, -2.23],
    city: 'La Libertad',
    province: 'Guayas',
    country: 'Ecuador',
    alias: [],
    regions: [],
    timezone: 'America/Guayaquil',
    unlocs: ['ECLLD'],
    code: '33136',
    id: 'ECLLD',
  },
  {
    name: 'Manta',
    coordinates: [-80.73, -0.95],
    city: 'Manta',
    province: 'Manabí',
    country: 'Ecuador',
    alias: [],
    regions: [],
    timezone: 'America/Guayaquil',
    unlocs: ['ECMEC'],
    code: '33151',
    id: 'ECMEC',
  },
  {
    name: 'Monteverde',
    coordinates: [-80.72, -2.03],
    city: 'Monteverde',
    country: 'Ecuador',
    alias: [],
    regions: [],
    province: 'Santa Elena',
    timezone: 'America/Guayaquil',
    unlocs: ['ECMTV'],
    code: '33100',
    id: 'ECMTV',
  },
  {
    name: 'Puerto Bolivar',
    coordinates: [-79.98, -3.27],
    city: 'Puerto Bolívar',
    province: 'El Oro',
    country: 'Ecuador',
    alias: [],
    regions: [],
    timezone: 'America/Guayaquil',
    unlocs: ['ECPBO'],
    code: '33159',
    id: 'ECPBO',
  },
  {
    timezone: 'Europe/Tallinn',
    coordinates: [22.4658999, 58.8093999],
    name: 'Haldi',
    city: 'Haldi',
    province: 'Hiiumaa',
    country: 'Estonia',
    alias: [],
    regions: [],
    unlocs: ['EEHLD'],
    code: '44700',
    id: 'EEHLD',
  },
  {
    name: 'Muuga',
    coordinates: [24.97, 59.5],
    city: 'Muuga',
    province: 'Harjumaa',
    country: 'Estonia',
    alias: [],
    regions: [],
    timezone: 'Europe/Tallinn',
    unlocs: ['EEMUG'],
    code: '44702',
    id: 'EEMUG',
  },
  {
    name: 'Tallinn',
    coordinates: [24.73, 59.43],
    city: 'Tallinn',
    province: 'Harjumaa',
    country: 'Estonia',
    alias: [],
    regions: [],
    timezone: 'Europe/Tallinn',
    unlocs: ['EETLL'],
    code: '44701',
    id: 'EETLL',
  },
  {
    name: 'Ain Sukhna',
    city: 'Ain Sukhna',
    country: 'Egypt',
    alias: [],
    regions: [],
    coordinates: [32.3300171, 29.6325617],
    province: 'Suez Governorate',
    timezone: 'Africa/Cairo',
    unlocs: ['EGAIS'],
    code: '72932',
    id: 'EGAIS',
  },
  {
    name: 'El Iskandariya (Alexandria)',
    city: 'El Iskandariya (Alexandria)',
    country: 'Egypt',
    alias: [],
    regions: [],
    coordinates: [30.0625938, 31.3091118],
    province: 'Alexandria Governorate',
    timezone: 'Africa/Cairo',
    unlocs: ['EGALY'],
    code: '72901',
    id: 'EGALY',
  },
  {
    name: 'Dumyat (Damietta)',
    coordinates: [31.82, 31.42],
    city: 'Dumyat (Damietta)',
    country: 'Egypt',
    alias: [],
    regions: [],
    province: 'Damietta Governorate',
    timezone: 'Africa/Cairo',
    unlocs: ['EGDAM'],
    code: '72912',
    id: 'EGDAM',
  },
  {
    name: 'El Dekheila',
    city: 'El Dekheila',
    country: 'Egypt',
    alias: [],
    regions: [],
    coordinates: [30.7716308, 30.7051537],
    province: 'El Beheira Governorate',
    timezone: 'Africa/Cairo',
    unlocs: ['EGEDK'],
    code: '72901',
    id: 'EGEDK',
  },
  {
    name: 'Port Said',
    city: 'Port Said',
    country: 'Egypt',
    alias: [],
    regions: [],
    coordinates: [32.3018661, 31.2652893],
    province: 'Port Said Governorate',
    timezone: 'Africa/Cairo',
    unlocs: ['EGPSD'],
    code: '72913',
    id: 'EGPSD',
  },
  {
    name: 'Sokhna Port',
    coordinates: [32.35, 29.65],
    city: 'Sokhna',
    country: 'Egypt',
    alias: [],
    regions: [],
    timezone: 'Africa/Cairo',
    unlocs: ['EGSOK'],
    code: '72999',
    id: 'EGSOK',
  },
  {
    name: 'As Suways (Suez)',
    coordinates: [32.52, 29.97],
    city: 'As Suways (Suez)',
    province: 'As Suways',
    country: 'Egypt',
    alias: [],
    regions: [],
    timezone: 'Africa/Cairo',
    unlocs: ['EGSUZ'],
    code: '72949',
    id: 'EGSUZ',
  },
  {
    name: 'Assab',
    coordinates: [42.73, 13],
    city: 'Assab',
    province: 'Debubawi K’eyyih Bahri',
    country: 'Eritrea',
    alias: [],
    regions: [],
    timezone: 'Africa/Asmera',
    unlocs: ['ERASA'],
    code: '77405',
    id: 'ERASA',
  },
  {
    name: 'Massawa (Mitsiwa)',
    coordinates: [39.45, 15.6],
    city: 'Massawa (Mitsiwa)',
    province: 'Semienawi K’eyyih Bahri',
    country: 'Eritrea',
    alias: [],
    regions: [],
    timezone: 'Africa/Asmera',
    unlocs: ['ERMSW'],
    code: '77409',
    id: 'ERMSW',
  },
  {
    name: 'Lanzarote',
    coordinates: [-13.53, 28.97],
    city: 'Lanzarote',
    province: 'Las Palmas',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Atlantic/Canary',
    unlocs: ['ESACE'],
    id: 'ESACE',
  },
  {
    name: 'Malaga',
    coordinates: [-4.42, 36.72],
    city: 'Málaga',
    country: 'Spain',
    alias: [],
    regions: [],
    province: 'Andalusia',
    timezone: 'Europe/Madrid',
    unlocs: ['ESAGP'],
    code: '47076',
    id: 'ESAGP',
  },
  {
    name: 'Alicante',
    city: 'Alicante',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-0.4906855, 38.3459963],
    province: 'Valencian Community',
    timezone: 'Europe/Madrid',
    unlocs: ['ESALC'],
    code: '47057',
    id: 'ESALC',
  },
  {
    name: 'Algeciras',
    city: 'Algeciras',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-5.456233, 36.1407591],
    province: 'Andalusia',
    timezone: 'Europe/Madrid',
    unlocs: ['ESALG'],
    code: '47031',
    id: 'ESALG',
  },
  {
    name: 'Aviles',
    city: 'Aviles',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-5.9246653, 43.5579523],
    province: 'Asturias',
    timezone: 'Europe/Madrid',
    unlocs: ['ESAVS'],
    code: '47003',
    id: 'ESAVS',
  },
  {
    name: 'Barcelona',
    city: 'Barcelona',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [2.1734035, 41.3850639],
    province: 'Catalonia',
    timezone: 'Europe/Madrid',
    unlocs: ['ESBCN'],
    code: '47061',
    id: 'ESBCN',
  },
  {
    name: 'Bilbao',
    coordinates: [-2.97, 43.25],
    city: 'Bilbao',
    province: 'Biskaia',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Europe/Madrid',
    unlocs: ['ESBIO'],
    code: '47005',
    id: 'ESBIO',
  },
  {
    name: 'Bermeo',
    coordinates: [-2.72, 43.43],
    city: 'Bermeo',
    province: 'Biskaia',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Europe/Madrid',
    unlocs: ['ESBRM'],
    id: 'ESBRM',
  },
  {
    name: 'Cadiz',
    city: 'Cadiz',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-6.2885962, 36.5270612],
    province: 'Andalusia',
    timezone: 'Europe/Madrid',
    unlocs: ['ESCAD'],
    code: '47035',
    id: 'ESCAD',
  },
  {
    name: 'Cartagena',
    city: 'Cartagena',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-0.9965839, 37.6256827],
    province: 'Región de Murcia',
    timezone: 'Europe/Madrid',
    unlocs: ['ESCAR'],
    code: '47063',
    id: 'ESCAR',
  },
  {
    name: 'Castellon de la Plana',
    coordinates: [-0.03, 39.98],
    city: 'Castellón de la Plana',
    province: 'Valencia / València',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Europe/Madrid',
    unlocs: ['ESCAS'],
    code: '47068',
    id: 'ESCAS',
  },
  {
    name: 'Ceuta',
    city: 'Ceuta',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-5.3213455, 35.8893874],
    province: 'Ceuta',
    timezone: 'Europe/Madrid',
    unlocs: ['ESCEU'],
    code: '47096',
    id: 'ESCEU',
  },
  {
    name: 'Cebolla',
    city: 'Cebolla',
    province: 'Semienawi K’eyyĭḥ Baḥri',
    country: 'Spain',
    alias: [],
    regions: [],
    unlocs: ['ESEBO'],
    id: 'ESEBO',
  },
  {
    name: 'Ferez',
    coordinates: [-2, 38.35],
    city: 'Ferez',
    province: 'Albacete',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Europe/Madrid',
    unlocs: ['ESFER'],
    id: 'ESFER',
  },
  {
    name: 'Puerto del Rosario-Fuerteventura',
    city: 'Puerto del Rosario-Fuerteventura',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-13.8628367, 28.500821],
    province: 'Canarias',
    timezone: 'Atlantic/Canary',
    unlocs: ['ESFUE'],
    id: 'ESFUE',
  },
  {
    name: 'Gijon',
    coordinates: [-5.67, 43.53],
    city: 'Gijón',
    province: 'Asturias',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Europe/Madrid',
    unlocs: ['ESGIJ'],
    code: '47017',
    id: 'ESGIJ',
  },
  {
    name: 'Huelva',
    city: 'Huelva',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-6.9447224, 37.261421],
    province: 'Andalucía',
    timezone: 'Europe/Madrid',
    unlocs: ['ESHUV'],
    code: '47044',
    id: 'ESHUV',
  },
  {
    name: 'Ibiza',
    coordinates: [1.43, 38.9],
    city: 'Ibiza',
    province: 'Balears [Baleares]',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Europe/Madrid',
    unlocs: ['ESIBZ'],
    code: '47067',
    id: 'ESIBZ',
  },
  {
    name: 'La Coruna (A Coruna)',
    coordinates: [-8.38, 43.37],
    city: 'La Coruña (A Coruña)',
    province: 'A Coruña [La Coruña]',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Europe/Madrid',
    unlocs: ['ESLCG'],
    id: 'ESLCG',
  },
  {
    name: 'Almeria',
    coordinates: [-2.45, 36.83],
    city: 'Almería',
    province: 'Almería',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Europe/Madrid',
    unlocs: ['ESLEI'],
    code: '47059',
    id: 'ESLEI',
  },
  {
    name: 'Las Palmas de Gran Canaria',
    coordinates: [-15.42, 28.1],
    city: 'Las Palmas de Gran Canaria',
    province: 'Las Palmas',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Atlantic/Canary',
    unlocs: ['ESLPA'],
    id: 'ESLPA',
  },
  {
    name: 'Madrid',
    city: 'Madrid',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-3.7037902, 40.4167754],
    province: 'Community of Madrid',
    timezone: 'Europe/Madrid',
    unlocs: ['ESMAD'],
    id: 'ESMAD',
  },
  {
    name: 'Mahon, Menorca',
    coordinates: [4.25, 39.88],
    city: 'Mahón, Menorca',
    province: 'Balears [Baleares]',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Europe/Madrid',
    unlocs: ['ESMAH'],
    id: 'ESMAH',
  },
  {
    name: 'Melilla',
    coordinates: [-2.88, 35.32],
    city: 'Melilla',
    province: 'Melilla',
    country: 'Spain',
    alias: [],
    regions: [],
    unlocs: ['ESMLN'],
    code: '47095',
    id: 'ESMLN',
  },
  {
    name: 'Motril',
    city: 'Motril',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-3.5180363, 36.7489747],
    province: 'Andalucía',
    timezone: 'Europe/Madrid',
    unlocs: ['ESMOT'],
    code: '47077',
    id: 'ESMOT',
  },
  {
    name: 'Marin, Pontevedra',
    city: 'Marin, Pontevedra',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-8.7020824, 42.3914848],
    province: 'Galicia',
    timezone: 'Europe/Madrid',
    unlocs: ['ESMPG'],
    id: 'ESMPG',
  },
  {
    name: 'Palamos',
    coordinates: [3.13, 41.85],
    city: 'Palamós',
    country: 'Spain',
    alias: [],
    regions: [],
    province: 'Catalunya',
    timezone: 'Europe/Madrid',
    unlocs: ['ESPAL'],
    code: '47065',
    id: 'ESPAL',
  },
  {
    name: 'Pasajes',
    city: 'Pasajes',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-1.9301396, 43.32547030000001],
    province: 'Euskadi',
    timezone: 'Europe/Madrid',
    unlocs: ['ESPAS'],
    code: '47027',
    id: 'ESPAS',
  },
  {
    name: 'Palma de Mallorca',
    coordinates: [2.63, 39.57],
    city: 'Palma de Mallorca',
    country: 'Spain',
    alias: [],
    regions: [],
    province: 'Balearic Islands',
    timezone: 'Europe/Madrid',
    unlocs: ['ESPMI'],
    id: 'ESPMI',
  },
  {
    name: 'Santa Cruz de Tenerife',
    city: 'Santa Cruz de Tenerife',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-16.2518467, 28.4636296],
    province: 'Canarias',
    timezone: 'Atlantic/Canary',
    unlocs: ['ESSCT'],
    code: '47073',
    id: 'ESSCT',
  },
  {
    name: 'Santander',
    city: 'Santander',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-3.8099803, 43.46230569999999],
    province: 'Cantabria',
    timezone: 'Europe/Madrid',
    unlocs: ['ESSDR'],
    code: '47013',
    id: 'ESSDR',
  },
  {
    name: 'Santurce',
    coordinates: [-3.03, 43.33],
    city: 'Santurce',
    province: 'Biskaia',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Europe/Madrid',
    unlocs: ['ESSNR'],
    id: 'ESSNR',
  },
  {
    name: 'Santa Cruz de La Palma',
    coordinates: [-17.77, 28.68],
    city: 'Santa Cruz de La Palma',
    province: 'Las Palmas',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Atlantic/Canary',
    unlocs: ['ESSPC'],
    code: '47072',
    id: 'ESSPC',
  },
  {
    name: 'Sevilla',
    city: 'Sevilla',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-5.9844589, 37.3890924],
    province: 'Andalusia',
    timezone: 'Europe/Madrid',
    unlocs: ['ESSVQ'],
    code: '47039',
    id: 'ESSVQ',
  },
  {
    name: 'Tarragona',
    city: 'Tarragona',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [1.2444909, 41.1188827],
    province: 'Catalonia',
    timezone: 'Europe/Madrid',
    unlocs: ['ESTAR'],
    code: '47092',
    id: 'ESTAR',
  },
  {
    timezone: 'Europe/Madrid',
    coordinates: [-1.5432601, 41.6949364],
    name: 'Tabuenca',
    city: 'Tabuenca',
    province: 'Zaragoza',
    country: 'Spain',
    alias: [],
    regions: [],
    unlocs: ['ESTBZ'],
    id: 'ESTBZ',
  },
  {
    name: 'Tenerife',
    coordinates: [-16.57, 28.32],
    city: 'Tenerife',
    province: 'Las Palmas',
    country: 'Spain',
    alias: [],
    regions: [],
    timezone: 'Atlantic/Canary',
    unlocs: ['ESTCI'],
    code: '47073',
    id: 'ESTCI',
  },
  {
    name: 'Tortosa',
    city: 'Tortosa',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [0.5214423, 40.8125777],
    province: 'Catalonia',
    timezone: 'Europe/Madrid',
    unlocs: ['ESTOT'],
    id: 'ESTOT',
  },
  {
    name: 'Vigo',
    city: 'Vigo',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-8.7207268, 42.24059889999999],
    province: 'Galicia',
    timezone: 'Europe/Madrid',
    unlocs: ['ESVGO'],
    code: '47029',
    id: 'ESVGO',
  },
  {
    name: 'Valencia',
    city: 'Valencia',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-0.3762881, 39.4699075],
    province: 'Valencian Community',
    timezone: 'Europe/Madrid',
    unlocs: ['ESVLC'],
    code: '47094',
    id: 'ESVLC',
  },
  {
    name: 'Zaragoza',
    city: 'Zaragoza',
    country: 'Spain',
    alias: [],
    regions: [],
    coordinates: [-0.8890853, 41.6488226],
    province: 'Aragon',
    timezone: 'Europe/Madrid',
    unlocs: ['ESZAZ'],
    id: 'ESZAZ',
  },
  {
    name: 'Helsingfors (Helsinki)',
    city: 'Helsingfors (Helsinki)',
    province: 'Uusimaa',
    country: 'Finland',
    alias: [],
    regions: [],
    coordinates: [24.9410248, 60.17332440000001],
    timezone: 'Europe/Helsinki',
    unlocs: ['FIHEL'],
    code: '40525',
    id: 'FIHEL',
  },
  {
    name: 'Hango (Hanko)',
    city: 'Hangö (Hanko)',
    province: 'Uusimaa',
    country: 'Finland',
    alias: [],
    regions: [],
    coordinates: [22.9658562, 59.8283461],
    timezone: 'Europe/Helsinki',
    unlocs: ['FIHKO'],
    code: '40523',
    id: 'FIHKO',
  },
  {
    name: 'Fredrikshamn (Hamina)',
    coordinates: [27.2, 60.57],
    city: 'Fredrikshamn (Hamina)',
    province: 'Kymenlaakso',
    country: 'Finland',
    alias: [],
    regions: [],
    timezone: 'Europe/Helsinki',
    unlocs: ['FIHMN'],
    code: '40520',
    id: 'FIHMN',
  },
  {
    name: 'Kemi/Tornea (Kemi/Tornio)',
    coordinates: [24.57, 65.73],
    city: 'Kemi/Torneå (Kemi/Tornio)',
    province: 'Lappi',
    country: 'Finland',
    alias: [],
    regions: [],
    timezone: 'Europe/Helsinki',
    unlocs: ['FIKEM'],
    code: '40543',
    id: 'FIKEM',
  },
  {
    name: 'Karleby (Kokkola)',
    coordinates: [23.12, 63.83],
    city: 'Karleby (Kokkola)',
    province: 'Keski-Pohjanmaa',
    country: 'Finland',
    alias: [],
    regions: [],
    timezone: 'Europe/Helsinki',
    unlocs: ['FIKOK'],
    code: '40500',
    id: 'FIKOK',
  },
  {
    name: 'Kotka',
    coordinates: [26.92, 60.47],
    city: 'Kotka',
    province: 'Kymenlaakso',
    country: 'Finland',
    alias: [],
    regions: [],
    timezone: 'Europe/Helsinki',
    unlocs: ['FIKTK'],
    code: '40549',
    id: 'FIKTK',
  },
  {
    name: 'Mantyluoto',
    city: 'Mantyluoto',
    country: 'Finland',
    alias: [],
    regions: [],
    coordinates: [21.4822772, 61.59187739999999],
    timezone: 'Europe/Helsinki',
    unlocs: ['FIMTL'],
    code: '40551',
    id: 'FIMTL',
  },
  {
    name: 'Oulu',
    city: 'Oulu',
    country: 'Finland',
    alias: [],
    regions: [],
    coordinates: [25.4714526, 65.0126148],
    province: 'Northern Ostrobothnia',
    timezone: 'Europe/Helsinki',
    unlocs: ['FIOLU'],
    code: '40531',
    id: 'FIOLU',
  },
  {
    name: 'Oulu (Uleaborg)',
    coordinates: [25.47, 65.02],
    city: 'Oulu (Uleåborg)',
    province: 'Pohjois-Pohjanmaa',
    country: 'Finland',
    alias: [],
    regions: [],
    timezone: 'Europe/Helsinki',
    unlocs: ['FIOUL'],
    code: '40531',
    id: 'FIOUL',
  },
  {
    name: 'Bjorneborg (Pori)',
    coordinates: [21.8, 61.48],
    city: 'Björneborg (Pori)',
    province: 'Satakunta',
    country: 'Finland',
    alias: [],
    regions: [],
    timezone: 'Europe/Helsinki',
    unlocs: ['FIPOR'],
    code: '40562',
    id: 'FIPOR',
  },
  {
    name: 'Rauma (Raumo)',
    coordinates: [21.5, 61.13],
    city: 'Rauma (Raumo)',
    province: 'Satakunta',
    country: 'Finland',
    alias: [],
    regions: [],
    timezone: 'Europe/Helsinki',
    unlocs: ['FIRAU'],
    code: '40571',
    id: 'FIRAU',
  },
  {
    name: 'Abo (Turku)',
    coordinates: [22.28, 60.45],
    city: 'Åbo (Turku)',
    province: 'Varsinais-Suomi',
    country: 'Finland',
    alias: [],
    regions: [],
    timezone: 'Europe/Helsinki',
    unlocs: ['FITKU'],
    code: '40587',
    id: 'FITKU',
  },
  {
    name: 'Tornea (Tornio)',
    coordinates: [24.18, 65.85],
    city: 'Torneå (Tornio)',
    province: 'Lappi',
    country: 'Finland',
    alias: [],
    regions: [],
    timezone: 'Europe/Helsinki',
    unlocs: ['FITOR'],
    code: '40500',
    id: 'FITOR',
  },
  {
    name: 'Lautoka',
    coordinates: [177.45, -17.62],
    city: 'Lautoka',
    country: 'Fiji',
    alias: [],
    regions: [],
    province: 'Western Division',
    timezone: 'Pacific/Fiji',
    unlocs: ['FJLTK'],
    code: '68630',
    id: 'FJLTK',
  },
  {
    name: 'Suva',
    city: 'Suva',
    country: 'Fiji',
    alias: [],
    regions: [],
    coordinates: [178.4500789, -18.1248086],
    province: 'Central Division',
    timezone: 'Pacific/Fiji',
    unlocs: ['FJSUV'],
    code: '68628',
    id: 'FJSUV',
  },
  {
    name: 'Port Stanley',
    city: 'Port Stanley',
    country: 'Falkland Islands (Malvinas)',
    alias: [],
    regions: [],
    coordinates: [-57.85166269999999, -51.6977129],
    province: 'East Falkland',
    timezone: 'Atlantic/Stanley',
    unlocs: ['FKPSY'],
    code: '37200',
    id: 'FKPSY',
  },
  {
    name: 'Pohnpei (ex Ponape)',
    city: 'Pohnpei (ex Ponape)',
    country: 'Micronesia, Federated States of',
    alias: [],
    regions: [],
    coordinates: [158.2623822, 6.8541254],
    province: 'Pohnpei',
    timezone: 'Pacific/Ponape',
    unlocs: ['FMPNI'],
    code: '68207',
    id: 'FMPNI',
  },
  {
    name: 'Thorshavn',
    coordinates: [-6.77, 62.02],
    city: 'Thorshavn',
    country: 'Faroe Islands',
    alias: [],
    regions: [],
    timezone: 'Atlantic/Faeroe',
    unlocs: ['FOTHO'],
    code: '40985',
    id: 'FOTHO',
  },
  {
    name: 'Bassens',
    city: 'Bassens',
    province: 'Gironde',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [-0.516238, 44.899835],
    timezone: 'Europe/Paris',
    unlocs: ['FRBAS'],
    code: '42701',
    id: 'FRBAS',
  },
  {
    name: 'Bayonne',
    city: 'Bayonne',
    province: 'Pyrénées-Atlantiques',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [-1.474841, 43.492949],
    timezone: 'Europe/Paris',
    unlocs: ['FRBAY'],
    code: '42702',
    id: 'FRBAY',
  },
  {
    name: 'Brest',
    coordinates: [-4.48, 48.4],
    city: 'Brest',
    province: 'Finistère',
    country: 'France',
    alias: [],
    regions: [],
    timezone: 'Europe/Paris',
    unlocs: ['FRBES'],
    code: '42709',
    id: 'FRBES',
  },
  {
    name: 'Bordeaux',
    city: 'Bordeaux',
    province: 'Gironde',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [-0.57918, 44.837789],
    timezone: 'Europe/Paris',
    unlocs: ['FRBOD'],
    code: '42707',
    id: 'FRBOD',
  },
  {
    name: 'Boulogne-sur-Mer',
    coordinates: [1.62, 50.72],
    city: 'Boulogne-sur-Mer',
    province: 'Pas-de-Calais',
    country: 'France',
    alias: [],
    regions: [],
    timezone: 'Europe/Paris',
    unlocs: ['FRBOL'],
    code: '42708',
    id: 'FRBOL',
  },
  {
    name: 'Calais',
    city: 'Calais',
    province: 'Pas-de-Calais',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [1.858686, 50.95129000000001],
    timezone: 'Europe/Paris',
    unlocs: ['FRCQF'],
    code: '42713',
    id: 'FRCQF',
  },
  {
    name: 'Dunkerque',
    city: 'Dunkerque',
    province: 'Nord',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [2.3767763, 51.0343684],
    timezone: 'Europe/Paris',
    unlocs: ['FRDKK'],
    code: '42727',
    id: 'FRDKK',
  },
  {
    name: 'Dieppe',
    city: 'Dieppe',
    province: 'Seine-Maritime',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [1.077483, 49.922992],
    timezone: 'Europe/Paris',
    unlocs: ['FRDPE'],
    code: '42736',
    id: 'FRDPE',
  },
  {
    name: 'Fos-sur-Mer',
    coordinates: [4.93, 43.43],
    city: 'Fos-sur-Mer',
    province: 'Bouches-du-Rhône',
    country: 'France',
    alias: [],
    regions: [],
    timezone: 'Europe/Paris',
    unlocs: ['FRFOS'],
    code: '42776',
    id: 'FRFOS',
  },
  {
    timezone: 'Europe/Paris',
    coordinates: [3.702397, 47.762686],
    name: 'Chitry',
    city: 'Chitry',
    province: 'Yonne',
    country: 'France',
    alias: [],
    regions: [],
    unlocs: ['FRHGT'],
    code: '42700',
    id: 'FRHGT',
  },
  {
    timezone: 'Europe/Paris',
    coordinates: [5.1307681, 46.2475706],
    name: 'Honfleur',
    city: 'Honfleur',
    province: 'Ain',
    country: 'France',
    alias: [],
    regions: [],
    unlocs: ['FRHON'],
    code: '42700',
    id: 'FRHON',
  },
  {
    timezone: 'Europe/Paris',
    coordinates: [5.1307681, 46.2475706],
    name: 'Javrezac',
    city: 'Javrezac',
    province: 'Ain',
    country: 'France',
    alias: [],
    regions: [],
    unlocs: ['FRJAV'],
    code: '42700',
    id: 'FRJAV',
  },
  {
    name: 'La Ciotat',
    city: 'La Ciotat',
    province: 'Bouches-du-Rhône',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [5.605155, 43.17365299999999],
    timezone: 'Europe/Paris',
    unlocs: ['FRLCT'],
    code: '42799',
    id: 'FRLCT',
  },
  {
    name: 'Le Havre',
    city: 'Le Havre',
    province: 'Seine-Maritime',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [0.107929, 49.49437],
    timezone: 'Europe/Paris',
    unlocs: ['FRLEH'],
    code: '42737',
    id: 'FRLEH',
  },
  {
    name: 'La Planche',
    coordinates: [-1.43, 47],
    city: 'La Planche',
    province: 'Loire-Atlantique',
    country: 'France',
    alias: [],
    regions: [],
    timezone: 'Europe/Paris',
    unlocs: ['FRLPC'],
    code: '42799',
    id: 'FRLPC',
  },
  {
    name: 'La Pallice',
    city: 'La Pallice',
    province: 'Charente-Maritime',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [-1.205105, 46.1625749],
    timezone: 'Europe/Rome',
    unlocs: ['FRLPE'],
    code: '42733',
    id: 'FRLPE',
  },
  {
    name: 'La Rochelle',
    city: 'La Rochelle',
    province: 'Charente-Maritime',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [-1.151139, 46.160329],
    timezone: 'Europe/Paris',
    unlocs: ['FRLRH'],
    code: '42700',
    id: 'FRLRH',
  },
  {
    name: 'Lorient',
    city: 'Lorient',
    province: 'Morbihan',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [-3.3702449, 47.7482524],
    timezone: 'Europe/Paris',
    unlocs: ['FRLRT'],
    code: '42743',
    id: 'FRLRT',
  },
  {
    name: 'Le Treport',
    city: 'Le Tréport',
    province: 'Seine-Maritime',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [1.382472, 50.05916999999999],
    timezone: 'Europe/Paris',
    unlocs: ['FRLTR'],
    code: '42700',
    id: 'FRLTR',
  },
  {
    name: 'Le Verdon-sur-Mer',
    coordinates: [-1.07, 45.55],
    city: 'Le Verdon-sur-Mer',
    province: 'Gironde',
    country: 'France',
    alias: [],
    regions: [],
    timezone: 'Europe/Paris',
    unlocs: ['FRLVE'],
    code: '42700',
    id: 'FRLVE',
  },
  {
    timezone: 'Europe/Paris',
    coordinates: [7.7144412, 48.6434829],
    name: 'Mundolsheim',
    city: 'Mundolsheim',
    province: 'Bas-Rhin',
    country: 'France',
    alias: [],
    regions: [],
    unlocs: ['FRMDH'],
    code: '42700',
    id: 'FRMDH',
  },
  {
    name: 'Marseille',
    coordinates: [5.4, 43.3],
    city: 'Marseille',
    province: 'Bouches-du-Rhône',
    country: 'France',
    alias: [],
    regions: [],
    timezone: 'Europe/Paris',
    unlocs: ['FRMRS'],
    code: '42782',
    id: 'FRMRS',
  },
  {
    timezone: 'Europe/Paris',
    coordinates: [0.2576879, 44.622205],
    name: 'Monteton',
    city: 'Monteton',
    province: 'Lot-et-Garonne',
    country: 'France',
    alias: [],
    regions: [],
    unlocs: ['FRMT7'],
    id: 'FRMT7',
  },
  {
    name: 'Montoir-de-Bretagne',
    coordinates: [-2.15, 47.33],
    city: 'Montoir-de-Bretagne',
    province: 'Loire-Atlantique',
    country: 'France',
    alias: [],
    regions: [],
    timezone: 'Europe/Paris',
    unlocs: ['FRMTX'],
    code: '42745',
    id: 'FRMTX',
  },
  {
    name: 'Nantes',
    city: 'Nantes',
    province: 'Loire-Atlantique',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [-1.553621, 47.218371],
    timezone: 'Europe/Paris',
    unlocs: ['FRNTE'],
    code: '42749',
    id: 'FRNTE',
  },
  {
    timezone: 'Europe/Paris',
    coordinates: [3.681767, 50.078788],
    name: 'La Groise',
    city: 'La Groise',
    province: 'Nord',
    country: 'France',
    alias: [],
    regions: [],
    unlocs: ['FROR6'],
    code: '42700',
    id: 'FROR6',
  },
  {
    name: 'Paris',
    city: 'Paris',
    province: 'Paris',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [2.3522219, 48.856614],
    timezone: 'Europe/Paris',
    unlocs: ['FRPAR'],
    code: '42700',
    id: 'FRPAR',
  },
  {
    name: 'Piriac-sur-Mer',
    coordinates: [-2.55, 47.38],
    city: 'Piriac-sur-Mer',
    province: 'Loire-Atlantique',
    country: 'France',
    alias: [],
    regions: [],
    timezone: 'Europe/Paris',
    unlocs: ['FRPIR'],
    code: '42700',
    id: 'FRPIR',
  },
  {
    name: 'Port-Vendres',
    coordinates: [3.12, 42.52],
    city: 'Port-Vendres',
    province: 'Pyrénées-Orientales',
    country: 'France',
    alias: [],
    regions: [],
    timezone: 'Europe/Paris',
    unlocs: ['FRPOV'],
    code: '42799',
    id: 'FRPOV',
  },
  {
    name: 'Sete',
    city: 'Sète',
    province: 'Hérault',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [3.7008219, 43.4078758],
    timezone: 'Europe/Paris',
    unlocs: ['FRSET'],
    code: '42789',
    id: 'FRSET',
  },
  {
    name: 'Saint-Nazaire',
    coordinates: [-2.2, 47.28],
    city: 'Saint-Nazaire',
    province: 'Loire-Atlantique',
    country: 'France',
    alias: [],
    regions: [],
    timezone: 'Europe/Paris',
    unlocs: ['FRSNR'],
    code: '42762',
    id: 'FRSNR',
  },
  {
    name: 'Toulon',
    city: 'Toulon',
    province: 'Var',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [5.928, 43.124228],
    timezone: 'Europe/Paris',
    unlocs: ['FRTLN'],
    code: '42790',
    id: 'FRTLN',
  },
  {
    name: 'Rouen',
    city: 'Rouen',
    province: 'Seine-Maritime',
    country: 'France',
    alias: [],
    regions: [],
    coordinates: [1.099971, 49.44323199999999],
    timezone: 'Europe/Paris',
    unlocs: ['FRURO'],
    code: '42759',
    id: 'FRURO',
  },
  {
    timezone: 'Europe/Paris',
    coordinates: [5.2418509, 43.951727],
    name: 'Mus',
    city: 'Mus',
    province: 'Vaucluse',
    country: 'France',
    alias: [],
    regions: [],
    unlocs: ['FRUSJ'],
    code: '42799',
    id: 'FRUSJ',
  },
  {
    name: 'Cap Lopez',
    city: 'Cap Lopez',
    country: 'Gabon',
    alias: [],
    regions: [],
    coordinates: [8.7811645, -0.7726462],
    timezone: 'Africa/Libreville',
    unlocs: ['GACLZ'],
    code: '75505',
    id: 'GACLZ',
  },
  {
    name: 'Libreville',
    city: 'Libreville',
    country: 'Gabon',
    alias: [],
    regions: [],
    coordinates: [9.4672676, 0.4161976],
    province: 'Estuaire',
    timezone: 'Africa/Libreville',
    unlocs: ['GALBV'],
    code: '75501',
    id: 'GALBV',
  },
  {
    name: 'Owendo',
    city: 'Owendo',
    country: 'Gabon',
    alias: [],
    regions: [],
    coordinates: [9.483282599999999, 0.3379564],
    province: 'Estuaire',
    timezone: 'Africa/Libreville',
    unlocs: ['GAOWE'],
    code: '75521',
    id: 'GAOWE',
  },
  {
    name: 'Port Gentil',
    city: 'Port Gentil',
    country: 'Gabon',
    alias: [],
    regions: [],
    coordinates: [8.7591311, -0.7351025999999999],
    province: 'Ogooue-Maritime',
    timezone: 'Africa/Libreville',
    unlocs: ['GAPOG'],
    code: '75525',
    id: 'GAPOG',
  },
  {
    name: 'Aberdeen',
    coordinates: [-2.1, 57.13],
    city: 'Aberdeen',
    province: 'Aberdeen City',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBABD'],
    code: '41650',
    id: 'GBABD',
  },
  {
    name: 'Avonmouth',
    coordinates: [-2.68, 51.5],
    city: 'Avonmouth',
    province: 'Bristol, City of',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBAVO'],
    code: '41201',
    id: 'GBAVO',
  },
  {
    name: 'Barry Dock',
    coordinates: [-3.25, 51.38],
    city: 'Barry Dock',
    province: 'Vale of Glamorgan, The [Bro Morgannwg GB-BMG]',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Asia/Tehran',
    unlocs: ['GBBAD'],
    id: 'GBBAD',
  },
  {
    name: 'Broadstairs',
    coordinates: [1.42, 51.37],
    city: 'Broadstairs',
    province: 'Kent',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBBDT'],
    id: 'GBBDT',
  },
  {
    name: 'Belfast',
    coordinates: [-5.92, 54.98],
    city: 'Belfast',
    province: 'Belfast',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBBEL'],
    code: '41801',
    id: 'GBBEL',
  },
  {
    name: 'Burnham on Crouch',
    coordinates: [0.83, 51.62],
    city: 'Burnham on Crouch',
    province: 'Essex',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBBOC'],
    id: 'GBBOC',
  },
  {
    name: 'Bootle',
    coordinates: [-3.02, 53.47],
    city: 'Bootle',
    province: 'Sefton',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBBOE'],
    id: 'GBBOE',
  },
  {
    name: 'Boston',
    city: 'Boston',
    province: 'Lincolnshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-0.026577, 52.97893999999999],
    timezone: 'Europe/London',
    unlocs: ['GBBOS'],
    code: '41398',
    id: 'GBBOS',
  },
  {
    name: 'Birkenhead',
    coordinates: [-3.03, 53.4],
    city: 'Birkenhead',
    province: 'Wirral',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBBRK'],
    code: '41207',
    id: 'GBBRK',
  },
  {
    name: 'Bristol',
    city: 'Bristol',
    province: 'Bristol, City of',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-2.58791, 51.454513],
    timezone: 'Europe/London',
    unlocs: ['GBBRS'],
    code: '41211',
    id: 'GBBRS',
  },
  {
    name: 'Bridlington',
    coordinates: [-0.2, 54.08],
    city: 'Bridlington',
    province: 'East Riding of Yorkshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBBRT'],
    id: 'GBBRT',
  },
  {
    name: 'Bridgwater',
    city: 'Bridgwater',
    province: 'Somerset',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-3.003632, 51.127889],
    timezone: 'Europe/London',
    unlocs: ['GBBRW'],
    id: 'GBBRW',
  },
  {
    name: 'Cardiff',
    coordinates: [-3.2, 51.5],
    city: 'Cardiff',
    province: 'Cardiff [Caerdydd GB-CRD]',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBCDF'],
    code: '41487',
    id: 'GBCDF',
  },
  {
    name: 'Carrickfergus',
    city: 'Carrickfergus',
    province: 'Carrickfergus',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-5.8101207, 54.7261871],
    timezone: 'Europe/London',
    unlocs: ['GBCFG'],
    id: 'GBCFG',
  },
  {
    timezone: 'Europe/London',
    coordinates: [-3.0063019, 56.599797],
    name: 'Charleston',
    city: 'Charleston',
    province: 'Angus',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    unlocs: ['GBCHD'],
    id: 'GBCHD',
  },
  {
    name: 'Cairnryan',
    coordinates: [-5.02, 54.97],
    city: 'Cairnryan',
    province: 'Dumfries and Galloway',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBCYN'],
    id: 'GBCYN',
  },
  {
    name: 'Dundee',
    coordinates: [-2.97, 56.5],
    city: 'Dundee',
    province: 'Dundee City',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBDUN'],
    code: '41659',
    id: 'GBDUN',
  },
  {
    name: 'Dover',
    city: 'Dover',
    province: 'Kent',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [1.3134027, 51.1278758],
    timezone: 'Europe/London',
    unlocs: ['GBDVR'],
    code: '41337',
    id: 'GBDVR',
  },
  {
    name: 'Edinburgh',
    coordinates: [-3.2, 55.95],
    city: 'Edinburgh',
    province: 'Edinburgh, City of',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBEDI'],
    code: '41675',
    id: 'GBEDI',
  },
  {
    name: 'Sheildaig',
    city: 'Sheildaig',
    province: 'Woleu-Ntem',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    unlocs: ['GBEIL'],
    id: 'GBEIL',
  },
  {
    name: 'Ellesmere Port',
    city: 'Ellesmere',
    province: 'Cheshire West and Chester',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-2.8873982, 53.2068281],
    timezone: 'Europe/London',
    unlocs: ['GBELL'],
    code: '41227',
    id: 'GBELL',
  },
  {
    timezone: 'Europe/London',
    coordinates: [-3.0718734, 50.70531339999999],
    name: 'Seaton',
    city: 'Seaton',
    province: 'Devon',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    unlocs: ['GBEON'],
    id: 'GBEON',
  },
  {
    name: 'Falmouth',
    city: 'Falmouth',
    province: 'Cornwall',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-5.066269999999999, 50.15257099999999],
    timezone: 'Europe/London',
    unlocs: ['GBFAL'],
    code: '41328',
    id: 'GBFAL',
  },
  {
    name: 'Fawley',
    city: 'Fawley',
    province: 'Hampshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-1.3502269, 50.8268139],
    timezone: 'Europe/London',
    unlocs: ['GBFAW'],
    code: '41329',
    id: 'GBFAW',
  },
  {
    name: 'Fraserburgh',
    coordinates: [-2.02, 57.68],
    city: 'Fraserburgh',
    province: 'Aberdeenshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBFRB'],
    code: '41698',
    id: 'GBFRB',
  },
  {
    name: 'Felixstowe',
    city: 'Felixstowe',
    province: 'Suffolk',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [1.351255, 51.961726],
    timezone: 'Europe/London',
    unlocs: ['GBFXT'],
    code: '41323',
    id: 'GBFXT',
  },
  {
    name: 'Guernsey',
    city: 'Guernsey',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-2.58949, 49.4481982],
    timezone: 'Europe/Guernsey',
    unlocs: ['GBGCI'],
    id: 'GBGCI',
  },
  {
    name: 'Glasgow',
    coordinates: [-4.25, 55.83],
    city: 'Glasgow',
    province: 'Glasgow City',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBGLW'],
    code: '41623',
    id: 'GBGLW',
  },
  {
    name: 'Goole',
    coordinates: [-0.87, 53.72],
    city: 'Goole',
    province: 'East Riding of Yorkshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBGOO'],
    code: '41335',
    id: 'GBGOO',
  },
  {
    name: 'Grangemouth',
    coordinates: [-3.72, 56],
    city: 'Grangemouth',
    province: 'Falkirk',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBGRG'],
    code: '41665',
    id: 'GBGRG',
  },
  {
    name: 'Greenock',
    coordinates: [-4.75, 55.93],
    city: 'Greenock',
    province: 'Inverclyde',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBGRK'],
    code: '41625',
    id: 'GBGRK',
  },
  {
    name: 'Grimsby',
    coordinates: [-0.5, 53.53],
    city: 'Grimsby',
    province: 'North East Lincolnshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBGSY'],
    code: '41398',
    id: 'GBGSY',
  },
  {
    name: 'Great Yarmouth',
    city: 'Great Yarmouth',
    province: 'Norfolk',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [1.728047, 52.598233],
    timezone: 'Europe/London',
    unlocs: ['GBGTY'],
    code: '41398',
    id: 'GBGTY',
  },
  {
    name: 'Gravesend',
    city: 'Gravesend',
    province: 'Kent',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [0.370759, 51.44188399999999],
    timezone: 'Europe/London',
    unlocs: ['GBGVS'],
    code: '41398',
    id: 'GBGVS',
  },
  {
    name: 'Harwich',
    city: 'Harwich',
    province: 'Essex',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [1.260297, 51.934731],
    timezone: 'Europe/London',
    unlocs: ['GBHRW'],
    code: '41398',
    id: 'GBHRW',
  },
  {
    name: 'Hull',
    coordinates: [-0.32, 53.73],
    city: 'Hull',
    province: 'East Riding of Yorkshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBHUL'],
    code: '41341',
    id: 'GBHUL',
  },
  {
    name: 'Immingham',
    coordinates: [-0.22, 53.6],
    city: 'Immingham',
    province: 'North East Lincolnshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBIMM'],
    code: '41342',
    id: 'GBIMM',
  },
  {
    name: 'Inverkeithing',
    city: 'Inverkeithing',
    province: 'Fife',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-3.398795, 56.030043],
    timezone: 'Europe/London',
    unlocs: ['GBINK'],
    code: '41698',
    id: 'GBINK',
  },
  {
    name: 'Inverness',
    city: 'Inverness',
    province: 'Highland',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-4.224721, 57.477773],
    timezone: 'Europe/London',
    unlocs: ['GBINV'],
    code: '41683',
    id: 'GBINV',
  },
  {
    name: 'Ipswich',
    city: 'Ipswich',
    province: 'Suffolk',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [1.14822, 52.056736],
    timezone: 'Europe/London',
    unlocs: ['GBIPS'],
    code: '41398',
    id: 'GBIPS',
  },
  {
    name: 'Irlam',
    coordinates: [-2.42, 53.43],
    city: 'Irlam',
    province: 'Trafford',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBIRL'],
    id: 'GBIRL',
  },
  {
    name: "King's Lynn",
    coordinates: [0.38, 52.75],
    city: "King's Lynn",
    province: 'Norfolk',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBKLN'],
    code: '41398',
    id: 'GBKLN',
  },
  {
    name: 'Lancaster',
    city: 'Lancaster',
    province: 'Lancashire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-2.8007399, 54.046575],
    timezone: 'Europe/London',
    unlocs: ['GBLAN'],
    id: 'GBLAN',
  },
  {
    name: 'London Gateway Port',
    coordinates: [0.48, 51.5],
    city: 'London Gateway',
    province: 'Essex',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBLGP'],
    id: 'GBLGP',
  },
  {
    name: 'Liverpool',
    coordinates: [-3, 53.42],
    city: 'Liverpool',
    province: 'Liverpool',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBLIV'],
    code: '41251',
    id: 'GBLIV',
  },
  {
    name: 'London',
    city: 'London',
    province: 'London, City of',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-0.1277583, 51.5073509],
    timezone: 'Europe/London',
    unlocs: ['GBLON'],
    code: '41352',
    id: 'GBLON',
  },
  {
    name: 'Maldon',
    city: 'Maldon',
    province: 'Essex',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [0.6714479999999999, 51.73180499999999],
    timezone: 'Europe/London',
    unlocs: ['GBMAL'],
    id: 'GBMAL',
  },
  {
    name: 'Macduff',
    coordinates: [-2.48, 57.67],
    city: 'Macduff',
    province: 'Aberdeenshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBMCD'],
    id: 'GBMCD',
  },
  {
    name: 'Middlesbrough',
    coordinates: [-1.15, 54.57],
    city: 'Middlesbrough',
    province: 'Redcar and Cleveland',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBMID'],
    code: '41355',
    id: 'GBMID',
  },
  {
    timezone: 'Europe/London',
    coordinates: [-3.3108075, 58.9163522],
    name: 'Moaness, Hoy',
    city: 'Moaness, Hoy',
    province: 'Orkney Islands',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    unlocs: ['GBMNS'],
    id: 'GBMNS',
  },
  {
    name: 'Montrose',
    coordinates: [-2.45, 56.7],
    city: 'Montrose',
    province: 'Angus',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBMON'],
    code: '41681',
    id: 'GBMON',
  },
  {
    name: 'Maryport',
    city: 'Maryport',
    province: 'Cumbria',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-3.49494, 54.714441],
    timezone: 'Europe/London',
    unlocs: ['GBMRY'],
    id: 'GBMRY',
  },
  {
    name: 'Newcastle upon Tyne',
    coordinates: [-1.6, 54.97],
    city: 'Newcastle upon Tyne',
    province: 'Newcastle upon Tyne',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBNCL'],
    id: 'GBNCL',
  },
  {
    name: 'Northfleet',
    city: 'Northfleet',
    province: 'Kent',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [0.33694, 51.44107200000001],
    timezone: 'Europe/London',
    unlocs: ['GBNFT'],
    id: 'GBNFT',
  },
  {
    name: 'Newhaven',
    city: 'Newhaven',
    province: 'East Sussex',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [0.045574, 50.79307000000001],
    timezone: 'Europe/London',
    unlocs: ['GBNHV'],
    code: '41357',
    id: 'GBNHV',
  },
  {
    name: 'Nelson',
    city: 'Nelson',
    province: 'Lancashire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-2.219377, 53.835187],
    timezone: 'Europe/London',
    unlocs: ['GBNLN'],
    id: 'GBNLN',
  },
  {
    name: 'Newport',
    city: 'Newport',
    province: 'Blaenau Gwent',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-3.0305937, 51.581186],
    timezone: 'Europe/London',
    unlocs: ['GBNPT'],
    code: '41258',
    id: 'GBNPT',
  },
  {
    name: 'Pen-Clawdd',
    city: 'Pen-Clawdd',
    province: 'Swansea [Abertawe GB-ATA]',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    unlocs: ['GBPDD'],
    id: 'GBPDD',
  },
  {
    name: 'Purfleet',
    city: 'Purfleet',
    province: 'Essex',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [0.2433122, 51.48671059999999],
    timezone: 'Europe/London',
    unlocs: ['GBPFT'],
    code: '41398',
    id: 'GBPFT',
  },
  {
    name: 'Plymouth',
    coordinates: [-4.12, 50.4],
    city: 'Plymouth',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    province: 'England',
    timezone: 'Europe/London',
    unlocs: ['GBPLY'],
    code: '41360',
    id: 'GBPLY',
  },
  {
    name: 'Portsmouth',
    city: 'Portsmouth',
    province: 'Hampshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-1.0879769, 50.8197675],
    timezone: 'Europe/London',
    unlocs: ['GBPME'],
    code: '41398',
    id: 'GBPME',
  },
  {
    name: 'Poole',
    city: 'Poole',
    province: 'Dorset',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-1.987248, 50.71505],
    timezone: 'Europe/London',
    unlocs: ['GBPOO'],
    code: '41398',
    id: 'GBPOO',
  },
  {
    name: 'Preston',
    coordinates: [-2.72, 53.75],
    city: 'Preston',
    province: 'Lancashire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBPRE'],
    code: '41264',
    id: 'GBPRE',
  },
  {
    name: 'Portbury',
    coordinates: [-2.72, 51.47],
    city: 'Portbury',
    province: 'North Somerset',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBPRU'],
    code: '41262',
    id: 'GBPRU',
  },
  {
    name: 'Parkeston Quay',
    city: 'Parkeston Quay',
    province: 'Essex',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [1.255846, 51.94448],
    timezone: 'Europe/London',
    unlocs: ['GBPST'],
    id: 'GBPST',
  },
  {
    name: 'Queenborough',
    city: 'Queenborough',
    province: 'Kent',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [0.7470207, 51.4172853],
    timezone: 'Europe/London',
    unlocs: ['GBQUB'],
    id: 'GBQUB',
  },
  {
    name: 'Rye',
    city: 'Rye',
    province: 'East Sussex',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [0.7372599999999999, 50.949708],
    timezone: 'Europe/London',
    unlocs: ['GBRYE'],
    id: 'GBRYE',
  },
  {
    name: 'Scarborough',
    city: 'Scarborough',
    province: 'North Yorkshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-0.399752, 54.283113],
    timezone: 'Europe/London',
    unlocs: ['GBSCA'],
    code: '27451',
    id: 'GBSCA',
  },
  {
    name: 'Sheerness',
    city: 'Sheerness',
    province: 'Kent',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [0.764158, 51.44011],
    timezone: 'Europe/London',
    unlocs: ['GBSHS'],
    code: '41370',
    id: 'GBSHS',
  },
  {
    name: 'Southampton',
    city: 'Southampton',
    province: 'Hampshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-1.4043509, 50.90970040000001],
    timezone: 'Europe/London',
    unlocs: ['GBSOU'],
    id: 'GBSOU',
  },
  {
    name: 'Stanlow',
    coordinates: [-2.3, 52.58],
    city: 'Stanlow',
    province: 'Shropshire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBSOW'],
    code: '41227',
    id: 'GBSOW',
  },
  {
    name: 'South Shields',
    coordinates: [-1.42, 54.98],
    city: 'South Shields',
    province: 'South Tyneside',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBSSH'],
    id: 'GBSSH',
  },
  {
    name: 'Sunderland',
    coordinates: [-1.38, 54.9],
    city: 'Sunderland',
    province: 'Sunderland',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBSUN'],
    code: '41376',
    id: 'GBSUN',
  },
  {
    name: 'Silvertown',
    coordinates: [0.03, 51.5],
    city: 'Silvertown',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    province: 'England',
    timezone: 'Europe/London',
    unlocs: ['GBSVT'],
    id: 'GBSVT',
  },
  {
    name: 'Swansea',
    coordinates: [-3.97, 51.63],
    city: 'Swansea',
    province: 'Swansea [Abertawe GB-ATA]',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBSWA'],
    code: '41485',
    id: 'GBSWA',
  },
  {
    name: 'Teesport',
    coordinates: [-1.15, 54.58],
    city: 'Teesport',
    province: 'Redcar and Cleveland',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBTEE'],
    code: '41377',
    id: 'GBTEE',
  },
  {
    name: 'Thamesport',
    city: 'Thamesport',
    province: 'Kent',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [0.5217254000000001, 51.2787075],
    timezone: 'Europe/London',
    unlocs: ['GBTHP'],
    code: '41351',
    id: 'GBTHP',
  },
  {
    name: 'Tilbury',
    city: 'Tilbury',
    province: 'Essex',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [0.360498, 51.463024],
    timezone: 'Europe/London',
    unlocs: ['GBTIL'],
    code: '41380',
    id: 'GBTIL',
  },
  {
    name: 'Tyne',
    coordinates: [-1.43, 55],
    city: 'Tyne',
    province: 'Newcastle upon Tyne',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBTYN'],
    id: 'GBTYN',
  },
  {
    name: 'Widnes',
    coordinates: [-2.73, 53.35],
    city: 'Widnes',
    province: 'Halton',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    timezone: 'Europe/London',
    unlocs: ['GBWDN'],
    id: 'GBWDN',
  },
  {
    name: 'Workington',
    city: 'Workington',
    province: 'Cumbria',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-3.542752, 54.643569],
    timezone: 'Europe/London',
    unlocs: ['GBWOR'],
    code: '41299',
    id: 'GBWOR',
  },
  {
    name: 'Stonehouse',
    city: 'Stonehouse',
    province: 'Gloucestershire',
    country: 'United Kingdom',
    alias: [],
    regions: [],
    coordinates: [-2.279028, 51.74388099999999],
    timezone: 'Europe/London',
    unlocs: ['GBYVZ'],
    id: 'GBYVZ',
  },
  {
    name: "Saint George's",
    city: "Saint George's",
    country: 'Grenada',
    alias: [],
    regions: [],
    coordinates: [-61.7487996, 12.0560975],
    province: 'Saint George',
    timezone: 'America/Grenada',
    unlocs: ['GDSTG'],
    code: '48400',
    id: 'GDSTG',
  },
  {
    name: 'Batumi',
    city: 'Batumi',
    country: 'Georgia',
    alias: [],
    regions: [],
    coordinates: [41.6367455, 41.6167547],
    province: 'Adjara',
    timezone: 'Asia/Tbilisi',
    unlocs: ['GEBUS'],
    code: '46321',
    id: 'GEBUS',
  },
  {
    name: 'Poti',
    city: 'Poti',
    country: 'Georgia',
    alias: [],
    regions: [],
    coordinates: [41.67137, 42.1582596],
    province: 'Samegrelo-Upper Svaneti',
    timezone: 'Asia/Tbilisi',
    unlocs: ['GEPTI'],
    code: '46337',
    id: 'GEPTI',
  },
  {
    name: 'Tbilisi',
    city: 'Tbilisi',
    country: 'Georgia',
    alias: [],
    regions: [],
    coordinates: [44.827096, 41.7151377],
    province: 'Tbilisi',
    timezone: 'Asia/Tbilisi',
    unlocs: ['GETBS'],
    code: '46357',
    id: 'GETBS',
  },
  {
    name: 'Cayenne',
    city: 'Cayenne',
    country: 'French Guiana',
    alias: [],
    regions: [],
    coordinates: [-52.3134531, 4.92242],
    province: 'Cayenne',
    timezone: 'America/Cayenne',
    unlocs: ['GFCAY'],
    code: '31700',
    id: 'GFCAY',
  },
  {
    name: 'Degrad des Cannes',
    coordinates: [-52.27, 4.85],
    city: 'Dégrad des Cannes',
    country: 'French Guiana',
    alias: [],
    regions: [],
    province: 'Cayenne',
    timezone: 'America/Cayenne',
    unlocs: ['GFDDC'],
    code: '31707',
    id: 'GFDDC',
  },
  {
    name: 'Tema',
    city: 'Tema',
    country: 'Ghana',
    alias: [],
    regions: [],
    coordinates: [0.0186555, 5.7089238],
    province: 'Greater Accra',
    timezone: 'Africa/Accra',
    unlocs: ['GHTEM'],
    code: '74990',
    id: 'GHTEM',
  },
  {
    name: 'Takoradi',
    city: 'Takoradi',
    country: 'Ghana',
    alias: [],
    regions: [],
    coordinates: [-1.7830973, 4.9015794],
    province: 'Western',
    timezone: 'Africa/Accra',
    unlocs: ['GHTKD'],
    code: '74985',
    id: 'GHTKD',
  },
  {
    name: 'Gibraltar',
    city: 'Gibraltar',
    country: 'Gibraltar',
    alias: [],
    regions: [],
    coordinates: [-5.353599399999999, 36.1407734],
    timezone: 'Europe/Gibraltar',
    unlocs: ['GIGIB'],
    code: '47201',
    id: 'GIGIB',
  },
  {
    name: 'Banjul',
    city: 'Banjul',
    country: 'Gambia',
    alias: [],
    regions: [],
    coordinates: [-16.5790323, 13.4548761],
    province: 'Banjul',
    timezone: 'Africa/Banjul',
    unlocs: ['GMBJL'],
    code: '75041',
    id: 'GMBJL',
  },
  {
    name: 'Conakry',
    city: 'Conakry',
    country: 'Guinea',
    alias: [],
    regions: [],
    coordinates: [-13.5784012, 9.641185499999999],
    province: 'Conakry',
    timezone: 'Africa/Conakry',
    unlocs: ['GNCKY'],
    code: '74619',
    id: 'GNCKY',
  },
  {
    name: 'Basse-Terre',
    coordinates: [-61.72, 16],
    city: 'Basse-Terre',
    country: 'Guadeloupe',
    alias: [],
    regions: [],
    province: 'Basse-Terre',
    timezone: 'America/Guadeloupe',
    unlocs: ['GPBBR'],
    code: '48400',
    id: 'GPBBR',
  },
  {
    name: 'Gustavia',
    city: 'Gustavia',
    country: 'Guadeloupe',
    alias: [],
    regions: [],
    coordinates: [-62.8486533, 17.896877],
    unlocs: ['GPGUS'],
    code: '28319',
    id: 'GPGUS',
  },
  {
    name: 'Pointe-a-Pitre',
    city: 'Pointe-à-Pitre',
    country: 'Guadeloupe',
    alias: [],
    regions: [],
    coordinates: [-61.5340424, 16.2376869],
    province: 'Grande-Terre',
    timezone: 'America/Guadeloupe',
    unlocs: ['GPPTP'],
    code: '48400',
    id: 'GPPTP',
  },
  {
    name: 'Saint Barthelemy',
    city: 'Saint Barthelemy',
    country: 'Guadeloupe',
    alias: [],
    regions: [],
    coordinates: [-61.5323532, 16.2544056],
    province: 'Pointe-à-Pitre',
    timezone: 'America/Guadeloupe',
    unlocs: ['GPSBH'],
    code: '28319',
    id: 'GPSBH',
  },
  {
    name: 'Bata',
    city: 'Bata',
    country: 'Equatorial Guinea',
    alias: [],
    regions: [],
    coordinates: [9.779022699999999, 1.8533177],
    province: 'Litoral',
    timezone: 'Africa/Malabo',
    unlocs: ['GQBSG'],
    code: '73800',
    id: 'GQBSG',
  },
  {
    name: 'Malabo',
    city: 'Malabo',
    province: 'Bioko Norte',
    country: 'Equatorial Guinea',
    alias: [],
    regions: [],
    coordinates: [8.7371039, 3.7504118],
    timezone: 'Africa/Malabo',
    unlocs: ['GQSSG'],
    code: '73801',
    id: 'GQSSG',
  },
  {
    name: 'Astakos',
    coordinates: [21.08, 38.53],
    city: 'Astakós',
    country: 'Greece',
    alias: [],
    regions: [],
    province: 'Peloponnisos Dytiki Ellada ke Ionio',
    timezone: 'Europe/Athens',
    unlocs: ['GRAST'],
    code: '48400',
    id: 'GRAST',
  },
  {
    name: 'Eleusina',
    coordinates: [23.53, 38.03],
    city: 'Eleusina',
    province: 'Attiki',
    country: 'Greece',
    alias: [],
    regions: [],
    timezone: 'Europe/Athens',
    unlocs: ['GREEU'],
    code: '48445',
    id: 'GREEU',
  },
  {
    name: 'Heraklion (Iraklion)',
    coordinates: [25.17, 35.33],
    city: 'Heraklion (Iraklion)',
    province: 'Irakleio',
    country: 'Greece',
    alias: [],
    regions: [],
    timezone: 'Europe/Athens',
    unlocs: ['GRHER'],
    code: '48411',
    id: 'GRHER',
  },
  {
    name: 'Kapsalion (Kythira)',
    city: 'Kapsalion (Kythira)',
    province: 'Wele‐Nzas',
    country: 'Greece',
    alias: [],
    regions: [],
    unlocs: ['GRKAP'],
    code: '48400',
    id: 'GRKAP',
  },
  {
    name: 'Piraeus',
    coordinates: [23.62, 37.93],
    city: 'Piraeus',
    country: 'Greece',
    alias: [],
    regions: [],
    province: 'Attica',
    timezone: 'Europe/Athens',
    unlocs: ['GRPIR'],
    code: '48452',
    id: 'GRPIR',
  },
  {
    name: 'Rhodes',
    city: 'Rhodes',
    country: 'Greece',
    alias: [],
    regions: [],
    coordinates: [28.2174829, 36.4349631],
    province: 'Egeo',
    timezone: 'Europe/Athens',
    unlocs: ['GRRHO'],
    code: '48400',
    id: 'GRRHO',
  },
  {
    name: 'Thessaloniki',
    coordinates: [22.95, 40.63],
    city: 'Thessaloníki',
    country: 'Greece',
    alias: [],
    regions: [],
    province: 'Makedonia Thraki',
    timezone: 'Europe/Athens',
    unlocs: ['GRSKG'],
    code: '48471',
    id: 'GRSKG',
  },
  {
    name: 'Volos',
    coordinates: [22.95, 39.37],
    city: 'Vólos',
    country: 'Greece',
    alias: [],
    regions: [],
    province: 'Thessalia Sterea Ellada',
    timezone: 'Europe/Athens',
    unlocs: ['GRVOL'],
    code: '48473',
    id: 'GRVOL',
  },
  {
    name: 'Champerico',
    coordinates: [-91.92, 14.3],
    city: 'Champerico',
    province: 'Retalhuleu',
    country: 'Guatemala',
    alias: [],
    regions: [],
    timezone: 'America/Guatemala',
    unlocs: ['GTCHR'],
    code: '20599',
    id: 'GTCHR',
  },
  {
    name: 'Guatemala City',
    city: 'Guatemala City',
    country: 'Guatemala',
    alias: [],
    regions: [],
    coordinates: [-90.5068824, 14.6349149],
    province: 'Guatemala',
    timezone: 'America/Guatemala',
    unlocs: ['GTGUA'],
    code: '20500',
    id: 'GTGUA',
  },
  {
    name: 'Puerto Barrios',
    city: 'Puerto Barrios',
    country: 'Guatemala',
    alias: [],
    regions: [],
    coordinates: [-88.58551729999999, 15.695364],
    province: 'Izabal',
    timezone: 'America/Guatemala',
    unlocs: ['GTPBR'],
    code: '20513',
    id: 'GTPBR',
  },
  {
    name: 'Puerto Quetzal',
    city: 'Puerto Quetzal',
    country: 'Guatemala',
    alias: [],
    regions: [],
    coordinates: [-90.7580342, 13.9306292],
    province: 'Escuintla',
    timezone: 'America/Guatemala',
    unlocs: ['GTPRQ'],
    code: '20506',
    id: 'GTPRQ',
  },
  {
    name: 'Puerto Santo Tomas de Castilla',
    coordinates: [-88.62, 15.7],
    city: 'Puerto Santo Tomás de Castilla',
    province: 'Izabal',
    country: 'Guatemala',
    alias: [],
    regions: [],
    timezone: 'America/Guatemala',
    unlocs: ['GTSTC'],
    code: '20505',
    id: 'GTSTC',
  },
  {
    name: 'Guam',
    city: 'Guam',
    country: 'Guam',
    alias: [],
    regions: [],
    coordinates: [144.793731, 13.444304],
    timezone: 'Pacific/Guam',
    unlocs: ['GUGUM'],
    code: '93501',
    id: 'GUGUM',
  },
  {
    name: 'Bissau',
    coordinates: [-15.58, 11.85],
    city: 'Bissau',
    province: 'Bissau',
    country: 'Guinea-Bissau',
    alias: [],
    regions: [],
    timezone: 'Africa/Bissau',
    unlocs: ['GWOXB'],
    code: '76489',
    id: 'GWOXB',
  },
  {
    name: 'Georgetown',
    city: 'Georgetown',
    country: 'Guyana',
    alias: [],
    regions: [],
    coordinates: [-58.1551255, 6.8012793],
    province: 'Demerara-Mahaica',
    timezone: 'America/Guyana',
    unlocs: ['GYGEO'],
    code: '31201',
    id: 'GYGEO',
  },
  {
    name: 'Hong Kong',
    city: 'Hong Kong',
    country: 'Hong Kong',
    alias: [],
    regions: [],
    coordinates: [114.109497, 22.396428],
    timezone: 'Asia/Hong_Kong',
    unlocs: ['HKHKG'],
    code: '58201',
    id: 'HKHKG',
  },
  {
    name: 'Amapala',
    city: 'Amapala',
    country: 'Honduras',
    alias: [],
    regions: [],
    coordinates: [-87.65194729999999, 13.2916538],
    timezone: 'America/Tegucigalpa',
    unlocs: ['HNAMP'],
    code: '21551',
    id: 'HNAMP',
  },
  {
    name: 'La Ceiba',
    city: 'La Ceiba',
    country: 'Honduras',
    alias: [],
    regions: [],
    coordinates: [-86.7919009, 15.770288],
    province: 'Atlantida',
    timezone: 'America/Tegucigalpa',
    unlocs: ['HNLCE'],
    code: '21515',
    id: 'HNLCE',
  },
  {
    name: 'Puerto Castilla',
    city: 'Puerto Castilla',
    country: 'Honduras',
    alias: [],
    regions: [],
    coordinates: [-85.9585544, 16.0173268],
    province: 'Colon',
    timezone: 'America/Tegucigalpa',
    unlocs: ['HNPCA'],
    code: '21527',
    id: 'HNPCA',
  },
  {
    name: 'Puerto Cortes',
    city: 'Puerto Cortés',
    country: 'Honduras',
    alias: [],
    regions: [],
    coordinates: [-87.92857769999999, 15.825072],
    province: 'Cortés',
    timezone: 'America/Tegucigalpa',
    unlocs: ['HNPCR'],
    code: '21531',
    id: 'HNPCR',
  },
  {
    name: 'Roatan',
    coordinates: [-86.55, 16.3],
    city: 'Roatán',
    country: 'Honduras',
    alias: [],
    regions: [],
    province: 'Bay Islands',
    timezone: 'America/Tegucigalpa',
    unlocs: ['HNRTB'],
    code: '21535',
    id: 'HNRTB',
  },
  {
    name: 'San Lorenzo',
    city: 'San Lorenzo',
    country: 'Honduras',
    alias: [],
    regions: [],
    coordinates: [-87.45544319999999, 13.4326204],
    province: 'Valle',
    timezone: 'America/Tegucigalpa',
    unlocs: ['HNSLO'],
    code: '21551',
    id: 'HNSLO',
  },
  {
    name: 'Tela',
    city: 'Tela',
    country: 'Honduras',
    alias: [],
    regions: [],
    coordinates: [-87.46535019999999, 15.7732601],
    province: 'Atlantida',
    timezone: 'America/Tegucigalpa',
    unlocs: ['HNTEA'],
    code: '21543',
    id: 'HNTEA',
  },
  {
    city: 'Tegucigalpa',
    country: 'Honduras',
    province: 'Tegucigalpa',
    name: 'Tegucigalpa',
    alias: [],
    regions: [],
    unlocs: ['HNTGU'],
    code: '21500',
    id: 'HNTGU',
  },
  {
    name: 'Dubrovnik',
    coordinates: [18.08, 42.65],
    city: 'Dubrovnik',
    province: 'Dubrovacko-neretvanska županija',
    country: 'Croatia',
    alias: [],
    regions: [],
    timezone: 'Europe/Zagreb',
    unlocs: ['HRDBV'],
    code: '47902',
    id: 'HRDBV',
  },
  {
    name: 'Lastovo',
    city: 'Lastovo',
    province: 'Yoro',
    country: 'Croatia',
    alias: [],
    regions: [],
    unlocs: ['HRLST'],
    code: '47998',
    id: 'HRLST',
  },
  {
    name: 'Ploce',
    coordinates: [17.43, 43.05],
    city: 'Ploce',
    country: 'Croatia',
    alias: [],
    regions: [],
    province: 'Dubrovnik-Neretva County',
    timezone: 'Europe/Zagreb',
    unlocs: ['HRPLE'],
    code: '47911',
    id: 'HRPLE',
  },
  {
    name: 'Rijeka',
    coordinates: [14.4, 45.33],
    city: 'Rijeka',
    province: 'Primorsko-goranska županija',
    country: 'Croatia',
    alias: [],
    regions: [],
    timezone: 'Europe/Zagreb',
    unlocs: ['HRRJK'],
    code: '47923',
    id: 'HRRJK',
  },
  {
    name: 'Split',
    coordinates: [16.45, 43.5],
    city: 'Split',
    province: 'Splitsko-dalmatinska županija',
    country: 'Croatia',
    alias: [],
    regions: [],
    timezone: 'Europe/Zagreb',
    unlocs: ['HRSPU'],
    code: '47931',
    id: 'HRSPU',
  },
  {
    name: 'Cap-Haitien',
    coordinates: [-72.2, 19.75],
    city: 'Cap-Haïtien',
    country: 'Haiti',
    alias: [],
    regions: [],
    province: 'Nord',
    timezone: 'America/Port-au-Prince',
    unlocs: ['HTCAP'],
    code: '24513',
    id: 'HTCAP',
  },
  {
    name: 'Port-au-Prince',
    city: 'Port-au-Prince',
    province: 'Ouest',
    country: 'Haiti',
    alias: [],
    regions: [],
    coordinates: [-72.3074326, 18.594395],
    timezone: 'America/Port-au-Prince',
    unlocs: ['HTPAP'],
    code: '24579',
    id: 'HTPAP',
  },
  {
    name: 'Budapest',
    city: 'Budapest',
    country: 'Hungary',
    alias: [],
    regions: [],
    coordinates: [19.040235, 47.497912],
    province: 'Budapest',
    timezone: 'Europe/Budapest',
    unlocs: ['HUBUD'],
    id: 'HUBUD',
  },
  {
    name: 'Amamapare, Ij',
    city: 'Amamapare, Ij',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [136.797607, -4.86529],
    province: 'Papua',
    timezone: 'Asia/Jayapura',
    unlocs: ['IDAMA'],
    code: '60433',
    id: 'IDAMA',
  },
  {
    name: 'Banjarmasin',
    city: 'Banjarmasin',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [114.5943784, -3.3186067],
    province: 'South Kalimantan',
    timezone: 'Asia/Makassar',
    unlocs: ['IDBDJ'],
    code: '56013',
    id: 'IDBDJ',
  },
  {
    name: 'Bitung, Sulawesi',
    city: 'Bitung, Sulawesi',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [124.6303372, 1.1321809],
    province: 'North Sulawesi',
    timezone: 'Asia/Makassar',
    unlocs: ['IDBIT'],
    code: '56028',
    id: 'IDBIT',
  },
  {
    name: 'Belawan, Sumatra',
    city: 'Belawan, Sumatra',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [98.694221, 3.784303],
    province: 'Sumatera Utara',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDBLW'],
    code: '56073',
    id: 'IDBLW',
  },
  {
    timezone: 'Asia/Jakarta',
    coordinates: [101.7068294, 0.2933469],
    name: 'Belanak Terminal',
    city: 'Belanak Terminal',
    province: 'Riau',
    country: 'Indonesia',
    alias: [],
    regions: [],
    unlocs: ['IDBNT'],
    code: '56058',
    id: 'IDBNT',
  },
  {
    name: 'Balikpapan',
    coordinates: [116.83, -1.28],
    city: 'Balikpapan',
    province: 'Kalimantan Timur',
    country: 'Indonesia',
    alias: [],
    regions: [],
    unlocs: ['IDBPN'],
    code: '56007',
    id: 'IDBPN',
  },
  {
    name: 'Batam Island',
    city: 'Batam Island',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [104.0304535, 1.0828276],
    timezone: 'Asia/Jakarta',
    unlocs: ['IDBTM'],
    code: '56000',
    id: 'IDBTM',
  },
  {
    name: 'Buatan',
    city: 'Buatan',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [101.818901, 0.736198],
    province: 'Riau',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDBUN'],
    code: '56000',
    id: 'IDBUN',
  },
  {
    name: 'Batu Ampal',
    city: 'Batu Ampal',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [116.856639, -1.245998],
    province: 'Kalimantan Timur',
    timezone: 'Asia/Makassar',
    unlocs: ['IDBUR'],
    code: '56020',
    id: 'IDBUR',
  },
  {
    name: 'Cigading',
    city: 'Cigading',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [105.987668, -6.011872899999999],
    province: 'Banten',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDCIG'],
    code: '56056',
    id: 'IDCIG',
  },
  {
    name: 'Jambi, Sumatra',
    city: 'Jambi, Sumatra',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [103.6131203, -1.6101229],
    province: 'Jambi',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDDJB'],
    code: '56078',
    id: 'IDDJB',
  },
  {
    timezone: 'Asia/Jayapura',
    coordinates: [140.6689995, -2.5916025],
    name: 'Jayapura, Irian Jaya',
    city: 'Jayapura, Irian Jaya',
    province: 'Papua',
    country: 'Indonesia',
    alias: [],
    regions: [],
    unlocs: ['IDDJJ'],
    code: '56025',
    id: 'IDDJJ',
  },
  {
    name: 'Jakarta, Java',
    coordinates: [106.83, -6.13],
    city: 'Jakarta, Java',
    province: 'Jakarta Raya',
    country: 'Indonesia',
    alias: [],
    regions: [],
    timezone: 'Asia/Jakarta',
    unlocs: ['IDJKT'],
    code: '56033',
    id: 'IDJKT',
  },
  {
    name: 'Kabil',
    coordinates: [104.13, 1.05],
    city: 'Kabil',
    country: 'Indonesia',
    alias: [],
    regions: [],
    province: 'Riau Islands',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDKAB'],
    code: '56058',
    id: 'IDKAB',
  },
  {
    name: 'Lobam',
    coordinates: [104.27, 1],
    city: 'Lobam',
    country: 'Indonesia',
    alias: [],
    regions: [],
    timezone: 'Asia/Jakarta',
    unlocs: ['IDLBM'],
    code: '56059',
    id: 'IDLBM',
  },
  {
    name: 'Makassar',
    coordinates: [119.4, -5.12],
    city: 'Makassar',
    province: 'Sulawesi Selatan',
    country: 'Indonesia',
    alias: [],
    regions: [],
    timezone: 'Asia/Makassar',
    unlocs: ['IDMAK'],
    code: '56027',
    id: 'IDMAK',
  },
  {
    name: 'Medan, Sumatra',
    city: 'Medan, Sumatra',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [98.6722227, 3.5951956],
    province: 'North Sumatra',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDMES'],
    code: '56099',
    id: 'IDMES',
  },
  {
    name: 'Merak, Java',
    city: 'Merak, Java',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [106.0064628, -5.9349331],
    province: 'Banten',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDMRK'],
    code: '56039',
    id: 'IDMRK',
  },
  {
    name: 'Padang',
    coordinates: [100.35, -0.95],
    city: 'Padang',
    province: 'Sumatera Barat',
    country: 'Indonesia',
    alias: [],
    regions: [],
    timezone: 'Asia/Jakarta',
    unlocs: ['IDPDG'],
    code: '56081',
    id: 'IDPDG',
  },
  {
    name: 'Panjang (Lampung, Sumatra)',
    city: 'Panjang (Lampung, Sumatra)',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [105.3218997, -5.464115899999999],
    province: 'Lampung',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDPJG'],
    code: '56097',
    id: 'IDPJG',
  },
  {
    name: 'Pekanbaru, Sumatra',
    city: 'Pekanbaru, Sumatra',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [101.4477793, 0.5070677],
    province: 'Riau',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDPKU'],
    code: '56099',
    id: 'IDPKU',
  },
  {
    name: 'Palembang, Sumatra',
    city: 'Palembang, Sumatra',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [104.7754307, -2.9760735],
    province: 'South Sumatra',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDPLM'],
    code: '56083',
    id: 'IDPLM',
  },
  {
    name: 'Panjang',
    city: 'Panjang',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [105.3218997, -5.464115899999999],
    province: 'Lampung',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDPNJ'],
    code: '56097',
    id: 'IDPNJ',
  },
  {
    name: 'Pontianak, Kalimantan',
    city: 'Pontianak, Kalimantan',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [109.3425039, -0.0263303],
    province: 'West Kalimantan',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDPNK'],
    code: '56020',
    id: 'IDPNK',
  },
  {
    name: 'Perawang',
    coordinates: [101.62, 0.63],
    city: 'Perawang',
    province: 'Riau',
    country: 'Indonesia',
    alias: [],
    regions: [],
    timezone: 'Asia/Jakarta',
    unlocs: ['IDPWG'],
    code: '56000',
    id: 'IDPWG',
  },
  {
    name: 'Sungai Pakning, Sumatra',
    city: 'Sungai Pakning, Sumatra',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [102.0775411, 1.3501405],
    province: 'Riau',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDSEQ'],
    code: '56099',
    id: 'IDSEQ',
  },
  {
    name: 'Semarang',
    coordinates: [110.48, -6.97],
    city: 'Semarang',
    province: 'Jawa Tengah',
    country: 'Indonesia',
    alias: [],
    regions: [],
    timezone: 'Asia/Jakarta',
    unlocs: ['IDSRG'],
    code: '56051',
    id: 'IDSRG',
  },
  {
    name: 'Samarinda, Kalimantan',
    city: 'Samarinda, Kalimantan',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [117.1436154, -0.4948232],
    province: 'East Kalimantan',
    timezone: 'Asia/Makassar',
    unlocs: ['IDSRI'],
    code: '56012',
    id: 'IDSRI',
  },
  {
    name: 'Surabaya',
    coordinates: [112.75, -7.23],
    city: 'Surabaya',
    province: 'Jawa Timur',
    country: 'Indonesia',
    alias: [],
    regions: [],
    timezone: 'Asia/Jakarta',
    unlocs: ['IDSUB'],
    code: '56053',
    id: 'IDSUB',
  },
  {
    name: 'Taboneo',
    coordinates: [114.42, -3.68],
    city: 'Taboneo',
    province: 'Kalimantan Selatan',
    country: 'Indonesia',
    alias: [],
    regions: [],
    unlocs: ['IDTAB'],
    id: 'IDTAB',
  },
  {
    name: 'Teluk Betung, Sumatra',
    city: 'Teluk Betung, Sumatra',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [105.2670247, -5.445867],
    province: 'Lampung',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDTBG'],
    code: '56099',
    id: 'IDTBG',
  },
  {
    name: 'Tanjung Priok',
    city: 'Tanjung Priok',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [106.8714848, -6.1320555],
    province: 'Special Capital Region of Jakarta',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDTPP'],
    code: '56033',
    id: 'IDTPP',
  },
  {
    name: 'Tebingtinggi',
    city: 'Tebingtinggi',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [99.1566855, 3.3262879],
    province: 'North Sumatra',
    timezone: 'Asia/Jakarta',
    unlocs: ['IDTTI'],
    code: '56099',
    id: 'IDTTI',
  },
  {
    name: 'Ujung Pandang, Sulawesi',
    city: 'Ujung Pandang, Sulawesi',
    country: 'Indonesia',
    alias: [],
    regions: [],
    coordinates: [119.5494844, -5.077747],
    timezone: 'Asia/Makassar',
    unlocs: ['IDUPG'],
    code: '56027',
    id: 'IDUPG',
  },
  {
    name: 'Drogheda',
    city: 'Drogheda',
    country: 'Ireland',
    alias: [],
    regions: [],
    coordinates: [-6.3560985, 53.717856],
    province: 'Louth',
    timezone: 'Europe/Dublin',
    unlocs: ['IEDRO'],
    code: '41919',
    id: 'IEDRO',
  },
  {
    name: 'Dublin',
    city: 'Dublin',
    country: 'Ireland',
    alias: [],
    regions: [],
    coordinates: [-6.2603097, 53.3498053],
    province: 'Dublin',
    timezone: 'Europe/Dublin',
    unlocs: ['IEDUB'],
    code: '41911',
    id: 'IEDUB',
  },
  {
    name: 'Foynes',
    city: 'Foynes',
    country: 'Ireland',
    alias: [],
    regions: [],
    coordinates: [-9.1051123, 52.6105253],
    province: 'Limerick',
    timezone: 'Europe/Dublin',
    unlocs: ['IEFOV'],
    code: '41913',
    id: 'IEFOV',
  },
  {
    name: 'Galway',
    city: 'Galway',
    country: 'Ireland',
    alias: [],
    regions: [],
    coordinates: [-9.0567905, 53.270668],
    province: 'Galway',
    timezone: 'Europe/Dublin',
    unlocs: ['IEGWY'],
    code: '41900',
    id: 'IEGWY',
  },
  {
    name: 'Kinsale',
    city: 'Kinsale',
    country: 'Ireland',
    alias: [],
    regions: [],
    coordinates: [-8.5225014, 51.7060502],
    province: 'Cork',
    timezone: 'Europe/Dublin',
    unlocs: ['IEKLN'],
    code: '41900',
    id: 'IEKLN',
  },
  {
    name: 'Cork',
    city: 'Cork',
    country: 'Ireland',
    alias: [],
    regions: [],
    coordinates: [-8.4863157, 51.8968917],
    province: 'Cork',
    timezone: 'Europe/Dublin',
    unlocs: ['IEORK'],
    code: '41905',
    id: 'IEORK',
  },
  {
    name: 'Waterford',
    coordinates: [-7.1, 52.25],
    city: 'Waterford',
    province: 'Waterford',
    country: 'Ireland',
    alias: [],
    regions: [],
    timezone: 'Europe/Dublin',
    unlocs: ['IEWAT'],
    code: '41910',
    id: 'IEWAT',
  },
  {
    name: 'Ashdod',
    city: 'Ashdod',
    country: 'Israel',
    alias: [],
    regions: [],
    coordinates: [34.655314, 31.804381],
    timezone: 'Asia/Jerusalem',
    unlocs: ['ILASH'],
    code: '50805',
    id: 'ILASH',
  },
  {
    name: 'Hadera',
    city: 'Hadera',
    country: 'Israel',
    alias: [],
    regions: [],
    coordinates: [34.9196518, 32.4340458],
    province: 'Haifa District',
    timezone: 'Asia/Jerusalem',
    unlocs: ['ILHAD'],
    code: '50815',
    id: 'ILHAD',
  },
  {
    name: 'Haifa',
    city: 'Haifa',
    country: 'Israel',
    alias: [],
    regions: [],
    coordinates: [34.989571, 32.7940463],
    timezone: 'Asia/Jerusalem',
    unlocs: ['ILHFA'],
    code: '50801',
    id: 'ILHFA',
  },
  {
    name: 'Netanya',
    coordinates: [34.87, 32.33],
    city: 'Netanya',
    country: 'Israel',
    alias: [],
    regions: [],
    province: 'Center District',
    timezone: 'Asia/Jerusalem',
    unlocs: ['ILNAT'],
    code: '50800',
    id: 'ILNAT',
  },
  {
    name: 'Ramat Gan',
    coordinates: [34.8, 32.08],
    city: 'Ramat Gan',
    country: 'Israel',
    alias: [],
    regions: [],
    province: 'Tel Aviv District',
    timezone: 'Asia/Jerusalem',
    unlocs: ['ILRMG'],
    code: '50800',
    id: 'ILRMG',
  },
  {
    name: 'Tel Aviv-Yafo',
    coordinates: [34.75, 32.05],
    city: 'Tel Aviv-Yafo',
    province: 'Tel-Aviv',
    country: 'Israel',
    alias: [],
    regions: [],
    timezone: 'Asia/Jerusalem',
    unlocs: ['ILTLV'],
    code: '50845',
    id: 'ILTLV',
  },
  {
    name: 'Agra',
    city: 'Agra',
    province: 'Uttar Pradesh',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [78.00807449999999, 27.1766701],
    timezone: 'Asia/Calcutta',
    unlocs: ['INAGR'],
    code: '53398',
    id: 'INAGR',
  },
  {
    name: 'Kakinada',
    city: 'Kakinada',
    province: 'Andhra Pradesh',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [82.2475, 16.9891],
    timezone: 'Asia/Calcutta',
    unlocs: ['INKAK'],
    code: '53398',
    id: 'INKAK',
  },
  {
    name: 'Ahmedabad',
    coordinates: [72.62, 23.03],
    city: 'Ahmedabad',
    province: 'Gujarat',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INAMD'],
    code: '53398',
    id: 'INAMD',
  },
  {
    name: 'Bangalore',
    coordinates: [77.58, 12.98],
    city: 'Bangalore',
    province: 'Karnataka',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INBLR'],
    code: '53398',
    id: 'INBLR',
  },
  {
    name: 'Mumbai (ex Bombay)',
    coordinates: [72.82, 18.97],
    city: 'Mumbai (ex Bombay)',
    province: 'Maharashtra',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INBOM'],
    code: '53312',
    id: 'INBOM',
  },
  {
    name: 'Kolkata (ex Calcutta)',
    coordinates: [88.35, 22.57],
    city: 'Kolkata (ex Calcutta)',
    province: 'West Bengal',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    code: '53353',
    unlocs: ['INCCU'],
    id: 'INCCU',
  },
  {
    name: 'Cuddalore',
    city: 'Cuddalore',
    province: 'Tamil Nadu',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [79.7680243, 11.744699],
    timezone: 'Asia/Calcutta',
    unlocs: ['INCDL'],
    code: '53398',
    id: 'INCDL',
  },
  {
    name: 'Coimbatore',
    city: 'Coimbatore',
    province: 'Tamil Nadu',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [76.9558321, 11.0168445],
    timezone: 'Asia/Calcutta',
    unlocs: ['INCJB'],
    code: '53398',
    id: 'INCJB',
  },
  {
    name: 'Cochin',
    coordinates: [76.23, 9.97],
    city: 'Cochin',
    province: 'Kerala',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INCOK'],
    code: '53333',
    id: 'INCOK',
  },
  {
    name: 'Daman',
    city: 'Daman',
    province: 'Daman and Diu',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [72.8327991, 20.3973736],
    timezone: 'Asia/Calcutta',
    unlocs: ['INDAM'],
    code: '53398',
    id: 'INDAM',
  },
  {
    name: 'Dadri',
    coordinates: [77.55, 28.57],
    city: 'Dadri',
    province: 'Uttar Pradesh',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INDRI'],
    code: '53398',
    id: 'INDRI',
  },
  {
    name: 'Goa',
    city: 'Goa',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [74.12399599999999, 15.2993265],
    province: 'Goa',
    timezone: 'Asia/Calcutta',
    unlocs: ['INGOI'],
    code: '53340',
    id: 'INGOI',
  },
  {
    name: 'Haldia',
    city: 'Haldia',
    province: 'West Bengal',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [88.06981180000001, 22.0666742],
    timezone: 'Asia/Calcutta',
    unlocs: ['INHAL'],
    code: '53354',
    id: 'INHAL',
  },
  {
    name: 'Hyderabad',
    city: 'Hyderabad',
    province: 'Andhra Pradesh',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [79.1766114, 15.1283849],
    timezone: 'Asia/Calcutta',
    unlocs: ['INHYD'],
    code: '53300',
    id: 'INHYD',
  },
  {
    name: 'New Delhi',
    coordinates: [77.2, 28.6],
    city: 'New Delhi',
    province: 'Delhi',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INICD'],
    code: '53300',
    id: 'INICD',
  },
  {
    name: 'Mangalore',
    city: 'Mangalore',
    province: 'Karnataka',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [74.8559568, 12.9141417],
    timezone: 'Asia/Calcutta',
    unlocs: ['INIXE'],
    code: '53343',
    id: 'INIXE',
  },
  {
    name: 'Kandla',
    coordinates: [70.22, 23.03],
    city: 'Kandla',
    province: 'Gujarat',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INIXY'],
    code: '53307',
    id: 'INIXY',
  },
  {
    name: 'Jaipur',
    coordinates: [75.82, 26.92],
    city: 'Jaipur',
    province: 'Rajasthan',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INJAI'],
    code: '53398',
    id: 'INJAI',
  },
  {
    name: 'Jodhpur',
    coordinates: [73.03, 26.25],
    city: 'Jodhpur',
    province: 'Rajasthan',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INJDH'],
    code: '53398',
    id: 'INJDH',
  },
  {
    name: 'Kanpur',
    city: 'Kanpur',
    province: 'Uttar Pradesh',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [80.3318736, 26.449923],
    timezone: 'Asia/Calcutta',
    unlocs: ['INKNU'],
    code: '53300',
    id: 'INKNU',
  },
  {
    name: 'Krishnapatnam',
    city: 'Krishnapatnam',
    province: 'Andhra Pradesh',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [80.1248476, 14.2886008],
    timezone: 'Asia/Calcutta',
    unlocs: ['INKRI'],
    code: '53300',
    id: 'INKRI',
  },
  {
    name: 'Ludhiana',
    city: 'Ludhiana',
    province: 'Punjab',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [75.8572758, 30.900965],
    timezone: 'Asia/Calcutta',
    unlocs: ['INLUH'],
    code: '53398',
    id: 'INLUH',
  },
  {
    name: 'Chennai (ex Madras)',
    coordinates: [80.28, 13.08],
    city: 'Chennai (ex Madras)',
    province: 'Tamil Nadu',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INMAA'],
    code: '53357',
    id: 'INMAA',
  },
  {
    name: 'Moradabad',
    coordinates: [78.78, 28.83],
    city: 'Moradabad',
    province: 'Uttar Pradesh',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INMOR'],
    code: '53300',
    id: 'INMOR',
  },
  {
    name: 'Marmagao (Marmugao)',
    coordinates: [73.78, 15.4],
    city: 'Marmagao (Marmugao)',
    province: 'Goa',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INMRM'],
    code: '53340',
    id: 'INMRM',
  },
  {
    name: 'Mundra',
    city: 'Mundra',
    province: 'Gujarat',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [69.7213266, 22.8395202],
    timezone: 'Asia/Calcutta',
    unlocs: ['INMUN'],
    code: '53306',
    id: 'INMUN',
  },
  {
    name: 'Nagpur',
    city: 'Nagpur',
    province: 'Maharashtra',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [79.0881546, 21.1458004],
    timezone: 'Asia/Calcutta',
    unlocs: ['INNAG'],
    code: '53398',
    id: 'INNAG',
  },
  {
    name: 'Jawaharlal Nehru (Nhava Sheva)',
    city: 'Jawaharlal Nehru (Nhava Sheva)',
    province: 'Maharashtra',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [72.9504858, 18.9497162],
    timezone: 'Asia/Calcutta',
    unlocs: ['INNSA'],
    code: '53313',
    id: 'INNSA',
  },
  {
    name: 'Panipat',
    city: 'Panipat',
    province: 'Haryana',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [76.9635023, 29.3909464],
    timezone: 'Asia/Calcutta',
    unlocs: ['INPAP'],
    code: '53398',
    id: 'INPAP',
  },
  {
    name: 'Pipavav (Victor) Port',
    coordinates: [71.55, 20.97],
    city: 'Pipavav (Victor)',
    province: 'Gujarat',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INPAV'],
    code: '53304',
    id: 'INPAV',
  },
  {
    name: 'Pithampur',
    city: 'Pithampur',
    province: 'Madhya Pradesh',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [75.6822899, 22.613252],
    timezone: 'Asia/Calcutta',
    unlocs: ['INPIR'],
    code: '53398',
    id: 'INPIR',
  },
  {
    name: 'Pune',
    city: 'Pune',
    province: 'Maharashtra',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [73.8567437, 18.5204303],
    timezone: 'Asia/Calcutta',
    unlocs: ['INPNQ'],
    code: '53398',
    id: 'INPNQ',
  },
  {
    name: 'Surat',
    coordinates: [72.83, 21.17],
    city: 'Surat',
    province: 'Gujarat',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INSTV'],
    code: '53398',
    id: 'INSTV',
  },
  {
    name: 'Tuticorin',
    coordinates: [78.13, 8.78],
    city: 'Tuticorin',
    province: 'Tamil Nadu',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INTUT'],
    code: '53395',
    id: 'INTUT',
  },
  {
    name: 'Visakhapatnam',
    coordinates: [83.3, 17.7],
    city: 'Visakhapatnam',
    province: 'Andhra Pradesh',
    country: 'India',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['INVTZ'],
    code: '53399',
    id: 'INVTZ',
  },
  {
    name: 'Veraval',
    city: 'Veraval',
    province: 'Gujarat',
    country: 'India',
    alias: [],
    regions: [],
    coordinates: [70.3628516, 20.9158979],
    timezone: 'Asia/Calcutta',
    unlocs: ['INVVA'],
    code: '53347',
    id: 'INVVA',
  },
  {
    name: 'Umm Qasr Port',
    coordinates: [47.93, 30.03],
    city: 'Umm Qasr',
    province: 'Al Basrah',
    country: 'Iraq',
    alias: [],
    regions: [],
    timezone: 'Asia/Baghdad',
    unlocs: ['IQUQR'],
    code: '50570',
    id: 'IQUQR',
  },
  {
    name: 'Bandar Khomeini',
    coordinates: [49.1, 30.43],
    city: 'Bandar Khomeini',
    province: 'Khuzestan',
    country: 'Iran, Islamic Republic of',
    alias: [],
    regions: [],
    timezone: 'Asia/Tehran',
    unlocs: ['IRBKM'],
    code: '50770',
    id: 'IRBKM',
  },
  {
    name: 'Bandar Abbas',
    coordinates: [56.27, 27.18],
    city: 'Bandar Abbas',
    province: 'Hormozgan',
    country: 'Iran, Islamic Republic of',
    alias: [],
    regions: [],
    timezone: 'Asia/Tehran',
    unlocs: ['IRBND'],
    code: '50730',
    id: 'IRBND',
  },
  {
    name: 'Bushehr',
    coordinates: [50.83, 28.97],
    city: 'Bushehr',
    province: 'Bushehr',
    country: 'Iran, Islamic Republic of',
    alias: [],
    regions: [],
    timezone: 'Asia/Tehran',
    unlocs: ['IRBUZ'],
    code: '50700',
    id: 'IRBUZ',
  },
  {
    name: 'Khorramshahr',
    city: 'Khorramshahr',
    country: 'Iran, Islamic Republic of',
    alias: [],
    regions: [],
    coordinates: [48.1891185, 30.4256219],
    province: 'Khuzestan',
    timezone: 'Asia/Tehran',
    unlocs: ['IRKHO'],
    code: '50700',
    id: 'IRKHO',
  },
  {
    name: 'Chah Bahar',
    city: 'Chah Bahar',
    country: 'Iran, Islamic Republic of',
    alias: [],
    regions: [],
    coordinates: [60.6459285, 25.296878],
    province: 'Sistan Va Baluchestan',
    timezone: 'Asia/Tehran',
    unlocs: ['IRZBR'],
    code: '50700',
    id: 'IRZBR',
  },
  {
    name: 'Eskifjordur - hofn',
    city: 'Eskifjørdur - høfn',
    country: 'Iceland',
    alias: [],
    regions: [],
    coordinates: [65.0726522, -14.0538958],
    unlocs: ['ISESK'],
    code: '40000',
    id: 'ISESK',
  },
  {
    name: 'Grundartangi',
    city: 'Grundartangi',
    country: 'Iceland',
    alias: [],
    regions: [],
    coordinates: [-21.7758053, 64.3603796],
    province: 'West',
    timezone: 'Atlantic/Reykjavik',
    unlocs: ['ISGRT'],
    code: '40002',
    id: 'ISGRT',
  },
  {
    name: 'Reykjavik',
    coordinates: [-21.95, 64.15],
    city: 'Reykjavík',
    country: 'Iceland',
    alias: [],
    regions: [],
    province: 'Capital Region',
    timezone: 'Atlantic/Reykjavik',
    unlocs: ['ISREY'],
    code: '40037',
    id: 'ISREY',
  },
  {
    name: 'Ancona',
    coordinates: [13.5, 43.63],
    city: 'Ancona',
    province: 'Ancona',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITAOI'],
    code: '47571',
    id: 'ITAOI',
  },
  {
    name: 'Augusta',
    coordinates: [15.22, 37.22],
    city: 'Augusta',
    country: 'Italy',
    alias: [],
    regions: [],
    province: 'Sicilia',
    timezone: 'Europe/Rome',
    unlocs: ['ITAUG'],
    code: '47542',
    id: 'ITAUG',
  },
  {
    name: 'Bari',
    coordinates: [16.85, 41.13],
    city: 'Bari',
    province: 'Bari',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITBRI'],
    code: '47578',
    id: 'ITBRI',
  },
  {
    name: 'Cagliari',
    coordinates: [9.12, 39.22],
    city: 'Cagliari',
    province: 'Cagliari',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITCAG'],
    code: '47563',
    id: 'ITCAG',
  },
  {
    name: 'Crotone',
    coordinates: [17.13, 39.08],
    city: 'Crotone',
    country: 'Italy',
    alias: [],
    regions: [],
    province: 'Calabria',
    timezone: 'Europe/Rome',
    unlocs: ['ITCRV'],
    code: '47582',
    id: 'ITCRV',
  },
  {
    name: 'Catania',
    coordinates: [15.1, 37.5],
    city: 'Catania',
    country: 'Italy',
    alias: [],
    regions: [],
    province: 'Sicilia',
    timezone: 'Europe/Rome',
    unlocs: ['ITCTA'],
    code: '47548',
    id: 'ITCTA',
  },
  {
    name: 'Civitavecchia',
    coordinates: [11.8, 42.1],
    city: 'Civitavecchia',
    country: 'Italy',
    alias: [],
    regions: [],
    province: 'Lazio',
    timezone: 'Europe/Rome',
    unlocs: ['ITCVV'],
    code: '47519',
    id: 'ITCVV',
  },
  {
    name: 'Faenza',
    city: 'Faenza',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [11.8774091, 44.2898527],
    province: 'Emilia-Romagna',
    timezone: 'Europe/Rome',
    unlocs: ['ITFAE'],
    code: '47598',
    id: 'ITFAE',
  },
  {
    name: 'Gaeta',
    coordinates: [13.58, 41.2],
    city: 'Gaeta',
    country: 'Italy',
    alias: [],
    regions: [],
    province: 'Lazio',
    timezone: 'Europe/Rome',
    unlocs: ['ITGAE'],
    code: '47525',
    id: 'ITGAE',
  },
  {
    name: 'Gioia Tauro',
    coordinates: [15.9, 38.42],
    city: 'Gioia Tauro',
    country: 'Italy',
    alias: [],
    regions: [],
    province: 'Calabria',
    timezone: 'Europe/Rome',
    unlocs: ['ITGIT'],
    code: '47536',
    id: 'ITGIT',
  },
  {
    name: 'Genova',
    coordinates: [8.95, 44.42],
    city: 'Genova',
    province: 'Genova',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITGOA'],
    code: '47527',
    id: 'ITGOA',
  },
  {
    name: 'Livorno',
    coordinates: [10.32, 43.55],
    city: 'Livorno',
    country: 'Italy',
    alias: [],
    regions: [],
    province: 'Toscana',
    timezone: 'Europe/Rome',
    unlocs: ['ITLIV'],
    code: '47537',
    id: 'ITLIV',
  },
  {
    name: 'Milano',
    city: 'Milano',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [9.1859243, 45.4654219],
    province: 'Lombardy',
    timezone: 'Europe/Rome',
    unlocs: ['ITMIL'],
    code: '47500',
    id: 'ITMIL',
  },
  {
    name: 'Monfalcone',
    city: 'Monfalcone',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [13.5331727, 45.8050468],
    province: 'Friuli-Venezia Giulia',
    timezone: 'Europe/Rome',
    unlocs: ['ITMNF'],
    code: '47588',
    id: 'ITMNF',
  },
  {
    name: 'Modena',
    city: 'Modena',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [10.9252269, 44.647128],
    province: 'Emilia-Romagna',
    timezone: 'Europe/Rome',
    unlocs: ['ITMOD'],
    code: '47500',
    id: 'ITMOD',
  },
  {
    name: 'Napoli',
    coordinates: [14.25, 40.83],
    city: 'Napoli',
    province: 'Napoli',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITNAP'],
    code: '47507',
    id: 'ITNAP',
  },
  {
    name: 'Padova',
    city: 'Padova',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [11.8767611, 45.4064349],
    province: 'Veneto',
    timezone: 'Europe/Rome',
    unlocs: ['ITPDA'],
    code: '47598',
    id: 'ITPDA',
  },
  {
    name: 'Pesaro',
    coordinates: [12.92, 43.9],
    city: 'Pesaro',
    province: 'Pesaro e Urbino',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITPES'],
    code: '47598',
    id: 'ITPES',
  },
  {
    name: 'Porto Garibaldi',
    city: 'Porto Garibaldi',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [12.2395659, 44.6780654],
    province: 'Emilia-Romagna',
    timezone: 'Europe/Rome',
    unlocs: ['ITPGA'],
    code: '47598',
    id: 'ITPGA',
  },
  {
    name: 'Palermo',
    coordinates: [13.37, 38.12],
    city: 'Palermo',
    province: 'Palermo',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITPMO'],
    code: '47551',
    id: 'ITPMO',
  },
  {
    name: 'Capri',
    coordinates: [14.23, 40.55],
    city: 'Capri',
    province: 'Napoli',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITPRJ'],
    code: '47500',
    id: 'ITPRJ',
  },
  {
    name: 'Pescara',
    coordinates: [14.23, 42.47],
    city: 'Pescara',
    province: 'Pescara',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITPSR'],
    code: '47598',
    id: 'ITPSR',
  },
  {
    name: 'Portoscuso (Porto Vesme)',
    coordinates: [8.38, 39.2],
    city: 'Portoscuso (Porto Vesme)',
    country: 'Italy',
    alias: [],
    regions: [],
    province: 'Sardegna',
    timezone: 'Europe/Rome',
    unlocs: ['ITPVE'],
    code: '47568',
    id: 'ITPVE',
  },
  {
    name: 'Ravenna',
    city: 'Ravenna',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [12.2035294, 44.4183598],
    province: 'Emilia-Romagna',
    timezone: 'Europe/Rome',
    unlocs: ['ITRAN'],
    code: '47584',
    id: 'ITRAN',
  },
  {
    name: 'Salerno',
    city: 'Salerno',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [14.7680961, 40.68244079999999],
    province: 'Campania',
    timezone: 'Europe/Rome',
    unlocs: ['ITSAL'],
    code: '47538',
    id: 'ITSAL',
  },
  {
    name: 'La Spezia',
    coordinates: [9.83, 44.12],
    city: 'La Spezia',
    country: 'Italy',
    alias: [],
    regions: [],
    province: 'Liguria',
    timezone: 'Europe/Rome',
    unlocs: ['ITSPE'],
    code: '47531',
    id: 'ITSPE',
  },
  {
    name: 'Savona',
    coordinates: [8.5, 44.28],
    city: 'Savona',
    province: 'Savona',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITSVN'],
    code: '47517',
    id: 'ITSVN',
  },
  {
    name: 'Taranto',
    coordinates: [17.23, 40.48],
    city: 'Taranto',
    country: 'Italy',
    alias: [],
    regions: [],
    province: 'Puglia',
    timezone: 'Europe/Rome',
    unlocs: ['ITTAR'],
    code: '47585',
    id: 'ITTAR',
  },
  {
    name: 'Trapani',
    coordinates: [12.48, 38.02],
    city: 'Trapani',
    province: 'Trapani',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITTPS'],
    code: '47558',
    id: 'ITTPS',
  },
  {
    name: 'Torino',
    city: 'Torino',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [7.686856499999999, 45.070312],
    province: 'Piedmont',
    timezone: 'Europe/Rome',
    unlocs: ['ITTRN'],
    code: '47500',
    id: 'ITTRN',
  },
  {
    name: 'Trieste',
    city: 'Trieste',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [13.7768182, 45.6495264],
    province: 'Friuli-Venezia Giulia',
    timezone: 'Europe/Rome',
    unlocs: ['ITTRS'],
    code: '47587',
    id: 'ITTRS',
  },
  {
    name: 'Venezia',
    coordinates: [12.33, 45.43],
    city: 'Venezia',
    province: 'Venezia',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITVCE'],
    code: '47595',
    id: 'ITVCE',
  },
  {
    name: 'Vado Ligure',
    coordinates: [8.45, 44.27],
    city: 'Vado Ligure',
    province: 'Savona',
    country: 'Italy',
    alias: [],
    regions: [],
    timezone: 'Europe/Rome',
    unlocs: ['ITVDL'],
    code: '47540',
    id: 'ITVDL',
  },
  {
    name: 'Viareggio',
    city: 'Viareggio',
    country: 'Italy',
    alias: [],
    regions: [],
    coordinates: [10.2513103, 43.8657267],
    province: 'Toscana',
    timezone: 'Europe/Rome',
    unlocs: ['ITVIA'],
    code: '47500',
    id: 'ITVIA',
  },
  {
    name: 'Kingston',
    city: 'Kingston',
    country: 'Jamaica',
    alias: [],
    regions: [],
    coordinates: [-76.8028933, 18.0423274],
    timezone: 'America/Jamaica',
    unlocs: ['JMKIN'],
    code: '24128',
    id: 'JMKIN',
  },
  {
    name: 'Montego Bay',
    city: 'Montego Bay',
    country: 'Jamaica',
    alias: [],
    regions: [],
    coordinates: [-77.8938895, 18.4762228],
    province: 'Saint James Parish',
    timezone: 'America/Jamaica',
    unlocs: ['JMMBJ'],
    code: '24145',
    id: 'JMMBJ',
  },
  {
    name: 'Ocho Rios',
    city: 'Ocho Rios',
    country: 'Jamaica',
    alias: [],
    regions: [],
    coordinates: [-77.0967357, 18.4056822],
    province: 'Saint Ann Parish',
    timezone: 'America/Jamaica',
    unlocs: ['JMOCJ'],
    code: '24155',
    id: 'JMOCJ',
  },
  {
    name: 'Port Kaiser',
    city: 'Port Kaiser',
    country: 'Jamaica',
    alias: [],
    regions: [],
    coordinates: [-77.1319777, 17.8939846],
    province: 'Saint Catherine Parish',
    timezone: 'America/Jamaica',
    unlocs: ['JMPKS'],
    code: '24165',
    id: 'JMPKS',
  },
  {
    name: "Al 'Aqabah",
    coordinates: [35, 29.53],
    city: "Al 'Aqabah",
    country: 'Jordan',
    alias: [],
    regions: [],
    province: 'Aqaba',
    timezone: 'Asia/Amman',
    unlocs: ['JOAQJ'],
    code: '51105',
    id: 'JOAQJ',
  },
  {
    name: 'Aburatsu',
    city: 'Aburatsu',
    province: 'Miyazaki',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [131.4041631, 31.5829755],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPABU'],
    code: '58800',
    id: 'JPABU',
  },
  {
    name: 'Aomori',
    city: 'Aomori',
    province: 'Aomori',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.7473647, 40.822072],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPAOJ'],
    code: '58844',
    id: 'JPAOJ',
  },
  {
    name: 'Akita',
    city: 'Akita',
    province: 'Akita',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.1025642, 39.7200079],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPAXT'],
    code: '58832',
    id: 'JPAXT',
  },
  {
    name: 'Chiba',
    city: 'Chiba',
    province: 'Tiba [Chiba]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.1062907, 35.6072668],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPCHB'],
    code: '58818',
    id: 'JPCHB',
  },
  {
    name: 'Fukuyama, Hiroshima',
    city: 'Fukuyama, Hiroshima',
    province: 'Hirosima [Hiroshima]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [133.3623127, 34.4858993],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPFKY'],
    code: '58803',
    id: 'JPFKY',
  },
  {
    name: 'Hachinohe',
    city: 'Hachinohe',
    province: 'Aomori',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [141.4883986, 40.5122839],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHHE'],
    code: '58808',
    id: 'JPHHE',
  },
  {
    name: 'Hibi',
    city: 'Hibi',
    province: 'Okayama',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [133.9221388, 34.4574823],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHIB'],
    code: '58800',
    id: 'JPHIB',
  },
  {
    name: 'Hitachinaka',
    city: 'Hitachinaka',
    province: 'Ibaraki',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.5346762, 36.3966149],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHIC'],
    code: '58800',
    id: 'JPHIC',
  },
  {
    name: 'Hiroshima',
    city: 'Hiroshima',
    province: 'Hirosima [Hiroshima]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [132.4552927, 34.3852029],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHIJ'],
    code: '58831',
    id: 'JPHIJ',
  },
  {
    name: 'Himeji',
    city: 'Himeji',
    province: 'Hyôgo[Hyogo]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [134.6853528, 34.815149],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHIM'],
    code: '58871',
    id: 'JPHIM',
  },
  {
    name: 'Hikoshima',
    city: 'Hikoshima',
    province: 'Yamaguti [Yamaguchi]',
    country: 'Japan',
    alias: [],
    regions: [],
    unlocs: ['JPHIS'],
    code: '58800',
    id: 'JPHIS',
  },
  {
    name: 'Hakodate',
    city: 'Hakodate',
    province: 'Hokkaidô [Hokkaido]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.7288103, 41.7687933],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHKD'],
    code: '58826',
    id: 'JPHKD',
  },
  {
    name: 'Hakata/Fukuoka',
    coordinates: [130.42, 33.6],
    city: 'Hakata/Fukuoka',
    province: 'Hukuoka [Fukuoka]',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHKT'],
    code: '58825',
    id: 'JPHKT',
  },
  {
    name: 'Hamada',
    city: 'Hamada',
    province: 'Simane [Shimane]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [132.0797833, 34.8993025],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHMD'],
    code: '58800',
    id: 'JPHMD',
  },
  {
    name: 'Hamamatsu',
    city: 'Hamamatsu',
    province: 'Sizuoka [Shizuoka]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [137.7261258, 34.7108344],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHMM'],
    code: '58800',
    id: 'JPHMM',
  },
  {
    name: 'Hososhima',
    city: 'Hososhima',
    province: 'Miyazaki',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [131.6690227, 32.4275172],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHSM'],
    code: '58800',
    id: 'JPHSM',
  },
  {
    name: 'Hitachi',
    city: 'Hitachi',
    province: 'Ibaraki',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.6514693, 36.5991539],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPHTC'],
    code: '58869',
    id: 'JPHTC',
  },
  {
    name: 'Niihama',
    city: 'Niihama',
    province: 'Ehime',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [133.2833512, 33.96028950000001],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPIHA'],
    code: '58858',
    id: 'JPIHA',
  },
  {
    name: 'Imabari',
    city: 'Imabari',
    province: 'Ehime',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [132.99786, 34.0660979],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPIMB'],
    code: '58800',
    id: 'JPIMB',
  },
  {
    name: 'Imari',
    city: 'Imari',
    province: 'Saga',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [129.880269, 33.2645856],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPIMI'],
    code: '58802',
    id: 'JPIMI',
  },
  {
    name: 'Ishigaki',
    coordinates: [124.15, 24.33],
    city: 'Ishigaki',
    province: 'Okinawa',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPISG'],
    code: '58800',
    id: 'JPISG',
  },
  {
    name: 'Ishikari',
    city: 'Ishikari',
    province: 'Hokkaidô [Hokkaido]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [141.3155196, 43.1713843],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPISI'],
    code: '58800',
    id: 'JPISI',
  },
  {
    name: 'Ishinomaki',
    city: 'Ishinomaki',
    province: 'Miyagi',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [141.3029167, 38.4344802],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPISM'],
    code: '58829',
    id: 'JPISM',
  },
  {
    name: 'Isa',
    coordinates: [130.6, 32.05],
    city: 'Isa',
    province: 'Kagosima [Kagoshima]',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPISS'],
    code: '58800',
    id: 'JPISS',
  },
  {
    name: 'Iwakuni',
    city: 'Iwakuni',
    province: 'Yamaguti [Yamaguchi]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [132.2188479, 34.1665616],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPIWK'],
    code: '58833',
    id: 'JPIWK',
  },
  {
    name: 'Iyomishima',
    city: 'Iyomishima',
    province: 'Ehime',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [132.7656808, 33.8416238],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPIYM'],
    code: '58812',
    id: 'JPIYM',
  },
  {
    name: 'Kochi',
    city: 'Kochi',
    province: 'Kôti [Kochi]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [133.5311675, 33.5588035],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKCZ'],
    code: '58873',
    id: 'JPKCZ',
  },
  {
    name: 'Kakogawa',
    city: 'Kakogawa',
    province: 'Hyôgo[Hyogo]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [134.8411775, 34.7569503],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKGA'],
    code: '58828',
    id: 'JPKGA',
  },
  {
    name: 'Niigata',
    city: 'Niigata',
    province: 'Niigata',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [139.0364126, 37.9161924],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKIJ'],
    code: '58859',
    id: 'JPKIJ',
  },
  {
    name: 'Kumamoto',
    city: 'Kumamoto',
    province: 'Kumamoto',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [130.7078911, 32.8031004],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKMJ'],
    code: '58800',
    id: 'JPKMJ',
  },
  {
    name: 'Kanda, Fukuoka',
    city: 'Kanda, Fukuoka',
    province: 'Hukuoka [Fukuoka]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [130.4017155, 33.5903547],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKND'],
    code: '58862',
    id: 'JPKND',
  },
  {
    name: 'Kinuura',
    city: 'Kinuura',
    province: 'Aiti [Aichi]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [136.9065647, 35.1801883],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKNU'],
    code: '58872',
    id: 'JPKNU',
  },
  {
    name: 'Kanazawa',
    city: 'Kanazawa',
    province: 'Isikawa [Ishikawa]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [136.6562051, 36.56132540000001],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKNZ'],
    code: '58800',
    id: 'JPKNZ',
  },
  {
    name: 'Kagoshima',
    city: 'Kagoshima',
    province: 'Kagosima [Kagoshima]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [130.5571158, 31.5965535],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKOJ'],
    code: '58807',
    id: 'JPKOJ',
  },
  {
    name: 'Kure, Hiroshima',
    city: 'Kure, Hiroshima',
    province: 'Hirosima [Hiroshima]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [132.5658045, 34.2492541],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKRE'],
    code: '58845',
    id: 'JPKRE',
  },
  {
    name: 'Kashima, Ibaraki',
    city: 'Kashima, Ibaraki',
    province: 'Ibaraki',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.6448128, 35.9657558],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKSM'],
    code: '58839',
    id: 'JPKSM',
  },
  {
    name: 'Kushiro',
    city: 'Kushiro',
    province: 'Hokkaidô [Hokkaido]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [144.3813556, 42.9848542],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKUH'],
    code: '58846',
    id: 'JPKUH',
  },
  {
    name: 'Kawasaki',
    city: 'Kawasaki',
    province: 'Kanagawa',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [139.7030511, 35.5309058],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKWS'],
    code: '58838',
    id: 'JPKWS',
  },
  {
    name: 'Kisarazu',
    city: 'Kisarazu',
    province: 'Tiba [Chiba]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.1062907, 35.6072668],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPKZU'],
    code: '58827',
    id: 'JPKZU',
  },
  {
    name: 'Maizuru',
    city: 'Maizuru',
    province: 'Hyôgo [Kyoto]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [135.7680294, 35.0116363],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPMAI'],
    code: '58848',
    id: 'JPMAI',
  },
  {
    name: 'Miike, Fukuoka',
    city: 'Miike',
    province: 'Hukuoka [Fukuoka]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [130.4801879, 33.04974670000001],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPMII'],
    code: '58851',
    id: 'JPMII',
  },
  {
    name: 'Moji/Kitakyushu',
    coordinates: [130.97, 33.9],
    city: 'Moji/Kitakyushu',
    province: 'Hukuoka [Fukuoka]',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPMOJ'],
    code: '58835',
    id: 'JPMOJ',
  },
  {
    name: 'Mutsure',
    city: 'Mutsure',
    province: 'Yamaguti [Yamaguchi]',
    country: 'Japan',
    alias: [],
    regions: [],
    unlocs: ['JPMTR'],
    code: '58800',
    id: 'JPMTR',
  },
  {
    name: 'Muroran',
    city: 'Muroran',
    province: 'Hokkaidô [Hokkaido]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.9737991, 42.3152306],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPMUR'],
    code: '58855',
    id: 'JPMUR',
  },
  {
    name: 'Matsuyama',
    city: 'Matsuyama',
    province: 'Ehime',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [132.7655752, 33.8391574],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPMYJ'],
    code: '58849',
    id: 'JPMYJ',
  },
  {
    name: 'Naha, Okinawa',
    city: 'Naha, Okinawa',
    province: 'Okinawa',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [127.6791568, 26.2123124],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPNAH'],
    code: '58860',
    id: 'JPNAH',
  },
  {
    name: 'Nakanoseki',
    coordinates: [131.53, 34],
    city: 'Nakanoseki',
    province: 'Yamaguti [Yamaguchi]',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPNAN'],
    code: '58800',
    id: 'JPNAN',
  },
  {
    name: 'Naoetsu',
    city: 'Naoetsu',
    province: 'Niigata',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [138.2550381, 37.1858604],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPNAO'],
    code: '58892',
    id: 'JPNAO',
  },
  {
    name: 'Naoshima',
    city: 'Naoshima',
    province: 'Kagawa',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [133.9956894, 34.4597032],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPNAS'],
    code: '58800',
    id: 'JPNAS',
  },
  {
    name: 'Nagoya, Aichi',
    city: 'Nagoya, Aichi',
    province: 'Aiti [Aichi]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [136.906398, 35.1814464],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPNGO'],
    code: '58857',
    id: 'JPNGO',
  },
  {
    name: 'Nagasaki',
    city: 'Nagasaki',
    province: 'Nagasaki',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [129.877667, 32.7502856],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPNGS'],
    code: '58856',
    id: 'JPNGS',
  },
  {
    name: 'Oita',
    city: 'Oita',
    province: 'Ôita [Oita]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [131.609272, 33.2395578],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPOIT'],
    code: '58805',
    id: 'JPOIT',
  },
  {
    name: 'Okinawa',
    city: 'Okinawa',
    province: 'Okinawa',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [127.8055832, 26.3344266],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPOKA'],
    code: '58860',
    id: 'JPOKA',
  },
  {
    name: 'Omaezaki',
    city: 'Omaezaki',
    province: 'Sizuoka [Shizuoka]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [138.1281269, 34.6379866],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPOMZ'],
    code: '58800',
    id: 'JPOMZ',
  },
  {
    name: 'Onahama',
    city: 'Onahama',
    province: 'Hukusima [Fukushima]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.9055861, 36.9490884],
    timezone: 'Europe/London',
    unlocs: ['JPONA'],
    code: '58864',
    id: 'JPONA',
  },
  {
    name: 'Onomichi',
    city: 'Onomichi',
    province: 'Hirosima [Hiroshima]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [133.2048886, 34.4088756],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPONO'],
    code: '58800',
    id: 'JPONO',
  },
  {
    name: 'Osaka',
    coordinates: [135.5, 34.67],
    city: 'Osaka',
    province: 'Ôsaka [Osaka]',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPOSA'],
    code: '58866',
    id: 'JPOSA',
  },
  {
    name: 'Otake',
    city: 'Otake',
    province: 'Hirosima [Hiroshima]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [132.2224752, 34.2379773],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPOTK'],
    code: '58800',
    id: 'JPOTK',
  },
  {
    name: 'Saganoseki',
    city: 'Saganoseki',
    province: 'Ôita [Oita]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [131.8755159, 33.2489479],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSAG'],
    code: '58800',
    id: 'JPSAG',
  },
  {
    name: 'Shibushi',
    city: 'Shibushi',
    province: 'Kagosima [Kagoshima]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [131.045336, 31.495442],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSBS'],
    code: '58800',
    id: 'JPSBS',
  },
  {
    name: 'Sendai, Miyagi',
    coordinates: [140.92, 38.13],
    city: 'Sendai, Miyagi',
    province: 'Miyagi',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSDJ'],
    code: '58897',
    id: 'JPSDJ',
  },
  {
    name: 'Sendaishinko',
    city: 'Sendaishinko',
    province: 'Miyagi',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.8721, 38.2688373],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSDS'],
    code: '58800',
    id: 'JPSDS',
  },
  {
    name: 'Sodegaura',
    city: 'Sodegaura',
    province: 'Tiba [Chiba]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.1062907, 35.6072668],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSDU'],
    code: '58899',
    id: 'JPSDU',
  },
  {
    name: 'Satsumasendai',
    coordinates: [130.25, 31.82],
    city: 'Satsumasendai',
    province: 'Kagosima [Kagoshima]',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSEN'],
    code: '58800',
    id: 'JPSEN',
  },
  {
    name: 'Shimonoseki',
    city: 'Shimonoseki',
    province: 'Yamaguti [Yamaguchi]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [130.941459, 33.9578307],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSHS'],
    code: '58814',
    id: 'JPSHS',
  },
  {
    name: 'Sakaide',
    city: 'Sakaide',
    province: 'Kagawa',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [133.8605102, 34.3163193],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSKD'],
    code: '58875',
    id: 'JPSKD',
  },
  {
    name: 'Shikama',
    city: 'Shikama',
    province: 'Hyôgo[Hyogo]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [135.1830706, 34.6912688],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSKM'],
    code: '58800',
    id: 'JPSKM',
  },
  {
    name: 'Sakata',
    city: 'Sakata',
    province: 'Yamagata',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [139.8365153, 38.9144331],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSKT'],
    code: '58876',
    id: 'JPSKT',
  },
  {
    name: 'Souma',
    city: 'Souma',
    province: 'Hukusima [Fukushima]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [140.9194697, 37.7966242],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSMA'],
    code: '58800',
    id: 'JPSMA',
  },
  {
    name: 'Sakaiminato',
    city: 'Sakaiminato',
    province: 'Tottori',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [133.2314862, 35.5395916],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSMN'],
    code: '58800',
    id: 'JPSMN',
  },
  {
    name: 'Shimizu',
    city: 'Shimizu',
    province: 'Sizuoka [Shizuoka]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [138.4895275, 35.0158879],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPSMZ'],
    code: '58878',
    id: 'JPSMZ',
  },
  {
    name: 'Takamatsu',
    city: 'Takamatsu',
    province: 'Kagawa',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [134.046574, 34.3427879],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTAK'],
    code: '58800',
    id: 'JPTAK',
  },
  {
    name: 'Tobata/Kitakyushu',
    coordinates: [130.83, 33.88],
    city: 'Tobata/Kitakyushu',
    province: 'Hukuoka [Fukuoka]',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTBT'],
    code: '58881',
    id: 'JPTBT',
  },
  {
    name: 'Tagonoura',
    city: 'Tagonoura',
    province: 'Sizuoka [Shizuoka]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [138.3827596, 34.975562],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTGO'],
    code: '58880',
    id: 'JPTGO',
  },
  {
    name: 'Toyohashi',
    city: 'Toyohashi',
    province: 'Aiti [Aichi]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [136.9065647, 35.1801883],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTHS'],
    code: '58883',
    id: 'JPTHS',
  },
  {
    name: 'Tokushima',
    city: 'Tokushima',
    province: 'Tokusima [Tokushima]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [134.5548438, 34.0702703],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTKS'],
    code: '58800',
    id: 'JPTKS',
  },
  {
    name: 'Tokuyama',
    city: 'Tokuyama',
    province: 'Yamaguti [Yamaguchi]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [131.8144403, 34.0647516],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTKY'],
    code: '58885',
    id: 'JPTKY',
  },
  {
    name: 'Tsukumi',
    city: 'Tsukumi',
    province: 'Ôita [Oita]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [131.8612771, 33.0721832],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTMI'],
    code: '58889',
    id: 'JPTMI',
  },
  {
    name: 'Tomakomai',
    city: 'Tomakomai',
    province: 'Hokkaidô [Hokkaido]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [141.6053721, 42.6343021],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTMK'],
    code: '58811',
    id: 'JPTMK',
  },
  {
    name: 'Tonda',
    city: 'Tonda',
    province: 'Yamaguti [Yamaguchi]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [131.7600531, 34.0748851],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTND'],
    code: '58800',
    id: 'JPTND',
  },
  {
    name: 'Tokachi',
    city: 'Tokachi',
    province: 'Hokkaidô [Hokkaido]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [143.691, 42.915],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTOK'],
    code: '58800',
    id: 'JPTOK',
  },
  {
    name: 'Toyamashinko',
    city: 'Toyamashinko',
    province: 'Toyama',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [137.2136768, 36.6959518],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTOS'],
    code: '58800',
    id: 'JPTOS',
  },
  {
    name: 'Toyama',
    city: 'Toyama',
    province: 'Toyama',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [137.2136768, 36.6959518],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTOY'],
    code: '58810',
    id: 'JPTOY',
  },
  {
    name: 'Tsuruga',
    city: 'Tsuruga',
    province: 'Hukui [Fukui]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [136.0554408, 35.6452443],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTRG'],
    code: '58800',
    id: 'JPTRG',
  },
  {
    name: 'Tokyo',
    coordinates: [139.75, 35.68],
    city: 'Tokyo',
    province: 'Tôkyô [Tokyo]',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPTYO'],
    code: '58886',
    id: 'JPTYO',
  },
  {
    name: 'Ube',
    city: 'Ube',
    province: 'Yamaguti [Yamaguchi]',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [131.2467858, 33.9515087],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPUBJ'],
    code: '58888',
    id: 'JPUBJ',
  },
  {
    name: 'Kobe',
    coordinates: [135.17, 34.68],
    city: 'Kobe',
    province: 'Hyôgo[Hyogo]',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPUKB'],
    code: '58840',
    id: 'JPUKB',
  },
  {
    name: 'Unten',
    city: 'Unten',
    province: 'Okinawa',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [128.0033058, 26.681767],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPUNT'],
    code: '58800',
    id: 'JPUNT',
  },
  {
    name: 'Wakayama',
    city: 'Wakayama',
    province: 'Wakayama',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [135.1708083, 34.2305113],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPWAK'],
    code: '58891',
    id: 'JPWAK',
  },
  {
    name: 'Yatsushiro',
    city: 'Yatsushiro',
    province: 'Kumamoto',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [130.6017433, 32.5074371],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPYAT'],
    code: '58887',
    id: 'JPYAT',
  },
  {
    name: 'Yokkaichi',
    city: 'Yokkaichi',
    province: 'Mie',
    country: 'Japan',
    alias: [],
    regions: [],
    coordinates: [136.6244847, 34.9651567],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPYKK'],
    code: '58894',
    id: 'JPYKK',
  },
  {
    name: 'Yokohama',
    coordinates: [139.65, 35.45],
    city: 'Yokohama',
    province: 'Kanagawa',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPYOK'],
    code: '58895',
    id: 'JPYOK',
  },
  {
    name: 'Yokosuka',
    coordinates: [139.67, 35.28],
    city: 'Yokosuka',
    province: 'Kanagawa',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Tokyo',
    unlocs: ['JPYOS'],
    code: '58896',
    id: 'JPYOS',
  },
  {
    name: 'Yawata/Kitakyushu',
    coordinates: [130.8, 33.85],
    city: 'Yawata/Kitakyushu',
    province: 'Hukuoka [Fukuoka]',
    country: 'Japan',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['JPYWT'],
    code: '58893',
    id: 'JPYWT',
  },
  {
    name: 'Mombasa',
    city: 'Mombasa',
    country: 'Kenya',
    alias: [],
    regions: [],
    coordinates: [39.6682065, -4.0434771],
    province: 'Mombasa',
    timezone: 'Africa/Nairobi',
    unlocs: ['KEMBA'],
    code: '77913',
    id: 'KEMBA',
  },
  {
    name: 'Nairobi',
    city: 'Nairobi',
    country: 'Kenya',
    alias: [],
    regions: [],
    coordinates: [36.8219462, -1.2920659],
    province: 'Nairobi',
    timezone: 'Africa/Nairobi',
    unlocs: ['KENBO'],
    code: '77999',
    id: 'KENBO',
  },
  {
    name: 'Kampong Saom',
    coordinates: [103.52, 10.6],
    city: 'Kâmpóng Saôm',
    country: 'Cambodia',
    alias: [],
    regions: [],
    province: 'Preah Sihanouk',
    timezone: 'Asia/Phnom_Penh',
    unlocs: ['KHKOS'],
    code: '55500',
    id: 'KHKOS',
  },
  {
    name: 'Phnom Penh',
    city: 'Phnom Penh',
    country: 'Cambodia',
    alias: [],
    regions: [],
    coordinates: [104.8921668, 11.5448729],
    province: 'Phnom Penh',
    timezone: 'Asia/Phnom_Penh',
    unlocs: ['KHPNH'],
    code: '55500',
    id: 'KHPNH',
  },
  {
    name: 'Tarawa',
    city: 'Tarawa',
    country: 'Kiribati',
    alias: [],
    regions: [],
    coordinates: [172.9790528, 1.3290526],
    timezone: 'Pacific/Tarawa',
    unlocs: ['KITRW'],
    code: '77999',
    id: 'KITRW',
  },
  {
    name: 'Mutsamudu, Anjouan',
    coordinates: [44.38, -12.15],
    city: 'Mutsamudu, Anjouan',
    province: 'Anjouan',
    country: 'Comoros',
    alias: [],
    regions: [],
    timezone: 'Indian/Comoro',
    unlocs: ['KMMUT'],
    code: '78900',
    id: 'KMMUT',
  },
  {
    name: 'Moroni',
    city: 'Moroni',
    country: 'Comoros',
    alias: [],
    regions: [],
    coordinates: [43.2473146, -11.7172157],
    province: 'Grande Comore',
    timezone: 'Indian/Comoro',
    unlocs: ['KMYVA'],
    code: '78900',
    id: 'KMYVA',
  },
  {
    name: 'Basseterre, Saint Kitts',
    city: 'Basseterre, Saint Kitts',
    country: 'Saint Kitts and Nevis',
    alias: [],
    regions: [],
    coordinates: [-62.7176924, 17.3026058],
    province: 'Saint George Basseterre Parish',
    timezone: 'America/St_Kitts',
    unlocs: ['KNBAS'],
    id: 'KNBAS',
  },
  {
    name: 'Nevis',
    coordinates: [-62.58, 17.15],
    city: 'Nevis',
    country: 'Saint Kitts and Nevis',
    alias: [],
    regions: [],
    timezone: 'America/St_Kitts',
    unlocs: ['KNNEV'],
    id: 'KNNEV',
  },
  {
    name: 'Chongjin',
    city: 'Chongjin',
    country: "Korea, Democratic People's Republic of",
    alias: [],
    regions: [],
    coordinates: [129.723402, 41.7669671],
    province: 'North Hamgyong',
    timezone: 'Asia/Pyongyang',
    unlocs: ['KPCHO'],
    code: '77999',
    id: 'KPCHO',
  },
  {
    name: 'Wonsan',
    city: 'Wonsan',
    country: "Korea, Democratic People's Republic of",
    alias: [],
    regions: [],
    coordinates: [127.4460001, 39.1538529],
    province: 'Kangwon',
    timezone: 'Asia/Pyongyang',
    unlocs: ['KPWON'],
    code: '77999',
    id: 'KPWON',
  },
  {
    name: 'Jeju',
    coordinates: [126.52, 33.5],
    city: 'Jeju',
    province: 'Jeju-teukbyeoljachido [Jeju]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRCHA'],
    id: 'KRCHA',
  },
  {
    name: 'Jinhae',
    coordinates: [128.65, 35.13],
    city: 'Jinhae',
    province: 'Gyeongsangnamdo [Kyongsangnam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRCHF'],
    code: '58015',
    id: 'KRCHF',
  },
  {
    name: 'Janghang/Seocheon-gun',
    coordinates: [126.68, 36],
    city: 'Janghang/Seocheon-gun',
    province: "Chungcheongnamdo [Ch'ungch'ongnam-do]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRCHG'],
    id: 'KRCHG',
  },
  {
    name: 'Cheonan',
    coordinates: [127.15, 36.8],
    city: 'Cheonan',
    province: "Chungcheongnamdo [Ch'ungch'ongnam-do]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRCHO'],
    id: 'KRCHO',
  },
  {
    name: 'Chungju',
    coordinates: [127.92, 36.97],
    city: 'Chungju',
    province: "Chungcheongbugdo [Ch'ungch'ongbuk-do]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRCHU'],
    id: 'KRCHU',
  },
  {
    name: 'Changwon',
    coordinates: [128.67, 35.22],
    city: 'Changwon',
    province: 'Gyeongsangnamdo [Kyongsangnam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRCHW'],
    id: 'KRCHW',
  },
  {
    name: 'Cheongwon-gun',
    coordinates: [127.48, 36.63],
    city: 'Cheongwon-gun',
    province: "Chungcheongbugdo [Ch'ungch'ongbuk-do]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRCWO'],
    id: 'KRCWO',
  },
  {
    name: 'Goyang',
    coordinates: [126.82, 37.65],
    city: 'Goyang',
    province: 'Gyeonggido [Kyonggi-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRGYG'],
    id: 'KRGYG',
  },
  {
    name: 'Jinju',
    coordinates: [128.08, 35.17],
    city: 'Jinju',
    province: 'Gyeongsangnamdo [Kyongsangnam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRHIN'],
    id: 'KRHIN',
  },
  {
    name: 'Hamyang-gun',
    coordinates: [127.72, 35.52],
    city: 'Hamyang-gun',
    province: 'Gyeongsangnamdo [Kyongsangnam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRHYG'],
    id: 'KRHYG',
  },
  {
    name: 'Hyeonpoong/Daegu',
    coordinates: [128.43, 35.68],
    city: 'Hyeonpoong/Daegu',
    province: "Daegu Gwang'yeogsi [Taegu-Kwangyokshi]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRHYP'],
    id: 'KRHYP',
  },
  {
    name: 'Incheon',
    city: 'Incheon',
    province: 'Incheon',
    country: 'South Korea',
    alias: [],
    regions: [],
    coordinates: [126.6168962, 37.4767894],
    timezone: 'Asia/Seoul',
    unlocs: ['KRICH'],
    id: 'KRICH',
  },
  {
    name: 'Incheon',
    coordinates: [126.62, 37.45],
    city: 'Incheon',
    province: "Incheon Gwang'yeogsi [Incheon]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRINC'],
    id: 'KRINC',
  },
  {
    name: 'Jeongeup',
    coordinates: [126.85, 35.57],
    city: 'Jeongeup',
    province: 'Jeonrabugdo [Chollabuk-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRJEO'],
    id: 'KRJEO',
  },
  {
    name: 'Geoje',
    coordinates: [128.68, 34.9],
    city: 'Geoje',
    province: 'Gyeongsangnamdo [Kyongsangnam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRJGE'],
    id: 'KRJGE',
  },
  {
    name: 'Jangheung-gun',
    coordinates: [126.9, 34.67],
    city: 'Jangheung-gun',
    province: 'Jeonranamdo [Chollanam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRJHG'],
    id: 'KRJHG',
  },
  {
    name: 'Gwangyang',
    coordinates: [127.7, 34.93],
    city: 'Gwangyang',
    province: 'Jeonranamdo [Chollanam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRKAN'],
    id: 'KRKAN',
  },
  {
    name: 'Geoje',
    coordinates: [128.58, 34.85],
    city: 'Geoje',
    province: 'Gyeongsangnamdo [Kyongsangnam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRKJE'],
    id: 'KRKJE',
  },
  {
    name: 'Gimcheon',
    coordinates: [128.1, 36.12],
    city: 'Gimcheon',
    province: 'Gyeongsangbugdo [Kyongsangbuk-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRKMC'],
    id: 'KRKMC',
  },
  {
    name: 'Pohang',
    coordinates: [129.37, 36.03],
    city: 'Pohang',
    province: 'Gyeongsangbugdo [Kyongsangbuk-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRKPO'],
    code: '58055',
    id: 'KRKPO',
  },
  {
    name: 'Gumi',
    coordinates: [128.33, 36.12],
    city: 'Gumi',
    province: 'Gyeongsangbugdo [Kyongsangbuk-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRKUM'],
    id: 'KRKUM',
  },
  {
    name: 'Gunsan',
    coordinates: [126.72, 35.98],
    city: 'Gunsan',
    province: 'Jeonrabugdo [Chollabuk-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRKUV'],
    code: '58028',
    id: 'KRKUV',
  },
  {
    name: 'Gwangju',
    coordinates: [126.9, 35.15],
    city: 'Gwangju',
    province: "Gwangju Gwang'yeogsi [Kwangju-Kwangyokshi]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRKWJ'],
    id: 'KRKWJ',
  },
  {
    name: 'Gwangju',
    coordinates: [127.25, 37.4],
    city: 'Gwangju',
    province: 'Gyeonggido [Kyonggi-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRKWU'],
    id: 'KRKWU',
  },
  {
    name: 'Masan',
    coordinates: [128.57, 35.2],
    city: 'Masan',
    province: 'Gyeongsangnamdo [Kyongsangnam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRMAS'],
    code: '58033',
    id: 'KRMAS',
  },
  {
    name: 'Mokpo',
    coordinates: [126.38, 34.8],
    city: 'Mokpo',
    province: 'Jeonranamdo [Chollanam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRMOK'],
    code: '58035',
    id: 'KRMOK',
  },
  {
    name: 'Namyang/Goheung-gun',
    coordinates: [127.33, 34.72],
    city: 'Namyang/Goheung-gun',
    province: 'Jeonranamdo [Chollanam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRNYJ'],
    id: 'KRNYJ',
  },
  {
    name: 'Namyangju',
    coordinates: [127.2, 37.63],
    city: 'Namyangju',
    province: 'Gyeonggido [Kyonggi-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRNYU'],
    id: 'KRNYU',
  },
  {
    name: 'Okpo/Geoje',
    coordinates: [128.7, 34.88],
    city: 'Okpo/Geoje',
    province: 'Gyeongsangnamdo [Kyongsangnam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KROKP'],
    id: 'KROKP',
  },
  {
    name: 'Onsan/Ulsan',
    coordinates: [129.35, 35.43],
    city: 'Onsan/Ulsan',
    province: "Ulsan Gwang'yeogsi [Ulsan-Kwangyokshi]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRONS'],
    id: 'KRONS',
  },
  {
    name: 'Busan',
    coordinates: [129.05, 35.13],
    city: 'Busan',
    province: "Busan Gwang'yeogsi [Pusan-Kwangyokshi]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRPUS'],
    code: '58023',
    id: 'KRPUS',
  },
  {
    name: 'Samcheonpo/Sacheon',
    coordinates: [128.07, 34.92],
    city: 'Samcheonpo/Sacheon',
    province: 'Gyeongsangnamdo [Kyongsangnam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRSCP'],
    id: 'KRSCP',
  },
  {
    name: 'Seoul',
    coordinates: [126.93, 37.52],
    city: 'Seoul',
    province: 'Seoul-teukbyeolsi [Seoul]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRSEL'],
    id: 'KRSEL',
  },
  {
    name: 'Seosan',
    coordinates: [126.45, 36.78],
    city: 'Seosan',
    province: "Chungcheongnamdo [Ch'ungch'ongnam-do]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRSSA'],
    id: 'KRSSA',
  },
  {
    name: 'Daegu',
    coordinates: [128.58, 35.87],
    city: 'Daegu',
    province: "Daegu Gwang'yeogsi [Taegu-Kwangyokshi]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRTAE'],
    id: 'KRTAE',
  },
  {
    name: 'Daejeon',
    coordinates: [127.42, 36.32],
    city: 'Daejeon',
    province: "Daejeon Gwang'yeogsi [Taejon-Kwangyokshi]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRTJN'],
    id: 'KRTJN',
  },
  {
    name: 'Tongyeong',
    coordinates: [128.42, 34.83],
    city: 'Tongyeong',
    province: 'Gyeongsangnamdo [Kyongsangnam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRTYG'],
    id: 'KRTYG',
  },
  {
    name: 'Ulsan',
    coordinates: [129.32, 35.53],
    city: 'Ulsan',
    province: "Ulsan Gwang'yeogsi [Ulsan-Kwangyokshi]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRUSN'],
    code: '58061',
    id: 'KRUSN',
  },
  {
    name: 'Yesan',
    coordinates: [126.83, 36.68],
    city: 'Yesan',
    province: "Chungcheongnamdo [Ch'ungch'ongnam-do]",
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRYES'],
    id: 'KRYES',
  },
  {
    name: 'Yeosu',
    coordinates: [127.72, 34.75],
    city: 'Yeosu',
    province: 'Jeonranamdo [Chollanam-do]',
    country: 'South Korea',
    alias: [],
    regions: [],
    timezone: 'Asia/Seoul',
    unlocs: ['KRYOS'],
    code: '58065',
    id: 'KRYOS',
  },
  {
    name: 'Kuwait',
    city: 'Kuwait',
    country: 'Kuwait',
    alias: [],
    regions: [],
    coordinates: [47.9774052, 29.375859],
    province: 'Al Asimah',
    timezone: 'Asia/Kuwait',
    unlocs: ['KWKWI'],
    code: '51321',
    id: 'KWKWI',
  },
  {
    name: 'Shuaiba',
    coordinates: [48.13, 29.03],
    city: 'Shuaiba',
    country: 'Kuwait',
    alias: [],
    regions: [],
    province: 'Ahmadi',
    timezone: 'Asia/Kuwait',
    unlocs: ['KWSAA'],
    code: '51350',
    id: 'KWSAA',
  },
  {
    name: 'Shuwaikh',
    city: 'Shuwaikh',
    country: 'Kuwait',
    alias: [],
    regions: [],
    coordinates: [47.9670891, 29.3671915],
    province: 'Al Asimah',
    timezone: 'Asia/Kuwait',
    unlocs: ['KWSWK'],
    code: '51300',
    id: 'KWSWK',
  },
  {
    name: 'Grand Cayman',
    coordinates: [-81.35, 19.28],
    city: 'Grand Cayman',
    country: 'Cayman Islands',
    alias: [],
    regions: [],
    timezone: 'America/Cayman',
    unlocs: ['KYGCM'],
    code: '24410',
    id: 'KYGCM',
  },
  {
    name: 'Georgetown, Grand Cayman',
    coordinates: [-81.38, 19.3],
    city: 'Georgetown, Grand Cayman',
    country: 'Cayman Islands',
    alias: [],
    regions: [],
    province: 'George Town',
    timezone: 'America/Cayman',
    unlocs: ['KYGEC'],
    code: '24410',
    id: 'KYGEC',
  },
  {
    name: 'Beirut',
    coordinates: [35.48, 33.83],
    city: 'Beirut',
    country: 'Lebanon',
    alias: [],
    regions: [],
    province: 'Beirut',
    timezone: 'Asia/Beirut',
    unlocs: ['LBBEY'],
    code: '50401',
    id: 'LBBEY',
  },
  {
    name: 'Tripoli',
    city: 'Tripoli',
    country: 'Lebanon',
    alias: [],
    regions: [],
    coordinates: [35.8308371, 34.4380941],
    province: 'North',
    timezone: 'Asia/Beirut',
    unlocs: ['LBKYE'],
    code: '50400',
    id: 'LBKYE',
  },
  {
    name: 'Castries',
    coordinates: [-60.97, 14.02],
    city: 'Castries',
    country: 'Saint Lucia',
    alias: [],
    regions: [],
    province: 'Castries',
    timezone: 'America/St_Lucia',
    unlocs: ['LCCAS'],
    code: '24865',
    id: 'LCCAS',
  },
  {
    name: 'Saint Lucia Apt',
    coordinates: [-60.93, 13.7],
    city: 'Saint Lucia Apt',
    country: 'Saint Lucia',
    alias: [],
    regions: [],
    province: 'Queensland',
    unlocs: ['LCSLU'],
    id: 'LCSLU',
  },
  {
    name: 'Vieux Fort',
    city: 'Vieux Fort',
    country: 'Saint Lucia',
    alias: [],
    regions: [],
    coordinates: [-60.94964329999999, 13.720608],
    province: 'Vieux Fort',
    timezone: 'America/St_Lucia',
    unlocs: ['LCVIF'],
    code: '24867',
    id: 'LCVIF',
  },
  {
    name: 'Colombo',
    coordinates: [79.85, 6.92],
    city: 'Colombo',
    country: 'Sri Lanka',
    alias: [],
    regions: [],
    province: 'Western Province',
    timezone: 'Asia/Colombo',
    unlocs: ['LKCMB'],
    code: '54201',
    id: 'LKCMB',
  },
  {
    name: 'Monrovia',
    coordinates: [-10.8, 6.3],
    city: 'Monrovia',
    province: 'Montserrado',
    country: 'Liberia',
    alias: [],
    regions: [],
    timezone: 'Africa/Monrovia',
    unlocs: ['LRMLW'],
    code: '76529',
    id: 'LRMLW',
  },
  {
    name: 'Klaipeda',
    city: 'Klaipeda',
    country: 'Lithuania',
    alias: [],
    regions: [],
    coordinates: [21.1442794, 55.70329479999999],
    province: 'Klaipėda County',
    timezone: 'Europe/Vilnius',
    unlocs: ['LTKLJ'],
    code: '45101',
    id: 'LTKLJ',
  },
  {
    timezone: 'Europe/Riga',
    coordinates: [25.27645, 57.69487600000001],
    name: 'Aizkraukle',
    city: 'Aizkraukle',
    province: 'Burtnieku novads (Burtnieki)',
    country: 'Latvia',
    alias: [],
    regions: [],
    unlocs: ['LVAIZ'],
    code: '44900',
    id: 'LVAIZ',
  },
  {
    name: 'Riga',
    city: 'Riga',
    province: 'Riga',
    country: 'Latvia',
    alias: [],
    regions: [],
    coordinates: [24.093533, 56.961029],
    timezone: 'Europe/Riga',
    unlocs: ['LVRIX'],
    code: '44911',
    id: 'LVRIX',
  },
  {
    timezone: 'Europe/Riga',
    coordinates: [24.4923901, 57.7069608],
    name: 'Salacgriva',
    city: 'Salacgriva',
    province: 'Limbažu novads (Limbaži)',
    country: 'Latvia',
    alias: [],
    regions: [],
    unlocs: ['LVSAL'],
    code: '44900',
    id: 'LVSAL',
  },
  {
    name: 'Ventspils',
    city: 'Ventspils',
    province: 'Ventspils',
    country: 'Latvia',
    alias: [],
    regions: [],
    coordinates: [21.8587558, 57.2833682],
    timezone: 'Europe/Riga',
    unlocs: ['LVVNT'],
    code: '44951',
    id: 'LVVNT',
  },
  {
    name: 'Bingazi (Benghazi)',
    city: 'Bingazi (Benghazi)',
    country: 'Libya',
    alias: [],
    regions: [],
    coordinates: [20.1879106, 32.0947711],
    province: 'Banghazi',
    timezone: 'Africa/Tripoli',
    unlocs: ['LYBEN'],
    code: '72501',
    id: 'LYBEN',
  },
  {
    name: 'Al Khums',
    coordinates: [14.27, 32.65],
    city: 'Al Khums',
    country: 'Libya',
    alias: [],
    regions: [],
    province: 'Al Murqub',
    timezone: 'Africa/Tripoli',
    unlocs: ['LYKHO'],
    code: '72500',
    id: 'LYKHO',
  },
  {
    name: 'Misurata',
    city: 'Misurata',
    country: 'Libya',
    alias: [],
    regions: [],
    coordinates: [15.1025075, 32.3196827],
    province: 'Misratah',
    timezone: 'Africa/Tripoli',
    unlocs: ['LYMRA'],
    code: '72500',
    id: 'LYMRA',
  },
  {
    name: 'Tripoli',
    city: 'Tripoli',
    country: 'Libya',
    alias: [],
    regions: [],
    coordinates: [13.1913383, 32.8872094],
    province: 'Tripoli',
    timezone: 'Africa/Tripoli',
    unlocs: ['LYTIP'],
    code: '72525',
    id: 'LYTIP',
  },
  {
    name: 'Agadir',
    city: 'Agadir',
    country: 'Morocco',
    alias: [],
    regions: [],
    coordinates: [-9.5981072, 30.4277547],
    province: 'Souss-Massa-Drâa',
    timezone: 'Africa/Casablanca',
    unlocs: ['MAAGA'],
    code: '71403',
    id: 'MAAGA',
  },
  {
    name: 'Casablanca',
    coordinates: [-7.6, 33.58],
    city: 'Casablanca',
    province: 'Casablanca [Dar el Beïda]*',
    country: 'Morocco',
    alias: [],
    regions: [],
    timezone: 'Africa/Casablanca',
    unlocs: ['MACAS'],
    code: '71401',
    id: 'MACAS',
  },
  {
    name: 'El Jadida',
    coordinates: [-8.5, 33.25],
    city: 'El Jadida',
    province: 'El Jadida',
    country: 'Morocco',
    alias: [],
    regions: [],
    timezone: 'Africa/Casablanca',
    unlocs: ['MAELJ'],
    code: '71400',
    id: 'MAELJ',
  },
  {
    name: 'Mohammedia',
    city: 'Mohammedia',
    country: 'Morocco',
    alias: [],
    regions: [],
    coordinates: [-7.384854699999999, 33.6835086],
    province: 'Grand Casablanca',
    timezone: 'Africa/Casablanca',
    unlocs: ['MAMOH'],
    code: '71418',
    id: 'MAMOH',
  },
  {
    name: 'Kenitra (ex Port Lyautey)',
    city: 'Kenitra (ex Port Lyautey)',
    country: 'Morocco',
    alias: [],
    regions: [],
    coordinates: [-6.5890166, 34.2540503],
    province: 'Gharb-Chrarda-Beni Hssen',
    timezone: 'Africa/Casablanca',
    unlocs: ['MANNA'],
    id: 'MANNA',
  },
  {
    name: 'Tanger Med',
    city: 'Tanger Med',
    province: 'Tanger-Assilah',
    country: 'Morocco',
    alias: [],
    regions: [],
    coordinates: [-5.7907302, 35.7407719],
    timezone: 'Africa/Casablanca',
    unlocs: ['MAPTM'],
    id: 'MAPTM',
  },
  {
    name: 'Safi',
    city: 'Safi',
    country: 'Morocco',
    alias: [],
    regions: [],
    coordinates: [-9.2272033, 32.3008151],
    province: 'Doukkala-Abda',
    timezone: 'Africa/Casablanca',
    unlocs: ['MASFI'],
    code: '71419',
    id: 'MASFI',
  },
  {
    name: 'Tangier',
    coordinates: [-5.82, 35.78],
    city: 'Tangier',
    country: 'Morocco',
    alias: [],
    regions: [],
    province: 'Tangier-Tetouan',
    timezone: 'Africa/Casablanca',
    unlocs: ['MATNG'],
    code: '71425',
    id: 'MATNG',
  },
  {
    name: 'Monaco',
    city: 'Monaco',
    country: 'Monaco',
    alias: [],
    regions: [],
    coordinates: [7.420815999999999, 43.73741099999999],
    timezone: 'Europe/Monaco',
    unlocs: ['MCMON'],
    id: 'MCMON',
  },
  {
    name: 'Giurgiulesti',
    coordinates: [28.18, 45.47],
    city: 'Giurgiulesti',
    province: 'Cahul',
    country: 'Moldova, Republic of',
    alias: [],
    regions: [],
    timezone: 'Europe/Bucharest',
    unlocs: ['MDGIU'],
    id: 'MDGIU',
  },
  {
    name: 'Bar',
    coordinates: [19.08, 42.08],
    city: 'Bar',
    country: 'Montenegro',
    alias: [],
    regions: [],
    province: 'Bar',
    timezone: 'Europe/Podgorica',
    unlocs: ['MEBAR'],
    code: '47901',
    id: 'MEBAR',
  },
  {
    name: 'Antsiranana',
    city: 'Antsiranana',
    country: 'Madagascar',
    alias: [],
    regions: [],
    coordinates: [49.2942833, -12.3231349],
    province: 'Antsiranana',
    timezone: 'Indian/Antananarivo',
    unlocs: ['MGDIE'],
    code: '78800',
    id: 'MGDIE',
  },
  {
    name: 'Ehoala',
    coordinates: [46.95, -25.1],
    city: 'Ehoala',
    province: 'Toliara',
    country: 'Madagascar',
    alias: [],
    regions: [],
    timezone: 'Indian/Antananarivo',
    unlocs: ['MGEHL'],
    code: '78800',
    id: 'MGEHL',
  },
  {
    name: 'Majunga (Mahajanga)',
    city: 'Majunga (Mahajanga)',
    country: 'Madagascar',
    alias: [],
    regions: [],
    coordinates: [46.3336857, -15.6921494],
    province: 'Mahajanga',
    timezone: 'Indian/Antananarivo',
    unlocs: ['MGMJN'],
    code: '78800',
    id: 'MGMJN',
  },
  {
    name: 'Nosy-Be',
    coordinates: [48.25, -13.33],
    city: 'Nosy-Be',
    country: 'Madagascar',
    alias: [],
    regions: [],
    timezone: 'Indian/Antananarivo',
    unlocs: ['MGNOS'],
    code: '78800',
    id: 'MGNOS',
  },
  {
    name: 'Tulear (Toliara)',
    city: 'Tulear (Toliara)',
    country: 'Madagascar',
    alias: [],
    regions: [],
    coordinates: [43.6854936, -23.3516191],
    province: 'Toliara',
    timezone: 'Indian/Antananarivo',
    unlocs: ['MGTLE'],
    code: '78800',
    id: 'MGTLE',
  },
  {
    name: 'Tamatave (Toamasina)',
    city: 'Tamatave (Toamasina)',
    country: 'Madagascar',
    alias: [],
    regions: [],
    coordinates: [49.3957836, -18.1442811],
    province: 'Toamasina',
    timezone: 'Indian/Antananarivo',
    unlocs: ['MGTMM'],
    code: '78845',
    id: 'MGTMM',
  },
  {
    name: 'Toamasina',
    city: 'Toamasina',
    country: 'Madagascar',
    alias: [],
    regions: [],
    coordinates: [49.3957836, -18.1442811],
    province: 'Toamasina',
    timezone: 'Indian/Antananarivo',
    unlocs: ['MGTOA'],
    code: '78845',
    id: 'MGTOA',
  },
  {
    name: 'Majuro',
    coordinates: [171.38, 7.1],
    city: 'Majuro',
    province: 'Majuro',
    country: 'Marshall Islands',
    alias: [],
    regions: [],
    timezone: 'Pacific/Majuro',
    unlocs: ['MHMAJ'],
    code: '68190',
    id: 'MHMAJ',
  },
  {
    name: 'Bamako',
    city: 'Bamako',
    country: 'Mali',
    alias: [],
    regions: [],
    coordinates: [-8.0028892, 12.6392316],
    province: 'Bamako',
    timezone: 'Africa/Bamako',
    unlocs: ['MLBKO'],
    id: 'MLBKO',
  },
  {
    name: 'Yangon',
    city: 'Yangon',
    country: 'Myanmar',
    alias: [],
    regions: [],
    coordinates: [96.19513200000002, 16.8660694],
    province: 'Yangon Region',
    timezone: 'Asia/Rangoon',
    unlocs: ['MMRGN'],
    id: 'MMRGN',
  },
  {
    name: 'Macau',
    coordinates: [113.53, 22.2],
    city: 'Macau',
    country: 'Macao',
    alias: [],
    regions: [],
    timezone: 'Europe/Amsterdam',
    unlocs: ['MOMFM'],
    code: '56649',
    id: 'MOMFM',
  },
  {
    name: 'Saipan',
    city: 'Saipan',
    country: 'Northern Mariana Islands',
    alias: [],
    regions: [],
    coordinates: [145.750967, 15.177801],
    province: 'Saipan',
    timezone: 'Pacific/Saipan',
    unlocs: ['MPSPN'],
    code: '96165',
    id: 'MPSPN',
  },
  {
    name: 'Fort-de-France',
    city: 'Fort-de-France',
    country: 'Martinique',
    alias: [],
    regions: [],
    coordinates: [-61.0587804, 14.6160647],
    province: 'Fort-de-France',
    timezone: 'America/Martinique',
    unlocs: ['MQFDF'],
    code: '28399',
    id: 'MQFDF',
  },
  {
    name: 'Nouadhibou',
    city: 'Nouadhibou',
    country: 'Mauritania',
    alias: [],
    regions: [],
    coordinates: [-17.0362272, 20.9425179],
    province: 'Dakhlet Nouadhibou',
    timezone: 'Africa/Nouakchott',
    unlocs: ['MRNDB'],
    code: '74165',
    id: 'MRNDB',
  },
  {
    name: 'Nouakchott',
    city: 'Nouakchott',
    country: 'Mauritania',
    alias: [],
    regions: [],
    coordinates: [-15.9582372, 18.0735299],
    province: 'Nouakchott',
    timezone: 'Africa/Nouakchott',
    unlocs: ['MRNKC'],
    code: '74100',
    id: 'MRNKC',
  },
  {
    name: 'Marsaxlokk',
    coordinates: [14.53, 35.83],
    city: 'Marsaxlokk',
    province: 'Birzebbuga',
    country: 'Malta',
    alias: [],
    regions: [],
    timezone: 'Europe/Malta',
    unlocs: ['MTMAR'],
    code: '47306',
    id: 'MTMAR',
  },
  {
    timezone: 'Europe/Malta',
    coordinates: [14.4199322, 35.8904967],
    name: 'Mgarr, Gozo',
    city: 'Mgarr, Gozo',
    province: 'Attard',
    country: 'Malta',
    alias: [],
    regions: [],
    unlocs: ['MTMGZ'],
    code: '47300',
    id: 'MTMGZ',
  },
  {
    name: 'Valletta',
    coordinates: [14.5, 35.88],
    city: 'Valletta',
    province: 'Valletta',
    country: 'Malta',
    alias: [],
    regions: [],
    timezone: 'Europe/Malta',
    unlocs: ['MTMLA'],
    code: '47305',
    id: 'MTMLA',
  },
  {
    name: 'Port Louis',
    city: 'Port Louis',
    country: 'Mauritius',
    alias: [],
    regions: [],
    coordinates: [57.502332, -20.1668958],
    province: 'Port Louis',
    timezone: 'Indian/Mauritius',
    unlocs: ['MUPLU'],
    code: '78501',
    id: 'MUPLU',
  },
  {
    name: 'Male',
    coordinates: [73.5, 4.17],
    city: 'Male',
    province: 'Male',
    country: 'Maldives',
    alias: [],
    regions: [],
    timezone: 'Indian/Maldives',
    unlocs: ['MVMLE'],
    code: '56805',
    id: 'MVMLE',
  },
  {
    name: 'Blantyre',
    city: 'Blantyre',
    country: 'Malawi',
    alias: [],
    regions: [],
    coordinates: [35.0167866, -15.7666707],
    province: 'Southern Region',
    timezone: 'Africa/Blantyre',
    unlocs: ['MWBLZ'],
    id: 'MWBLZ',
  },
  {
    name: 'Acapulco',
    coordinates: [-99.87, 16.85],
    city: 'Acapulco',
    province: 'Guerrero',
    country: 'Mexico',
    alias: [],
    regions: [],
    unlocs: ['MXACA'],
    code: '20101',
    id: 'MXACA',
  },
  {
    name: 'Altamira',
    coordinates: [-97.92, 22.4],
    city: 'Altamira',
    province: 'Tamaulipas',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Mexico_City',
    unlocs: ['MXATM'],
    code: '20193',
    id: 'MXATM',
  },
  {
    name: 'Coatzacoalcos',
    coordinates: [-94.4, 18.13],
    city: 'Coatzacoalcos',
    province: 'Veracruz',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Mexico_City',
    unlocs: ['MXCOA'],
    code: '20171',
    id: 'MXCOA',
  },
  {
    name: 'Ensenada',
    coordinates: [-116.6, 31.85],
    city: 'Ensenada',
    province: 'Baja California',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Tijuana',
    unlocs: ['MXESE'],
    code: '20108',
    id: 'MXESE',
  },
  {
    name: 'Guaymas',
    coordinates: [-110.88, 27.92],
    city: 'Guaymas',
    province: 'Sonora',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Hermosillo',
    unlocs: ['MXGYM'],
    code: '20113',
    id: 'MXGYM',
  },
  {
    name: 'Ciudad de Mexico',
    coordinates: [-99.13, 19.43],
    city: 'Ciudad de México',
    province: 'Distrito Federal',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Mexico_City',
    unlocs: ['MXMEX'],
    code: '20195',
    id: 'MXMEX',
  },
  {
    name: 'Progreso',
    coordinates: [-89.65, 21.27],
    city: 'Progreso',
    province: 'Yucatán',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Mexico_City',
    unlocs: ['MXPGO'],
    code: '20185',
    id: 'MXPGO',
  },
  {
    name: 'Puerto Morelos',
    coordinates: [-86.87, 20.83],
    city: 'Puerto Morelos',
    province: 'Quintana Roo',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Cancun',
    unlocs: ['MXPMS'],
    code: '20195',
    id: 'MXPMS',
  },
  {
    name: 'Reynosa',
    coordinates: [-98.28, 26.08],
    city: 'Reynosa',
    province: 'Tamaulipas',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Mexico_City',
    unlocs: ['MXREX'],
    code: '20195',
    id: 'MXREX',
  },
  {
    name: 'Salina Cruz',
    coordinates: [-95.18, 16.17],
    city: 'Salina Cruz',
    province: 'Oaxaca',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Mexico_City',
    unlocs: ['MXSCX'],
    code: '20129',
    id: 'MXSCX',
  },
  {
    name: 'Tampico',
    coordinates: [-97.87, 22.25],
    city: 'Tampico',
    province: 'Tamaulipas',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Mexico_City',
    unlocs: ['MXTAM'],
    code: '20193',
    id: 'MXTAM',
  },
  {
    name: 'Topolobampo',
    coordinates: [-109.05, 25.6],
    city: 'Topolobampo',
    province: 'Sinaloa',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Mazatlan',
    unlocs: ['MXTPB'],
    code: '20106',
    id: 'MXTPB',
  },
  {
    name: 'Veracruz',
    coordinates: [-96.08, 19.2],
    city: 'Veracruz',
    province: 'Veracruz',
    country: 'Mexico',
    alias: [],
    regions: [],
    unlocs: ['MXVER'],
    code: '20199',
    id: 'MXVER',
  },
  {
    name: 'Manzanillo',
    coordinates: [-104.3, 19.05],
    city: 'Manzanillo',
    province: 'Colima',
    country: 'Mexico',
    alias: [],
    regions: [],
    timezone: 'America/Mexico_City',
    unlocs: ['MXZLO'],
    code: '20120',
    id: 'MXZLO',
  },
  {
    name: 'Kota Kinabalu, Sabah',
    city: 'Kota Kinabalu, Sabah',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [116.0734568, 5.980408],
    province: 'Sabah',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYBKI'],
    code: '55752',
    id: 'MYBKI',
  },
  {
    name: 'Bintulu, Sarawak',
    city: 'Bintulu, Sarawak',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [113.0419069, 3.171322],
    province: 'Sarawak',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYBTU'],
    code: '55732',
    id: 'MYBTU',
  },
  {
    name: 'Bagan Luar (Butterworth)',
    city: 'Bagan Luar (Butterworth)',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [100.370839, 5.410509999999999],
    province: 'Penang',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYBWH'],
    code: '55705',
    id: 'MYBWH',
  },
  {
    name: 'Johor Bahru',
    city: 'Johor Bahru',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [103.7413591, 1.492659],
    province: 'Johor',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYJHB'],
    code: '55707',
    id: 'MYJHB',
  },
  {
    name: 'Kuching, Sarawak',
    city: 'Kuching, Sarawak',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [110.3785438, 1.6076812],
    province: 'Sarawak',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYKCH'],
    code: '55713',
    id: 'MYKCH',
  },
  {
    name: 'Kuantan (Tanjong Gelang)',
    city: 'Kuantan (Tanjong Gelang)',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [103.43, 3.9608333],
    province: 'Pahang',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYKUA'],
    code: '55715',
    id: 'MYKUA',
  },
  {
    name: 'Labuan, Sabah',
    city: 'Labuan, Sabah',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [115.230825, 5.2831456],
    province: 'Wilayah Persekutuan Labuan',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYLBU'],
    code: '55717',
    id: 'MYLBU',
  },
  {
    name: 'Mersing',
    city: 'Mersing',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [103.8361151, 2.430917],
    province: 'Johor',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYMEP'],
    code: '55700',
    id: 'MYMEP',
  },
  {
    name: 'Malacca',
    city: 'Malacca',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [102.2500868, 2.189594],
    province: 'Malacca',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYMKZ'],
    code: '55700',
    id: 'MYMKZ',
  },
  {
    name: 'Muar',
    city: 'Muar',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [102.5848717, 2.0630519],
    province: 'Johor',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYMUA'],
    code: '55700',
    id: 'MYMUA',
  },
  {
    name: 'Miri, Sarawak',
    city: 'Miri, Sarawak',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [113.9913832, 4.399493],
    province: 'Sarawak',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYMYY'],
    code: '55731',
    id: 'MYMYY',
  },
  {
    name: 'Penang (Georgetown)',
    city: 'Penang (Georgetown)',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [100.3233734, 5.4163033],
    province: 'Pulau Pinang',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYPEN'],
    code: '55735',
    id: 'MYPEN',
  },
  {
    name: 'Pasir Gudang, Johor',
    city: 'Pasir Gudang, Johor',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [103.9029689, 1.470288],
    province: 'Johor',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYPGU'],
    code: '55707',
    id: 'MYPGU',
  },
  {
    name: 'Port Klang (Pelabuhan Klang)',
    coordinates: [101.4, 3],
    city: 'Port Klang (Pelabuhan Klang)',
    province: 'Selangor',
    country: 'Malaysia',
    alias: [],
    regions: [],
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYPKG'],
    code: '55751',
    id: 'MYPKG',
  },
  {
    name: 'Sibu, Sarawak',
    city: 'Sibu, Sarawak',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [111.830535, 2.287284],
    province: 'Sarawak',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYSBW'],
    code: '55700',
    id: 'MYSBW',
  },
  {
    name: 'Sandakan, Sabah',
    city: 'Sandakan, Sabah',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [118.1171729, 5.839443999999999],
    province: 'Sabah',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYSDK'],
    code: '55706',
    id: 'MYSDK',
  },
  {
    name: 'Tanjong Baran',
    city: 'Tanjong Baran',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [113.9769444, 4.593333299999999],
    province: 'Sarawak',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYTBA'],
    code: '55700',
    id: 'MYTBA',
  },
  {
    name: 'Kuala Terengganu',
    city: 'Kuala Terengganu',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [103.1370142, 5.329624],
    province: 'Terengganu',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYTGG'],
    code: '55711',
    id: 'MYTGG',
  },
  {
    name: 'Tanjung Pelepas',
    coordinates: [103.55, 1.37],
    city: 'Tanjung Pelepas',
    province: 'Johor',
    country: 'Malaysia',
    alias: [],
    regions: [],
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYTPP'],
    code: '55750',
    id: 'MYTPP',
  },
  {
    name: 'Tawau, Sabah',
    city: 'Tawau, Sabah',
    country: 'Malaysia',
    alias: [],
    regions: [],
    coordinates: [117.8911861, 4.244651],
    province: 'Sabah',
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYTWU'],
    code: '55700',
    id: 'MYTWU',
  },
  {
    name: 'Westport/Port Klang',
    coordinates: [101.3, 2.95],
    city: 'Westport/Port Klang',
    province: 'Selangor',
    country: 'Malaysia',
    alias: [],
    regions: [],
    timezone: 'Asia/Kuala_Lumpur',
    unlocs: ['MYWSP'],
    code: '55700',
    id: 'MYWSP',
  },
  {
    name: 'Beira',
    city: 'Beira',
    country: 'Mozambique',
    alias: [],
    regions: [],
    coordinates: [34.8844867, -19.7963816],
    province: 'Sofala',
    timezone: 'Africa/Maputo',
    unlocs: ['MZBEW'],
    code: '78701',
    id: 'MZBEW',
  },
  {
    name: 'Nacala',
    city: 'Nacala',
    country: 'Mozambique',
    alias: [],
    regions: [],
    coordinates: [40.6854309, -14.5656065],
    province: 'Nampula',
    timezone: 'Africa/Maputo',
    unlocs: ['MZMNC'],
    code: '78735',
    id: 'MZMNC',
  },
  {
    name: 'Maputo',
    city: 'Maputo',
    country: 'Mozambique',
    alias: [],
    regions: [],
    coordinates: [32.6051351, -25.891968],
    province: 'Maputo City',
    timezone: 'Africa/Maputo',
    unlocs: ['MZMPM'],
    code: '78717',
    id: 'MZMPM',
  },
  {
    name: 'Pemba',
    city: 'Pemba',
    country: 'Mozambique',
    alias: [],
    regions: [],
    coordinates: [40.5178014, -12.9732026],
    province: 'Cabo Delgado',
    timezone: 'Africa/Maputo',
    unlocs: ['MZPOL'],
    id: 'MZPOL',
  },
  {
    name: 'Quelimane',
    city: 'Quelimane',
    country: 'Mozambique',
    alias: [],
    regions: [],
    coordinates: [36.9218584, -17.8502636],
    province: 'Zambezia Province',
    timezone: 'Africa/Maputo',
    unlocs: ['MZUEL'],
    code: '78700',
    id: 'MZUEL',
  },
  {
    name: 'Luderitz',
    coordinates: [15.17, -26.65],
    city: 'Lüderitz',
    country: 'Namibia',
    alias: [],
    regions: [],
    province: 'Karas',
    timezone: 'Africa/Windhoek',
    unlocs: ['NALUD'],
    code: '79299',
    id: 'NALUD',
  },
  {
    name: 'Walvis Bay',
    city: 'Walvis Bay',
    country: 'Namibia',
    alias: [],
    regions: [],
    coordinates: [14.5247463, -22.9389587],
    province: 'Erongo',
    timezone: 'Africa/Windhoek',
    unlocs: ['NAWVB'],
    code: '79286',
    id: 'NAWVB',
  },
  {
    name: 'Noumea',
    coordinates: [166.45, -22.27],
    city: 'Nouméa',
    country: 'New Caledonia',
    alias: [],
    regions: [],
    province: 'South Province',
    timezone: 'Pacific/Noumea',
    unlocs: ['NCNOU'],
    code: '64109',
    id: 'NCNOU',
  },
  {
    name: 'Apapa',
    city: 'Apapa',
    country: 'Nigeria',
    alias: [],
    regions: [],
    coordinates: [3.3640841, 6.45528],
    province: 'Lagos',
    timezone: 'Africa/Lagos',
    unlocs: ['NGAPP'],
    code: '75313',
    id: 'NGAPP',
  },
  {
    name: 'Calabar',
    city: 'Calabar',
    country: 'Nigeria',
    alias: [],
    regions: [],
    coordinates: [8.3417006, 4.9757165],
    province: 'Cross River',
    timezone: 'Africa/Lagos',
    unlocs: ['NGCBQ'],
    code: '75330',
    id: 'NGCBQ',
  },
  {
    name: 'Lagos',
    city: 'Lagos',
    country: 'Nigeria',
    alias: [],
    regions: [],
    coordinates: [3.3792057, 6.5243793],
    province: 'Lagos',
    timezone: 'Africa/Lagos',
    unlocs: ['NGLOS'],
    code: '75367',
    id: 'NGLOS',
  },
  {
    name: 'Onne',
    coordinates: [7.15, 4.72],
    city: 'Onne',
    province: 'Rivers',
    country: 'Nigeria',
    alias: [],
    regions: [],
    timezone: 'Africa/Lagos',
    unlocs: ['NGONN'],
    code: '75385',
    id: 'NGONN',
  },
  {
    name: 'Port Harcourt',
    city: 'Port Harcourt',
    country: 'Nigeria',
    alias: [],
    regions: [],
    coordinates: [7.0498442, 4.815554],
    province: 'Rivers',
    timezone: 'Africa/Lagos',
    unlocs: ['NGPHC'],
    code: '75385',
    id: 'NGPHC',
  },
  {
    name: 'Tincan/Lagos',
    city: 'Tincan/Lagos',
    country: 'Nigeria',
    alias: [],
    regions: [],
    coordinates: [3.3429075, 6.4334794],
    province: 'Lagos',
    timezone: 'Africa/Lagos',
    unlocs: ['NGTIN'],
    id: 'NGTIN',
  },
  {
    name: 'Warri',
    city: 'Warri',
    country: 'Nigeria',
    alias: [],
    regions: [],
    coordinates: [5.7932008, 5.5543995],
    province: 'Delta',
    timezone: 'Africa/Lagos',
    unlocs: ['NGWAR'],
    code: '75387',
    id: 'NGWAR',
  },
  {
    name: 'Corinto',
    city: 'Corinto',
    country: 'Nicaragua',
    alias: [],
    regions: [],
    coordinates: [-87.1784334, 12.4907414],
    province: 'Chinandega',
    timezone: 'America/Managua',
    unlocs: ['NICIO'],
    code: '21951',
    id: 'NICIO',
  },
  {
    name: 'Managua',
    city: 'Managua',
    country: 'Nicaragua',
    alias: [],
    regions: [],
    coordinates: [-86.2361744, 12.1149926],
    province: 'Managua',
    timezone: 'America/Managua',
    unlocs: ['NIMGA'],
    id: 'NIMGA',
  },
  {
    name: 'Alkmaar',
    city: 'Alkmaar',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [4.753375399999999, 52.6323813],
    province: 'Noord-Holland',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLALK'],
    id: 'NLALK',
  },
  {
    name: 'Amsterdam',
    city: 'Amsterdam',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [4.895167900000001, 52.3702157],
    province: 'North Holland',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLAMS'],
    code: '42105',
    id: 'NLAMS',
  },
  {
    name: 'Bergen op Zoom',
    coordinates: [4.3, 51.5],
    city: 'Bergen op Zoom',
    country: 'Netherlands',
    alias: [],
    regions: [],
    province: 'North Brabant',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLBZM'],
    id: 'NLBZM',
  },
  {
    name: 'Dordrecht',
    city: 'Dordrecht',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [4.6900929, 51.81329789999999],
    province: 'Zuid-Holland',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLDOR'],
    code: '42117',
    id: 'NLDOR',
  },
  {
    name: 'Eemshaven',
    city: 'Eemshaven',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [6.8354937, 53.4385887],
    province: 'Groningen',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLEEM'],
    code: '42132',
    id: 'NLEEM',
  },
  {
    name: 'Groningen',
    city: 'Groningen',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [6.566501799999999, 53.2193835],
    province: 'Groningen',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLGRQ'],
    code: '42100',
    id: 'NLGRQ',
  },
  {
    name: 'Hoek van Holland',
    city: 'Hoek van Holland',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [4.1341847, 51.9806322],
    province: 'South Holland',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLHVH'],
    id: 'NLHVH',
  },
  {
    name: 'IJmuiden/Velsen',
    coordinates: [4.6, 52.47],
    city: 'IJmuiden/Velsen',
    country: 'Netherlands',
    alias: [],
    regions: [],
    province: 'North Holland',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLIJM'],
    id: 'NLIJM',
  },
  {
    name: 'Moerdijk',
    city: 'Moerdijk',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [4.6273499, 51.70181669999999],
    province: 'Noord-Brabant',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLMOE'],
    id: 'NLMOE',
  },
  {
    name: 'Rotterdam',
    coordinates: [4.5, 51.92],
    city: 'Rotterdam',
    province: 'Zuid-Holland',
    country: 'Netherlands',
    alias: [],
    regions: [],
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLRTM'],
    code: '42157',
    id: 'NLRTM',
  },
  {
    name: 'Terneuzen',
    city: 'Terneuzen',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [3.8324265, 51.3322854],
    province: 'Zeeland',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLTNZ'],
    code: '42171',
    id: 'NLTNZ',
  },
  {
    name: 'Velsen',
    city: 'Velsen',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [4.630587, 52.4520591],
    province: 'North Holland',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLVEL'],
    code: '42190',
    id: 'NLVEL',
  },
  {
    name: 'Vlaardingen',
    city: 'Vlaardingen',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [4.3494366, 51.9120668],
    province: 'Zuid-Holland',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLVLA'],
    code: '42181',
    id: 'NLVLA',
  },
  {
    name: 'Vlissingen',
    city: 'Vlissingen',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [3.5709125, 51.4536672],
    province: 'Zeeland',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLVLI'],
    code: '42185',
    id: 'NLVLI',
  },
  {
    name: 'Zaandam',
    city: 'Zaandam',
    country: 'Netherlands',
    alias: [],
    regions: [],
    coordinates: [4.8291992, 52.4420399],
    province: 'North Holland',
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLZAA'],
    code: '42100',
    id: 'NLZAA',
  },
  {
    name: 'Zeewolde',
    coordinates: [5.55, 52.35],
    city: 'Zeewolde',
    province: 'Flevoland',
    country: 'Netherlands',
    alias: [],
    regions: [],
    timezone: 'Europe/Amsterdam',
    unlocs: ['NLZEW'],
    id: 'NLZEW',
  },
  {
    name: 'Alvik',
    coordinates: [6.42, 60.42],
    city: 'Ålvik',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOAAV'],
    code: '40300',
    id: 'NOAAV',
  },
  {
    name: 'Alesund',
    coordinates: [6.15, 62.47],
    city: 'Ålesund',
    province: 'Møre og Romsdal',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOAES'],
    id: 'NOAES',
  },
  {
    name: 'Agotnes',
    coordinates: [5.02, 60.4],
    city: 'Ågotnes',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOAGO'],
    id: 'NOAGO',
  },
  {
    name: 'Andalsnes',
    coordinates: [7.7, 62.57],
    city: 'Åndalsnes',
    province: 'Møre og Romsdal',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOAND'],
    code: '40300',
    id: 'NOAND',
  },
  {
    name: 'Austevoll',
    coordinates: [5.15, 60.1],
    city: 'Austevoll',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOASV'],
    id: 'NOASV',
  },
  {
    name: 'Bergen',
    coordinates: [5.32, 60.38],
    city: 'Bergen',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOBGO'],
    code: '40311',
    id: 'NOBGO',
  },
  {
    name: 'Bodo',
    coordinates: [14.37, 67.28],
    city: 'Bodø',
    province: 'Nordland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOBOO'],
    code: '40369',
    id: 'NOBOO',
  },
  {
    name: 'Brevik',
    coordinates: [9.7, 59.05],
    city: 'Brevik',
    province: 'Telemark',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOBVK'],
    code: '40313',
    id: 'NOBVK',
  },
  {
    name: 'Drammen',
    coordinates: [10.23, 59.73],
    city: 'Drammen',
    province: 'Buskerud',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NODRM'],
    code: '40321',
    id: 'NODRM',
  },
  {
    name: 'Eigersund',
    city: 'Eigersund',
    province: 'Rogaland',
    country: 'Norway',
    alias: [],
    regions: [],
    coordinates: [5.899172099999999, 58.3730054],
    timezone: 'Europe/Oslo',
    unlocs: ['NOEGD'],
    id: 'NOEGD',
  },
  {
    timezone: 'Europe/Oslo',
    coordinates: [5.2397138, 60.51194100000001],
    name: 'Frekhaug',
    city: 'Frekhaug',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    unlocs: ['NOFKG'],
    id: 'NOFKG',
  },
  {
    name: 'Fredrikstad',
    coordinates: [10.92, 59.2],
    city: 'Fredrikstad',
    province: 'Østfold',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOFRK'],
    code: '40334',
    id: 'NOFRK',
  },
  {
    name: 'Floro',
    coordinates: [5.02, 61.6],
    city: 'Florø',
    province: 'Sogn og Fjordane',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOFRO'],
    code: '40340',
    id: 'NOFRO',
  },
  {
    name: 'Fusa',
    coordinates: [5.62, 60.2],
    city: 'Fusa',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOFUS'],
    id: 'NOFUS',
  },
  {
    name: 'Gjemnes',
    city: 'Gjemnes',
    province: 'Møre og Romsdal',
    country: 'Norway',
    alias: [],
    regions: [],
    coordinates: [7.7628928, 62.95102639999999],
    timezone: 'Europe/Oslo',
    unlocs: ['NOGJM'],
    id: 'NOGJM',
  },
  {
    name: 'Halden',
    coordinates: [11.37, 59.1],
    city: 'Halden',
    province: 'Østfold',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOHAL'],
    code: '40342',
    id: 'NOHAL',
  },
  {
    name: 'Haugesund',
    coordinates: [5.25, 59.4],
    city: 'Haugesund',
    province: 'Rogaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOHAU'],
    code: '40345',
    id: 'NOHAU',
  },
  {
    name: 'Hestvika',
    city: 'Hestvika',
    province: 'Sør-Trøndelag',
    country: 'Norway',
    alias: [],
    regions: [],
    coordinates: [9.193158, 63.56376799999999],
    timezone: 'Europe/Oslo',
    unlocs: ['NOHES'],
    id: 'NOHES',
  },
  {
    name: 'Holla',
    coordinates: [9.18, 59.6],
    city: 'Holla',
    province: 'Telemark',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOHLA'],
    code: '40320',
    id: 'NOHLA',
  },
  {
    name: 'Hogset',
    coordinates: [15.02, 67.13],
    city: 'Høgset',
    province: 'Nordland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOHOG'],
    id: 'NOHOG',
  },
  {
    name: 'Husoy - Tonsberg',
    coordinates: [10.45, 59.23],
    city: 'Husøy - Tønsberg',
    province: 'Vestfold',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOHOY'],
    id: 'NOHOY',
  },
  {
    name: 'Hareid',
    coordinates: [6.03, 62.37],
    city: 'Hareid',
    province: 'Møre og Romsdal',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOHRI'],
    id: 'NOHRI',
  },
  {
    name: 'Heroya',
    coordinates: [9.62, 59.12],
    city: 'Herøya',
    province: 'Telemark',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOHRY'],
    id: 'NOHRY',
  },
  {
    name: 'Husnes',
    coordinates: [5.77, 59.87],
    city: 'Husnes',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOHUS'],
    id: 'NOHUS',
  },
  {
    name: 'Havik',
    coordinates: [5.32, 59.3],
    city: 'Håvik',
    province: 'Nordland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOHVI'],
    id: 'NOHVI',
  },
  {
    name: 'Ikornnes',
    coordinates: [6.55, 62.38],
    city: 'Ikornnes',
    province: 'Møre og Romsdal',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOIKR'],
    id: 'NOIKR',
  },
  {
    name: 'Karmoy',
    city: 'Karmøy',
    province: 'Rogaland',
    country: 'Norway',
    alias: [],
    regions: [],
    coordinates: [5.263402, 59.276775],
    timezone: 'Europe/Oslo',
    unlocs: ['NOKMY'],
    id: 'NOKMY',
  },
  {
    name: 'Kristiansand',
    coordinates: [7.98, 58.13],
    city: 'Kristiansand',
    province: 'Vest-Agder',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOKRS'],
    id: 'NOKRS',
  },
  {
    name: 'Knarrevik',
    coordinates: [5.15, 60.37],
    city: 'Knarrevik',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOKRV'],
    id: 'NOKRV',
  },
  {
    name: 'Kristiansund',
    coordinates: [7.73, 63.1],
    city: 'Kristiansund',
    province: 'Møre og Romsdal',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOKSU'],
    id: 'NOKSU',
  },
  {
    name: 'Larvik',
    coordinates: [10.02, 59.03],
    city: 'Larvik',
    province: 'Vestfold',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOLAR'],
    code: '40335',
    id: 'NOLAR',
  },
  {
    name: 'Lyngdal',
    city: 'Lyngdal',
    province: 'Vest-Agder',
    country: 'Norway',
    alias: [],
    regions: [],
    coordinates: [7.070436099999999, 58.1378983],
    timezone: 'Europe/Oslo',
    unlocs: ['NOLND'],
    id: 'NOLND',
  },
  {
    name: 'Maloy',
    coordinates: [5.1, 61.93],
    city: 'Måløy',
    province: 'Sogn og Fjordane',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOMAY'],
    id: 'NOMAY',
  },
  {
    name: 'Molde',
    coordinates: [7.15, 62.73],
    city: 'Molde',
    province: 'Møre og Romsdal',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOMOL'],
    id: 'NOMOL',
  },
  {
    name: 'Mongstad',
    coordinates: [5.02, 60.82],
    city: 'Mongstad',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOMON'],
    code: '40373',
    id: 'NOMON',
  },
  {
    name: 'Mo i Rana',
    coordinates: [14.13, 66.32],
    city: 'Mo i Rana',
    province: 'Nordland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOMQN'],
    code: '40370',
    id: 'NOMQN',
  },
  {
    name: 'Moss',
    coordinates: [10.65, 59.42],
    city: 'Moss',
    province: 'Østfold',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOMSS'],
    code: '40371',
    id: 'NOMSS',
  },
  {
    name: 'Odda',
    coordinates: [6.53, 60.07],
    city: 'Odda',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOODD'],
    code: '40300',
    id: 'NOODD',
  },
  {
    name: 'Orkanger',
    coordinates: [9.83, 63.32],
    city: 'Orkanger',
    province: 'Sør-Trøndelag',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOORK'],
    id: 'NOORK',
  },
  {
    name: 'Oslo',
    coordinates: [10.73, 59.9],
    city: 'Oslo',
    province: 'Oslo',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOOSL'],
    code: '40377',
    id: 'NOOSL',
  },
  {
    name: 'Porsgrunn',
    coordinates: [9.63, 59.13],
    city: 'Porsgrunn',
    province: 'Telemark',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOPOR'],
    code: '40378',
    id: 'NOPOR',
  },
  {
    name: 'Sandefjord',
    coordinates: [10.22, 59.12],
    city: 'Sandefjord',
    province: 'Vestfold',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOSAD'],
    code: '40300',
    id: 'NOSAD',
  },
  {
    name: 'Sauda',
    coordinates: [6.35, 59.63],
    city: 'Sauda',
    province: 'Rogaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOSAU'],
    code: '40383',
    id: 'NOSAU',
  },
  {
    name: 'Skien',
    coordinates: [9.6, 59.2],
    city: 'Skien',
    province: 'Telemark',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOSKE'],
    code: '40385',
    id: 'NOSKE',
  },
  {
    name: 'Skogn',
    coordinates: [11.15, 63.7],
    city: 'Skogn',
    province: 'Nord-Trøndelag',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOSKX'],
    id: 'NOSKX',
  },
  {
    name: 'Sortland',
    coordinates: [15.42, 68.7],
    city: 'Sortland',
    province: 'Nordland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOSLX'],
    id: 'NOSLX',
  },
  {
    name: 'Sarpsborg',
    coordinates: [11.1, 59.27],
    city: 'Sarpsborg',
    province: 'Østfold',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOSPG'],
    code: '40300',
    id: 'NOSPG',
  },
  {
    name: 'Stord',
    coordinates: [5.48, 59.75],
    city: 'Stord',
    province: 'Hordaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOSRP'],
    id: 'NOSRP',
  },
  {
    name: 'Straumen',
    coordinates: [15.6, 67.35],
    city: 'Straumen',
    country: 'Norway',
    alias: [],
    regions: [],
    province: 'Nord-Trondelag',
    timezone: 'Europe/Oslo',
    unlocs: ['NOSTM'],
    id: 'NOSTM',
  },
  {
    name: 'Sunndalsora',
    coordinates: [8.55, 62.67],
    city: 'Sunndalsøra',
    province: 'Møre og Romsdal',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOSUN'],
    code: '40390',
    id: 'NOSUN',
  },
  {
    name: 'Svelgen',
    coordinates: [5.28, 61.77],
    city: 'Svelgen',
    province: 'Sogn og Fjordane',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOSVE'],
    code: '40392',
    id: 'NOSVE',
  },
  {
    name: 'Stavanger',
    coordinates: [5.75, 58.97],
    city: 'Stavanger',
    province: 'Rogaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOSVG'],
    code: '40387',
    id: 'NOSVG',
  },
  {
    name: 'Tananger',
    coordinates: [5.58, 58.92],
    city: 'Tananger',
    province: 'Rogaland',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOTAE'],
    id: 'NOTAE',
  },
  {
    name: 'Tonsberg',
    coordinates: [10.42, 59.25],
    city: 'Tønsberg',
    province: 'Vestfold',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOTON'],
    code: '40300',
    id: 'NOTON',
  },
  {
    name: 'Tromso',
    coordinates: [18.97, 69.67],
    city: 'Tromsø',
    province: 'Troms',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOTOS'],
    code: '40338',
    id: 'NOTOS',
  },
  {
    name: 'Trondheim',
    coordinates: [10.4, 63.43],
    city: 'Trondheim',
    province: 'Sør-Trøndelag',
    country: 'Norway',
    alias: [],
    regions: [],
    timezone: 'Europe/Oslo',
    unlocs: ['NOTRD'],
    code: '40397',
    id: 'NOTRD',
  },
  {
    name: 'Auckland',
    coordinates: [174.8, -36.83],
    city: 'Auckland',
    province: 'Auckland',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZAKL'],
    code: '61401',
    id: 'NZAKL',
  },
  {
    name: 'Bluff',
    coordinates: [168.32, -46.6],
    city: 'Bluff',
    province: 'Southland',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZBLU'],
    id: 'NZBLU',
  },
  {
    name: 'Christchurch',
    coordinates: [172.63, -43.53],
    city: 'Christchurch',
    province: 'Canterbury',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZCHC'],
    code: '61405',
    id: 'NZCHC',
  },
  {
    name: 'Dunedin',
    coordinates: [170.5, -45.87],
    city: 'Dunedin',
    province: 'Otago',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZDUD'],
    code: '61407',
    id: 'NZDUD',
  },
  {
    name: 'Hamilton',
    coordinates: [175.28, -37.78],
    city: 'Hamilton',
    province: 'Waikato',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZHLZ'],
    id: 'NZHLZ',
  },
  {
    name: 'Lyttelton',
    coordinates: [172.72, -43.6],
    city: 'Lyttelton',
    province: 'Canterbury',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZLYT'],
    code: '61415',
    id: 'NZLYT',
  },
  {
    name: 'Middleton/Christchurch',
    city: 'Middleton/Christchurch',
    province: 'Canterbury',
    country: 'New Zealand',
    alias: [],
    regions: [],
    coordinates: [172.5909435, -43.5442039],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZMNR'],
    id: 'NZMNR',
  },
  {
    name: 'Napier',
    coordinates: [176.9, -39.47],
    city: 'Napier',
    province: "Hawkes's Bay",
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZNPE'],
    code: '61419',
    id: 'NZNPE',
  },
  {
    name: 'New Plymouth',
    coordinates: [174.02, -39.05],
    city: 'New Plymouth',
    province: 'Taranaki',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZNPL'],
    code: '61423',
    id: 'NZNPL',
  },
  {
    name: 'Nelson',
    coordinates: [173.27, -41.25],
    city: 'Nelson',
    province: 'Nelson City',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZNSN'],
    code: '61406',
    id: 'NZNSN',
  },
  {
    name: 'Palmerston North',
    city: 'Palmerston North',
    province: 'Manawatu-Wanganui',
    country: 'New Zealand',
    alias: [],
    regions: [],
    coordinates: [175.6082145, -40.3523065],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZPMR'],
    id: 'NZPMR',
  },
  {
    name: 'Port Chalmers',
    coordinates: [170.6, -45.82],
    city: 'Port Chalmers',
    province: 'Otago',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZPOE'],
    code: '61431',
    id: 'NZPOE',
  },
  {
    name: 'Timaru',
    coordinates: [171.25, -44.38],
    city: 'Timaru',
    province: 'Canterbury',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZTIU'],
    code: '61445',
    id: 'NZTIU',
  },
  {
    name: 'Temuka',
    coordinates: [171.27, -44.25],
    city: 'Temuka',
    province: 'Canterbury',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZTKA'],
    id: 'NZTKA',
  },
  {
    name: 'Tauranga',
    coordinates: [176.17, -37.68],
    city: 'Tauranga',
    province: 'Bay of Plenty',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZTRG'],
    code: '61443',
    id: 'NZTRG',
  },
  {
    name: 'Wanganui',
    coordinates: [174.98, -39.93],
    city: 'Wanganui',
    province: 'Manawatu-Wanganui',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZWAG'],
    code: '61400',
    id: 'NZWAG',
  },
  {
    name: 'Wellington',
    coordinates: [174.78, -41.27],
    city: 'Wellington',
    province: 'Wellington',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZWLG'],
    code: '61447',
    id: 'NZWLG',
  },
  {
    name: 'Whangarei',
    coordinates: [174.48, -35.83],
    city: 'Whangarei',
    province: 'Northland',
    country: 'New Zealand',
    alias: [],
    regions: [],
    timezone: 'Pacific/Auckland',
    unlocs: ['NZWRE'],
    id: 'NZWRE',
  },
  {
    name: 'Muscat',
    city: 'Muscat',
    country: 'Oman',
    alias: [],
    regions: [],
    coordinates: [58.4059227, 23.58589],
    province: 'Muscat Governorate',
    timezone: 'Asia/Muscat',
    unlocs: ['OMMCT'],
    code: '52325',
    id: 'OMMCT',
  },
  {
    name: "Mina' Qabus",
    coordinates: [58.57, 23.62],
    city: "Mina' Qabus",
    province: 'Masqat',
    country: 'Oman',
    alias: [],
    regions: [],
    timezone: 'Asia/Muscat',
    unlocs: ['OMMNQ'],
    id: 'OMMNQ',
  },
  {
    name: 'Port Qaboos',
    city: 'Port Qaboos',
    country: 'Oman',
    alias: [],
    regions: [],
    coordinates: [58.5615244, 23.6275477],
    province: 'Muscat Governorate',
    timezone: 'Asia/Muscat',
    unlocs: ['OMOPQ'],
    id: 'OMOPQ',
  },
  {
    name: 'Salalah',
    city: 'Salalah',
    country: 'Oman',
    alias: [],
    regions: [],
    coordinates: [54.1065864, 17.0506675],
    province: 'Dhofar Governorate',
    timezone: 'Asia/Muscat',
    unlocs: ['OMSLL'],
    code: '52330',
    id: 'OMSLL',
  },
  {
    name: 'Sohar',
    city: 'Sohar',
    country: 'Oman',
    alias: [],
    regions: [],
    coordinates: [56.7075141, 24.3460959],
    province: 'Al Batinah North Governorate',
    timezone: 'Asia/Muscat',
    unlocs: ['OMSOH'],
    id: 'OMSOH',
  },
  {
    name: 'Puerto Armuelles',
    city: 'Puerto Armuelles',
    country: 'Panama',
    alias: [],
    regions: [],
    coordinates: [-82.92864279999999, 8.3117647],
    province: 'Chiriqui',
    timezone: 'America/Panama',
    unlocs: ['PAAML'],
    code: '22587',
    id: 'PAAML',
  },
  {
    name: 'Balboa',
    city: 'Balboa',
    country: 'Panama',
    alias: [],
    regions: [],
    coordinates: [-79.5631537, 8.9613962],
    province: 'Panama',
    timezone: 'America/Panama',
    unlocs: ['PABLB'],
    code: '22575',
    id: 'PABLB',
  },
  {
    name: 'Colon Free Zone',
    coordinates: [-79.9, 9.35],
    city: 'Colón Free Zone',
    province: 'Colón',
    country: 'Panama',
    alias: [],
    regions: [],
    timezone: 'America/Panama',
    unlocs: ['PACFZ'],
    id: 'PACFZ',
  },
  {
    name: 'Coco Solo',
    city: 'Coco Solo',
    country: 'Panama',
    alias: [],
    regions: [],
    coordinates: [-79.879913, 9.368985],
    province: 'Colon',
    timezone: 'America/Panama',
    unlocs: ['PACSO'],
    code: '22517',
    id: 'PACSO',
  },
  {
    name: 'Cristobal',
    coordinates: [-79.9, 9.35],
    city: 'Cristóbal',
    country: 'Panama',
    alias: [],
    regions: [],
    province: 'Colon',
    timezone: 'America/Panama',
    unlocs: ['PACTB'],
    code: '22518',
    id: 'PACTB',
  },
  {
    name: 'Chitre',
    city: 'Chitre',
    country: 'Panama',
    alias: [],
    regions: [],
    coordinates: [-80.4382153, 7.9523119],
    province: 'Herrera',
    timezone: 'America/Panama',
    unlocs: ['PACTD'],
    id: 'PACTD',
  },
  {
    name: 'Canazas',
    coordinates: [-80.7, 8.13],
    city: 'Cañazas',
    country: 'Panama',
    alias: [],
    regions: [],
    province: 'Veraguas',
    timezone: 'America/Panama',
    unlocs: ['PACZZ'],
    id: 'PACZZ',
  },
  {
    name: 'Manzanillo',
    city: 'Manzanillo',
    country: 'Panama',
    alias: [],
    regions: [],
    coordinates: [-81.16666699999999, 7.533332999999999],
    province: 'Veraguas',
    timezone: 'America/Panama',
    unlocs: ['PAMIT'],
    code: '22556',
    id: 'PAMIT',
  },
  {
    name: 'Colon',
    coordinates: [-79.87, 9.35],
    city: 'Colón',
    province: 'Colón',
    country: 'Panama',
    alias: [],
    regions: [],
    timezone: 'America/Panama',
    unlocs: ['PAONX'],
    code: '22519',
    id: 'PAONX',
  },
  {
    name: 'Rodman',
    city: 'Rousseau',
    country: 'Panama',
    alias: [],
    regions: [],
    coordinates: [-79.58843689999999, 8.9593892],
    province: 'Panama',
    timezone: 'America/Panama',
    unlocs: ['PAPSA'],
    code: '22599',
    id: 'PAPSA',
  },
  {
    name: 'Panama, Ciudad de',
    coordinates: [-79.53, 8.97],
    city: 'Panamá, Ciudad de',
    province: 'Panamá',
    country: 'Panama',
    alias: [],
    regions: [],
    timezone: 'America/Panama',
    unlocs: ['PAPTY'],
    id: 'PAPTY',
  },
  {
    name: 'Atico',
    city: 'Atico',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-73.60964969999999, -16.2283321],
    province: 'Arequipa',
    timezone: 'America/Lima',
    unlocs: ['PEATI'],
    code: '33300',
    id: 'PEATI',
  },
  {
    name: 'Chimbote',
    city: 'Chimbote',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-78.589011, -9.063336399999999],
    province: 'Ancash',
    timezone: 'America/Lima',
    unlocs: ['PECHM'],
    code: '33300',
    id: 'PECHM',
  },
  {
    name: 'Callao',
    city: 'Callao',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-77.1259843, -12.0508491],
    province: 'Lima',
    timezone: 'America/Lima',
    unlocs: ['PECLL'],
    code: '33303',
    id: 'PECLL',
  },
  {
    name: 'General San Martin',
    city: 'General San Martin',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-76.9242537, -12.1975909],
    province: 'Lima',
    timezone: 'America/Lima',
    unlocs: ['PEGSM'],
    code: '33373',
    id: 'PEGSM',
  },
  {
    name: 'Huacho',
    city: 'Huacho',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-77.61056789999999, -11.1227218],
    province: 'Lima',
    timezone: 'America/Lima',
    unlocs: ['PEHCO'],
    code: '33300',
    id: 'PEHCO',
  },
  {
    name: 'Ilo',
    city: 'Ilo',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-71.3468091, -17.6680839],
    province: 'Moquegua',
    timezone: 'America/Lima',
    unlocs: ['PEILQ'],
    code: '33335',
    id: 'PEILQ',
  },
  {
    name: 'Iquitos',
    city: 'Iquitos',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-73.25163260000001, -3.7436735],
    province: 'Loreto',
    timezone: 'America/Lima',
    unlocs: ['PEIQT'],
    code: '33337',
    id: 'PEIQT',
  },
  {
    name: 'Lobitos',
    city: 'Lobitos',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-81.2821647, -4.4564351],
    province: 'Piura',
    timezone: 'America/Lima',
    unlocs: ['PELOB'],
    id: 'PELOB',
  },
  {
    name: 'Matarani',
    city: 'Matarani',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-72.1001434, -17.0054057],
    province: 'Arequipa',
    timezone: 'America/Lima',
    unlocs: ['PEMRI'],
    code: '33354',
    id: 'PEMRI',
  },
  {
    name: 'Pacasmayo',
    city: 'Pacasmayo',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-79.565078, -7.4040051],
    province: 'La Libertad',
    timezone: 'America/Lima',
    unlocs: ['PEPAC'],
    code: '33300',
    id: 'PEPAC',
  },
  {
    name: 'Paita',
    city: 'Paita',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-81.0962172, -5.0938488],
    province: 'Piura',
    timezone: 'America/Lima',
    unlocs: ['PEPAI'],
    code: '33363',
    id: 'PEPAI',
  },
  {
    name: 'Puerto Chicama',
    city: 'Puerto Chicama',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-79.1407421, -7.847994799999999],
    province: 'La Libertad',
    timezone: 'America/Lima',
    unlocs: ['PEPCH'],
    id: 'PEPCH',
  },
  {
    name: 'Pisco',
    city: 'Pisco',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-76.1841701, -13.7134562],
    province: 'Ica',
    timezone: 'America/Lima',
    unlocs: ['PEPIO'],
    code: '33373',
    id: 'PEPIO',
  },
  {
    name: 'San Nicolas',
    city: 'San Nicolas',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-77.5053269, -6.3867084],
    province: 'Amazonas',
    timezone: 'America/Lima',
    unlocs: ['PESNX'],
    code: '33380',
    id: 'PESNX',
  },
  {
    name: 'Supe',
    city: 'Supe',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-77.71373059999999, -10.7987521],
    province: 'Lima',
    timezone: 'America/Lima',
    unlocs: ['PESUP'],
    code: '33385',
    id: 'PESUP',
  },
  {
    name: 'Salaverry',
    city: 'Salaverry',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-78.97689989999999, -8.2141205],
    province: 'La Libertad',
    timezone: 'America/Lima',
    unlocs: ['PESVY'],
    code: '33377',
    id: 'PESVY',
  },
  {
    name: 'Talara',
    city: 'Talara',
    country: 'Peru',
    alias: [],
    regions: [],
    coordinates: [-81.26289609999999, -4.581151999999999],
    province: 'Piura',
    timezone: 'America/Lima',
    unlocs: ['PETYL'],
    code: '33389',
    id: 'PETYL',
  },
  {
    name: 'Papeete',
    city: 'Papeete',
    country: 'French Polynesia',
    alias: [],
    regions: [],
    coordinates: [-149.5584758, -17.5516251],
    province: 'Windward Islands',
    timezone: 'Pacific/Tahiti',
    unlocs: ['PFPPT'],
    code: '64151',
    id: 'PFPPT',
  },
  {
    name: 'Lae',
    city: 'Lae',
    country: 'Papua New Guinea',
    alias: [],
    regions: [],
    coordinates: [146.999905, -6.7155252],
    province: 'Morobe Province',
    timezone: 'Pacific/Port_Moresby',
    unlocs: ['PGLAE'],
    code: '60441',
    id: 'PGLAE',
  },
  {
    name: 'Madang',
    city: 'Madang',
    country: 'Papua New Guinea',
    alias: [],
    regions: [],
    coordinates: [145.7856324, -5.2218841],
    province: 'Madang Province',
    timezone: 'Pacific/Port_Moresby',
    unlocs: ['PGMAG'],
    code: '60400',
    id: 'PGMAG',
  },
  {
    name: 'Port Moresby',
    city: 'Port Moresby',
    country: 'Papua New Guinea',
    alias: [],
    regions: [],
    coordinates: [147.1802671, -9.443800399999999],
    province: 'National Capital District',
    timezone: 'Pacific/Port_Moresby',
    unlocs: ['PGPOM'],
    code: '60447',
    id: 'PGPOM',
  },
  {
    name: 'Rabaul',
    city: 'Rabaul',
    country: 'Papua New Guinea',
    alias: [],
    regions: [],
    coordinates: [152.1644612, -4.1999648],
    province: 'East New Britain Province',
    timezone: 'Pacific/Port_Moresby',
    unlocs: ['PGRAB'],
    code: '60449',
    id: 'PGRAB',
  },
  {
    name: 'Cebu',
    city: 'Cebu',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [123.8854366, 10.3156992],
    province: 'Central Visayas',
    timezone: 'Asia/Manila',
    unlocs: ['PHCEB'],
    code: '56520',
    id: 'PHCEB',
  },
  {
    name: 'Cagayan de Oro, Mindanao',
    city: 'Cagayan de Oro, Mindanao',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [124.6318977, 8.4542363],
    province: 'Northern Mindanao',
    timezone: 'Asia/Manila',
    unlocs: ['PHCGY'],
    id: 'PHCGY',
  },
  {
    name: 'Davao, Mindanao',
    city: 'Davao, Mindanao',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [125.455341, 7.190708],
    province: 'Davao Region',
    timezone: 'Asia/Manila',
    unlocs: ['PHDVO'],
    id: 'PHDVO',
  },
  {
    name: 'General Santos',
    coordinates: [125.15, 6.12],
    city: 'General Santos',
    country: 'Philippines',
    alias: [],
    regions: [],
    province: 'Region XII',
    timezone: 'Asia/Manila',
    unlocs: ['PHGES'],
    id: 'PHGES',
  },
  {
    name: 'Iligan, Mindanao',
    city: 'Iligan, Mindanao',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [124.2452422, 8.228020899999999],
    province: 'Northern Mindanao',
    timezone: 'Asia/Manila',
    unlocs: ['PHIGN'],
    id: 'PHIGN',
  },
  {
    name: 'Isabela (Basilan)',
    city: 'Isabela (Basilan)',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [121.969, 6.7029445],
    province: 'Zamboanga Peninsula',
    timezone: 'Asia/Manila',
    unlocs: ['PHISB'],
    id: 'PHISB',
  },
  {
    name: 'Magellanes/Masao',
    city: 'Magellanes/Masao',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [121.774017, 12.879721],
    timezone: 'Asia/Manila',
    unlocs: ['PHMLS'],
    id: 'PHMLS',
  },
  {
    timezone: 'Asia/Manila',
    coordinates: [125.6368861, 10.9955232],
    name: 'Manicani',
    city: 'Manicani',
    province: 'Eastern Samar',
    country: 'Philippines',
    alias: [],
    regions: [],
    unlocs: ['PHMNI'],
    id: 'PHMNI',
  },
  {
    name: 'Manila',
    city: 'Manila',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [120.9842195, 14.5995124],
    province: 'Metro Manila',
    timezone: 'Asia/Manila',
    unlocs: ['PHMNL'],
    code: '56549',
    id: 'PHMNL',
  },
  {
    name: 'Manila North Harbour',
    city: 'Manila North Harbour',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [120.9566667, 14.6161111],
    timezone: 'Asia/Manila',
    unlocs: ['PHMNN'],
    id: 'PHMNN',
  },
  {
    name: 'Manila South Harbour',
    city: 'Manila South Harbour',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [120.9691667, 14.5758333],
    timezone: 'Asia/Manila',
    unlocs: ['PHMNS'],
    id: 'PHMNS',
  },
  {
    name: 'Ozamis, Mindanao',
    city: 'Ozamis, Mindanao',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [123.7741195, 8.162410999999999],
    province: 'Northern Mindanao',
    timezone: 'Asia/Manila',
    unlocs: ['PHOZC'],
    id: 'PHOZC',
  },
  {
    timezone: 'Asia/Manila',
    coordinates: [120.7115592, 18.1647281],
    name: 'Pasaleng, Luzon',
    city: 'Pasaleng, Luzon',
    province: 'Ilocos Norte',
    country: 'Philippines',
    alias: [],
    regions: [],
    unlocs: ['PHPSL'],
    id: 'PHPSL',
  },
  {
    name: 'Subic Bay',
    city: 'Subic Bay',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [120.2397336, 14.7910576],
    timezone: 'Asia/Manila',
    unlocs: ['PHSFS'],
    code: '56585',
    id: 'PHSFS',
  },
  {
    name: 'Zamboanga',
    city: 'Zamboanga',
    country: 'Philippines',
    alias: [],
    regions: [],
    coordinates: [122.0790267, 6.9214424],
    province: 'Zamboanga Peninsula',
    timezone: 'Asia/Manila',
    unlocs: ['PHZAM'],
    code: '56597',
    id: 'PHZAM',
  },
  {
    name: 'Karachi-muhammad Bin Qasim',
    city: 'Karachi-muhammad Bin Qasim',
    country: 'Pakistan',
    alias: [],
    regions: [],
    coordinates: [67.0152682, 24.8550065],
    province: 'Sindh',
    timezone: 'Asia/Karachi',
    unlocs: ['PKBQM'],
    id: 'PKBQM',
  },
  {
    name: 'Karachi',
    city: 'Karachi',
    province: 'Sindh',
    country: 'Pakistan',
    alias: [],
    regions: [],
    coordinates: [67.0099388, 24.8614622],
    timezone: 'Asia/Karachi',
    unlocs: ['PKKHI'],
    code: '53550',
    id: 'PKKHI',
  },
  {
    name: 'Gdansk',
    city: 'Gdansk',
    country: 'Poland',
    alias: [],
    regions: [],
    coordinates: [18.6466384, 54.35202520000001],
    province: 'Pomeranian Voivodeship',
    timezone: 'Europe/Warsaw',
    unlocs: ['PLGDN'],
    code: '45511',
    id: 'PLGDN',
  },
  {
    name: 'Gdynia',
    coordinates: [18.55, 54.5],
    city: 'Gdynia',
    province: 'Pomorskie',
    country: 'Poland',
    alias: [],
    regions: [],
    timezone: 'Europe/Warsaw',
    unlocs: ['PLGDY'],
    code: '45505',
    id: 'PLGDY',
  },
  {
    name: 'Swinoujscie',
    coordinates: [14.25, 53.9],
    city: 'Swinoujscie',
    country: 'Poland',
    alias: [],
    regions: [],
    province: 'West Pomeranian Voivodeship',
    timezone: 'Europe/Warsaw',
    unlocs: ['PLSWI'],
    code: '45512',
    id: 'PLSWI',
  },
  {
    name: 'Szczecin',
    city: 'Szczecin',
    country: 'Poland',
    alias: [],
    regions: [],
    coordinates: [14.5528116, 53.4285438],
    province: 'West Pomeranian Voivodeship',
    timezone: 'Europe/Warsaw',
    unlocs: ['PLSZZ'],
    code: '45507',
    id: 'PLSZZ',
  },
  {
    name: 'Aguadilla',
    city: 'Aguadilla',
    country: 'Puerto Rico',
    alias: [],
    regions: [],
    coordinates: [-67.1540698, 18.4274454],
    province: 'Aguadilla',
    timezone: 'America/Puerto_Rico',
    unlocs: ['PRBQN'],
    id: 'PRBQN',
  },
  {
    name: 'Mayaguez',
    city: 'Mayagüez',
    country: 'Puerto Rico',
    alias: [],
    regions: [],
    coordinates: [-67.1451517, 18.2013463],
    province: 'Mayagüez',
    timezone: 'America/Puerto_Rico',
    unlocs: ['PRMAZ'],
    id: 'PRMAZ',
  },
  {
    name: 'Puerto Nuevo',
    city: 'Puerto Nuevo',
    country: 'Puerto Rico',
    alias: [],
    regions: [],
    coordinates: [-66.08273679999999, 18.4173179],
    province: 'San Juan',
    timezone: 'America/Puerto_Rico',
    unlocs: ['PRPNU'],
    code: '33132',
    id: 'PRPNU',
  },
  {
    name: 'Ponce',
    city: 'Ponce',
    country: 'Puerto Rico',
    alias: [],
    regions: [],
    coordinates: [-66.6140616, 18.0110768],
    province: 'Ponce',
    timezone: 'America/Puerto_Rico',
    unlocs: ['PRPSE'],
    id: 'PRPSE',
  },
  {
    name: 'San Juan',
    coordinates: [-66.08, 18.45],
    city: 'San Juan',
    country: 'Puerto Rico',
    alias: [],
    regions: [],
    province: 'San Juan',
    timezone: 'America/Puerto_Rico',
    unlocs: ['PRSJU'],
    id: 'PRSJU',
  },
  {
    name: 'Angra do Heroismo',
    coordinates: [-27.22, 38.65],
    city: 'Angra do Heroísmo',
    province: 'Região Autónoma dos Açores',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Atlantic/Azores',
    unlocs: ['PTADH'],
    code: '47150',
    id: 'PTADH',
  },
  {
    name: 'Canical',
    coordinates: [-16.73, 32.73],
    city: 'Caniçal',
    province: 'Região Autónoma da Madeira',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Europe/Lisbon',
    unlocs: ['PTCNL'],
    id: 'PTCNL',
  },
  {
    name: 'Entroncamento',
    coordinates: [-8.47, 39.47],
    city: 'Entroncamento',
    province: 'Santarém',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Europe/Lisbon',
    unlocs: ['PTENT'],
    id: 'PTENT',
  },
  {
    name: 'Figueira da Foz',
    coordinates: [-8.87, 40.15],
    city: 'Figueira da Foz',
    province: 'Coimbra',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Europe/Lisbon',
    unlocs: ['PTFDF'],
    code: '47135',
    id: 'PTFDF',
  },
  {
    name: 'Funchal, Madeira',
    coordinates: [-16.9, 32.63],
    city: 'Funchal, Madeira',
    province: 'Região Autónoma da Madeira',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Europe/Lisbon',
    unlocs: ['PTFNC'],
    id: 'PTFNC',
  },
  {
    name: 'Horta',
    coordinates: [-28.63, 38.53],
    city: 'Horta',
    province: 'Região Autónoma dos Açores',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Asia/Calcutta',
    unlocs: ['PTHOR'],
    code: '47150',
    id: 'PTHOR',
  },
  {
    name: 'Leixoes',
    coordinates: [-8.68, 41.18],
    city: 'Leixões',
    province: 'Porto',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Europe/Lisbon',
    unlocs: ['PTLEI'],
    code: '47123',
    id: 'PTLEI',
  },
  {
    name: 'Lisboa',
    coordinates: [-9.13, 38.72],
    city: 'Lisboa',
    province: 'Lisboa',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Europe/Lisbon',
    unlocs: ['PTLIS'],
    code: '47125',
    id: 'PTLIS',
  },
  {
    name: 'Porto',
    coordinates: [-8.62, 41.15],
    city: 'Porto',
    province: 'Porto',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Europe/Lisbon',
    unlocs: ['PTOPO'],
    code: '47133',
    id: 'PTOPO',
  },
  {
    name: 'Ponta Delgada',
    coordinates: [-25.67, 37.73],
    city: 'Ponta Delgada',
    province: 'Região Autónoma dos Açores',
    country: 'Portugal',
    alias: [],
    regions: [],
    unlocs: ['PTPDL'],
    code: '47151',
    id: 'PTPDL',
  },
  {
    name: 'Praia da Vitoria',
    coordinates: [-27.07, 38.73],
    city: 'Praia da Vitória',
    province: 'Região Autónoma dos Açores',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Atlantic/Azores',
    unlocs: ['PTPRV'],
    id: 'PTPRV',
  },
  {
    name: 'Setubal',
    coordinates: [-8.9, 38.53],
    city: 'Setúbal',
    province: 'Setúbal',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Europe/Lisbon',
    unlocs: ['PTSET'],
    code: '47141',
    id: 'PTSET',
  },
  {
    name: 'Sines',
    coordinates: [-8.87, 37.95],
    city: 'Sines',
    province: 'Setúbal',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Europe/Lisbon',
    unlocs: ['PTSIE'],
    code: '47127',
    id: 'PTSIE',
  },
  {
    name: 'Terceira Island Apt',
    coordinates: [-27.22, 38.72],
    city: 'Terceira Island Apt',
    province: 'Região Autónoma dos Açores',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Atlantic/Azores',
    unlocs: ['PTTER'],
    id: 'PTTER',
  },
  {
    name: 'Viana do Castelo',
    coordinates: [-8.83, 41.7],
    city: 'Viana do Castelo',
    province: 'Viana do Castelo',
    country: 'Portugal',
    alias: [],
    regions: [],
    timezone: 'Europe/Lisbon',
    unlocs: ['PTVDC'],
    id: 'PTVDC',
  },
  {
    name: 'Asuncion',
    coordinates: [-57.67, -25.27],
    city: 'Asunción',
    province: 'Asunción',
    country: 'Paraguay',
    alias: [],
    regions: [],
    timezone: 'America/Asuncion',
    unlocs: ['PYASU'],
    code: '35301',
    id: 'PYASU',
  },
  {
    name: 'Barrio Caacupe Mi',
    coordinates: [-57.55, -25.22],
    city: 'Barrio Caacupé Mí',
    province: 'Central',
    country: 'Paraguay',
    alias: [],
    regions: [],
    timezone: 'America/Asuncion',
    unlocs: ['PYBCM'],
    id: 'PYBCM',
  },
  {
    name: 'Fenix',
    city: 'Fenix',
    province: 'Asunción',
    country: 'Paraguay',
    alias: [],
    regions: [],
    coordinates: [-57.57592599999999, -25.2637399],
    timezone: 'America/Asuncion',
    unlocs: ['PYFNX'],
    id: 'PYFNX',
  },
  {
    name: 'Pilar',
    city: 'Pilar',
    country: 'Paraguay',
    alias: [],
    regions: [],
    coordinates: [-58.2918546, -26.8634263],
    province: 'Neembucu',
    timezone: 'America/Asuncion',
    unlocs: ['PYPIL'],
    id: 'PYPIL',
  },
  {
    name: 'Pto Seguro/col Mariano Roque Alonso',
    city: 'Pto Seguro/col Mariano Roque Alonso',
    province: 'Presidente Hayes',
    country: 'Paraguay',
    alias: [],
    regions: [],
    coordinates: [-57.55, -25.166667],
    timezone: 'America/Asuncion',
    unlocs: ['PYPSE'],
    id: 'PYPSE',
  },
  {
    name: 'San Antonio',
    city: 'San Antonio',
    country: 'Paraguay',
    alias: [],
    regions: [],
    coordinates: [-57.54761449999999, -25.4200064],
    province: 'Central',
    timezone: 'America/Asuncion',
    unlocs: ['PYSAN'],
    code: '35300',
    id: 'PYSAN',
  },
  {
    name: 'Terport (San Antonio)',
    coordinates: [-57.63, -25.38],
    city: 'Terport (San Antonio)',
    country: 'Paraguay',
    alias: [],
    regions: [],
    province: 'Central',
    timezone: 'America/Asuncion',
    unlocs: ['PYTER'],
    id: 'PYTER',
  },
  {
    name: 'Doha',
    city: 'Doha',
    province: 'Ad Dawhah',
    country: 'Qatar',
    alias: [],
    regions: [],
    coordinates: [51.4939853, 25.3521436],
    timezone: 'Asia/Qatar',
    unlocs: ['QADOH'],
    code: '51813',
    id: 'QADOH',
  },
  {
    name: 'Mesaieed',
    coordinates: [51.92, 25.63],
    city: 'Mesaieed',
    province: 'Al Wakrah',
    country: 'Qatar',
    alias: [],
    regions: [],
    unlocs: ['QAMES'],
    id: 'QAMES',
  },
  {
    name: "Umm Sa'id (Mesaieed)",
    coordinates: [51.53, 24.98],
    city: "Umm Sa'id (Mesaieed)",
    province: 'Al Wakrah',
    country: 'Qatar',
    alias: [],
    regions: [],
    timezone: 'Asia/Qatar',
    unlocs: ['QAUMS'],
    id: 'QAUMS',
  },
  {
    name: 'Le Port',
    coordinates: [55.3, -20.92],
    city: 'Le',
    country: 'Réunion',
    alias: [],
    regions: [],
    unlocs: ['RELPT'],
    id: 'RELPT',
  },
  {
    name: 'Port de Pointe des Galets',
    city: 'Port de Pointe des Galets',
    country: 'Réunion',
    alias: [],
    regions: [],
    coordinates: [-20.9239169, 55.2856063],
    unlocs: ['REPDG'],
    id: 'REPDG',
  },
  {
    name: 'Reunion',
    coordinates: [55.53, -20.88],
    city: 'Réunion',
    country: 'Réunion',
    alias: [],
    regions: [],
    timezone: 'Indian/Reunion',
    unlocs: ['REREU'],
    id: 'REREU',
  },
  {
    name: 'Saint-Denis de la Reunion Apt',
    coordinates: [55.53, -20.88],
    city: 'Saint-Denis de la Réunion Apt',
    country: 'Réunion',
    alias: [],
    regions: [],
    province: 'Île-de-France',
    timezone: 'Indian/Reunion',
    unlocs: ['RERUN'],
    id: 'RERUN',
  },
  {
    name: 'Agigea',
    coordinates: [28.62, 44.08],
    city: 'Agigea',
    province: 'Constarta',
    country: 'Romania',
    alias: [],
    regions: [],
    timezone: 'Europe/Bucharest',
    unlocs: ['ROAGI'],
    id: 'ROAGI',
  },
  {
    name: 'Braila',
    coordinates: [27.98, 45.27],
    city: 'Braila',
    province: 'Braila',
    country: 'Romania',
    alias: [],
    regions: [],
    timezone: 'Europe/Bucharest',
    unlocs: ['ROBRA'],
    code: '48500',
    id: 'ROBRA',
  },
  {
    name: 'Constanta',
    coordinates: [28.65, 44.18],
    city: 'Constanta',
    province: 'Constarta',
    country: 'Romania',
    alias: [],
    regions: [],
    timezone: 'Europe/Bucharest',
    unlocs: ['ROCND'],
    code: '48511',
    id: 'ROCND',
  },
  {
    name: 'Galati',
    coordinates: [28.05, 45.43],
    city: 'Galati',
    province: 'Galati',
    country: 'Romania',
    alias: [],
    regions: [],
    timezone: 'Europe/Bucharest',
    unlocs: ['ROGAL'],
    code: '48500',
    id: 'ROGAL',
  },
  {
    timezone: 'Europe/Bucharest',
    coordinates: [28.5741496, 43.8152436],
    name: 'Mangalia',
    city: 'Mangalia',
    province: 'Constarţa',
    country: 'Romania',
    alias: [],
    regions: [],
    unlocs: ['ROMAG'],
    id: 'ROMAG',
  },
  {
    name: 'Arkhangelsk',
    city: 'Arkhangelsk',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [40.5601553, 64.54725069999999],
    province: 'Arkhangelsk Oblast',
    timezone: 'Europe/Moscow',
    unlocs: ['RUARH'],
    code: '46200',
    id: 'RUARH',
  },
  {
    name: 'Magadan',
    city: 'Magadan',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [150.8301413, 59.5611525],
    province: 'Magadan Oblast',
    timezone: 'Asia/Vladivostok',
    unlocs: ['RUGDX'],
    code: '46232',
    id: 'RUGDX',
  },
  {
    name: 'Kaliningrad',
    city: 'Kaliningrad',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [20.4522144, 54.7104264],
    province: 'Kaliningrad Oblast',
    timezone: 'Europe/Kaliningrad',
    unlocs: ['RUKGD'],
    code: '46213',
    id: 'RUKGD',
  },
  {
    name: 'Kholmsk',
    coordinates: [142.05, 47.05],
    city: 'Kholmsk',
    province: "Sakhalinskaya oblast'",
    country: 'Russian Federation',
    alias: [],
    regions: [],
    timezone: 'Asia/Vladivostok',
    unlocs: ['RUKHO'],
    id: 'RUKHO',
  },
  {
    name: 'Korsakov',
    city: 'Korsakov',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [142.7841876, 46.6353796],
    province: 'Sakhalin Oblast',
    timezone: 'Asia/Vladivostok',
    unlocs: ['RUKOR'],
    code: '46236',
    id: 'RUKOR',
  },
  {
    name: 'Krasnodar',
    city: 'Krasnodar',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [38.987221, 45.03926740000001],
    province: 'Krasnodar Krai',
    timezone: 'Europe/Moscow',
    unlocs: ['RUKRR'],
    id: 'RUKRR',
  },
  {
    name: 'Saint Petersburg (ex Leningrad)',
    coordinates: [30.25, 59.88],
    city: 'Saint Petersburg (ex Leningrad)',
    province: 'Sankt-Peterburg',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    timezone: 'Europe/Moscow',
    unlocs: ['RULED'],
    id: 'RULED',
  },
  {
    name: 'Murmansk',
    city: 'Murmansk',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [33.0826598, 68.9585244],
    province: 'Murmansk Oblast',
    timezone: 'Europe/Moscow',
    unlocs: ['RUMMK'],
    code: '46209',
    id: 'RUMMK',
  },
  {
    name: 'Nakhodka',
    city: 'Nakhodka',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [132.8834039, 42.8222753],
    province: 'Primorsky Krai',
    timezone: 'Asia/Vladivostok',
    unlocs: ['RUNJK'],
    code: '46230',
    id: 'RUNJK',
  },
  {
    name: 'Novorossiysk',
    coordinates: [37.77, 44.72],
    city: 'Novorossiysk',
    province: 'Krasnodarskiy kray',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    timezone: 'Europe/Moscow',
    unlocs: ['RUNVS'],
    code: '46241',
    id: 'RUNVS',
  },
  {
    timezone: 'Asia/Irkutsk',
    coordinates: [103.948625, 56.13214199999999],
    name: 'Potapova',
    city: 'Potapova',
    province: "Irkutskaya oblast'",
    country: 'Russian Federation',
    alias: [],
    regions: [],
    unlocs: ['RUPTP'],
    id: 'RUPTP',
  },
  {
    name: 'Rostov',
    city: 'Rostov',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [39.701505, 47.2357137],
    province: 'Rostov Oblast',
    timezone: 'Europe/Moscow',
    unlocs: ['RUROV'],
    id: 'RUROV',
  },
  {
    name: 'Taganrog',
    city: 'Taganrog',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [38.8676013, 47.2416334],
    province: 'Rostov Oblast',
    timezone: 'Europe/Moscow',
    unlocs: ['RUTAG'],
    code: '46242',
    id: 'RUTAG',
  },
  {
    name: 'Vladivostok',
    city: 'Vladivostok',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [132.0064506, 43.1737387],
    province: 'Primorsky Krai',
    timezone: 'Asia/Vladivostok',
    unlocs: ['RUVVO'],
    code: '46238',
    id: 'RUVVO',
  },
  {
    name: 'Vyborg',
    city: 'Vyborg',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    coordinates: [28.75715709999999, 60.7139529],
    province: 'Leningrad Oblast',
    timezone: 'Europe/Moscow',
    unlocs: ['RUVYG'],
    code: '46220',
    id: 'RUVYG',
  },
  {
    name: 'Vostochnyy Port',
    coordinates: [133.05, 42.77],
    city: 'Vostochnyy',
    country: 'Russian Federation',
    alias: [],
    regions: [],
    province: 'Moscow',
    timezone: 'Asia/Vladivostok',
    unlocs: ['RUVYP'],
    code: '46231',
    id: 'RUVYP',
  },
  {
    name: 'Kigali',
    city: 'Kigali',
    country: 'Rwanda',
    alias: [],
    regions: [],
    coordinates: [30.112735, -1.9578755],
    province: 'Kigali',
    timezone: 'Africa/Kigali',
    unlocs: ['RWKGL'],
    id: 'RWKGL',
  },
  {
    name: 'Ad Dammam',
    coordinates: [50.1, 26.42],
    city: 'Ad Dammam',
    province: 'Ash Sharqiyah',
    country: 'Saudi Arabia',
    alias: [],
    regions: [],
    timezone: 'Asia/Riyadh',
    unlocs: ['SADMM'],
    code: '51715',
    id: 'SADMM',
  },
  {
    name: 'Dammam',
    city: 'Dammam',
    country: 'Saudi Arabia',
    alias: [],
    regions: [],
    coordinates: [49.9777136, 26.3926665],
    province: 'Eastern Province',
    timezone: 'Asia/Riyadh',
    unlocs: ['SADMN'],
    id: 'SADMN',
  },
  {
    name: 'Jeddah',
    city: 'Jeddah',
    country: 'Saudi Arabia',
    alias: [],
    regions: [],
    coordinates: [39.2375507, 21.2854067],
    province: 'Makkah Province',
    timezone: 'Asia/Riyadh',
    unlocs: ['SAJED'],
    id: 'SAJED',
  },
  {
    name: 'Jubail',
    city: 'Jubail',
    country: 'Saudi Arabia',
    alias: [],
    regions: [],
    coordinates: [49.5687416, 26.9597709],
    province: 'Eastern Province',
    timezone: 'Asia/Riyadh',
    unlocs: ['SAJUB'],
    code: '51720',
    id: 'SAJUB',
  },
  {
    name: 'King Abdullah City',
    coordinates: [39.08, 22.4],
    city: 'King Abdullah City',
    province: 'Makkah al Mukarramah',
    country: 'Saudi Arabia',
    alias: [],
    regions: [],
    timezone: 'Asia/Riyadh',
    unlocs: ['SAKAC'],
    id: 'SAKAC',
  },
  {
    name: 'Riyadh',
    city: 'Riyadh',
    country: 'Saudi Arabia',
    alias: [],
    regions: [],
    coordinates: [46.9028375, 24.7494029],
    province: 'Riyadh Province',
    timezone: 'Asia/Riyadh',
    unlocs: ['SARUH'],
    id: 'SARUH',
  },
  {
    name: 'Honiara, Guadalcanal Is',
    city: 'Honiara, Guadalcanal Is',
    country: 'Solomon Islands',
    alias: [],
    regions: [],
    coordinates: [159.9728999, -9.4456381],
    province: 'Capital Territory',
    timezone: 'Pacific/Guadalcanal',
    unlocs: ['SBHIR'],
    id: 'SBHIR',
  },
  {
    name: 'Noro, New Georgia',
    city: 'Noro',
    province: 'New Georgia',
    country: 'Solomon Islands',
    alias: [],
    regions: [],
    coordinates: [157.199876, -8.2409868],
    timezone: 'Pacific/Guadalcanal',
    unlocs: ['SBNOR'],
    id: 'SBNOR',
  },
  {
    name: 'Mahe',
    city: 'Mahe',
    country: 'Seychelles',
    alias: [],
    regions: [],
    coordinates: [55.480396, -4.6826693],
    timezone: 'Indian/Mahe',
    unlocs: ['SCMAW'],
    id: 'SCMAW',
  },
  {
    name: 'Port Victoria',
    city: 'Port Victoria',
    country: 'Seychelles',
    alias: [],
    regions: [],
    coordinates: [55.4833333, -4.6166667],
    timezone: 'Indian/Mahe',
    unlocs: ['SCPOV'],
    code: '78083',
    id: 'SCPOV',
  },
  {
    name: 'Port Sudan',
    city: 'Port Sudan',
    province: 'Al Bahr al Ahmar',
    country: 'Sudan',
    alias: [],
    regions: [],
    coordinates: [37.1901616, 19.5903471],
    timezone: 'Africa/Khartoum',
    unlocs: ['SDPZU'],
    code: '73201',
    id: 'SDPZU',
  },
  {
    name: 'Ahus',
    city: 'Åhus',
    province: 'Skåne län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [14.2979949, 55.9250391],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEAHU'],
    code: '40122',
    id: 'SEAHU',
  },
  {
    name: 'Falkenberg',
    city: 'Falkenberg',
    province: 'Hallands län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [12.4888013, 56.90273329999999],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEFAG'],
    code: '40121',
    id: 'SEFAG',
  },
  {
    name: 'Goteborg',
    coordinates: [11.97, 57.72],
    city: 'Göteborg',
    province: 'Västra Götalands län',
    country: 'Sweden',
    alias: [],
    regions: [],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEGOT'],
    code: '40117',
    id: 'SEGOT',
  },
  {
    name: 'Gavle',
    city: 'Gävle',
    province: 'Gävleborgs län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [17.1412726, 60.6748796],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEGVX'],
    code: '40116',
    id: 'SEGVX',
  },
  {
    name: 'Halmstad',
    city: 'Halmstad',
    province: 'Hallands län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [12.8577884, 56.6743748],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEHAD'],
    code: '40119',
    id: 'SEHAD',
  },
  {
    name: 'Helsingborg',
    city: 'Helsingborg',
    province: 'Skåne län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [12.6945121, 56.0464674],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEHEL'],
    code: '40120',
    id: 'SEHEL',
  },
  {
    name: 'Karlshamn',
    city: 'Karlshamn',
    province: 'Blekinge län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [14.863073, 56.170303],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEKAN'],
    code: '40133',
    id: 'SEKAN',
  },
  {
    name: 'Karlstad',
    city: 'Karlstad',
    province: 'Värmlands län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [13.5114977, 59.4021806],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEKSD'],
    code: '40100',
    id: 'SEKSD',
  },
  {
    name: 'Landskrona',
    city: 'Landskrona',
    province: 'Skåne län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [12.8300802, 55.8703477],
    timezone: 'Europe/Stockholm',
    unlocs: ['SELAA'],
    id: 'SELAA',
  },
  {
    name: 'Malmo',
    coordinates: [13, 55.6],
    city: 'Malmö',
    province: 'Skåne län',
    country: 'Sweden',
    alias: [],
    regions: [],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEMMA'],
    code: '40143',
    id: 'SEMMA',
  },
  {
    name: 'Norrkoping',
    city: 'Norrköping',
    province: 'Östergötlands län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [16.192421, 58.587745],
    timezone: 'Europe/Stockholm',
    unlocs: ['SENRK'],
    code: '40148',
    id: 'SENRK',
  },
  {
    name: 'Oskarshamn',
    city: 'Oskarshamn',
    province: 'Kalmar län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [16.4473984, 57.26569929999999],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEOSK'],
    code: '40157',
    id: 'SEOSK',
  },
  {
    name: 'Oxelosund',
    city: 'Oxelösund',
    province: 'Södermanlands län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [17.1037333, 58.6701741],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEOXE'],
    code: '40159',
    id: 'SEOXE',
  },
  {
    name: 'Sundsvall',
    coordinates: [17.3, 62.38],
    city: 'Sundsvall',
    province: 'Västernorrlands län',
    country: 'Sweden',
    alias: [],
    regions: [],
    timezone: 'Europe/Stockholm',
    unlocs: ['SESDL'],
    code: '40182',
    id: 'SESDL',
  },
  {
    name: 'Skelleftea',
    city: 'Skellefteå',
    province: 'Västerbottens län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [20.950917, 64.750244],
    timezone: 'Europe/Stockholm',
    unlocs: ['SESFT'],
    code: '40124',
    id: 'SESFT',
  },
  {
    name: 'Skelleftehamn',
    city: 'Skelleftehamn',
    province: 'Västerbottens län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [21.2361263, 64.6861026],
    timezone: 'Europe/Stockholm',
    unlocs: ['SESKE'],
    code: '40124',
    id: 'SESKE',
  },
  {
    name: 'Sodertalje',
    coordinates: [17.62, 59.2],
    city: 'Södertälje',
    province: 'Stockholms län',
    country: 'Sweden',
    alias: [],
    regions: [],
    timezone: 'Europe/Stockholm',
    unlocs: ['SESOE'],
    id: 'SESOE',
  },
  {
    name: 'Stockholm',
    city: 'Stockholm',
    province: 'Stockholms län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [18.0685808, 59.32932349999999],
    timezone: 'Europe/Stockholm',
    unlocs: ['SESTO'],
    code: '40179',
    id: 'SESTO',
  },
  {
    name: 'Trelleborg',
    city: 'Trelleborg',
    province: 'Skåne län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [13.1574231, 55.3762427],
    timezone: 'Europe/Stockholm',
    unlocs: ['SETRG'],
    code: '40185',
    id: 'SETRG',
  },
  {
    name: 'Uddevalla',
    city: 'Uddevalla',
    province: 'Västra Götalands län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [11.935649, 58.3498003],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEUDD'],
    code: '40188',
    id: 'SEUDD',
  },
  {
    name: 'Umea',
    coordinates: [20.25, 63.83],
    city: 'Umeå',
    province: 'Västerbottens län',
    country: 'Sweden',
    alias: [],
    regions: [],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEUME'],
    code: '40125',
    id: 'SEUME',
  },
  {
    name: 'Vasteras',
    city: 'Västerås',
    province: 'Västmanlands län',
    country: 'Sweden',
    alias: [],
    regions: [],
    coordinates: [16.5448091, 59.60990049999999],
    timezone: 'Europe/Stockholm',
    unlocs: ['SEVST'],
    code: '40192',
    id: 'SEVST',
  },
  {
    name: 'Jurong/Singapore',
    coordinates: [103.7, 1.33],
    city: 'Jurong/Singapore',
    country: 'Singapore',
    alias: [],
    regions: [],
    timezone: 'Asia/Singapore',
    unlocs: ['SGJUR'],
    id: 'SGJUR',
  },
  {
    name: 'Singapore',
    city: 'Singapore',
    country: 'Singapore',
    alias: [],
    regions: [],
    coordinates: [103.8509491, 1.2800945],
    timezone: 'Asia/Singapore',
    unlocs: ['SGSIN'],
    code: '55976',
    id: 'SGSIN',
  },
  {
    name: 'Koper',
    city: 'Koper',
    country: 'Slovenia',
    alias: [],
    regions: [],
    coordinates: [13.7301877, 45.54805899999999],
    province: 'Koper',
    timezone: 'Europe/Ljubljana',
    unlocs: ['SIKOP'],
    code: '47909',
    id: 'SIKOP',
  },
  {
    name: 'Bratislava',
    coordinates: [17.12, 48.15],
    city: 'Bratislava',
    province: 'Bratislavský kraj',
    country: 'Slovakia',
    alias: [],
    regions: [],
    timezone: 'Europe/Bratislava',
    unlocs: ['SKBTS'],
    id: 'SKBTS',
  },
  {
    name: 'Freetown',
    city: 'Freetown',
    country: 'Sierra Leone',
    alias: [],
    regions: [],
    coordinates: [-13.2317225, 8.4656765],
    province: 'Western Area',
    timezone: 'Africa/Freetown',
    unlocs: ['SLFNA'],
    code: '74751',
    id: 'SLFNA',
  },
  {
    name: 'Dakar',
    city: 'Dakar',
    country: 'Senegal',
    alias: [],
    regions: [],
    coordinates: [-17.3660286, 14.7645042],
    province: 'Dakar',
    timezone: 'Africa/Dakar',
    unlocs: ['SNDKR'],
    code: '74473',
    id: 'SNDKR',
  },
  {
    name: 'Berbera',
    city: 'Berbera',
    country: 'Somalia',
    alias: [],
    regions: [],
    coordinates: [45.0139904, 10.4347941],
    province: 'Woqooyi Galbeed',
    timezone: 'Africa/Mogadishu',
    unlocs: ['SOBBO'],
    code: '77001',
    id: 'SOBBO',
  },
  {
    name: 'Kismayu',
    city: 'Kismayu',
    country: 'Somalia',
    alias: [],
    regions: [],
    coordinates: [42.5460573, -0.3560455],
    province: 'Jubbada Hoose',
    timezone: 'Africa/Mogadishu',
    unlocs: ['SOKMU'],
    code: '77099',
    id: 'SOKMU',
  },
  {
    name: 'Mogadishu',
    city: 'Mogadishu',
    country: 'Somalia',
    alias: [],
    regions: [],
    coordinates: [45.3181623, 2.0469343],
    province: 'Banaadir',
    timezone: 'Africa/Mogadishu',
    unlocs: ['SOMGQ'],
    code: '77051',
    id: 'SOMGQ',
  },
  {
    name: 'Paramaribo',
    city: 'Paramaribo',
    country: 'Suriname',
    alias: [],
    regions: [],
    coordinates: [-55.2038278, 5.8520355],
    province: 'Paramaribo District',
    timezone: 'America/Paramaribo',
    unlocs: ['SRPBM'],
    code: '31523',
    id: 'SRPBM',
  },
  {
    name: 'Sao Tome Island',
    city: 'Sao Tome Island',
    country: 'Sao Tome and Principe',
    alias: [],
    regions: [],
    coordinates: [6.621061099999999, 0.2602568],
    timezone: 'Africa/Sao_Tome',
    unlocs: ['STTMS'],
    id: 'STTMS',
  },
  {
    name: 'Acajutla',
    city: 'Acajutla',
    country: 'El Salvador',
    alias: [],
    regions: [],
    coordinates: [-89.82708939999999, 13.5957],
    province: 'Sonsonate',
    timezone: 'America/El_Salvador',
    unlocs: ['SVAQJ'],
    code: '21101',
    id: 'SVAQJ',
  },
  {
    name: 'La Libertad',
    coordinates: [-89.32, 13.48],
    city: 'La Libertad',
    province: 'La Libertad',
    country: 'El Salvador',
    alias: [],
    regions: [],
    timezone: 'America/El_Salvador',
    unlocs: ['SVLLD'],
    code: '21199',
    id: 'SVLLD',
  },
  {
    name: 'La Union',
    city: 'La Union',
    country: 'El Salvador',
    alias: [],
    regions: [],
    coordinates: [-87.8500644, 13.3323464],
    province: 'La Union',
    timezone: 'America/El_Salvador',
    unlocs: ['SVLUN'],
    code: '21137',
    id: 'SVLUN',
  },
  {
    name: 'San Salvador',
    city: 'San Salvador',
    country: 'El Salvador',
    alias: [],
    regions: [],
    coordinates: [-89.2181911, 13.6929403],
    province: 'San Salvador',
    timezone: 'America/El_Salvador',
    unlocs: ['SVSAL'],
    code: '21199',
    id: 'SVSAL',
  },
  {
    name: 'Damascus (Damas)',
    city: 'Damascus (Damas)',
    country: 'Syrian Arab Republic',
    alias: [],
    regions: [],
    coordinates: [36.2765279, 33.5138073],
    province: 'Damascus Governorate',
    timezone: 'Asia/Damascus',
    unlocs: ['SYDAM'],
    id: 'SYDAM',
  },
  {
    name: 'Latakia',
    coordinates: [35.78, 35.52],
    city: 'Latakia',
    country: 'Syrian Arab Republic',
    alias: [],
    regions: [],
    province: 'Latakia Governorate',
    timezone: 'Asia/Damascus',
    unlocs: ['SYLTK'],
    code: '50215',
    id: 'SYLTK',
  },
  {
    name: 'Tartus',
    coordinates: [35.9, 34.9],
    city: 'Tartus',
    province: 'Tartus',
    country: 'Syrian Arab Republic',
    alias: [],
    regions: [],
    timezone: 'Asia/Damascus',
    unlocs: ['SYTTS'],
    code: '50220',
    id: 'SYTTS',
  },
  {
    name: 'Providenciales',
    city: 'Providenciales',
    country: 'Turks and Caicos Islands',
    alias: [],
    regions: [],
    coordinates: [-72.271908, 21.7737654],
    province: 'Caicos Islands',
    timezone: 'America/Grand_Turk',
    unlocs: ['TCPLS'],
    code: '24311',
    id: 'TCPLS',
  },
  {
    name: 'Lome',
    city: 'Lome',
    country: 'Togo',
    alias: [],
    regions: [],
    coordinates: [1.2313618, 6.1724969],
    province: 'Maritime',
    timezone: 'Africa/Lome',
    unlocs: ['TGLFW'],
    code: '75291',
    id: 'TGLFW',
  },
  {
    name: 'Bangpakong',
    city: 'Bangpakong',
    country: 'Thailand',
    alias: [],
    regions: [],
    coordinates: [100.6541717, 13.7255165],
    province: 'Krung Thep Maha Nakhon',
    timezone: 'Asia/Bangkok',
    unlocs: ['THBGP'],
    id: 'THBGP',
  },
  {
    name: 'Bangkok',
    coordinates: [100.52, 13.75],
    city: 'Bangkok',
    province: 'Krung Thep Maha Nakhon [Bangkok]',
    country: 'Thailand',
    alias: [],
    regions: [],
    timezone: 'Asia/Tehran',
    unlocs: ['THBKK'],
    code: '54901',
    id: 'THBKK',
  },
  {
    name: 'Bangkok Modern Terminals/Bangkok',
    coordinates: [100.58, 13.6],
    city: 'Bangkok Modern Terminals/Bangkok',
    country: 'Thailand',
    alias: [],
    regions: [],
    timezone: 'Asia/Bangkok',
    unlocs: ['THBMT'],
    id: 'THBMT',
  },
  {
    name: 'Koh Sichang',
    city: 'Koh Sichang',
    country: 'Thailand',
    alias: [],
    regions: [],
    coordinates: [100.8084675, 13.1499153],
    timezone: 'Asia/Bangkok',
    unlocs: ['THKSI'],
    id: 'THKSI',
  },
  {
    name: 'Khlong Toei',
    coordinates: [100.57, 13.72],
    city: 'Khlong Toei',
    province: 'Krung Thep Maha Nakhon [Bangkok]',
    country: 'Thailand',
    alias: [],
    regions: [],
    timezone: 'Asia/Bangkok',
    unlocs: ['THKTY'],
    id: 'THKTY',
  },
  {
    name: 'Laem Chabang',
    coordinates: [100.88, 13.08],
    city: 'Laem Chabang',
    province: 'Chon Buri',
    country: 'Thailand',
    alias: [],
    regions: [],
    timezone: 'Asia/Bangkok',
    unlocs: ['THLCH'],
    code: '54930',
    id: 'THLCH',
  },
  {
    country: 'Thailand',
    province: 'Bangkok',
    city: 'Lat Krabang',
    name: 'Lat Krabang',
    alias: [],
    regions: [],
    unlocs: ['THLKR'],
    id: 'THLKR',
  },
  {
    name: 'Mab Tapud',
    city: 'Mab Tapud',
    country: 'Thailand',
    alias: [],
    regions: [],
    coordinates: [100.5313648, 13.6935027],
    province: 'จังหวัด กรุงเทพมหานคร',
    timezone: 'Asia/Bangkok',
    unlocs: ['THMAT'],
    id: 'THMAT',
  },
  {
    name: 'Pat Bangkok',
    coordinates: [100.57, 13.7],
    city: 'Pat Bangkok',
    country: 'Thailand',
    alias: [],
    regions: [],
    province: 'Pathum Thani',
    timezone: 'Asia/Bangkok',
    unlocs: ['THPAT'],
    id: 'THPAT',
  },
  {
    name: 'Paknam',
    city: 'Paknam',
    country: 'Thailand',
    alias: [],
    regions: [],
    coordinates: [103.1675053, 15.9050383],
    province: 'Maha Sarakham',
    timezone: 'Asia/Bangkok',
    unlocs: ['THPKN'],
    id: 'THPKN',
  },
  {
    name: 'Siam Bangkok Port',
    coordinates: [100.55, 13.65],
    city: 'Siam Bangkok',
    country: 'Thailand',
    alias: [],
    regions: [],
    timezone: 'Asia/Bangkok',
    unlocs: ['THSBP'],
    id: 'THSBP',
  },
  {
    name: 'Songkhla',
    coordinates: [100.6, 7.2],
    city: 'Songkhla',
    province: 'Songkhla',
    country: 'Thailand',
    alias: [],
    regions: [],
    timezone: 'Asia/Bangkok',
    unlocs: ['THSGZ'],
    code: '54927',
    id: 'THSGZ',
  },
  {
    name: 'Samut Prakarn',
    city: 'Samut Prakarn',
    country: 'Thailand',
    alias: [],
    regions: [],
    coordinates: [100.5998319, 13.5990961],
    province: 'Samut Prakan',
    timezone: 'Asia/Bangkok',
    unlocs: ['THSPR'],
    id: 'THSPR',
  },
  {
    name: 'Sriracha',
    city: 'Sriracha',
    country: 'Thailand',
    alias: [],
    regions: [],
    coordinates: [100.8750802, 13.1336611],
    timezone: 'Asia/Bangkok',
    unlocs: ['THSRI'],
    code: '54905',
    id: 'THSRI',
  },
  {
    name: 'Thai prosperity terminal',
    city: 'Thai prosperity terminal',
    country: 'Thailand',
    alias: [],
    regions: [],
    unlocs: ['THTPT'],
    id: 'THTPT',
  },
  {
    name: 'Gabes',
    coordinates: [10.12, 33.88],
    city: 'Gabès',
    country: 'Tunisia',
    alias: [],
    regions: [],
    province: 'Gabes',
    timezone: 'Africa/Tunis',
    unlocs: ['TNGAE'],
    code: '72305',
    id: 'TNGAE',
  },
  {
    name: 'Jarjis',
    coordinates: [-11.1, 33.5],
    city: 'Jarjis',
    country: 'Tunisia',
    alias: [],
    regions: [],
    unlocs: ['TNJAR'],
    id: 'TNJAR',
  },
  {
    name: 'La Goulette Nord (Halqueloued)',
    city: 'La Goulette Nord (Halqueloued)',
    country: 'Tunisia',
    alias: [],
    regions: [],
    coordinates: [10.3020843, 36.8200203],
    province: 'Tunis',
    timezone: 'Africa/Tunis',
    unlocs: ['TNLGN'],
    id: 'TNLGN',
  },
  {
    name: 'Rades',
    coordinates: [10.27, 36.77],
    city: 'Radès',
    province: 'Tunis',
    country: 'Tunisia',
    alias: [],
    regions: [],
    timezone: 'Africa/Tunis',
    unlocs: ['TNRDS'],
    id: 'TNRDS',
  },
  {
    name: 'Sfax',
    city: 'Sfax',
    country: 'Tunisia',
    alias: [],
    regions: [],
    coordinates: [10.766163, 34.7478469],
    province: 'Sfax',
    timezone: 'Africa/Tunis',
    unlocs: ['TNSFA'],
    code: '72337',
    id: 'TNSFA',
  },
  {
    name: 'Sousse',
    city: 'Sousse',
    country: 'Tunisia',
    alias: [],
    regions: [],
    coordinates: [10.608395, 35.825603],
    province: 'Sousse',
    timezone: 'Africa/Tunis',
    unlocs: ['TNSUS'],
    id: 'TNSUS',
  },
  {
    name: 'Tunis',
    city: 'Tunis',
    country: 'Tunisia',
    alias: [],
    regions: [],
    coordinates: [10.1815316, 36.8064948],
    province: 'Tunis',
    timezone: 'Africa/Tunis',
    unlocs: ['TNTUN'],
    code: '72373',
    id: 'TNTUN',
  },
  {
    name: "Nuku'alofa",
    coordinates: [-175.2, -21.13],
    city: "Nuku'alofa",
    country: 'Tonga',
    alias: [],
    regions: [],
    province: 'Tongatapu',
    timezone: 'Pacific/Tongatapu',
    unlocs: ['TOTBU'],
    id: 'TOTBU',
  },
  {
    name: 'Aliaga',
    city: 'Aliaga',
    province: 'Izmir',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [26.97074, 38.799561],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRALI'],
    code: '48915',
    id: 'TRALI',
  },
  {
    name: 'Ambarli',
    coordinates: [41.68, 41.32],
    city: 'Ambarli',
    province: 'Artvin',
    country: 'Turkey',
    alias: [],
    regions: [],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRAMB'],
    code: '48945',
    id: 'TRAMB',
  },
  {
    name: 'Avcilar',
    coordinates: [28.7, 40.97],
    city: 'Avcilar',
    province: 'Istanbul',
    country: 'Turkey',
    alias: [],
    regions: [],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRAVC'],
    id: 'TRAVC',
  },
  {
    name: 'Antalya',
    city: 'Antalya',
    province: 'Antalya',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [30.7133233, 36.8968908],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRAYT'],
    code: '48903',
    id: 'TRAYT',
  },
  {
    name: 'Bayrampasa',
    city: 'Bayrampasa',
    province: 'Istanbul',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [28.90045529999999, 41.0481503],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRBAY'],
    id: 'TRBAY',
  },
  {
    name: 'Bandirma',
    coordinates: [27.97, 40.35],
    city: 'Bandirma',
    province: 'Balikesir',
    country: 'Turkey',
    alias: [],
    regions: [],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRBDM'],
    code: '48963',
    id: 'TRBDM',
  },
  {
    name: 'Evyap Port /Kocaeli',
    coordinates: [29.7, 40.77],
    city: 'Evyap Port /Kocaeli',
    province: 'Kocaeli',
    country: 'Turkey',
    alias: [],
    regions: [],
    timezone: 'Europe/Istanbul',
    unlocs: ['TREYP'],
    id: 'TREYP',
  },
  {
    name: 'Gebze',
    coordinates: [29.42, 40.78],
    city: 'Gebze',
    province: 'Kocaeli',
    country: 'Turkey',
    alias: [],
    regions: [],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRGEB'],
    code: '48944',
    id: 'TRGEB',
  },
  {
    name: 'Gemlik',
    city: 'Gemlik',
    province: 'Bursa',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [29.155416, 40.43181999999999],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRGEM'],
    code: '48947',
    id: 'TRGEM',
  },
  {
    name: 'Haydarpasa',
    city: 'Haydarpasa',
    province: 'Istanbul',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [29.011395, 41.0046297],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRHAY'],
    code: '48999',
    id: 'TRHAY',
  },
  {
    name: 'Hereke',
    city: 'Hereke',
    province: 'Kocaeli',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [29.626024, 40.793768],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRHER'],
    id: 'TRHER',
  },
  {
    name: 'Iskenderun',
    city: 'Iskenderun',
    province: 'Hatay',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [36.1756161, 36.584673],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRISK'],
    code: '48931',
    id: 'TRISK',
  },
  {
    name: 'Istanbul',
    city: 'Istanbul',
    province: 'Istanbul',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [28.9783589, 41.0082376],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRIST'],
    code: '48945',
    id: 'TRIST',
  },
  {
    name: 'Izmir',
    coordinates: [27.15, 38.42],
    city: 'Izmir',
    province: 'Izmir',
    country: 'Turkey',
    alias: [],
    regions: [],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRIZM'],
    code: '48939',
    id: 'TRIZM',
  },
  {
    name: 'Izmit',
    coordinates: [29.95, 40.78],
    city: 'Izmit',
    province: 'Kocaeli',
    country: 'Turkey',
    alias: [],
    regions: [],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRIZT'],
    code: '48940',
    id: 'TRIZT',
  },
  {
    name: 'Kumport',
    coordinates: [28.82, 40.95],
    city: 'Kumport',
    province: 'Istanbul',
    country: 'Turkey',
    alias: [],
    regions: [],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRKMX'],
    id: 'TRKMX',
  },
  {
    name: 'Mardas',
    coordinates: [28.95, 41.02],
    city: 'Mardas',
    province: 'Istanbul',
    country: 'Turkey',
    alias: [],
    regions: [],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRMAD'],
    id: 'TRMAD',
  },
  {
    name: 'Marmara Ereglisi',
    city: 'Marmara Ereglisi',
    province: 'Tekirdag',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [27.9014654, 41.041446],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRMAR'],
    id: 'TRMAR',
  },
  {
    name: 'Mersin',
    coordinates: [34.63, 36.72],
    city: 'Mersin',
    province: 'Mersin',
    country: 'Turkey',
    alias: [],
    regions: [],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRMER'],
    code: '48938',
    id: 'TRMER',
  },
  {
    name: 'Nemrut Bay',
    city: 'Nemrut Bay',
    province: 'Izmir',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [27.142826, 38.423734],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRNEM'],
    code: '48915',
    id: 'TRNEM',
  },
  {
    name: 'Samsun',
    city: 'Samsun',
    province: 'Samsun',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [36.3360667, 41.2797031],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRSSX'],
    code: '48967',
    id: 'TRSSX',
  },
  {
    name: 'Tekirdag',
    city: 'Tekirdag',
    province: 'Tekirdag',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [27.511674, 40.9780919],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRTEK'],
    code: '48999',
    id: 'TRTEK',
  },
  {
    name: 'Trabzon',
    city: 'Trabzon',
    province: 'Trabzon',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [39.7167633, 41.0026969],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRTZX'],
    code: '48999',
    id: 'TRTZX',
  },
  {
    name: 'Yarimca',
    city: 'Yarimca',
    province: 'Kocaeli',
    country: 'Turkey',
    alias: [],
    regions: [],
    coordinates: [29.727171, 40.771592],
    timezone: 'Europe/Istanbul',
    unlocs: ['TRYAR'],
    code: '48999',
    id: 'TRYAR',
  },
  {
    name: 'Port-of-Spain',
    coordinates: [-61.52, 10.65],
    city: 'Port-of-Spain',
    province: 'Port of Spain',
    country: 'Trinidad and Tobago',
    alias: [],
    regions: [],
    timezone: 'America/Port_of_Spain',
    unlocs: ['TTPOS'],
    code: '27431',
    id: 'TTPOS',
  },
  {
    name: 'Pointe a Pierre',
    city: 'Pointe a Pierre',
    country: 'Trinidad and Tobago',
    alias: [],
    regions: [],
    coordinates: [-61.45959930000001, 10.3155401],
    province: 'Couva-Tabaquite-Talparo',
    timezone: 'America/Port_of_Spain',
    unlocs: ['TTPTP'],
    code: '27415',
    id: 'TTPTP',
  },
  {
    name: 'Point Lisas',
    city: 'Point Lisas',
    country: 'Trinidad and Tobago',
    alias: [],
    regions: [],
    coordinates: [-61.4630653, 10.4056994],
    province: 'Couva-Tabaquite-Talparo',
    timezone: 'America/Port_of_Spain',
    unlocs: ['TTPTS'],
    code: '27425',
    id: 'TTPTS',
  },
  {
    name: 'San Fernando',
    city: 'San Fernando',
    country: 'Trinidad and Tobago',
    alias: [],
    regions: [],
    coordinates: [-61.4493842, 10.2905853],
    province: 'San Fernando',
    timezone: 'America/Port_of_Spain',
    unlocs: ['TTSFE'],
    code: '27443',
    id: 'TTSFE',
  },
  {
    name: 'Keelung (Chilung)',
    city: 'Keelung (Chilung)',
    country: 'Taiwan',
    alias: [],
    regions: [],
    coordinates: [121.75, 25.1333333],
    timezone: 'Asia/Taipei',
    unlocs: ['TWKEL'],
    code: '58301',
    id: 'TWKEL',
  },
  {
    name: 'Kaohsiung',
    city: 'Kaohsiung',
    country: 'Taiwan',
    alias: [],
    regions: [],
    coordinates: [120.3014353, 22.6272784],
    province: 'Kaohsiung City',
    timezone: 'Asia/Taipei',
    unlocs: ['TWKHH'],
    code: '58309',
    id: 'TWKHH',
  },
  {
    name: 'Mai-liao',
    coordinates: [120.13, 23.1],
    city: 'Mai-liao',
    country: 'Taiwan',
    alias: [],
    regions: [],
    timezone: 'Asia/Taipei',
    unlocs: ['TWMLI'],
    id: 'TWMLI',
  },
  {
    name: 'Suao',
    city: 'Suao',
    country: 'Taiwan',
    alias: [],
    regions: [],
    coordinates: [121.8533264, 24.594229],
    timezone: 'Asia/Taipei',
    unlocs: ['TWSUO'],
    code: '58302',
    id: 'TWSUO',
  },
  {
    country: 'Taiwan',
    province: 'Taiwan',
    city: 'Taipei',
    name: 'Taipei',
    alias: [],
    regions: [],
    unlocs: ['TWTPE'],
    code: '58304',
    id: 'TWTPE',
  },
  {
    name: 'Taichung',
    city: 'Taichung',
    country: 'Taiwan',
    alias: [],
    regions: [],
    coordinates: [120.6736482, 24.1477358],
    province: 'Taichung City',
    timezone: 'Asia/Taipei',
    unlocs: ['TWTXG'],
    code: '58303',
    id: 'TWTXG',
  },
  {
    name: 'Taoyuan',
    city: 'Taoyuan',
    country: 'Taiwan',
    alias: [],
    regions: [],
    coordinates: [121.3009798, 24.9936281],
    province: 'Taoyuan City',
    timezone: 'Asia/Taipei',
    unlocs: ['TWTYN'],
    id: 'TWTYN',
  },
  {
    name: 'Dar es Salaam',
    coordinates: [39.28, -6.8],
    city: 'Dar es Salaam',
    country: 'Tanzania, United Republic of',
    alias: [],
    regions: [],
    province: 'Dar es Salaam',
    timezone: 'Africa/Dar_es_Salaam',
    unlocs: ['TZDAR'],
    code: '78351',
    id: 'TZDAR',
  },
  {
    name: 'Tanga',
    city: 'Tanga',
    country: 'Tanzania, United Republic of',
    alias: [],
    regions: [],
    coordinates: [39.1023228, -5.0888751],
    province: 'Tanga',
    timezone: 'Africa/Dar_es_Salaam',
    unlocs: ['TZTGT'],
    id: 'TZTGT',
  },
  {
    name: 'Zanzibar',
    city: 'Zanzibar',
    country: 'Tanzania, United Republic of',
    alias: [],
    regions: [],
    coordinates: [39.202641, -6.165916999999999],
    province: 'Mjini Magharibi',
    timezone: 'Africa/Dar_es_Salaam',
    unlocs: ['TZZNZ'],
    id: 'TZZNZ',
  },
  {
    name: 'Kiev',
    city: 'Kiev',
    country: 'Ukraine',
    alias: [],
    regions: [],
    coordinates: [30.5234, 50.4501],
    province: 'Kyiv city',
    timezone: 'Europe/Kiev',
    unlocs: ['UAIEV'],
    id: 'UAIEV',
  },
  {
    name: "Illichivs'k",
    coordinates: [30.67, 46.3],
    city: "Illichivs'k",
    province: 'Odeska oblast',
    country: 'Ukraine',
    alias: [],
    regions: [],
    timezone: 'Europe/Kiev',
    unlocs: ['UAILK'],
    id: 'UAILK',
  },
  {
    name: 'Kherson',
    city: 'Kherson',
    country: 'Ukraine',
    alias: [],
    regions: [],
    coordinates: [32.616867, 46.635417],
    province: "Khersons'ka oblast",
    timezone: 'Europe/Kiev',
    unlocs: ['UAKHE'],
    id: 'UAKHE',
  },
  {
    name: 'Mariupol (ex Zhdanov)',
    coordinates: [37.55, 47.1],
    city: 'Mariupol (ex Zhdanov)',
    province: 'Donetska oblast',
    country: 'Ukraine',
    alias: [],
    regions: [],
    timezone: 'Europe/Kiev',
    unlocs: ['UAMPW'],
    code: '46276',
    id: 'UAMPW',
  },
  {
    name: 'Odessa',
    city: 'Odessa',
    country: 'Ukraine',
    alias: [],
    regions: [],
    coordinates: [30.7233095, 46.482526],
    province: 'Odessa Oblast',
    timezone: 'Europe/Kiev',
    unlocs: ['UAODS'],
    code: '46275',
    id: 'UAODS',
  },
  {
    name: 'Sevastopol',
    coordinates: [33.53, 44.6],
    city: 'Sevastopol',
    province: 'Sevastopol',
    country: 'Ukraine',
    alias: [],
    regions: [],
    timezone: 'Europe/Simferopol',
    unlocs: ['UASVP'],
    code: '46279',
    id: 'UASVP',
  },
  {
    name: 'Jinja',
    city: 'Jinja',
    country: 'Uganda',
    alias: [],
    regions: [],
    coordinates: [33.2026122, 0.4478565999999999],
    province: 'Eastern Region',
    timezone: 'Africa/Kampala',
    unlocs: ['UGJIN'],
    id: 'UGJIN',
  },
  {
    name: 'Kampala',
    city: 'Kampala',
    country: 'Uganda',
    alias: [],
    regions: [],
    coordinates: [32.5825197, 0.3475964],
    province: 'Central Region',
    timezone: 'Africa/Kampala',
    unlocs: ['UGKLA'],
    id: 'UGKLA',
  },
  {
    name: 'Kodiak',
    city: 'Kodiak',
    province: 'Alaska',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-152.4072222, 57.79000000000001],
    timezone: 'America/Anchorage',
    unlocs: ['USADQ'],
    code: '3127',
    id: 'USADQ',
  },
  {
    name: 'Anchorage',
    city: 'Anchorage',
    province: 'Alaska',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-149.9002778, 61.2180556],
    timezone: 'America/Anchorage',
    unlocs: ['USANC'],
    code: '3126',
    id: 'USANC',
  },
  {
    name: 'Annapolis',
    city: 'Annapolis',
    province: 'Maryland',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-76.4921829, 38.9784453],
    timezone: 'America/New_York',
    unlocs: ['USANP'],
    code: '1301',
    id: 'USANP',
  },
  {
    timezone: 'America/Chicago',
    coordinates: [-92.4096005, 35.367302],
    name: 'Damascus',
    city: 'Damascus',
    province: 'Arkansas',
    country: 'United States',
    alias: [],
    regions: [],
    unlocs: ['USAR5'],
    id: 'USAR5',
  },
  {
    name: 'Atlanta',
    city: 'Atlanta',
    province: 'Georgia',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-84.3879824, 33.7489954],
    timezone: 'America/New_York',
    unlocs: ['USATL'],
    code: '1704',
    id: 'USATL',
  },
  {
    name: 'Baltimore',
    coordinates: [-76.62, 39.28],
    city: 'Baltimore',
    province: 'Maryland',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USBAL'],
    code: '1303',
    id: 'USBAL',
  },
  {
    name: 'Bear Creek',
    city: 'Bear Creek',
    province: 'Alaska',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-149.395, 60.1641667],
    timezone: 'America/Anchorage',
    unlocs: ['USBCC'],
    id: 'USBCC',
  },
  {
    country: 'United States',
    province: 'Alabama',
    city: 'Birmingham',
    code: '1904',
    name: 'Birmingham',
    alias: [],
    regions: [],
    unlocs: ['USBHM'],
    id: 'USBHM',
  },
  {
    name: 'Nashville',
    city: 'Nashville',
    province: 'Tennessee',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-86.7816016, 36.1626638],
    timezone: 'America/Chicago',
    unlocs: ['USBNA'],
    code: '2007',
    id: 'USBNA',
  },
  {
    name: 'Boston',
    city: 'Boston',
    province: 'Massachusetts',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-71.0588801, 42.3600825],
    timezone: 'America/New_York',
    unlocs: ['USBOS'],
    code: '0401',
    id: 'USBOS',
  },
  {
    name: 'Beaumont',
    city: 'Beaumont',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-94.1265562, 30.080174],
    timezone: 'America/Chicago',
    unlocs: ['USBPT'],
    code: '2104',
    id: 'USBPT',
  },
  {
    name: 'Brownsville',
    coordinates: [-97.48, 25.9],
    city: 'Brownsville',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Chicago',
    unlocs: ['USBRO'],
    code: '2301',
    id: 'USBRO',
  },
  {
    name: 'Buffalo',
    city: 'Buffalo',
    province: 'New York',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-78.8783689, 42.88644679999999],
    timezone: 'America/New_York',
    unlocs: ['USBUF'],
    id: 'USBUF',
  },
  {
    country: 'United States',
    province: 'Iowa',
    city: 'Council Bluffs',
    name: 'Council Bluffs',
    alias: [],
    regions: [],
    unlocs: ['USCBF'],
    id: 'USCBF',
  },
  {
    name: 'Chattanooga',
    coordinates: [-85.3, 35.03],
    city: 'Chattanooga',
    province: 'Tennessee',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USCHA'],
    code: '2008',
    id: 'USCHA',
  },
  {
    name: 'Chicago',
    city: 'Chicago',
    province: 'Illinois',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-87.6297982, 41.8781136],
    timezone: 'America/Chicago',
    unlocs: ['USCHI'],
    code: '3901',
    id: 'USCHI',
  },
  {
    name: 'Charleston',
    city: 'Charleston',
    province: 'South Carolina',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-79.93105120000001, 32.7764749],
    timezone: 'America/New_York',
    unlocs: ['USCHS'],
    code: '1601',
    id: 'USCHS',
  },
  {
    name: 'Cleveland',
    coordinates: [-81.67, 41.5],
    city: 'Cleveland',
    province: 'Ohio',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USCLE'],
    id: 'USCLE',
  },
  {
    name: 'Port Angeles',
    city: 'Port Angeles',
    province: 'Washington',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-123.4307413, 48.118146],
    timezone: 'America/Los_Angeles',
    unlocs: ['USCLM'],
    code: '3007',
    id: 'USCLM',
  },
  {
    name: 'Charlotte',
    city: 'Charlotte',
    province: 'North Carolina',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-80.8431267, 35.2270869],
    timezone: 'America/New_York',
    unlocs: ['USCLT'],
    code: '1512',
    id: 'USCLT',
  },
  {
    name: 'Columbus',
    city: 'Columbus',
    province: 'Ohio',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-82.99879419999999, 39.9611755],
    timezone: 'America/New_York',
    unlocs: ['USCMH'],
    id: 'USCMH',
  },
  {
    country: 'United States',
    province: 'Wisconsin',
    city: 'Chippewa Falls',
    name: 'Chippewa Falls',
    alias: [],
    regions: [],
    unlocs: ['USCPF'],
    id: 'USCPF',
  },
  {
    name: 'Cape Canaveral',
    city: 'Cape Canaveral',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-80.60771319999999, 28.3922182],
    timezone: 'America/New_York',
    unlocs: ['USCPV'],
    id: 'USCPV',
  },
  {
    name: 'Corpus Christi',
    city: 'Corpus Christi',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-97.39638099999999, 27.8005828],
    timezone: 'America/Chicago',
    unlocs: ['USCRP'],
    code: '5312',
    id: 'USCRP',
  },
  {
    name: 'Cincinnati',
    city: 'Cincinnati',
    province: 'Ohio',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-84.5120196, 39.1031182],
    timezone: 'America/New_York',
    unlocs: ['USCVG'],
    id: 'USCVG',
  },
  {
    name: 'Cape Romanzof',
    city: 'Cape Romanzof',
    province: 'Alaska',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-166.0977778, 61.8122222],
    timezone: 'America/Anchorage',
    unlocs: ['USCZF'],
    id: 'USCZF',
  },
  {
    timezone: 'America/New_York',
    coordinates: [-76.9310859, 40.3673093],
    name: 'Dauphin',
    city: 'Dauphin',
    province: 'Pennsylvania',
    country: 'United States',
    alias: [],
    regions: [],
    unlocs: ['USDA8'],
    id: 'USDA8',
  },
  {
    name: 'Dallas',
    city: 'Dallas',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-96.79698789999999, 32.7766642],
    timezone: 'America/Chicago',
    unlocs: ['USDAL'],
    id: 'USDAL',
  },
  {
    name: 'Decatur',
    city: 'Decatur',
    province: 'Illinois',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-88.9548001, 39.8403147],
    timezone: 'America/Chicago',
    unlocs: ['USDEC'],
    id: 'USDEC',
  },
  {
    name: 'Denver',
    city: 'Denver',
    province: 'Colorado',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-104.990251, 39.7392358],
    timezone: 'America/Denver',
    unlocs: ['USDEN'],
    code: '3307',
    id: 'USDEN',
  },
  {
    name: 'Detroit',
    coordinates: [-83.03, 42.32],
    city: 'Detroit',
    province: 'Michigan',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Toronto',
    unlocs: ['USDET'],
    code: '3801',
    id: 'USDET',
  },
  {
    name: 'Dalton',
    city: 'Dalton',
    province: 'Georgia',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-84.9702228, 34.7698021],
    timezone: 'America/New_York',
    unlocs: ['USDNN'],
    id: 'USDNN',
  },
  {
    name: 'Des Moines',
    city: 'Des Moines',
    province: 'Iowa',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-93.6091064, 41.6005448],
    timezone: 'America/Chicago',
    unlocs: ['USDSM'],
    code: '3513',
    id: 'USDSM',
  },
  {
    name: 'Dutch Harbor',
    city: 'Dutch Harbor',
    province: 'Alaska',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-166.5422, 53.88980000000001],
    timezone: 'America/Anchorage',
    unlocs: ['USDUT'],
    id: 'USDUT',
  },
  {
    name: 'El Paso',
    coordinates: [-106.48, 31.75],
    city: 'El Paso',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Denver',
    unlocs: ['USELP'],
    code: '2402',
    id: 'USELP',
  },
  {
    name: 'Kenai',
    city: 'Kenai',
    province: 'Alaska',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-151.2583333, 60.55444439999999],
    timezone: 'America/Anchorage',
    unlocs: ['USENA'],
    id: 'USENA',
  },
  {
    name: 'Newark',
    coordinates: [-74.17, 40.73],
    city: 'Newark',
    province: 'New Jersey',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USEWR'],
    id: 'USEWR',
  },
  {
    name: 'Fernandina Beach',
    coordinates: [-81.45, 30.67],
    city: 'Fernandina Beach',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USFEB'],
    code: '1805',
    id: 'USFEB',
  },
  {
    name: 'Fort Lauderdale',
    coordinates: [-80.13, 26.12],
    city: 'Fort Lauderdale',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USFLL'],
    id: 'USFLL',
  },
  {
    name: 'Freeport',
    city: 'Freeport',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-95.35966169999999, 28.9541368],
    timezone: 'America/Chicago',
    unlocs: ['USFPO'],
    code: '5311',
    id: 'USFPO',
  },
  {
    name: 'Fresh Water Bay',
    city: 'Fresh Water Bay',
    province: 'Alaska',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-135.0160367, 57.87419879999999],
    timezone: 'America/Anchorage',
    unlocs: ['USFRP'],
    id: 'USFRP',
  },
  {
    name: 'Fort Worth',
    city: 'Fort Worth',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-97.3307658, 32.7554883],
    timezone: 'America/Chicago',
    unlocs: ['USFWT'],
    id: 'USFWT',
  },
  {
    city: 'Greensboro',
    province: 'North Carolina',
    country: 'United States',
    name: 'Greensboro',
    alias: [],
    regions: [],
    unlocs: ['USGBO'],
    id: 'USGBO',
  },
  {
    name: 'Galveston',
    city: 'Galveston',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-94.7976958, 29.3013479],
    timezone: 'America/Chicago',
    unlocs: ['USGLS'],
    code: '5310',
    id: 'USGLS',
  },
  {
    name: 'Gulfport',
    city: 'Gulfport',
    province: 'Mississippi',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-89.0928155, 30.3674198],
    timezone: 'America/Chicago',
    unlocs: ['USGPT'],
    code: '1902',
    id: 'USGPT',
  },
  {
    country: 'United States',
    province: 'South Carolina',
    city: 'Greer',
    name: 'Greer',
    alias: [],
    regions: [],
    unlocs: ['USGSP'],
    id: 'USGSP',
  },
  {
    name: 'Honolulu',
    coordinates: [-157.85, 21.3],
    city: 'Honolulu',
    province: 'Hawaii',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'Pacific/Honolulu',
    unlocs: ['USHNL'],
    code: '3201',
    id: 'USHNL',
  },
  {
    name: 'Houston',
    coordinates: [-95.35, 29.75],
    city: 'Houston',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Chicago',
    unlocs: ['USHOU'],
    code: '5301',
    id: 'USHOU',
  },
  {
    name: 'Huntsville',
    city: 'Huntsville',
    province: 'Alabama',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-86.5861037, 34.7303688],
    timezone: 'America/Chicago',
    unlocs: ['USHSV'],
    code: '1910',
    id: 'USHSV',
  },
  {
    name: 'Wilmington',
    coordinates: [-77.93, 34.22],
    city: 'Wilmington',
    province: 'North Carolina',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USILM'],
    code: '1501',
    id: 'USILM',
  },
  {
    name: 'Indianapolis',
    coordinates: [-86.15, 39.77],
    city: 'Indianapolis',
    province: 'Indiana',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USIND'],
    code: '4110',
    id: 'USIND',
  },
  {
    timezone: 'America/Los_Angeles',
    coordinates: [-122.9669865, 42.31345839999999],
    name: 'Jacksonville',
    city: 'Jacksonville',
    province: 'Oregon',
    country: 'United States',
    alias: [],
    regions: [],
    unlocs: ['USJ3C'],
    id: 'USJ3C',
  },
  {
    name: 'Jacksonville',
    city: 'Jacksonville',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-81.65565099999999, 30.3321838],
    timezone: 'America/New_York',
    unlocs: ['USJAX'],
    code: '1803',
    id: 'USJAX',
  },
  {
    country: 'United States',
    province: 'Illinois',
    city: 'Joliet',
    name: 'Joliet',
    alias: [],
    regions: [],
    unlocs: ['USJOT'],
    id: 'USJOT',
  },
  {
    name: 'Kansas City',
    city: 'Kansas City',
    province: 'Kansas',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-94.6274636, 39.114053],
    timezone: 'America/Chicago',
    unlocs: ['USKCK'],
    id: 'USKCK',
  },
  {
    name: 'Las Vegas',
    coordinates: [-115.15, 36.07],
    city: 'Las Vegas',
    province: 'Nevada',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Los_Angeles',
    unlocs: ['USLAS'],
    code: '2722',
    id: 'USLAS',
  },
  {
    timezone: 'America/Los_Angeles',
    coordinates: [-118.0725641, 33.8030716],
    name: 'Los Alamitos',
    city: 'Los Alamitos',
    province: 'California',
    country: 'United States',
    alias: [],
    regions: [],
    unlocs: ['USLAT'],
    id: 'USLAT',
  },
  {
    name: 'Los Angeles',
    city: 'Los Angeles',
    province: 'California',
    country: 'United States',
    alias: ['Long Beach'],
    regions: [],
    coordinates: [-118.2436849, 34.0522342],
    timezone: 'America/Los_Angeles',
    unlocs: ['USLAX'],
    code: '2704',
    id: 'USLAX',
  },
  {
    name: 'Lake Charles',
    city: 'Lake Charles',
    province: 'Louisiana',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-93.2173758, 30.2265949],
    timezone: 'America/Chicago',
    unlocs: ['USLCH'],
    code: '2017',
    id: 'USLCH',
  },
  {
    name: 'Long Beach',
    coordinates: [-118.18, 33.77],
    city: 'Long Beach',
    province: 'California',
    country: 'United States',
    alias: ['Los Angeles', 'LAX', 'Long Beach'],
    regions: [],
    timezone: 'America/Los_Angeles',
    code: '2709',
    unlocs: ['USLGB'],
    id: 'USLGB',
  },
  {
    name: 'Laredo',
    coordinates: [-99.48, 27.5],
    city: 'Laredo',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Chicago',
    unlocs: ['USLRD'],
    code: '2304',
    id: 'USLRD',
  },
  {
    name: 'Louisville',
    coordinates: [-85.77, 38.23],
    city: 'Louisville',
    province: 'Kentucky',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USLUI'],
    id: 'USLUI',
  },
  {
    name: 'Memphis',
    city: 'Memphis',
    province: 'Tennessee',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-90.0489801, 35.1495343],
    timezone: 'America/Chicago',
    unlocs: ['USMEM'],
    code: '2006',
    id: 'USMEM',
  },
  {
    name: 'Minneapolis',
    city: 'Minneapolis',
    province: 'Minnesota',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-93.2650108, 44.977753],
    timezone: 'America/Chicago',
    unlocs: ['USMES'],
    id: 'USMES',
  },
  {
    name: 'Miami',
    city: 'Miami',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-80.1917902, 25.7616798],
    timezone: 'America/New_York',
    unlocs: ['USMIA'],
    code: '5201',
    id: 'USMIA',
  },
  {
    name: 'Kansas City',
    coordinates: [-94.57, 39.1],
    city: 'Kansas City',
    province: 'Missouri',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Chicago',
    unlocs: ['USMKC'],
    id: 'USMKC',
  },
  {
    name: 'Milwaukee',
    city: 'Milwaukee',
    province: 'Wisconsin',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-87.9064736, 43.0389025],
    timezone: 'America/Chicago',
    unlocs: ['USMKE'],
    code: '3701',
    id: 'USMKE',
  },
  {
    name: 'Mobile',
    city: 'Mobile',
    province: 'Alabama',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-88.0398912, 30.6953657],
    timezone: 'America/Chicago',
    unlocs: ['USMOB'],
    code: '1901',
    id: 'USMOB',
  },
  {
    name: 'Morehead City',
    city: 'Morehead City',
    province: 'North Carolina',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-76.7260436, 34.7229391],
    timezone: 'America/New_York',
    unlocs: ['USMRH'],
    id: 'USMRH',
  },
  {
    name: 'Minneapolis/St Paul Apt',
    coordinates: [-93.2, 44.88],
    city: 'Minneapolis/St Paul Apt',
    province: 'Minnesota',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Chicago',
    unlocs: ['USMSP'],
    id: 'USMSP',
  },
  {
    name: 'New Orleans',
    city: 'New Orleans',
    province: 'Louisiana',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-90.0715323, 29.95106579999999],
    timezone: 'America/Chicago',
    unlocs: ['USMSY'],
    code: '2002',
    id: 'USMSY',
  },
  {
    name: 'Newport News',
    coordinates: [-76.42, 36.97],
    city: 'Newport News',
    province: 'Virginia',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USNNS'],
    id: 'USNNS',
  },
  {
    name: 'Port Hueneme',
    coordinates: [-119.18, 34.13],
    city: 'Port Hueneme',
    province: 'California',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Los_Angeles',
    unlocs: ['USNTD'],
    code: '2713',
    id: 'USNTD',
  },
  {
    name: 'North Charleston',
    city: 'North Charleston',
    province: 'South Carolina',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-79.9748103, 32.8546197],
    timezone: 'America/New_York',
    unlocs: ['USNTS'],
    id: 'USNTS',
  },
  {
    name: 'New York',
    coordinates: [-74, 40.7],
    city: 'New York',
    province: 'New York',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USNYC'],
    code: '4601',
    id: 'USNYC',
  },
  {
    name: 'Oakland',
    city: 'Oakland',
    province: 'California',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-122.2711137, 37.8043637],
    timezone: 'America/Chicago',
    unlocs: ['USOAK'],
    code: '2811',
    id: 'USOAK',
  },
  {
    name: 'Oceanside',
    city: 'Oceanside',
    province: 'California',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-117.3794834, 33.1958696],
    timezone: 'America/Los_Angeles',
    unlocs: ['USOCN'],
    id: 'USOCN',
  },
  {
    name: 'Washington',
    city: 'Washington',
    province: 'North Carolina',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-77.0521742, 35.54655169999999],
    timezone: 'America/New_York',
    unlocs: ['USOCW'],
    id: 'USOCW',
  },
  {
    name: 'Olympia',
    city: 'Olympia',
    province: 'Washington',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-122.9006951, 47.0378741],
    timezone: 'America/Los_Angeles',
    unlocs: ['USOLM'],
    code: '3026',
    id: 'USOLM',
  },
  {
    name: 'Omaha',
    city: 'Omaha',
    province: 'Nebraska',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-95.99798829999999, 41.2523634],
    timezone: 'America/Chicago',
    unlocs: ['USOMA'],
    code: '3512',
    id: 'USOMA',
  },
  {
    name: 'Norfolk',
    city: 'Norfolk',
    province: 'Virginia',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-76.28587259999999, 36.8507689],
    timezone: 'America/New_York',
    unlocs: ['USORF'],
    code: '1401',
    id: 'USORF',
  },
  {
    name: 'Orlando',
    coordinates: [-81.37, 28.53],
    city: 'Orlando',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USORL'],
    code: '1808',
    id: 'USORL',
  },
  {
    name: 'Otay Mesa',
    coordinates: [-117.02, 32.57],
    city: 'Otay Mesa',
    province: 'California',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Los_Angeles',
    unlocs: ['USOYM'],
    code: '2506',
    id: 'USOYM',
  },
  {
    name: 'Palm Beach',
    city: 'Palm Beach',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-80.0364297, 26.7056206],
    timezone: 'America/New_York',
    unlocs: ['USPAB'],
    id: 'USPAB',
  },
  {
    name: 'Portage Creek',
    city: 'Portage Creek',
    province: 'Alaska',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-157.7175, 58.9061111],
    timezone: 'America/Anchorage',
    unlocs: ['USPCA'],
    id: 'USPCA',
  },
  {
    name: 'Portland',
    city: 'Portland',
    province: 'Oregon',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-122.6764816, 45.5230622],
    timezone: 'America/Los_Angeles',
    unlocs: ['USPDX'],
    code: '2904',
    id: 'USPDX',
  },
  {
    name: 'Port Elizabeth',
    coordinates: [-74.97, 39.3],
    city: 'Port Elizabeth',
    province: 'New Jersey',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USPEB'],
    id: 'USPEB',
  },
  {
    name: 'Port Everglades',
    coordinates: [-80.13, 26.1],
    city: 'Port Everglades',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USPEF'],
    code: '5203',
    id: 'USPEF',
  },
  {
    name: 'Panama City',
    city: 'Panama City',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-85.6602058, 30.1588129],
    timezone: 'America/Chicago',
    unlocs: ['USPFN'],
    code: '1818',
    id: 'USPFN',
  },
  {
    name: 'Pascagoula',
    city: 'Pascagoula',
    province: 'Mississippi',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-88.5561272, 30.3657552],
    timezone: 'America/Chicago',
    unlocs: ['USPGL'],
    code: '1903',
    id: 'USPGL',
  },
  {
    name: 'Hampton-Williamsburg-Newport News Apt',
    coordinates: [-76.48, 37.12],
    city: 'Hampton-Williamsburg-Newport News Apt',
    province: 'Virginia',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USPHF'],
    id: 'USPHF',
  },
  {
    name: 'Philadelphia',
    city: 'Philadelphia',
    province: 'Pennsylvania',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-75.1652215, 39.9525839],
    timezone: 'America/New_York',
    unlocs: ['USPHL'],
    code: '1101',
    id: 'USPHL',
  },
  {
    name: 'Phoenix',
    city: 'Phoenix',
    province: 'Arizona',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-112.0740373, 33.4483771],
    timezone: 'America/Phoenix',
    unlocs: ['USPHX'],
    code: '2605',
    id: 'USPHX',
  },
  {
    name: 'Peoria',
    city: 'Peoria',
    province: 'Illinois',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-89.5889864, 40.6936488],
    timezone: 'America/Chicago',
    unlocs: ['USPIA'],
    code: '3902',
    id: 'USPIA',
  },
  {
    name: 'Pittsburgh',
    city: 'Pittsburgh',
    province: 'Pennsylvania',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-79.9958864, 40.44062479999999],
    timezone: 'America/New_York',
    unlocs: ['USPIT'],
    code: '1104',
    id: 'USPIT',
  },
  {
    name: 'Port Manatee',
    coordinates: [-82.55, 27.63],
    city: 'Port Manatee',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USPME'],
    code: '1821',
    id: 'USPME',
  },
  {
    name: 'Pensacola',
    city: 'Pensacola',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-87.2169149, 30.42130899999999],
    timezone: 'America/Chicago',
    unlocs: ['USPNS'],
    code: '1819',
    id: 'USPNS',
  },
  {
    name: 'Port Arthur',
    coordinates: [-93.92, 29.88],
    city: 'Port Arthur',
    province: 'Texas',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Chicago',
    unlocs: ['USPOA'],
    code: '2101',
    id: 'USPOA',
  },
  {
    name: 'Pennsauken',
    coordinates: [-75.05, 39.95],
    city: 'Pennsauken',
    province: 'New Jersey',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USPPS'],
    id: 'USPPS',
  },
  {
    timezone: 'America/Chicago',
    coordinates: [-89.0165019, 45.3641312],
    name: 'Pearson',
    city: 'Pearson',
    province: 'Wisconsin',
    country: 'United States',
    alias: [],
    regions: [],
    unlocs: ['USPS6'],
    id: 'USPS6',
  },
  {
    name: 'Portsmouth',
    city: 'Portsmouth',
    province: 'Virginia',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-76.2982742, 36.8354258],
    timezone: 'America/New_York',
    unlocs: ['USPTM'],
    id: 'USPTM',
  },
  {
    name: 'Providence',
    city: 'Providence',
    province: 'Rhode Island',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-71.4128343, 41.8239891],
    timezone: 'America/New_York',
    unlocs: ['USPVD'],
    code: '0502',
    id: 'USPVD',
  },
  {
    name: 'Portland',
    city: 'Portland',
    province: 'Maine',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-70.2553259, 43.66147100000001],
    timezone: 'America/New_York',
    unlocs: ['USPWM'],
    code: '0101',
    id: 'USPWM',
  },
  {
    name: 'Port Wentworth',
    city: 'Port Wentworth',
    province: 'Georgia',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-81.163168, 32.149092],
    timezone: 'America/New_York',
    unlocs: ['USPWN'],
    id: 'USPWN',
  },
  {
    name: 'Plymouth',
    city: 'Plymouth',
    province: 'Massachusetts',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-70.6672621, 41.9584457],
    timezone: 'America/New_York',
    unlocs: ['USPYM'],
    code: '0406',
    id: 'USPYM',
  },
  {
    timezone: 'America/New_York',
    coordinates: [-73.97095829999999, 42.78702089999999],
    name: 'Rotterdam',
    city: 'Rotterdam',
    province: 'New York',
    country: 'United States',
    alias: [],
    regions: [],
    unlocs: ['USRAJ'],
    id: 'USRAJ',
  },
  {
    name: 'Riviera Beach',
    city: 'Riviera Beach',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-80.0580969, 26.7753405],
    timezone: 'America/New_York',
    unlocs: ['USRBC'],
    id: 'USRBC',
  },
  {
    name: 'Richmond',
    coordinates: [-77.45, 37.55],
    city: 'Richmond',
    province: 'Virginia',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USRIC'],
    id: 'USRIC',
  },
  {
    code: '2833',
    city: 'Reno',
    province: 'Nevada',
    country: 'United States',
    name: 'Reno',
    alias: [],
    regions: [],
    unlocs: ['USRNO'],
    id: 'USRNO',
  },
  {
    name: 'Sacramento',
    coordinates: [-121.48, 38.57],
    city: 'Sacramento',
    province: 'California',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Los_Angeles',
    unlocs: ['USSAC'],
    id: 'USSAC',
  },
  {
    name: 'San Diego',
    coordinates: [-117.15, 32.7],
    city: 'San Diego',
    province: 'California',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Los_Angeles',
    unlocs: ['USSAN'],
    code: '2501',
    id: 'USSAN',
  },
  {
    country: 'United States',
    city: 'San Antonio',
    name: 'San Antonio',
    province: 'Texas',
    alias: [],
    regions: [],
    unlocs: ['USSAT'],
    id: 'USSAT',
  },
  {
    name: 'Savannah',
    city: 'Savannah',
    province: 'Georgia',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-81.09983419999999, 32.0835407],
    timezone: 'America/New_York',
    unlocs: ['USSAV'],
    code: '1703',
    id: 'USSAV',
  },
  {
    name: 'Stockton',
    coordinates: [-121.28, 37.95],
    city: 'Stockton',
    province: 'California',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Los_Angeles',
    unlocs: ['USSCK'],
    code: '2810',
    id: 'USSCK',
  },
  {
    name: 'Seattle',
    city: 'Seattle',
    province: 'Washington',
    country: 'United States',
    alias: ['Tacoma'],
    regions: [],
    coordinates: [-122.3320708, 47.6062095],
    timezone: 'America/Los_Angeles',
    unlocs: ['USSEA'],
    code: '3001',
    id: 'USSEA',
  },
  {
    name: 'San Francisco',
    city: 'San Francisco',
    province: 'California',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-122.4194155, 37.7749295],
    timezone: 'America/Los_Angeles',
    unlocs: ['USSFO'],
    code: '2809',
    id: 'USSFO',
  },
  {
    name: 'Salt Lake City',
    city: 'Salt Lake City',
    province: 'Utah',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-111.8910474, 40.7607793],
    timezone: 'America/Denver',
    unlocs: ['USSLC'],
    code: '3303',
    id: 'USSLC',
  },
  {
    name: 'South Saint Paul',
    coordinates: [-93.05, 44.88],
    city: 'South Saint Paul',
    province: 'Minnesota',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Chicago',
    unlocs: ['USSPT'],
    id: 'USSPT',
  },
  {
    name: 'Brunswick',
    city: 'Brunswick',
    province: 'Georgia',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-81.49148939999999, 31.1499528],
    timezone: 'America/New_York',
    unlocs: ['USSSI'],
    code: '1701',
    id: 'USSSI',
  },
  {
    name: 'Saint Louis',
    city: 'Saint Louis',
    province: 'Missouri',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-90.19940419999999, 38.6270025],
    timezone: 'America/Chicago',
    unlocs: ['USSTL'],
    id: 'USSTL',
  },
  {
    name: 'Saint Paul',
    city: 'Saint Paul',
    province: 'Minnesota',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-93.0899578, 44.9537029],
    timezone: 'America/Chicago',
    unlocs: ['USSTP'],
    id: 'USSTP',
  },
  {
    name: 'Sturgeon Bay',
    city: 'Sturgeon Bay',
    province: 'Wisconsin',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-87.3770419, 44.8341639],
    timezone: 'America/Chicago',
    unlocs: ['USSUE'],
    id: 'USSUE',
  },
  {
    name: 'Superior',
    city: 'Superior',
    province: 'Wisconsin',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-92.10407959999999, 46.7207737],
    timezone: 'America/Chicago',
    unlocs: ['USSUW'],
    id: 'USSUW',
  },
  {
    name: 'Newburgh',
    city: 'Newburgh',
    province: 'New York',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-74.0104178, 41.5034271],
    timezone: 'America/New_York',
    unlocs: ['USSWF'],
    id: 'USSWF',
  },
  {
    country: 'United States',
    province: 'New Mexico',
    city: 'Santa Teresa',
    code: '2408',
    name: 'Santa Teresa',
    alias: [],
    regions: [],
    unlocs: ['USSXT'],
    id: 'USSXT',
  },
  {
    name: 'Swartz Creek',
    coordinates: [-83.82, 42.95],
    city: 'Swartz Creek',
    province: 'Michigan',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USSXZ'],
    id: 'USSXZ',
  },
  {
    name: 'Tacoma',
    city: 'Tacoma',
    province: 'Washington',
    country: 'United States',
    alias: ['Seattle'],
    regions: [],
    coordinates: [-122.4442906, 47.2528768],
    timezone: 'America/Los_Angeles',
    unlocs: ['USTIW'],
    code: '3002',
    id: 'USTIW',
  },
  {
    timezone: 'America/Chicago',
    coordinates: [-89.2700811, 37.23505],
    name: 'Tamms',
    city: 'Tamms',
    province: 'Illinois',
    country: 'United States',
    alias: [],
    regions: [],
    unlocs: ['USTMM'],
    id: 'USTMM',
  },
  {
    name: 'Toledo',
    city: 'Toledo',
    province: 'Ohio',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-83.55521200000001, 41.6639383],
    timezone: 'America/New_York',
    unlocs: ['USTOL'],
    id: 'USTOL',
  },
  {
    name: 'Tampa',
    city: 'Tampa',
    province: 'Florida',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-82.4571776, 27.950575],
    timezone: 'America/New_York',
    unlocs: ['USTPA'],
    code: '1801',
    id: 'USTPA',
  },
  {
    timezone: 'America/Chicago',
    coordinates: [-88.2492037, 35.2248034],
    name: 'Savannah',
    city: 'Savannah',
    province: 'Tennessee',
    country: 'United States',
    alias: [],
    regions: [],
    unlocs: ['USTSA'],
    id: 'USTSA',
  },
  {
    name: 'Tulsa',
    city: 'Tulsa',
    province: 'Oklahoma',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-95.99277500000001, 36.1539816],
    timezone: 'America/Chicago',
    unlocs: ['USTUL'],
    code: '5505',
    id: 'USTUL',
  },
  {
    name: 'Waukegan',
    coordinates: [-87.83, 42.35],
    city: 'Waukegan',
    province: 'Illinois',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/Chicago',
    unlocs: ['USUGN'],
    id: 'USUGN',
  },
  {
    name: 'Port Chester',
    city: 'Port Chester',
    province: 'New York',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-73.66568339999999, 41.0017643],
    timezone: 'America/New_York',
    unlocs: ['USUXZ'],
    id: 'USUXZ',
  },
  {
    name: 'Tonawanda',
    city: 'Tonawanda',
    province: 'New York',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-78.880315, 43.0203347],
    timezone: 'America/New_York',
    unlocs: ['USUZF'],
    id: 'USUZF',
  },
  {
    name: 'Virginia Beach',
    city: 'Virginia Beach',
    province: 'Virginia',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-75.97798499999999, 36.8529263],
    timezone: 'America/New_York',
    unlocs: ['USVAB'],
    id: 'USVAB',
  },
  {
    name: 'Howard Beach/Queens/New York',
    coordinates: [-73.83, 40.65],
    city: 'Howard Beach/Queens/New York',
    province: 'New York',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USXGO'],
    id: 'USXGO',
  },
  {
    name: 'Kirkland',
    city: 'Kirkland',
    province: 'Washington',
    country: 'United States',
    alias: [],
    regions: [],
    coordinates: [-122.2087353, 47.6814875],
    timezone: 'America/Los_Angeles',
    unlocs: ['USXHF'],
    id: 'USXHF',
  },
  {
    name: 'Long Island City/Queens/New York',
    coordinates: [-73.93, 40.73],
    city: 'Long Island City/Queens/New York',
    province: 'New York',
    country: 'United States',
    alias: [],
    regions: [],
    timezone: 'America/New_York',
    unlocs: ['USXHO'],
    id: 'USXHO',
  },
  {
    timezone: 'America/New_York',
    coordinates: [-80.3289521, 35.2257007],
    name: 'Oakboro',
    city: 'Oakboro',
    province: 'North Carolina',
    country: 'United States',
    alias: [],
    regions: [],
    unlocs: ['USYOB'],
    id: 'USYOB',
  },
  {
    name: 'Colonia',
    city: 'Colonia',
    country: 'Uruguay',
    alias: [],
    regions: [],
    coordinates: [-55.765835, -32.522779],
    timezone: 'America/Montevideo',
    unlocs: ['UYCYR'],
    code: '35500',
    id: 'UYCYR',
  },
  {
    name: 'Montevideo',
    city: 'Montevideo',
    country: 'Uruguay',
    alias: [],
    regions: [],
    coordinates: [-56.16453139999999, -34.9011127],
    province: 'Montevideo Department',
    timezone: 'America/Montevideo',
    unlocs: ['UYMVD'],
    code: '35525',
    id: 'UYMVD',
  },
  {
    name: 'Nueva Palmira',
    city: 'Nueva Palmira',
    country: 'Uruguay',
    alias: [],
    regions: [],
    coordinates: [-58.4002165, -33.877469],
    province: 'Colonia',
    timezone: 'America/Montevideo',
    unlocs: ['UYNVP'],
    code: '35535',
    id: 'UYNVP',
  },
  {
    name: 'Paysandu',
    city: 'Paysandu',
    country: 'Uruguay',
    alias: [],
    regions: [],
    coordinates: [-57.4627259, -32.2216595],
    province: 'Paysandu',
    timezone: 'America/Montevideo',
    unlocs: ['UYPDU'],
    code: '35500',
    id: 'UYPDU',
  },
  {
    name: 'Kingstown',
    coordinates: [-61.22, 13.13],
    city: 'Kingstown',
    country: 'Saint Vincent and the Grenadines',
    alias: [],
    regions: [],
    province: 'Saint George',
    timezone: 'America/St_Vincent',
    unlocs: ['VCKTN'],
    id: 'VCKTN',
  },
  {
    name: 'Amuay',
    city: 'Amuay',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-70.2423207, 11.776897],
    province: 'Falcón',
    timezone: 'America/Caracas',
    unlocs: ['VEAMY'],
    id: 'VEAMY',
  },
  {
    name: 'Barcelona',
    city: 'Barcelona',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-64.67768, 10.1445691],
    province: 'Anzoategui',
    timezone: 'America/Caracas',
    unlocs: ['VEBLA'],
    id: 'VEBLA',
  },
  {
    name: 'El Guamache',
    city: 'El Guamache',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-64.0665046, 10.8958956],
    province: 'Nueva Esparta',
    timezone: 'America/Caracas',
    unlocs: ['VEEGU'],
    code: '30774',
    id: 'VEEGU',
  },
  {
    name: 'El Tablazo/Maracaibo L',
    city: 'El Tablazo/Maracaibo L',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-71.64193399999999, 10.596956],
    province: 'Zulia',
    timezone: 'America/Caracas',
    unlocs: ['VEETV'],
    id: 'VEETV',
  },
  {
    name: 'Guaranao Bay',
    city: 'Guaranao Bay',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [11.6656673, -70.2147818],
    timezone: 'Antarctica/South_Pole',
    unlocs: ['VEGUB'],
    id: 'VEGUB',
  },
  {
    name: 'Guanta',
    city: 'Guanta',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-64.591805, 10.2343685],
    province: 'Anzoategui',
    timezone: 'America/Caracas',
    unlocs: ['VEGUT'],
    code: '30741',
    id: 'VEGUT',
  },
  {
    name: 'La Guaira',
    city: 'La Guaira',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-66.9213628, 10.587508],
    province: 'Vargas',
    timezone: 'America/Caracas',
    unlocs: ['VELAG'],
    code: '30745',
    id: 'VELAG',
  },
  {
    name: 'La Salina/Maracaibo L',
    city: 'La Salina/Maracaibo L',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-71.6777177, 10.7156738],
    province: 'Zulia',
    timezone: 'America/Caracas',
    unlocs: ['VELSV'],
    id: 'VELSV',
  },
  {
    name: 'Maracaibo',
    city: 'Maracaibo',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-71.7147951, 10.6544509],
    province: 'Zulia',
    timezone: 'America/Caracas',
    unlocs: ['VEMAR'],
    code: '30759',
    id: 'VEMAR',
  },
  {
    name: 'Matanzas',
    city: 'Matanzas',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-62.8, 8.316666999999999],
    province: 'Bolívar',
    timezone: 'America/Caracas',
    unlocs: ['VEMTV'],
    code: '30760',
    id: 'VEMTV',
  },
  {
    name: 'Puerto Cabello',
    city: 'Puerto Cabello',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-68.02986539999999, 10.4697685],
    province: 'Carabobo',
    timezone: 'America/Caracas',
    unlocs: ['VEPBL'],
    code: '30764',
    id: 'VEPBL',
  },
  {
    name: 'Punta Cardon',
    coordinates: [-70.22, 11.65],
    city: 'Punta Cardón',
    province: 'Falcón',
    country: 'Venezuela',
    alias: [],
    regions: [],
    timezone: 'America/Caracas',
    unlocs: ['VEPCN'],
    code: '30770',
    id: 'VEPCN',
  },
  {
    name: 'Puerto Sucre',
    city: 'Puerto Sucre',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-68.3833329, 8.966667000000001],
    province: 'Cojedes',
    timezone: 'America/Caracas',
    unlocs: ['VEPSU'],
    code: '30769',
    id: 'VEPSU',
  },
  {
    name: 'Puerto Ordaz',
    city: 'Puerto Ordaz',
    country: 'Venezuela',
    alias: [],
    regions: [],
    coordinates: [-62.76766329999999, 8.2750921],
    province: 'Bolívar',
    timezone: 'America/Caracas',
    unlocs: ['VEPZO'],
    code: '30768',
    id: 'VEPZO',
  },
  {
    name: 'Road Town, Tortola',
    city: 'Road Town, Tortola',
    country: 'Virgin Islands, British',
    alias: [],
    regions: [],
    coordinates: [-64.61412229999999, 18.4229948],
    province: 'Tortola',
    timezone: 'America/Tortola',
    unlocs: ['VGRAD'],
    id: 'VGRAD',
  },
  {
    name: 'Tortola',
    city: 'Tortola',
    country: 'Virgin Islands, British',
    alias: [],
    regions: [],
    coordinates: [-64.61412229999999, 18.4229948],
    province: 'Tortola',
    timezone: 'America/Tortola',
    unlocs: ['VGTOV'],
    id: 'VGTOV',
  },
  {
    name: 'Charlotte Amalie, Saint Thomas',
    city: 'Charlotte Amalie, Saint Thomas',
    country: 'Virgin Islands, U.S.',
    alias: [],
    regions: [],
    coordinates: [-64.9307007, 18.3419004],
    province: 'St. Thomas',
    timezone: 'America/St_Thomas',
    unlocs: ['VICHA'],
    code: '91155',
    id: 'VICHA',
  },
  {
    name: 'Christiansted, Saint Croix',
    city: 'Christiansted, Saint Croix',
    country: 'Virgin Islands, U.S.',
    alias: [],
    regions: [],
    coordinates: [-64.7031977, 17.7466397],
    province: 'St. Croix',
    timezone: 'America/St_Thomas',
    unlocs: ['VICTD'],
    code: '91115',
    id: 'VICTD',
  },
  {
    name: 'Saint Thomas',
    coordinates: [-64.93, 18.35],
    city: 'Saint Thomas',
    country: 'Virgin Islands, U.S.',
    alias: [],
    regions: [],
    province: 'VI',
    timezone: 'America/St_Thomas',
    unlocs: ['VISTT'],
    code: '91155',
    id: 'VISTT',
  },
  {
    name: 'Saint Croix',
    coordinates: [-64.73, 17.75],
    city: 'Saint Croix',
    country: 'Virgin Islands, U.S.',
    alias: [],
    regions: [],
    province: 'St. Croix',
    timezone: 'America/St_Thomas',
    unlocs: ['VISTX'],
    code: '91149',
    id: 'VISTX',
  },
  {
    name: 'Cat Lai',
    coordinates: [106.78, 10.77],
    city: 'Cát Lái',
    province: 'Ho Chi Minh',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    timezone: 'Asia/Saigon',
    unlocs: ['VNCLI'],
    id: 'VNCLI',
  },
  {
    name: 'Cai Lan',
    coordinates: [107.05, 21.42],
    city: 'Cai Lan',
    province: 'Quảng Ninh',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    timezone: 'Asia/Saigon',
    unlocs: ['VNCLN'],
    id: 'VNCLN',
  },
  {
    name: 'Da Nang',
    city: 'Da Nang',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    coordinates: [108.2021667, 16.0544068],
    province: 'Da Nang',
    timezone: 'Asia/Saigon',
    unlocs: ['VNDAD'],
    code: '55204',
    id: 'VNDAD',
  },
  {
    name: 'Haiphong',
    city: 'Haiphong',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    coordinates: [106.6880841, 20.8449115],
    province: 'Haiphong',
    timezone: 'Asia/Saigon',
    unlocs: ['VNHPH'],
    code: '55201',
    id: 'VNHPH',
  },
  {
    name: 'Nha Trang',
    city: 'Nha Trang',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    coordinates: [109.1967488, 12.2387911],
    province: 'Khanh Hoa Province',
    timezone: 'Asia/Saigon',
    unlocs: ['VNNHA'],
    code: '55208',
    id: 'VNNHA',
  },
  {
    name: 'Phuoc Long',
    coordinates: [106.95, 10.72],
    city: 'Phuóc Long',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    province: 'Bac Lieu',
    timezone: 'Asia/Saigon',
    unlocs: ['VNPHG'],
    id: 'VNPHG',
  },
  {
    name: 'Ho Chi Minh City',
    coordinates: [106.67, 10.77],
    city: 'Ho Chi Minh City',
    province: 'Ho Chi Minh',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    timezone: 'Asia/Saigon',
    unlocs: ['VNSGN'],
    code: '55224',
    id: 'VNSGN',
  },
  {
    timezone: 'Asia/Saigon',
    coordinates: [108.2614775, 10.9804603],
    name: 'Su Tu Den',
    city: 'Su Tu Den',
    province: 'Bình Thuận',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    unlocs: ['VNSTD'],
    id: 'VNSTD',
  },
  {
    timezone: 'Asia/Saigon',
    coordinates: [107.1551514, 10.5850216],
    name: 'Cai Mep',
    city: 'Cai Mep',
    province: 'Bà Rịa - Vũng Tàu',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    unlocs: ['VNTOT'],
    id: 'VNTOT',
  },
  {
    name: 'Tan Thuan Dong',
    coordinates: [106.72, 10.73],
    city: 'Tân Thuan Dông',
    province: 'Ho Chi Minh',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    timezone: 'Asia/Saigon',
    unlocs: ['VNTTD'],
    id: 'VNTTD',
  },
  {
    name: 'Qui Nhon',
    coordinates: [109.22, 13.77],
    city: 'Qui Nhon',
    province: 'Bình Định',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    timezone: 'Asia/Saigon',
    unlocs: ['VNUIH'],
    id: 'VNUIH',
  },
  {
    name: 'Ho Chi Minh, VICT',
    city: 'Ho Chi Minh, VICT',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    coordinates: [106.6721498, 10.796011],
    province: 'Hồ Chí Minh',
    timezone: 'Asia/Saigon',
    unlocs: ['VNVIC'],
    code: '55224',
    id: 'VNVIC',
  },
  {
    name: 'Vung Tau',
    coordinates: [107.07, 10.35],
    city: 'Vung Tau',
    province: 'Bà Rịa - Vung Tàu',
    country: 'Viet Nam',
    alias: [],
    regions: [],
    timezone: 'Asia/Saigon',
    unlocs: ['VNVUT'],
    code: '55206',
    id: 'VNVUT',
  },
  {
    name: 'Espiritu Santo',
    city: 'Espiritu Santo',
    country: 'Vanuatu',
    alias: [],
    regions: [],
    coordinates: [166.9182097, -15.3003549],
    timezone: 'Pacific/Efate',
    unlocs: ['VUSON'],
    id: 'VUSON',
  },
  {
    name: 'Port Vila',
    city: 'Port Vila',
    country: 'Vanuatu',
    alias: [],
    regions: [],
    coordinates: [168.3273245, -17.7332512],
    province: 'Shefa Province',
    timezone: 'Pacific/Efate',
    unlocs: ['VUVLI'],
    code: '62209',
    id: 'VUVLI',
  },
  {
    name: 'Apia',
    city: 'Apia',
    country: 'Samoa',
    alias: [],
    regions: [],
    coordinates: [-171.7513551, -13.8506958],
    province: 'Upolu',
    timezone: 'Pacific/Apia',
    unlocs: ['WSAPW'],
    code: '61501',
    id: 'WSAPW',
  },
  {
    name: 'Aden',
    city: 'Aden',
    country: 'Yemen',
    alias: [],
    regions: [],
    coordinates: [45.0186548, 12.7854969],
    province: 'Aden',
    timezone: 'Asia/Aden',
    unlocs: ['YEADE'],
    code: '52101',
    id: 'YEADE',
  },
  {
    name: 'Hodeidah',
    coordinates: [42.9, 14.83],
    city: 'Hodeidah',
    country: 'Yemen',
    alias: [],
    regions: [],
    province: 'Al Hudaydah',
    timezone: 'Asia/Aden',
    unlocs: ['YEHOD'],
    code: '52115',
    id: 'YEHOD',
  },
  {
    name: 'Little Aden',
    coordinates: [44.87, 12.73],
    city: 'Little Aden',
    province: 'Adan',
    country: 'Yemen',
    alias: [],
    regions: [],
    timezone: 'Asia/Aden',
    unlocs: ['YELAD'],
    code: '52101',
    id: 'YELAD',
  },
  {
    name: 'Mukalla',
    city: 'Mukalla',
    country: 'Yemen',
    alias: [],
    regions: [],
    coordinates: [49.127197, 14.5404328],
    province: 'Hadramaut',
    timezone: 'Asia/Aden',
    unlocs: ['YEMKX'],
    code: '52100',
    id: 'YEMKX',
  },
  {
    name: 'Longoni',
    coordinates: [45.17, -12.75],
    city: 'Longoni',
    country: 'Mayotte',
    alias: [],
    regions: [],
    province: 'Koungou',
    timezone: 'Indian/Mayotte',
    unlocs: ['YTLON'],
    id: 'YTLON',
  },
  {
    name: 'Bloemfontein',
    city: 'Bloemfontein',
    country: 'South Africa',
    alias: [],
    regions: [],
    coordinates: [26.1595761, -29.085214],
    province: 'Free State',
    timezone: 'Africa/Johannesburg',
    unlocs: ['ZABFN'],
    id: 'ZABFN',
  },
  {
    name: 'Cape Town',
    coordinates: [18.42, -33.92],
    city: 'Cape Town',
    province: 'Western Cape',
    country: 'South Africa',
    alias: [],
    regions: [],
    timezone: 'Africa/Johannesburg',
    unlocs: ['ZACPT'],
    code: '79101',
    id: 'ZACPT',
  },
  {
    name: 'Durban',
    city: 'Durban',
    country: 'South Africa',
    alias: [],
    regions: [],
    coordinates: [31.0218404, -29.85868039999999],
    province: 'KwaZulu-Natal',
    timezone: 'Africa/Johannesburg',
    unlocs: ['ZADUR'],
    code: '79113',
    id: 'ZADUR',
  },
  {
    name: 'East London',
    city: 'East London',
    country: 'South Africa',
    alias: [],
    regions: [],
    coordinates: [27.8545867, -33.0291582],
    province: 'Eastern Cape',
    timezone: 'Africa/Johannesburg',
    unlocs: ['ZAELS'],
    code: '79125',
    id: 'ZAELS',
  },
  {
    name: 'Johannesburg',
    coordinates: [28.08, -26.2],
    city: 'Johannesburg',
    province: 'Gauteng',
    country: 'South Africa',
    alias: [],
    regions: [],
    timezone: 'Africa/Johannesburg',
    unlocs: ['ZAJNB'],
    id: 'ZAJNB',
  },
  {
    name: 'Port Elizabeth',
    city: 'Port Elizabeth',
    country: 'South Africa',
    alias: [],
    regions: [],
    coordinates: [25.5207358, -33.7139247],
    province: 'Eastern Cape',
    timezone: 'Africa/Johannesburg',
    unlocs: ['ZAPLZ'],
    code: '79145',
    id: 'ZAPLZ',
  },
  {
    name: 'Pretoria',
    coordinates: [28.22, -25.7],
    city: 'Pretoria',
    province: 'Gauteng',
    country: 'South Africa',
    alias: [],
    regions: [],
    timezone: 'Africa/Johannesburg',
    unlocs: ['ZAPRY'],
    id: 'ZAPRY',
  },
  {
    name: 'Richards Bay',
    city: 'Richards Bay',
    country: 'South Africa',
    alias: [],
    regions: [],
    coordinates: [32.0382856, -28.7807276],
    province: 'KwaZulu-Natal',
    timezone: 'Africa/Johannesburg',
    unlocs: ['ZARCB'],
    id: 'ZARCB',
  },
  {
    name: 'Coega',
    coordinates: [25.67, -33.77],
    city: 'Coega',
    province: 'Eastern Cape',
    country: 'South Africa',
    alias: [],
    regions: [],
    timezone: 'Africa/Johannesburg',
    unlocs: ['ZAZBA'],
    id: 'ZAZBA',
  },
  {
    name: 'Lusaka',
    city: 'Lusaka',
    country: 'Zambia',
    alias: [],
    regions: [],
    coordinates: [28.3228165, -15.3875259],
    province: 'Lusaka',
    timezone: 'Africa/Lusaka',
    unlocs: ['ZMLUN'],
    id: 'ZMLUN',
  },
  {
    name: 'Bulawayo',
    city: 'Bulawayo',
    country: 'Zimbabwe',
    alias: [],
    regions: [],
    coordinates: [28.626479, -20.1325066],
    province: 'Bulawayo',
    timezone: 'Africa/Harare',
    unlocs: ['ZWBUQ'],
    id: 'ZWBUQ',
  },
  {
    name: 'Harare',
    city: 'Harare',
    country: 'Zimbabwe',
    alias: [],
    regions: [],
    coordinates: [31.03351, -17.8251657],
    province: 'Harare',
    timezone: 'Africa/Harare',
    unlocs: ['ZWHRE'],
    id: 'ZWHRE',
  },
  {
    name: 'Mutare',
    city: 'Mutare',
    country: 'Zimbabwe',
    alias: [],
    regions: [],
    coordinates: [32.650351, -18.9757714],
    province: 'Manicaland',
    timezone: 'Africa/Harare',
    unlocs: ['ZWUTA'],
    id: 'ZWUTA',
  },
]

import styled, { css } from 'styled-components'
import { colors, fonts } from 'styles'
import ReactNumberFormat from 'react-number-format'
import CurrencyInput from './CurrencyInput'

const containerThemes = {
  default: css`
    height: 60px;
    border-bottom: 2px solid ${colors.defaultInputBorder};
    position: relative;

    ${({ isFocused, hasValue }) =>
      (isFocused || hasValue) &&
      css`
        height: 60px;
      `}
  `,
  bordered: css`
    flex-direction: column;
  `,
  inlineGrey: css`
    height: 50px;
    border: 1px solid ${colors.silver7};
    border-radius: 5px;
  `,
}

export const Container = styled.div`
  display: flex;
  min-width: 50%;
  margin-bottom: ${({ marginBottom }) => marginBottom || 'unset'};

  ${({ theme }) => containerThemes[theme] || containerThemes.default}
`

const placeholderSize = '20px'

const placeholderThemes = {
  default: css`
    font-weight: bold;
    font-size: ${placeholderSize};
    line-height: ${placeholderSize};
    width: 100%;

    z-index: 10;
    font-weight: 500;
    position: absolute;
    top: 30%;
    width: auto;
    transition: transform 150ms ease-out, font-size 150ms ease-out;

    ${({ isFocused, hasValue }) =>
      (isFocused || hasValue) &&
      css`
        top: 50%;
        transform: translateY(-125%);
      `}

    color: ${colors.primary};
  `,
  bordered: css`
    font-family: ${fonts.primary};
    color: ${colors.boulder};
    font-size: 16px;
    text-align: left;
    width: 100%;
    margin-bottom: 5px;
  `,
  inlineGrey: css`
    height: 100%;
    width: 40%;
    background: ${colors.alto2};
    border: 1px solid ${colors.alto2};
    font-size: 16px;
    color: ${colors.black};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
}

export const Placeholder = styled.span`
  ${({ theme }) => placeholderThemes[theme] || placeholderThemes.default}
`

const inputThemes = {
  default: css`
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    font-size: 16px;
    font-style: italic;
    padding-left: 4px;
    color: ${colors.dorado};

    ${({ isFocused, hasValue }) =>
      (isFocused || hasValue) &&
      css`
        padding-top: ${placeholderSize};
      `}
  `,
  bordered: css`
    padding: 0.5rem 1rem;
    font-family: ${fonts.primary};
    font-size: 14px;
    outline: none;
    line-height: 1.2;
    background: transparent;
    color: ${colors.greyDark};
    appearance: none;
    border-radius: 5px;

    height: 40px;
    width: 100%;
    min-width: 240px;
    margin: 5px 0;

    border: 1px solid ${colors.silver};
  `,
  inlineGrey: css`
    height: 100%;
    width: 60%;
    background: ${colors.concrete};
    font-size: 16px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 35px;
    border: none;
  `,
}

export const ToggleContainer = styled.div`
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    padding: 10px;
    cursor: pointer;
`

export const Input = styled.input`
  ${({ theme }) => inputThemes[theme] || inputThemes.default}
`

export const NumberFormat = styled(ReactNumberFormat)`
  ${({ theme }) => inputThemes[theme] || inputThemes.default}
`

export const Currency = styled(CurrencyInput)`
  ${({ theme }) => inputThemes[theme] || inputThemes.default}
`

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  tons: {
    id: 'loi.list.tons',
    defaultMessage: 'toneladas',
  },
  remainingTime: {
    id: 'loi.list.remainingTime',
    defaultMessage: 'Tempo restante:',
  },
  originatingOffer: {
    id: 'loi.list.originatingOffer',
    defaultMessage: 'Originando oferta',
  },
  all: {
    id: 'loi.list.all',
    defaultMessage: 'Todas',
  },
  productOriginated: {
    id: 'loi.list.productOriginated',
    defaultMessage: 'Produto originado',
  },
  sold: {
    id: 'loi.list.sold',
    defaultMessage: 'Vendido',
  },
  canceled: {
    id: 'loi.list.canceled',
    defaultMessage: 'Cancelado',
  },
})

export default messages

import { useIntl } from 'react-intl'

import messages from './messages'

export function useConstants() {
  const { formatMessage } = useIntl()

  const contractTypes = [
    {
      type: formatMessage(messages.cif),
      description: formatMessage(messages.cifDescription),
    },
    {
      type: formatMessage(messages.crf),
      description: formatMessage(messages.crfDescription),
    },
    {
      type: formatMessage(messages.fob),
      description: formatMessage(messages.fobDescription),
    },
  ]

  const transportationMethods = [
    {
      type: 'truck',
      description: formatMessage(messages.truck),
    },
    {
      type: 'container',
      description: formatMessage(messages.container),
    },
  ]

  const paymentTypes = [
    {
      type: formatMessage(messages.transferThirtySeventy),
      description: formatMessage(messages.transferThirtySeventy),
    },
    {
      type: formatMessage(messages.transferTotal),
      description: formatMessage(messages.transferTotal),
    },
    {
      type: formatMessage(messages.lcThirtySeventy),
      description: formatMessage(messages.lcThirtySeventy),
    },
    {
      type: formatMessage(messages.lcTotal),
      description: formatMessage(messages.lcTotal),
    },
  ]

  const packageTypes = [
    {
      id: 'granel',
      description: formatMessage(messages.granel),
    },
    {
      id: 'bigbag',
      description: formatMessage(messages.bigBag),
    },
    {
      id: 'sacks',
      description: formatMessage(messages.sacks),
    },
  ]

  const bigBagValues = [
    { label: '800', value: 800 },
    { label: '1000', value: 1000 },
  ]

  const sacksValues = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '40', value: 40 },
    { label: '50', value: 50 },
    { label: '60', value: 60 },
    { label: '70', value: 70 },
  ]

  const sackBatchValues = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '40', value: 40 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  const tonsValues = [
    { label: '27', value: 27 },
    { label: '54', value: 54 },
    { label: '81', value: 81 },
    { label: '108', value: 108 },
    { label: '135', value: 135 },
    { label: '162', value: 162 },
    { label: '189', value: 189 },
    { label: '216', value: 216 },
    { label: '243', value: 243 },
    { label: '270', value: 270 },
    { label: '297', value: 297 },
    { label: '324', value: 324 },
    { label: '351', value: 351 },
    { label: '378', value: 378 },
    { label: '405', value: 405 },
    { label: '432', value: 432 },
    { label: '459', value: 459 },
    { label: '486', value: 486 },
    { label: '513', value: 513 },
    { label: '540', value: 540 },
    { label: '567', value: 567 },
    { label: '594', value: 594 },
    { label: '621', value: 621 },
    { label: '648', value: 648 },
    { label: '675', value: 675 },
    { label: '702', value: 702 },
    { label: '729', value: 729 },
    { label: '756', value: 756 },
    { label: '783', value: 783 },
    { label: '810', value: 810 },
    { label: '837', value: 837 },
    { label: '864', value: 864 },
    { label: '891', value: 891 },
    { label: '918', value: 918 },
    { label: '945', value: 945 },
    { label: '972', value: 972 },
    { label: '999', value: 999 },
    { label: '1026', value: 1026 },
  ]

  const countries = [
    { name: 'Afeganistão', code: 'AF', name_en: 'Afghanistan' },
    { name: 'África do Sul', code: 'ZA', name_en: 'South Africa' },
    { name: 'Albânia', code: 'AL', name_en: 'Albania' },
    { name: 'Alemanha', code: 'DE', name_en: 'Germany' },
    { name: 'Andorra', code: 'AD', name_en: 'Andorra' },
    { name: 'Angola', code: 'AO', name_en: 'Angola' },
    { name: 'Anguilla', code: 'AI', name_en: 'Anguilla' },
    { name: 'Antíga e Barbuda', code: 'AG', name_en: 'Antigua and Barbuda' },
    {
      name: 'Antilhas Holandêsas',
      code: 'AN',
      name_en: 'Netherlands Antilles',
    },
    { name: 'Arábia Saudita', code: 'SA', name_en: 'Saudi Arabia' },
    { name: 'Argélia', code: 'DZ', name_en: 'Algeria' },
    { name: 'Argentina', code: 'AR', name_en: 'Argentina' },
    { name: 'Arménia', code: 'AM', name_en: 'Armenia' },
    { name: 'Aruba', code: 'AW', name_en: 'Aruba' },
    { name: 'Austrália', code: 'AU', name_en: 'Australia' },
    { name: 'Áustria', code: 'AT', name_en: 'Austria' },
    { name: 'Azerbaijão', code: 'AZ', name_en: 'Azerbaijan' },
    { name: 'Bahamas', code: 'BS', name_en: 'Bahamas' },
    { name: 'Bangladexe', code: 'BD', name_en: 'Bangladesh' },
    { name: 'Barbados', code: 'BB', name_en: 'Barbados' },
    { name: 'Barém', code: 'BH', name_en: 'Bahrain' },
    { name: 'Bélgica', code: 'BE', name_en: 'Belgium' },
    { name: 'Belize', code: 'BZ', name_en: 'Belize' },
    { name: 'Benim', code: 'BJ', name_en: 'Benin' },
    { name: 'Bermudas', code: 'BM', name_en: 'Bermuda' },
    { name: 'Bielorrússia', code: 'BY', name_en: 'Belarus' },
    { name: 'Bolívia', code: 'BO', name_en: 'Bolivia' },
    {
      name: 'Bósnia e Herzegovina',
      code: 'BA',
      name_en: 'Bosnia and Herzegovina',
    },
    { name: 'Botsuana', code: 'BW', name_en: 'Botswana' },
    { name: 'Brasil', code: 'BR', name_en: 'Brazil' },
    { name: 'Brunei', code: 'BN', name_en: 'Brunei' },
    { name: 'Bulgária', code: 'BG', name_en: 'Bulgaria' },
    { name: 'Burquina Faso', code: 'BF', name_en: 'Burkina Faso' },
    { name: 'Burúndi', code: 'BI', name_en: 'Burundi' },
    { name: 'Butão', code: 'BT', name_en: 'Bhutan' },
    { name: 'Cabo Verde', code: 'CV', name_en: 'Cape Green' },
    { name: 'Camarões', code: 'CM', name_en: 'Cameroon' },
    { name: 'Camboja', code: 'KH', name_en: 'Cambodia' },
    { name: 'Canadá', code: 'CA', name_en: 'Canada' },
    { name: 'Catar', code: 'QA', name_en: 'Qatar' },
    { name: 'Cazaquistão', code: 'KZ', name_en: 'Kazakhstan' },
    { name: 'Chade', code: 'TD', name_en: 'Chad' },
    { name: 'Chile', code: 'CL', name_en: 'Chile' },
    { name: 'China', code: 'CN', name_en: 'China' },
    { name: 'Chipre', code: 'CY', name_en: 'Cyprus' },
    { name: 'Colômbia', code: 'CO', name_en: 'Colombia' },
    { name: 'Comores', code: 'KM', name_en: 'Comoros' },
    { name: 'Congo', code: 'CG', name_en: 'Congo' },
    { name: 'Coreia do Norte', code: 'KP', name_en: 'North Korea' },
    { name: 'Coreia do Sul', code: 'KR', name_en: 'South Korea' },
    { name: 'Cosovo', code: 'XK', name_en: 'Cosovo' },
    { name: 'Costa do Marfim', code: 'CI', name_en: 'Ivory Coast' },
    { name: 'Costa Rica', code: 'CR', name_en: 'Costa Rica' },
    { name: 'Croácia', code: 'HR', name_en: 'Croatia' },
    { name: 'Cuaite', code: 'KW', name_en: 'Kuwait' },
    { name: 'Cuba', code: 'CU', name_en: 'Cuba' },
    { name: 'Djibouti', code: 'DJ', name_en: 'Djibouti' },
    { name: 'Dinamarca', code: 'DK', name_en: 'Denmark' },
    { name: 'Dominica', code: 'DM', name_en: 'Dominica' },
    { name: 'Egito', code: 'EG', name_en: 'Egypt' },
    {
      name: 'Emirados Árabes Unidos',
      code: 'AE',
      name_en: 'United Arab Emirates',
    },
    { name: 'Equador', code: 'EC', name_en: 'Ecuador' },
    { name: 'Eritreia', code: 'ER', name_en: 'Eritreia' },
    { name: 'Eslováquia', code: 'SK', name_en: 'Slovakia' },
    { name: 'Eslovénia', code: 'SI', name_en: 'Slovenia' },
    { name: 'Espanha', code: 'ES', name_en: 'Spain' },
    { name: 'Estado da Palestina', code: 'PS', name_en: 'Palestine' },
    { name: 'Estados Unidos', code: 'US', name_en: 'United States' },
    { name: 'Estónia', code: 'EE', name_en: 'Estonia' },
    { name: 'Etiópia', code: 'ET', name_en: 'Ethiopia' },
    { name: 'Fiji', code: 'FJ', name_en: 'Fiji' },
    { name: 'Filipinas', code: 'PH', name_en: 'Philippines' },
    { name: 'Finlândia', code: 'FI', name_en: 'Finland' },
    { name: 'França', code: 'FR', name_en: 'France' },
    { name: 'Gabão', code: 'GA', name_en: 'Gabon' },
    { name: 'Gâmbia', code: 'GM', name_en: 'Gambia' },
    { name: 'Gana', code: 'GH', name_en: 'Ghana' },
    { name: 'Geórgia', code: 'GE', name_en: 'Georgia' },
    { name: 'Gibraltar', code: 'GI', name_en: 'Gibraltar' },
    { name: 'Granada', code: 'GD', name_en: 'Grenada' },
    { name: 'Grécia', code: 'GR', name_en: 'Greece' },
    { name: 'Guadalupe', code: 'GP', name_en: 'Guadeloupe' },
    { name: 'Guam', code: 'GU', name_en: 'Guam' },
    { name: 'Guatemala', code: 'GT', name_en: 'Guatemala' },
    { name: 'Guiana', code: 'GY', name_en: 'Guyana' },
    { name: 'Guiana Francesa', code: 'GF', name_en: 'French Guiana' },
    { name: 'Guiné', code: 'GN', name_en: 'Guinea' },
    { name: 'Guiné Equatorial', code: 'GQ', name_en: 'Equatorial Guinea' },
    { name: 'Guiné Bissau', code: 'GW', name_en: 'Guinea Bissau' },
    { name: 'Haiti', code: 'HT', name_en: 'Haiti' },
    { name: 'Honduras', code: 'HN', name_en: 'Honduras' },
    { name: 'Hungria', code: 'HU', name_en: 'Hungary' },
    { name: 'Iémen', code: 'YE', name_en: 'Yemen' },
    { name: 'Ilhas Feroé', code: 'FO', name_en: 'Faroe Islands' },
    { name: 'Ilhas Malvinas', code: 'FK', name_en: 'Falkland Islands' },
    { name: 'Ilhas Marechal', code: 'MH', name_en: 'Marshal Islands' },
    { name: 'Índia', code: 'IN', name_en: 'India' },
    { name: 'Indonésia', code: 'ID', name_en: 'Indonesia' },
    { name: 'Irão', code: 'IR', name_en: 'Iran' },
    { name: 'Iraque', code: 'IQ', name_en: 'Iraq' },
    { name: 'Irlanda', code: 'IE', name_en: 'Ireland' },
    { name: 'Islândia', code: 'IS', name_en: 'Iceland' },
    { name: 'Israel', code: 'IL', name_en: 'Israel' },
    { name: 'Itália', code: 'IT', name_en: 'Italy' },
    { name: 'Jamaica', code: 'JM', name_en: 'Jamaica' },
    { name: 'Japão', code: 'JP', name_en: 'Japan' },
    { name: 'Jibuti', code: 'DJ', name_en: 'Djibouti' },
    { name: 'Jordânia', code: 'JO', name_en: 'Jordan' },
    { name: 'Laos', code: 'LA', name_en: 'Laos' },
    { name: 'Lesoto', code: 'LS', name_en: 'Lesotho' },
    { name: 'Letónia', code: 'LV', name_en: 'Latvia' },
    { name: 'Líbano', code: 'LB', name_en: 'Lebanon' },
    { name: 'Libéria', code: 'LR', name_en: 'Liberia' },
    { name: 'Líbia', code: 'LY', name_en: 'Libya' },
    { name: 'Listenstaine', code: 'LI', name_en: 'Liechtenstein' },
    { name: 'Lituânia', code: 'LT', name_en: 'Lithuania' },
    { name: 'Luxemburgo', code: 'LU', name_en: 'Luxembourg' },
    { name: 'Macedónia', code: 'MK', name_en: 'Macedónia' },
    { name: 'Madagáscar', code: 'MG', name_en: 'Madagascar' },
    { name: 'Malásia', code: 'MY', name_en: 'Malaysia' },
    { name: 'Maláui', code: 'MW', name_en: 'Malawi' },
    { name: 'Maldivas', code: 'MV', name_en: 'Maldives' },
    { name: 'Mali', code: 'ML', name_en: 'Mali' },
    { name: 'Malta', code: 'MT', name_en: 'Malta' },
    { name: 'Marrocos', code: 'MA', name_en: 'Morocco' },
    { name: 'Maurícia', code: 'MU', name_en: 'Mauritius' },
    { name: 'Mauritânia', code: 'MR', name_en: 'Mauritania' },
    { name: 'México', code: 'MX', name_en: 'Mexico' },
    { name: 'Mianmar', code: 'MM', name_en: 'Myanmar' },
    { name: 'Micronésia', code: 'FM', name_en: 'Micronesia' },
    { name: 'Moçambique', code: 'MZ', name_en: 'Mozambique' },
    { name: 'Moldávia', code: 'MD', name_en: 'Moldavia' },
    { name: 'Mónaco', code: 'MC', name_en: 'Monaco' },
    { name: 'Mongólia', code: 'MN', name_en: 'Mongolia' },
    { name: 'Montenegro', code: 'ME', name_en: 'Montenegro' },
    { name: 'Namíbia', code: 'NA', name_en: 'Namibia' },
    { name: 'Nauru', code: 'NR', name_en: 'Nauru' },
    { name: 'Nepal', code: 'NP', name_en: 'Nepal' },
    { name: 'Nicarágua', code: 'NI', name_en: 'Nicaragua' },
    { name: 'Níger', code: 'NE', name_en: 'Niger' },
    { name: 'Nigéria', code: 'NG', name_en: 'Nigeria' },
    { name: 'Noruega', code: 'NO', name_en: 'Norway' },
    { name: 'Nova Zelândia', code: 'NZ', name_en: 'New Zealand' },
    { name: 'Omã', code: 'OM', name_en: 'Oman' },
    { name: 'Países Baixos', code: 'NL', name_en: 'Netherlands' },
    { name: 'Palau', code: 'PW', name_en: 'Palau' },
    { name: 'Panamá', code: 'PA', name_en: 'Panama' },
    { name: 'Papua Nova Guiné', code: 'PG', name_en: 'Papua New Guinea' },
    { name: 'Paquistão', code: 'PK', name_en: 'Pakistan' },
    { name: 'Paraguai', code: 'PY', name_en: 'Paraguay' },
    { name: 'Peru', code: 'PE', name_en: 'Peru' },
    { name: 'Polónia', code: 'PL', name_en: 'Poland' },
    { name: 'Portugal', code: 'PT', name_en: 'Portugal' },
    { name: 'Quénia', code: 'KE', name_en: 'Kenya' },
    { name: 'Quirguistão', code: 'KG', name_en: 'Kyrgyzstan' },
    { name: 'Quiribáti', code: 'KI', name_en: 'Kiribati' },
    { name: 'Reino Unido', code: 'GB', name_en: 'United Kingdom' },
    {
      name: 'República Centro-Africana',
      code: 'CF',
      name_en: 'Central African Republic',
    },
    { name: 'República Checa', code: 'CZ', name_en: 'Czech Republic' },
    {
      name: 'República Democrática do Congo',
      code: 'CD',
      name_en: 'Democratic Republic of Congo',
    },
    { name: 'República Dominicana', code: 'DO', name_en: 'Dominican Republic' },
    { name: 'Roménia', code: 'RO', name_en: 'Romania' },
    { name: 'Ruanda', code: 'RW', name_en: 'Rwanda' },
    { name: 'Rússia', code: 'RU', name_en: 'Russia' },
    { name: 'Salomão', code: 'SB', name_en: 'Solomon' },
    { name: 'El Salvador', code: 'SV', name_en: 'El Salvador' },
    { name: 'Samoa', code: 'WS', name_en: 'Samoa' },
    { name: 'Samoa Americana', code: 'AS', name_en: 'American Samoa' },
    { name: 'Santa Lúcia', code: 'LC', name_en: 'Saint Lucia' },
    {
      name: 'São Cristóvão e Neves',
      code: 'KN',
      name_en: 'Saint Kitts and Nevis',
    },
    { name: 'São Marinho', code: 'SM', name_en: 'San Marino' },
    {
      name: 'São Tomé e Príncipe',
      code: 'ST',
      name_en: 'Sao Tome and Principe',
    },
    {
      name: 'São Vicente e Granadinas',
      code: 'VC',
      name_en: 'Saint Vincent and the Grenadines',
    },
    { name: 'Seicheles', code: 'SC', name_en: 'Seychelles' },
    { name: 'Senegal', code: 'SN', name_en: 'Senegal' },
    { name: 'Serra Leoa', code: 'SL', name_en: 'Sierra Leone' },
    { name: 'Sérvia', code: 'RS', name_en: 'Serbia' },
    { name: 'Singapura', code: 'SG', name_en: 'Singapore' },
    { name: 'Síria', code: 'SY', name_en: 'Syria' },
    { name: 'Somália', code: 'SO', name_en: 'Somalia' },
    { name: 'Sri Lanca', code: 'LK', name_en: 'Sri Lanka' },
    { name: 'Suazilândia', code: 'SZ', name_en: 'Swaziland' },
    { name: 'Sudão', code: 'SD', name_en: 'Sudão' },
    { name: 'Sudão do Sul', code: 'SS', name_en: 'Southern Sudan' },
    { name: 'Suécia', code: 'SE', name_en: 'Sweden' },
    { name: 'Suíça', code: 'CH', name_en: 'Switzerland' },
    { name: 'Suriname', code: 'SR', name_en: 'Suriname' },
    { name: 'Tailândia', code: 'TH', name_en: 'Thailand' },
    { name: 'Taiwan', code: 'TW', name_en: 'Taiwan' },
    { name: 'Tajiquistão', code: 'TJ', name_en: 'Tajikistan' },
    { name: 'Tanzânia', code: 'TZ', name_en: 'Tanzania' },
    { name: 'Timor', code: 'TL', name_en: 'Timor' },
    { name: 'Togo', code: 'TG', name_en: 'Togo' },
    { name: 'Tonga', code: 'TO', name_en: 'Tonga' },
    { name: 'Trindade e Tobago', code: 'TT', name_en: 'Trinidad and Tobago' },
    { name: 'Tunísia', code: 'TN', name_en: 'Tunisia' },
    { name: 'Turcomenistão', code: 'TM', name_en: 'Turkmenistan' },
    { name: 'Turquia', code: 'TR', name_en: 'Turkey' },
    { name: 'Tuvalu', code: 'TV', name_en: 'Tuvalu' },
    { name: 'Ucrânia', code: 'UA', name_en: 'Ukraine' },
    { name: 'Uganda', code: 'UG', name_en: 'Uganda' },
    { name: 'Uruguai', code: 'UY', name_en: 'Uruguay' },
    { name: 'Uzbequistão', code: 'UZ', name_en: 'Uzbekistan' },
    { name: 'Vanuatu', code: 'VU', name_en: 'Vanuatu' },
    { name: 'Vaticano', code: 'VA', name_en: 'Vatican' },
    { name: 'Venezuela', code: 'VE', name_en: 'Venezuela' },
    { name: 'Vietnã', code: 'VN', name_en: 'Vietnam' },
    { name: 'Zâmbia', code: 'ZM', name_en: 'Zambia' },
    { name: 'Zimbábue', code: 'ZW', name_en: 'Zimbabwe' },
  ]

  return {
    contractTypes,
    transportationMethods,
    paymentTypes,
    packageTypes,
    bigBagValues,
    sackBatchValues,
    sacksValues,
    tonsValues,
    countries,
  }
}

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  my: {
    id: 'profile.title.my',
    defaultMessage: 'Meu',
  },
  profile: {
    id: 'profile.title.profile',
    defaultMessage: 'Perfil',
  },
  editPhoto: {
    id: 'profile.title.editPhoto',
    defaultMessage: 'Editar foto',
  },
  telephone: {
    id: 'profile.title.telephone',
    defaultMessage: 'Telefone ',
  },
  addressesTitle:{
    id: 'addresses.title',
    defaultMessage: 'Meus endereços',
  },
})

export default messages;
import React, { useEffect, useState, useRef } from 'react'

import { useFileUpload } from 'modules/data/hooks/useFileUpload'

import SVG from '../SVG'

import * as s from './styles'

function Status({ fileUpload }) {
  const { progress, error, url } = fileUpload

  if (url) {
    return <SVG.FileStatus type="success" />
  }

  if (error) {
    return <SVG.FileStatus type="error" />
  }

  if (progress > 0 && progress < 100) {
    return <SVG.FileStatus type="loading" />
  }

  return null;
}

function Upload({ className, onChange, onUpload, theme, fileDialogOpen, setFileDialogOpen, folder, label, ...props }) {
  const [file, setFile] = useState({});
  const [fileUrl, setFileUrl] = useState(null);
  const fileUpload = useFileUpload()
  const uploadRef = useRef()

  const openDialog = (event) => {
    if (event) {
      event.stopPropagation()
      event.preventDefault()
    }
    uploadRef.current && uploadRef.current.click()
  }

  const triggerUpload = (event) => {
    event.stopPropagation()
    event.preventDefault()

    setFileDialogOpen && setFileDialogOpen(false);

    setFile(event.target.files[0])
    fileUpload.upload(event.target.files[0], folder)
  }
  
  useEffect(() => {
    onChange && onChange(fileUpload)
    if (fileUpload.url !== fileUrl) {
      setFileUrl(fileUpload.url)
      onUpload(fileUpload.url)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUpload.url])

  useEffect(() => {
    if (fileDialogOpen) {
      openDialog()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileDialogOpen])

  return (
    <s.Wrapper className={className} theme={theme}>
      <s.Container theme={theme}>
        <s.Placeholder>{label}{file.name ? `: ${file.name}` : ''}</s.Placeholder>
        <s.Input
          type='file'
          accept=".jpg,.jpeg,.png,.webp,.pdf"
          ref={uploadRef}
          onChange={triggerUpload}
          {...props}
        />
        <s.Attachment theme={theme} onClick={openDialog}>
          <SVG.Attachment color="dorado" />
        </s.Attachment>
      </s.Container>
      <Status fileUpload={fileUpload} />
    </s.Wrapper>
  )
}

export default Upload;
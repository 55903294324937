import React from 'react'

import InputComponent from 'components/Input'
import Select from 'components/Select'
import Text from 'components/Text'
import Upload from 'components/Upload'
import Dropzone from 'components/Dropzone'

import * as s from './styles'

function Input({ ...props }) {
  return <InputComponent {...props} theme='bordered' />
}

function InputWithActionButton({ field, value, children, idx, ...props }) {
  const { placeholder, ...f } = field
  return (
    <s.FieldGroup>
      <Input {...f} placeholder={`${placeholder} (${idx + 1})`} value={value} />
      <s.ActionButton {...props} type='button'>
        {children}
      </s.ActionButton>
    </s.FieldGroup>
  )
}

function InputVariations({ field, addNewField, removeField }) {
  if (field.add_more_fields) {
    const { value = [''] } = field
    const fields = value.map((value, i) => {
      if (i === 0) {
        return {
          field,
          value,
          children: '+',
          onClick: () => addNewField(field),
        }
      }
      return {
        field,
        value,
        children: '-',
        background: 'danger',
        onClick: () => removeField(field, i),
      }
    })
    return fields.map((f, i) => (
      <InputWithActionButton
        key={`input_variations_${i}`}
        field={{ ...f.field, onChange: (e) => field.onChange(e, i) }}
        value={f.value}
        onClick={f.onClick}
        background={f.background}
        idx={i}>
        {f.children}
      </InputWithActionButton>
    ))
  }

  return <Input {...field} />
}

export default function ({ field, ...props }) {
  if (!field) {
    return null
  }

  if (!field.editable) {
    const isOption = field.options && field.options.length > 0
    return (
      <s.FieldNotEditable>
        <Text color='primary'>
          <b>{field.placeholder}</b>
        </Text>
        <Text>
          {isOption
            ? (field.options.find((o) => o.value === field.value) || {}).label
            : field.value}
        </Text>
      </s.FieldNotEditable>
    )
  }

  switch (field.component) {
    case 'INPUT':
      return <InputVariations field={field} {...props} />
    case 'SELECT':
      return <Select {...field} />
    case 'MULTISELECT':
      return <Select multiple {...field} />
    case 'DROPZONE':
      return <Dropzone
        label={field.placeholder}
        onChange={(urls) => handleFilesOnchange(urls, field)}
        onUpload={urls => handleFilesOnchange(urls, field)} />
    case 'UPLOAD': {
      return <Upload
        label={field.placeholder}
        onChange={(file) => handleFilesOnchange("", field)}
        onUpload={url => handleFilesOnchange(url, field)}
      />
    }
    default:
      return null
  }
}

const handleFilesOnchange = (value, field) => {
  const e = {
    preventDefault: () => { },
    target: { name: field.name, value }
  }

  if (field.onChange) {
    field.onChange(e)
  }
}
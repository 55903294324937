import React from 'react'
import { colors } from 'styles'

export default function ({ color, ...props }) {
  const fill = colors[color] || colors.defaultSVG
  return (
    <svg {...props} width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9083 2.01321C15.1213 -0.671071 10.5828 -0.671071 7.79883 2.01321L0.0769505 9.44485C0.0266547 9.4933 2.73517e-05 9.55884 2.73517e-05 9.62723C2.73517e-05 9.69562 0.0266547 9.76116 0.0769505 9.8096L1.16866 10.8611C1.21857 10.9089 1.28612 10.9358 1.35653 10.9358C1.42695 10.9358 1.4945 10.9089 1.5444 10.8611L9.26628 3.42944C10.2249 2.50618 11.5 1.99896 12.855 1.99896C14.2101 1.99896 15.4852 2.50618 16.4408 3.42944C17.3994 4.3527 17.926 5.58086 17.926 6.8831C17.926 8.1882 17.3994 9.41351 16.4408 10.3368L8.57102 17.9137L7.29587 19.1419C6.10357 20.2903 4.1657 20.2903 2.97339 19.1419C2.39647 18.5862 2.07991 17.8482 2.07991 17.0617C2.07991 16.2752 2.39647 15.5372 2.97339 14.9815L10.7811 7.46441C10.9793 7.27634 11.2397 7.17091 11.5178 7.17091H11.5207C11.7988 7.17091 12.0562 7.27634 12.2515 7.46441C12.4497 7.65533 12.5562 7.90609 12.5562 8.17395C12.5562 8.43896 12.4467 8.68972 12.2515 8.87779L5.86984 15.0186C5.81954 15.067 5.79292 15.1326 5.79292 15.201C5.79292 15.2693 5.81954 15.3349 5.86984 15.3833L6.96155 16.4348C7.01145 16.4827 7.07901 16.5095 7.14942 16.5095C7.21984 16.5095 7.28739 16.4827 7.33729 16.4348L13.716 10.2912C14.3047 9.72411 14.6272 8.97183 14.6272 8.1711C14.6272 7.37038 14.3018 6.61524 13.716 6.05103C12.5 4.87986 10.5237 4.88271 9.3077 6.05103L8.55031 6.78337L1.50298 13.5682C1.02467 14.0261 0.645525 14.571 0.387528 15.1712C0.12953 15.7714 -0.00218246 16.415 2.73517e-05 17.0646C2.73517e-05 18.3839 0.535529 19.6235 1.50298 20.5553C2.50594 21.5184 3.81955 22 5.13315 22C6.44676 22 7.76037 21.5184 8.76037 20.5553L17.9083 11.7501C19.2544 10.4507 20 8.72107 20 6.8831C20.0029 5.04229 19.2574 3.31261 17.9083 2.01321Z" fill={fill} />
    </svg>

  )
}

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  weConnect: {
    id: 'registerName.text.weConnect',
    defaultMessage: 'Conectamos Produtores Rurais a Compradores Globais',
  },
  needName: {
    id: 'registerName.text.needName',
    defaultMessage: 'Primeiro precisamos do seu',
  },
  fullName: {
    id: 'registerName.input.fullName',
    defaultMessage: 'Nome Completo',
  },
  step: {
    id: 'registerName.text.step',
    defaultMessage: 'etapa 1 de 5',
  },
  back: {
    id: 'registerName.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'registerName.button.continue',
    defaultMessage: 'Continuar',
  },
})

export default messages

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { routes } from 'configuration'

import Farmers from 'views/Management/Farmers'
import Buyers from 'views/Management/Buyers'
import Negotiation from 'views/Management/Negotiation'
import Match from 'views/Management/Match'

const ManagementRoutes = () => {
  return (
    <Switch>
      <Route path={routes.admin.farmers}>
        <Farmers />
      </Route>
      <Route path={routes.admin.buyers}>
        <Buyers />
      </Route>
      <Route path={routes.admin.negotiation}>
        <Negotiation />
      </Route>
      <Route path={routes.admin.match}>
        <Match />
      </Route>
    </Switch>
  )
}

export default ManagementRoutes

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'form.required.fileds',
    defaultMessage: 'Os campos com * são obrigatórios'
  },
  employeesNumber: {
    id: 'companyData.input.employeesNumber',
    defaultMessage: 'Número de funcionários *',
  },
  salesAnnually: {
    id: 'companyData.input.salesAnnually',
    defaultMessage: 'Volume de vendas anual',
  },
  dontWorry: {
    id: 'companyData.text.dontWorry',
    defaultMessage:
      'Não se preocupe. Seus dados estão seguros e são confidenciais',
  },
  buysInMarkets: {
    id: 'companyData.text.buysInMarkets',
    defaultMessage: 'Realiza compras em mercado:',
  },
  intern: {
    id: 'companyData.text.intern',
    defaultMessage: 'Interno',
  },
  extern: {
    id: 'companyData.text.extern',
    defaultMessage: 'Externo',
  },
  hybrid: {
    id: 'companyData.text.hybrid',
    defaultMessage: 'Híbrido',
  },
  mainMarkets: {
    id: 'companyData.input.mainMarkets',
    defaultMessage: 'Principais mercados',
  },
  mainMarketsPlaceholder: {
    id: 'companyData.input.mainMarketsPlaceholder',
    defaultMessage: 'Digite as regiões do mundo em que atua',
  },
  step: {
    id: 'companyMoreData.text.step',
    defaultMessage: 'etapa 4 de 9',
  },
  back: {
    id: 'companyData.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'companyData.button.continue',
    defaultMessage: 'Continuar',
  },
})

export default messages

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Card, Content, Header, Cards, Infos } from './styles'
import moment from 'moment'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'

import ScheduleModal from './ScheduleModal'
import { useFarm } from 'modules/data/hooks/useFarm'
import { useProduct } from 'modules/data/hooks/useProduct'
import SVG from 'components/SVG'
import { status as farmStatus } from 'modules/data/consts/farm'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default function InspectionHome(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { all } = useFarm()
  const { all: allProducts = [] } = useProduct()

  const handleClose = () => {}
  const filter = (farm) => {
    switch (props.filter) {
      case 'all':
        return (
          farmStatus.STATUS_WAITING_FOR_INSPECTION in farm.status &&
          [
            farmStatus.STATUS_APPROVED,
            farmStatus.STATUS_REPROVED,
            farmStatus.STATUS_WAITING_FOR_VISIT
          ].some((status) => status in farm.status) === false
        )
      case 'schedule':
        return (
          farmStatus.STATUS_WAITING_FOR_VISIT in farm.status &&
          [farmStatus.STATUS_APPROVED, farmStatus.STATUS_REPROVED].some(
            (status) => status in farm.status
          ) === false
        )
      case 'review':
      default:
        return (
          [farmStatus.STATUS_APPROVED, farmStatus.STATUS_REPROVED].some(
            (status) => status in farm.status
          ) === true
        )
    }
  }
  return (
    <Container>
      <Content>
        {all
          ? all
              .filter((farm) => filter(farm))
              .map((farm) => {
                if (farm.users[0].profile) {
                  const turnName = ['turno', 'da manhã', 'da tarde']
                  let scheduleDetails = { week: '', turn: '' }
                  let now = moment()
                  let scheduleWeek = ''
                  let weeks = 0
                  if (
                    farm.status[farmStatus.STATUS_WAITING_FOR_INSPECTION] !=
                    null
                  ) {
                    scheduleDetails =
                      props.filter === 'all'
                        ? farm.status[farmStatus.STATUS_WAITING_FOR_INSPECTION]
                        : farm.status[farmStatus.STATUS_WAITING_FOR_VISIT]
                    scheduleWeek = moment(scheduleDetails.week.replace('-', ''))
                    weeks = scheduleWeek.diff(now, 'week')
                  }
                  return (
                    <Card>
                      <Content>
                        <Header>
                          <SVG.Farm
                            height='10%'
                            width='30%'
                            viewBox='-10 -5 60 40'
                          />
                          <Infos>
                            <div id='header-card'>
                              <div id='farm-info'>
                                <strong>{farm.name}</strong>
                                <span>CNPJ: {farm.cnpj}</span>
                                <p>
                                  {farm.city}, {farm.state}
                                </p>
                              </div>
                            </div>

                            <div id='description'>
                              <p className='item'>
                                {farm.users[0].profile.name}
                              </p>
                              <p className='item'>
                                CPF: {farm.users[0].profile.cpf}
                              </p>
                              <p className='item'>
                                Telefone: {farm.users[0].profile.phone}
                              </p>
                            </div>

                            {props.filter === 'all' ? (
                              <div id='schedule'>
                                <strong>Período da Visita: </strong>
                                <span>
                                  {weeks > 1
                                    ? `Daqui ${weeks} semanas`
                                    : 'Nos próximos dias'}{' '}
                                  no turno {turnName[scheduleDetails.turn]}
                                </span>
                                <button onClick={(e) => setOpen(farm.id)}>
                                  Agendar
                                </button>
                              </div>
                            ) : props.filter === 'schedule' ? (
                              <div id='schedule'>
                                <strong>Data da Visita: </strong>
                                <span>
                                  {moment(
                                    farm.status[
                                      farmStatus.STATUS_WAITING_FOR_VISIT
                                    ].week
                                  ).format('DD/MM/YYYY')}{' '}
                                  no turno {turnName[scheduleDetails.turn]}
                                </span>
                                <div className='button-list'>
                                  <Link
                                    to={
                                      '/platform/inspection?farm=' +
                                      farm.id +
                                      '&id=' +
                                      farm.status[
                                        farmStatus.STATUS_WAITING_FOR_VISIT
                                      ].week
                                    }>
                                    <button>Enviar Relatório</button>
                                  </Link>
                                  <button
                                    className='cancel'
                                    onClick={(e) => setOpen(farm.id)}>
                                    Reagendar
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div id='schedule'>
                                <strong>Resultado da visita: </strong>
                                {farmStatus.STATUS_APPROVED in farm.status ? 'Aprovada' : 'Reprovada'}
                              </div>
                            )}
                          </Infos>
                        </Header>
                        <Cards>
                          {allProducts
                            ? allProducts
                                .filter((product) =>
                                  farm.productList.some(
                                    (farmProduct) =>
                                      farmProduct.id === product.id
                                  )
                                )
                                .map((product) => (
                                  <div className='card active'>
                                    <img src={product.url} alt='product' />
                                    <p>{product.name}</p>
                                  </div>
                                ))
                            : ''}
                        </Cards>
                      </Content>
                      <Modal
                        aria-labelledby='transition-modal-title'
                        aria-describedby='transition-modal-description'
                        className={classes.modal}
                        open={open === farm.id}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}>
                        <Fade in={open}>
                          <ScheduleModal
                            close={(event) => setOpen(false)}
                            week={scheduleWeek}
                            turn={
                              farm.status[
                                farmStatus.STATUS_WAITING_FOR_INSPECTION
                              ].turn
                            }
                            name={farm.name}
                            id={farm.id}
                          />
                        </Fade>
                      </Modal>
                    </Card>
                  )
                } else return ''
              })
          : ''}
      </Content>
    </Container>
  )
}

import React from 'react'
import ResetPassword from './ResetPassword'
import VerifyEmail from './VerifyEmail'
import { useQuery } from 'modules/data/hooks/useQuery'

export default function ConfirmPassword() {
  const query = useQuery()

  const code = query.get('oobCode')
  const mode = query.get('mode')

  return ( 
    <div>
    { mode === 'resetPassword' ?
        <ResetPassword
          code={code}
        />
        : mode === 'verifyEmail' ?
          <VerifyEmail
            code={code}
          /> : ''
    } 
    </div>)
}

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'welcomeBuyer.title',
    defaultMessage: 'Olá Comprador(a) Global!',
  },
  happy: {
    id: 'welcomeBuyer.text.happy',
    defaultMessage:
      'Ficamos muito felizes com o seu interesse em fazer parte da <strong> Agricon!</strong>',
  },
  oportunity: {
    id: 'welcomeBuyer.text.oportunity',
    defaultMessage:
      'Aqui você tem a oportunidade de <strong> comprar produtos de diversos produtores rurais.</strong>',
  },
  informations: {
    id: 'welcomeBuyer.text.informations',
    defaultMessage:
      'Cadastre suas informações para poder ter acesso a plataforma. Fique tranquilo(a), seus dados são confidenciais e de uso interno.',
  },
  begin: {
    id: 'welcomeBuyer.button.begin',
    defaultMessage: 'Iniciar',
  },
  continue: {
    id: 'welcomeBuyer.button.continue',
    defaultMessage: 'Retomar',
  },
  registration: {
    id: 'welcomeBuyer.button.registration',
    defaultMessage: 'cadastro',
  },
})

export default messages

import React from 'react'
import { CardContainer, Card, CardTitle, Container } from './styles'
import { useHistory } from 'react-router-dom'
import WelcomeBuyerModal from 'components/WelcomeBuyerModal'
import { useUser } from 'modules/data/hooks/useUser'
import routes from 'configuration/routes'
import SVGObject from 'components/SVG/Object'
import { Counters } from '../Farmer/styles'
import Counter from 'components/Counter'
import { useLOI } from 'modules/data/hooks/useLOI'
import messages from './messages'
import { useIntl } from 'react-intl'
import { colors } from 'styles'

export default function BuyerHome() {
  const history = useHistory()
  const user = useUser()
  const { mine } = useLOI()
  const { formatMessage } = useIntl()

  if (user.loaded && (!user.user.phone || !user.user.phone.verified)) {
    history.push(routes.buyer.verifyPhone)
  }

  return (
    <Container>
      {user.user.enabled ? (
        <CardContainer>
          <Card>
            <SVGObject svg={'WheatBag'} />
            <CardTitle>{formatMessage(messages.title)}</CardTitle>
            <Counters>
              <Counter
                color={colors.bostonBlue}
                number={mine ? mine.length : 0}
                text={formatMessage(messages.activeCounter)}
              />
              <Counter
                color={'#47AC34'}
                number={mine ? mine.reduce((acc, value) => {
                  if (value && value.sold) {
                    return acc + 1
                  } else {
                    return acc
                  }
                }, 0) : 0}
                text={formatMessage(messages.originatedCounter)}
              />
            </Counters>
          </Card>
        </CardContainer>
      ) : (
        <WelcomeBuyerModal />
      )}
    </Container>
  )
}

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'confirmEmailFarmer.title',
    defaultMessage: 'Falta pouco!',
  },
  subTitle: {
    id: 'confirmEmailFarmer.text.subTitle',
    defaultMessage: 'Agora só precisamos que você crie seus dados de acesso.',
  },
  email: {
    id: 'confirmEmailFarmer.input.email',
    defaultMessage: 'E-mail',
  },
  confirmEmail: {
    id: 'confirmEmailFarmer.input.confirmEmail',
    defaultMessage: 'Confirmar E-mail',
  },
  step: {
    id: 'confirmEmailFarmer.text.step',
    defaultMessage: 'etapa 3 de 5',
  },
  back: {
    id: 'confirmEmailFarmer.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'confirmEmailFarmer.button.continue',
    defaultMessage: 'Continuar',
  },
  error: {
    id: 'confirmEmailFarmer.text.error',
    defaultMessage: 'E-mail inválido!',
  },
  warning: {
    id: 'confirmEmailFarmer.text.warning',
    defaultMessage: 'Os e-mails devem ser iguais!',
  },
})

export default messages

import React from 'react'

import * as s from './styles'
import * as shared from '../styles-shared'

export default function Select({
  options,
  placeholder,
  placeholderAlign,
  value,
  type,
  arrowColor = 'black',
  ...props
}) {
  const hasValue = !!value

  return (
    <s.SelectStyled hasValue={hasValue} {...props}>
      {!!placeholder && (
        <s.Label placeholderAlign={placeholderAlign}>
          {placeholder}
          {props.required && <shared.Required>*</shared.Required>}
        </s.Label>
      )}
      <div>
        <input type={type || 'text'} value={value} {...props} />
      </div>
    </s.SelectStyled>
  )
}

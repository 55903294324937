import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'form.required.fileds',
    defaultMessage: 'Os campos com * são obrigatórios'
  },
  cellphone: {
    id: 'registerPhone.input.cellphone',
    defaultMessage: 'Telefone',
  },
  smsWillBeSent: {
    id: 'registerPhone.text.smsWillBeSent',
    defaultMessage: 'Será enviado um SMS para validar seu número. Não se preocupe, nenhuma taxa será cobrada.',
  },
  step: {
    id: 'registerPhone.text.step',
    defaultMessage: 'etapa 8 de 9',
  },
  back: {
    id: 'registerPhone.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'registerPhone.button.continue',
    defaultMessage: 'Continuar',
  },
  invalid: {
    id: 'registerPhone.text.invalid',
    defaultMessage: 'Senha inválida!',
  },
  equal: {
    id: 'registerPhone.text.equal',
    defaultMessage: 'As senhas devem ser iguais!',
  },
  size: {
    id: 'registerPhone.text.size',
    defaultMessage: 'A senha deve ter 6 dígitos!',
  },
})

export default messages

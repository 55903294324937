import { getFirebase } from 'react-redux-firebase'
import { LOI_COLLECTION, PRODUCT_COLLECTION } from '../consts/collections'
import { getParams, userHasRole } from './helper'

export const create = async (dispatch, getState, data) => {
  const firebase = getFirebase()

  const params = await getParams('negotiation')

  if (!userHasRole(['buyer', 'admin'])) {
    dispatch({
      type: 'loi/create/error',
      error: { 
        code: 'no-permission',
        message: 'User has no permission to create loi' 
      },
    })
    return
  }

  return firebase.firestore().collection(LOI_COLLECTION)
    .add({
      ...data,
      product: firebase.firestore().collection(PRODUCT_COLLECTION).doc(data.product.id),
      expiration: params.loiExpirationDays,
      createdBy: firebase.auth().currentUser.uid,
      status: 'originatingOffer',
      statusHistory: []
    })
    .then(async (doc) => {
      await firebase.firestore().collection(doc.ref.path).collection('status')
        .doc('open')
        .set({
          date: firebase.firestore.Timestamp.fromDate(new Date()),
          user: firebase.auth().currentUser.uid,
          status: 'open',
        })
      dispatch({ type: 'loi/created', data: doc })
    })
    .catch((error) => {
      dispatch({ type: 'farm/create/error', error })
    })
}

export const setStatus = async (dispatch, getState, loiId, status) => {
  const firebase = getFirebase()
  if (!userHasRole(['buyer', 'admin', 'farmer'])) {
    dispatch({
      type: 'loi/status/error',
      error: { 
        code: 'no-permission',
        message: 'User has no permission to change status' 
      },
    })
    return
  }

  return firebase.firestore().collection(LOI_COLLECTION).doc(loiId)
    .collection('statusHistory').doc(status)
    .set({
      date: firebase.firestore.Timestamp.fromDate(new Date()),
      user: firebase.auth().currentUser.uid,
      status: status,
    })
    .then((data) => {
      firebase.firestore().collection(LOI_COLLECTION).doc(loiId).update({status: status})
      dispatch({ type: 'loi/status/changed', status })
    })
    .catch((error) => {
      dispatch({ type: 'loi/status/error', error })
    })
}

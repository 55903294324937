import styled from 'styled-components';
import { colors } from "styles"

export const Container = styled.div`
  margin-top: 50px;
  width: 40vw;

  background: ${colors.white};
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.31);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  padding: 50px 80px 80px;
  justify-content: space-between;
  align-items: center;

  img {
    max-width: 250px;
  }

  @media only screen and (max-width: 590px) {
    padding: 10px 20px 20px;
    min-height: 456px;
    img {
      width: 192px;
    }
  }
`;

export const Title = styled.p`
  font-size: 30px;
  text-align: center;
  margin-top: 40px;

  @media only screen and (max-width: 590px) {
    font-size: 30px;
    text-align: center;
  }

  & b {
    color: ${colors.apple};
  }
`;

export const Info = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
  margin-top: 30px;

  @media only screen and (max-width: 590px) {
    font-size: 18px;
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white2};
  border: 1px solid ${colors.alto4};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;

  & > svg {
    margin-right: 30px;
  }

  & > p {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }
`;

export const Close = styled.p`
  text-decoration-line: underline;
  font-size: 18px;
  line-height: 21px;
  border: none;
  background: none;
  cursor: pointer;
`
import { getFirebase } from 'react-redux-firebase'
import { BUYER_COLLECTION as COLLECTION_NAME, USER_COLLECTION } from '../consts/collections'
import { updateDoc } from './helper'

export const create = (dispatch, getState) => {
  const firebase = getFirebase()

  dispatch({type: 'buyer/create', loginType: 'anonymous' })
  return firebase.auth().signInAnonymously().then(async (login) => {
      const when = firebase.firestore.Timestamp.fromDate(new Date())
      const payload = {
        uid: login.user.uid,
        roles: {
          buyer: {
            date: when,
          },
        },
        enabled: false
      }
      await firebase.firestore().collection(USER_COLLECTION).doc(login.user.uid)
        .set(payload)
        .then( data => dispatch({ type: 'buyer/created', data: payload}))


      // const payload = {
      //   user: login.user,
      //   roles: {
      //     buyer: {
      //       date: when,
      //     },
      //   }
      // }
      // updateDoc(dispatch, USER_COLLECTION, login.user.uid, payload, 'user/roles/update', 'user/roles/updated', 'user/roles/error');
      // dispatch({
      //   type: 'farmer/created',
      //   data: payload,
      // })

      // await firebase
      //   .firestore()
      //   .collection('users')
      //   .doc(login.user.uid)
      //   .set({
      //     uid: login.user.uid,
      //     roles: {
      //       buyer: {
      //         date: when,
      //       },
      //     },
      //     enabled: false
      //   })
    })
    .catch(function (error) {
      return dispatch({ type: 'buyer/create/error', error: error })
    })
}

export const addFeature = (dispatch, getState, data) => updateDoc(dispatch, COLLECTION_NAME, getFirebase().auth().currentUser.uid, data, 'buyer/add/feature', 'buyer/add/feature/added', 'buyer/add/feature/error')

export const acceptTerms = (dispatch, getState, version) => {
  const firebase = getFirebase()

  const terms = {
    date: firebase.firestore.Timestamp.fromDate(new Date()),
    version: firebase.firestore().collection('terms').doc(version),
  }

  updateDoc(dispatch, COLLECTION_NAME, firebase.auth().currentUser.uid, { terms }, 'buyer/terms/accept', 'buyer/terms/accepted', 'buyer/terms/error')
}

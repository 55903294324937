import styled, { css } from 'styled-components'
import { colors, fonts } from 'styles'

export const MultipleLabel = styled.span`
  font-size: 15px;
  padding-left: 15px;

  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const multipleStyle = css`
  ${({ multiple }) =>
    multiple &&
    css`
      display: ${({ visible }) => (visible ? 'inline-block' : 'none')};

      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      background: ${colors.white};
      z-index: 10;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background: ${({ background }) => colors[background] || colors.white};
      border: 1px solid ${colors.silver};
      padding: 0;

      & > option {
        padding: 5px;
        border-bottom: 1px solid ${colors.silver};
      }
    `}
`

const selectThemes = {
  default: css`
    position: relative;
    margin: 0;
    font-family: inherit;
    min-height: min-content;
    max-width: ${({ maxWidth }) => maxWidth || 'none'};
    display: flex;
    align-items: flex-end;
    flex-grow: 1;

    ${({ hasValue }) =>
      hasValue &&
      css`
        height: 90px;
      `}

    & > div {
      position: relative;
      display: flex;
      align-items: center;
      height: ${({ height }) => height || '40px'};
      width: 100%;
      min-width: ${({ minWidth }) => minWidth || '240px'};
      max-width: ${({ maxWidth }) => maxWidth || 'none'};
      background: ${({ background }) => colors[background] || 'transparent'};
      border-radius: ${({ borderRadius }) => borderRadius || '0px'};

      border: 0;
      border-bottom: 2px solid
        ${({ borderColor }) => colors[borderColor] || colors.alto};

      & > svg {
        position: absolute;
        right: 10px;
        pointer-events: none;
        width: 20px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }

    & select {
      display: inline-block;
      padding: 0.5rem 1rem;
      font-family: ${fonts.primary};
      font-size: ${({ fontSize }) => fontSize || '14px'};
      outline: none;
      line-height: 1.2;
      color: ${colors.greyDark};
      appearance: none;
      background: ${({ background }) => colors[background] || 'transparent'};
      border: none;
      width: 100%;

      ${multipleStyle}
    }
  `,
  bordered: css`
    position: relative;
    display: inline-block;
    margin: 0;
    font-family: inherit;
    min-height: min-content;
    max-width: ${({ maxWidth }) => maxWidth || 'none'};
    width: ${({ labelWidth }) => labelWidth || 'unset'};

    & > div {
      position: relative;
      display: flex;
      align-items: center;
      height: ${({ height }) => height || '40px'};
      width: 100%;
      min-width: ${({ minWidth }) => minWidth || '240px'};
      max-width: ${({ maxWidth }) => maxWidth || 'none'};
      background: ${({ background }) => colors[background] || 'transparent'};
      border-radius: ${({ borderRadius }) => borderRadius || '5px'};

      margin: 5px 0;

      /* border: 1px solid
        ${({ borderColor }) => colors[borderColor] || colors.silver}; */

      & > svg {
        position: absolute;
        right: 10px;
        pointer-events: none;
        width: 20px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }

    & select {
      display: inline-block;
      padding: 0.5rem 1rem;
      font-family: ${fonts.primary};
      font-size: ${({ fontSize }) => fontSize || '14px'};
      outline: none;
      line-height: 1.2;
      color: ${colors.greyDark};
      appearance: none;
      background: ${({ background }) => colors[background] || 'transparent'};
      border: none;
      width: 100%;

      ${multipleStyle}
    }
  `,
}

export const SelectStyled = styled.label`
  ${({ theme }) => selectThemes[theme] || selectThemes.bordered}
  margin-bottom: ${({ marginBottom }) => marginBottom || 'unset'};
`

const labelThemes = {
  default: css`
    font-family: ${fonts.primary};
    color: ${colors.apple};
    font-size: 20px;
    font-weight: 500;
    text-align: ${({ placeholderAlign }) => placeholderAlign || 'left'};
    width: 100%;
    margin-bottom: 5px;

    position: absolute;
    top: 8px;
    /* background: ${colors.white}; */
    width: auto;
    padding: 0 2px;
    transition: transform 150ms ease-out, font-size 150ms ease-out;

    ${({ focus, hasValue }) =>
      (focus || hasValue) &&
      css`
        transform: translateY(-30%);
      `}
  `,
  bordered: css`
    font-family: ${fonts.primary};
    color: ${colors.boulder};
    font-size: 18px;
    text-align: ${({ placeholderAlign }) => placeholderAlign || 'left'};
    width: 100%;
    margin-bottom: 10px;
    display: block;
  `,
}

export const Label = styled.span`
  ${({ theme }) => labelThemes[theme] || labelThemes.bordered}

  & .question-mark {
    padding: 0 !important;
    min-width: auto !important;

    & svg {
      width: 17px;
      height: 17px;
      margin-left: 10px;
    }
  }
`

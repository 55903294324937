import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Examples from 'views/Examples'
import SignIn from 'views/SignIn'
import RecoverPassword from 'views/RecoverPassword'
import ValidateNumber from 'views/ValidateNumber'
import VerifyEmail from 'views/VerifyEmail'
import WelcomeRegistration from 'views/WelcomeRegistration'
import Account from  'views/Account'

import { routes } from 'configuration'

export default function InitialRoutes() {
  return (
    <Switch>
      <Route path={routes.examples}>
        <Examples />
      </Route>
      <Route exact path={routes.signin}>
        <SignIn />
      </Route>
      <Route path={routes.recoverPassword}>
        <RecoverPassword />
      </Route>
      <Route path={routes.validateNumber}>
        <ValidateNumber />
      </Route>
      <Route path={routes.verifyEmail}>
        <VerifyEmail />
      </Route>
      <Route path={routes.welcomeRegistration}>
        <WelcomeRegistration />
      </Route>
      <Route path={routes.account}>
        <Account />
      </Route>
    </Switch>
  )
}

import React from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  VerifyCode,
} from './styles'
import Title from 'components/Title'
import Text from 'components/Text'
// import Input from "components/Input";
import logo from 'assets/logo.svg'
import phoneIcon from 'assets/phone.svg'
import { Link } from 'react-router-dom'
import ReactCodeInput from 'react-verification-code-input'
import messages from './messages'
import { useIntl } from 'react-intl'

export default function ValidateNumber() {

  const { formatMessage } = useIntl()
  return (
    <Container>
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <img src={phoneIcon} alt='Phone' />
          <Content>
            <Title className='title' color='primary'>
              {formatMessage(messages.title)}
            </Title>
            <Text className='info'>{formatMessage(messages.info)}</Text>
            <VerifyCode>
              <ReactCodeInput fields={5} />
            </VerifyCode>
            <Footer>
              <Link className='resend'>
                <Text color='secondary'>{formatMessage(messages.resend)}</Text>
              </Link>
              <Link to='/recover-password'>
                <Text color='primary'>{formatMessage(messages.back)}</Text>
              </Link>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

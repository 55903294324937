import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from '../styles'
import Input from 'components/Input'
import Text from 'components/Text'
import Button from 'components/Button'
import logo from 'assets/logo-green.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { Map, GoogleApiWrapper } from 'google-maps-react'
import { useIntl } from 'react-intl'
import messages from './messages'
import { toast }  from 'react-toastify'
import { maps } from 'modules/data/consts/apiTokens'

function CompanyData(props) {
  const history = useHistory()
  const [place, setPlace] = useState('')
  const [country, setCountry] = useState('')
  const [state, setState] = useState('')
  const [city, setCity] = useState('')
  const [cnpj, setCnpj] = useState('')
  const { addFeature, buyer } = useBuyer()
  const [mapService, setMapService] = useState({})
  const [placeList, setPlaceList] = useState([])
  const { formatMessage } = useIntl()

  useEffect( () => {
  	if(buyer && buyer.city) {
  		setCity(buyer.city)
  		setState(buyer.state)
  		setCountry(buyer.country)
  		setCnpj(buyer.cnpj)
  		setPlace(`${buyer.city}, ${buyer.state} - ${buyer.country}`)
  	}
  }, [ buyer ])

  const handleNextStep = () => {
    if(!place || !cnpj) {
      toast.error(formatMessage(messages.error))
      return
    }
    addFeature({
      country,
      state,
      city,
      cnpj,
    })
    history.push('/buyer-step-name')
  }

	const initServices = (mapProps, map) => 
		setMapService({
			map,
			service: new props.google.maps.places.PlacesService(map),
	})

	const search = city => {
		setPlace(city.value)
		if(city.value.length < 4) return;
		const { google } = props
		const request = {
			query: city.value,
			fields: ['formatted_address', 'types', 'geometry'],
		}

		mapService.service.textSearch(
			request,
			function (results, status) {
			  if (status === google.maps.places.PlacesServiceStatus.OK) {
			    setPlaceList(results)
			  }
			}
		)
	}

	const chooseCity = place => {
		setPlace(place.formatted_address);
		setPlaceList([]);

		const geocoder = new props.google.maps.Geocoder()
    geocoder.geocode({ location: place.geometry.location }, (results, status) => {
      if (status === 'OK') {
        results[0].address_components.forEach((result) => {
          if (result.types.includes('administrative_area_level_2'))
            setCity(result.long_name)
          if (result.types.includes('administrative_area_level_1'))
            setState(result.long_name)
          if (result.types.includes('country')) setCountry(result.long_name)
        })
      }
    })
	}

	let itemList = placeList
		.filter(place => place.types.includes('locality') || place.types.includes('administrative_area_level_2'))
		.map(place => {
		return (
			<li 
				onClick={e => chooseCity(place)}
				className="city-option"
			>
				{place.formatted_address}
			</li>
		)
	})

  return (
    <Container>
      <div className='bg-image' />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Text className='info' innerHtml={formatMessage(messages.info)} />
          <Content>
            <div className='fields'>
              <div className='location'>
                <Input
                  placeholder={formatMessage(messages.city)}
                  value={place}
                  onChange={({ target }) => search(target)}
                />
                <ul className='city-list'>{itemList}</ul>
              </div>
              <Input
                placeholder={formatMessage(messages.identification)}
                value={cnpj}
                onChange={({ target }) => setCnpj(target.value)}
              />
            </div>
            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to='/welcome-buyer'>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to='/welcome-buyer'>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
        <Map
          google={props.google}
          onReady={initServices}
          visible={false}
          zoom={8}
          centerAroundCurrentLocation={true}
        />
      </FormContainer>
    </Container>
  )
}


export default GoogleApiWrapper({
  apiKey: maps.key,
})(CompanyData)
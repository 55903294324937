import React from 'react'
import { Route, Switch } from 'react-router-dom'
import WelcomeBuyer from 'views/Buyer/WelcomeBuyer'
import CompanyName from 'views/Buyer/CompanyName'
import CompanyData from 'views/Buyer/CompanyData'
import CompanyDetails from 'views/Buyer/CompanyDetails'
import CompanyMoreDetails from 'views/Buyer/CompanyMoreDetails'
import CompanyFinishingDetails from 'views/Buyer/CompanyFinishingDetails'
import ConfirmEmail from 'views/Buyer/ConfirmEmail'
import ConfirmPassword from 'views/Buyer/ConfirmPassword'
import RegisterPhone from 'views/Buyer/RegisterPhone'
import VerifyPhone from 'views/Buyer/VerifyPhone'
import Terms from 'views/Buyer/Terms'
import Done from 'views/Buyer/Done'

import { routes } from 'configuration'

export default function BuyerRoutes() {
  return (
    <Switch>
      <Route path={routes.buyer.welcome}>
        <WelcomeBuyer />
      </Route>
      <Route path={routes.buyer.registerCompanyName}>
        <CompanyName />
      </Route>
      <Route path={routes.buyer.registerCompanyData}>
        <CompanyData />
      </Route>
      <Route path={routes.buyer.registerCompanyDetails}>
        <CompanyDetails />
      </Route>
      <Route path={routes.buyer.registerCompanyMoreDetails}>
        <CompanyMoreDetails />
      </Route>
      <Route path={routes.buyer.registerCompanyFinishingDetails}>
        <CompanyFinishingDetails />
      </Route>
      <Route path={routes.buyer.confirmEmail}>
        <ConfirmEmail />
      </Route>
      <Route path={routes.buyer.confirmPassword}>
        <ConfirmPassword />
      </Route>
      <Route path={routes.buyer.registerPhone}>
        <RegisterPhone />
      </Route>
      <Route path={routes.buyer.verifyPhone}>
        <VerifyPhone />
      </Route>
      <Route path={routes.buyer.terms}>
        <Terms />
      </Route>
      <Route path={routes.buyer.done}>
        <Done />
      </Route>
    </Switch>
  )
}

import styled from 'styled-components'

import { Drawer as DrawerMui } from '@material-ui/core'
import { colors, device } from 'styles'

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media ${device.untilTablet} {
    position: fixed;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.17);
    margin: 0;
    padding: 12px;
    top: 100px;
    left: -10px;
    justify-content: center;
    align-items: center;
    z-index: 9;
  }
`

export const PageDescription = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.untilTablet} {
    flex-direction: row;
  }

  & > :not(:last-child) {
    margin-bottom: 5px;
    @media ${device.untilTablet} {
      margin-right: 5px;
      margin-bottom: 0;
      font-size: 16px;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  overflow: scroll;
  margin-bottom: 30px;

  @media ${device.untilTablet} {
    margin-top: 70px;
    margin-bottom: 150px;
    padding: 30px 12px 30px 17px;
    overflow: hidden;
  }
`

export const Content = styled.div`
  display: block;
  width: 100%;
  height: 100%;

  & > p {
    margin: 80px 0 50px !important;
  }

  & > button {
    @media ${device.untilTablet} {
      position: absolute;
      bottom: 60px;
      font-size: 14px;
      width: auto;
      height: 35px;
      padding: 0 16px;
      transform: translateX(-50%);
      left: 50%;
    }
  }
`

export const NoBatches = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > p {
    margin: 80px 0 50px !important;
  }

  & > button {
    @media ${device.untilTablet} {
      position: absolute;
      bottom: 60px;
      font-size: 14px;
      width: auto;
      height: 35px;
      padding: 0 16px;
      transform: translateX(-50%);
      left: 50%;
    }
  }
`

export const TitleHint = styled.span`
  color: ${colors.silverChalice2};
  font-weight: 300;
  font-size: 16px;
`

export const Drawer = styled(DrawerMui)`
  @media ${device.untilTablet} {
    .MuiDrawer-paperAnchorRight {
      right: auto;
      width: 100%;
    }
  }

  select {
    border-radius: 6px;
    background: transparent !important;
  }
`

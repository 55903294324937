import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  height: 60px;
  background: rgba(199, 199, 199, 0.2);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
`

export const CounterNumber = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: ${(props) => props.color};
`
export const CounterText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: ${(props) => props.color};
`
import styled from 'styled-components'
import { colors, fonts } from 'styles'

export const Container = styled.div`
  background: ${colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  max-width: 243px;
  max-height: 148px;
  width: 100%;
  height: 100%;
  padding: 20px;
  margin-right: 20px;
  min-height: 150px;

  .delete {
    display: block;
    float: right;
    cursor: pointer;
  }
`
export const Title = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #565656;
  margin-bottom: 10px;
`

export const Text = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #565656;
  margin-bottom: 8px;
`

import { send, forEachFarmer } from '../logic/mailLogic'
import * as messages from 'components/MailTemplates'
import { status } from '../consts/farm'

const bcc = 'eduarda@agriconbusiness.com, victor@agriconbusiness.com, rafael@agriconbusiness.com, antonio@agriconbusiness.com, tech@agriconbusiness.com, antonio@hansens.com.br'

export default ({ getState, dispatch }) => (next) => async (action) => {
  if(!action.type) return action
  next(action)
  let type = '';
  switch (action.type) {
    case 'user/created':
      try {
        if(action.user && action.user.email) {
        	if('farmer' in action.user.roles) {
				type = 'farmer';
				send({
					to: action.user.email,
					message: {
						subject: 'AGRICON BUSINESS | Bem-Vindo(a) Produtor(a) Rural',
						html: messages.welcomeFarmer({...action.user, name: getState().farmer.name})
					}
				})
			}
			if('buyer' in action.user.roles) {
				type = 'buyer';
				send({
					to: action.user.email,
					message: {
						subject: 'AGRICON BUSINESS | Bem-Vindo(a) Comprador(a) Global',
						html: messages.welcomeBuyer({...action.user, name: getState().buyer.name})
					}
				})
			}
			
			send({
				to: 'eduarda@agriconbusiness.com, victor@agriconbusiness.com, rafael@agriconbusiness.com, antonio@agriconbusiness.com, tech@agriconbusiness.com, antonio@hansens.com.br',
				// to: 'antonio@hansens.com.br, foxga@gmail.com, compras.hansen@gmail.com',
				message: {
					subject: `Novo cadastro de ${type === 'farmer' ? 'Produtor' : 'Comprador' } na plataforma`,
					html: messages.newRegistration({...action.user, name: getState().buyer.name}, type === 'farmer' ? 'Produtor' : 'Comprador')
				}
			})
        }
      } catch(error) {
        dispatch({ type: 'system/error', error})
      }
      break
    case 'farm/status/changed':
      try {
      	if(action.payload.status[status.STATUS_WAITING_FOR_INSPECTION]){ 
      		forEachFarmer(action.docId, farmer => send({
      			to: farmer.email,
            bcc: bcc,
      			message: {
      				subject: 'AGRICON BUSINESS | Visita Técnica',
      				html: messages.inspectionRequested(farmer)
      			}
      		}))
      	}
      	if(action.payload.status[status.STATUS_WAITING_FOR_VISIT]){ 
      		forEachFarmer(action.docId, farmer => send({
      			to: farmer.email,
      			message: {
      				subject: 'AGRICON BUSINESS | Visita Técnica Confirmada',
      				html: messages.inspectionScheduled(farmer, action.payload.status[status.STATUS_WAITING_FOR_VISIT])
      			}
      		}))
      	}
      } catch(error) {
        dispatch({ type: 'system/error', error})
      }
      break
    default:
      break
  }
  return action
}
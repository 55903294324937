import React from 'react'
import { colors } from 'styles'

export default function ({ color, width, height, ...props }) {
  const fill = colors[color] || colors.defaultSVG
  return (
    <svg
      {...props}
      width={width ? width : '124'}
      height={height ? height : '124'}
      viewBox='0 0 124 124'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M62 0C27.7616 0 0 27.7616 0 62C0 96.2384 27.7616 124 62 124C96.2384 124 124 96.2384 124 62C124 27.7616 96.2384 0 62 0ZM62 113.482C33.5741 113.482 10.5179 90.4259 10.5179 62C10.5179 33.5741 33.5741 10.5179 62 10.5179C90.4259 10.5179 113.482 33.5741 113.482 62C113.482 90.4259 90.4259 113.482 62 113.482Z'
        fill={fill}
      />
      <path
        d='M86.1771 79.5203L66.4423 65.252V30.9997C66.4423 30.3908 65.9441 29.8926 65.3351 29.8926H58.6784C58.0695 29.8926 57.5713 30.3908 57.5713 30.9997V69.1131C57.5713 69.4729 57.7374 69.8051 58.028 70.0127L80.9182 86.7028C81.4164 87.0627 82.1083 86.952 82.4682 86.4676L86.4262 81.0703C86.786 80.5582 86.6753 79.8662 86.1771 79.5203Z'
        fill={fill}
      />
    </svg>
  )
}

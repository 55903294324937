import React from 'react'

export default function FileStatus({ type }) {
  if (type === 'success') {
    return (
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="13.5" fill="#47AC34" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1212 9.31953C18.327 9.11558 18.6047 9.0008 18.8944 9C19.1841 8.9992 19.4624 9.11245 19.6693 9.31526C19.8762 9.51806 19.9949 9.79413 19.9998 10.0838C20.0048 10.3734 19.8955 10.6534 19.6957 10.8631L13.827 18.1989C13.7262 18.3076 13.6045 18.3948 13.4691 18.4553C13.3338 18.5158 13.1877 18.5484 13.0394 18.5511C12.8912 18.5539 12.744 18.5267 12.6065 18.4712C12.469 18.4158 12.3442 18.3332 12.2393 18.2283L8.35093 14.3384C8.2426 14.2375 8.15571 14.1158 8.09545 13.9805C8.03519 13.8453 8.00278 13.6993 8.00017 13.5512C7.99756 13.4032 8.02479 13.2561 8.08025 13.1189C8.1357 12.9816 8.21824 12.8569 8.32294 12.7522C8.42764 12.6475 8.55235 12.5649 8.68964 12.5095C8.82693 12.454 8.97398 12.4268 9.12203 12.4294C9.27007 12.432 9.41607 12.4644 9.55132 12.5247C9.68657 12.5849 9.80829 12.6718 9.90923 12.7801L12.9876 15.8571L18.0933 9.35187C18.1024 9.34048 18.1122 9.32968 18.1227 9.31953H18.1212Z" fill="white" />
      </svg>
    )
  }

  if (type === 'loading') {
    return (
      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="13.5" fill="#F4A711" />
        <path d="M14 6C10.1344 6 7 9.13438 7 13C7 16.8656 10.1344 20 14 20C17.8656 20 21 16.8656 21 13C21 9.13438 17.8656 6 14 6ZM14 18.8125C10.7906 18.8125 8.1875 16.2094 8.1875 13C8.1875 9.79063 10.7906 7.1875 14 7.1875C17.2094 7.1875 19.8125 9.79063 19.8125 13C19.8125 16.2094 17.2094 18.8125 14 18.8125Z" fill="white" />
        <path d="M16.7297 14.9781L14.5016 13.3672V9.5C14.5016 9.43125 14.4453 9.375 14.3766 9.375H13.625C13.5562 9.375 13.5 9.43125 13.5 9.5V13.8031C13.5 13.8438 13.5187 13.8813 13.5516 13.9047L16.1359 15.7891C16.1922 15.8297 16.2703 15.8172 16.3109 15.7625L16.7578 15.1531C16.7984 15.0953 16.7859 15.0172 16.7297 14.9781Z" fill="white" />
      </svg>
    )
  }

  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13.5" cy="13.5" r="13.5" fill="#F41111" />
      <path d="M13.6602 12.1074L16.7275 7.20312H18.7139L14.6709 13.5488L18.8105 20H16.8066L13.6602 15.0078L10.4961 20H8.50098L12.6494 13.5488L8.59766 7.20312H10.5752L13.6602 12.1074Z" fill="white" />
    </svg>
  )
}

import React, { useState, useEffect } from 'react'

import * as s from './styles'
import messages from './messages'
import { useIntl } from 'react-intl'
import Address from './Address'
import { useFarmer } from 'modules/data/hooks/useFarmer'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useUser } from 'modules/data/hooks/useUser'

export default function Addresses({ toggleDrawer }) {
  const [addressList, setAddressList] = useState([])
  const { formatMessage } = useIntl()
  const farmerHook = useFarmer()
  const buyerHook = useBuyer()
  const { user } = useUser()

  useEffect(() => {
    if (user?.roles?.buyer) {
      if (buyerHook.buyer && buyerHook.buyer.addressList)
        setAddressList(buyerHook.buyer.addressList)
    } else if (user?.roles?.farmer) {
      if (farmerHook.farmer && farmerHook.farmer.addressList)
        setAddressList(farmerHook.farmer.addressList)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmerHook, buyerHook])

  const addresses =
    (user?.roles?.buyer ? buyerHook.buyer.addressList : farmerHook.farmer.addressList) ||
    []

  const removeAddress = (id) => {
    const obj = addressList.filter((address) => !(address.description === id))
    setAddressList(obj)

    if (user.roles.buyer)
      buyerHook.addFeature({
        addressList: obj,
      })
    else if (user.roles.farmer)
      farmerHook.addFeature({
        addressList: obj,
      })
  }

  return (
    <s.Container>
      <s.AddressContainer>
        {addresses.map((a) => (
          <Address {...a} onClick={(id) => removeAddress(id)} />
        ))}
      </s.AddressContainer>
      <s.AddButton onClick={toggleDrawer}>
        {formatMessage(messages.addAddress)}
      </s.AddButton>
    </s.Container>
  )
}

import React from 'react'
import ReactCodeInput from 'react-verification-code-input'
import { Container } from './styles'

export function VerifyCode(props) {
  return (
    <Container>
      <ReactCodeInput className='code' onChange={props.onChange} fields={6} {...props} />
    </Container>
  )
}

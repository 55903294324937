const initialState = {
	error: {},
	phoneNumber: '',
	recaptcha: false,
	confirmation: null,
	success: false
}

export default (state = initialState, action) => {
	switch(action?.type) {
		case 'phone/recaptcha':
			return {
				...state,
				recaptcha: true,
			}
		case 'phone/request': 
			const { phoneNumber } = action
			return {
				...state, 
				phoneNumber,
				error: {}
			}
		case 'phone/request/error':
			return {
				...state, 
				error: { ...state.error, request: action.error}
			}
		case 'phone/request/confirmation':
			const { confirmation } = action
			return {
				...state,
				confirmation: confirmation,
				error: {}
			}
		case 'phone/confirm/code':
			return {
				...state,
				code: action.code,
				error: {}
			}
		case 'user/created':
			const { loginType } = action
			return {
				...state,
				success: loginType === 'phone',
				error: {}
			}
		case 'user/create/error':
			if(action.error && ((action.error.credential && 
				action.error.credential.signInMethod === 'phone') ||
				action.error.code === 'auth/invalid-verification-code')) {
				return {
					...state,
					error: { ...state.error, verify: action.error}
				}
			} else {
				return {
					...state,
					error: { ...action.error}
				}
			}
		case 'phone/error/dismiss':
	      return {
	        ...state, error: {}
	      }
		default:
			return state
	}
}
import styled, { css } from 'styled-components'
import { colors, device, fonts } from 'styles'

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  width: 750px;
  height: 750px;
  max-height: 95%;
  padding: 30px;
  display: flex;
  flex-direction: column;

  @media ${device.untilTablet} {
    width: 90%;
    height: auto;
    overflow: auto;
  }
`

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const HeaderImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin-right: 25px;

  @media ${device.untilTablet} {
    display: none;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h1 {
    margin-bottom: 8px;
    color: ${colors.dorado};
  }

  & > p:not(:last-of-type) {
    margin-bottom: 17px;
    font-style: italic;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 30px;

  & .loi-help {
    margin: 40px auto 20px auto;

    & b {
      color: ${colors.apple};
    }

    & a {
      text-decoration: none;
      font-weight: bold;
    }
  }

  & .batch-help {
    margin: 40px auto 20px auto;
    & a {
      color: ${colors.apple};
    }
  }
`

export const LoiDescription = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.untilTablet} {
    flex-direction: column;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${device.untilTablet} {
    margin-top: 40px;
  }

  &.loi-description {
    & p {
      color: ${colors.silverChalice6};

      & b {
        color: ${colors.dorado};
      }
    }

    & > :not(:last-child) {
      margin-bottom: 25px;
    }
  }
`

export const FooterButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @media ${device.untilTablet} {
    margin-top: 40px;
    justify-content: center;
  }

  & button {
    border-radius: 5px;
    font-weight: bold;
    font-size: 20px;
    width: 200px;
    height: 40px;

    @media ${device.untilTablet} {
      font-size: 16px;
    }

    &.exclude {
      background: ${colors.fuzzyWuzzyBrown};
      color: ${colors.white};
    }

    &.close {
      border: 1px solid ${colors.dorado};
      background: ${colors.white};
      color: ${colors.dorado};
    }
  }

  & > :not(:last-child) {
    margin-right: 20px;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ padding }) => padding || '25px'};
  background: ${colors.wildSand};
  border: 1px solid ${colors.mercury3};
  border-radius: 20px;
  flex: 1;

  & .clock {
    margin: auto;

    & path {
      fill: ${colors.mercury2};
    }
  }
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    color: ${colors.dorado};
    margin-bottom: 17px;
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: ${colors.gray};
  }

  & .btn-paid {
    border-radius: 10px;
    width: 100px;
    height: 35px;
  }
`

export const CardHeaderDivider = styled.div`
  display: flex;
  padding-top: 20px;
`

export const CardBody = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'column'};
  width: 100%;
  height: 100%;
  padding: 30px 10px 0;
  align-items: ${({ alignItems }) => alignItems || 'unset'};
  justify-content: ${({ justifyContent }) => justifyContent || 'unset'};
`

export const CardBodyLeft = styled.div`
  display: flex;
  flex: 1.2;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 30px 0 0 0;
  border-radius: 10px 0 0 10px;
  background-color: ${({ backgroundColor }) => colors[backgroundColor] || 'white'};
`

export const CardBodyRight = styled.div`
  display: flex;
  flex: 1.5;
  flex-direction: column;
  width: 100%;
  padding: 30px 0 0 0;
  border-radius: 0 10px 10px 0;
  background-color: ${({ backgroundColor }) => colors[backgroundColor] || 'white'};
`

export const Section = styled.div`
  display: flex;
  padding: 0 20px;
  margin: ${({ margin }) => margin || '0 0 10px 0'};
  flex-direction: column;

  & p > b {
    color: ${colors.dorado}
  }
`

export const CardBodySection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`

export const SectionDivider = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: end;
  padding: 0 30px 0 0;

  & .value {
    color: ${colors.apple};
    font-size: 20px;
    font-weight: bold;
  }

  & .truck-input-file {
    min-width: auto;
    width: 100%;
    max-width: 295px;
  }
`

export const DateList = styled.div`
  display: flex;
  margin-bottom: 50px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  & > button {
    border: 1px solid ${colors.dorado};
    background: transparent;
    height: 40px;
    width: 115px;
    color: ${colors.dorado};
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;

    &.selected-date {
      background: ${colors.dorado};
      color: ${colors.white};
    }
  }
`

export const TruckList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  & > .arrow-nav {
    position: absolute;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    top: 25%;

    &.arrow-left {
      left: 5px;
    }
    &.arrow-right {
      right: 5px;
    }
  }
`

export const Truck = styled.div`
  border-radius: 5px;
  background: ${colors.white};
  width: 225px;
  height: 50px;
  margin-bottom: 15px;
  padding: 0 15px;
  display: flex;
  align-items: center;

  &:nth-child(even) {
    margin-right: 10px;
  }

  &:nth-child(odd) {
    margin-left: 10px;
  }

  ${({ active }) =>
    active &&
    css`
      background: rgba(71, 172, 52, 0.1);
      border: 1px solid ${colors.apple};
    `}

  & > div {
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    & .truck-name {
      color: ${colors.dorado};
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }

    & .board {
      color: ${colors.dorado};
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  & > .svg-check {
    margin-left: auto;
  }
`

export const Destination = styled.div`
  max-width: 300px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  padding: 30px;
  margin-top: 60px;
  align-self: flex-end;
  width: 100%;

  @media ${device.untilTablet} {
    margin-top: 0px;
    align-self: flex-start;
  }
`

export const Link = styled.a`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  text-decoration-line: underline;
  color: ${colors.bostonBlue};
  padding-top: 60px;
`

export const LinkButton = styled.span`
  cursor: pointer;
  color: ${colors.apple};
`

export const ModalContainer = styled.div`
  background: ${colors.white};
  border-radius: 5px;
  width: 650px;
  max-height: 95%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  

  @media ${device.untilTablet} {
    width: 90%;
    height: auto;
    overflow: auto;
  }
`

export const ModalHeader = styled.div`
  margin: 20px auto;
`

export const ModalSection = styled.div`
  background: ${colors.wildSand};
  border-radius: 5px;
  width: 100%;
  padding: 16px 25px;
  margin: 5px 0;
`

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 16px 25px;
  margin: 5px 0;
  justify-content: center;
  display: flex;
`

export const Title = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.mineShaft};
`
export const TitleAddress = styled(Title)`
  padding: 17px 0 10px 0;
`

export const TitlePorts = styled(Title)`
  padding: 5px 0 17px 0;
`

export const NegotiationButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => margin || 'unset'};

  // & button {
  //   width: 210px;
  //   font-weight: bold;
  // }

  & span {
    font-size: 16px;
    font-weight: 700;
  }

  & .accept {
    margin-right: 7px;
  }
  & .reject {
    margin-left: 7px;
    background: ${colors.danger};
  }
`

export const NegotiationDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || 'unset'};

  & > :not(:last-child) {
    margin-bottom: 30px;
  }
`

export const Values = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
  }
  & > :last-child {
    margin-left: 5px;
  }
`

export const ValueCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  padding: 15px 22px;
  width: ${({width}) => width? width : '215px'};
  margin-right: 20px;

  & > p:first-of-type {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 12px;
  }

  & > p:last-of-type {
    color: ${colors.apple};
    font-size: 20px;
    font-weight: bold;
  }
`

import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from './styles'
import Title from 'components/Title'
import InputPhoneNumber from 'components/InputPhoneNumber'
import InputCpf from 'components/InputCpf'
import Text from 'components/Text'
import Button from 'components/Button'
import { ErrorMessage } from 'components/ErrorMessage'
import logo from 'assets/logo.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { toast } from 'react-toastify'

import { useFarmer } from 'modules/data/hooks/useFarmer';
import { useUser } from 'modules/data/hooks/useUser';
import { useIntl } from 'react-intl'
import messages from './messages'
import routes from 'configuration/routes'

const validateCPF = cpf => {
    //Taken from https://pt.stackoverflow.com/questions/51340/como-validar-cpf-no-lado-do-cliente-com-script
    cpf = cpf.replace(/\D/g, '');
    if(cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    var result = true;
    [9,10].forEach(function(j){
        var soma = 0, r;
        cpf.split(/(?=)/).splice(0,j).forEach(function(e, i){
            soma += parseInt(e) * ((j+2)-(i+1));
        });
        r = soma % 11;
        r = (r <2)?0:11-r;
        if(r.toString() !== cpf.substring(j, j+1)) result = false;
    });
    return result;
}

export default function RegisterData() {
  const history = useHistory()
  const [phoneValue, setPhoneValue] = useState('')
  const [cpfValue, setCpfValue] = useState('')
  const [cpfValid, setCpfValid] = useState(true)
  const [cpfNotMine, setCpfNotMine] = useState(false)
  const [phoneExists, setPhoneExists] = useState(false)
  const [bannerCount, setBannerCount] = useState(0)
  const { addFeature, farmer, cpfAlreadyInUse } = useFarmer()
  const user = useUser()
  const { formatMessage } = useIntl()

  const nextStep = routes.farmer.confirmEmail
  const prevStep = routes.farmer.registerName

  useEffect(() => {
    if(farmer && farmer.cpf) {
      setCpfValue(farmer.cpf);
      setPhoneValue(farmer.phone);
      validateFields()
    }
    // eslint-disable-next-line
  }, [farmer])

  const handleNextStep = () => {
    validateFields()

    if (!phoneValue || !cpfValue) {
      toast.error(formatMessage(messages.error))
      return
    }

    if(phoneExists) {
      // toast.error('O número de telefone selecionado já está em uso por outro usuário da plataforma')
      if(!bannerCount) toast.error('O formulário possui dados incorretos')
      return
    }

    if(cpfNotMine) {
      // cpfAlreayInUse.error('O número de telefone selecionado já está em uso por outro usuário da plataforma')
      if(!bannerCount) toast.error('O formulário possui dados incorretos')
      return
    }

    if(!cpfValid) {
      return
    }

    addFeature({
      phone: phoneValue,
      cpf: cpfValue,
    })
    history.push(nextStep)
  }

  const validateFields = () => {
    if(phoneValue?.length > 3) setPhoneExists(user.checkPhone(phoneValue))
    if(parseInt(cpfValue?.length) === 11) {
      setCpfNotMine(cpfAlreadyInUse(cpfValue) && cpfValue !== farmer.cpf)
      setCpfValid(cpfValue === '' || validateCPF(cpfValue))
    }
  }

  useEffect( () => {
    validateFields()
    // eslint-disable-next-line
  }, [ cpfValue ])

  const handleCpfChange = value => {
    setCpfValue(value)
  }

  return (
    <Container>
      <FormContainer>
        <ErrorMessage
          hide={cpfValid}
          mode='banner'
          title='Número de CPF é inválido'
          message='O digito verificador do CPF está incorreto. Por favor, insira um número de CPF válido'
          onError={e => setBannerCount(bannerCount+1)}
          onClose={e => setBannerCount(bannerCount-1)}
        />
        <ErrorMessage
          hide={!cpfNotMine}
          mode='banner'
          title='Número de CPF já cadastrado'
          message='O número escolhido já está cadastrado. Utilize um outro número ou entre em contato conosco.'
          onError={e => setBannerCount(bannerCount+1)}
          onClose={e => setBannerCount(bannerCount-1)}
        />
        <ErrorMessage
          hide={!phoneExists}
          mode='banner'
          title='Número de telefone já cadastrado'
          message='O número escolhido já está cadastrado. Utilize um outro número.'
          onError={e => setBannerCount(bannerCount+1)}
          onClose={e => setBannerCount(bannerCount-1)}
        />
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Title className='title'>
              {formatMessage(messages.title)} &nbsp;<Title>{farmer && farmer.name}</Title>
            </Title>
            <Text>
              {formatMessage(messages.moreInfo)}
            </Text>
            <InputCpf
              placeholder={formatMessage(messages.identification)}
              value={cpfValue}
              // onChange={({ target }) => handleCpfChange(target.value)}
              onValueChange={target => handleCpfChange(target.value)}
            />

            <InputPhoneNumber
              placeholder={formatMessage(messages.cellphone)}
              value={phoneValue}
              onChange={({ target }) => setPhoneValue(target.value)}
            />
            <Text className='info-msg'>
              {formatMessage(messages.infoMsg)}
            </Text>
            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to={prevStep}>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to={prevStep}>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

import React, { useEffect, useState } from 'react'
import {
  Container,
  Header,
  HeaderContent,
  HeaderMobile,
  Logo,
  LogoMobile,
  Menu,
  Profile,
  MenuButtonOpen,
  MenuItem,
} from './styles'

import moment from 'moment'
import 'moment/locale/pt-br'

import logo from 'assets/logo-dashboard.svg'
import LogoAgricon from 'assets/logoAgriconMobile.png'
import CardHeader from 'components/CardHeader'
import placeholder from 'assets/Missing_avatar.svg'
import { useUser } from '../../modules/data/hooks/useUser'
import { useWeather } from '../../modules/data/hooks/useWeather'
import { useMarketInfo } from '../../modules/data/hooks/useMarketInfo'
import { IntlProvider, FormattedNumber, useIntl } from 'react-intl'
import profileMenus from 'configuration/menus'
import SidebarButton from 'components/SidebarButton'

import device from 'styles/base/device'

import { getMessageWelcome } from 'utils'
import useWindowSize from 'modules/data/hooks/useWindowSize'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useFarmer } from 'modules/data/hooks/useFarmer'

import messages from './messages'
import AvatarUpload from 'components/AvatarUpload'

const HeaderComponent = () => {
  const menus = profileMenus
  const [pickerOpen, setPickerOpen] = useState(false)
  const { user, current, setAvatar, logout } = useUser()
  const weather = useWeather()
  const market = useMarketInfo()
  const [width] = useWindowSize()
  const { buyer } = useBuyer()
  const { farmer } = useFarmer()
  const doLogout = () => logout()
  const { formatMessage } = useIntl()
  const intl = useIntl()

  const [anchorEl, setAnchorEl] = React.useState(null)
  let message = ''

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (partToScroll) => {
    if (partToScroll === 'platform') {
      window.location.href = 'https://app.agriconbusiness.com'
    } else {
      setAnchorEl(null)

      const element = document.getElementById(partToScroll)
      if (element) window.scrollTo(0, element.offsetTop)
    }
  }

  const buildMenu = () => {
    let menu = []

    if (user.roles) {
      if (user.roles.admin) {
        menu = menu.concat(menus.inspector)
        message = 'Olá, eu sou técnico de campo Agricon e gostaria de suporte'
      } else if (user.roles.farmer) {
        menu = menu.concat(menus.farmer)
        message =
          'Olá, eu me chamo ' +
          farmer?.name +
          ', sou produtor(a) e gostaria de suporte no uso da plataforma Agricon.'
      } else if (user.roles.buyer) {
        menu = menu.concat(menus.buyer)
        message =
          'Olá, aqui é da empresa ' +
          buyer?.fantasy +
          ', somos compradores na plataforma Agricon e gostaríamos de suporte no uso da plataforma.'
      }
    }

    return menu.map((menuItem) => {
      return <SidebarButton {...menuItem} key={menuItem.link} handleCloseMenu={handleClose}/>
    })
  }

  const locale = intl.locale
  var d = moment()
  d.locale(user.roles && user.roles.buyer ? locale : 'pt-br')
  const today = {
    day:
      user.roles && user.roles.buyer
        ? d.format('MMMM Do')
        : String(d.date()).padStart(2, '0') + ' de ' + d.format('MMMM'),
    weekDay: d.format('dddd'), //weekDays[d.getDay()],
  }

  let currentWeather = weather.officeLocation
  if (weather.currentLocation) currentWeather = weather.currentLocation
  else if (weather.farmLocations && weather.farmLocations.length) {
    currentWeather =
      weather.farmLocations[Object.keys(weather.farmLocations)[0]]
  }

  let avatar = user.avatar || placeholder

  useEffect(() => {
    setAnchorEl(null)
    // eslint-disable-next-line
  }, [window.location.pathname])

  return (
    <Container>
      {width < device.screen.tablet && (
        <HeaderMobile>
          <MenuButtonOpen onClick={handleClick} />
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {buildMenu()}
            <MenuItem
              onClick={() => {
                window.open(
                  'https://api.whatsapp.com/send?phone=+5551991634737&text=' +
                    message,
                  '_blank'
                )
              }}>
              {' '}
              Preciso de ajuda?{' '}
            </MenuItem>
            <MenuItem onClick={doLogout}> Sair </MenuItem>
          </Menu>

          <LogoMobile>
            <img src={LogoAgricon} alt='agricon' />
          </LogoMobile>

          <Profile>
            <AvatarUpload
              id='myInput'
              theme='invisible'
              folder={current}
              opened={pickerOpen}
              setOpened={(open) => setPickerOpen(open)}
              onUpload={(url) => setAvatar(url)}
            />
            <img
              src={avatar}
              className='avatar'
              alt='avatar'
              onClick={() => {console.log('click');setPickerOpen(true)}}
            />
          </Profile>
        </HeaderMobile>
      )}

      <Header>
        {width > device.screen.tablet && (
          <Logo>
            <img src={logo} alt='agricon' />
          </Logo>
        )}
        <HeaderContent>
          <CardHeader title={today.weekDay} data={today.day} />
          <CardHeader
            title={formatMessage(messages.minMax)}
            data={
              currentWeather.temp_min + '°/' + currentWeather.temp_max + '°'
            }
          />
          <CardHeader title={formatMessage(messages.dollar)}>
            {width < device.screen.tablet && (
              <React.Fragment>U$1 = </React.Fragment>
            )}
            <IntlProvider locale='pt-BR'>
              <FormattedNumber
                value={market.dollar}
                style={`currency`}
                currency='BRL'
              />
            </IntlProvider>
          </CardHeader>
          {/*<CardHeader title='Saca de soja' data='R$ 571,00' />*/}
          {width > device.screen.tablet && (
            <Profile>
              <AvatarUpload
                id='myInput'
                theme='invisible'
                folder={current}
                opened={pickerOpen}
                setOpened={(open) => setPickerOpen(open)}
                onUpload={(url) => setAvatar(url)}
              />
              <img
                src={avatar}
                className='avatar'
                alt='avatar'
                onClick={() => setPickerOpen(true)}
              />
              <span>
                {formatMessage(getMessageWelcome())}
                <strong>
                  {!user.loading ? user.profile.first_name : ''}
                </strong>{' '}
              </span>
            </Profile>
          )}
        </HeaderContent>
      </Header>
    </Container>
  )
}
export default HeaderComponent

import React, { useState, useEffect, useCallback } from 'react'

import Title from 'components/Title'
import Button from 'components/Button'
import Spinner from 'components/Spinner'

import ComponentField from './ComponentField'
import { Wrapper, Report } from './helpers'
import { getInspectionSteps, saveFields } from './mock'
import { useInspection } from 'modules/data/hooks/useInspection'

import { useHookMap } from './hookmap'

import * as s from './styles'

function StepIndicator({ actual, final }) {
  return (
    <s.StepStatus>
      etapa {actual} de {final}
    </s.StepStatus>
  )
}

function InspectionForm(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [showError, setShowError] = useState(false)
  const [actualStep, setActualStep] = useState(0)
  const [formData, setFormData] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [steps, setSteps] = useState([])
  const { inspection, addInfo } = useInspection(props.farm, props.id)

  const hookMap = useHookMap(props.farm)

  async function persistData() {
    await saveFields(formData)
    addInfo(formData)
    hookMap.save(formData)
  }

  async function nextStep(e) {
    e.preventDefault()

    setShowError(false)

    if (actualStep === steps.length - 1) {
      setIsLoading(true)
      await persistData();
      setIsLoading(false)
      setIsFinished(true)
      return
    }

    setIsLoading(true)
    await persistData();
    setIsLoading(false)

    setActualStep(actualStep + 1)
  }

  function prevStep() {
    if (actualStep === 0) {
      return
    }

    setActualStep(actualStep - 1)
  }

  function interpolateData(steps, data) {
    const _formData = {}

    steps.forEach((step) => {
      step.fields.forEach((field) => {
        _formData[field.name] = data[field.name]
      })
    })
    setFormData(_formData)
  }

  const setUp = useCallback(async () => {
    setIsLoading(true)

    const inspectionSteps = await getInspectionSteps()
    const _steps =
      inspectionSteps
        .filter((s) => s.active)
        .map((s) => ({ ...s, fields: s.fields.filter((f) => f.active) })) || []
    // const data = await getData();
    const data = inspection
    setSteps(_steps)
    interpolateData(_steps, data)
    const hookData = hookMap.load()
    interpolateData(_steps, hookData)
    setIsLoading(false)
    // eslint-disable-next-line
  }, [inspection])

  useEffect(() => {
    setUp()
  },[])

  if (isFinished) {
    return (
      <Report
        steps={steps}
        formData={formData}
        farm={props.farm}
        inspection={props.id}
        prevStep={() => setIsFinished(false)}
      />
    )
  }

  if (isLoading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    )
  }

  const step = steps[actualStep]

  if (!step) {
    return null
  }

  function setField({ name, value }, add_more_fields, idx) {
    if (add_more_fields) {
      const values = formData[name] || [value]
      values.splice(idx, 1, value)
      setFormData({ ...formData, [name]: values })
      return
    }
    setFormData({ ...formData, [name]: value })
  }

  function addNewField({ name, value }) {
    const newValue = value ? [...value, ''] : ['', '']
    setField({ name, value: newValue })
  }

  function removeField({ name }, i) {
    const value = formData[name]
    value.splice(i, 1)
    setField({ name, value })
  }

  function handleValidation({ name, errorMessage }) {
    const newErrors = formErrors
    Object.assign(newErrors, { [name]: errorMessage })
    setFormErrors(newErrors)
  }

  return (
    <Wrapper>
      <Title>{step.name}</Title>
      <s.Form onSubmit={nextStep}>
        <s.FormContent>
          {step.fields.map((field, i) => (
            <ComponentField
              key={`field_${i}`}
              field={{
                ...field,
                value: formData[field.name],
                theme: '',
                showError,
                editable: !step.editable ? false : field.editable,
                handleValidation,
                onChange: function (e, idx) {
                  e.preventDefault()
                  const {
                    target: { name, value },
                  } = e
                  return setField({ name, value }, field.add_more_fields, idx)
                },
              }}
              addNewField={addNewField}
              removeField={removeField}
            />
          ))}
        </s.FormContent>
        <s.FooterButtons>
          {actualStep > 0 && (
            <Button type='button' onClick={prevStep} background='boulder'>
              Voltar
            </Button>
          )}
          <Button type='submit'>
            {actualStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
          </Button>
        </s.FooterButtons>
        <StepIndicator actual={actualStep + 1} final={steps.length} />
      </s.Form>
    </Wrapper>
  )
}

export default InspectionForm

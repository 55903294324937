import React from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from './styles'
import Title from 'components/Title'
import Text from 'components/Text'
import Button from 'components/Button'
import logo from 'assets/logo-green.svg'
import checkIcon from 'assets/check.svg'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import messages from './messages'
import routes from 'configuration/routes'

export default function Done() {
  const history = useHistory()
  const { formatMessage } = useIntl()

  const handleNextStep = () => {
    history.push(routes.admin.home)
  }

  return (
    <Container>
      <div className='bg-image' />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <img src={checkIcon} alt='Phone' />
          <Content>
            <Title className='title' color='primary'>
              {formatMessage(messages.title)}
            </Title>
            <Text className='info'>{formatMessage(messages.subTitle)}</Text>

            <Footer>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to='/buyer-step-terms'>
                    <Text color='primary'>{formatMessage(messages.back)}</Text>
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  dollar: {
    id: 'headerComponent.dollar',
    defaultMessage: 'Dólar',
  },
  minMax: {
    id: 'headerComponent.minMax',
    defaultMessage: 'Min/Máx',
  },
})

export default messages

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  addLoi: {
    id: 'loi.addLoi',
    defaultMessage: 'Cadastrar LOI',
  },
  addNewLoi: {
    id: 'loi.addNewLoi',
    defaultMessage: 'Cadastrar nova LOI',
  },
  noLoiAdded: {
    id: 'loi.noLoi',
    defaultMessage: 'Você ainda não cadastrou nenhuma LOI',
  },
  my: {
    id: 'loi.my',
    defaultMessage: 'Minhas',
  },
  loi: {
    id: 'loi.loi',
    defaultMessage: 'Lois',
  },
})

export default messages

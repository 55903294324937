import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { useUser } from 'modules/data/hooks/useUser'
import * as s from './styles'
import Title from 'components/Title'
import Upload from 'components/Upload'
import placeholder from 'assets/Missing_avatar.svg'
import messages from './messages'
import Addresses from './Addresses'
import Accounts from './Accounts'
import Form from './Form'
import { Tabs } from 'components/Tabs'
import { Drawer } from '@material-ui/core'

export default function Profile() {
  const { user, current, setAvatar } = useUser()
  const [fileDialogOpen, setFileDialogOpen] = useState(false)
  const { formatMessage } = useIntl()
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const [tabs, setTabs] = useState([
    { name: formatMessage(messages.addressesTitle), selected: true, slug: 'ADDRESSES' },
  ])

  useEffect(() => {
    if(user?.roles?.farmer) {
      setTabs([
        ...tabs,
        { name: 'Minhas contas', slug: 'ACCOUNTS' },
      ])
    }
    // eslint-disable-next-line
  }, [ user.roles ])

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const changeTab = (tab) => {
    setSelectedTab(tab)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  let avatar = user.avatar || placeholder

  return (
    <s.Container>
      <s.Header>
        <s.PageDescription>
          <s.TitleHint>{formatMessage(messages.my)}</s.TitleHint>
          <Title>{formatMessage(messages.profile)}</Title>
        </s.PageDescription>
      </s.Header>

      <s.Card>
        <s.CardHeader>
          <s.LeftHeader>
            <s.Profile>
              <Upload
                id='myInput'
                theme='invisible'
                folder={current}
                fileDialogOpen={fileDialogOpen}
                setFileDialogOpen={setFileDialogOpen}
                onUpload={(url) => setAvatar(url)}
              />
              <img
                src={avatar}
                className='avatar'
                alt='avatar'
                onClick={() => setFileDialogOpen(true)}
              />
              <span onClick={() => setFileDialogOpen(true)}>
                {formatMessage(messages.editPhoto)}
              </span>
            </s.Profile>

            <s.BlockLine>
              <s.Name>{user.profile.name}</s.Name>
              <s.Email>{user.profile.email}</s.Email>
            </s.BlockLine>
          </s.LeftHeader>
          <s.RightHeader>
            <s.BlockLine>
              <s.CompanyName>{user.profile.fantasy}</s.CompanyName>
              <s.Telephone>
                {formatMessage(messages.telephone) + ': '}
                <b>
                  <u>{user.profile.phone}</u>
                </b>
              </s.Telephone>
            </s.BlockLine>
          </s.RightHeader>
        </s.CardHeader>
        <Tabs tabs={tabs} setTabs={setTabs} onSetTabs={changeTab} />

        {selectedTab.slug === 'ADDRESSES' && (
          <Addresses toggleDrawer={toggleDrawer('right', true)} />
        )}
        {selectedTab.slug === 'ACCOUNTS' && (
          <Accounts toggleDrawer={toggleDrawer('right', true)} />
        )}
        <Drawer
          anchor='right'
          open={state['right']}
          onClose={toggleDrawer('right', false)}>
          {
            <Form
              formType={
                selectedTab.slug === 'ADDRESSES' ? 'address' : 'account'
              }
              toggleDrawer={toggleDrawer('right', false)}
            />
          }
        </Drawer>
      </s.Card>
    </s.Container>
  )
}

import React from 'react'
import { Switch } from 'react-router-dom'
import Management from 'views/Management'
import { PrivateRoute } from './PrivateRoute'

export default function AdminRoutes() {
  return (
    <Switch>
      <PrivateRoute path='/management' component={Management} />
    </Switch>
  )
}

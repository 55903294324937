import React from 'react'
import SelectableDiv from 'components/SelectableDiv'
import Text from 'components/Text'

import * as s from './styles'

export default function TransportTypeList({ onSelect, selected, items }) {
  return (
    <s.Container>
      {items.map((c) => (
        <SelectableDiv
          key={c.type}
          selected={selected && selected.type === c.type}
          disabled={c.disabled}
          selectionObject={c}
          setSelected={onSelect}
          width={'271px'}
          height={'40px'}>
          <Text
            userSelect={'none'}
            width={'100%'}
            alignSelf={'center'}
            marginLeft={'25px'}>
            {c.description}
          </Text>
        </SelectableDiv>
      ))}
    </s.Container>
  )
}

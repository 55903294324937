import React from 'react'
import { colors } from 'styles'

export default function ({ color, ...props }) {
  const fill = colors[color] || colors.defaultSVG
  return (
    <svg
      width='14'
      height='8'
      viewBox='0 0 14 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.646039 0.645978C0.692485 0.599415 0.747661 0.562472 0.808406 0.537265C0.869151 0.512059 0.934272 0.499084 1.00004 0.499084C1.06581 0.499084 1.13093 0.512059 1.19167 0.537265C1.25242 0.562472 1.30759 0.599415 1.35404 0.645978L7.00004 6.29298L12.646 0.645978C12.6925 0.59949 12.7477 0.562614 12.8085 0.537455C12.8692 0.512296 12.9343 0.499346 13 0.499346C13.0658 0.499346 13.1309 0.512296 13.1916 0.537455C13.2524 0.562614 13.3076 0.59949 13.354 0.645978C13.4005 0.692466 13.4374 0.747655 13.4626 0.808394C13.4877 0.869134 13.5007 0.934234 13.5007 0.999978C13.5007 1.06572 13.4877 1.13082 13.4626 1.19156C13.4374 1.2523 13.4005 1.30749 13.354 1.35398L7.35404 7.35398C7.30759 7.40054 7.25242 7.43748 7.19167 7.46269C7.13093 7.4879 7.06581 7.50087 7.00004 7.50087C6.93427 7.50087 6.86915 7.4879 6.80841 7.46269C6.74766 7.43748 6.69248 7.40054 6.64604 7.35398L0.646039 1.35398C0.599476 1.30753 0.562533 1.25236 0.537327 1.19161C0.51212 1.13087 0.499146 1.06575 0.499146 0.999978C0.499146 0.934211 0.51212 0.86909 0.537327 0.808344C0.562533 0.747599 0.599476 0.692424 0.646039 0.645978Z'
        fill={fill}
      />
    </svg>
  )
}

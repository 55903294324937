import React from 'react'
import { Switch } from 'react-router-dom'
import Platform from 'views/Platform'
import { PrivateRoute } from './PrivateRoute'

export default function PlatformRoutes() {
  return (
    <Switch>
      <PrivateRoute path='/platform' component={Platform} />
    </Switch>
  )
}

import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { create, addFeature, acceptTerms } from '../logic/farmerLogic'
import { FARMER_COLLECTION as COLLECTION_NAME } from '../consts/collections'

export function useFarmer() {
  const user = useSelector((state) => state.firebase.auth.uid)
  const error = useSelector(state => state.farmer)
  const methods = useSelector(state => state.user.loginMethods)

  const dispatch = useDispatch()

  useFirestoreConnect([{ collection: COLLECTION_NAME }])

  const all = useSelector((state) => state.firestore.ordered[COLLECTION_NAME]);
  const farmer = useSelector(
    ({ firestore: { data } }) =>
      data[COLLECTION_NAME] && data[COLLECTION_NAME][user]
  )

  return {
    all: all,
    farmer: isLoaded(farmer) ? farmer : { loading: true },
    create: () => dispatch(create),
    addFeature: (data, uid) => dispatch(addFeature, data, uid),
    acceptTerms: version => dispatch(acceptTerms, version),
    cpfAlreadyInUse: cpf => all ? all.some(farmer => farmer.cpf === cpf) : false,
    emailAlreadyInUse: email => all ? all.filter(farmer => farmer.email === email).length : 0,
    methods,
    error
  }
}

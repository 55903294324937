import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from './styles'
import Title from 'components/Title'
import Input from 'components/Input'

import Text from 'components/Text'
import Button from 'components/Button'
import ErrorAgent from 'components/ErrorAgent'
import logo from 'assets/logo.svg'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useUser } from 'modules/data/hooks/useUser'
import { toast } from 'react-toastify'

export default function ResetPassword(props) {
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const user = useUser()

  const handleNextStep = async () => {
    setLoading(true)
    const schema = Yup.object().shape({
      password: Yup.string().required().min(6),
    })

    if (!(await schema.isValid({ password: password }))) {
      toast.warn('A senha deve ter 6 dígitos!')
      setLoading(false)
      return
    }

    if (!password) {
      toast.error('Senha iválida!')
      setLoading(false)
      return
    }

    if (password !== confirmPassword) {
      toast.warn('As senhas devem ser iguais!')
      setLoading(false)
      return
    }

    user.resetPassword(props.code, password)
    setLoading(false)
  }

  useEffect( () => {
    if(user.reset && user.reset.success) {
      toast.success('Senha atualizada com sucesso.', {
        onClose: props => { history.push('/')}
      })
    }
    // eslint-disable-next-line
  }, [ user.reset ])

  return (
    <Container>
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <ErrorAgent
              group='passwordReset'
              onError={e => setLoading(false)}
              onClear={e => setLoading(false)}
            />
            <Title className='title'>Troca de senha</Title>
            <Text>Preencha a nova senha para atualizar seu cadastro.</Text>
            <Input
              type='password'
              placeholder='Senha'
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <Input
              type='password'
              placeholder='Confirmar Senha'
              value={confirmPassword}
              onChange={({ target }) => setConfirmPassword(target.value)}
            />

            <Footer>
              <Buttons>
                <Button
                  loading={loading}
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  Enviar
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

import React from 'react'
import { colors } from 'styles'

export default function ({ color, ...props }) {
  const fill = colors[color] || colors.defaultSVG
  return (
    <svg
      width='28'
      height='36'
      viewBox='0 0 28 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M25.9918 19.9642L20.6165 11.5377C21.3781 11.1374 21.899 10.3388 21.899 9.4203C21.899 8.41498 21.275 7.55318 20.3941 7.2004L22.0225 1.50728C22.0928 1.26166 22.0243 0.997342 21.8437 0.816698C20.7605 -0.266461 18.9981 -0.266461 17.9148 0.816698C17.6557 1.07582 17.3112 1.21849 16.9448 1.21849C16.5784 1.21849 16.2339 1.07582 15.9732 0.81501L15.9697 0.811565C14.8904 -0.267938 13.131 -0.270962 12.0472 0.805166L12.0271 0.825276C11.4922 1.35638 10.6234 1.35483 10.0905 0.821831L10.0853 0.816698C9.0021 -0.266461 7.23969 -0.266461 6.15646 0.816698C5.97574 0.997482 5.90732 1.26201 5.97771 1.50777L7.60962 7.19907C6.72687 7.55093 6.10112 8.41365 6.10112 9.42037C6.10112 10.3389 6.62202 11.1375 7.38369 11.5378L2.00833 19.9642C-0.0712189 23.224 -0.202993 27.1946 1.65583 30.5852C3.51465 33.9758 6.93289 36 10.7997 36H17.2003C21.0671 36 24.4853 33.9758 26.3442 30.5852C28.203 27.1946 28.0713 23.224 25.9918 19.9642ZM9.09085 1.81112L9.09598 1.81625C10.1755 2.89568 11.9348 2.89864 13.0185 1.82265L13.0386 1.80254C13.5735 1.27144 14.4422 1.27298 14.9768 1.8076L14.9803 1.81105C16.0635 2.89421 17.8259 2.89421 18.9092 1.81105C19.3499 1.3703 20.0184 1.29274 20.5393 1.57837L18.9803 7.02953H9.02411L7.46097 1.5783C7.98188 1.29274 8.65017 1.37044 9.09085 1.81112ZM25.111 29.9091C23.5029 32.8425 20.5457 34.5938 17.2004 34.5938H10.7998C7.4545 34.5938 4.49726 32.8425 2.88912 29.9091C1.28097 26.9758 1.39496 23.5408 3.19401 20.7205L8.87736 11.8111H10.8358C11.2242 11.8111 11.539 11.4963 11.539 11.1079C11.539 10.7195 11.2242 10.4047 10.8358 10.4047H8.49189C7.94904 10.4047 7.50745 9.96315 7.50745 9.4203C7.50745 8.87746 7.94904 8.43587 8.49189 8.43587H8.49421H19.5082C20.051 8.43587 20.4926 8.87746 20.4926 9.4203C20.4926 9.96315 20.051 10.4047 19.5082 10.4047H17.1643C16.776 10.4047 16.4612 10.7195 16.4612 11.1079C16.4612 11.4963 16.776 11.8111 17.1643 11.8111H19.1227L24.8061 20.7205C26.6052 23.5408 26.7192 26.9757 25.111 29.9091Z'
        fill={fill}
      />
      <path
        d='M15.2398 22.9641C15.0633 22.9017 14.8833 22.8368 14.7032 22.7694V19.5825C15.18 19.7228 15.4984 19.9503 15.5119 19.9601C15.8212 20.1918 16.2601 20.1303 16.4938 19.8219C16.7283 19.5123 16.6675 19.0713 16.3579 18.8367C16.3167 18.8054 15.6629 18.3201 14.7032 18.1364V17.4366C14.7032 17.0482 14.3884 16.7334 14.0001 16.7334C13.6117 16.7334 13.2969 17.0482 13.2969 17.4366V18.1313C13.1778 18.1541 13.0571 18.183 12.9347 18.2198C11.9451 18.5172 11.2053 19.3635 11.0042 20.4285C10.8206 21.401 11.1321 22.339 11.8173 22.8763C12.1891 23.1679 12.6537 23.4342 13.2969 23.717V27.7951C12.7432 27.7601 12.3727 27.6341 11.7552 27.2301C11.4303 27.0174 10.9944 27.1086 10.7818 27.4336C10.5692 27.7586 10.6603 28.1944 10.9853 28.407C11.8858 28.9961 12.4955 29.1629 13.2969 29.2024V29.9295C13.2969 30.3179 13.6117 30.6327 14.0001 30.6327C14.3884 30.6327 14.7032 30.3179 14.7032 29.9295V29.1293C16.209 28.7995 17.0897 27.5431 17.2873 26.3676C17.5455 24.8309 16.7419 23.495 15.2398 22.9641ZM12.6852 21.7697C12.4149 21.5577 12.3003 21.1437 12.3861 20.6894C12.465 20.2716 12.7461 19.7678 13.2969 19.5814V22.1566C13.0657 22.0333 12.8571 21.9045 12.6852 21.7697ZM15.9003 26.1344C15.7991 26.7367 15.3926 27.3754 14.7032 27.6577V24.2657C14.7261 24.2738 14.7478 24.2818 14.771 24.29C16.0317 24.7356 15.9542 25.8144 15.9003 26.1344Z'
        fill={fill}
      />
      <path
        d='M14 11.8111C14.185 11.8111 14.3664 11.7359 14.4972 11.6051C14.628 11.4743 14.7032 11.2929 14.7032 11.108C14.7032 10.923 14.628 10.7416 14.4972 10.6108C14.3664 10.48 14.185 10.4048 14 10.4048C13.8151 10.4048 13.6337 10.48 13.5029 10.6108C13.3721 10.7416 13.2969 10.923 13.2969 11.108C13.2969 11.2929 13.3721 11.4743 13.5029 11.6051C13.6337 11.7359 13.815 11.8111 14 11.8111Z'
        fill={fill}
      />
    </svg>
  )
}

import React, { useEffect, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import { useFileUpload } from 'modules/data/hooks/useFileUpload'

import SVG from '../SVG'

import * as s from './styles'

function Status({ fileUpload }) {
  const { error, url } = fileUpload

  if (url) {
    return <SVG.FileStatus type="success" />
  }

  if (error) {
    return <SVG.FileStatus type="error" />
  }

  return <SVG.FileStatus type="loading" />
}

function Dropzone({ label, onChange, onUpload, folder, ...props }) {
  const [files, setFiles] = useState([]);
  const [fileToUpload, setFileToUpload] = useState(null);

  const fileUpload = useFileUpload();

  const triggerUpload = () => {
    const file = files[fileToUpload];
    if (file) {
      fileUpload.upload(file.content, folder)
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(f => ({ content: f, preview: URL.createObjectURL(f) })));
    acceptedFiles[0] && setFileToUpload(0)
  }, [])
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, accept: ".jpg,.jpeg,.png,.webp,.pdf" })

  const fileList = files.map(file => (
    <s.File src={file.preview} key={file.content.name}>
      <div>
        <Status fileUpload={file} />
      </div>
      <span>{file.content.name}</span>
    </s.File>
  ));

  useEffect(() => {
    triggerUpload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileToUpload])

  useEffect(() => {
    const newFiles = files.map((f, i) => i === fileToUpload ? ({ ...f, ...fileUpload }) : f)
    setFiles(newFiles)
    const urls = newFiles.filter(f => f.url).map(f => f.url);
    onChange && onChange(urls)
    const next = fileToUpload + 1
    setFileToUpload(next)
    if (!files[next]) {
      onUpload && onUpload(urls)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUpload.url])

  return (
    <s.Wrapper>
      <s.Label>{label}</s.Label>
      <s.Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <input {...getInputProps()} />
      {files.length > 0 ?
        <s.FileList>
          {fileList}
        </s.FileList>
        : <React.Fragment>
          {!isDragReject && <s.Text>Arraste arquivos aqui, ou clique para selecionar arquivos</s.Text>}
          {isDragReject && <s.Text error>Arquivo não suportado</s.Text>}
        </React.Fragment>}
    </s.Container>
    </s.Wrapper>
  )
}

export default Dropzone;
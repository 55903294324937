import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import {
  BATCH_COLLECTION,
  FARM_COLLECTION,
  PRODUCT_COLLECTION,
} from '../consts/collections'
import { create, setStatus } from '../logic/batchLogic'

export function useBatch(id) {
  useFirestoreConnect([
    { collection: BATCH_COLLECTION, doc: id },
    { collection: PRODUCT_COLLECTION },
    { collection: FARM_COLLECTION },
  ])
  const dispatch = useDispatch()

  const batch = useSelector(
    ({ firestore: { data } }) =>
      data[BATCH_COLLECTION] && data[BATCH_COLLECTION][id]
  )

  const uid = useSelector((state) => state.firebase.auth.uid)
  const error = useSelector((state) => state.batch.error)
  const all = useSelector((state) => state.firestore.ordered[BATCH_COLLECTION])
  const products = useSelector(
    (state) => state.firestore.data[PRODUCT_COLLECTION]
  )
  const farms = useSelector((state) => state.firestore.data[FARM_COLLECTION])

  const allBatches =
    all && products
      ? all.map((batch) => {
          return {
            ...batch,
            product: products[batch.product.id],
            farm: { ...farms[batch.farm.id], id: batch.farm.id },
          }
        })
      : []

  return {
    all: allBatches,
    mine: allBatches ? allBatches.filter((batches) => batches.createdBy === uid) : [],
    batch: isLoaded(batch) ? batch : { loading: true },
    create: (data) => dispatch(create, data),
    setStatus: (status) => dispatch(setStatus, status),
    error,
  }
}

import styled from 'styled-components'
import BannerFarmer from 'assets/banner-produtor.jpg'
import { colors } from 'styles'

export const Container = styled.div`
  flex: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${BannerFarmer}) no-repeat top;
  background-size: cover;
  display: flex;
  justify-content: center;
  max-height: 415px;
  
  @media only screen and (max-width: 590px) {
    background-position: 0 -23vh;
  }
`
export const Logo = styled.div`
  display: flex;
  justify-content: center;
`

export const FormContainer = styled.div`
  height: 100%;
  padding-top: 30px;

  form {
    width: 60vw;
    height: 60vh;

    background: ${colors.white};
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.31);
    border-radius: 5px;
    margin-top: 21px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
  }

  @media only screen and (max-width: 590px) {
    form {
      width: 400px;
    }
  }

  @media only screen and (max-width: 450px) {
    form {
      width: 350px;
    }
  }

  @media only screen and (max-width: 390px) {
    form {
      width: 300px;
    }
  }
`

export const Content = styled.div`
  width: 450px;

  div:not(:first-child) {
    margin-top: 30px;
  }

  margin-bottom: 20px;

  .title {
    font-weight: 400;
    color: ${colors.greenDark};
    display: flex;
    align-self: flex-start;
  }

  .info-msg {
    color: ${colors.silver3};
    font-style: italic;
    margin-top: 20px;
  }

  p {
    line-height: 1.4;
    text-justify: auto;
    color: ${colors.dorado};
    margin: 0;
    margin-top: 10px;
    font-weight: 300;
  }

  @media only screen and (max-width: 590px) {
    width: 340px;
  }

  @media only screen and (max-width: 450px) {
    width: 310px;
  }

  @media only screen and (max-width: 390px) {
    width: 270px;
  }
`

export const Footer = styled.div`
  margin-top: 60px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: red; */

  .resend {
    margin-bottom: 50px;
    font-style: italic;
    text-decoration: underline;
    color: ${colors.camarone2};
  }

  .step {
    color: ${colors.silver5};
    font-style: italic;
    margin-top: 30px;
  }

  @media only screen and (max-width: 590px) {
    .step {
      display: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    margin-right: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
      color: ${colors.apple};
    }
  }
  .continue {
    width: 150px;
  }

  .voltar-mobile {
    width: 70px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    display: flex;
    justify-content: center;
    align-items: center;

    display: none;
  }

  @media only screen and (max-width: 590px) {
    .voltar-desktop {
      display: none;
    }

    .voltar-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

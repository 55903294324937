import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { create, addFeature, acceptTerms } from '../logic/buyerLogic'
import { BUYER_COLLECTION as COLLECTION_NAME } from '../consts/collections'

export function useBuyer() {
  const user = useSelector((state) => state.firebase.auth.uid)
  const error = useSelector( state => state.buyer.error )
  const dispatch = useDispatch()

  useFirestoreConnect([{ collection: COLLECTION_NAME, doc: user }])

  const buyer = useSelector(
    ({ firestore: { data } }) =>
      data[COLLECTION_NAME] && data[COLLECTION_NAME][user]
  )

  return {
    all: useSelector((state) => state.firestore.ordered[COLLECTION_NAME]),
    buyer: isLoaded(buyer) ? buyer : { loading: true },
    create: () => dispatch(create),
    addFeature: (data) => dispatch(addFeature, data),
    acceptTerms: (version) => dispatch(acceptTerms, version),
    error
  }
}

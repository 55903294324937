import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Card,
  Buttons,
} from './styles'
import Title from 'components/Title'
import Input from 'components/Input'
import Text from 'components/Text'
import Button from 'components/Button'
import logo from 'assets/logo.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useFarmer } from 'modules/data/hooks/useFarmer';
import { toast } from 'react-toastify'
import messages from './messages'
import { useIntl } from 'react-intl'
import routes from 'configuration/routes'

export default function RegisterName() {
  const history = useHistory()
  const [value, setValue] = useState("");
  const { addFeature, farmer } = useFarmer();
  const { formatMessage } = useIntl()

  const nextStep = routes.farmer.registerData
  const prevStep = routes.farmer.welcome

  useEffect( () => {
      if(farmer && farmer.name) setValue(farmer.name);
  }, [ farmer ])
  
  const handleNextStep = event => {
    if (!value) {
      toast.error('Nome inválido!')
      return
    }
    addFeature({ name: value })
    history.push(nextStep)
  }

  return (
    <Container>
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action='#' onSubmit={e => e.stopPropagation()}>
          <Card>
            <Text>{formatMessage(messages.weConnect)}</Text>
          </Card>
          <Content>
            <Text>{formatMessage(messages.needName)}</Text>
            <Title className='title'>{formatMessage(messages.fullName)}</Title>
            <Input
              placeholder=''
              value={value}
              onChange={({ target }) => setValue(target.value)}
            />
            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to={prevStep}>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to={prevStep}>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='submit'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

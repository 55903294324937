import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import Title from 'components/Title'
import Text from 'components/Text'
import Button from 'components/Button'
import Input from 'components/Input'
import SVG from 'components/SVG'
import Upload from 'components/Upload'

import { Help } from './Content'
import { negotiationStatus } from 'utils'
import * as s from './styles'
import { useShipment } from 'modules/data/hooks/useShipment'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { setStatus as setStatusBatch } from 'modules/data/logic/batchLogic'
import { setStatus as setStatusLoi } from 'modules/data/logic/loiLogic'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

function ChargingTab({ negotiation }) {
  const [modalTruckOpen, setModalTruckOpen] = useState(false)
  const [selectedFreight, setSelectedFreight] = useState(0)
  const [selectedTruck, setSelectedTruck] = useState(undefined)
  const [loadedWeight, setLoadedWeight] = useState(0)
  const [unloadedWeight, setUnloadedWeight] = useState(0)
  const [fiscalNote, setFiscalNote] = useState('')
  const dispatch = useDispatch()
  const classes = useStyles()
  const { shipment, updateTruck } = useShipment(negotiation.shipment)

  const handleTruckClick = (truck) => {
    setSelectedTruck(truck)
    setLoadedWeight(truck.loadedWeight)
    setUnloadedWeight(truck.emptyWeight)
    setModalTruckOpen(true)
  }

  const handleTruckLoadedWeightChanged = (e, truck) => {
    const { value } = e.target
    setLoadedWeight(value)
  }

  const handleConfirmClick = () => {
    if (!selectedTruck.loaded) {
      const selectedTruckUpdated = {
        ...selectedTruck,
        loadedWeight: loadedWeight,
        emptyWeight: unloadedWeight,
        timeLoaded: moment().format('HH:mm'),
        loaded: true,
        fiscalNote: fiscalNote,
      }
      updateTruck(selectedTruckUpdated)

      if (isAllTrucksLoaded()) {
        changeStatus()
      }
    }
    setModalTruckOpen(false)
  }

  const changeStatus = () => {
    setStatusLoi(
      dispatch,
      null,
      negotiation.loiId.id,
      negotiationStatus.IN_TRANSIT
    )
    setStatusBatch(
      dispatch,
      null,
      negotiation.batchId.id,
      negotiationStatus.IN_TRANSIT
    )
  }

  const isAllTrucksLoaded = () => {
    var isAllTrucksLoaded = true

    shipment.freights.forEach((freight) => {
      freight.trucks.forEach((truck) => {
        isAllTrucksLoaded = truck.loaded
      })
    })

    return isAllTrucksLoaded
  }

  const handleUnloadedTruckWeightChanged = (e, truck) => {
    const { value } = e.target
    setUnloadedWeight(value)
  }

  const handleTruckModalClose = () => {
    setModalTruckOpen(false)
    setSelectedTruck(undefined)
  }

  return (
    <React.Fragment>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={modalTruckOpen}
        onClose={handleTruckModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        {shipment?.freights && (
          <Fade in={modalTruckOpen}>
            <s.Container size='medium'>
              <div onClick={handleTruckModalClose} className='close-modal'>
                <SVG.Close color='dorado' />
              </div>
              <s.TruckHeader>
                <s.TruckHeaderBox>
                  <SVG.Truck color='white' />
                  <Text>04</Text>
                </s.TruckHeaderBox>
                <s.TruckHeaderContent>
                  <Text>
                    PLACA: <b>{selectedTruck?.tag}</b>
                  </Text>
                  <Text>
                    Motorista: <b>{selectedTruck?.driver}</b>
                  </Text>
                  <Text>
                    Data do carregamento:{' '}
                    <b>
                      {moment(
                        shipment?.freights[selectedFreight].date.toDate()
                      ).format('DD/MM/yyyy HH:mm')}
                    </b>
                  </Text>
                  <Text>
                    Toneladas a serem carregadas:{' '}
                    <b>{selectedTruck?.tonsToLoad}</b>
                  </Text>
                </s.TruckHeaderContent>
              </s.TruckHeader>
              <s.TruckBody>
                <s.TruckRow>
                  <Text>Peso do caminhão vazio</Text>
                  <Input
                    className='truck-input'
                    theme='bordered'
                    disabled={selectedTruck?.loaded}
                    value={unloadedWeight}
                    onChange={(value) =>
                      handleUnloadedTruckWeightChanged(value, selectedTruck)
                    }
                  />
                  <Text>toneladas</Text>
                </s.TruckRow>
                <s.TruckRow>
                  <Text>Peso do caminhão cheio</Text>
                  <Input
                    disabled={selectedTruck?.loaded}
                    className='truck-input'
                    theme='bordered'
                    value={loadedWeight}
                    onChange={(value) =>
                      handleTruckLoadedWeightChanged(value, selectedTruck)
                    }
                  />
                  <Text>toneladas</Text>
                </s.TruckRow>
                <s.TruckRow>
                  <Text>Horário do carregamento</Text>
                  <Input
                    className='truck-input'
                    theme='bordered'
                    disabled={selectedTruck?.loaded}
                    value={selectedTruck?.timeLoaded || moment().format('HH:mm')}
                  />
                  <Text></Text>
                </s.TruckRow>
                <s.TruckRow>
                  <Text>Nota fiscal do produtor</Text>
                  <Upload
                    className='truck-input-file'
                    folder='' // TODO: verificar se terá alguma pasta padrão
                    disabled={selectedTruck?.loaded}
                    onChange={(file) => console.log('file >> ', file)}
                    onUpload={(url) => setFiscalNote(url)}
                  />
                  <Text></Text>
                </s.TruckRow>
                <Button onClick={handleConfirmClick}>Confirmar</Button>
              </s.TruckBody>
            </s.Container>
          </Fade>
        )}
      </Modal>
      <s.Card>
        <s.CardHeader>
          <Title>Carregamento confirmado!</Title>
        </s.CardHeader>
        <s.DateList>
          {shipment?.freights &&
            shipment?.freights?.map((freight, i) => (
              <Button
                className={i === selectedFreight ? 'selected-date' : ''}
                onClick={() => setSelectedFreight(i)}>
                {moment(freight.date.toDate()).format('DD/MM/yyyy')}
              </Button>
            ))}
        </s.DateList>
        <s.TruckList>
          <button className='arrow-nav arrow-left'>
            <svg
              width='10'
              height='19'
              viewBox='0 0 10 19'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M9.79021 0.209795C9.85671 0.276129 9.90947 0.354931 9.94547 0.441688C9.98147 0.528444 10 0.621451 10 0.71538C10 0.809309 9.98147 0.902316 9.94547 0.989073C9.90947 1.07583 9.85671 1.15463 9.79021 1.22097L1.72512 9.28462L9.79021 17.3483C9.9243 17.4824 9.99963 17.6642 9.99963 17.8539C9.99963 18.0435 9.9243 18.2254 9.79021 18.3594C9.65612 18.4935 9.47425 18.5689 9.28462 18.5689C9.09499 18.5689 8.91313 18.4935 8.77904 18.3594L0.209794 9.79021C0.143292 9.72387 0.0905301 9.64507 0.0545301 9.55831C0.0185301 9.47156 0 9.37855 0 9.28462C0 9.19069 0.0185301 9.09768 0.0545301 9.01093C0.0905301 8.92417 0.143292 8.84537 0.209794 8.77904L8.77904 0.209795C8.84537 0.143293 8.92417 0.0905308 9.01093 0.0545308C9.09768 0.0185308 9.19069 0 9.28462 0C9.37855 0 9.47156 0.0185308 9.55831 0.0545308C9.64507 0.0905308 9.72387 0.143293 9.79021 0.209795Z'
                fill='#565656'
              />
            </svg>
          </button>
          {shipment?.freights &&
            shipment?.freights[selectedFreight].trucks?.map((truck) => (
              <s.Truck
                active={truck.loaded}
                onClick={() => handleTruckClick(truck)}>
                <SVG.Truck color='apple' />
                <div>
                  <Text className='truck-name'>{truck.driver}</Text>
                  <Text className='board'>{truck.tag}</Text>
                </div>
                {
                  truck.loaded &&
                  <SVG.Check className='svg-check' color='apple' />
                }
              </s.Truck>
            ))}
          <button className='arrow-nav arrow-right'>
            <svg
              width='10'
              height='19'
              viewBox='0 0 10 19'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M0.209794 0.209795C0.143292 0.276129 0.0905304 0.354931 0.0545311 0.441688C0.0185308 0.528444 0 0.621451 0 0.71538C0 0.809309 0.0185308 0.902316 0.0545311 0.989073C0.0905304 1.07583 0.143292 1.15463 0.209794 1.22097L8.27488 9.28462L0.209794 17.3483C0.0757046 17.4824 0.000374794 17.6642 0.000374794 17.8539C0.000374794 18.0435 0.0757046 18.2254 0.209794 18.3594C0.343884 18.4935 0.525748 18.5689 0.71538 18.5689C0.905011 18.5689 1.08687 18.4935 1.22096 18.3594L9.79021 9.79021C9.85671 9.72387 9.90947 9.64507 9.94547 9.55831C9.98147 9.47156 10 9.37855 10 9.28462C10 9.19069 9.98147 9.09768 9.94547 9.01093C9.90947 8.92417 9.85671 8.84537 9.79021 8.77904L1.22096 0.209795C1.15463 0.143293 1.07583 0.0905308 0.989072 0.0545308C0.902315 0.0185308 0.809309 0 0.71538 0C0.62145 0 0.528444 0.0185308 0.441688 0.0545308C0.354931 0.0905308 0.276128 0.143293 0.209794 0.209795Z'
                fill='#565656'
              />
            </svg>
          </button>
        </s.TruckList>
      </s.Card>
      <Help />
    </React.Fragment>
  )
}

export default ChargingTab

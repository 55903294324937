import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
  Container,
  Content,
  Infos,
  Header,
  ScheduleAppointmentCard,
  RescheduleAppointmentCard,
  ActiveCard,
  Cards,
} from './styles'
import SVG from 'components/SVG'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

import Appointment from '../Appointment'
import CancelAppointment from '../CancelAppointment'

import { useProduct } from 'modules/data/hooks/useProduct'
import { useFarm } from 'modules/data/hooks/useFarm'
import { status as farmStatus } from 'modules/data/consts/farm'
import useWindowSize from 'modules/data/hooks/useWindowSize'
import { device } from 'styles'
import { useBatch } from 'modules/data/hooks/useBatch'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export default function FarmCard(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [inspectionLabel, setInspectionLabel] = useState('')
  const [width] = useWindowSize()
  const { all } = useProduct()
  const { mine } = useBatch()
  const { setStatus, unsetStatus, farm } = useFarm(props.farm.id)

  useEffect(() => {
    if (!farm) return
    if (farmStatus.STATUS_WAITING_FOR_SCHEDULE in farm.status)
      setInspectionLabel('Aguardando agendamento da visita')
    if (farmStatus.STATUS_WAITING_FOR_INSPECTION in farm.status)
      setInspectionLabel('Aguardando a definição da data da visita')
    if (farmStatus.STATUS_WAITING_FOR_VISIT in farm.status)
      setInspectionLabel('Em breve o relatório da visita')
    if (farmStatus.STATUS_APPROVED in farm.status) {
      const today = moment()
      const dateApproved = moment(
        farm.status[farmStatus.STATUS_APPROVED].date.toDate()
      )
      const days = dateApproved.add(1, 'years').diff(today, 'days')
      setInspectionLabel(
        <span>
          Vistoria vence em <strong>{days} dias</strong>
        </span>
      )
    }
    if (farmStatus.STATUS_REPROVED in farm.status)
      setInspectionLabel('Entre em contato com nossa equipe')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (scheduleMode) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setOpenCancel(false)
  }

  const handleOpenCancel = () => {
    setOpenCancel(true)
  }

  const bookInspection = (week, turn) => {
    if (week && turn)
      setStatus(farmStatus.STATUS_WAITING_FOR_INSPECTION, week, turn)
    setOpen(false)
  }

  const cancelSchedule = () => {
    unsetStatus(farmStatus.STATUS_WAITING_FOR_INSPECTION)
    setOpenCancel(false)
  }

  const schedule = (
    <ScheduleAppointmentCard>
      <div id='appointment-info'>
        <strong>Agendar Visita</strong>
        <span>Agende uma visita para sua propriedade para ativá-la</span>
      </div>
      <div id='buttons'>
        <div>
          <button id='cancel' onClick={(e) => handleOpen(true)}>
            Saiba Mais
          </button>
        </div>
      </div>
    </ScheduleAppointmentCard>
  )

  const turnName = ['turno', 'da manhã', 'da tarde']
  let scheduleDetails = { week: '', turn: '' }
  let now = moment()
  let scheduleWeek = ''
  let weeks = 0
  if (props.farm.status[farmStatus.STATUS_WAITING_FOR_INSPECTION] != null) {
    scheduleDetails =
      props.farm.status[farmStatus.STATUS_WAITING_FOR_INSPECTION]
    scheduleWeek = moment(scheduleDetails.week.replace('-', ''))
    weeks = scheduleWeek.diff(now, 'week')
  }
  const reschedule = (
    <RescheduleAppointmentCard>
      <div id='appointment-info'>
        <strong>Visita Agendada</strong>
        <span>
          {weeks > 1 ? `Daqui ${weeks} semanas` : 'Nos próximos dias'} no turno{' '}
          {turnName[scheduleDetails.turn]}
        </span>
      </div>
      <div id='buttons'>
        <div>
          <button id='reschedule' onClick={handleOpen}>
            Remarcar
          </button>
          <button id='cancel' onClick={handleOpenCancel}>
            Cancelar
          </button>
        </div>
      </div>
    </RescheduleAppointmentCard>
  )

  const nothing = <div></div>

  const active = (
    <ActiveCard>
      <div id='appointment-info'>
        <strong>Propriedade ativa</strong>
        <span></span>
      </div>
    </ActiveCard>
  )

  let farmCard = schedule
  let cardStatus = 'created'
  const statusCardMap = {
    [farmStatus.STATUS_WAITING_FOR_SCHEDULE]: {
      obj: schedule,
      status: 'created',
    },
    [farmStatus.STATUS_WAITING_FOR_INSPECTION]: {
      obj: reschedule,
      status: 'scheduled',
    },
    [farmStatus.STATUS_WAITING_FOR_VISIT]: {
      obj: nothing,
      status: 'scheduled',
    },
    [farmStatus.STATUS_APPROVED]: { obj: active, status: 'approved' },
    [farmStatus.STATUS_REPROVED]: { obj: nothing, status: ' created' },
  }
  Object.entries(farmStatus).forEach((keyValue) => {
    const key = keyValue[1]
    if (props.farm.status && props.farm.status[key] != null) {
      farmCard = statusCardMap[key].obj
      cardStatus = statusCardMap[key].status
    }
  })

  return (
    <Container status={cardStatus}>
      <div id='left-bar' />
      <Content>
        <Header>
          <SVG.Farm height='10%' width='30%' viewBox='-10 -5 60 40' />
          <Infos>
            <div id='header-card'>
              <div id='farm-info'>
                {/* eslint-disable-next-line */}
                <p>
                  <a
                    className='edit-farm'
                    href='#1'
                    onClick={(e) => props.edit(props.farm.id)}>
                    Editar
                  </a>
                </p>
                <strong>{props.farm.name}</strong>
                <span>CNPJ: {props.farm.cnpj}</span>
              </div>
              {width > device.screen.tablet ? farmCard : null}
            </div>
            <div id='description'>
              <div id='days'>
                <span>{inspectionLabel}</span>
              </div>
              <div id='wheatbag'>
                <div>
                  <SVG.WheatBag />
                </div>
                <div>
                  <span>
                    <strong>
                      {mine && mine.length > 0
                        ? mine.reduce((acc, value) => {
                            if (value.farm.id === farm.id) {
                              return acc + 1
                            } else {
                              return acc
                            }
                          }, 0)
                        : 0}
                    </strong>{' '}
                    lotes publicados
                  </span>
                </div>
              </div>
              <div id='moneybag'>
                <div>
                  <SVG.MoneyBag />
                </div>
                <div>
                  <span>
                    <strong>
                      {mine && mine.length > 0
                        ? mine.reduce((acc, value) => {
                            if (value.farm.id === farm.id) {
                              if (value.status === 'sold') return acc + 1
                              else return acc
                            } else {
                              return acc
                            }
                          }, 0)
                        : 0}
                    </strong>{' '}
                    negociações concluídas
                  </span>
                </div>
              </div>
            </div>
          </Infos>
        </Header>
        <Cards>
          {all
            ? all
                .filter((product) =>
                  props.farm.productList.some(
                    (farmProduct) => farmProduct.id === product.id
                  )
                )
                .map((product) => (
                  <div className='card active'>
                    <img src={product.url} alt='product' />
                    <p>{product.name}</p>
                  </div>
                ))
            : ''}

          {/* <div className="card">
            <img src={ProductImage} alt="product" />
          </div> */}
        </Cards>
      </Content>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Appointment
            schedule={
              props.farm.status[farmStatus.STATUS_WAITING_FOR_INSPECTION] ==
              null
            }
            close={(event) => setOpen(false)}
            book={bookInspection}
          />
        </Fade>
      </Modal>

      {/* Cancel */}
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openCancel}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openCancel}>
          <CancelAppointment
            closeCancel={(event) => setOpenCancel(false)}
            cancelSchedule={cancelSchedule}
          />
        </Fade>
      </Modal>
    </Container>
  )
}

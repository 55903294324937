import React from 'react'
import { colors } from 'styles'

export default function ({ color, ...props }) {
  const fill = colors[color] || colors.defaultSVG
  return (
    <svg
      width='29'
      height='38'
      viewBox='0 0 29 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M24.5417 38H3.95833C1.77492 38 0 36.2235 0 34.0417V8.70833C0 6.5265 1.77492 4.75 3.95833 4.75H7.125C7.562 4.75 7.91667 5.10467 7.91667 5.54167C7.91667 5.97867 7.562 6.33333 7.125 6.33333H3.95833C2.64892 6.33333 1.58333 7.39892 1.58333 8.70833V34.0417C1.58333 35.3511 2.64892 36.4167 3.95833 36.4167H24.5417C25.8511 36.4167 26.9167 35.3511 26.9167 34.0417V8.70833C26.9167 7.39892 25.8511 6.33333 24.5417 6.33333H21.375C20.938 6.33333 20.5833 5.97867 20.5833 5.54167C20.5833 5.10467 20.938 4.75 21.375 4.75H24.5417C26.7251 4.75 28.5 6.5265 28.5 8.70833V34.0417C28.5 36.2235 26.7251 38 24.5417 38Z'
        fill={fill}
      />
      <path
        d='M19.7916 9.5H8.70825C7.39884 9.5 6.33325 8.43442 6.33325 7.125V3.95833C6.33325 3.52133 6.68792 3.16667 7.12492 3.16667H10.3708C10.7381 1.36167 12.3388 0 14.2499 0C16.161 0 17.7618 1.36167 18.1291 3.16667H21.3749C21.8119 3.16667 22.1666 3.52133 22.1666 3.95833V7.125C22.1666 8.43442 21.101 9.5 19.7916 9.5ZM7.91659 4.75V7.125C7.91659 7.562 8.27284 7.91667 8.70825 7.91667H19.7916C20.227 7.91667 20.5833 7.562 20.5833 7.125V4.75H17.4166C16.9796 4.75 16.6249 4.39533 16.6249 3.95833C16.6249 2.64892 15.5593 1.58333 14.2499 1.58333C12.9405 1.58333 11.8749 2.64892 11.8749 3.95833C11.8749 4.39533 11.5203 4.75 11.0833 4.75H7.91659Z'
        fill={fill}
      />
      <path
        d='M22.9583 15.8333H5.54167C5.10467 15.8333 4.75 15.4787 4.75 15.0417C4.75 14.6047 5.10467 14.25 5.54167 14.25H22.9583C23.3953 14.25 23.75 14.6047 23.75 15.0417C23.75 15.4787 23.3953 15.8333 22.9583 15.8333Z'
        fill={fill}
      />
      <path
        d='M22.9583 20.5833H5.54167C5.10467 20.5833 4.75 20.2287 4.75 19.7917C4.75 19.3547 5.10467 19 5.54167 19H22.9583C23.3953 19 23.75 19.3547 23.75 19.7917C23.75 20.2287 23.3953 20.5833 22.9583 20.5833Z'
        fill={fill}
      />
      <path
        d='M22.9583 25.3333H5.54167C5.10467 25.3333 4.75 24.9787 4.75 24.5417C4.75 24.1047 5.10467 23.75 5.54167 23.75H22.9583C23.3953 23.75 23.75 24.1047 23.75 24.5417C23.75 24.9787 23.3953 25.3333 22.9583 25.3333Z'
        fill={fill}
      />
      <path
        d='M22.9583 30.0833H5.54167C5.10467 30.0833 4.75 29.7287 4.75 29.2917C4.75 28.8547 5.10467 28.5 5.54167 28.5H22.9583C23.3953 28.5 23.75 28.8547 23.75 29.2917C23.75 29.7287 23.3953 30.0833 22.9583 30.0833Z'
        fill={fill}
      />
    </svg>
  )
}

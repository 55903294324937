import styled from 'styled-components'
import { colors, device } from 'styles'

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const badgeSizes = {
  default: 25,
  notification: 20,
}

export const Badge = styled.div`
  position: absolute;
  bottom: 0;
  right: -13px;
  width: ${({ size }) => badgeSizes[size] || badgeSizes.default}px;
  height: ${({ size }) => badgeSizes[size] || badgeSizes.default}px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => colors[background] || colors.white};
  border: 1px solid
    ${({ borderColor }) => colors[borderColor] || colors.silver2};

  @media ${device.untilTablet} {
    right: 20px;
    width: 15px;
    height: 15px;
  }

  & > svg {
    max-height: 60%;
  }
`

import styled from 'styled-components'
import { colors, device } from 'styles'

export const Title = styled.h1`
  font-size: ${({ fontSize }) => fontSize || '24px'};
  color: ${colors.defaultTitle};
  color: ${({ color }) => colors[color] || colors.defaultTitle};

  @media ${device.untilTablet} {
    font-size: 16px;
    color: ${colors.dorado};
  }
`

import React from 'react';
import { Container, CounterNumber, CounterText } from './styles';

export default function Counter({color, number, text}) {
    return (
      <Container>
        <CounterNumber color={color}>{number}</CounterNumber>
        <CounterText color={color}>{text}</CounterText>
      </Container>
    )
}
import styled from 'styled-components'
import { device } from 'styles'

const barColors = {
  created: '#C64E4E',
  scheduled: '#EDAE0E',
  approved: '#00BD00',
}

export const Container = styled.div`
  width: calc(100% - 40px);
  margin-top: 60px;
  min-height: 400px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;

  @media ${device.untilTablet} {
    width: calc(100% - 15px);
    margin-top: 20px;
    min-height: auto;
  }

  &:last-child {
    margin-bottom: 150px;
  }

  #left-bar {
    background: ${(props) => barColors[props.status]};
    border-radius: 10px 0px 0px 10px;
    width: 15px;
    min-height: 400px;
    @media ${device.untilTablet} {
      min-height: auto;
    }
  }

  .edit {
    display: none;
    width: 100px;
    height: 30px;
  }

  &:hover .edit {
    display: block;
    position: absolute;
    bottom: 100px;
    right: 50px;
  }
`

export const Content = styled.div`
  width: inherit;
`
export const Infos = styled.div`
  width: 100%;
  #header-card {
    margin-top: 15px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 107px;

    @media ${device.untilTablet} {
      padding: 0 20px;
      border-bottom: none;
      height: auto;
      margin-bottom: 30px;
    }

    #farm-info {
      span {
        display: block;
        margin: 0;
        color: #a3a3a3;
        margin-top: 8px;
      }
      .edit-farm,
      .edit-farm:hover,
      .edit-farm:visited {
        text-decoration: none;
        color: #a3a3a3;
      }

      p {
        @media ${device.untilTablet} {
          display: none;
        }
      }
    }
  }

  #description {
    display: flex;
    align-items: center;
    margin-top: 30px;

    @media ${device.untilTablet} {
      display: block;
      padding: 0 15px;
      margin: 0 0 20px;

      & svg {
        display: block !important;
      }
    }

    #days {
      color: #adadad;
      margin-right: 10px;

      @media ${device.untilTablet} {
        display: none;
      }
    }

    #wheatbag {
      display: flex;
      align-items: center;

      @media ${device.untilTablet} {
        margin-right: 10px;
        margin-bottom: 15px;
      }

      span {
        margin-left: 10px;

        @media ${device.untilTablet} {
          strong {
            font-size: 24px;
          }
          margin-left: 20px;
          font-size: 14px;
        }
      }
      margin-right: 10px;
    }

    #moneybag {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;

        @media ${device.untilTablet} {
          strong {
            font-size: 24px;
          }
          margin-left: 28px;
          font-size: 14px;
        }
      }
    }
  }
`
export const Header = styled.div`
  display: flex;

  @media ${device.untilTablet} {
    svg {
      display: none;
    }
  }

  img {
    margin: 30px 24px 30px 30px;
  }
`

export const ActiveCard = styled.div`
  background: rgba(0, 189, 0, 0.2);
  color: #00bd00;
  border: 1px solid #00bd00;
  box-sizing: border-box;
  border-radius: 5px;
  width: 218px;
  height: 78px;
  padding: 15px;
  text-align: center;

  #appointment-info {
    display: block;
    margin: auto;
    line-height: 45px;

    strong {
      color: #00bd00;
      font-weight: bold;
    }

    span {
      display: block;
      margin: 0;
      font-style: normal;
      font-weight: normal;
      color: #00bd00;
      margin-top: 8px;
    }
  }
`

export const ScheduleAppointmentCard = styled.div`
  background: rgba(198, 78, 78, 0.2);
  color: #c64e4e;
  border: 1px solid #c64e4e;
  box-sizing: border-box;
  border-radius: 5px;
  width: 593px;
  padding: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  #appointment-info {
    strong {
      color: #c64e4e;
      font-weight: bold;
    }

    span {
      display: block;
      margin: 0;
      font-style: normal;
      font-weight: normal;
      color: #c64e4e;
      margin-top: 8px;
    }
  }

  #buttons {
    padding: 10px;
    display: flex;
    justify-content: end;
    flex-direction: row;
    flex-wrap: wrap;

    button {
      vertical-align: middle;
      border: 0;
      width: 99px;
      cursor: pointer;
      margin: 0;
    }

    #reschedule {
      margin-right: 6px;
      background: #eec868;
      border-radius: 5px;
      width: 99px;
      height: 41px;
    }

    #cancel {
      background: #ff9797;
      border-radius: 5px;
      width: 99px;
      height: 41px;
    }
  }
`

export const RescheduleAppointmentCard = styled.div`
  background: rgba(237, 174, 14, 0.2);
  border: 1px solid #edae0e;
  box-sizing: border-box;
  border-radius: 5px;
  max-height: 100px;
  padding: 5px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  #appointment-info {
    margin-right: 30px;
    strong {
      color: #edae0e;
      font-weight: bold;
    }

    span {
      display: block;
      margin: 0;
      font-style: normal;
      font-weight: normal;
      color: #edae0e;
      margin-top: 8px;
    }
  }

  #buttons {
    padding: 10px;
    button {
      vertical-align: middle;
      border: 0;
      width: 99px;
      cursor: pointer;
      margin: 0;
    }

    #reschedule {
      margin-right: 6px;
      background: #eec868;
      border-radius: 5px;
      width: 99px;
      height: 41px;
    }

    #cancel {
      background: #ff9797;
      border-radius: 5px;
      width: 99px;
      height: 41px;
    }
  }
`
export const Cards = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  justify-content: left;
  align-items: left;
  width: 100%;

  @media ${device.untilTablet} {
    display: none;
  }

  .card {
    width: 130px;
    height: 130px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-right: 25px;
    margin-top: 10px;
    /* padding-bottom: 15px; */

    img {
      height: 45px;
      display: block;
    }

    p {
      display: block;
      width: 100%;
      text-align: center;
      margin: 20px 0 0 0;
    }
  }
`

import React from 'react'
import { colors } from 'styles'

export default function ({ color, ...props }) {
  const stroke = colors[color] || colors.defaultSVG
  return (
    <svg
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox={props.viewBox || '0 0 24 24'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
        <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="12" cy="12" r="3" stroke={stroke} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

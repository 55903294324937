import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Farms from 'views/Admin/Farms'
import Home from 'views/Admin/Home'
import Lot from 'views/Admin/Lot'
import Negotiations from 'views/Admin/Negotiations'
import Profile from 'views/Admin/Profile'
import InspectionHome from 'views/Admin/InspectionHome'
import InspectionForm from 'views/Admin/InspectionForm'
import Loi from 'views/Admin/Loi'
import { useQuery } from 'modules/data/hooks/useQuery'

import { routes } from 'configuration'

import { EnabledRoute } from './EnabledRoute'

const InternRoutes = () => {
  const query = useQuery()
  return (
    <Switch>
      <Route path={routes.admin.farms}>
        <Farms />
      </Route>
      <Route path={routes.admin.home}>
        <Home />
      </Route>
      <Route path={routes.inspector.inspections}>
        <InspectionHome filter='all' />
      </Route>
      <Route path={routes.inspector.schedule}>
        <InspectionHome filter='schedule' />
      </Route>
      <Route path={routes.inspector.review}>
        <InspectionHome filter='review' />
      </Route>
      <Route path={routes.inspector.inspection}>
        <InspectionForm farm={query.get('farm')} id={query.get('id')} />
      </Route>
      <Route path={routes.admin.batches}>
        <Lot />
      </Route>
      <EnabledRoute path={routes.admin.negotiations} component={Negotiations} />
      <EnabledRoute path={routes.admin.profile} component={Profile} />
      <EnabledRoute path={routes.admin.lois} component={Loi} />
      <Route path={routes.buyer.home}>
        <Home />
      </Route>
    </Switch>
  )
}

export default InternRoutes

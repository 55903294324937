import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'registerData.title',
    defaultMessage: 'Bem-vindo,',
  },
  moreInfo: {
    id: 'registerData.text.moreInfo',
    defaultMessage:
      'Precisamos só de mais algumas informações para concluir seu cadastro',
  },
  identification: {
    id: 'registerData.input.identification',
    defaultMessage: 'CPF',
  },
  cellphone: {
    id: 'registerData.input.cellphone',
    defaultMessage: 'Telefone',
  },
  infoMsg: {
    id: 'registerData.text.infoMsg',
    defaultMessage:
      'Será enviado um SMS para validar seu número. Não se preoucpe, nenhuma taxa será cobrada.',
  },
  step: {
    id: 'registerData.text.step',
    defaultMessage: 'etapa 2 de 5',
  },
  back: {
    id: 'registerData.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'registerData.button.continue',
    defaultMessage: 'Continuar',
  },
  error: {
    id: 'registerData.text.error',
    defaultMessage: 'Telefone ou CPF inválido!',
  },
})

export default messages

import React, { useState } from 'react'

import Button from 'components/Button'
import Text from 'components/Text'
import Title from 'components/Title'
import Input from 'components/Input'
import SVG, { SVGWithNotification } from 'components/SVG'
import Spinner from 'components/Spinner'
import Select from 'components/Select'
import Upload from 'components/Upload'
import Dropzone from 'components/Dropzone'
import PhoneModal  from 'components/PhoneModal'

import * as s from './styles'

function Examples() {
  const [value, setValue] = useState('')
  const [multiple1, setMultiple1] = useState('')
  const [multiple2, setMultiple2] = useState('')

  const DashboardIcons = ['Farm', 'WheatBag', 'MoneyBag', 'Farmer', 'Clipboard']
  return (
    <s.Container>
      <Title>Titulo padrão</Title>
      <Title color='primary'>Titulo com cor primária</Title>
      <Button>Botão padrão</Button>
      <Button background='danger'>Botão danger</Button>
      <Button disabled>Botão desabilitado</Button>
      <Button loading>Botão com loader</Button>
      <Text>Texto padrão</Text>
      <Text color='primary'>Texto com cor primária</Text>
      <Input
        placeholder='E-mail'
        value={value}
        onChange={({ target }) => setValue(target.value)}
      />
      <Upload
        label="Arquivo"
        onChange={(file) => console.log('file >> ', file)}
        onUpload={(url) => console.log('url >> ', url)}
      />
      <Upload
        theme="invisible"
        onChange={(file) => console.log('file >> ', file)}
        onUpload={(url) => console.log('url >> ', url)}
      />
      <Dropzone
        label="Dropzone"
        onChange={(files) => console.log('onChange > files :>> ', files)}
        onUpload={files => console.log('onUpload > files :>> ', files)}
      />
      <Select
        placeholder='Select multiple'
        placeholderAlign='center'
        multiple
        value={multiple1}
        onChange={({ target }) => setMultiple1(target.value)}
        options={[
          { label: 'option 1', value: 1 },
          { label: 'option 2', value: 2 },
          { label: 'option 3', value: 3 },
          { label: 'option 4', value: 4 },
          { label: 'option 5', value: 5 },
          { label: 'option 6', value: 6 },
          { label: 'option 7', value: 7 },
          { label: 'option 8', value: 8 },
          { label: 'option 9', value: 9 },
          { label: 'option 10', value: 10 },
        ]}
      />
      <Select
        placeholder='Select multiple'
        placeholderAlign='center'
        multiple
        value={multiple2}
        onChange={({ target }) => setMultiple2(target.value)}
        theme="default"
        options={[
          { label: 'option 1', value: 1 },
          { label: 'option 2', value: 2 },
          { label: 'option 3', value: 3 },
          { label: 'option 4', value: 4 },
          { label: 'option 5', value: 5 },
          { label: 'option 6', value: 6 },
          { label: 'option 7', value: 7 },
          { label: 'option 8', value: 8 },
          { label: 'option 9', value: 9 },
          { label: 'option 10', value: 10 },
        ]}
      />
      <Select
        placeholder='Select centralizado'
        placeholderAlign='center'
        options={[
          { label: 'option 1', value: 1 },
          { label: 'option 2', value: 2 },
        ]}
      />
      <Select
        placeholder='Select selecionado'
        options={[
          { label: 'option 1', value: 1 },
          { label: 'option 2', value: 2 },
        ]}
        value='2'
      />
      <Select
        placeholder='Select tooltip'
        tooltip='Essa é a dica que aparece para o usuário'
        options={[
          { label: 'option 1', value: 1 },
          { label: 'option 2', value: 2 },
        ]}
      />
      <Title>Icones</Title>
      <s.Icons>
        {Object.values(SVG).map((Icon) => (
          <Icon />
        ))}
        {DashboardIcons.map((key) => (
          <SVGWithNotification svg={key} count={1} />
        ))}
      </s.Icons>
      <Spinner />

      <div>
        <PhoneModal
          phone='+5551282761066'
          submitId='sms'
          onLoading={e => console.log(e)}
          onError={e => console.log(e)}
          onSuccess={e => alert('Success')}
        /> 
        <Button id='sms'>Validar</Button>
      </div>

    </s.Container>
  )
}

export default Examples

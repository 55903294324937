import { getFirebase } from 'react-redux-firebase'

export default ({ getState, dispatch }) => (next) => async (action) => {
  if(!action.type) return action
  next(action)
  switch (action.type) {
  	case 'system/error':
  		getFirebase().firestore().collection('logs').add({
  			type: 'error',
  			detail: action.error
  		})
  		break
  	default:
  		break
  }
}
import styled from 'styled-components'
import { colors } from 'styles'

export const Text = styled.p`
  color: ${({ color }) => colors[color] || colors.defaultText};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};
  margin-right: ${({ marginRight }) => marginRight || '0'};
  align-self: ${({ alignSelf }) => alignSelf || 'unset'};
  text-align: ${({ textAlign }) => textAlign || 'unset'};
  width: ${({ width }) => width || 'unset'};
  user-select: ${({ userSelect }) => userSelect || 'auto'};
  line-height: ${({ lineHeight }) => lineHeight || 'unset'};
`

import { useDispatch, useSelector } from 'react-redux'
import { init, request, confirm } from '../logic/phoneLogic'

export function usePhoneVerifier(btnId) {
	const dispatch = useDispatch()
	const phone = useSelector(state => state.phone)

	return {
		init: () => dispatch(init, btnId),
		request: phoneNumber => dispatch(request, phoneNumber),
		confirm: code => dispatch(confirm, code),
		dismiss: () => dispatch({ type: 'phone/error/dismiss' }),
		recaptcha: phone.recaptcha,
		success: phone.success,
		error: phone.error
	}
}
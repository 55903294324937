import React from 'react'
import { colors } from 'styles'

export default function ({ color, ...props }) {
  const fill = colors[color] || colors.apple
  return (
    <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.8321 9.94163V9.90388C16.8321 7.63926 15.0657 5.97854 12.3934 5.97854C11.5754 5.96502 10.7644 6.13184 10.0182 6.46714C9.27191 6.80243 8.60866 7.29798 8.07554 7.91857C8.00148 7.9872 7.94194 8.07 7.90045 8.16207C7.85895 8.25413 7.83635 8.35358 7.83398 8.45453C7.83393 8.54766 7.85262 8.63986 7.88893 8.72563C7.92524 8.8114 7.97843 8.88899 8.04533 8.95378C8.11224 9.01858 8.1915 9.06925 8.27839 9.10279C8.36528 9.13633 8.45802 9.15205 8.55111 9.14901C8.75053 9.14407 8.94122 9.06618 9.08707 8.9301C9.49053 8.4573 9.99162 8.07753 10.5559 7.81691C11.1201 7.55628 11.7341 7.42099 12.3557 7.42035C14.1372 7.42035 15.2468 8.56776 15.2468 9.99447V10.0322C15.2468 11.7005 13.9183 12.629 11.8197 12.7875C11.7347 12.7908 11.6514 12.812 11.5752 12.8497C11.499 12.8874 11.4316 12.9408 11.3774 13.0063C11.3233 13.0719 11.2836 13.1482 11.2609 13.2301C11.2383 13.3121 11.2332 13.3979 11.246 13.482V15.5579C11.2648 15.7447 11.3525 15.9179 11.4921 16.0435C11.6317 16.1691 11.8131 16.2382 12.0009 16.2373H12.0839C12.2708 16.2185 12.4439 16.1307 12.5695 15.9912C12.6952 15.8516 12.7642 15.6702 12.7633 15.4824V13.9274C14.9977 13.5877 16.8321 12.4176 16.8321 9.94163Z" fill="#47AC34" />
      <path d="M12.0012 19.9665C12.5223 19.9665 12.9448 19.5441 12.9448 19.0229C12.9448 18.5018 12.5223 18.0793 12.0012 18.0793C11.4801 18.0793 11.0576 18.5018 11.0576 19.0229C11.0576 19.5441 11.4801 19.9665 12.0012 19.9665Z" fill={fill} />
      <path d="M23.5809 8.91491C23.1222 9.12504 22.6372 9.27231 22.1391 9.35274C22.9357 11.6207 22.9383 14.092 22.1464 16.3615C21.3545 18.6311 19.8151 20.5644 17.7805 21.8445C15.7459 23.1246 13.3368 23.6755 10.9481 23.4071C8.55935 23.1387 6.33265 22.0668 4.63292 20.367C2.9332 18.6673 1.86128 16.4406 1.59285 14.0519C1.32441 11.6631 1.87539 9.25405 3.15548 7.21947C4.43556 5.18489 6.36882 3.6455 8.63841 2.85359C10.908 2.06168 13.3793 2.06424 15.6472 2.86083C15.7277 2.36274 15.875 1.87778 16.085 1.41902C13.493 0.52277 10.6737 0.531882 8.08753 1.44487C5.50136 2.35786 3.30118 4.12077 1.84633 6.44568C0.39147 8.77059 -0.232084 11.5201 0.0775058 14.2452C0.387095 16.9702 1.61153 19.5098 3.55084 21.4491C5.49014 23.3884 8.02971 24.6129 10.7548 24.9224C13.4798 25.232 16.2294 24.6085 18.5543 23.1536C20.8792 21.6988 22.6421 19.4986 23.5551 16.9124C24.4681 14.3262 24.4772 11.5069 23.5809 8.91491Z" fill="#47AC34" />
      <path d="M21.2255 7.54873C23.3101 7.54873 24.9999 5.85889 24.9999 3.77436C24.9999 1.68984 23.3101 0 21.2255 0C19.141 0 17.4512 1.68984 17.4512 3.77436C17.4512 5.85889 19.141 7.54873 21.2255 7.54873Z" fill={fill} />
    </svg>

  )
}

const colors = {
  apple: '#47AC34',
  green: '#00BD00',
  greenDark: '#2C592F',
  camarone: '#005113',
  camarone2: '#005D16',
  dorado: '#565656',
  white: '#FFFFFF',
  mercury: '#E5E5E5',
  mercury2: '#E2E2E2',
  mercury3: '#E7E7E7',
  gallery: '#EFEFEF',
  fuzzyWuzzyBrown: '#C64E4E',
  silverChalice: '#9D9D9D',
  silverChalice2: '#A2A2A2',
  alto: '#D5D5D5',
  silver: '#C4C4C4',
  silver2: '#C9C9C9',
  silver3: '#9E9E9E',
  silver4: '#A7A7A7',
  silver5: '#9F9F9F',
  silver6: '#f7f7f7',
  boulder: '#7D7D7D',
  black: '#000000',
  concrete: '#F3F3F3',
  silver7: '#CACACA',
  alto2: '#DADADA',
  mineShaft: '#2D2D2D',
  silverChalice3: '#B0B0B0',
  silver8: '#C6C6C6',
  curiousBlue: '#3088C7',
  fuelYellow: '#F2A42D',
  silverChalice4: '#ABABAB',
  alto3: '#D6D6D6',
  silverChalice5: '#989898',
  silver9: '#B8B8B8',
  transparent: 'transparent',
  nobel: '#B6B6B6',
  bostonBlue: '#3474BE',
  wildSand: '#F4F4F4',
  silverChalice6: '#A5A5A5',
  gray: '#868282',
  white2: '#FEFEFE',
  alto4: '#D8D8D8',
  alabaster: '#FBFBFB',
  vividRed: '#F41111',
}

export default {
  ...colors,
  primary: colors.apple,
  secondary: colors.camarone2,
  danger: colors.fuzzyWuzzyBrown,
  defaultSVG: colors.silverChalice,
  defaultText: colors.dorado,
  defaultTitle: colors.camarone2,
  defaultInputBorder: colors.alto,
  defaultCardBorder: colors.silver,
}

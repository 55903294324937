import React from 'react'
import Sidebar from 'components/Sidebar'
import HeaderComponent from 'components/HeaderComponent'
import { Container, Wrapper, SidebarMain } from './styles'
import ManagementRoutes from 'Routes/management.routes'

const Management = () => {
  return (
    <Wrapper>
      <Container>
        <HeaderComponent />
        <SidebarMain>
          <Sidebar />
          <ManagementRoutes />
        </SidebarMain>
      </Container>
    </Wrapper>
  )
}

export default Management

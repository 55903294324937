import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'verifyMail.title',
    defaultMessage: 'Email de recuperação enviado',
  },
  info: {
    id: 'verifyMail.text.info',
    defaultMessage:
      'Tudo certo! Em instantes você receberá um email contendo as informações necessárias para criar uma nova senha. Não se esqueça de verificar sua caixa de spam!',
  },
  back: {
    id: 'verifyMail.button.back',
    defaultMessage: 'Voltar',
  },
})

export default messages

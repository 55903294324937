const initialState = {
    error: {}
  }
  
  export default (state = initialState, action) => {
    switch (action?.type) {
      case 'negotiation/status/error':
        return {
          ...state,
          error: { ...state.error, update: action.error}
        }
      default:
        return state
    }
  }
  
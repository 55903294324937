import styled from 'styled-components'
import { colors, fonts } from 'styles'
import Select from 'components/Select'
import { Slider, Button } from '@material-ui/core'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.mercury2};
  padding: 40px;
  width: 100%;

  & > h1 {
    margin-bottom: 12px;
    font-weight: 400;
  }
`

export const SubTitle = styled.p``

export const Container = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 863px;
  overflow-y: auto;
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;

  & > :not(:last-child) {
    margin-bottom: 60px;
  }
`

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    margin-bottom: 25px;
  }
`

export const Products = styled.div`
  display: flex;
  overflow-x: auto;

  & > :not(:last-child) {
    margin-right: 25px;
  }
`

export const Link = styled(Button)`
  align-self: center;
  margin-top: ${({ marginTop }) => marginTop || '40px'};
  align-self: ${({ alignSelf }) => alignSelf || 'unset'};
  color: ${colors.bostonBlue};
  text-decoration: underline;
  cursor: pointer;
  transform: translateY(50%);
`

export const Row = styled.div`
  display: flex;
  width: 100%;

  & > :not(:last-child) {
    margin-right: 40px;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TitleContainer = styled.div`
  display: flex;
`

export const CustomSlider = styled(Slider)`
  width: 200px !important;
  margin-top: 40px;

  & .MuiSlider-rail,
  & .MuiSlider-track {
    color: #d7d7d7 !important;
    opacity: 1;
  }

  & .MuiSlider-thumb {
    color: ${colors.apple} !important;
  }
`

export const CustomSelect = styled(Select)`
  & select {
    background: ${colors.concrete};
    border: 1px solid ${colors.silver7};
    height: 50px;
    font-size: 18px;
  }

  & .select-placeholder {
    margin-bottom: 25px;
  }
`

export const Resume = styled.div`
  border-top: 1px solid ${colors.apple};
  border-bottom: 1px solid ${colors.apple};
  position: relative;
  display: flex;
  padding: 60px 0;
  justify-content: center;
`

export const ResumeBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;

  &:nth-of-type(3) {
    border-left: 1px solid ${colors.silver8};
    width: 60%;
  }

  & > .resume-list {
    & > :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  & > :not(:last-child) {
    margin-bottom: 15px;
  }
`

export const ResumeTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  background: ${colors.apple};
  border-radius: 10px;
  color: ${colors.white};
  padding: 10px 60px;
  position: absolute;
  top: -21px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 185px;
`

export const Reminder = styled.div`
  padding: 5%;
`

export const Footer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0px -10px 40px rgba(0, 0, 0, 0.1);
  padding: 0 40px;

  & p {
    font-size: 18px;
    color: ${colors.silver9};
    width: 100%;
  }

  & button {
    border-radius: 0;
    font-weight: bold;
    width: 200px;
    min-width: 200px;
  }
`

export const ContractDescription = styled.p`
  font-family: ${fonts.primary};
  font-style: italic;
  font-size: 16px;
  line-height: 22px;
  color: #adadad;
`

export const ContractTitle = styled(ContractDescription)`
  font-weight: bold;
`

export const ModalBody = styled.div`
  max-width: 500px;
  height: 100%;
  max-height: 600px;
  background-color: ${colors.white};
  margin: 0 10px;
  padding: 26px;

  & input {
    width: 75px;
    height: 40px;
    font-family: ${fonts.primary};
    font-size: 16px;
    line-height: 22px;
    min-width: unset;
    min-height: unset;
    margin-right: 35px;
  }
`

export const ModalRow = styled.div`
  display: flex;
  align-items: center;
`

import React, { useState } from 'react'

// import Drawer from '@material-ui/core/Drawer'

import Text from 'components/Text'
import Title from 'components/Title'
import Button from 'components/Button'
import SVG from 'components/SVG'
import { device } from 'styles'

import { useBatch } from 'modules/data/hooks/useBatch'

import Form from './Form'
import BatchesList from './BatchesList'

import * as s from './styles'
import { Drawer } from './styles'
import useWindowSize from 'modules/data/hooks/useWindowSize'

export default function Lot() {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const { mine: batches = [] } = useBatch()
  const [width] = useWindowSize()

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  return (
    <s.Container>
      <s.Header>
        <s.PageDescription>
          <s.TitleHint>Meus</s.TitleHint>
          <Title>Lotes</Title>
        </s.PageDescription>
        {width > device.screen.tablet && (
          <Button
            onClick={toggleDrawer('right', true)}
            height='40px'
            fontSize='16px'
            maxWidth='230px'
            background='dorado'>
            Cadastrar novo lote
          </Button>
        )}
      </s.Header>
      <s.Content>
        {batches.length ? (
          <React.Fragment>
            <BatchesList batches={batches} />
            {width < device.screen.tablet && (
              <Button
                onClick={toggleDrawer('right', true)}
                maxWidth='230px'
                fontSize='20px'>
                Cadastrar novo lote
              </Button>
            )}
          </React.Fragment>
        ) : (
          <s.NoBatches>
            <SVG.WheatBag width='86px' height='86px' />
            <Text>Você ainda não cadastrou nenhum lote</Text>
            <Button
              onClick={toggleDrawer('right', true)}
              maxWidth='230px'
              fontSize='20px'>
              Cadastrar lote
            </Button>
          </s.NoBatches>
        )}

        <Drawer
          anchor='right'
          open={state['right']}
          onClose={toggleDrawer('right', false)}>
          <Form hideModal={toggleDrawer('right', false)} />
        </Drawer>
      </s.Content>
    </s.Container>
  )
}

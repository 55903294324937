import React from 'react'
import {
  Container,
  Banner,
  Header,
  CardsContainer,
  Card,
  CardTitle,
  FarmStatus,
  Value,
  Counters,
  FarmExpiration,
} from './styles'
import farmLogo from 'assets/farmIcon.svg'
import { useHistory } from 'react-router-dom'
import { useUser } from 'modules/data/hooks/useUser'
import routes from 'configuration/routes'
import { useFarm } from 'modules/data/hooks/useFarm'
import { useBatch } from 'modules/data/hooks/useBatch'
import SVGObject from 'components/SVG/Object'
import { status as farmStatus } from 'modules/data/consts/farm'
import Counter from 'components/Counter'
import moment from 'moment'
import { colors } from 'styles'

export default function FarmerHome() {
  const history = useHistory()
  const { user } = useUser()
  const { mine: farms } = useFarm()
  const { mine: batches } = useBatch()

  const handleRegisterFarm = () => {
    history.push('/platform/farms')
  }

  if (user.loaded && (!user.user.phone || !user.user.phone.verified)) {
    history.push(routes.farmer.verifyPhone)
  }

  const getFarmStatus = (farm) => {
    let response = ''
    if (farmStatus.STATUS_WAITING_FOR_SCHEDULE in farm.status)
      response = 'Aguardando agendamento da visita'
    if (farmStatus.STATUS_WAITING_FOR_INSPECTION in farm.status)
      response = 'Aguardando inspeção'
    if (farmStatus.STATUS_WAITING_FOR_VISIT in farm.status)
      response = 'Aguardando relatório da visita'
    if (farmStatus.STATUS_APPROVED in farm.status) response = 'Ativa'
    if (farmStatus.STATUS_REPROVED in farm.status) response = 'Reprovada'

    return response
  }

  return (
    <Container>
      <Header>
        <div id='title'>
          <span>Minha </span>
          <strong>Tela Inicial</strong>
        </div>
      </Header>
      {farms && farms.length > 0 ? (
        <CardsContainer>
          <Card>
           <SVGObject svg={'Farm'} />
            <CardTitle>{farms[0].name}</CardTitle>
            <FarmStatus>{getFarmStatus(farms[0])}</FarmStatus>
            {farmStatus.STATUS_APPROVED in farms[0].status ? (
              <FarmExpiration>
                {farms[0].status[farmStatus.STATUS_APPROVED].date ? new moment(farms[0].status[farmStatus.STATUS_APPROVED].date.toDate()).format('DD/MM/YYYY') : ''}
              </FarmExpiration>
            ) : (
              <></>
            )}
          </Card>
          <Card>
            <SVGObject svg={'WheatBag'} />
            <CardTitle>Lotes</CardTitle>
            <Counters>
              <Counter
                color={colors.bostonBlue}
                number={batches.length}
                text={'ativos'}
              />
              <Counter
                color={'#47AC34'}
                number={batches.reduce((acc, value) => {
                  if (value.sold) {
                    return acc + 1
                  } else {
                    return acc
                  }
                }, 0)}
                text={'vendidos'}
              />
            </Counters>
          </Card>
          <Card>
            <SVGObject svg={'MoneyBag'} />
            <CardTitle>Total Recebido</CardTitle>
            <Value>R$ 0,00</Value>
          </Card>
        </CardsContainer>
      ) : (
        <Banner>
          <div id='info-container'>
            <div id='icon'>
              <img src={farmLogo} alt='Farm' />
            </div>
            <div id='info'>
              <strong>Cadastre sua propriedade</strong>
              <span>
                Você precisa cadastrar sua propriedade para começar a vender
                seus lotes.
              </span>
            </div>
          </div>
          <button type='button' onClick={handleRegisterFarm}>
            Cadastrar propriedade
          </button>
        </Banner>
      )}
    </Container>
  )
}

import { getFirebase } from 'react-redux-firebase'
import {
  BATCH_COLLECTION,
  PRODUCT_COLLECTION,
  FARM_COLLECTION,
} from '../consts/collections'
import { status as farmStatus } from '../consts/farm'
import { userHasAccessToFarm, getParams, userHasRole } from './helper'

export const create = async (dispatch, getState, { farmId, productId, ...data }) => {
  const firebase = getFirebase()

  if (!userHasAccessToFarm(farmId)) {
    dispatch({
      type: 'batch/create/error',
      error: { 
        code: 'no-access',
        message: 'User has no access to farm' 
      },
    })
    return
  }

  const farmDoc = await firebase
    .firestore()
    .collection(FARM_COLLECTION)
    .doc(farmId)
    .get()
  const farm = farmDoc.data()

  if (!farm.status[farmStatus.STATUS_APPROVED]) {
    dispatch({
      type: 'batch/create/error',
      error: { 
        code: 'farm-status',
        message: 'Farm is not approved yet' 
      },
    })
    return
  }

  const params = await getParams('negotiation')

  firebase
    .firestore()
    .collection(BATCH_COLLECTION)
    .add({
      ...data,
      farm: firebase.firestore().collection(FARM_COLLECTION).doc(farmId),
      status: 'originatingOffer',
      product: firebase
        .firestore()
        .collection(PRODUCT_COLLECTION)
        .doc(productId),
      expiration: params.batchExpirationDays,
      createdBy: firebase.auth().currentUser.uid,
      statusHistory: []
    })
    .then(async (doc) => {
      await firebase
        .firestore()
        .collection(doc.ref.path)
        .collection('statusHistory')
        .doc('open')
        .set({
          date: firebase.firestore.Timestamp.fromDate(new Date()),
          user: firebase.auth().currentUser.uid,
          status: 'open',
        })
      dispatch({ type: 'batch/created', data: doc })
    })
    .catch((error) => {
      dispatch({ type: 'batch/create/error', error })
    })
}

export const setStatus = async (dispatch, getState, batchId, status) => {
  const firebase = getFirebase()
  if (!userHasRole(['farmer', 'admin', 'buyer'])) {
    dispatch({
      type: 'batch/status/error',
      error: { 
        code: 'no-permission',
        message: 'User has no permission to change status' 
      },
    })
    return
  }

  return firebase
    .firestore()
    .collection(BATCH_COLLECTION)
    .doc(batchId)
    .collection('statusHistory')
    .doc(status)
    .set({
      date: firebase.firestore.Timestamp.fromDate(new Date()),
      user: firebase.auth().currentUser.uid,
      status: status,
    })
    .then((data) => {
      firebase.firestore().collection(BATCH_COLLECTION).doc(batchId).update({status: status})
      dispatch({ type: 'batch/status/changed', status })
    })
    .catch((error) => {
      dispatch({ type: 'batch/status/error', error })
    })
}

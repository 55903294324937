import React, { useState } from 'react';
import Fade from '@material-ui/core/Fade';

import image  from 'assets/welcome_buyer.png'

import { useIntl } from 'react-intl'
import messages from './messages'

import * as s from './styles';

function WelcomeBuyerModal() {
  // eslint-disable-next-line
  const [modalTruckOpen, setModalTruckOpen] = useState(true);
  const { formatMessage } = useIntl()

  return (
    <div>
      <Fade in={modalTruckOpen}>
        <s.Container>
          <img src={image} alt='Buyer Home' class='header' />
          <s.Title><b>{formatMessage(messages.title)}</b></s.Title>
          <s.Info>{formatMessage(messages.message)}</s.Info>
        </s.Container>
      </Fade>
    </div>
  )

}

export default WelcomeBuyerModal;
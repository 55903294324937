import { defineMessages } from 'react-intl'

const messages = defineMessages({
  errorRequired: {
    id: 'form.required.fileds',
    defaultMessage: 'Os campos com * são obrigatórios'
  },
  errorMismatch: {
    id: 'form.email.mismatch',
    defaultMessage: 'Os endereços de e-mail estão diferentes'
  },
  title: {
    id: 'confirmEmailBuyer.title',
    defaultMessage: 'Falta pouco!',
  },
  subTitle: {
    id: 'confirmEmailBuyer.text.subTitle',
    defaultMessage: 'Agora só precisamos que você crie seus dados de acesso.',
  },
  email: {
    id: 'confirmEmailBuyer.input.email',
    defaultMessage: 'E-mail',
  },
  confirmEmail: {
    id: 'confirmEmailBuyer.input.confirmEmail',
    defaultMessage: 'Confirmar E-mail',
  },
  step: {
    id: 'confirmEmailBuyer.text.step',
    defaultMessage: 'etapa 6 de 9',
  },
  back: {
    id: 'confirmEmailBuyer.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'confirmEmailBuyer.button.continue',
    defaultMessage: 'Continuar',
  },
  error: {
    id: 'confirmEmailBuyer.text.error',
    defaultMessage: 'E-mail inválido!',
  },
  warning: {
    id: 'confirmEmailBuyer.text.warning',
    defaultMessage: 'Os e-mails devem ser iguais!',
  },
})

export default messages

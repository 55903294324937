import styled, { css } from 'styled-components'
import { colors, device } from 'styles'

export const Container = styled.div`
  background: ${colors.white};
  display: flex;
  height: 100%;
  overflow-y: auto;
  margin-top: ${({ marginTop }) => marginTop || '24px'};

  & > :not(:last-child) {
    margin-right: 15px;
  }
`

export const BankData = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;

  ${({ bordered }) =>
    bordered &&
    css`
      border: 1px solid ${colors.silver};
      padding: 20px 25px;
      width: 320px;
      @media ${device.untilTablet} {
        width: 100%;
      }
    `}

  & > * {
    margin-bottom: 5px;
  }

  & > :first-child {
    color: ${colors.mineShaft};
    font-size: 24px;
  }

  & > .cpf {
    font-size: 18px !important;
  }

  & > :not(:first-child) {
    color: ${colors.silverChalice3};
    font-size: 16px;

    & > span {
      font-size: 12px;
    }
  }

  & .bank-data-title {
    color: ${colors.silver};
    font-size: 16px;
    font-weight: 400;
  }
`

export const Tabs = styled.div`
  display: flex;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${device.untilTablet} {
    overflow-x: auto;
  }
`

export const TabItem = styled.div`
  display: flex;
  font-size: 20px;
  border-bottom: 1px solid ${colors.alto3};
  padding-bottom: 10px;
  color: ${colors.silverChalice5};
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  margin: 50px 0;

  @media ${device.untilTablet} {
    color: ${colors.apple};
    background-color: transparent;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.apple};
    margin: 0 0 20px 5px;
    white-space: nowrap;
  }

  ${({ selected }) =>
    selected &&
    css`
      color: ${colors.apple};
      font-weight: bold;
      border-bottom: 2px solid ${colors.apple};
      @media ${device.untilTablet} {
        color: ${colors.white};
        background-color: ${colors.apple};
        border-radius: 30px;
        font-size: 15px;
        font-weight: 500;
        padding: 10px 18px;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    `}
`

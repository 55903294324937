import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from './styles'
import Title from 'components/Title'
import Text from 'components/Text'
import Button from 'components/Button'
import ErrorAgent from 'components/ErrorAgent'
import { Link } from 'react-router-dom'
import logo from 'assets/logo.svg'
import { useUser } from 'modules/data/hooks/useUser'

export default function VerifyEmail(props) {
  const [ error, setError ] = useState(false)
  const user = useUser()

  useEffect( () => {
    user.verifyEmail(props.code)
    // eslint-disable-next-line
  }, [ ])


  return (
    <Container>
      <FormContainer>
        <ErrorAgent 
          group='emailValidation' 
          onError={e => setError(true)} 
          onClear={e => setError(false)}
        />
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Title className='title'>Confirmação de conta</Title>
              <div>
                <Text>{ error ? 'Não foi possível validar sua conta.' : 'Sua conta foi confirmada com sucesso.' }</Text>
              </div>
            <Footer>
              <Buttons>
                <Link to='/'>
                  <Button
                    type='button'
                    className='continue'
                  >Ir para o início</Button>
                </Link>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

import React, { useState } from 'react'
import { Container } from './styles'
import Input from 'components/Input'
import { Map, GoogleApiWrapper } from 'google-maps-react'
import { maps } from 'modules/data/consts/apiTokens'

function SearchAddress(props) {
  const [place, setPlace] = useState('')
  const [mapService, setMapService] = useState({})
  const [placeList, setPlaceList] = useState([])
  const { label } = props

  const initServices = (mapProps, map) =>
    setMapService({
      map,
      service: new props.google.maps.places.PlacesService(map),
    })

  const search = (address) => {
    setPlace(address.value)
    if (address.value.length < 6) return
    const { google } = props
    const request = {
      query: address.value,
      fields: ['formatted_address', 'types', 'geometry'],
    }

    mapService.service.textSearch(request, function (results, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setPlaceList(results)
      }
    })
  }

  const chooseAddress = (place) => {
    setPlace(place.formatted_address)
    setPlaceList([])

    const geocoder = new props.google.maps.Geocoder()
    let address = {
      route: '',
      number: '',
      locality: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      formatted_address: place.formatted_address,
    }
    geocoder.geocode(
      { location: place.geometry.location },
      (results, status) => {
        if (status === 'OK') {
          let it = 0
          results[0].address_components.forEach((result) => {
            if (result.types.includes('route')) address.route = result.long_name
            if (result.types.includes('street_number'))
              address.number = result.long_name
            if (result.types.includes('locality'))
              address.locality = result.long_name
            if (result.types.includes('sublocality_level_1'))
              address.neighborhood = result.long_name
            if (result.types.includes('postal_code'))
              address.postalCode = result.long_name
            if (result.types.includes('administrative_area_level_2'))
              address.city = result.long_name
            if (result.types.includes('administrative_area_level_1'))
              address.state = result.long_name
            if (result.types.includes('country'))
              address.country = result.long_name

            if (++it === results[0].address_components.length) {
              props.onResult(address, place)
            }
          })
        }
      }
    )
  }

  let itemList = placeList
    // .filter(place => place.types.includes('locality'))
    .map((place) => {
      return (
        <li onClick={(e) => chooseAddress(place)} className='address-option'>
          {place.formatted_address}
        </li>
      )
    })

  return (
    <Container>
      <Input
        placeholder={ label ? label : 'Buscar endereço' }
        value={place}
        onChange={({ target }) => search(target)}
      />
      <ul className='address-list'>{itemList}</ul>

      <Map
        google={props.google}
        onReady={initServices}
        containerStyle={{ width: '90%', height: '0px' }}
        visible={false}
        zoom={8}
        centerAroundCurrentLocation={true}
      />
    </Container>
  )
}

export default GoogleApiWrapper({
  apiKey: maps.key,
})(SearchAddress)

import React, { useState, useEffect } from 'react'
import {
  Container, FormContainer, Logo, Footer, Buttons,  Content
} from './styles'
import Text from 'components/Text'
import Title from 'components/Title'
import logo from 'assets/logo.svg'
import phoneIcon from 'assets/phone.svg'
import Button from 'components/Button'
import { Link, useHistory } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useUser } from 'modules/data/hooks/useUser';
import { useBuyer } from 'modules/data/hooks/useBuyer';
import PhoneModal from 'components/PhoneModal'
import { toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import messages from './messages'

const equalNumbers = (x, y) => {
  if(!x || !y) return false
  if(typeof x !== 'string' || typeof y !== 'string') return false
  return x.replace(/\D/g, "") === y.replace(/\D/g, "")
}
export default function VerifyPhoneNumber() {
  const [code, setCode] = useState(0)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const user = useUser()
  const { buyer } = useBuyer()
  const { formatMessage } = useIntl()

  const handleNextStep = () => {
    if(equalNumbers(buyer.phone, user.phoneNumber)) {
      history.push('/buyer-step-terms')
    }
    if(!code) {
      toast.warn(formatMessage(messages.error))
      return
    }
  }

  useEffect( () => {
    if(equalNumbers(buyer.phone, user.phoneNumber)) {
      setLoading(false)
    }
  }, [ buyer, user])

  return (
    <Container>
    <FormContainer>
      <Logo>
        <img src={logo} alt='Agricon' />
      </Logo>
      <form action=''>
        <img src={phoneIcon} alt='Phone' />
        <Content>
        <Title className='title' color='primary'>
          {formatMessage(messages.title)}
        </Title>
        {
          equalNumbers(buyer.phone, user.phoneNumber) ? 
          <div>
            <Text className='infoDone'>{formatMessage(messages.done)}</Text>
          </div> :
          <div>
            <Text className='info'>{formatMessage(messages.info)}</Text>
            <PhoneModal
              submitId='sms'
              onSuccess={e => history.push('/buyer-step-terms')}
              onError={e => setLoading(false)}
              onLoading={e => setLoading(e)}
              onCode={code => setCode(code)}
              phone={buyer.phone}
            />
          </div>          
        } 
        <Footer>
          <Buttons>
            <Link className='voltar-desktop' to='/buyer-step-phone'>
              <Text color='primary'>{formatMessage(messages.back)}</Text>
            </Link>
            <Link className='voltar-mobile' to='/buyer-step-phone'>
              <img src={voltar} alt='voltar' />
            </Link>
            <Button 
              loading={loading}
              type="button" 
              id="sms" 
              className="continue" 
              onClick={handleNextStep}>
              {equalNumbers(buyer.phone, user.phoneNumber) ? formatMessage(messages.next) : formatMessage(messages.continue)}
            </Button>
          </Buttons>
        </Footer>
        </Content>
      </form>
    </FormContainer>
    </Container>
  )
}

import styled from 'styled-components'
import { colors, fonts, device } from 'styles'

export const Container = styled.div`
  max-width: 863px;
  height: calc(100% + 80px);

  hr {
    border-top: 1px solid #e2e2e2;
  }
`
export const Header = styled.div`
  margin: 30px;

  @media ${device.untilTablet} {
    padding: 30px;
    margin: 0;
    background-color: #005d16;

    h1,
    p {
      color: #fff;
    }
  }

  h1 {
    font-weight: 400;
    margin-bottom: 7px;
  }
`

export const Form = styled.div`
  label {
    @media ${device.untilTablet} {
      width: 100%;
      margin: 20px 0 0 0;
    }
  }

  #buttonContainer {
    display: flex;
    justify-content: center;
    padding: 20px;

    @media ${device.untilMobileM} {
      padding: 0;
    }

    #finishing {
      width: 150px;
      height: 50px;
      background: #47ac34;
      font-weight: bold;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;
      border: 0;
      margin-top: 15px;
      margin-bottom: 15px;
      border-radius: 5px;
      font-family: ${fonts.primary};

      @media ${device.untilMobileM} {
        width: 135px;
      }
    }

    #cancel {
      width: 150px;
      height: 50px;
      background: #cfcfcf;
      cursor: pointer;
      border: 0;
      color: #ffffff;
      font-weight: bold;
      font-size: 18px;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 10px;
      border-radius: 5px;
      font-family: ${fonts.primary};

      @media ${device.untilMobileM} {
        width: 135px;
      }
    }
  }

  footer {
    height: 80px;
    width: 100%;
    background: red;
    position: fixed;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px -10px 40px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: #b8b8b8;
      width: 384px;
      margin-left: 40px;
      margin-right: 200px;
    }

    div {
      width: inherit;
      height: inherit;
      align-self: flex-end;

      #finishing {
        width: 200px;
        height: 50px;
        background: #47ac34;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        border: 0;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      #cancel {
        width: 150px;
        height: 50px;
        background: transparent;
        cursor: pointer;
        border: 0;
        color: #565656;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 10px;
      }
    }
  }

  #farm-data {
    div {
      margin-right: 20px;

      @media ${device.untilTablet} {
        margin: 0;
      }
    }
    padding: 20px 20px 0;
  }

  #farm-location {
    padding: 0 20px 0;

    div {
      margin-right: 20px;

      @media ${device.untilTablet} {
        margin: 0;
      }
    }
  }

  #image {
    padding: 0 20px;
    height: 40vh;
    width: 100%;

    @media ${device.untilTablet} {
      height: 300px;
    }
  }

  strong {
    color: #7d7d7d;
    padding: 20px;
    display: block;
  }

  .option {
    display: flex;
    align-items: center;

    padding: 20px;

    .container-check {
      display: flex;
      span {
        margin-left: 10px;
        margin-right: 40px;
        display: flex;
        color: ${colors.dorado};
        font-style: italic;
      }
    }

    .item {
      width: 20px;
      height: 20px;
      background: ${colors.silver};
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .check {
      width: 12px;
      height: 12px;
      background: ${colors.apple};
      border-radius: 50%;
    }
  }

  #cpf {
    width: 250px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    a {
      margin-top: 10px;
      color: #005d16;
    }
    margin-bottom: 80px;
  }
`

export const Cards = styled.div`
  padding: 0 20px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: left;
  width: 100%;

  @media ${device.untilTablet} {
    justify-content: center;
  }

  .card {
    width: 130px;
    height: 130px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    cursor: pointer;
    margin-right: 25px;
    margin-top: 20px;

    @media ${device.untilMobileM} {
      width: 115px;
      height: 115px;
      margin: 10px;
    }

    img {
      display: block;
      height: 45px;
    }

    p {
      display: block;
      width: 100%;
      text-align: center;
      margin: 20px 0 0 0;

      @media ${device.untilMobileM} {
        font-size: 14px;
      }
    }
  }

  .active {
    border: 3px solid #00bd00;
  }

  .active > object {
    fill: #00bd00;
  }
`
export const OqueProduz = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: ${colors.boulder};
  padding: 20px 20px 0;
  margin-top: 20px;
`

export const MapDescription = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: ${colors.apple};
  margin-bottom: 20px;
`

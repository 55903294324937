import styled from 'styled-components'
import { colors } from 'styles'
import Select from 'components/Select'

export const Container = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 400px;
  overflow-y: auto;
`
export const CustomSelect = styled(Select)`
  & select {
    background: ${colors.concrete};
    border: 1px solid ${colors.silver7};
    height: 50px;
    font-size: 18px;
  }

  & .select-placeholder {
    margin-bottom: 25px;
  }
`

export const FormContent = styled.div`
  padding: 20px;
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useUser } from '../modules/data/hooks/useUser'

export function EnabledRoute({ component: Component, ...rest }) {
  const { user, roles } = useUser()
  const enabled = user.enabled || roles.farmer !== null;
  // const enabled = (roles.buyer === false && buyer.enabled === true) || (roles && !roles.buyer)

  return (
    <Route
      {...rest}
      render={(props) =>
        enabled === true ? (
          <Component {...props} />
        )
        : (
          <Redirect to={{ pathname: '/platform', state: { from: props.location } }} />
        )
      }
    />
  )
}
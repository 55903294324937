import styled, { keyframes } from 'styled-components';
import logo from 'assets/home_logo.svg'

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-image: ${logo};
`;

const run = keyframes`
	0% {
	    left: -90px;
	    color: #eee;
	  }
	  50% {
	    color: #666;
	  }
	  100% {
	    left: 90px;
	    color: #eee;
	  }
`;

export const Loader = styled.div`
	font-size: 100px;
	margin-top: -80px;
	position: relative;
	animation: ease-in-out infinite alternate;
	animation-name: ${run};
	animation-duration: 1.2s;
`;


import styled from 'styled-components'

import { shade } from 'polished'
import { colors, device } from 'styles'

export const Container = styled.div`
  width: calc(100% - 250px);
  display: flex;
  justify-content: center;

  @media ${device.untilTablet} {
    width: 100%;
  }

  #content {
    width: 100%;
    overflow-y: auto;
    padding-bottom: 150px;

    @media ${device.untilTablet} {
      position: relative;
      overflow-y: unset;
      padding-bottom: unset;
    }
  }

  #floatButton {
    @media ${device.untilTablet} {
      display: initial;
      font-size: 16px;
    }

    display: none;
    position: fixed;
    bottom: 70px;
    margin: 0;
    width: auto;
  }

  /* Handle */
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
    margin: 10px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${colors.silver3};
  }
`

export const Card = styled.div`
  margin: 20px;
  border-radius: 10px;
  background: rgba(0, 155, 189, 0.2);
  border: 1px solid #009bbd;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  #info-container {
    display: flex;
    align-items: center;

    #icon {
      background: #fff;
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      svg {
        fill: #30acc7;
      }
    }
  }

  #info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    strong {
      color: #2d97ae;
    }
    span {
      color: #2d97ae;
      margin-top: 5px;
    }
  }

  #buttons {
    button:nth-child(2) {
      opacity: 0.7;
    }
    button {
      padding: 10px;
      border: 0;
      background: #2d97ae;
      border-radius: 5px;
      color: #fff;
      cursor: pointer;
      margin: 10px;
      width: 107px;

      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.2, '#2D97AE')};
      }
    }
  }
`

export const Header = styled.div`
  margin: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.untilTablet} {
    position: fixed;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.17);
    margin: 0;
    padding: 12px;
  }

  button {
    padding: 10px;
    width: 190px;
    border: 0;
    background: #565656;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s;
    font-size: 16px;

    &:hover {
      background: ${shade(0.2, '#565656')};
    }

    @media ${device.untilTablet} {
      display: none;
    }
  }

  #title {
    display: flex;
    flex-direction: column;

    @media ${device.untilTablet} {
      display: block;
      text-align: center;
      width: 100%;
    }

    span {
      color: #a2a2a2;

      @media ${device.untilTablet} {
        margin-right: 5px;
        color: #565656;
      }
    }
    strong {
      color: #005d16;

      @media ${device.untilTablet} {
        color: #565656;
      }
    }
  }
`

export const Content = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.untilTablet} {
    margin-bottom: 100px;
    /* overflow-y: auto; */
    height: 100%;
    justify-content: start;
    padding: 0 10px 220px;

    p {
      text-align: center;
    }

    #icon {
      margin-bottom: 40px;
    }
  }

  button {
    margin-top: 30px;
    padding: 10px;
    width: 256px;
    border: 0;
    background: #47ac34;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;

    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#47AC34')};
    }
  }

  span {
    margin-top: 50px;
  }
`

export const ContentEmpty = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.untilTablet} {
    margin-bottom: 100px;
    overflow-y: auto;
    height: 100%;
    justify-content: center;
    padding: 0 10px 220px;

    p {
      text-align: center;
    }

    #icon {
      margin-bottom: 40px;
    }
  }

  button {
    margin-top: 30px;
    padding: 10px;
    width: 256px;
    border: 0;
    background: #47ac34;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;

    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#47AC34')};
    }
  }

  span {
    margin-top: 50px;
  }
`

import styled, { css } from 'styled-components'
import { colors, device } from 'styles'

import AwaitingNegotiationSVG from 'assets/NegotiationStatus/awaiting-negotiation.svg'
import AwaitingSaleConfirmationSVG from 'assets/NegotiationStatus/awaiting-sale-confirmation.svg'
import LoadingCargoSVG from 'assets/NegotiationStatus/loadingCargo.svg'
import CargoLoadedSVG from 'assets/NegotiationStatus/cargoLoaded.svg'
import InTransitSVG from 'assets/NegotiationStatus/inTransit.svg'
import CrossDockingSVG from 'assets/NegotiationStatus/crossDocking.svg'
import InCertificationSVG from 'assets/NegotiationStatus/inCertification.svg'
import InPortSVG from 'assets/NegotiationStatus/inPort.svg'
import InCargoShipSVG from 'assets/NegotiationStatus/inCargoShip.svg'

import Sold from 'assets/NegotiationStatus/sold.svg'
import Canceled from 'assets/NegotiationStatus/canceled.svg'

const statusProperties = {
  sold: { color: colors.apple },
  originatingOffer: {
    color: colors.curiousBlue,
    icon: css`
      background: url(${AwaitingNegotiationSVG});
      background-repeat: no-repeat;
      width: 60px;
      height: 80px;
    `,
    statusHeader: css`
      display: none;
    `,
  },
  awaitingNegotiation: {
    color: colors.fuelYellow,
    icon: css`
      background: url(${AwaitingSaleConfirmationSVG});
      background-repeat: no-repeat;
      width: 60px;
      height: 80px;
    `,
    statusHeader: css`
      display: none;
    `,
  },
  awaitingSaleConfirmation: {
    color: colors.fuelYellow,
    icon: css`
      background: url(${AwaitingSaleConfirmationSVG});
      background-repeat: no-repeat;
      width: 60px;
      height: 80px;
    `,
    statusHeader: css`
      display: none;
    `,
  },
  awaitingLoading: {
    color: colors.apple,
    icon: css`
      background: url(${LoadingCargoSVG});
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      top: 25px;
    `,
  },
  loadingConfirmed: {
    color: colors.apple,
    icon: css`
      background: url(${CargoLoadedSVG});
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      top: 25px;
    `,
  },
  inTransit: {
    color: colors.apple,
    icon: css`
      background: url(${InTransitSVG});
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      top: 25px;
    `,
  },
  crossDocking: {
    color: colors.apple,
    icon: css`
      background: url(${CrossDockingSVG});
      background-repeat: no-repeat;
      width: 50px;
      height: 80px;
      top: 15px;
    `,
  },
  inCertification: {
    color: colors.apple,
    icon: css`
      background: url(${InCertificationSVG});
      background-repeat: no-repeat;
      width: 45px;
      height: 80px;
      top: 25px;
    `,
  },
  inPort: {
    color: colors.apple,
    icon: css`
      background: url(${InPortSVG});
      background-repeat: no-repeat;
      width: 55px;
      height: 80px;
      top: 30px;
    `,
  },
  inCargoShip: {
    color: colors.apple,
    icon: css`
      background: url(${InCargoShipSVG});
      background-repeat: no-repeat;
      width: 55px;
      height: 80px;
      top: 30px;
    `,
  },
  done: {
    color: colors.green,
    icon: css`
      background: url(${Sold});
      background-repeat: no-repeat;
      width: 45px;
      height: 80px;
      top: 35px;
    `,
  },
  canceled: {
    color: colors.fuzzyWuzzyBrown,
    icon: css`
      background: url(${Canceled});
      background-repeat: no-repeat;
      width: 60px;
      height: 80px;
      top: 30px;
    `,
    statusFooter: css`
      display: none;
    `,
  },
}

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;

  @media ${device.untilTablet} {
    padding-bottom: 200px;
    padding-top: 30px;
  }

  & > :not(:last-child) {
    margin-right: 30px;
  }
`

export const Img = styled.img`
  height: 100%;
  width: 100%;
  max-width: 120px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  @media ${device.untilTablet} {
    display: none;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding-left: 15px;

  & > :not(:last-child) {
    margin-bottom: 15px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  height: 35px;
`

export const StatusFooter = styled.div`
  width: 100%;
  height: 30px;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom-right-radius: 5px;

  @media ${device.untilTablet} {
    position: absolute;
    top: 0;
    left: -6px;
    width: 10px;
    height: 100%;
    font-size: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
`

export const StatusHeader = styled.div`
  color: ${colors.white};
  padding: 8px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 5px;
`

export const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-right: 5px;
`

export const Text = styled.p`
  color: ${({ color }) => (color ? color : colors.silverChalice4)};
  font-style: italic;

  ${({ fontSize }) =>
    css`
      font-size: ${fontSize || '12px'};
    `}

  ${({ defaultColor }) =>
    defaultColor &&
    css`
      color: ${colors.defaultText};
    `}

  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}
`

export const StatusIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin-top: auto;
  margin-bottom: auto;

  @media ${device.untilTablet} {
    display: none;
  }
`

export const Item = styled.div`
  background: ${colors.white};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  height: 150px;
  min-width: 550px;
  display: flex;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  margin-bottom: 30px;

  @media ${device.untilTablet} {
    height: auto;
    width: 100%;
    min-width: 100%;
  }

  ${({ status = 'awaitingNegotiation' }) =>
    css`
      & ${Title} {
        color: ${statusProperties[status].color};
      }

      & ${StatusHeader}, & ${StatusFooter} {
        background: ${statusProperties[status].color};
      }

      & ${StatusIcon} {
        ${statusProperties[status].icon}
      }

      & ${StatusHeader} {
        ${statusProperties[status].statusHeader}
      }

      & ${StatusFooter} {
        ${statusProperties[status].statusFooter}
      }
    `}
`

export const Infos = styled.div`
  display: flex;
  align-items: center;
`

export const Time = styled.div`
  display: flex;
  align-items: center;
  padding-left: 25px;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`

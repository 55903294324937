import React, { useEffect, useState, useRef } from 'react'

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

import SVG from '../SVG'
import Text from '../Text'

import * as s from './styles'
import * as shared from '../styles-shared'

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(0, 93, 22);',
    color: 'white',
    maxWidth: 260,
    fontSize: 15,
    borderRadius: '5px',
    padding: 10,
    
  },
  arrow: {
    color: 'rgba(0, 93, 22);',
  }
}))(Tooltip);

export default function Select({
  options,
  placeholder,
  placeholderAlign,
  value,
  required,
  showError,
  theme,
  tooltip,
  multiple,
  onChange,
  name,
  arrowColor = 'black',
  ...props
}) {
  const [visible, setVisible] = useState(false);
  const [multipleValues, setMultipleValues] = useState([]);
  const ref = useRef();

  const hasValue = !!value

  const setValue = (v) => {
    onChange && onChange({ preventDefault: () => {}, target: { name, value: v } });
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { target } = e
    const v = [];
    let selectedOption=(target.selectedOptions);

    for (let i = 0; i < selectedOption.length; i++){
        v.push(selectedOption.item(i).value)
    }

    setValue(v);
  }

  const handleClickOption = (opt, i, e) => {
    e.preventDefault()
    if (multiple) {
      const newMultipleValues = multipleValues;
      const index = multipleValues.findIndex(m => m.val === opt.val)
      if (index > -1) {
        newMultipleValues.splice(index, 1);
      } else {
        newMultipleValues.splice(i, 0, opt);
      }
      if (!newMultipleValues.length < 1) {
        setValue(newMultipleValues.map(m => m.val))
        setMultipleValues(newMultipleValues)
      }
    }
  }

  const handleClick = (e) => {
    if (!ref.current.contains(e.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);

    return () => document.removeEventListener('mousedown', handleClick, false);
  }, []);

  return (
    <s.SelectStyled visible={visible} onClick={() => setVisible(true)} theme={theme} multiple={multiple} hasValue={hasValue} {...props}>
      {!!placeholder && (
        <s.Label
          theme={theme}
          hasValue={hasValue}
          className='select-placeholder'
          placeholderAlign={placeholderAlign}>
          {placeholder}
          {required && <shared.Required>*</shared.Required>}
          {tooltip && <CustomTooltip title={tooltip} placement="left" style={{ zIndex: '99999' }}arrow>
            <Button className="question-mark">
              <SVG.QuestionMark />
            </Button>
          </CustomTooltip>}
        </s.Label>
      )}
      <div>
        {multiple && <s.MultipleLabel>{multipleValues.map(m => m.label).join(', ')}</s.MultipleLabel>}
        <select ref={ref} multiple={multiple} className='select-element' onChange={handleChange} name={name} value={value} {...props}>
          <option value={null} selected={!value} disabled>
            {' '}
          </option>
          {options.map(({ value: val, label }, i) => {
            return (
              <option onClick={(e) => handleClickOption({ val, label }, i, e)} key={`${placeholder}-${val}-${i}-option`} value={val}>
                {label}
              </option>
            )
          })}
        </select>
        <SVG.ArrowDown color={arrowColor} />
      </div>
      {showError && (
        <Text fontSize='13px' color='danger'>
          {required &&
            !options.some((o) => `${o.value}` === `${value}`) &&
            'Campo obrigatório'}
        </Text>
      )}
    </s.SelectStyled>
  )
}
import React from 'react'

import Text from 'components/Text'
import SVG from '../SVGSimple'

import * as s from './styles'

export default function WithBadge({
  svg,
  ComponentBadge,
  badgeBackground,
  badgeBorderColor,
  badgeSize,
}) {
  const SVGComponent = SVG[svg]

  if (!SVGComponent || !ComponentBadge) {
    return null
  }

  return (
    <s.Container>
      <SVGComponent />
      <s.Badge
        size={badgeSize}
        background={badgeBackground}
        borderColor={badgeBorderColor}>
        {ComponentBadge}
      </s.Badge>
    </s.Container>
  )
}

export function SVGWithNotification({ svg, count }) {
  return (
    <WithBadge
      svg={svg}
      ComponentBadge={
        <Text color='white' fontSize='14px' fontWeight='bold'>
          {count}
        </Text>
      }
      badgeBackground='primary'
      badgeBorderColor='primary'
      badgeSize='notification'
    />
  )
}

export function Buyer() {
  return (
    <WithBadge svg='Farmer' ComponentBadge={<SVG.MoneyBag color='primary' />} />
  )
}

export function Producer() {
  return (
    <WithBadge svg='Farmer' ComponentBadge={<SVG.WheatBag color='primary' />} />
  )
}

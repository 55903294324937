import moment from 'moment'
import messages from './messages'

export function parseToMoneyBRL(value) {
  if (!value) {
    return 'R$ 0,00'
  }
  return parseMoneyToCurrency(value, 'BRL')
}

export function parseMoneyToCurrency(value = 0, currency) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: currency,
  }).format(value)
}

export function getMessageWelcome() {
  let d = new Date()
  let hour = d.getHours()
  if (hour < 5) {
    return messages.goodNight
  }
  if (hour < 12) {
    return messages.goodMorning
  }
  if (hour < 18) {
    return messages.goodAfternoon
  }

  return messages.goodNight
}

export const getDays = (dateTime, expiration = 0) => {
  // const { formatMessage } = useIntl()
  let message
  const initialDay = moment(dateTime.toDate()).add(expiration, 'days')
  const today = moment()

  const remainingDays = initialDay.diff(today, 'days') + 1

  // if (remainingDays > 1) message = remainingDays + ' ' + formatMessage(messages.days)
  // else if (remainingDays < 1) message = formatMessage(messages.expired)
  // else message = remainingDays + formatMessage(messages.day)

  if (remainingDays > 1) message = remainingDays + ' days'
  else if (remainingDays < 1) message = 'Expired'
  else message = remainingDays + 'day'

  return message
}

export const getStatusDescription = (status) => {
  switch (status) {
    case 'originatingOffer':
      return messages.originatingOffer
    case 'awaitingSaleConfirmation':
      return messages.awaitingSaleConfirmation
    case 'productOriginated':
      return messages.productOriginated
    case 'canceled':
      return messages.canceled
    case 'sold':
      return messages.sold
    default:
      return messages.originatingOffer
  }
}

export const getLoiStatusDescription = (status) => {
  switch (status) {
    case 'awaitingNegotiation':
      return messages.awaitingNegotiation
    case 'originatingOffer':
      return messages.originatingOffer
    case 'awaitingSaleConfirmation':
      return messages.awaitingSaleConfirmation
    case 'productOriginated':
      return messages.productOriginated
    case 'loadingCargo':
      return messages.loadingCargo
    case 'inTransit':
      return messages.inTransit
    case 'done':
      return messages.done
    case 'crossDocking':
      return messages.crossDocking
    case 'inCargoShip':
      return messages.inCargoShip
    case 'inPort':
      return messages.inPort
    case 'inCertification':
      return messages.inCertification
    case 'canceled':
      return messages.canceled
    default:
      return messages.originatingOffer
  }
}

export const getBatchStatusDescription = (status) => {
  switch (status) {
    case 'awaitingNegotiation':
      return messages.awaitingNegotiation
    case 'awaitingSaleConfirmation':
      return messages.awaitingSaleConfirmation
    case 'awaitingLoading':
      return messages.awaitingLoading
    case 'loadingConfirmed':
      return messages.loadingConfirmed
    case 'inTransit':
      return messages.inTransit
    case 'crossDocking':
      return messages.crossDocking
    case 'inCertification':
      return messages.inCertification
    case 'inPort':
      return messages.inPort
    case 'inCargoShip':
      return messages.inCargoShip
    case 'done':
      return messages.done
    case 'canceled':
      return messages.canceled
    default:
      return messages.awaitingNegotiation
  }
}

export const negotiationStatus = {
  AWATING_NEGOTIATION: 'awaitingNegotiation',
  ORIGINATING_OFFER: 'originatingOffer',
  AWAITING_SALE_CONFIRMATION: 'awaitingSaleConfirmation',
  PRODUCT_ORIGINATED: 'productOriginated',
  LOADING_CARGO: 'loadingCargo',
  AWATING_LOADING: 'awaitingLoading',
  LOADING_CONFIRMED: 'loadingConfirmed',
  IN_TRANSIT: 'inTransit',
  DONE: 'done',
  CROSS_DOCKING: 'crossDocking',
  IN_CARGO_SHIP: 'inCargoShip',
  IN_PORT: 'inPort',
  IN_CERTIFICATION: 'inCertification',
  CANCELED: 'canceled'
}
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'form.required.fileds',
    defaultMessage: 'Os campos com * são obrigatórios'
  },
  title: {
    id: 'buyerTerms.title',
    defaultMessage: 'Termos de uso',
  },
  subTitle: {
    id: 'buyerTerms.text.subTitle',
    defaultMessage:
      'Não se preocupe. Você pode acessar os termos de uso a hora que quiser.',
  },
  accept: {
    id: 'buyerTerms.input.accept',
    defaultMessage: 'Eu aceito os termos de uso',
  },
  step: {
    id: 'buyerTerms.text.step',
    defaultMessage: 'etapa 9 de 9',
  },
  back: {
    id: 'buyerTerms.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'buyerTerms.button.continue',
    defaultMessage: 'Finalizar',
  },
  checkTerms: {
    id: 'buyerTerms.text.checkTerms',
    defaultMessage: 'Você precisa aceitar os termos!',
  },
})

export default messages

import React from 'react'
import Sidebar from 'components/Sidebar'
import HeaderComponent from 'components/HeaderComponent'
import { Container, Wrapper, SidebarMain } from './styles'
import InternRoutes from 'Routes/internal.routes'
import WelcomeFarmerModal from 'components/WelcomeFarmerModal'
import InactivityLogger from 'components/InactivityLogger'
import { useUser } from 'modules/data/hooks/useUser'

const Platform = () => {
  const { logout } = useUser()
  const inactiveLimit = 30 * 60 * 1000; // 30 * 60 segundos * 1000 milisegundos
  const handleInactivity = () => {
    console.log('Usuário inativo há mais de 30 minutos');
    // Execute a função que deseja aqui
    logout();
  };

  return(
    <Wrapper>
      <Container>
        <HeaderComponent />
        <SidebarMain>
          <Sidebar />

          <InternRoutes />
          <WelcomeFarmerModal />
          <InactivityLogger inactiveTimeThreshold={inactiveLimit} onInactivity={handleInactivity} />
        </SidebarMain>
      </Container>
    </Wrapper>
  )
}

export default Platform

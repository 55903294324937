import React from "react";
import { colors } from "styles";

export default function ({ color, ...props }) {
  const fill = colors[color] || colors.defaultSVG;
  return (
    <svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9975 12.7862L20.2088 22L21.9975 20.2138L12.7837 11L21.9975 1.7887L20.2113 0L10.9975 9.21383L1.78617 0L0 1.7887L9.2113 11L0 20.2113L1.78617 22L10.9975 12.7862Z" fill={fill} />
    </svg>
  );
}

import React from 'react'
import SelectableDiv from 'components/SelectableDiv'

import * as s from './styles'

export default function AddressTypeList({ onSelect, selected, items }) {
  return (
    <s.Container>
      {items.map((c) => {
        const {
          description,
          route,
          neighborhood,
          number,
          city,
          state,
          postalCode,
        } = c

        return (
          <SelectableDiv
            key={c.type}
            selected={selected && selected.description === c.description}
            disabled={c.disabled}
            selectionObject={c}
            setSelected={onSelect}
            max-width={'370px'}
            min-width={'170px'}>
            <s.ContentContainer>
              <s.Title>{description} </s.Title>
              <s.Text>
                {route}, {number} - {neighborhood}
              </s.Text>
              <s.Text>
                {city}, {state}
              </s.Text>
              <s.Text>{postalCode}</s.Text>
            </s.ContentContainer>
          </SelectableDiv>
        )
      })}
    </s.Container>
  )
}

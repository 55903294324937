import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
  Term,
  AcceptTerm,
} from './styles'
import Title from 'components/Title'
import Text from 'components/Text'
import Button from 'components/Button'
import logo from 'assets/logo-green.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useTerms } from 'modules/data/hooks/useTerms'
import { useIntl } from 'react-intl'
import messages from './messages'
import { toast } from 'react-toastify'

export default function Terms() {
  const history = useHistory()
  const [value, setValue] = useState(false)
  const { current } = useTerms()
  const { acceptTerms, buyer } = useBuyer()
  const { formatMessage, locale } = useIntl()

  useEffect( () => {
    if(buyer && buyer.terms) {
      setValue(true)
    }
  }, [ buyer ])

  const handleNextStep = () => {
    if(!value) {
      toast.error(formatMessage(messages.error))
      return
    }
    if (value) {
      acceptTerms(current.version)
      history.push('/buyer-done')
    }
  }

  const handlCheck = () => {
    setValue(!value)
  }

  const terms = locale === 'pt-br' ? current.content.pt_br : current.content.en

  return (
    <Container>
      <div className='bg-image' />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Title className='title'>{formatMessage(messages.title)}</Title>
            <Text>{formatMessage(messages.subTitle)}</Text>
            <Term>
              <Text>{terms}</Text>
            </Term>
            <AcceptTerm>
              <div onClick={handlCheck} className='container'>
                {value && <div className='check' />}
              </div>
              <span>{formatMessage(messages.accept)}</span>
            </AcceptTerm>
            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to='/buyer-verify-phone'>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to='/buyer-verify-phone'>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

import styled from 'styled-components'
import { colors, fonts } from 'styles'

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-height: 150px;
`
export const FormContent = styled.div`
  padding: 20px;
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.p`
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${colors.apple};
`

export const AddButton = styled.p`
  font-family: ${fonts.primary};
  font-size: 18px;
  line-height: 23px;
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.bostonBlue};
  margin-top: 30px;
  align-self: flex-end;
`
export const AddressContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 30px;
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
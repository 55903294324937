import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useAdmin } from 'modules/data/hooks/useAdmin'
import React from 'react'

const Buyers = () => {
  const adminFactory = useAdmin()

  const columns = [
    { id: 'name', label: 'Nome', minWidth: 100 },
    {
      id: 'phone',
      label: 'Fone',
      minWidth: 170,
      align: 'right',
      // format: (value) => value.toLocaleString('en-US'),
    },
    { id: 'id', label: 'ID', minWidth: 170 },
  ]

  return (
    <div>
      <TableContainer
        style={{
          minWidth: 'calc(100vw - 250px)',
          height: 'calc(100vh - 110px)',
        }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {adminFactory.buyers &&
              adminFactory.buyers.map((fam) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={fam.id}>
                    {columns.map((column) => {
                      const value = fam[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )

  // return (
  //   <div>
  //     {adminFactory.users &&
  //       adminFactory.users.map((fam) => {
  //         if (fam.roles.buyer) return <p>{fam.id}</p>
  //       })}
  //   </div>
  // )
}

export default Buyers

import { roles } from './user'

export const status = {
  STATUS_WAITING_FOR_SCHEDULE: 'waiting_for_schedule',
  STATUS_WAITING_FOR_INSPECTION: 'waiting_for_inspection',
  STATUS_WAITING_FOR_VISIT: 'waiting_for_visit',
  STATUS_APPROVED: 'approved',
  STATUS_REPROVED: 'reproved',
}

export const roleAccessToStatus = {
  STATUS_WAITING_FOR_SCHEDULE: [roles.FARMER, roles.ADMIN],
  STATUS_WAITING_FOR_INSPECTION: [roles.OPERATOR, roles.ADMIN],
  STATUS_WAITING_FOR_ANALYSIS: [roles.INSPECTOR, roles.ADMIN],
  STATUS_APPROVED: [roles.OPERATOR, roles.ADMIN],
  STATUS_REPROVED: [roles.OPERATOR, roles.ADMIN],
}

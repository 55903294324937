import React from 'react'
import { colors } from 'styles'

export default function ({ color, ...props }) {
  const fill = colors[color] || colors.defaultSVG
  return (
    <svg
      {...props}
      width='33'
      height='25'
      viewBox='0 0 33 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0 3.21875C0 2.39824 0.325948 1.61133 0.906138 1.03114C1.48633 0.450948 2.27324 0.125 3.09375 0.125H21.6562C22.4768 0.125 23.2637 0.450948 23.8439 1.03114C24.4241 1.61133 24.75 2.39824 24.75 3.21875V17.6562H22.6875V3.21875C22.6875 2.94525 22.5789 2.68294 22.3855 2.48955C22.1921 2.29615 21.9298 2.1875 21.6562 2.1875H3.09375C2.82025 2.1875 2.55794 2.29615 2.36455 2.48955C2.17115 2.68294 2.0625 2.94525 2.0625 3.21875V17.6562C2.0625 17.9298 2.17115 18.1921 2.36455 18.3855C2.55794 18.5789 2.82025 18.6875 3.09375 18.6875V20.75C2.27324 20.75 1.48633 20.4241 0.906138 19.8439C0.325948 19.2637 0 18.4768 0 17.6562L0 3.21875ZM9.28125 18.6875H21.6562V20.75H9.28125V18.6875Z'
        fill={fill}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M22.6875 6.3125H26.8538C27.3173 6.31291 27.7748 6.41748 28.1925 6.61846C28.6101 6.81945 28.9773 7.11173 29.2669 7.47369L32.3214 11.2893C32.7609 11.8383 33.0003 12.5207 33 13.2239V17.6562C33 18.4768 32.6741 19.2637 32.0939 19.8439C31.5137 20.4241 30.7268 20.75 29.9062 20.75H27.8438V18.6875H29.9062C30.1798 18.6875 30.4421 18.5789 30.6355 18.3855C30.8289 18.1921 30.9375 17.9298 30.9375 17.6562V13.2219C30.9371 12.9878 30.8571 12.7609 30.7106 12.5784L27.6581 8.76275C27.5617 8.64197 27.4393 8.54441 27.3001 8.4773C27.1609 8.41018 27.0083 8.37522 26.8538 8.375H24.75V17.6562H22.6875V6.3125ZM6.1875 22.8125C6.73451 22.8125 7.25911 22.5952 7.64591 22.2084C8.0327 21.8216 8.25 21.297 8.25 20.75C8.25 20.203 8.0327 19.6784 7.64591 19.2916C7.25911 18.9048 6.73451 18.6875 6.1875 18.6875C5.64049 18.6875 5.11589 18.9048 4.72909 19.2916C4.3423 19.6784 4.125 20.203 4.125 20.75C4.125 21.297 4.3423 21.8216 4.72909 22.2084C5.11589 22.5952 5.64049 22.8125 6.1875 22.8125ZM6.1875 24.875C7.28152 24.875 8.33073 24.4404 9.10432 23.6668C9.8779 22.8932 10.3125 21.844 10.3125 20.75C10.3125 19.656 9.8779 18.6068 9.10432 17.8332C8.33073 17.0596 7.28152 16.625 6.1875 16.625C5.09348 16.625 4.04427 17.0596 3.27068 17.8332C2.4971 18.6068 2.0625 19.656 2.0625 20.75C2.0625 21.844 2.4971 22.8932 3.27068 23.6668C4.04427 24.4404 5.09348 24.875 6.1875 24.875Z'
        fill={fill}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M24.75 22.8125C25.297 22.8125 25.8216 22.5952 26.2084 22.2084C26.5952 21.8216 26.8125 21.297 26.8125 20.75C26.8125 20.203 26.5952 19.6784 26.2084 19.2916C25.8216 18.9048 25.297 18.6875 24.75 18.6875C24.203 18.6875 23.6784 18.9048 23.2916 19.2916C22.9048 19.6784 22.6875 20.203 22.6875 20.75C22.6875 21.297 22.9048 21.8216 23.2916 22.2084C23.6784 22.5952 24.203 22.8125 24.75 22.8125ZM24.75 24.875C25.844 24.875 26.8932 24.4404 27.6668 23.6668C28.4404 22.8932 28.875 21.844 28.875 20.75C28.875 19.656 28.4404 18.6068 27.6668 17.8332C26.8932 17.0596 25.844 16.625 24.75 16.625C23.656 16.625 22.6068 17.0596 21.8332 17.8332C21.0596 18.6068 20.625 19.656 20.625 20.75C20.625 21.844 21.0596 22.8932 21.8332 23.6668C22.6068 24.4404 23.656 24.875 24.75 24.875Z'
        fill={fill}
      />
    </svg>
  )
}

import React, { useState } from 'react'

import Title from 'components/Title'
import Text from 'components/Text'
import Input from 'components/Input'
import CheckBox from 'components/CheckBox'
import SVG from 'components/SVG'
import Button from 'components/Button'

import { useFarm } from 'modules/data/hooks/useFarm'
import { useBatch } from 'modules/data/hooks/useBatch'
import { useProduct } from 'modules/data/hooks/useProduct'

import { parseToMoneyBRL } from 'utils'

import { BankData } from '../Shared'
import Farm from './Farm'
import * as s from './styles'
import { useFarmer } from 'modules/data/hooks/useFarmer'
import useWindowSize from 'modules/data/hooks/useWindowSize'
import { device } from 'styles'
import { useConstants } from 'views/Admin/Loi/Form/constants'

function Form({ hideModal }) {
  const [form, setForm] = useState({ cost: {} })
  const [sacksError, setSacksError] = useState(false)
  const minCost = 0
  const maxCost = 500

  const { mine: farms = [] } = useFarm()
  const { create } = useBatch()
  const { all: products = [] } = useProduct()
  const { farmer } = useFarmer()
  const [width] = useWindowSize()

  const { sackBatchValues } = useConstants()

  function setFarm(farm) {
    setForm({ ...form, farmId: farm.id })
  }

  function setField({ target: { name, value } }) {
    const val = Array.isArray(value) ? value[0] : value
    setForm({ ...form, [name]: val })
  }

  function getWeight() {
    let response = [{ label: '', value: '' }]
    if (form.productId) {
      const product = products.find((product) => product.id === form.productId)
      response = [{ label: `${product.kg}kg`, value: product.kg }]
    }
    return response
  }

  const handleSackChange = (value) => {
    if(value < 450) {
      setSacksError(true)
    } else {
      setSacksError(false) 
    }

    setField({
      target: { name: 'sacks', value: value },
    })
  }

  function setCost({ min, max }) {
    const value = parseFloat(
      (min || max || '0')
        .split('.')
        .join('')
        .replace('R$', '')
        .replace(',', '.')
    )

    if (!(value >= minCost && value <= maxCost)) {
      setForm({ ...form, cost: form.cost })
      return
    }

    if (min) {
      setForm({ ...form, cost: { ...form.cost, min: value } })
      return
    }

    setForm({ ...form, cost: { ...form.cost, max: value } })
  }

  const setSelected = (name, object) => {
    setField({ target: { name: name, value: object } })
  }

  const selectedFarm = farms.find((f) => f.id === form.farmId) || {}
  const selectedProduct = products.find((p) => p.id === form.productId) || {}
  const { cost = {} } = form

  async function createBatch(e) {
    await create(form)
    hideModal(e)
  }

  const getProducts = () => {
    return form.farmId
      ? farms
          .filter((farm) => farm.id === form.farmId)[0]
          .productList.map((p) => ({ label: p.name, value: p.id }))
      : []
  }

  return (
    <React.Fragment>
      <s.Container>
        <s.Header>
          <Title>Cadastro de Lote</Title>
          <Text>
            Cadastre seus lotes para que sejam disponibilizados na plataforma
          </Text>
        </s.Header>
        <s.FormContent>
          <s.FarmsContainer>
            <Text>
              Selecione a Propriedade onde o produto será disponibilizado para
              coleta.
            </Text>
            <s.Farms>
              {farms.filter((farm) => farm.status.approved != null).length >
              0 ? (
                farms
                  .filter((farm) => farm.status.approved != null)
                  .map((farm) => (
                    <Farm
                      key={`farm-${farm.id}`}
                      onClick={() => setFarm(farm)}
                      farm={{
                        ...farm,
                        active: farm.id === form.farmId,
                        available: true,
                        products: farm.productList,
                        img: require('./img.jpeg'),
                      }}
                    />
                  ))
              ) : (
                <Text>Você não possui nenhuma fazenda aprovada</Text>
              )}
            </s.Farms>
          </s.FarmsContainer>
          <s.Row>
            <s.CustomSelect
              placeholder='Selecione o produto:'
              options={getProducts()}
              name='productId'
              onChange={setField}
              maxWidth={width > device.screen.tablet ? '270px' : 'unset'}
              labelWidth={width < device.screen.tablet ? '100%' : 'unset'}
              disabled={!form.farmId}
            />
          </s.Row>
          <s.Row flexDirection={'column'}>
            <div style={{width: '60%'}}>
              <Input
                theme='inlineGrey'
                placeholder='Quantidade de sacas:'
                name='sacks'
                className='inputMax'
                // disabled={!form.farmId}
                value={form.sacks}
                onChange={({ target: { value } }) => handleSackChange(value)} />
                {sacksError && 
                  <s.SackErrorContainer>
                      <Text color={'vividRed'} alignSelf={'center'} fontWeight={'bold'} fontSize={'18px'}>
                        Valor mínimo para sacas é de 450
                      </Text>
                  </s.SackErrorContainer>
                }
              </div>
          </s.Row>
          <s.Row>
            <s.CustomSelect
              placeholder='Kg por saca:'
              options={getWeight()}
              name='sacksKG'
              onChange={setField}
              minWidth={width > device.screen.tablet ? '175px' : 'unset'}
              maxWidth={width > device.screen.tablet ? '175px' : 'unset'}
              labelWidth={width < device.screen.tablet ? '100%' : 'unset'}
              disabled={!form.farmId}
            />
          </s.Row>

          <s.Row>
            <CheckBox
              placeholder='Tempo de oferta:'
              options={[
                { label: '07', value: '07' },
                { label: '15', value: '15' },
                { label: '30', value: '30' },
              ]}
              name='offerTime'
              onChange={setField}
              hint='dias corridos'
            />
          </s.Row>
          <s.Row>
            <CheckBox
              placeholder='Disponibilidade mínima de entrega:'
              options={[
                { label: 'Imediata', value: 'now' },
                { label: '07', value: '07' },
                { label: '15', value: '15' },
                { label: '30', value: '30' },
              ]}
              name='daysToProvide'
              onChange={setField}
            />
          </s.Row>

          <s.Row>
            <s.Column>
              <Text marginBottom='25px' fontSize='18px'>
                Faixa de preço da saca (de R${minCost} a R${maxCost})
              </Text>
              <s.Row>
                <Input
                  theme='inlineGrey'
                  placeholder='Valor mínimo'
                  inputPlaceholder='R$0,00'
                  className='inputMin'
                  currency
                  value={form.cost.min}
                  onChange={({ target: { value } }) => setCost({ min: value })}
                />
                <Input
                  theme='inlineGrey'
                  placeholder='Valor máximo'
                  className='inputMax'
                  inputPlaceholder='R$0,00'
                  currency
                  value={form.cost.max}
                  onChange={({ target: { value } }) => setCost({ max: value })}
                />
              </s.Row>
            </s.Column>
          </s.Row>
          <s.Row>
            <s.Column>
              <Text marginBottom='25px' fontSize='18px' className='txtMob'>
                Dados bancários para pagamento:
              </Text>
              {farmer.accountList && farmer.accountList.length > 0 ? (
                <BankData
                  accounts={farmer.accountList}
                  onSelect={(paymentAccount) =>
                    setSelected('paymentAccount', paymentAccount)
                  }
                  selected={form.paymentAccount}
                  farmer={farmer}
                />
              ) : (
                <Text>Não existem dados bancários cadastrados</Text>
              )}
            </s.Column>
          </s.Row>
        </s.FormContent>
        {Object.values(form).filter((f) => !!f).length > 0 && (
          <s.Resume>
            <s.ResumeTitle>Resumo</s.ResumeTitle>
            {form.sacks && selectedProduct.name && (
              <s.ResumeBlock>
                <SVG.WheatBag width='40px' height='40px' />
                <Text fontSize='20px'>
                  <b>{form.sacks}</b> sacas de <b>{selectedProduct.name}</b>
                </Text>
                <Text fontSize='14px'>Lote disponível</Text>
              </s.ResumeBlock>
            )}
            <s.ResumeBlock>
              <div className='resume-list'>
                {selectedFarm.name && (
                  <Text fontSize='14px'>
                    Fazenda onde o produto será produzido:{' '}
                    <b>{selectedFarm.name}</b>
                  </Text>
                )}
                {form.offerTime && (
                  <Text fontSize='14px'>
                    Tempo da oferta: <b>{form.offerTime} dias</b>
                  </Text>
                )}
                {form.daysToProvide && (
                  <Text fontSize='14px'>
                    Disponibilidade para entrega:{' '}
                    <b>{form.daysToProvide} dias</b>
                  </Text>
                )}
                {cost.min >= minCost && (
                  <Text fontSize='14px'>
                    Valor mínimo: <b>{parseToMoneyBRL(cost.min)}</b>
                  </Text>
                )}
                {cost.max <= maxCost && (
                  <Text fontSize='14px'>
                    Valor máximo: <b>{parseToMoneyBRL(cost.max)}</b>
                  </Text>
                )}
              </div>
            </s.ResumeBlock>
          </s.Resume>
        )}
        <s.Reminder>
          <Text fontSize='20px' fontWeight='bold' color={'apple'}>
            Lembre-se que você não poderá editar seu lote, apenas excluí-lo se
            desejar. Ao clicar em concluir seu lote ficará visível apenas para
            você até que você clique em <b>Concluir</b>
          </Text>
        </s.Reminder>
      </s.Container>
      <s.Footer>
        <Text>Cadastrar lote:</Text>
        <Button
          onClick={createBatch}
          disabled={
            !form.farmId ||
            !form.productId ||
            !form.sacksKG ||
            !form.offerTime ||
            !form.daysToProvide ||
            !form.paymentAccount ||
            !form.sacks ||
            sacksError
          }>
          Concluir
        </Button>
        <Button
          color='defaultText'
          background='transparent'
          onClick={hideModal}>
          Cancelar
        </Button>
      </s.Footer>
    </React.Fragment>
  )
}

export default Form

import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { NEGOTIATIONS_COLLECTION } from '../consts/collections'
import { idType } from '../consts/negotiation'
import { acceptNegotiation, rejectNegotiation } from '../logic/negotiationLogic'
import { useUser } from './useUser'

export function useNegotiation(uid, type) {
  useFirestoreConnect([{ collection: NEGOTIATIONS_COLLECTION }])
  const dispatch = useDispatch()
  const { user } = useUser()

  const negotiation = useSelector(({ firestore: { data } }) => {
    const negotiations = data[NEGOTIATIONS_COLLECTION] || {}
    const result = Object.keys(negotiations).map((key) => {
      const negotiation = negotiations[key]
      if ((type = idType.loi && negotiation.loiId.id === uid) && (!negotiation.refusal?.farmer && !negotiation.refusal?.buyer)) {
        return { ...negotiation, id: key }
      } else {
        if (negotiation.batchId.id === uid && (!negotiation.refusal?.farmer && !negotiation.refusal?.buyer)) {
          return { ...negotiation, id: key }
        }
      }
    }) || null
    return result ? result.filter(( element ) => {
      return element !== undefined;
   })[0] : null
  })

  const error = useSelector((state) => state.negotiation.error)
  const all = useSelector(
    (state) => state.firestore.ordered[NEGOTIATIONS_COLLECTION]
  )

  const allNegotiations = all

  return {
    all: allNegotiations,
    mine: allNegotiations
      ? allNegotiations.filter(
          (negotiation) =>
            (type = idType.loi
              ? negotiation.loiId === uid
              : negotiation.batchId === uid)
        )
      : [],

    negotiation: isLoaded(negotiation) ? negotiation : { loading: true },
    acceptNegotiation: (negotiation) =>
      dispatch(acceptNegotiation, negotiation, user),
    rejectNegotiation: (negotiation) =>
      dispatch(rejectNegotiation, negotiation, user),
    // create: (data) => dispatch(create, data),
    // setStatus: (status) => dispatch(setStatus, status),
    error,
  }
}

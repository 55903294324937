import { getFirebase } from 'react-redux-firebase'
import { INSPECTION_COLLECTION } from '../consts/collections'
import { userHasRole, userHasAccessToFarm, updateDoc } from './helper'
import { roles } from '../consts/user'

export const create = async (dispatch, getState, farmId, data) => {
  const firebase = getFirebase()
  if (!(await userHasRole([roles.INSPECTOR]))) {
    dispatch({
      type: 'inspection/error/permission',
      error: { 
        code: 'no-permission',
        message: 'User lacks permission' 
      },
    })
    return
  }
  if (!userHasAccessToFarm(farmId)) {
    dispatch({
      type: 'inspection/error/access',
      error: { 
        code: 'no-access',
        message: 'User has not access to farm', 
      },
      farm: farmId 
    })
    return
  }
  if (!data.date) {
    dispatch({
      type: 'inspection/error/parameters',
      error: { 
        code: 'no-date',
        message: 'No inspection date provided'
      }, 
      data
    })
    return
  }

  const payload = {
    [data.date]: {
      ...data,
      user: firebase.auth().currentUser.uid,
      creationDate: firebase.firestore.Timestamp.fromDate(new Date()),
    },
  }
  updateDoc(dispatch, INSPECTION_COLLECTION, farmId, payload, 'inspection/update', 'inspection/updated', 'inspection/update/error')

  // return firebase
  //   .firestore()
  //   .collection(INSPECTION_COLLECTION)
  //   .doc(farmId)
  //   .set(
  //     {
  //       [data.date]: {
  //         ...data,
  //         user: firebase.auth().currentUser.uid,
  //         creationDate: firebase.firestore.Timestamp.fromDate(new Date()),
  //       },
  //     },
  //     { merge: true }
  //   )
  //   .then(async (doc) => {
  //     return { type: 'inspection/added', data: doc }
  //   })
  //   .catch((error) => {
  //     return { type: 'inspection/add/error', error }
  //   })
}

export const addInfo = async (dispatch, getState, farmId, id, data) => {
  if (!(await userHasRole([roles.INSPECTOR]))) {
    dispatch({
      type: 'inspection/error/permission',
      error: { 
        code: 'no-permission',
        message: 'User lacks permission' 
      },
    })
    return
  }
  //Pre-processing
  Object.keys(data).forEach((key) =>
    data[key] === undefined ? delete data[key] : {}
  )

  const payload = { [id]: data }
  updateDoc(dispatch, INSPECTION_COLLECTION, farmId, payload, 'inspection/info/add', 'inspection/info/added', 'inspection/info/add/error')

  // return getFirebase()
  //   .firestore()
  //   .collection(INSPECTION_COLLECTION)
  //   .doc(farmId)
  //   .set({ [id]: data }, { merge: true })
  //   .then((result) => {
  //     return { type: 'inspection/info/added', data }
  //   })
}

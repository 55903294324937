import { defineMessages } from 'react-intl'

const messages = defineMessages({
  login: {
    id: 'signin.input.login',
    defaultMessage: 'Login',
  },
  password: {
    id: 'signin.input.password',
    defaultMessage: 'Senha',
  },
  forgotPassword: {
    id: 'signin.button.forgotPassword',
    defaultMessage: 'Esqueci minha senha',
  },
  signIn: {
    id: 'signin.button.signIn',
    defaultMessage: 'Entrar',
  },
  noAccount: {
    id: 'signin.link.noAccount',
    defaultMessage: 'Não possui uma conta?',
  },
  signUp: {
    id: 'signin.link.signUp',
    defaultMessage: 'Cadastre-se',
  },
})

export default messages;
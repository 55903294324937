import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'confirmPasswordFarmer.title',
    defaultMessage: 'Falta pouco!',
  },
  subTitle: {
    id: 'confirmPasswordFarmer.text.subTitle',
    defaultMessage: 'Agora só precisamos que você crie seus dados de acesso.',
  },
  password: {
    id: 'confirmPasswordFarmer.input.password',
    defaultMessage: 'Senha',
  },
  confirmPassword: {
    id: 'confirmPasswordFarmer.input.confirmPassword',
    defaultMessage: 'Confirmar Senha',
  },
  step: {
    id: 'confirmPasswordFarmer.text.step',
    defaultMessage: 'etapa 4 de 5',
  },
  back: {
    id: 'confirmPasswordFarmer.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'confirmPasswordFarmer.button.continue',
    defaultMessage: 'Continuar',
  },
  invalid: {
    id: 'confirmPasswordFarmer.text.invalid',
    defaultMessage: 'Senha inválida!',
  },
  equal: {
    id: 'confirmPasswordFarmer.text.equal',
    defaultMessage: 'As senhas devem ser iguais!',
  },
  size: {
    id: 'confirmPasswordFarmer.text.size',
    defaultMessage: 'A senha deve ter 6 dígitos!',
  },
})

export default messages

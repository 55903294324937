import React, { useState } from 'react'

import * as s from './styles'
import NumberFormat from 'react-number-format'

export default function InputCpf({ placeholder, value, ...props }) {
  const [focus, setFocus] = useState(false)

  const sharedProps = { isFocused: focus }

  return (
    <s.Container {...sharedProps} hasValue={!!value}>
      <s.Placeholder {...sharedProps} hasValue={!!value}>{placeholder}</s.Placeholder>
      <NumberFormat
        value={value}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        {...props}
        {...sharedProps}
        format='###.###.###-##'
        mask='_'
      />
    </s.Container>
  )
}

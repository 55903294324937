import React from 'react'
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore' // <- needed if using firestore
import 'firebase/storage'
import 'firebase/functions'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from 'react-redux-firebase'
import {
  createFirestoreInstance,
  firestoreReducer,
  reduxFirestore,
} from 'redux-firestore' // <- needed if using firestore

import batchReducer from './redux/batchReducer'
import buyerReducer from './redux/buyerReducer'
import farmerReducer from './redux/farmerReducer'
import farmReducer from './redux/farmReducer'
import inspectionReducer from './redux/inspectionReducer'
import loiReducer from './redux/loiReducer'
import userReducer from './redux/userReducer'
import phoneReducer from './redux/phoneReducer'
import negotiationReducer from './redux/negotiationReducer'
import {
  asyncMiddleware,
  mailMiddleware,
  logMiddleware
} from './middlewares/'

import firebaseConfig from './firebase/config.json'

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp(firebaseConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore
firebase.auth().useDeviceLanguage();

// Add firebase to reducers
export const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  batch: batchReducer,
  buyer: buyerReducer,
  farmer: farmerReducer,
  farm: farmReducer,
  inspection: inspectionReducer,
  loi: loiReducer,
  phone: phoneReducer,
  user: userReducer,
  negotiation: negotiationReducer
})

// Create store with reducers and initial state
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      asyncMiddleware,
      mailMiddleware,
      logMiddleware
    ),
    reduxFirestore(firebase)
  )
)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
}

export default ({ children }) => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        {children}
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

import styled from 'styled-components'
import { colors, device } from 'styles'

export const Container = styled.div`
  width: 730px;
  height: 540px;

  background: ${colors.white};
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.31);
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  padding: 50px 80px 80px;
  justify-content: space-between;
  align-items: center;

  @media ${device.untilTablet} {
    padding: 50px 30px;
    margin: 0 20px;
  }
`

export const Title = styled.p`
  font-size: 30px;

  @media ${device.untilTablet} {
    font-size: 20px;
  }

  & b {
    color: ${colors.apple};
  }
`

export const Info = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white2};
  border: 1px solid ${colors.alto4};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;

  & > svg {
    margin-right: 30px;

    @media ${device.untilTablet} {
      display: none;
    }
  }

  & > p {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    @media ${device.untilTablet} {
      font-size: 16px;
      text-decoration: none;
    }
  }
`

export const Close = styled.p`
  text-decoration-line: underline;
  font-size: 18px;
  line-height: 21px;
  border: none;
  background: none;
  cursor: pointer;
`

import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 60px 20px;

  & > :not(:last-child) {
    margin-bottom: 15px;
  }
`

export const Icons = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  & > * {
    margin: 5px 15px;
  }
`

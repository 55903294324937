import styled from 'styled-components'
import { colors, fonts } from 'styles'

export const ProgressContainer = styled.div`
  display: flex;
`

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: initial;
  width: 100%;
  height: 100%;
`

export const StepShapeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StepShape = styled.div`
  background-color: ${colors.apple};
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  z-index: 10;

  &[disabled] {
    background-color: #dbdbdb;
  }
`

export const StepLine = styled.div`
  position: absolute;
  left: 49%;
  top: 21%;
  height: 1px;
  background-color: #dbdbdb;
  width: 100%;
  padding: 0;
  margin: 0;
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;
`

export const StepTitle = styled.p`
  text-align: center;
  font-family: ${fonts.primary};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding-top: 15px;
  color: ${colors.apple};
  
  &[disabled] {
    color: #dbdbdb;
  }
`

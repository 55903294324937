import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { INSPECTION_COLLECTION as COLLECTION } from '../consts/collections'
import { create, addInfo } from '../logic/inspectionLogic'

export function useInspection(farmId, id) {
  useFirestoreConnect([{ collection: COLLECTION, doc: farmId }])
  const dispatch = useDispatch()

  const inspection = useSelector(
    ({ firestore: { data } }) => data[COLLECTION] && data[COLLECTION][farmId]
  )
  const error = useSelector((state) => state.inspection.error)

  return {
    all: useSelector((state) => state.firestore.ordered[COLLECTION]),
    inspection: isLoaded(inspection)
      ? inspection && inspection.length > 0
        ? inspection[id]
        : { loading: true }
      : { loading: true },
    create: (farmId, data) => dispatch(create, farmId, data),
    addInfo: (data) => dispatch(addInfo, farmId, id, data),
    error,
  }
}

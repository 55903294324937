import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  CardsContainer,
  Card,
  Button,
} from './styles'
import Text from 'components/Text'
import Input from 'components/Input'
import { ErrorMessage } from 'components/ErrorMessage'
import logo from 'assets/logo.svg'
import { Link, useHistory } from 'react-router-dom'
import { useUser } from 'modules/data/hooks/useUser'
import { useIntl } from 'react-intl'
import messages from './messages'
import { toast } from 'react-toastify'

export default function RecoverPassword() {
  const [value, setValue] = useState('')
  const [emailExist, setEmailExist] = useState(true)
  const [requested, setRequested] = useState(false)
  const { checkEmail, loginMethods, requestPasswordReset } = useUser()
  const history = useHistory()
  const { formatMessage } = useIntl()

  const request = () => {
    if (!value) {
      toast.error('Você precisa preencher um e-mail válido')
      return
    }
    checkEmail(value)
    setRequested(true)
  }

  useEffect(() => {
    if (requested && loginMethods.values.length > 0) {
      requestPasswordReset(value)
      history.push('/validated-email')
    }
    if (loginMethods.checked && loginMethods?.values.length === 0) {
      setEmailExist(false)
    }
    // eslint-disable-next-line
  }, [loginMethods])

  return (
    <Container>
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Input
              type='email'
              placeholder={formatMessage(messages.signIn)}
              value={value}
              onChange={({ target }) => setValue(target.value)}
            />
            <ErrorMessage
              mode='dialog'
              hide={emailExist}
              title='E-mail não cadastrado'
              message='Essa conta de e-mail não consta em nossa base. Verifique ou entre em contato com a nossa equipe.'
              onClose={(e) => setRequested(false)}
            />
            <CardsContainer>
              <Link top='/validated-email' onClick={request}>
                <Card>
                  <Text color='white'>{formatMessage(messages.sendMail)}</Text>
                  <strong>{value}</strong>
                </Card>
              </Link>
            </CardsContainer>
            <Footer>
              <Button to='/'>
                <Text color='primary'>{formatMessage(messages.back)}</Text>
              </Button>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

import { getFirebase } from 'react-redux-firebase'
import {
  USER_COLLECTION,
  FARM_COLLECTION,
  PARAMS_COLLECTION,
} from '../consts/collections'
import { roles } from '../consts/user'

export const updateDoc = (dispatch, collection, docId, payload, mainAction, successAction, errorAction) => {
  const firebase = getFirebase()

  dispatch({type: mainAction, payload })
  firebase.firestore().collection(collection).doc(docId)
    .set(payload, { merge: true })
    .then(data => {
      dispatch({ type: successAction, payload, docId})
    })
    .catch(error => {
      dispatch({ type: errorAction, payload, error})
    })
}

export const getUserRoles = async () => {
  const firebase = getFirebase()
  const doc = await firebase
    .firestore()
    .collection(USER_COLLECTION)
    .doc(firebase.auth().currentUser.uid)
    .get()

  const user = doc.data()
  return user.roles
}

export const getFarmStatus = async (farmId) => {
  const firebase = getFirebase()
  const result = await firebase
    .firestore()
    .collection(FARM_COLLECTION)
    .doc(farmId)
    .collection('status')
    .orderBy('date', 'desc')
    .limit(1)
    .get()

  const status = result.docs[0].data()

  return { ...status, id: status.id }
}

export const userHasRole = async (roleList) => {
  const userRoles = await getUserRoles()
  const has = roleList.filter((role) => userRoles[role])
  return has.length > 0
}

export const userHasAccessToFarm = async (farmId) => {
  const firebase = getFirebase()
  let doc = await firebase
    .firestore()
    .collection(FARM_COLLECTION)
    .doc(farmId)
    .get()

  const farm = doc.data()

  let access = farm.accessList
    .map((user) => user.id)
    .includes(firebase.auth().currentUser.uid)
  if (access) return true

  return userHasRole([roles.INSPECTOR, roles.OPERATOR, roles.ADMIN])
}

export const getParams = async (name) => {
  const firebase = getFirebase()
  const doc = await firebase
    .firestore()
    .collection(PARAMS_COLLECTION)
    .doc(name)
    .get()

  return doc.data()
}

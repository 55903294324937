import React from 'react'

import * as s from './styles'

export default function Text({ children, innerHtml, ...props }) {
  return innerHtml ? (
    <s.Text dangerouslySetInnerHTML={{__html: innerHtml}} {...props} />
  ) : (
    <s.Text {...props}>{children}</s.Text>
  )
}

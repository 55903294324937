import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from './styles'
import Title from 'components/Title'
import Input from 'components/Input'

import Text from "components/Text";
import Button from "components/Button";
import { ErrorMessage } from "components/ErrorMessage";
import logo from 'assets/logo.svg';
import { useHistory, Link } from 'react-router-dom';
import voltar from 'assets/voltar.svg';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFarmer } from 'modules/data/hooks/useFarmer';
import { useIntl } from 'react-intl';
import messages from './messages'
import { useUser } from 'modules/data/hooks/useUser';
import routes from 'configuration/routes'

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default function ConfirmEmail() {
  const history = useHistory()
  const [email, setEmail] = useState("")
  const [confirmEmail, setConfirmEmail] = useState("")
  const [emailNotMine, setEmailNotMine] = useState(false)
  const [emailValid, setEmailValid] = useState(true)
  const [bannerCount, setBannerCount] = useState(0)
  const { addFeature, farmer, emailAlreadyInUse } = useFarmer()
  const user = useUser()
  const { formatMessage } = useIntl()

  const nextStep = routes.farmer.confirmPassword
  const prevStep = routes.farmer.registerData

  useEffect( () => {
    if(farmer && farmer.email) {
      if(farmer.email != email)
        setEmail(farmer.email);
    }
  }, [ farmer ])

  // let logged = farmer?.email === user?.email

  const handleNextStep = async () => {
    // if(logged) {
    //   history.push(nextStep)
    //   return
    // }
    const schema = Yup.object().shape({
      email: Yup.string().email().required(),
    })

    if (!(await schema.isValid({ email: email }))) {
      if(!bannerCount) toast.warn(formatMessage(messages.error))
      return
    }

    if (!email) {
      if(!bannerCount) toast.error(formatMessage(messages.error))
      return
    }

    if (email !== confirmEmail) {
      if(!bannerCount) toast.warn(formatMessage(messages.warning))
      return
    }

    if(emailNotMine) {
      if(!bannerCount) toast.error('Nome de usuário indisponível')
      return
    }

    addFeature({ email })
    history.push(nextStep)

  }

  useEffect( () => {
    if(!email || email.length < 10) return

    const emailValid = validateEmail(email);

    if(!emailValid) {
      setEmailValid(false)
      // user.checkEmail(email).then(methods => setEmailNotMine(!logged & methods.values.lengh > 0))
    } else {
      setEmailValid(true)
      // setEmailNotMine(!logged && emailAlreadyInUse(email))
      setEmailNotMine(emailAlreadyInUse(email))
    }
  }, [ email ])

  const handleEmailChange = (value) => {
    setEmail(value)
    setEmailNotMine(false)
    setEmailValid(true)
  }

  return (
    <Container>
      <FormContainer>
        <ErrorMessage
          hide={emailValid}
          mode='banner'
          title='E-mail inválido'
          message='O e-mail está em um formato inválido.'
          onError={e => setBannerCount(bannerCount+1)}
          onClose={e => setBannerCount(bannerCount-1)}
          key='valid'
        />
        <ErrorMessage
          hide={!emailNotMine}
          mode='banner'
          title='E-mail já cadastrado'
          message='O e-mail escolhido já está cadastrado. Utilize uma conta diferente.'
          onError={e => setBannerCount(bannerCount+1)}
          onClose={e => setBannerCount(bannerCount-1)}
          key='mine'
        />
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <div>
              <Title className='title'>{formatMessage(messages.title)}</Title>
              <Text>{formatMessage(messages.subTitle)}</Text>
              <Input
                type='email'
                placeholder={formatMessage(messages.email)}
                value={email}
                onChange={({ target }) => handleEmailChange(target.value)}
              />
              <Input
                type='email'
                placeholder={formatMessage(messages.confirmEmail)}
                value={confirmEmail}
                disablePaste={true}
                onChange={({ target }) => setConfirmEmail(target.value)}
              />
            </div>

            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to={prevStep}>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to={prevStep}>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

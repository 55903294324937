import styled, { css } from 'styled-components'
import { colors, device } from 'styles'

export const Farm = styled.div`
  background: ${colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border: 1px solid ${colors.mercury3};
  width: 350px;
  border-radius: 5px;
  display: flex;
  cursor: pointer;

  ${({ active, available }) =>
    (!active || !available) &&
    css`
      opacity: 0.3;

      ${!available &&
      css`
        cursor: not-allowed;
      `}
    `}
`

export const Img = styled.img`
  height: 100%;
  width: 40%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  @media ${device.untilTablet} {
    display: none;
  }
`

export const Content = styled.div`
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > :first-child {
    margin-bottom: 5px;
  }

  @media ${device.untilTablet} {
    .txt-hide {
      display: none;
    }
  }
`

export const Divider = styled.div`
  width: 100%;
  border: 1px solid ${colors.gallery};
  margin: 12px 0;
  @media ${device.untilTablet} {
    display: none;
  }
`

import styled from 'styled-components'
import { colors, device } from 'styles'
import Select from 'components/Select'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.mercury2};
  padding: 40px;
  width: 100%;

  @media ${device.untilTablet} {
    background-color: ${colors.camarone2};
    color: ${colors.white};
    padding: 20px;
  }

  & > h1 {
    margin-bottom: 12px;
    font-weight: 400;
    @media ${device.untilTablet} {
      color: ${colors.white};
      font-size: 19px;
    }
  }

  & > p {
    @media ${device.untilTablet} {
      color: ${colors.white};
      font-size: 14px;
    }
  }
`

export const Container = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 810px;
  overflow-y: auto;
  @media ${device.untilTablet} {
    width: 100%;
    > div {
      width: 100%;
    }
  }
`

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  @media ${device.untilTablet} {
    padding: 20px;
  }
  & > :not(:last-child) {
    margin-bottom: 60px;

    @media ${device.untilTablet} {
      margin-bottom: 25px;
    }
  }
`

export const FarmsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > p {
    margin-bottom: 25px;
  }
`

export const Farms = styled.div`
  display: flex;
  overflow-x: auto;

  & > :not(:last-child) {
    margin-right: 25px;
  }
`

export const SackErrorContainer = styled.div`
  margin-top: 15px;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ flexDirection }) => flexDirection || 'unset'};

  & > :not(:last-child) {
    margin-right: 40px;
    @media ${device.untilTablet} {
      margin-right: 0px;
      margin-bottom: 40px;
    }
  }

  @media ${device.untilTablet} {
    display: block;
  }
  @media ${device.untilTablet} {
    .inputMin,
    .inputMax {
      border: none;
      display: block;
      width: 100%;
      background: transparent;

      span {
        height: 33px;
      }
      input {
        border: 1px solid #cacaca;
        padding-left: 6px;
        border-radius: 6px;
      }
    }
    .txtMob {
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CustomSelect = styled(Select)`
  & select {
    background: ${colors.concrete};
    border: 1px solid ${colors.silver7};
    height: 50px;
    font-size: 18px;
  }

  & .select-placeholder {
    margin-bottom: 25px;
  }
`

export const Resume = styled.div`
  border-top: 1px solid ${colors.apple};
  border-bottom: 1px solid ${colors.apple};
  position: relative;
  display: flex;
  padding: 60px 0;
  justify-content: center;
  margin-top: 40px;
  @media ${device.untilTablet} {
    display: block;
  }
`

export const ResumeBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;

  &:nth-of-type(3) {
    border-left: 1px solid ${colors.silver8};
    width: 60%;
    @media ${device.untilTablet} {
      margin: 30px 20px;
      border: none;
    }
  }

  & > .resume-list {
    & > :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  & > :not(:last-child) {
    margin-bottom: 15px;
  }

  @media ${device.untilTablet} {
    margin: 0 auto;
    width: 100%;
  }
`

export const ResumeTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  background: ${colors.apple};
  border-radius: 10px;
  color: ${colors.white};
  padding: 10px 60px;
  position: absolute;
  top: -21px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 185px;
`

export const Reminder = styled.div`
  padding: 5%;

  @media ${device.untilTablet} {
    p {
      font-size: 12px;
    }
  }
`

export const Footer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0px -10px 40px rgba(0, 0, 0, 0.1);
  padding: 0 40px;

  & p {
    font-size: 18px;
    color: ${colors.silver9};
    width: 100%;
    @media ${device.untilTablet} {
      display: none;
    }
  }

  & button {
    border-radius: 0;
    font-weight: bold;
    width: 200px;
    min-width: 200px;
    @media ${device.untilTablet} {
      width: 100%;
      min-width: auto;
      border-radius: 5px;
    }
  }
`

import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import routes from 'configuration/routes'

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Text from 'components/Text';
import SVG from 'components/SVG';

import { useUser } from 'modules/data/hooks/useUser'
import { useFarm } from 'modules/data/hooks/useFarm'

import * as s from './styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function WelcomeBuyerModal() {
  const [modalTruckOpen, setModalTruckOpen] = useState(true);
  const classes = useStyles();

  const { user, roles } = useUser()
  const { mine, loaded } = useFarm()

  const name = user?.profile?.name;

  if(roles && roles.farmer && !roles.admin && loaded && mine && mine.length === 0) {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={modalTruckOpen}
        onClose={() => setModalTruckOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalTruckOpen}>
          <s.Container>
            <s.Title>Bem vindo, <b>{name}</b></s.Title>
            <s.Info>Essa é a sua plataforma. Mas antes de começar a cadastrar e negociar seus lotes, precisamos que você nos conte um pouco mais sobre sua <b>propriedade rural.</b></s.Info>
            <Link to={routes.admin.farms +  '?add=true'}>
              <s.Button onClick={() => setModalTruckOpen(false)}>
                <SVG.Farm />
                  <Text>Detalhar propriedade</Text>
              </s.Button>
            </Link>
            <s.Close onClick={() => setModalTruckOpen(false)}>Quero fazer isso depois</s.Close>
          </s.Container>
        </Fade>
      </Modal>
    )
  }

  return null;
}

export default WelcomeBuyerModal;

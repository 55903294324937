import React, { useState, useEffect } from 'react'

import * as s from './styles'
import messages from './messages'
import { useIntl } from 'react-intl'
import Account from './Account'
import { useFarmer } from 'modules/data/hooks/useFarmer'

export default function Accounts({ toggleDrawer }) {
  const [ accountList, setAccountList ] = useState([])
  const { formatMessage } = useIntl()
  const { addFeature, farmer } = useFarmer()

  useEffect( () => {
    if(farmer && farmer.accountList) setAccountList(farmer.accountList)
  }, [ farmer ])

  const accounts = farmer.accountList || [ ]

  const removeAccount = id => {
    const obj = accountList.filter(item => !(item.account === id))
    setAccountList(obj)
    addFeature({
      accountList: obj
    })
  }

  return (
    <s.Container>
      <s.AccountsContainer>
        {accounts.map((account) => (
          <Account {...account} onClick={(id) => removeAccount(id)} />
        ))}
      </s.AccountsContainer>
      <s.AddButton onClick={toggleDrawer}>
        {formatMessage(messages.addAccount)}
      </s.AddButton>
    </s.Container>
  )
}

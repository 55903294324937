import { defineMessages } from 'react-intl'

const messages = defineMessages({
  unit: {
    id: 'account.unit',
    defaultMessage: 'Agência',
  },
  account: {
    id: 'account.account',
    defaultMessage: 'Conta',
  },
})

export default messages

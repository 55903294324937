import React, { useState } from 'react'

import * as s from './styles'
// import NumberFormat from 'react-number-format'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function InputPhoneNumber({ placeholder, value, ...props }) {
  // eslint-disable-next-line
  const [focus, setFocus] = useState(false)

  const sharedProps = { isFocused: focus, hasValue: !!value }
  const handleChange = (value, country, e, formattedValue) => {
    props.onChange({target: { value: formattedValue }})
  }

  return (
    <s.Container {...sharedProps}>
      <s.Placeholder {...sharedProps}>{placeholder}</s.Placeholder>
      <PhoneInput
        country={'br'}
        value={value}
        onChange={handleChange}
        inputClass='input'
        containerClass='container'
      />
      
    </s.Container>
  )
}
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  error: {
    id: 'form.required.fileds',
    defaultMessage: 'Os campos com * são obrigatórios',
  },
  whichProducts: {
    id: 'companyData.input.whichProducts',
    defaultMessage: 'Tem Interesse em comprar quais produtos? *',
  },
  whichProductsPlaceholder: {
    id: 'companyData.input.whichProductsPlaceholder',
    defaultMessage: 'Digite e selecione entre as opções',
  },
  onDemand: {
    id: 'companyData.text.onDemand',
    defaultMessage: 'Tem interesse em comprar produtos sob demanda?',
  },
  tooltipOnDemand: {
    id: 'companyData.text.tooltipOnDemand',
    defaultMessage:
      'Aqui na Agricon contamos com inúmeros produtores rurais que estão dispostos a produzir produtos diferenciados sob demanda. Se for de seu interesse, selecione sim, entraremos em contato para entender a sua necessidade.',
  },
  yes: {
    id: 'companyData.text.yes',
    defaultMessage: 'Sim',
  },
  no: {
    id: 'companyData.text.no',
    defaultMessage: 'Não',
  },
  step: {
    id: 'companyFinishData.text.step',
    defaultMessage: 'etapa 5 de 9',
  },
  back: {
    id: 'companyData.button.back',
    defaultMessage: 'Voltar',
  },
  continue: {
    id: 'companyData.button.continue',
    defaultMessage: 'Continuar',
  },
})

export default messages

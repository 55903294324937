import { useState, useEffect } from 'react'
import { weather } from '../consts/apiTokens'
import { useFarm } from './useFarm'

export function useWeather() {
  const [currentLocation, setCurrentLocation] = useState(null)
  const [farmsLocation, setFarmsLocation] = useState({})
  const [officeLocation, setOfficeLocation] = useState({
    temp_min: 10,
    temp_max: 20,
  })
  const { all } = useFarm()

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      if (!currentLocation)
        fetch(
          `https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&appid=${weather.key}&units=metric`
        ).then(async (res) => setCurrentLocation(await res.json()))
    })

    fetch(
      `https://api.openweathermap.org/data/2.5/weather?q=Porto Alegre&appid=${weather.key}&units=metric`
    ).then(async (res) => {
      setOfficeLocation(await res.json())
    })

    if (all) {
      all.forEach((farm) => {
        if (farm.city) {
          fetch(
            `https://api.openweathermap.org/data/2.5/weather?q=${farm.city}&appid=${weather.key}&units=metric`
          ).then(async (res) =>
            setFarmsLocation({
              id: farm.id,
              temp: (await res.json()).main,
            })
          )
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    currentLocation: currentLocation ? currentLocation.main : null,
    farmsLocation,
    officeLocation: officeLocation.main ? officeLocation.main : officeLocation,
  }
}

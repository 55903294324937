import { defineMessages } from 'react-intl'

const messages = defineMessages({
  signIn: {
    id: 'recover.input.signIn',
    defaultMessage: 'Login',
  },
  sendMail: {
    id: 'recover.link.sendMail',
    defaultMessage: 'Enviar e-mail de recuperação para o email',
  },
  sendMessage: {
    id: 'recover.link.sendMessage',
    defaultMessage: 'Enviar mensagem de recuperação para o celular',
  },
  back: {
    id: 'recover.button.back',
    defaultMessage: 'Voltar',
  },
})

export default messages

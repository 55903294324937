import styled from 'styled-components'
import { colors, device } from 'styles'

export const Container = styled.div`
  height: 100%;
  background: ${colors.mercury};

  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
`

export const Wrapper = styled.div`
  height: 100%;
`

export const SidebarMain = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;

  @media ${device.untilTablet} {
    overflow: auto;
    margin-bottom: 30px;
  }
`

import { useState } from 'react'
import { useFirebase } from 'react-redux-firebase'

export function useFileUpload() {
  const firebase = useFirebase()
  const storageRef = firebase.storage().ref()
  const [progress, setProgress] = useState(0)
  const [url, setUrl] = useState('')
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  return {
    upload: (file, folder) => {
      folder = folder || Date.now()
      const fileRef = storageRef.child(folder + '/' + (file.name ? file.name : 'avatar.png'))
      const task = fileRef.put(file)
      setData(file)
      task.on(
        'state_changed',
        (snap) => {
          setProgress((snap.bytesTransferred / snap.totalBytes) * 100)
        },
        (err) => {
          setError(err)
        },
        () => {
          task.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            setUrl(downloadURL)
          })
        }
      )
    },
    progress,
    url,
    error,
    data,
  }
}
import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'

const COLLECTION_NAME = 'terms'

export function useTerms() {
  useFirestoreConnect([
    { collection: COLLECTION_NAME, queryParams: ['orderByKey'] },
  ])

  const all = useSelector((state) => state.firestore.ordered[COLLECTION_NAME])

  return {
    all: isLoaded(all) ? all : [],
    current: isLoaded(all)
      ? { ...all[all.length - 1], version: all[all.length - 1].id }
      : { content: { en: '', pt_br: '' } },
  }
}

import React, { useState, useEffect, useCallback } from 'react'

import { getProperties } from './utils'

import Text from '../Text'

import * as s from './styles'
import * as shared from '../styles-shared'
import EyeOpen from 'components/SVG/EyeOpen'
import EyeClosed from 'components/SVG/EyeClosed'

export default function Input({
  placeholder,
  inputPlaceholder,
  value,
  format,
  required,
  theme,
  showError,
  name,
  handleValidation,
  onChange,
  currency,
  type = "text",
  className,
  disablePaste = false,
  showPasswordToggle = false,
  ...props
}) {
  const [focus, setFocus] = useState(false)
  const [error, setError] = useState(null)
  const [passwordShown, setPasswordShown] = useState(false)
  const [inputType, setInputType] = useState(type);

  const sharedProps = { isFocused: focus, hasValue: !!value, theme, className }
  const { mask, fnValidation, errorMessage = '' } = getProperties(format)

  const handlePaste = (event) => {
    if (disablePaste) {
      event.preventDefault();
    }
  };

  const validate = useCallback(
    (v) => {
      if (fnValidation) {
        const isValid = fnValidation(v)

        if (!isValid) {
          setError(errorMessage)
          return handleValidation({ name, errorMessage })
        }
        setError(null)
        return handleValidation({ name, errorMessage: null })
      }
    },
    [handleValidation, name, fnValidation, errorMessage]
  )

  useEffect(() => {
    validate(value)
  }, [value, validate])

  function getComponent() {
    if (currency) {
      return { Component: s.Currency }
    }
    if (format) {
      return { Component: s.NumberFormat, defaultProps: { mask: '_' } }
    }

    return { Component: s.Input }
  }

  const { Component, defaultProps = {} } = getComponent()

  function _onChange(e) {
    const { value: v } = e.target

    validate(v)

    if (onChange) {
      onChange(e)
    }
  }

  const handlePasswordToggleClick = () => {
    if (passwordShown) {
      setInputType('password')
    } else { 
      setInputType('text')
    }

    setPasswordShown(!passwordShown)
  }

  return (
    <s.Container {...sharedProps}>
      <s.Placeholder {...sharedProps}>
        {placeholder}
        {required && <shared.Required>*</shared.Required>}
      </s.Placeholder>
      <Component
        // autoFocus={true}
        value={value}
        name={name}
        onChange={_onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        {...props}
        {...sharedProps}
        format={mask}
        {...defaultProps}
        type={inputType}
        placeholder={inputPlaceholder}
        onPaste={handlePaste}
      />
      {showPasswordToggle &&
        <s.ToggleContainer onClick={handlePasswordToggleClick}>
          {passwordShown ? <EyeOpen /> : <EyeClosed />}
        </s.ToggleContainer>
      }
      {showError && (
        <Text fontSize='13px' color='danger'>
          {error || (required && !value && 'Campo obrigatório')}
        </Text>
      )}
    </s.Container>
  )
}

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  needHelp: {
    id: 'menu.needHelp',
    defaultMessage: 'Precisa de ajuda?',
  },
  logout: {
    id: 'menu.logout',
    defaultMessage: 'Sair',
  },
})

export default messages;
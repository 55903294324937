import React from 'react'
import * as s from './styles'

export default function SelectableDiv({
  selected,
  disabled,
  children,
  setSelected,
  selectionObject,
  ...props
}) {
  return (
    <s.Container
      {...props}
      disabled={disabled}
      onClick={() => (setSelected ? setSelected(selectionObject) : {})}>
      {selected ? <s.LineGreen /> : <s.LineWhite />}
      {children}
    </s.Container>
  )
}

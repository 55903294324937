import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .code {
    width: 100% !important;
    justify-content: center;
    display: flex;
  }

  input {
    border: none !important;
    border-bottom: 2px solid #d5d5d5 !important;
    margin-right: 5px;
    font-family: sans-serif !important;

    @media only screen and (max-width: 1024px) {
      width: 40px !important;
    }

    @media only screen and (max-width: 765px) and (min-width: 590px) {
      width: 30px !important;
    }
  }
`
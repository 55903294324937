import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { FARM_COLLECTION, PRODUCT_COLLECTION } from '../consts/collections'
import { useFarmer } from './useFarmer'
import { useUser } from './useUser'
import { create, update, setStatus, unsetStatus } from '../logic/farmLogic'

export function useFarm(id) {
  const { all: allFarmers = null } = useFarmer()
  const { all: allUsers = null } = useUser()

  const dispatch = useDispatch()
  let loaded = false

  useFirestoreConnect([
    { collection: FARM_COLLECTION },
    { collection: PRODUCT_COLLECTION },
  ])
  const uid = useSelector((state) => state.firebase.auth.uid)
  const allFarms = useSelector(
    (state) => state.firestore.ordered[FARM_COLLECTION]
  )
  const products = useSelector(
    (state) => state.firestore.data[PRODUCT_COLLECTION]
  )

  const error = useSelector((state) => state.farm.error)
  let all = allFarms && allUsers && allFarmers
      ? allFarms.map((baseFarm) => {
          let farm = { ...baseFarm }
          farm.users = []
          farm.accessList.forEach((user, pos) => {
            farm.users.push({
              user: allUsers.find((users) => users.id === user.id),
              profile: allFarmers.find((farmer) => farmer.id === user.id),
            })
          })
          farm.productList = []
          baseFarm.productList.forEach((p) => {
            if (products) farm.productList.push({ ...products[p.id], id: p.id })
          })
          return farm
        })
      : []

  let mine = []
  const setMine = () => {
    loaded = false
    mine = all.filter((farm) => farm.accessList.some((user) => user.id === uid))
    loaded = true
  }

  if (isLoaded(allFarms)) setMine()

  return {
    loaded: loaded,
    all: all,
    mine: mine,
    farm: all ? all.find((farm) => farm.id === id) : {},
    error: error,
    create: (data) => dispatch(create, data),
    update: (data) => dispatch(update, id, data),
    setStatus: (status, week, turn) =>
      dispatch(setStatus, id, status, week, turn),
    unsetStatus: (status) => dispatch(unsetStatus, id, status),
  }
}

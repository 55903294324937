import React from 'react'
import { Container } from './styles'

export default function CardHeader({ title, data, children }) {
  return (
    <Container>
      <span>{title}</span>
      <strong>{data}{children}</strong>
    </Container>
  )
}

import React from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  CardsContainer,
  Card,
  TextWrapper,
} from './styles'
import Text from 'components/Text'
import Title from 'components/Title'
import logo from 'assets/logo.svg'
import buyerIcon from 'assets/welcome_buyer.svg'
import { Link } from 'react-router-dom'
import SVG from 'components/SVG'
import messages from './messages'
import { useIntl } from 'react-intl'

export default function WelcomeRegistration() {
  const {formatMessage} = useIntl()
  return (
    <Container>
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <Title color='secondary'>{formatMessage(messages.title)}</Title>
            <Text className='info-text'>
              {formatMessage(messages.subTitle)}
            </Text>

            <CardsContainer>
              <Link to='/welcome-farmer'>
                <Card>
                  <SVG.Producer />
                  <TextWrapper>
                    <Text color='primary' className='title'>
                      {formatMessage(messages.farmer)}
                    </Text>
                    <Text>{formatMessage(messages.sell)}</Text>
                  </TextWrapper>
                </Card>
              </Link>
              <Link to='/welcome-buyer'>
                <Card>
                  <img src={buyerIcon} className='buyer-icon' alt='buyer icon' />
                  <TextWrapper>
                    <Text color='primary' className='title'>
                      {formatMessage(messages.buyer)}
                    </Text>
                    <Text>{formatMessage(messages.buy)}</Text>
                  </TextWrapper>
                </Card>
              </Link>
            </CardsContainer>
            <Footer>
              <Link to='/'>
                <Text className='signup'>
                  {formatMessage(messages.haveAccount)}
                  <Text className='signup-text' color='primary'>
                    {' '}
                    &nbsp; {formatMessage(messages.signIn)}
                  </Text>
                </Text>
              </Link>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

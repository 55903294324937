export default {
  examples: '/exemplos',
  signin: '/',
  recoverPassword: '/recover-password',
  validateNumber: '/validate-number',
  verifyEmail: '/validated-email',
  welcomeRegistration: '/welcome-registration',
  account: '/account',
  farmer: {
    welcome: '/welcome-farmer',
    registerName: '/farmer-step-name',
    registerData: '/farmer-step-data',
    confirmEmail: '/farmer-step-email',
    confirmPassword: '/farmer-step-password',
    verifyPhone: '/farmer-step-phone',
    terms: '/farmer-step-terms',
    home: '/platform/home',
  },
  buyer: {
    welcome: '/welcome-buyer',
    registerCompanyName: '/buyer-step-name',
    registerCompanyData: '/buyer-step-data',
    registerCompanyDetails: '/buyer-step-details',
    registerCompanyMoreDetails: '/buyer-step-details-2',
    registerCompanyFinishingDetails: '/buyer-step-details-3',
    confirmEmail: '/buyer-step-email',
    confirmPassword: '/buyer-step-password',
    registerPhone: '/buyer-step-phone',
    verifyPhone: '/buyer-verify-phone',
    terms: '/buyer-step-terms',
    done: '/buyer-done',
    home: '/platform',
  },
  inspector: {
    inspections: '/platform/inspections',
    inspection: '/platform/inspection',
    schedule: '/platform/schedule',
    review: '/platform/review',
  },
  admin: {
    welcome: '/welcome-admin',
    dashboard: '/dashboard',
    home: '/platform/home',
    batches: '/platform/batches',
    farms: '/platform/farms',
    negotiations: '/platform/negotiations',
    profile: '/platform/profile',
    lois: '/platform/lois',
    management: '/management',
    farmers: '/management/farmers',
    buyers: '/management/buyers',
    negotiation: '/management/negotiation',
    match: '/management/match',
  },
}

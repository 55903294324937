const initialState = {
  error: {}
}

export default (state = initialState, action) => {
  switch (action?.type) {
    case 'farmer/create/error':
      return {
        ...state,
        error: { ...state.error, create: action.error}
      }
    case 'farmer/add/feature/added': {
      return {
        ...state,
        ...action.payload,
        error: {}
      }
    }
    case 'farmer/add/feature/error':
    case 'farmer/terms/error':
      return {
        ...state,
        error: { ...state.error, update: action.error}
      }
    default:
      return state
  }
}

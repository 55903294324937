import { getFirebase } from 'react-redux-firebase'
import {
  FARM_COLLECTION,
  USER_COLLECTION,
  PRODUCT_COLLECTION,
} from '../consts/collections'
import { status as farmStatus, roleAccessToStatus } from '../consts/farm'
import { userHasRole, userHasAccessToFarm, updateDoc } from './helper'

export const create = (dispatch, getState, data) => {
  const firebase = getFirebase()

  dispatch({ type: 'farm/create', farm: data })

  if (data.productList && data.productList.length) {
    data.productList.forEach(
      (id, i) =>
        (data.productList[i] = firebase
          .firestore()
          .collection(PRODUCT_COLLECTION)
          .doc(id))
    )
  }
  firebase.firestore().collection(FARM_COLLECTION)
    .add({
      ...data,
      accessList: [
        firebase
          .firestore()
          .collection(USER_COLLECTION)
          .doc(firebase.auth().currentUser.uid),
      ],
      status: {
        [farmStatus.STATUS_WAITING_FOR_SCHEDULE]: {
          date: firebase.firestore.Timestamp.fromDate(new Date()),
          user: firebase.auth().currentUser.uid,
        },
      },
    })
    .then(async (doc) => {
      dispatch({ type: 'farm/added', data: doc })
    })
    .catch((error) => {
      console.error(error)
      dispatch({ type: 'farm/add/error', error })
    })
}

export const update = (dispatch, getState, farmId, data) => {
  const firebase = getFirebase()

  dispatch({ type: 'farm/update', data })
  if (!userHasAccessToFarm(farmId)) {
    dispatch({
      type: 'farm/update/error',
      error: { 
        code: 'no-access',
        message: 'User has not access to requested farm' 
      },
    })
    return
  }
  if (data.productList && data.productList.length) {
    data.productList.forEach(
      (id, i) =>
        (data.productList[i] = firebase
          .firestore()
          .collection(PRODUCT_COLLECTION)
          .doc(id))
    )
  }

  updateDoc(dispatch, FARM_COLLECTION, farmId, data, 'farm/update', 'farm/updated', 'farm/update/error')
}

export const setStatus = (dispatch, getState, farmId, status, week = null, turn = null) => {
  const firebase = getFirebase()
  if (!userHasRole([roleAccessToStatus[status]])) {
    dispatch({
      type: 'farm/update/error',
      error: { 
        code: 'no-permission',
        message: 'User has no permission to change status' 
      },
    })
    return
  }
  if (!userHasAccessToFarm(farmId)) {
    dispatch({
      type: 'farm/status/error',
      error: { message: 'User has no access to provided farm' },
    })
    return
  }

  const payload = {
    status: {
      [status]: {
        date: firebase.firestore.Timestamp.fromDate(new Date()),
        user: firebase.auth().currentUser.uid,
        week,
        turn,
      },
    },
  }

  updateDoc(dispatch, FARM_COLLECTION, farmId, payload, 'farm/status', 'farm/status/changed', 'farm/status/error')

}

export const unsetStatus = (dispatch, getState, farmId, status) => {
  if (!userHasRole([roleAccessToStatus[status]])) {
    dispatch({
      type: 'farm/update/error',
      error: { 
        code: 'no-permission',
        message: 'User has no permission to change status' 
      },
    })
    return
  }
  if (!userHasAccessToFarm(farmId)) {
    dispatch({
      type: 'farm/status/error',
      error: { message: 'User has no access to provided farm' },
    })
    return
  }

  const payload = {
    status: { [status]: null },
  }

  updateDoc(dispatch, FARM_COLLECTION, farmId, payload, 'farm/status', 'farm/status/changed', 'farm/status/error')

}

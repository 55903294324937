import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'buyerHome.title',
    defaultMessage: 'Lois',
  },
  activeCounter: {
    id: 'buyerHome.activeCounter',
    defaultMessage:'ativas',
  },
  originatedCounter: {
      id: 'buyerHome.originatedCounter',
      defaultMessage: 'originadas'
  }
})

export default messages

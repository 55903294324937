import styled, { css } from 'styled-components';
import { colors } from 'styles';

const containerThemes = {
  default: css`
    height: 50px;
    background: ${colors.alabaster};
    border: 1px solid ${colors.silver7};
    border-radius: 5px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 15px;
  `,
  invisible: css`
    display: none;
  `
}

export const Container = styled.div`
  ${({ theme }) => containerThemes[theme] || containerThemes.default};
  min-height: 80vh;
  width: 60vw;
  justify-content: center;
`;

const wrapperThemes = {
  default: css`
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 45px;
    position: relative;

    & > svg {
      position: absolute;
      right: 0;
    }
  `,
  invisible: css`
    display: none;
  `
}

export const Wrapper = styled.div`
  ${({ theme }) => wrapperThemes[theme] || wrapperThemes.default}
`;

const attachmentThemes = {
  default: css`
    background: ${colors.alto2};
    border-radius: 5px;
    width: 64px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
  invisible: css`
    display: none;
  `
}

export const Attachment = styled.div`
  ${({ theme }) => attachmentThemes[theme] || attachmentThemes.default}
`;

export const Placeholder = styled.p`
  font-size: 16px;
  color: ${colors.dorado};
`;

export const Input = styled.input`
  display: none;
`;

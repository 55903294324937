import { colors } from "styles"

export const topbar = `
	background-color: #005D16;
	height: 106px;
	width: 100%;
	text-align: center;
	padding-top: 30px;
`

export const logo = `
	margin: auto;
	max-width: 100%;
 	max-height: 100%;
`

export const divbar = `
	height: 39px;
	background-color: #6BAC5F;
	width: 100%;
`

export const green = `
	color: ${colors.green};
`
export const subtitle = `
	margin-top: -20px;
`

export const body = `
	background-color: #C6C6C6;
	width: 60%;
	margin: auto;
	text-align: center;
	padding: 30px;
	display: block;
	margin-left: auto;
	margin-right: auto;
`

export const account =`
	margin: 10px;
`

export const button =`
	width: 300px;
	height: 50px;
	background-color: white;
	text-decoration: none;
	color: #6BAC5F;
	border-radius: 10px;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	padding: 5px;
	font-size: 16pt;
	font-weight: 500px;
	-webkit-box-shadow: -5px -2px 33px 10px rgba(171,168,171,1);
	-moz-box-shadow: -5px -2px 33px 10px rgba(171,168,171,1);
	box-shadow: -5px -2px 33px 10px rgba(171,168,171,1);
	line-height: 30px;
`

export const footer = `
	background-color: #005D16;
	height: 100px;
	color: white;
	padding-top: 40px;
	padding-left: 20px;
	padding-right: 20px;
`

export const url = `
	float: left;
	color: white;
	text-decoration: none;
	font-weight: 500;
`

export const social = `
	float: right;
`
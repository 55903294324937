import { useSelector, useDispatch } from 'react-redux'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { LOI_COLLECTION, PRODUCT_COLLECTION } from '../consts/collections'
import { create, setStatus } from '../logic/loiLogic'

export function useLOI(id) {
  useFirestoreConnect([{ collection: LOI_COLLECTION, doc: id }, { collection: PRODUCT_COLLECTION}])
  const dispatch = useDispatch()

  const loi = useSelector(
    ({ firestore: { data } }) =>
      data[LOI_COLLECTION] && data[LOI_COLLECTION][id]
  )
  const uid = useSelector((state) => state.firebase.auth.uid)
  const error = useSelector( state => state.loi.error )
  const all = useSelector((state) => state.firestore.ordered[LOI_COLLECTION])
  const products = useSelector((state) => state.firestore.data[PRODUCT_COLLECTION])

  const allLOIs = all && products ? all.map(loi => {
    return { ...loi, product: products[loi.product.id]}
  }) : null

  return {
    all: allLOIs,
    mine: allLOIs ? allLOIs.filter(loi => loi.createdBy === uid) : [],
    loi: isLoaded(loi) ? loi : { loading: true },
    create: (data) => dispatch(create, data),
    setStatus: (status) => dispatch(setStatus, status),
    error
  }
}

import React from 'react';
import { Wrapper } from './styles'
import { useAuth } from 'modules/data/hooks/useAuth'
import Spinner from 'components/Spinner'

function LoadingMessage() {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
}

const withSplashScreen = WrappedComponent => props => {
  const auth = useAuth()
  if (!auth.isLoaded) return(<LoadingMessage />)


  return(<WrappedComponent {...props} />)
}

export default withSplashScreen;
import styled from 'styled-components'
import { colors, fonts } from 'styles'

export const SelectStyled = styled.label`
  position: relative;
  display: inline-block;
  margin: 0;
  font-family: inherit;
  min-height: min-content;

  & > div {
    position: relative;

    & > svg {
      position: absolute;
      right: 10px;
      pointer-events: none;
      width: 20px;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }

  & input {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-family: ${fonts.primary};
    font-size: 14px;
    outline: none;
    line-height: 1.2;
    background: transparent;
    color: ${colors.greyDark};
    appearance: none;
    border-radius: ${({ borderRadius }) => borderRadius || '5px'};

    height: 40px;
    width: 100%;
    min-width: 240px;
    margin: 5px 0;

    border: 1px solid ${colors.silver};
  }
`

export const Label = styled.p`
  font-family: ${fonts.primary};
  color: ${colors.boulder};
  font-size: 18px;
  text-align: ${({ placeholderAlign }) => placeholderAlign || 'left'};
  width: 100%;
  margin-bottom: 5px;
`

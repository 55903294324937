import styled from 'styled-components'

export const Container = styled.div`
  width: 730px;
  background: #ffffff;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.31);
  border-radius: 5px;
  min-height: 386px;
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    color: #47ac34;
    font-weight: bold;
    font-size: 27px;
    margin: 30px 30px 10px 30px;
  }
`
export const Footer = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18pt;

  div {
    width: inherit;
    height: inherit;
    align-self: flex-end;
    margin-left: 350px;

    #finishing {
      width: 200px;
      height: 50px;
      background: #47ac34;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
      border: 0;
      margin-top: 15px;
      margin-bottom: 15px;
      border-radius: 5px;
      margin-top: 40px;
    }

    #cancel {
      width: 150px;
      height: 50px;
      background: transparent;
      cursor: pointer;
      border: 0;
      color: #565656;
      font-weight: bold;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 10px;
      border-radius: 5px;
      margin-top: 40px;
    }
  }
`
export const Content = styled.div`
  width: inherit;
  height: 150px;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;

  #space {
    width: 60px;
  }
`
export const Description = styled.div`
  width: inherit;
  background: #fff;
  padding: 80px;
  padding-bottom: 40px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  #space {
    width: 60px;
  }

  p {
    margin: 20px 0 20px;
    line-height: 34px;
    text-align: center;
  }
`

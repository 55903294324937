import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from './styles'
import InputPhoneNumber from 'components/InputPhoneNumber'
import Text from 'components/Text'
import Button from 'components/Button'
import logo from 'assets/logo-green.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useBuyer } from 'modules/data/hooks/useBuyer'
import { useIntl } from 'react-intl'
import messages from './messages'
import { toast } from 'react-toastify'

export default function RegisterPhone() {
  const history = useHistory()
  const [phoneValue, setPhoneValue] = useState('')
  const { addFeature, buyer } = useBuyer();
  const { formatMessage } = useIntl()

  useEffect( () => {
    if(buyer && buyer.phone) setPhoneValue(buyer.phone)
  }, [ buyer ])

  const handleNextStep = () => {
    if(!phoneValue) {
      toast.error(formatMessage(messages.error))
      return
    }
    addFeature({ phone: phoneValue });
    history.push('/buyer-verify-phone')
  }

  return (
    <Container>
      <div className='bg-image' />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <InputPhoneNumber
              placeholder={formatMessage(messages.cellphone)}
              value={phoneValue}
              onChange={({ target }) => setPhoneValue(target.value)}
            />
            <Text className='info-msg'>
              {formatMessage(messages.smsWillBeSent)}
            </Text>
            <Footer>
              <div className='step'>{formatMessage(messages.step)} </div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to='/buyer-step-password'>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to='/buyer-step-password'>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

import React, { useState, useEffect } from 'react'
import {
  Container,
  Logo,
  FormContainer,
  Content,
  Footer,
  Buttons,
} from '../styles'
import Input from 'components/Input'
import Button from 'components/Button'
import logo from 'assets/logo-green.svg'
import { useHistory, Link } from 'react-router-dom'
import voltar from 'assets/voltar.svg'
import { useBuyer } from '../../../modules/data/hooks/useBuyer'
import { useIntl } from 'react-intl'
import messages from './messages'
import { toast } from 'react-toastify'

export default function CompanyName() {
  const history = useHistory()
  const [nomeFantasia, setNomeFantasia] = useState('')
  const [razaoSocial, setRazaoSocial] = useState('')
  const { addFeature, buyer } = useBuyer()
  const { formatMessage } = useIntl()

  useEffect( () => {
    if(buyer && buyer.name) {
      setNomeFantasia(buyer.fantasy)
      setRazaoSocial(buyer.name)
    }
  },[ buyer ])

  const handleNextStep = () => {
    if(!nomeFantasia || !razaoSocial) {
      toast.error(formatMessage(messages.error))
      return
    }
    addFeature({ fantasy: nomeFantasia, name: razaoSocial })
    history.push('/buyer-step-details')
  }

  return (
    <Container>
      <div className='bg-image' />
      <FormContainer>
        <Logo>
          <img src={logo} alt='Agricon' />
        </Logo>
        <form action=''>
          <Content>
            <div className='fields'>
              <Input
                placeholder={formatMessage(messages.name)}
                value={razaoSocial}
                onChange={({ target }) => setRazaoSocial(target.value)}
              />

              <Input
                placeholder={formatMessage(messages.fantasyName)}
                value={nomeFantasia}
                onChange={({ target }) => setNomeFantasia(target.value)}
              />
            </div>
            <Footer>
              <div className='step'>{formatMessage(messages.step)}</div>
              <Buttons>
                <div>
                  <Link className='voltar-desktop' to='/buyer-step-data'>
                    {formatMessage(messages.back)}
                  </Link>
                  <Link className='voltar-mobile' to='/buyer-step-data'>
                    <img src={voltar} alt='voltar' />
                  </Link>
                </div>
                <Button
                  type='button'
                  className='continue'
                  onClick={handleNextStep}>
                  {formatMessage(messages.continue)}
                </Button>
              </Buttons>
            </Footer>
          </Content>
        </form>
      </FormContainer>
    </Container>
  )
}

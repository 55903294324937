import React from "react";

import * as s from "./styles";

export function Tabs({ tabs = [], onSetTabs, hasLast, minWidth, selectedTab, mobile = false }) {
  const selectTab = (i) => {
    if (onSetTabs) {
      onSetTabs(tabs[i], i);
    }
  };

  if(!mobile)
  return (
    <s.Tabs>
      {tabs
        .filter((t) => !t.disabled)
        .map((tab, i) => (
          <s.TabItem
            key={`tab-${tab.name}-${i}`}
            onClick={() => selectTab(i)}
            selected={selectedTab ? tab.slug === selectedTab.slug : false}
            minWidth={minWidth}
          >
            {tab.name}
          </s.TabItem>
        ))}

        {hasLast && 
        <s.LastTab />}
    </s.Tabs>
  );
  else
    return (
      <s.TabsMobile>
        {tabs
          .filter((t) => !t.disabled)
          .map((tab, i) => (
            <s.TabMobileItem
              key={`tab-${tab.name}-${i}`}
              onClick={() => selectTab(i)}
              selected={selectedTab ? tab.slug === selectedTab.slug : false}
              minWidth={minWidth}>
              {tab.name}
            </s.TabMobileItem>
          ))}

        {hasLast && <s.LastTab />}
      </s.TabsMobile>
    )
}
